import {
  BUTTON,
  EXTERNAL_LOGIN,
} from 'components/shared/form/formConstants';


const externalFormFields = [
  {
    id: EXTERNAL_LOGIN,
    formHeaderText: 'Log In or Register',
    formDescription: 'Please select the relevant option below to be redirected to DocCheck, a trusted third-party resource.',
    formFields: [
      {
        id: 'log-in-external',
        type: BUTTON,
        size: 50,
        className: 'btn_event_login',
        typeParams: {
          callBack: ({ actions }) => {
            actions.loginAction();
          },
          text: 'LOG IN',
          type: 'button',
          gtmEvent: "login_intent",
          gtmType: "login_page",
        },
      },
      {
        id: 'register-in-external',
        type: BUTTON,
        size: 50,
        className: 'btn_event_register',
        typeParams: {
          callBack: ({ actions }) => {
            actions.registerAction();
          },
          text: 'REGISTER',
          type: 'button',
          style: 'blank',
          gtmEvent: "register_intent",
          gtmType: "login_page",
        },
      },
    ],
  },
];

export {
  externalFormFields,
};

