import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    location: {
        pathname: null,
        search: null,
    },
};

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        storeLocation: (state, action) => {
            const { pathname, search } = action.payload;
            state.location.pathname = pathname || null;
            state.location.search = search || null;
        },
        clearStoreLocation: () => initialState,
    },
});

export const {
    storeLocation,
    clearStoreLocation,
} = locationSlice.actions;


// eslint-disable-next-line import/no-default-export
export default locationSlice.reducer;
