import React from 'react'
import isEmpty from 'lodash.isempty'

// components
import Link from 'components/reusableComponents/link/Link'
import { ChevronRight } from 'components/shared/SvgIcons'
import { sortByLastModifiedDate, sortRandomly } from 'utils/arrayUtils';
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
  } from 'components/layout/ScreenWidthContext';

// modules
import Carousel from 'modules/carousel/Carousel'

// styles
import './RelatedContent.scss'

const RelatedContent = ({
    content,
    contain,
    carouselSettings,
    bgColor,
    carouselTheme,
    cardTheme,
    alternateCardBg,
    componentType,
    headingText,
    removeHeading,
    mobileGap,
    tabletGap,
    desktopGap,
    fluid,
    fluidRight,
    removeTopPadding,
    removeLeftPadding,
    removeBottomPadding,
    removeRightPadding,
    children,
    brandCoulours
}) => {
   
    const { heading, viewAll } = content?.value?.elements || {}

    const sectionHeading = headingText || heading?.value || null
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

    const sectionBackgroundColor = bgColor || null

    const theme = cardTheme || carouselTheme || 'primary'

    const carouselItems = content?.value?.elements?.item?.values || content?.values || null

    const defaultCarouselSettings = {
        infinite: content?.value?.elements?.automateCarousel?.value ? true : false,
        arrows: false,
        slidesToShow: 3,
        autoplay: isSmall ? false  : content?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: content?.value?.elements?.automationInterval?.value ? parseFloat(content?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1 },
            },
        ],
    }

    const settings = !isEmpty(carouselSettings) ? { ...carouselSettings } : { ...defaultCarouselSettings }

    const carouselContent = (array) => {
        return content?.value?.elements?.carouselOrder?.value?.selection == "random" ? sortRandomly(array) : content?.value?.elements?.carouselOrder?.value?.selection == "lastModifiedDate" ? sortByLastModifiedDate(array) : array
    }
    
    
    if (!content?.value?.elements?.item?.values && (!content?.values || content?.values?.length === 0) && !children) {
        return null
    }

    if (contain) {
        return (
            <section
                className={`Related_Content_Section${fluid ? ' Related_Content_Section--fluid' : ''}${fluidRight ? ' Related_Content_Section--fluid Related_Content_Section--fluid-right' : ''}${
                    removeTopPadding ? ' Related_Content_Section--remove-top-padding' : ''
                }${
                    removeLeftPadding ? ' Related_Content_Section--remove-left-padding' : ''
                }${
                    removeBottomPadding ? ' Related_Content_Section--remove-bottom-padding' : ''
                }${
                    removeRightPadding ? ' Related_Content_Section--remove-right-padding' : ''
                }`}
                style={{ backgroundColor: sectionBackgroundColor }}
            >
                <div className='container'>
                    <div className='Related_Content_Heading'>
                        {!removeHeading && sectionHeading && <h2 className='Related_Content_Title'>{sectionHeading}</h2>}
                        {viewAll && viewAll?.linkText && viewAll?.linkURL && (
                            <div className='Related_Content_ViewAll Related_Content_ViewAll--desktop'>
                                <Link className='' href={viewAll.linkURL} >
                                    {viewAll.linkText}
                                    <span className='Related_Content_Chevron'>
                                        <ChevronRight />
                                    </span>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className='Related_Content'>
                        {children ? (
                            children
                        ) : (
                            <Carousel
                                settings={settings}
                                data={carouselContent(carouselItems)}
                                componentType={componentType || 'cards'}
                                theme={theme}
                                alternateCardBg={alternateCardBg}
                                mobileGap={mobileGap || null}
                                tabletGap={tabletGap || 20}
                                desktopGap={desktopGap || 40}
                            />
                        )}
                    </div>
                    {viewAll && viewAll?.linkText && viewAll?.linkURL && (
                        <div className='Related_Content_ViewAll Related_Content_ViewAll--mobile'>
                            <Link className='' href={viewAll.linkURL} >
                                {viewAll.linkText}
                                <span className='Related_Content_Chevron'>
                                    <ChevronRight />
                                </span>
                            </Link>
                        </div>
                    )}
                </div>
            </section>
        )
    }

    return (
        <section
            className={`Related_Content_Section${fluid ? ' Related_Content_Section--fluid' : ''}${fluidRight ? ' Related_Content_Section--fluid Related_Content_Section--fluid-right' : ''}${
                removeTopPadding ? ' Related_Content_Section--remove-top-padding' : ''
            }${
                removeLeftPadding ? ' Related_Content_Section--remove-left-padding' : ''
            }${
                removeBottomPadding ? ' Related_Content_Section--remove-bottom-padding' : ''
            }${
                removeRightPadding ? ' Related_Content_Section--remove-right-padding' : ''
            }`}
            style={{ backgroundColor: sectionBackgroundColor }}
        >
            <div className='Related_Content_Heading'>
                {!removeHeading && sectionHeading && <h2 className='Related_Content_Title'>{sectionHeading}</h2>}
                {viewAll && viewAll?.linkText && viewAll?.linkURL && (
                    <div className='Related_Content_ViewAll Related_Content_ViewAll--desktop'>
                        <Link className='' href={viewAll.linkURL} >
                            {viewAll.linkText}
                            <span className='Related_Content_Chevron'>
                                <ChevronRight />
                            </span>
                        </Link>
                    </div>
                )}
            </div>
            <div className='Related_Content'>
                {children ? (
                    children
                ) : (
                    <Carousel
                        settings={settings}
                        data={carouselContent(carouselItems)}
                        componentType={componentType || 'cards'}
                        theme={theme}
                        alternateCardBg={alternateCardBg}
                        mobileGap={mobileGap || null}
                        tabletGap={tabletGap || 20}
                        desktopGap={desktopGap || 40}
                        brandCoulours={brandCoulours}
                    />
                )}
            </div>
            {viewAll && viewAll?.linkText && viewAll?.linkURL && (
                <div className='Related_Content_ViewAll Related_Content_ViewAll--mobile'>
                    <Link className='' href={viewAll.linkURL} >
                        {viewAll.linkText}
                        <span className='Related_Content_Chevron'>
                            <ChevronRight />
                        </span>
                    </Link>
                </div>
            )}
        </section>
    )
}

export default RelatedContent