import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { childrenPropTypes } from 'propTypes/generic';
import { useToggleOpen } from 'utils/toggleOpen';
import './HomepageCollapsableSection.scss';
import { OpenClosedIndicator } from 'components/shared/OpenClosedIndicator';

const HomepageCollapsableSection = ({
  children,
  subtitle,
  title,
  icon,
  startOpen,
  isCollapsable,
  vvClickAttr,
  vvSnapshotAttr
}) => {
  const { open, toggleOpen } = useToggleOpen(startOpen);
  const { redesign } = useSelector(state => state.ui);
  if(redesign){
    return (
      <div className="homepage-collapsible-section">
        
        {isCollapsable && (
          <button
            type="button"
            className={`section-header custom-focus ${open ? 'open' : 'closed'}`}
            onClick={isCollapsable ? toggleOpen : null}
            data-vv-action={vvClickAttr}
            data-vv-snapshot={vvSnapshotAttr}
          >
            <span className="icon">
              {icon}
            </span>
            <span className="title">
              {title}
            </span>
            <span className="chevron">
              <OpenClosedIndicator open={open} />
            </span>
          </button>
        )}
  
        {!isCollapsable && subtitle && icon && (
          <div className={`section-header section-header--re-skin custom-focus open`} style={{ paddingLeft: 6 }} >
            <span className="icon">
              {icon}
            </span>
            <span className="title">
              {subtitle}
            </span>
          </div>
        )}
  
        {open && (
          <div className="contents">
            {children}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="homepage-collapsible-section">
      
      {isCollapsable && (
        <button
          type="button"
          className={`section-header custom-focus ${open ? 'open' : 'closed'}`}
          onClick={isCollapsable ? toggleOpen : null}
          data-vv-action={vvClickAttr}
          data-vv-snapshot={vvSnapshotAttr}
        >
          <span className="icon">
            {icon}
          </span>
          <span className="title">
            {title}
          </span>
          <span className="chevron">
            <OpenClosedIndicator open={open} />
          </span>
        </button>
      )}

      {!isCollapsable && (
        <div className={`section-header custom-focus open`} style={{ paddingLeft: 6 }} >
          <span className="icon">
            {icon}
          </span>
          <span className="title">
            {title}
          </span>
        </div>
      )}

      {open && (
        <div className="contents">
          {children}
        </div>
      )}
    </div>
  );
};

HomepageCollapsableSection.propTypes = {
  children: childrenPropTypes,
  title: PropTypes.string,
  icon: PropTypes.element,
  startOpen: PropTypes.bool,
  isCollapsable: PropTypes.bool,
  vvClickAttr: PropTypes.string,
  vvSnapshotAttr: PropTypes.string,
};

HomepageCollapsableSection.defaultProps = {
  isCollapsable: true,
} 

export { HomepageCollapsableSection };
