import React, { useEffect, useRef } from 'react'
import styles from "./Quiz.module.scss";

const QuizFooter = (props) => {  
  const columnRef  = useRef(null)
  useEffect(() => {
    if(props.children) {
      columnRef.current.style.setProperty(
        "--qt-footer-column-count",
        `${props.children.length}`
      )
    }
  })  
  return (
    <>
    {props.children && (
      <div className={styles["quiz-footer"]} ref={columnRef}>
        <div className={styles["quiz-footer-content"]}>
          {React.Children.map(props.children, function (child) {
            if (child.props.btnType === 'tertiary') {
              return (<span 
                        className={`
                          ${styles['quiz-footer-content-btn-tertiary']} 
                          ${child.props.isDisabled ? styles[`btn-disabled`] : ''}
                        `}
                      >{child}</span>)
            }
            if (child.props.btnType === 'primary') {
              return (<span 
                        className={`
                          ${styles['quiz-footer-content-btn-primary']} 
                          ${child.props.isDisabled ? styles[`btn-disabled`] : ''}
                        `}
                      >{child}</span>)
            }
            if (child.props.btnType === 'secondary') {
              return (<span 
                        className={`
                          ${styles['quiz-footer-content-btn-secondary']} 
                          ${child.props.isDisabled ? styles[`btn-disabled`] : ''}
                        `}
                      >{child}</span>)
            }
            return (child)
          })}
        </div>
      </div>
    )}
    </>
  )
}

export default QuizFooter;
