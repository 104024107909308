import { DATA_CAPTURE_URL } from './path';
import { authPostQuery } from '../AxiosQueries';
import { getEnvApiConfigValues } from 'utils/envUtils';
import { getAuthHeaderConfig } from 'utils/authUtils';

const { audience } = getEnvApiConfigValues();
const url = DATA_CAPTURE_URL;

const webinarSignUpDataCapture = ({body,token}) => {
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
    authToken:token
  });
  return authPostQuery({ url, data: body,  config: headerConfig });
};

export { webinarSignUpDataCapture };