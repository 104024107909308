import React from 'react'

// components
import Link from '../../../link/Link'

// icons
import { ChevronRight } from '../../../../shared/SvgIcons'

// styles
import './CardLink.scss'

import { getUrlCountryPath } from 'utils/urlUtils';

const CardLink = ({ text, href, theme, vvClickAttr,openInNewTab, onClick }) => {
    if (!text) return null

    if (!href) {
        return (
            <p className={`CardLink${theme ? ` CardLink--theme-${theme}` : ''}`}>
                <span>{text}</span>
                <ChevronRight />
            </p>
        )
    }

    const handleClick = (e) => {
        if(openInNewTab === true) {
            e.preventDefault()
            window.open(`/${getUrlCountryPath()}${href}`)
        }
    }
    return (
        <Link 
            className={`CardLink${theme ? ` CardLink--theme-${theme}` : ''}`} 
            href={href} 
            data-vv-action={vvClickAttr}
            onClick={e => {
                if(onClick) onClick()
                handleClick(e)
            }}
        >
            <span>{text}</span>
            <ChevronRight />
        </Link>
    )
}

export default CardLink
