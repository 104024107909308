import ContentCardItem from './ContentCardItem';
import Image from 'components/kanso/atoms/image/Image';
import ButtonAcousticData from 'components/kanso/atoms/button/ButtonAcousticData';
import RichText from 'components/reusableComponents/richText/RichText';
import {
  getFullUrl,
  getValue,
  getValues,
  getSelectionValue,
} from 'utils/utilityHelper';

const ContentCardAcousticData = (props) => {
  const { data } = props;

  const getRichText = (text) => {
    if (!text) return null;
    return <RichText data={{ value: text }} />;
  };

  return (
    <ContentCardItem
      image={
        <Image
          src={getFullUrl(data?.image?.url)}
          altText={getValue(data?.image?.altText)}
        />
      }
      title={getValue(data?.title)}
      titleTag={getSelectionValue(data?.titleTag)}
      subtitle={getValue(data?.subtitle)}
      subtitleTag={getSelectionValue(data?.subtitleTag)}
      label={getValue(data?.label)}
      text={getRichText(getValue(data?.text))}
      imageAlign={getSelectionValue(data?.imageAlign)}
    >
      {data?.linksButtons && getValues(data?.linksButtons) && (
        <>
          {getValues(data?.linksButtons).map((button, i) => {
            return <ButtonAcousticData key={i} data={button.elements} />;
          })}
        </>
      )}
    </ContentCardItem>
  );
};

export default ContentCardAcousticData;
