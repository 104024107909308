import React from "react";
import RichText from "components/reusableComponents/richText/RichText";

import "./SectionHeader.scss"

const SectionHeader = (props) => {
    return (
        <div className="sectionHeader">
            {(props?.title && <div className="sectionHeader__title">
                <RichText data={props?.title} />
            </div>)}
            {(props?.subtitle && <div className="sectionHeader__subTitle">
                <RichText data={props?.subtitle} />
            </div>)}
        </div>
    );
}

export default SectionHeader;