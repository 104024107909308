import CheckboxTile from "./CheckboxTile";
import CheckboxSimple from "./CheckboxSimple";

/**
* Input Component
* @param {string} type - it's the type checkbox - required
* @param {string} name - it's the name of the checkbox - required
* @param {string} id - it's the unique id for check box list values- required
* @param {string} label - it's the label of the checkbox - required
* @param {string} error - it's the error message - optional
* @param {string} required - checkbox label - required
* @param {string} disabled -check if the checkbox field is disabled or not - required
* @param {string} assistiveText - checkbox assistive text - required
* @param {string} showTiles -check if the tiles is activated or not - required
* @param {Object} tileImage - the image of the tile - optional
* @param {string} checked - get the value when checkbox is checked or unchecked - required
* @param {Object} field - field object contains the value of the checkbox and this parameter comes from Formik - required
* @return React component
**/

const Checkbox = (props) => {
  const {
    type,
    name,
    id,
    label,
    error,
    required,
    disabled,
		assistiveText,
		showTiles, 
		tileImage,
		checked,
		field,
		...rest
	} = props;

	return (
		<div>
			{showTiles ? (
				<CheckboxTile
					type = {type}
					name ={name}
					label = {label}
					id ={id}
					error ={error}
					field ={field}
					checkboxLabel = {label}
					checked={checked}
					checkboxAssistiveText = {assistiveText}
					checkboxIsRequired = {required}
					checkboxIsDisabled ={disabled}
					tileImage={tileImage}
					{...rest}
				></CheckboxTile>
			)	:
			<CheckboxSimple
				type = {type}
				name ={name}
				label = {label}
				id ={id}
				error ={error}
				field ={field}
				checkboxLabel = {label}
				checked={checked}
				checkboxIsRequired = {required}
				checkboxIsDisabled = {disabled}
				checkboxAssistiveText = {assistiveText}
				{...rest}
			></CheckboxSimple>
			}
		</div>
	)
}

export default Checkbox;
