import React from 'react'

import { getValues } from 'utils/contentUtils'
import { withLeadingSlash } from 'utils/stringUtils'
import NavLink from 'components/reusableComponents/navLink/NavLink'
import { ExternalSideNavIcon } from '../../components/shared/SvgIcons';

import './SideNavigation.scss'

const SideNavigation = ({ element, colour, onClick }) => {
    const { subnavigationItems } = element.elements
    const navLinks = getValues(subnavigationItems) || []
    return (
        <nav className='SideNavigation'>
            {navLinks.map((item, index) => {

                let href = null; 
                let isExternalUrl = false;
                
                if (item?.link?.linkURL && (item?.link?.linkURL.startsWith('http') || item?.link?.linkURL.startsWith('www'))) {
                    isExternalUrl = true;
                    href = item?.link?.linkURL ? item?.link?.linkURL : null
                } else {
                    href = item?.link?.linkURL ? withLeadingSlash(item?.link?.linkURL) : null
                }
                
                let hrefLink = item?.link?.linkText;
                if(href=== null){
                  href= (item?.linkText?.value);
                  hrefLink= href
                }
                
                if (href)
                    return (
                        <NavLink 
                            className='SideNavigation_Item' 
                            activeClassName="SideNavigation_Item--active" 
                            exact 
                            href={href} 
                            onClick={onClick}
                            key={'sideNav' + index} activeStyle={{ color:colour }}
                            vvClickAttr='click'
                        >
                            {isExternalUrl 
                                ? 
                                    <div className='SideNavigation--external'>
                                        <span  className={`SideNavigation_Item--${item.menuLevel?.value?.selection}`}>{hrefLink}</span>
                                        <ExternalSideNavIcon />
                                    </div>
                                : <span  className={`SideNavigation_Item--${item.menuLevel?.value?.selection}`}>{hrefLink}</span>
                            }
                            
                        </NavLink>
                    )
                return null
            })}
        </nav>
    )
}

export default SideNavigation
