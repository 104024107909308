import React, { useEffect, createRef } from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ConsentForm } from 'components/shared/form/ConsentForm';
import { useConsentFormFields } from 'components/layout/MandatoryConsentForm/formElements';
import { useConsentFormFieldsOld } from 'components/layout/MandatoryConsentForm/formElementsOld';
import { authActions } from 'redux/authentication/slice';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './ContentGate.scss';

// This paywall is an alternative to the paywall tied to access levels for self cert markets.
// This paywall can be used for external auth markets such as markets that use DocCheck and OneKey.
const ContentGatePopUp = () => {
  const mandatoryConsentFormRef = createRef();
  const { 
    featureSwitchDisplayNewFormFields,
    tToastBarText,
    adverseReportingUrl,
    tAdverseReportingLinkText,
    adverseReportingLinkLogo,
  } = useWebsiteConfigObject();
  useEffect(() => {
    if (mandatoryConsentFormRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(mandatoryConsentFormRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [mandatoryConsentFormRef]);

  const dispatch = useDispatch();

  const updateConsent = ({ formValues }) => {
    dispatch(authActions.global.consent.update({ formValues }));
  };

  const consentFormFields = useConsentFormFields();
  const consentFormFieldsOld = useConsentFormFieldsOld();
  const blockModifierClass = tToastBarText?.value || adverseReportingUrl || tAdverseReportingLinkText || adverseReportingLinkLogo?.value ? " content-gate--toastbar-present" : ""
  
  return (
    <div className={`content-gate content-gate--tc${blockModifierClass}`}>
      <div className='container'>
        <ConsentForm
          ref={mandatoryConsentFormRef}
          consentFormFields={
            featureSwitchDisplayNewFormFields
              ? consentFormFields
              : consentFormFieldsOld
          }
          actions={{ updateConsent }}
          version="new"
        />
      </div>
    </div>
  );
};

ContentGatePopUp.propTypes = {
  fullPage: PropTypes.bool,
  withBlur: PropTypes.bool,
  buttonLogo: PropTypes.object,
  loginRedirect: PropTypes.func,
  registerRedirect: PropTypes.func,
};

const ContentGateContainer = ({
  isSecure,
  canvas,
  browserSupported,
}) => {
  const contentGateRef = createRef();
  const { contentGateBlurLevel } = useWebsiteConfigObject();

  useEffect(() => {
    const contentWrapper = document.querySelectorAll('[role="main"]')[0];

    if (contentWrapper) {
      contentWrapper.setAttribute('id', 'blurredContentWrapper');
    }

    if (contentGateRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(contentGateRef.current);
    }

    return () => {
      const blurWrapper = document.getElementById('blurredContentWrapper');

      if (blurWrapper) {
        blurWrapper.removeAttribute('id');
      }

      clearAllBodyScrollLocks();
    };
  }, [contentGateRef]);

  return (
    <>
      <div
        ref={contentGateRef}
        className='content-gate__overlay content-gate__overlay--limited-access'
      >
        {browserSupported && (
          <>
            <svg id='svgBlur' version='1.1' xmlns='http://www.w3.org/2000/svg'>
              <filter id='blur'>
                <feGaussianBlur
                  stdDeviation={`${contentGateBlurLevel || 10}`}
                />
              </filter>
            </svg>
            {isSecure && (
              <img src={canvas} alt='Blurred Content' className='blur' />
            )}
          </>
        )}
      </div>

      <ContentGatePopUp />
    </>
  );
};

export { ContentGateContainer as ContentGate };
