import {
  BUTTON,
  RADIO,
  COMPONENT,
  CHECKBOX,
  OPTIN_HCP,
  INFORMATION_CONSENT,
  commonShouldHaveValue,
  SHOULD_ACCEPT_CONSENT,
  CONSENT_FORM,
  SHOULD_HAVE_VALUE_RADIO,
} from 'components/shared/form/formConstants';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { WithdrawConsent } from 'components/pageTemplates/login/WithdrawConsent';
import { MandatoryKey } from 'components/pageTemplates/login/MandatoryKey';
import { TermsLabel } from "components/pageTemplates/login/Terms-label";
import { PrivacyConsent } from "components/pageTemplates/login/PrivacyConsent";
import { MarketingLabel } from "components/pageTemplates/login/Marketing-label";
import { MarketingConsent } from "components/pageTemplates/login/MarketingConsent";
const useConsentFormFields = () => {
  const {
    tConsentFormTitle,
    tConsentFormText,
    tConsentFormHcpConsentText,
    tConsentFormMarketingConsentText,
    tConsentFormButtonText,
    tThisFieldIsRequired,
    tmarketingPreferenceErrorMessage,
    disableMarketingConsent
  } = useWebsiteConfigObject();

  const consentFormFields = [
    {
      id: CONSENT_FORM,
      formHeaderText: tConsentFormTitle,
      formDescription: tConsentFormText,
      formFields: [
        {
          id: "terms-label",
          type: COMPONENT,
          size: 100,
          typeParams: {
            PropComponent: TermsLabel,
          },
        },
        {
          id: "privacy-text",
          type: COMPONENT,
          size: 100,
          typeParams: {
            PropComponent: PrivacyConsent,
          },
        },
        {
          label: "",
          id: OPTIN_HCP,
          initialValue: "",
          type: RADIO,
          mandatory: true,
          typeParams: {},
          validation: [{
            validationMethodKey: SHOULD_ACCEPT_CONSENT,
            errorText: tThisFieldIsRequired ? tThisFieldIsRequired : 'This field is required',
          }],
        },
        {
          id: "marketing-label",
          type: COMPONENT,
          size: 100,
          typeParams: {
            PropComponent: MarketingLabel,
          },
          disabled: disableMarketingConsent
        },
        {
          id: "marketing-text",
          type: COMPONENT,
          size: 100,
          typeParams: {
            PropComponent: MarketingConsent,
          },
          disabled: disableMarketingConsent
        },
        {
          id: "informationConsent",
          initialValue: disableMarketingConsent ? null : false,
          type: RADIO,
          // mandatory: false,
          typeParams: {},
          // validation: [{
          //   validationMethodKey: SHOULD_HAVE_VALUE_RADIO,
          //   errorText: tmarketingPreferenceErrorMessage ? tmarketingPreferenceErrorMessage : 'Please select either "Yes" or "No"',
          // }],
          disabled: disableMarketingConsent
        },
        {
          id: 'withdraw-consent',
          type: COMPONENT,
          typeParams: {
            PropComponent: WithdrawConsent,
          },
        },
        {
          id: 'consent-continue',
          type: BUTTON,
          size: 50,
          typeParams: {
            callBack: ({ formValues, actions }) => {
              actions.updateConsent({ formValues });
            },
            text: tConsentFormButtonText,
            type: 'button',
          },
        },
        {
          id: 'mandatory-key',
          type: COMPONENT,
          size: 100,
          typeParams: {
            PropComponent: MandatoryKey,
          },
        },
      ],
    },
  ];

  return consentFormFields;
}

export {
  useConsentFormFields,
};

