import React, { useEffect, createRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'redux/authentication/slice';
import PropTypes from 'prop-types';
import { ChevronButton, LINK, BUTTON } from 'components/shared/ChevronButton';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useCookies } from 'react-cookie';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import './PayWall.scss';
import RichText from 'components/reusableComponents/richText/RichText';
import { extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics';

// Content Access Levels:
// LEVEL A: Open content - Visible to all - no self-certification or login required
// LEVEL B: Content only visible to HCPs - Self-certified or registered
// LEVEL C: Content only visible to registered AND logged in users
const LEVEL_A = 'a';
const LEVEL_B = 'b';
// const LEVEL_C = 'c';
let timer;

const PayWallPopUp = ({
  accessLevel,
  relatedPageUrl,
  handleSelfCertificationHCP,
  handleSelfCertificationGeneralPublic,
}) => {
  const {
    tSelfCertificationTitleLevelB,
    tSelfCertificationText,
    tSelfCertificationHcpButtonText,
    tSelfCertificationHcpButtonSmallprint,
    tSelfCertificationGeneralPublicButtonText,
    tSelfCertificationGeneralPublicSmallprint,
    tSelfCertificationTitleLevelC,
    tJointVentureNotificationText
  } = useWebsiteConfigObject();

  const title =
    accessLevel === LEVEL_B
      ? tSelfCertificationTitleLevelB
      : tSelfCertificationTitleLevelC;

  return (
    <div className='paywall'>
      {title && <h3 className='paywall__title'>{title}</h3>}

      {tSelfCertificationText && (
        <p className='paywall__text'>{tSelfCertificationText}</p>
      )}

      {tJointVentureNotificationText && (
        <RichText className='paywall__text' data={{ value: tJointVentureNotificationText}} />
      )}

      <div className='paywall__footer'>
        {accessLevel === LEVEL_B && (
          <div className='paywall__cta-wrapper'>
            <ChevronButton
              type={BUTTON}
              text={tSelfCertificationHcpButtonText}
              variant='paywall-btn'
              chevron='down'
              onClick={handleSelfCertificationHCP}
              vvClickAttr='click'
              vvWaitAttr='5000'
            />
            {tSelfCertificationHcpButtonSmallprint && (
              <p className='paywall__cta-smallprint'>
                {tSelfCertificationHcpButtonSmallprint}
              </p>
            )}
          </div>
        )}
        {relatedPageUrl && (
          <div className='paywall__cta-wrapper'>
            <ChevronButton
              type={LINK}
              text={tSelfCertificationGeneralPublicButtonText}
              variant='paywall-btn'
              onClick={handleSelfCertificationGeneralPublic}
              externalHref={relatedPageUrl}
            />
            {tSelfCertificationGeneralPublicSmallprint && (
              <p className='paywall__cta-smallprint'>
                {tSelfCertificationGeneralPublicSmallprint}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PayWallPopUp.propTypes = {
  accessLevel: PropTypes.string,
  relatedPageUrl: PropTypes.string,
  handleSelfCertificationHCP: PropTypes.func,
  handleSelfCertificationGeneralPublic: PropTypes.func,
};

const PayWall = ({ pageTitle, loggedIn, accessLevel, relatedPageUrl, content }) => {
  let gaPageTitle = pageTitle;
  if(pageTitle){
    gaPageTitle = pageTitle.concat(" ", " – Kyowa Kirin Hub");
  }
  let pathName = window.location.pathname;
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    'userSelfCertified',
  ]);
  const {
    userSelfCertified,
    updateUserSelfCertified,
    MINS_SELF_CERTIFICATION_VALID_FOR,
  } = useContext(cookieConfirmationContext);

  const payWallRef = createRef();
  const minsInMilliseconds = (mins) => mins * 60000;
  const { trackEventWithContentType, trackPageView } = useGoogleAnalytics();
  const { taxonomyFields } = useSelector((state) => state.ga);
  const taxonomy = extractTaxonomyValues(taxonomyFields, content?.elements?.taxonomy?.value);

  const handleSelfCertificationHCP = () => {
    trackEventWithContentType('HCP', 'yes');
    setTimeout(() => {
      trackPageView(pathName, gaPageTitle, false, taxonomy);
    }, 500);
    window.clearTimeout(timer);
    updateUserSelfCertified(true);
    dispatch(authActions.kki.guestLogin());

    timer = window.setTimeout(() => {
      dispatch(authActions.kki.guestLogout());
      updateUserSelfCertified(false);
    }, minsInMilliseconds(MINS_SELF_CERTIFICATION_VALID_FOR));
  };

  const handleSelfCertificationGeneralPublic = () => {
    trackEventWithContentType('HCP', 'no');
  };

  useEffect(() => {
    if (payWallRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(payWallRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [payWallRef]);

  if (!accessLevel || accessLevel === LEVEL_A) {
    return null;
  }

  if (accessLevel === LEVEL_B && (!userSelfCertified || cookies.userSelfCertified === "false") && !loggedIn) {
    return (
      <div ref={payWallRef} className='paywall__overlay'>
        <PayWallPopUp
          accessLevel={accessLevel}
          relatedPageUrl={relatedPageUrl}
          handleSelfCertificationHCP={handleSelfCertificationHCP}
          handleSelfCertificationGeneralPublic={
            handleSelfCertificationGeneralPublic
          }
        />
      </div>
    );
  }

  return null;
};

PayWall.propTypes = {
  loggedIn: PropTypes.bool,
  accessLevel: PropTypes.string,
  relatedPageUrl: PropTypes.string,
};

export { PayWall };
