import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
    fallbackLng: 'gb',
    preload: true,
    resources: {
        gb: {
            translations: require('./locales/gb/translations.json')
        },
        sa: {
            translations: require('./locales/sa/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
})

i18n.languages = ['gb', 'sa']

export default i18n