import React from 'react'
import { useSelector } from 'react-redux';

// components
import Card from '../../components/reusableComponents/card/Card'


const BasicCard = ({ children, ...props }) => {
    const { redesign } = useSelector(state => state.ui);

    return (
        <Card {...props} removeBorderRadius removeShadow={!redesign ? true : false}>
            {children}
        </Card>
    )
}

export default BasicCard
