import { AppConfig } from 'AppConfig'
import { getAuthHeaderConfig } from 'utils/authUtils'
import { SESSION_ID, setAuthKeys, getSessionFromStorage } from 'utils/localStorageUtils'
import { CREATE_TOKEN_URL, DELETE_USER_URL, VALIDATE_USER_URL, EXTENTED_PROFILE_PATH, USER_URL } from 'api/owaApi/paths'
import { warnUndefinedKeys } from 'utils/userDataUtils'
import { CONFIRM_DELETE_USER_ACCOUNT } from 'routes/routeConstants'
import { getLocationOrigin, getUrlCountryPath, getLanguageCode } from 'utils/urlUtils'
import { getEnvApiConfigValues } from 'utils/envUtils';
import uuid from 'react-uuid'
import { authPostQuery, authGetQuery } from '../AxiosQueries'

const {
  audience,
} = AppConfig.authApi.owa

const { owasecureURL, redirectUrl } = getEnvApiConfigValues();

const owaAuth = {
  // Redirects user to OneKey registration page
  owaRegisterRedirect: () => {

    const sessionId = uuid()

    setAuthKeys({ key: SESSION_ID, payload: sessionId })

     const url = (redirectUrl) ? `${owasecureURL}login?_flowId=inscription-webflow&TARGET=${encodeURIComponent(`${VALIDATE_USER_URL}?countryCode=${getUrlCountryPath()}&sessionId=${sessionId}&redirectURL=${redirectUrl}`)}` : `${owasecureURL}login?_flowId=inscription-webflow&TARGET=${encodeURIComponent(`${VALIDATE_USER_URL}?countryCode=${getUrlCountryPath()}&sessionId=${sessionId}`)}`

    window.location.href = url

  },
  // Redirects user to OneKey login page
  owaLoginRedirect: () => {

    const sessionId = uuid()

    setAuthKeys({ key: SESSION_ID, payload: sessionId })

    const url = (redirectUrl) ? `${owasecureURL}login?TARGET=${encodeURIComponent(`${VALIDATE_USER_URL}?countryCode=${getUrlCountryPath()}&sessionId=${sessionId}&redirectURL=${redirectUrl}`)}` : `${owasecureURL}login?TARGET=${encodeURIComponent(`${VALIDATE_USER_URL}?countryCode=${getUrlCountryPath()}&sessionId=${sessionId}`)}`

    window.location.href = url

  },
  // Call to API to create a user token
  createUserToken: ({taxonomyRegistration}) => {

    const sessionId = getSessionFromStorage()

    const url = `${CREATE_TOKEN_URL}?sessionId=${sessionId}&additionalInfo=${JSON.stringify(taxonomyRegistration).replaceAll('&','%26')}`
    return authPostQuery({ url, config: getAuthHeaderConfig({ audienceUrl: audience }) }).then((response) => response)

  },

  // Call to API to create a user token
  callExtendedUserProfile: (id, token) => {

    const url = `${USER_URL}/${id}/${EXTENTED_PROFILE_PATH}`;

    const headerConfig = getAuthHeaderConfig({
      audienceUrl: audience,
      authToken: token,
    });
  
    return authGetQuery({ url, config: headerConfig });

  },
  // Call to API to delete a user
  // Current user ID and JWT Token passed in
  deleteUser: ({ id, token }) => {

    const url = `${DELETE_USER_URL}/${id}`


    const params = {
      languageCode: getLanguageCode(),
      redirectUrl: `${getLocationOrigin()}/${getUrlCountryPath()}${CONFIRM_DELETE_USER_ACCOUNT}`,
    }

    warnUndefinedKeys(params, 'owaApi.js')

    const headerConfig = getAuthHeaderConfig({ audienceUrl: audience, authToken: token })

    return authPostQuery({ url, data: params, config: headerConfig })

  },
}



export { owaAuth }
