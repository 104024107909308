import { matchPath, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { withLeadingSlash } from 'utils/stringUtils';
import { getLibraryList } from 'utils/libraryUtils';
import { getUrlCountryPath } from 'utils/urlUtils'
import { routesQuery } from './routesQuery';
import { webinarQuery } from './webinarRoutes';
import { getAPIContent } from 'api/acousticApi/index';

// cache key for the routes query
const ROUTES_QUERY_KEY = 'routes-query';
const WEBINAR_QUERY_KEY = 'webinars-query';

// method to parse the data returned from the acoustic api search query
// and turn it into a useful array of routes
// routes returned have the form { contentId, templateType, path }
const parseRouteData = (data) => {
  if (!data) { return null; }
  const documents = data && data.data ? data.data.documents : null;
  if (!documents) { return null; }
  const routes = documents.map((document) => {
    const {
      id,
      type,
      string1,
    } = document;
    const slugValue = string1 && string1.length ? string1[0] : null;
    if (!slugValue) {
      // if the content does not have a defined urlSlug, it shouldn't be included in the routes
      return null;
    }
    const path = withLeadingSlash(slugValue);
    return ({
      contentId: id,
      templateType: type,
      path,
    });
  });
  // remove any null routes before returning
  return routes.filter(Boolean);
};

const useApiRoutes = () => {
  // useQuery takes a key for a query, and a callback that runs the query
  // and then caches the results. It also deals with loading fetching etc.
  // https://react-query.tanstack.com/docs/overview
  const { isLoading, error, data } = useQuery(
    ROUTES_QUERY_KEY,
    routesQuery,
    { staleTime: Infinity }, // only request the api routes once
  );
  const routes = data ? parseRouteData(data) : null;

  // Uncomment this function to create KKI Sitemap on Console
  // useSiteMap();
  return { routes, isLoading, error };
};

const useWebinarsQuery = () => {
  // useQuery takes a key for a query, and a callback that runs the query
  // and then caches the results. It also deals with loading fetching etc.
  // https://react-query.tanstack.com/docs/overview
  const { isLoading, error, data } = useQuery(
    WEBINAR_QUERY_KEY,
    webinarQuery,
    { staleTime: Infinity }, // only request the api routes once
  );
  const webinars = data ? data : null;

  // Uncomment this function to create KKI Sitemap on Console
  // useSiteMap();
  return { webinars, isLoading, error };
};

const useSiteMap = () => {
  const { data } = useQuery(
    ROUTES_QUERY_KEY,
    routesQuery,
    { staleTime: Infinity }, // only request the api routes once
  );
  const routes = parseRouteData(data);
  const culture = getUrlCountryPath()

  const libraryList = getLibraryList(true).map(library => `  <sitemap>
    <loc>https://www.kyowakirinhub.com/sitemap_${library.code}.xml</loc>
  </sitemap>`);

  console.log(`<?xml version="1.0" encoding="UTF-8"?>
<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${libraryList.join('\n')}
</sitemapindex>`);

  if (routes) {

    const urlPages = routes.map((item) => getSitemapItem(culture, item));

    Promise.all(urlPages).then((values) => {
      console.log(`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>https://www.kyowakirinhub.com/${culture}</loc>
  </url>
${values.sort().filter(Boolean).join('\n')}
</urlset>`);
    });
  }
}

const getSitemapItem = async (culture, item) => {
  return getAPIContent(item.contentId).then(result => {
    if (result?.elements?.contentAccess?.value?.selection !== 'c'
      && item.path !== '/contact_us'
      && item.path !== '/privacy-notice'
      && item.path !== '/terms-and-conditions') {
      return `  <url>
    <loc>https://www.kyowakirinhub.com/${culture}${item.path}</loc>
    <lastmod>${result?.lastModified}</lastmod>
  </url>`;
    }
  });
}

const useCurrentApiRoute = () => {
  const { routes } = useApiRoutes();
  const location = useLocation();
  const currentPath = location.pathname;
  return routes && routes.find((route) => {
    const match = matchPath(currentPath, {
      path: route.path,
      exact: true,
    });
    return match;
  });
};

export {
  useApiRoutes,
  useCurrentApiRoute,
  useSiteMap,
  useWebinarsQuery,
};
