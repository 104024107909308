import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/kanso/atoms/button/Button';
import styles from './Carousel.module.scss';
import { stringToCssId } from 'utils/stringUtils';

const Navigation = React.forwardRef((props, ref) => {
  const {
    slideCount,
    goPrevious,
    goNext,
    selectedIndex,
    onSelect,
    navigationStyle,
    settings,
  } = props;
  const { infinite } = settings;

  const dots = () => {
    let items = [];
    for (let i = 0; i < slideCount; i++) {
      items.push(
        <li key={i}>
          <Button
            className={
              styles[`dot${Math.abs(selectedIndex) === i ? '-active' : ''}`]
            }
            type='tertiary'
            text='•'
            onClick={() => onSelect(-i)}
          />
        </li>
      );
    }

    return items;
  };

  if (navigationStyle === 'bottom') {
    return (
      <>
        <ul className={styles['navigation']}>
          <li className={styles['prev-bottom']}>
            <Button
              type='tertiary'
              icon='chevronLeft'
              onClick={goPrevious}
              isDisabled={selectedIndex === 0 && !infinite}
            />
          </li>
          {dots()}
          <li className={styles['next-bottom']}>
            <Button
              type='tertiary'
              icon='chevronRight'
              onClick={goNext}
              isDisabled={
                Math.abs(selectedIndex) === slideCount - 1 && !infinite
              }
            />
          </li>
        </ul>
      </>
    );
  }

  return (
    <>
      <div className={styles['prev']}>
        <Button
          type='tertiary'
          icon='chevronLeft'
          onClick={goPrevious}
          isDisabled={selectedIndex === 0 && !infinite}
        />
      </div>
      <ul className={styles['navigation']}>{dots()}</ul>
      <div className={styles['next']}>
        <Button
          type='tertiary'
          icon='chevronRight'
          onClick={goNext}
          isDisabled={Math.abs(selectedIndex) === slideCount - 1 && !infinite}
        />
      </div>
    </>
  );
});

const Slider = (props) => {
  const { elements, settings, navigationStyle } = props;
  const { autoplay, autoplaySpeed, infinite, adaptiveHeight } = settings;
  const carouselContainer = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState();
  const [animated, setAnimated] = useState(true);
  const [navigationPosition, setNavigationPosition] = useState(navigationStyle);
  const shouldAutoplay = useRef(autoplay)

  useEffect(() => {
    if (carouselContainer.current) {
      carouselContainer.current.style.transition = animated
        ? 'transform 1s ease-in-out'
        : 'none';
    }
  }, [animated]);

  useEffect(() => {
    let intervalId;
    if (autoplay) intervalId = setInterval(execAutoplay, autoplaySpeed);

    return () => clearInterval(intervalId);
  }, [autoplay]);

  useEffect(() => {
    if (navigationStyle !== 'bottom')
      setNavigationPosition(carouselWidth < 768 ? 'bottom' : 'middle');
    if (currentIndex <= 0 && Math.abs(currentIndex) < elements.length) {
      carouselContainer.current.style.transform = `translateX(${
        currentIndex * carouselWidth
      }px)`;
    }

    if(adaptiveHeight) {
      const parentNode = carouselContainer.current.parentNode
      const currentChild = carouselContainer.current.childNodes[Math.abs(currentIndex)]

      parentNode.style.height = `${currentChild.clientHeight + 40}px`
    }
  }, [currentIndex, carouselWidth, navigationStyle, elements.length, adaptiveHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (carouselContainer.current) {
        setCarouselWidth(carouselContainer?.current?.offsetWidth);
        setAnimated(false);
      }
    };

    setCarouselWidth(carouselContainer?.current?.offsetWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goPrevious = (e) => {
    if (currentIndex < 0 || infinite) {
      setCurrentIndex((currentIndex) => {
        if (infinite && currentIndex === 0) return -(elements.length - 1);
        return currentIndex + 1;
      });
      setAnimated(true);
    }
  };

  const execAutoplay = () => {
    shouldAutoplay.current === true && goNext()
  }

  const goNext = (e) => {
    if (Math.abs(currentIndex) < elements.length - 1 || infinite) {
      setCurrentIndex((currentIndex) => {
        if (infinite && Math.abs(currentIndex) === elements.length - 1)
          return 0;
        return currentIndex - 1;
      });
      setAnimated(true);
    }
  };

  const selectIndex = (index) => {
    setCurrentIndex(index);
  };

  let index = 0;
  let fnl = goNext;
  let dir = -1;

  const test = () => {
    if (Math.abs(index) === elements.length - 1) {
      if (index * dir < 0) {
        fnl = goPrevious;
        dir = 1;
      } else {
        fnl = goNext;
        dir = -1;
      }
      index = 0;
    }

    fnl();
    index++;
  };

  const handleMouseEnter = (e) => {
    shouldAutoplay.current = false
  }

  const handleMouseLeave = (e) => {
    shouldAutoplay.current = true
  }

  return (
    <div className={styles['slider-container']} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div ref={carouselContainer} className={styles['carousel-items']}>
        {elements?.map((child, index) => {
          return (
            <div key={index} className={styles['slider-item']}>
              {child}
            </div>
          );
        })}
      </div>
      <Navigation
        slideCount={elements?.length}
        settings={settings}
        selectedIndex={currentIndex}
        onSelect={selectIndex}
        goPrevious={goPrevious}
        goNext={goNext}
        navigationStyle={navigationPosition}
      />
    </div>
  );
};

/**
 * Carousel Component
 * @param {string} heading           - The title of the Carousel component to be displayed.
 * @param {string} headingTag        - The heading tag wrapped around the title of Carousel component to be displayed.
 * @param {integer} navigationStyle  - The type of navigation carousel to display.
 * @param {object} settings          - The carousel settings.
 * @return React component
 */

const Carousel = (props) => {
  const { heading, headingTag, navigationStyle, settings, children } = props;

  const HeadingTag = `${headingTag}`;
  // const id = heading && stringToCssId(heading)

  return (
    <div
      className={`
        ${styles.carousel}
        `}
    >
      <HeadingTag className={styles['headline']}>{heading}</HeadingTag>
      <Slider
        elements={children}
        settings={settings}
        navigationStyle={navigationStyle}
      />
    </div>
  );
};

Carousel.propTypes = {
  heading: PropTypes.string,
  headingTag: PropTypes.string,
  headingTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  navigationStyle: PropTypes.string,
  navigationStyle: PropTypes.oneOf(['bottom', 'middle']),
  settings: PropTypes.object.isRequired,
};

Carousel.defaultProps = {
  headingTag: 'h3',
  navigationStyle: 'bottom',
};

export default Carousel;
