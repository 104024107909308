import { DATA_CAPTURE_URL } from './paths';
import { API_URL } from './paths';
import { authPostQuery } from '../AxiosQueries';
import { getEnvApiConfigValues } from 'utils/envUtils';
import { getAuthHeaderConfig } from 'utils/authUtils';

const { audience } = getEnvApiConfigValues();
const url = DATA_CAPTURE_URL;
const apiurl = API_URL;

const eventRegistration = ({body}) => {
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
  });
  return authPostQuery({ url, data: body,  config: headerConfig });
};

export { eventRegistration };
