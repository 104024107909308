import React from 'react'

import './Grid.scss'

const Grid = ({ children, itemsPerRow, gap, wrapperStyle, center }) => {
    
    if (!children) {
        return null
    }

    return (
        <div style={wrapperStyle} className={`Grid${itemsPerRow?.sm ? ` Grid--items-sm-${itemsPerRow?.sm}-gap-${gap?.sm}` : ''}${itemsPerRow?.md ? ` Grid--items-md-${itemsPerRow?.md}-gap-${gap?.md}` : ''}${itemsPerRow?.lg ? ` Grid--items-lg-${itemsPerRow?.lg}-gap-${gap?.lg}` : ''}${center ? ' Grid--items-center' : ''}`}>
            {children.map((child, index) => {
                return <div key={`Grid_Item--${index}`}>{child}</div>
            })}
        </div>
    )
}

export default Grid
