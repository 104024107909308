
import { AppConfig } from "AppConfig"
import { getUrlCountryPath } from 'utils/urlUtils';
import { groupBy } from "./arrayUtils";
import { getEnv } from "./envUtils";

// A-Z
const libraries = [
  { code: 'de-at', isoCode:'at', active: false, title: 'Austria - Deutsch', country: 'Austria', nationality: 'Austria', region: 'EMEA', libraryId: 'd332dac4-a219-4967-8789-6ce6a82cf697', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-bh', isoCode:'bh', active: true, title: 'Bahrain - English', country: 'Bahrain', nationality: 'Arabic', region: 'EMEA', libraryId: 'ba7ed98d-e84f-40c7-abd9-a0614f400766', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'nl-be', isoCode:'be', active: false, title: 'Belgium - Flemish', country: 'Belgium', nationality: 'Belgian', region: 'EMEA', libraryId: 'a317c1b5-09cc-4375-88bd-29c087d4e113', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  //{ code: 'bg-bg', isoCode:'bg', active: false, title: 'Bulgaria - Bulgarian', country: 'Bulgaria', nationality: 'Bulgarian', region: 'EMEA', libraryId: '09eb7b55-3141-4ee6-b7a6-8f73eb30606e', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  //{ code: 'hr-hr', isoCode:'hr', active: false, title: 'Croatia - Croatian', country: 'Croatia', nationality: 'Croatian', region: 'EMEA', libraryId: 'be6c9c25-45ab-4577-a6bd-f2eec2e32396', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  //{ code: 'cs-cz', isoCode:'cz', active: false, title: 'Czech Republic - Czech', country: 'Czech Republic', nationality: 'Czech', region: 'EMEA', libraryId: 'a907070b-2e2e-4c25-9694-56048c4a3e65', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'de-de', isoCode:'de', active: true, title: 'Deutschland - Deutsch', country: 'Deutschland', nationality: 'Deutsch', region: 'EMEA', libraryId: '040d342f-c78d-44c2-872c-b5f0acd07d6b', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'da-dk', isoCode:'dk', active: false, title: 'Denmark - Danish', country: 'Denmark', nationality: 'Danish', region: 'EMEA', libraryId: 'e6a32a55-06a3-4d75-96f7-25153b566e7c', gaMeasurementId: ' G-LFT4CJGY5Z', gtmMeasurementId: "GTM-NJV5HM5", whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-ie', isoCode:'ie', active: false, title: 'Ireland - English', country: 'Ireland', nationality: 'Irish', region: 'EMEA', libraryId: '4bac4ddc-7a49-4515-a021-1db41f8faeff', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'es-es', isoCode:'es', active: true, title: 'España - Español', country: 'España', nationality: 'Español', region: 'EMEA', libraryId: '064d499c-fad8-46bf-96d9-99a1c323743f', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  //{ code: 'et-ee', isoCode:'et', active: false, title: 'Estonia - Estonian', country: 'Estonia', nationality: 'Estonian', region: 'EMEA', libraryId: '5cde77ed-1dde-4d70-8eb4-b75b7ab366b5', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'fi-fi', isoCode:'fi',active: true, title: 'Finland - Finnish', country: 'Finland', nationality: 'Finns', region: 'EMEA', libraryId: '3e5dc55a-c59b-4a46-b965-544bd1f5abdb', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'fr-fr', isoCode:'fr', active: false, title: 'France - Français', country: 'France', nationality: 'Français', region: 'EMEA', libraryId: '95cc85ac-1669-4088-bcd2-c8fff18bd617', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  //{ code: 'hu-hu', isoCode:'hu',active: false, title: 'Hungary - Hungarian', country: 'Hungary', nationality: 'Hungarian', region: 'EMEA', libraryId: 'f2ffb48f-2afe-4efd-83c5-aeadccc46966', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'it-it', isoCode:'it', active: true, title: 'Italia - Italiano', country: 'Italia', nationality: 'Italiano', region: 'EMEA', libraryId: 'dd57ecba-c892-4303-b61a-ae43c17c7fbf', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-kw', isoCode:'kw', active: true, title: 'Kuwait - English', country: 'Kuwait', nationality: 'Arabic', region: 'EMEA', libraryId: '4e444b19-8466-44b0-927b-09d21c887ed0', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  //{ code: 'en-lv', isoCode:'lv', active: false, title: 'Latvia - English', country: 'Latvia', nationality: 'Latvian', region: 'EMEA', libraryId: '2dec529c-8e89-412d-91d0-aec4e917a121', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'nl-nl', isoCode:'nl', active: true, title: 'Nederland - Nederlands', country: 'Nederland', nationality: 'Nederlands', region: 'EMEA', libraryId: '0a861b00-ad92-4d19-b851-443f11c4a683', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'no-no', isoCode:'no', active: true, title: 'Norge - Norsk', country: 'Norge', nationality: 'Norsk', region: 'EMEA', libraryId: 'c8d215b7-2b92-42c7-b8bf-22ce07727798', gaMeasurementId: 'G-LFT4CJGY5Z ', gtmMeasurementId: "GTM-NJV5HM5", whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-om', isoCode:'om', active: true, title: 'Oman - English', country: 'Oman', nationality: 'Arabic', region: 'EMEA', libraryId: 'ba7ed98d-e84f-40c7-abd9-a0614f400766', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'pt-pt', isoCode:'pt', active: true, title: 'Portugal - Português', country: 'Portugal', nationality: 'Português', region: 'EMEA', libraryId: 'aa6c919e-9c89-429d-b15d-49e7f1e57a45', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-qa', isoCode:'qa', active: true, title: 'Qatar - English', country: 'Qatar', nationality: 'Arabic', region: 'EMEA', libraryId: '4e444b19-8466-44b0-927b-09d21c887ed0', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
 // { code: 'ro-ro', isoCode:'ro', active: false, title: 'Romania - Romanian', country: 'Romania', nationality: 'Romanian', region: 'EMEA', libraryId: '80f97b9e-f674-4cf8-b8b4-113d18524554', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
 //{ code: 'sk-sk', isoCode:'sk', active: false, title: 'Slovakia - Slovak', country: 'Slovakia', nationality: 'Slovak', region: 'EMEA', libraryId: 'b1e2085c-6157-49fa-a9e2-030c6af02061', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
 //{ code: 'sl-sl', isoCode:'si', active: false, title: 'Slovenia - Slovene', country: 'Slovenia', nationality: 'Slovene', region: 'EMEA', libraryId: '6173c260-6d94-4817-88c8-a61344b960d9', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'de-ch', isoCode:'ch', active: false, title: 'Switzerland - Deutsch', country: 'Switzerland', nationality: 'Swiss', region: 'EMEA', libraryId: '7fb52c0b-8bc8-4165-96df-9341813130e4', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5' , taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c'}, 
  { code: 'fr-ch', isoCode:'ch', active: false, title: 'Switzerland - Français', country: 'Switzerland', nationality: 'Swiss', region: 'EMEA', libraryId: 'd5e4002f-5953-4f6a-8ba6-0098eaa789b1', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5' , taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c'}, 
  { code: 'it-ch', isoCode:'ch', active: false, title: 'Switzerland - Italian', country: 'Switzerland', nationality: 'Swiss', region: 'EMEA', libraryId: '616189a8-33bd-4afe-a8e3-bcbd84162467', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5' , taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c'}, 
  { code: 'en-sa', isoCode:'sa', active: true, title: 'Saudi Arabia - English', country: 'Saudi Arabia', nationality: 'Arabic', region: 'EMEA', libraryId: '4e444b19-8466-44b0-927b-09d21c887ed0', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'sv-se', isoCode:'se', active: true, title: 'Sweden - Swedish', country: 'Sweden', nationality: 'Swedes', region: 'EMEA', libraryId: '2be41e4a-004a-478b-9cfb-c9d2a356001e', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-ae', isoCode:'ae', active: true, title: 'UAE - English', country: 'UAE', nationality: 'Arabic', region: 'EMEA', libraryId: '4e444b19-8466-44b0-927b-09d21c887ed0', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-gb', isoCode:'uk', active: true, title: 'United Kingdom - English', country: 'United Kingdom', nationality: 'British', region: 'EMEA', libraryId: '77d05f48-8ade-4160-be16-09a97605c322', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'events',isoCode:'uk', active: false, title: 'Events', country: 'Events Library', nationality: 'Events', region: 'EMEA', libraryId: '87b2b4e3-7728-4b31-a59f-a761950baebe', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: 'en-xx', isoCode:'xx', active: false, title: 'Sandbox - English', country: 'Sandbox', nationality: 'British', region: 'EMEA', libraryId: '7e67049b-123d-4420-94cc-ef861820c2a0', whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' }, // Sandbox library for develop/UAT only
  { code: 'en-lo', isoCode:'UK', active: false, title: 'Lorem Ipsum', country: 'Lorem Ipsum', nationality: 'British', region: 'EMEA', libraryId: 'f8ea2578-4fb4-4c26-9787-e059edfcbca0', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5", whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' },
  { code: '', isoCode:'uk', active: true, title: 'United Kingdom - English', country: 'United Kingdom', nationality: 'British', region: 'EMEA', libraryId: 'a1fb00e4-1cdf-4e9f-9cfd-e736f3c0549a', gaMeasurementId: 'G-LFT4CJGY5Z',gtmMeasurementId: "GTM-NJV5HM5",  whitelistId: 'f19b72ca-9410-41c3-9713-5b665d4ba0e5', taxonomyReg: '306ede81-0c88-422b-b979-5117ac69749a', gaTaxonomyId: 'bbc16062-4ea4-4c61-b509-126aed68fd6c' }
]

// Returns active libraries as array.
// If active parameter is false, it will return all defined libraries above.
export const getLibraryList = (active = true) => {
  if (active) {
    return libraries.filter(library => library.active === true)
  } else {
    return libraries
  }
}

// Returns active libraries as array grouped by region
// If active parameter is false, it will return all defined libraries above.
export const getLibrariesByRegion = (active = true) => {
  if (active) {
    return groupBy(libraries.filter(library => library.active === true), 'region')
  } else {
    return groupBy(libraries, 'region')
  }
}

// Returns requested library by code parameter.
export const getLibraryByCode = (code) => {
  let productionLibraries = [];
  const env = getEnv();
  productionLibraries = env === 'live' ? libraries.filter(library => library.code !== 'en-xx') : libraries;
  return productionLibraries.find(library => library.code === code)
}

// Returns the root hreflang tags from active libraries for SEO
export const getHrefLangTags = () => {
  return getLibraryList.map(library => <link rel="alternate" hreflang={library.code} href={`${AppConfig.siteUrl}/${library.code}/`} />)
}

// Returns current library according with the URL country code
export const getUrlCountryData = () => {
  const cultureCode = getUrlCountryPath();
  return getLibraryByCode(cultureCode);
}
