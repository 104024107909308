import React, {useRef} from "react";
import ReactTooltip from "react-tooltip";
import { AppConfig } from 'AppConfig';

import RichText from "components/reusableComponents/richText/RichText";
import { ConfirmAndNavigate } from 'utils/hyperlinkUtils'
import quotes from '../../../../../assets/images/infographics-assets/quotes.png';
import { toggleOverlay, closePopup } from 'components/pageTemplates/inforgraphicstb/components/helper';
import close from '../../../../../assets/images/infographics-assets/circle-close-icon.svg';
import './CallOut.scss';
import './../tooltips.scss';

const CallOut = (props) => {

    const {
		actionType,
		backgroundHexColor,
		backgroundImagePicker,
		callOutText,
		calloutStyle,
        fontColor,
        popupText,
        subtitleText,
        targetReferenceId,
        urlLinkTarget
	} = props?.calloutContent?.elements;

    let imageSource = AppConfig.acousticApi.domain;

    let quoteImg = imageSource +""+ backgroundImagePicker?.url ?? quotes;

    let toolTipBtn = useRef(null);

    const callOutModuleName = String(props?.calloutContent?.name).replace(/\s/g, '').toLowerCase() ?? "callOutModuleName";

    const callOutOnClick = (evt, actionType) => {
        evt.preventDefault();
        if (actionType === "Target Accordion") {
            if (targetReferenceId?.value !== undefined) {
                scrollTo(targetReferenceId?.value);
            }
        } else if (actionType === "Reveal Content") {
            if (targetReferenceId?.value !== undefined) {
                showHide(targetReferenceId?.value);
            }
        } else if (actionType === "Target Content") {
            if (targetReferenceId?.value !== undefined) {
                scrollTo(targetReferenceId?.value);
            }
        } else if (actionType === "Open Link") {
            if (urlLinkTarget?.linkURL !== undefined) {
                handleExternalLink(evt, urlLinkTarget?.linkURL);
            }
        }
    }

    const scrollTo = (tabID) => {
        let targetContent = document.getElementById(tabID);
        if(targetContent !== null) {
            if(!targetContent.classList.contains('open')){
                targetContent.click();
                targetContent.scrollIntoView({ behavior: 'smooth', block: 'start'});
            }
            if (actionType?.value?.selection === "Target Content") {
                targetContent.scrollIntoView({ behavior: 'smooth', block: 'start'});
            }
        }
    }

    const showHide = (contentId) => {
        document.getElementById(contentId).classList.remove("hide");
        document.getElementById(contentId).classList.add("show");
    }

    const handleExternalLink = (evt, url) => {
        const message = "You are about to leave the Kyowa Kirin site.\nAre you sure you want to continue?";
        const target = '_blank'
        ConfirmAndNavigate({
            href: url,
            message,
            target,
        })
    };

    const ctaStyle = {
        borderColor: fontColor?.value,
        color: fontColor?.value
    }

    const lozengeStyle = {
        backgroundColor: backgroundHexColor?.value,
        color: fontColor?.value
    }
    
    return (
        <>
        {
            calloutStyle?.value && calloutStyle?.value?.selection === "Lozenge" ?
            <>
            <a className="lozenge-call-out__btn" style={lozengeStyle} onClick={(event) => {callOutOnClick(event, actionType?.value?.selection)}} ref={ref => toolTipBtn = ref}  data-tip data-event='click' data-for={callOutModuleName}>
                <RichText data={callOutText} />
            </a>
            {
                actionType?.value?.selection === "Popup Text" ? 
                <ReactTooltip
                    id={callOutModuleName}
                    data-id={callOutModuleName}
                    globalEventOff='click' 
                    place="bottom" 
                    textColor='#3D3F5B' 
                    backgroundColor='#ffffff' 
                    effect='solid'
                    afterShow={(evt) => toggleOverlay('show')}
                    afterHide={(evt) => toggleOverlay('hide')}
                    wrapper='span'>
                        <div className="controlled-example">
                            <div className="controlled-example_header">
                                <img className="close" src={close} onClick={(evt) => closePopup(evt) } />
                            </div>
                            <div className="popup-content">
                                <RichText data={popupText} />
                            </div>
                        </div>
                </ReactTooltip>
                :null
            }
            </>
            : calloutStyle?.value?.selection === "Circle with Button" ? 
            <div className="circle-call-out__itemWrapper">
                <div className="circle-call-out__itemContent" style={{backgroundColor: backgroundHexColor?.value, backgroundImage: "url(" + imageSource +""+ backgroundImagePicker?.url + ")"}} >
                    <div className="circle-call-out__content" style={{color: fontColor?.value}}>
                        <RichText data={callOutText} />
                    </div>
                    <div className="circle-call-out__btn"  style={ctaStyle} onClick={(event) => {callOutOnClick(event, actionType?.value?.selection)}} ref={ref => toolTipBtn = ref}  data-tip data-event='click' data-for={callOutModuleName}>
                        <RichText data={subtitleText} />
                    </div>
                    {
                        actionType?.value?.selection === "Popup Text" ? 
                        <ReactTooltip
                            id={callOutModuleName}
                            data-id={callOutModuleName}
                            globalEventOff='click' 
                            place="bottom" 
                            textColor='#3D3F5B' 
                            backgroundColor='#ffffff' 
                            effect='solid'
                            afterShow={(evt) => toggleOverlay('show')}
                            afterHide={(evt) => toggleOverlay('hide')}
                            wrapper='span'>
                                <div className="controlled-example">
                                    <div className="controlled-example_header">
                                        <img className="close" src={close} onClick={(evt) => closePopup(evt) } />
                                    </div>
                                    <div className="popup-content">
                                        <RichText data={popupText} />
                                    </div>
                                </div>
                        </ReactTooltip>
                        :null
                    }
                </div>
            </div>
            : calloutStyle?.value?.selection === "Circle" ? 
            <div id={props?.id}>
                <div className="call-out__circle" style={{ backgroundColor: backgroundHexColor?.value }}>
                {backgroundImagePicker?.url && <span><img src={quoteImg} /></span>}
                <div className="call-out-circle__title">
                    <RichText data={callOutText} />
                </div>
                <div className="call-out-circle__subTitle" onClick={(evt) => {callOutOnClick(evt, actionType?.value?.selection)}}>
                    <RichText data={subtitleText} />
                </div>
                </div>
            </div>
            : calloutStyle?.value?.selection === "Left Slide Bar" ? 
            <div id={props?.id} className="onOffCallOut__state floatLeftCircle" style={{ backgroundColor: backgroundHexColor?.value }}>
                <div className="state" style={{ color: fontColor?.value }}><RichText data={subtitleText} /></div>
                <div className="desc" style={{ color: fontColor?.value }}>
                    <RichText data={callOutText} />
                </div>
                <div className="deco"></div>
            </div>
            : calloutStyle?.value?.selection === "Right Slide Bar" ? 
            <div  id={props?.id} className="onOffCallOut__state floatRightCircle" style={{ backgroundColor: backgroundHexColor?.value }}>
                <div className="state" style={{ color: fontColor?.value }}><RichText data={subtitleText} /></div>
                <div className="desc" style={{ color: fontColor?.value }}>
                    <RichText data={callOutText} />
                </div>
                <div className="deco"></div>
            </div>
            : calloutStyle?.value?.selection === "Rectangle" ? 
            <>
            <div className="info-container active" style={{ backgroundColor: backgroundHexColor?.value }}  ref={ref => toolTipBtn = ref}  data-tip data-event='click' data-for={callOutModuleName}  onClick={(evt) => {callOutOnClick(evt, actionType?.value?.selection)}}>
                <RichText data={callOutText} />
            </div>
            {
                actionType?.value?.selection === "Popup Text" ? 
                <ReactTooltip
                    id={callOutModuleName}
                    data-id={callOutModuleName}
                    globalEventOff='click' 
                    place="bottom" 
                    textColor='#3D3F5B' 
                    backgroundColor='#ffffff' 
                    effect='solid'
                    afterShow={(evt) => toggleOverlay('show')}
                    afterHide={(evt) => toggleOverlay('hide')}
                    wrapper='span'>
                        <div className="controlled-example">
                            <div className="controlled-example_header">
                                <img className="close" src={close} onClick={(evt) => closePopup(evt) } />
                            </div>
                            <div className="popup-content">
                                <RichText data={popupText} />
                            </div>
                        </div>
                </ReactTooltip>
                :null
            }
            </>
            : null
        }
        </>
    );
}

export default CallOut;