/* Field Types */
const INPUT = 'input';
const DROPDOWN_WEBINAR = 'webinar-dropdown';
const DROPDOWN_DEFAULT = 'default-dropdown';
const LINK = 'link';
const BUTTON = 'button';
const LINK_BUTTON = 'link-button';
const CHECKBOX = 'checkbox';
const RADIO = 'radio';
const COMPONENT = 'component';
const RADIO_BUTTTON = 'radio-eapc';
const DROPDOWN = 'dropdown';
const ROLE_DROPDOWN = 'role-dropdown';
const CHECKBOX_GROUP = 'checkbox-group';
const COMPLEX_DROPDOWN = 'complex-dropdown';
const TEXTAREA = 'textarea';

/* Field Ids */
const OPTIN_HCP = 'optinHCP';
const INFORMATION_CONSENT = 'informationConsent';


/* Form Ids */
const LOGIN = 'login';
const EXTERNAL_LOGIN = 'external-login';
const CONSENT_FORM = 'consent-form';
const REGISTER = 'register';
const FORGOTTEN_PASSWORD = 'forgotten-password';
const RESET_PASSWORD = 'reset-password';
const ACCOUNT_DETAILS = 'account-details';
const CHANGE_PASSWORD = 'change-password';
const DELETE_ACCOUNT = 'delete-account';


/* Validation Method Keys */
const SHOULD_HAVE_VALUE = 'should-have-value';
const SHOULD_MATCH_FIELD = 'should-match-field';
const SHOULD_BE_EMAIL_FORMAT = 'should-be-email-format';
const MIN_LEN = 'min-len';
const SHOULD_CONTAIN_NUMBER = 'should-contain-number';
const SHOULD_CONTAIN_ONLY_NUMBER = 'should-contain-only-number';
const SHOULD_CONTAIN_UPPER = 'should-contain-upper';
const SHOULD_CONTAIN_LOWER = 'should-contain-lower';
const SHOULD_CONTAIN_SPECIAL = 'should-contain-lower';
const SHOULD_BE_PROFESSIONAL_EMAIL = 'should-be-professional-email';
const SHOULD_ACCEPT_CHECKBOX = 'should-accept-checkbox';
const SHOULD_ACCEPT_CONSENT = 'should-accept-consent';
const SHOULD_HAVE_VALUE_RADIO = 'should-have-value-radio';





/* Common validation */
const commonShouldHaveValue = {
  validationMethodKey: SHOULD_HAVE_VALUE,
  errorText: 'This field is required',
};

const commonShouldBeEmailFormat = {
  validationMethodKey: SHOULD_BE_EMAIL_FORMAT,
  errorText: 'Invalid email',
};

const commonMinLen = {
  validationMethodKey: MIN_LEN,
  minLength: 7,
  getErrorText: (minLength) => `Must contain ${minLength + 1} characters`,
};

const commonShouldContainNumber = {
  validationMethodKey: SHOULD_CONTAIN_NUMBER,
  errorText: 'Must contain at least 1 number',
};

const commonShouldContainOnlyNumber = {
  validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
  errorText: "Must contain only numbers",
};

const commonShouldContainUpper = {
  validationMethodKey: SHOULD_CONTAIN_UPPER,
  errorText: 'Must contain at least 1 upper case letter',
};

const commonShouldContainLower = {
  validationMethodKey: SHOULD_CONTAIN_LOWER,
  errorText: 'Must contain at least 1 lower case letter',
};

const commonShouldContainSpecial = {
  validationMethodKey: SHOULD_CONTAIN_SPECIAL,
  errorText: 'Must contain at least 1 special character',
};

const commonShouldBeProfessionalEmail = {
  validationMethodKey: SHOULD_BE_PROFESSIONAL_EMAIL,
  errorText: 'Please enter your professional email',
};

const commonShouldAcceptConsent = {
  validationMethodKey: SHOULD_ACCEPT_CONSENT,
  errorText: 'This field is required',
};

const commonShouldHaveValueRadio = {
  validationMethodKey: SHOULD_HAVE_VALUE_RADIO,
  errorText: 'Please select either "Yes" or "No"',
}

const commonShouldAcceptCheckbox = {
  validationMethodKey: SHOULD_ACCEPT_CHECKBOX,
  errorText: 'Must accept before continuing'
}

/* Common validations */
const commonEmailValidations = [
  commonShouldBeEmailFormat,
  commonShouldHaveValue,
  commonShouldBeProfessionalEmail,
];

const commonNewPasswordValidations = [
  commonMinLen,
  commonShouldContainNumber,
  commonShouldContainUpper,
  commonShouldContainLower,
  commonShouldContainSpecial,
  commonShouldHaveValue,
];

/* Form Server Response Error Keys */
const CODE_400 = 400;
const CODE_401 = 401;
const CODE_404 = 404;
const CODE_406 = 406;
const CODE_415 = 415;
const CODE_422 = 422;

const errorCodesWithMessage = [
  CODE_400,
  CODE_401,
  CODE_404,
  CODE_406,
  CODE_415,
  CODE_422,
];

/* Internal constant for any error we don't have a specific message for */
const UNEXPECTED_ERROR = 'unexpected-error';

export {
  /* Field Types */
  INPUT,
  LINK,
  DROPDOWN_DEFAULT,
  ROLE_DROPDOWN,
  BUTTON,
  LINK_BUTTON,
  CHECKBOX,
  DROPDOWN_WEBINAR,
  COMPONENT,
  RADIO,
  /* Field Ids */
  OPTIN_HCP,
  INFORMATION_CONSENT,
  /* Form Ids */
  LOGIN,
  EXTERNAL_LOGIN,
  CONSENT_FORM,
  REGISTER,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  ACCOUNT_DETAILS,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  /* Validation Method Keys */
  SHOULD_HAVE_VALUE,
  SHOULD_MATCH_FIELD,
  SHOULD_BE_EMAIL_FORMAT,
  MIN_LEN,
  SHOULD_CONTAIN_NUMBER,
  SHOULD_CONTAIN_UPPER,
  SHOULD_CONTAIN_LOWER,
  SHOULD_CONTAIN_SPECIAL,
  SHOULD_BE_PROFESSIONAL_EMAIL,
  SHOULD_CONTAIN_ONLY_NUMBER,
  SHOULD_ACCEPT_CHECKBOX,
  SHOULD_ACCEPT_CONSENT,
  SHOULD_HAVE_VALUE_RADIO,
  /* Common validation */
  commonShouldHaveValue,
  commonShouldBeEmailFormat,
  /* Common Validations */
  commonEmailValidations,
  commonNewPasswordValidations,
  commonShouldContainOnlyNumber,
  commonShouldAcceptCheckbox,
  commonShouldAcceptConsent,
  commonShouldHaveValueRadio,
  /* Form API Response Error Keys */
  CODE_400,
  CODE_401,
  CODE_404,
  CODE_406,
  CODE_415,
  CODE_422,
  UNEXPECTED_ERROR,
  errorCodesWithMessage,
  RADIO_BUTTTON,
  DROPDOWN,
  CHECKBOX_GROUP,
  TEXTAREA,
  COMPLEX_DROPDOWN,
};
