import React from 'react';
import { useHistory } from 'react-router-dom';
import useAppendAuthParams from 'hooks/useAppendAuthParams'
import PropTypes from 'prop-types';
import { childrenPropTypes } from 'propTypes/generic';

// Component that has the same functionality as Link from react router. Might be worth replacing this with
// with actual links to make it more accibility friendly and allowing opening links in new tabs
const LinkButton = ({
  to,
  className,
  children,
  style,
  disabled,
  vvClickAttr,
}) => {
  const history = useHistory();

  const { value: href } = useAppendAuthParams(to ?? " ")

  // on click, navigate to the passed in route
  const handleClick = () => history.push(href);
  
  return (
    <button
      type="button"
      className={`${className} custom-focus`}
      onClick={handleClick}
      style={style}
      disabled={disabled}
      data-vv-action={vvClickAttr}
    >
      {children}
    </button>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string,
  children: childrenPropTypes,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  vvClickAttr: PropTypes.string,
};

export { LinkButton };
