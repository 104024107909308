import RichText from 'components/reusableComponents/richText/RichText';
import React,{useEffect,useState} from 'react';

import './CalloutBullets.scss';
import parse from 'react-html-parser';


const CalloutBullets = (props) => {
    const {
			backgroundColor,
			displayWidth,
			infoText,
			fontColour,
			columnOneText,
			columnTwoText,
			columnThreeText,
			referenceId,
			isVisible
    } = props?.element.elements
	let hidden = isVisible?.value;
	let reference = referenceId?.value ;	
	let index= props?.index +1

		let backgroundColorValue = backgroundColor?.value 
		if((backgroundColorValue?.indexOf("#") == -1)){
			backgroundColorValue = "#"+backgroundColor?.value 
	
		}
		let foregroundColorValue = fontColour?.value 
		if((foregroundColorValue?.indexOf("#") == -1)){
			foregroundColorValue = "#"+fontColour?.value 
	
		}
		const displayWidthValue= displayWidth?.value?.selection
		// const infoTextValue = infoText?.value

		// let ul = new DOMParser().parseFromString(infoTextValue, 'text/html').body;
	

		// let arrayUlThreeInColumn = new Array();
		// let arrayUlTwoInColumn = new Array();
		// let arrayUlOneInColumn =new Array(); 
		// let fullWidth = new Array();
		// for(let i = 0; i<ul.childNodes.length ;i++){
		// 	{for(let li of ul.childNodes[i].childNodes){
		// 		// console.log(li)
		// 		if((displayWidthValue==="full-width")||(infoText?.value !=null)){
		// 			fullWidth.push(li)
		// 		}
		// 		if(displayWidthValue == "3-column") {
		// 			arrayUlThreeInColumn.push(li)
		// 		} 
		// 		if(displayWidthValue == "2-column") {
		// 			arrayUlTwoInColumn.push(li)
		// 		} 
		// 		if(displayWidthValue == "1-column") {
		// 			arrayUlOneInColumn.push(li)
		// 		} 
		// 			// console.log(li)
					
		// 	}}
		// }
		
    return (
		<div id ={reference} className={hidden === true? "hide" : "show"}>
		<div id="calloutBullets">
			{displayWidthValue === "3-column" ? 
				<div id="mdt-section-members" className="mdt-section-members mdt-main-section"style={{color:foregroundColorValue}}>
					<div className="mdt-section-members-content__wrapper" style={{ backgroundColor: backgroundColorValue }}>
						<div className="mdt-section-members-content__item"><RichText data={columnOneText}/></div>
						<div className="mdt-section-members-content__item"><RichText data={columnTwoText}/></div>
						<div className="mdt-section-members-content__item"><RichText data={columnThreeText}/></div>
					</div>
				</div> : null
			}
			
			{displayWidthValue === "1-column"?
				<div id="mdt-section-members" className="mdt-section-members mdt-main-section containerOneColumn"style={{color:foregroundColorValue}} >
					<div className="mdt-section-members-content__wrapper" style={{ backgroundColor: backgroundColorValue }}>
						<div className="mdt-section-members-content__item"><RichText data={columnOneText}/></div> 
					</div>
				</div> : null
			}
			{displayWidthValue === "2-column" ? 
				<div id="mdt-section-members" className="mdt-section-members mdt-main-section containerTwoColumn" style={{color:foregroundColorValue}}>
					<div className="mdt-section-members-content__wrapper" style={{ backgroundColor: backgroundColorValue,  }}>
						<div className="mdt-section-members-content__item"><RichText data={columnOneText}/></div>
						<div className="mdt-section-members-content__item"><RichText data={columnTwoText}/></div>
					</div>
				</div> : null
			}
			{
				displayWidthValue==="full-width"? 
				<div id="mdt-section-members" className="mdt-section-members mdt-main-section containerOneColumn"style={{color:foregroundColorValue}} >
					<div className="mdt-section-members-content__wrapper" style={{ backgroundColor: backgroundColorValue }}>
							<div className="mdt-section-members-content__item"><RichText data={infoText}/></div>
					</div>
				</div> : null
			}
		</div>
		</div>	
    )
}

export default CalloutBullets;