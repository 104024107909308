import React, { createRef, forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

// components
import Card from '../../components/reusableComponents/card/Card'
import CardContent from '../../components/reusableComponents/card/components/cardContent/CardContent'
import CardImage from '../../components/reusableComponents/card/components/cardImage/CardImage'
import CardCategory from '../../components/reusableComponents/card/components/cardCategory/CardCategory'
import CardHeading from '../../components/reusableComponents/card/components/cardHeading/CardHeading'
import CardRichText from '../../components/reusableComponents/card/components/cardRichText/CardRichText'
import CardLink from '../../components/reusableComponents/card/components/cardLink/CardLink'
import CardDate from 'components/reusableComponents/card/components/cardDate/cardDate'
// styles
import './ContentCard.scss'

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const ContentCard = forwardRef(({ bgImage, brandLogo, category, heading, icon, content, href, alternateBg, bgColor, maxWidth, theme, fluid, clickable, removeShadow, linkText, center, date, links, section, ...rest }, ref) => {
    const cardRef = ref?.cardRef || createRef()

    const [hasBgImage, setHasBgImage] = useState(false)
    const { redesign } = useSelector(state => state.ui);
    const { trackGTMEventWithItemId } = useGoogleAnalytics();
    useEffect(() => {
        if (bgImage) {
            setHasBgImage(Boolean(bgImage))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bgImage])
    const handleClick = (url) => {
        if(clickable && section) {
            trackGTMEventWithItemId('therapyarea_selected', 'homepage_tile', section, url)
        }
    }
    const arrayLink = Array.isArray(links?.values)  ? links?.values : null;
    let ishref = arrayLink?.length > 0 ? arrayLink[0]?.linkURL : null;
    if(href){
        ishref = href;
    }
    if(redesign) {
        if (fluid) {
            return (
                <Card ref={cardRef} actionPdf="click" snapshotPdf="after" onClick={() => handleClick(ishref)} className={`ContentCard ContentCard--fluid${center ? ' ContentCard--center' : ''}`} alternateBg={alternateBg} bgColor={bgColor} hasBgImage={hasBgImage}  href={clickable && (!arrayLink || arrayLink?.length == 1) ? ishref : null} removeShadow={removeShadow} {...rest}>
                    <CardContent className='CardContent'>
                        <CardCategory category={category} theme={theme} withIcon fluidStyle />
                        <CardImage brandLogo={brandLogo} />
                        <CardHeading heading={heading} />
                        <CardRichText content={content} truncate/>
                        <CardDate date={date} />
                        {
                            arrayLink ? arrayLink?.map((elements)=>{
                                const {
                                    linkText,
                                    linkURL
                                } = elements;
                               return <CardLink text={linkText} href={clickable ? linkURL : null} onClick={() => handleClick(linkURL)} theme={theme} vvClickAttr='click' />
                            }) :
                            <CardLink text={linkText} href={clickable ? href : null} onClick={() => handleClick(href)} theme={theme} vvClickAttr='click' />
                        }
                    </CardContent>
                    <CardImage bgImage={bgImage} />
                </Card>
            )
        }
    
        return (
            <Card ref={cardRef} className={`TitleCard ${center ? 'TitleCard--center' : ''}`} onClick={() => handleClick(ishref)} alternateBg={alternateBg} bgColor={bgColor} maxWidth={maxWidth} href={clickable && (!arrayLink || arrayLink?.length == 1) ? ishref : null} removeShadow={removeShadow} {...rest}>
                <CardImage bgImage={bgImage} />
                <CardContent className='CardContent'>
                    <CardCategory category={category} theme={theme} withIcon />
                    <CardImage brandLogo={brandLogo} />
                    <CardHeading heading={heading} icon={icon} />
                    <CardRichText content={content} />
                    <CardDate date={date} />
                    {
                        arrayLink ? arrayLink?.map((elements)=>{
                            const {
                                linkText,
                                linkURL
                            } = elements;
                           return <CardLink text={linkText} href={clickable ? linkURL : null} onClick={() => handleClick(linkURL)} theme={theme} vvClickAttr='click' />
                        }) :
                        <CardLink text={linkText} href={clickable ? href : null} onClick={() => handleClick(href)} theme={theme} vvClickAttr='click' />
                    }
                </CardContent>
            </Card>
        )
    }
    if (fluid) {
        return (
            <Card ref={cardRef} actionPdf="click" snapshotPdf="after" onClick={() => handleClick(ishref)} className={`ContentCard ContentCard--fluid${center ? ' ContentCard--center' : ''}`} alternateBg={alternateBg} bgColor={bgColor} hasBgImage={hasBgImage} href={clickable && (!arrayLink || arrayLink?.length == 1) ? ishref : null} removeShadow={removeShadow} {...rest}>
                <CardContent className='CardContent'>
                    <CardCategory category={category} theme={theme} withIcon fluidStyle />
                    <CardImage brandLogo={brandLogo} />
                    <CardHeading heading={heading} />
                    <CardRichText content={content} truncate/>
                    <CardDate date={date} />
                    {
                        arrayLink ? arrayLink?.map((elements)=>{
                            const {
                                linkText,
                                linkURL
                            } = elements;
                           return <CardLink text={linkText} href={clickable ? linkURL : null} onClick={() => handleClick(linkURL)} theme={theme} vvClickAttr='click' />
                        }) :
                        <CardLink text={linkText} href={clickable ? href : null} onClick={() => handleClick(href)} theme={theme} vvClickAttr='click' />
                    }
                </CardContent>
                <CardImage bgImage={bgImage} />
            </Card>
        )
    }

    return (
        <Card ref={cardRef} className={`ContentCard${center ? ' ContentCard--center' : ''}`} alternateBg={alternateBg} onClick={() => handleClick(ishref)} bgColor={bgColor} maxWidth={maxWidth} href={clickable && (!arrayLink || arrayLink?.length == 1) ? ishref : null} removeShadow={removeShadow} {...rest}>
            <CardContent className='CardContent'>
                <CardCategory category={category} theme={theme} withIcon />
                <CardImage brandLogo={brandLogo} />
                <CardHeading heading={heading} icon={icon} />
                <CardRichText content={content} />
                <CardDate date={date} />
                {
                    arrayLink ? arrayLink?.map((elements)=>{
                        const {
                            linkText,
                            linkURL
                        } = elements;
                       return <CardLink text={linkText} href={clickable ? linkURL : null} onClick={() => handleClick(linkURL)} theme={theme} vvClickAttr='click' />
                    }) :
                    <CardLink text={linkText} href={clickable ? href : null} onClick={() => handleClick(href)} theme={theme} vvClickAttr='click' />
                }
            </CardContent>
        </Card>
    )
})

export default ContentCard
