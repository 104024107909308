import React, { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { getCourses } from 'api/dynamoApi';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import RichText from 'components/reusableComponents/richText/RichText';
import WebinarHubCardImage from 'components/shared/WebinarHubCardImage';
import Pagination from '@material-ui/lab/Pagination';
import Switch from 'components/shared/form/Switch';

//scss
import './WebinarTab.scss';

const WebinarTab = ({ tabId, tabActive, section, listOfWebinars }) => {
  const { resources, sectionDescription, sectionTitle } = section?.elements;
  const {
    pastWebinarsLabel,
    learnMoreLabel,
    numberOfCardPerPage,
    registeredLabel,
    removeLabel,
    switchLabel,
    displayPromotionalWebinars,
  } = resources?.value?.elements;

  const { trackWebinarPromoToggle } = useGoogleAnalytics();

  /**
   * State for changing tabs
   * This is set before any checks because useState must be declared before any conditional rendering
   * this implementation needs refactoring which has a big risk and needs thorough testing
   */
  const [allRegisteredWebinars, setAllRegisteredWebinars] = useState();
  const [promotional, setPromotional] = useState(false);
  const [pastWebinarsTab, setPastWebinarsTab] = useState(false);
  //all webinars
  const [pageOngoing, setPageOngoing] = useState(parseInt(1));
  const [pageOngoingPromo, setPageOngoingPromo] = useState(parseInt(1));

  const [pageCountOngoing, setPageCountOngoing] = useState(parseInt(0));
  const [pageCountOngoingPromo, setPageCountOngoingPromo] = useState(parseInt(0));

  const [ongoingCards, setOngoingCards] = useState();
  const [ongoingCardsPromo, setOngoingCardsPromo] = useState();

  const [webinarOngoing, setWebinarOngoing] = useState([]);
  const [webinarOngoingPromo, setWebinarOngoingPromo] = useState([]);

  //past webinars
  const [pagePast, setPagePast] = useState(parseInt(1));
  const [pagePastPromo, setPagePastPromo] = useState(parseInt(1));

  const [pageCountPast, setPageCountPast] = useState(parseInt(0));
  const [pageCountPastPromo, setPageCountPastPromo] = useState(parseInt(0));

  const [pastCards, setPastCards] = useState();
  const [pastCardsPromo, setPastCardsPromo] = useState();

  const [webinarPast, setWebinarPast] = useState([]);
  const [webinarPastPromo, setWebinarPastPromo] = useState([]);

  const [isFetching, setIsFetching] = useState(false);

  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;
  const { token } = data?.token;
  const cardsPerPage = numberOfCardPerPage?.value
    ? numberOfCardPerPage?.value
    : 10;
  /**
   * set variables for ongoing tab
   */
  let quotientOngoing = 0;
  let remainderOngoing = 0;
  let ongoingCoursesArray = [];

  let quotientOngoingPromo = 0;
  let remainderOngoingPromo = 0;
  let ongoingPromoCoursesArray = [];

  let quotientPast = 0;
  let remainderPast = 0;
  let pastCoursesArray = [];

  let quotientPastPromo = 0;
  let remainderPastPromo = 0;
  let pastPromoCoursesArray = [];
  

   /**
   * set variables for past webinars tab
   */

  useEffect(() => {
    if (listOfWebinars) {
      if (!isFetching) {
        setIsFetching(true);
        let type = "webinar"; 
        getCourses({ userId, token, type })
          .then((response) => {
            if (response.status === 200) {
              setAllRegisteredWebinars(response?.data);
              response.data.map((course) => {
                let webinar = listOfWebinars.data.documents.filter((item) => {
                  if (item?.document?.id) {
                    if(course?.data?.externalId){
                      const webinarsIds = course?.data?.externalId.split("|");
                      if(webinarsIds && webinarsIds.length > 0) {
                        return item?.document?.id === webinarsIds[0];
                      }
                    }
                  }
                });
                if (course.type === 'webinar' && webinar && webinar[0]) {
                  let webinarOngoingPromoArray = webinarOngoingPromo;
                  let webinarPastPromoArray = webinarPastPromo;
                  let webinarDate = webinar[0]?.document?.elements?.webinarDate?.value;
                  let webinarEndDate = webinar[0]?.document?.elements?.webinarEndDate?.value;
                  if(webinarEndDate){
                    if ((new Date(new Date(webinarEndDate).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime()) {
                      webinarPastPromoArray.push(webinar[0]);
                    }
                  } else {
                    if ((new Date(new Date(webinarDate).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime()) {
                      webinarPastPromoArray.push(webinar[0]);
                    }
                  }
                  webinarOngoingPromoArray.push(webinar[0]);
                  setWebinarOngoingPromo(webinarOngoingPromoArray);
                  setWebinarPastPromo(webinarPastPromoArray);
                  if (webinar[0]?.boolean1) {
                    if (!webinar[0]?.boolean1[0]) {
                      let webinarOngoingArray = webinarOngoing;
                      let webinarPastArray = webinarPast;
                      webinarOngoingArray.push(webinar[0]);
                      setWebinarOngoing(webinarOngoingArray);
                      if(webinarEndDate){
                        if ((new Date(new Date(webinarEndDate).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime()) {
                          webinarPastArray.push(webinar[0]);
                        }
                      } else {
                        if ((new Date(new Date(webinarDate).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime()) {
                          webinarPastArray.push(webinar[0]);
                        }
                      }
                      setWebinarPast(webinarPastArray);
                    }
                  } else {
                    let webinarOngoingArray = webinarOngoing;
                    let webinarPastArray = webinarPast;
                    webinarOngoingArray.push(webinar[0]);
                    setWebinarOngoing(webinarOngoingArray);
                    if(webinarEndDate){
                      if ((new Date(new Date(webinarEndDate).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime()) {
                        webinarPastArray.push(webinar[0]);
                      }
                    } else {
                      if ((new Date(new Date(webinarDate).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime()) {
                        webinarPastArray.push(webinar[0]);
                      }
                    }
                    setWebinarPast(webinarPastArray);
                  }
                }
              });
              /**
               * webinarOngoing
               */
              let ongoingSortedArray = webinarOngoing;
              ongoingSortedArray.sort(function (a, b) {
                return new Date(b?.document?.elements?.webinarDate?.value) - new Date(a?.document?.elements?.webinarDate?.value);
              });

              setWebinarOngoing(ongoingSortedArray);

              quotientOngoing = Math.floor(
                webinarOngoing.length / cardsPerPage
              );
              remainderOngoing = webinarOngoing.length % cardsPerPage;
              let pageCountOngoingValue =
                remainderOngoing > 0 ? quotientOngoing + 1 : quotientOngoing;
              setPageCountOngoing(pageCountOngoingValue);
              ongoingCoursesArray = webinarOngoing
                ? webinarOngoing.slice(0, cardsPerPage)
                : [];

              setOngoingCards(ongoingCoursesArray);

              /**
               * webinarPast
               */
              let pastSortedArray = webinarPast;
              pastSortedArray.sort(function (a, b) {
                return new Date(b?.document?.elements?.webinarDate?.value) - new Date(a?.document?.elements?.webinarDate?.value);
              });
 
              setWebinarPast(pastSortedArray);
 
              quotientPast = Math.floor(
                webinarPast.length / cardsPerPage
              );
              remainderPast = webinarPast.length % cardsPerPage;
              let pageCountPastValue =
                remainderPast > 0 ? quotientPast + 1 : quotientPast;
              
              setPageCountPast(pageCountPastValue);
              pastCoursesArray = webinarPast
                ? webinarPast.slice(0, cardsPerPage)
                : [];

              setPastCards(pastCoursesArray);
              /**
               * webinarOngoingPromo
               */
              let completedSortedArray = webinarOngoingPromo;

              completedSortedArray.sort(function (a, b) {
                return new Date(b?.document?.elements?.webinarDate?.value) - new Date(a?.document?.elements?.webinarDate?.value);
              });

              setWebinarOngoingPromo(completedSortedArray);

              quotientOngoingPromo = Math.floor(
                webinarOngoingPromo.length / cardsPerPage
              );
              remainderOngoingPromo = webinarOngoingPromo.length % cardsPerPage;
              let pageCountOngoingPromoValue =
                remainderOngoingPromo > 0
                  ? quotientOngoingPromo + 1
                  : quotientOngoingPromo;
              setPageCountOngoingPromo(pageCountOngoingPromoValue);
              ongoingPromoCoursesArray = webinarOngoingPromo
                ? webinarOngoingPromo.slice(0, cardsPerPage)
                : [];
              setOngoingCardsPromo(ongoingPromoCoursesArray);

              /**
               * webinarPastPromo
               */
              let pastPromoSortedArray = webinarPastPromo;
              pastPromoSortedArray.sort(function (a, b) {
                return new Date(b?.document?.elements?.webinarDate?.value) - new Date(a?.document?.elements?.webinarDate?.value);
              });

              setWebinarPastPromo(pastPromoSortedArray);

              quotientPastPromo = Math.floor(
                webinarPastPromo.length / cardsPerPage
              );
              remainderPastPromo = webinarPastPromo.length % cardsPerPage;
              let pageCountPastPromoValue =
                remainderPastPromo > 0 ? quotientPastPromo + 1 : quotientPastPromo;
              
              setPageCountPastPromo(pageCountPastPromoValue);
              pastPromoCoursesArray = webinarPastPromo
                ? webinarPastPromo.slice(0, cardsPerPage)
                : [];

              setPastCardsPromo(pastPromoCoursesArray);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [listOfWebinars]);

  const removeCourse = (id) => {
    //ongoing
    let ongoingCardsArray1 = webinarOngoing.filter(
      (item) => item?.document?.id != id
    );
    quotientOngoing = Math.floor(ongoingCardsArray1.length / cardsPerPage);
    remainderOngoing = ongoingCardsArray1.length % cardsPerPage;

    let pageCountOngoingValue =
      remainderOngoing > 0 ? quotientOngoing + 1 : quotientOngoing;
    setPageCountOngoing(pageCountOngoingValue);
    setWebinarOngoing(ongoingCardsArray1);

    let remainder1 = ongoingCardsArray1.length % cardsPerPage;
    let pageNum1 = pageOngoing;
    if (remainder1 === 0 && ongoingCardsArray1.length > 0 && (pageOngoing - 1 ) === pageCountOngoingValue) {
      pageNum1 = pageOngoing - 1;
      if (pageNum1 > 0) {
        setPageOngoing(pageNum1);
      }
    }
    if (pageNum1 === 1) {
      let ongoingCoursesArray = ongoingCardsArray1
        ? ongoingCardsArray1.slice(0, cardsPerPage)
        : [];
      setOngoingCards(ongoingCoursesArray);
    } else if (pageNum1 > 1) {
      let ongoingCoursesArray = ongoingCardsArray1
        ? ongoingCardsArray1.slice(
            cardsPerPage * (pageNum1 - 1),
            cardsPerPage * pageNum1
          )
        : [];
      setOngoingCards(ongoingCoursesArray);
    }
    //ongoing promo
    let ongoingCardsPromoArray1 = webinarOngoingPromo.filter(
      (item) => item?.document?.id != id
    );
    quotientOngoingPromo = Math.floor(ongoingCardsPromoArray1.length / cardsPerPage);
    remainderOngoingPromo = ongoingCardsPromoArray1.length % cardsPerPage;

    let pageCountOngoingPromoValue =
      remainderOngoingPromo > 0 ? quotientOngoingPromo + 1 : quotientOngoingPromo;
    setPageCountOngoingPromo(pageCountOngoingPromoValue);
    setWebinarOngoingPromo(ongoingCardsPromoArray1);

    let remainder2 = ongoingCardsPromoArray1.length % cardsPerPage;
    let pageNum2 = pageOngoingPromo;
    if (remainder2 === 0 && ongoingCardsPromoArray1.length > 0 && (pageOngoingPromo - 1 ) === pageCountOngoingPromoValue) {
      pageNum2 = pageOngoingPromo - 1;
      if (pageNum2 > 0) {
        setPageOngoingPromo(pageNum2);
      }
    }

    if (pageNum2 === 1) {
      let ongoingPromoCoursesArray = ongoingCardsPromoArray1
        ? ongoingCardsPromoArray1.slice(0, cardsPerPage)
        : [];
      setOngoingCardsPromo(ongoingPromoCoursesArray);
    } else if (pageNum2 > 1) {
      let ongoingPromoCoursesArray = ongoingCardsPromoArray1
        ? ongoingCardsPromoArray1.slice(
            cardsPerPage * (pageNum2 - 1),
            cardsPerPage * pageNum2
          )
        : [];
      setOngoingCardsPromo(ongoingPromoCoursesArray);
    }
    setWebinarOngoingPromo(ongoingCardsPromoArray1);

    //past
    let pastCardsArray1 = webinarPast.filter(
      (item) => item?.document?.id != id
    );
    quotientPast = Math.floor(pastCardsArray1.length / cardsPerPage);
    remainderPast = pastCardsArray1.length % cardsPerPage;

    let pageCountPastValue =
      remainderOngoing > 0 ? quotientPast + 1 : quotientPast;
    setPageCountPast(pageCountPastValue);
    setWebinarPast(pastCardsArray1);
    let remainder1Past = pastCardsArray1.length % cardsPerPage;
    let pageNumPast = pagePast;
    if (remainder1Past === 0 && pastCardsArray1.length > 0 && (pagePast - 1 ) === pageCountPastValue) {
      pageNumPast = pagePast - 1;
      if (pageNumPast > 0) {
        setPagePast(pageNumPast);
      }
    }

    if (pageNumPast === 1) {
      let pastCoursesArray = pastCardsArray1
        ? pastCardsArray1.slice(0, cardsPerPage)
        : [];
      setPastCards(pastCoursesArray);
    } else if (pageNumPast > 1) {
      let pastCoursesArray = pastCardsArray1
        ? pastCardsArray1.slice(
            cardsPerPage * (pageNumPast - 1),
            cardsPerPage * pageNumPast
          )
        : [];
      setPastCards(pastCoursesArray);
    }

    //past promo
    let pastCardsPromoArray1 = webinarPastPromo.filter(
      (item) => item?.document?.id != id
    );
    quotientPastPromo = Math.floor(pastCardsPromoArray1.length / cardsPerPage);
    remainderPastPromo = pastCardsPromoArray1.length % cardsPerPage;

    let pageCountPastPromoValue =
      remainderPastPromo > 0 ? quotientPastPromo + 1 : quotientPastPromo;
    setPageCountPastPromo(pageCountPastPromoValue);
    setWebinarPastPromo(pastCardsPromoArray1);

    let remainderPast2 = pastCardsPromoArray1.length % cardsPerPage;
    let pageNumPast2 = pagePastPromo;
    if (remainderPast2 === 0 && pastCardsPromoArray1.length > 0 && (pagePastPromo - 1 ) === pageCountPastPromoValue) {
      pageNumPast2 = pagePastPromo - 1;
      if (pageNumPast2 > 0) {
        setPagePastPromo(pageNumPast2);
      }
    }

    if (pageNumPast2 === 1) {
      let pastPromoCoursesArray = pastCardsPromoArray1
        ? pastCardsPromoArray1.slice(0, cardsPerPage)
        : [];
      setPastCardsPromo(pastPromoCoursesArray);
    } else if (pageNumPast2 > 0) {
      let pastPromoCoursesArray = pastCardsPromoArray1
        ? pastCardsPromoArray1.slice(
            cardsPerPage * (pageNumPast2 - 1),
            cardsPerPage * pageNumPast2
          )
        : [];
      setPastCardsPromo(pastPromoCoursesArray);
    }
    setWebinarPastPromo(pastCardsPromoArray1);
  };

  const webinarCardsRef = useRef(null);
  const executeScroll = () => webinarCardsRef.current.scrollIntoView();
  function handleChange(event, value, type) {
    executeScroll();
    if (type === 'ongoing') {
      setPageOngoing(value);
      if (value === 1) {
        let ongoingCoursesArray = webinarOngoing
          ? webinarOngoing.slice(0, cardsPerPage)
          : [];

        setOngoingCards(ongoingCoursesArray);
      } else if (value > 1) {
        let ongoingCoursesArray = webinarOngoing
          ? webinarOngoing.slice(
              cardsPerPage * (value - 1),
              cardsPerPage * value
            )
          : [];
        setOngoingCards(ongoingCoursesArray);
      }
    }
    if (type === 'past') {
      setPagePast(value);
      if (value === 1) {
        let pastWebinarsArray = webinarPast
          ? webinarPast.slice(0, cardsPerPage)
          : [];

        setPastCards(pastWebinarsArray);
      } else if (value > 1) {
        let pastWebinarsArray = webinarPast
          ? webinarPast.slice(
              cardsPerPage * (value - 1),
              cardsPerPage * value
            )
          : [];
        setPastCards(pastWebinarsArray);
      }
    }
    if (type === 'ongoingPromo') {
      setPageOngoingPromo(value);
      if (value === 1) {
        let completedCoursesArray = ongoingCardsPromo
          ? webinarOngoingPromo.slice(0, cardsPerPage)
          : [];
        setOngoingCardsPromo(completedCoursesArray);
      } else if (value > 0) {
        let completedCoursesArray = ongoingCardsPromo
          ? webinarOngoingPromo.slice(
              cardsPerPage * (value - 1),
              cardsPerPage * value
            )
          : [];
        setOngoingCardsPromo(completedCoursesArray);
      }
    }
    if (type === 'pastPromo') {
      setPagePastPromo(value);
      if (value === 1) {
        let pastPromoWebinarsArray = webinarPastPromo
          ? webinarPastPromo.slice(0, cardsPerPage)
          : [];

        setPastCardsPromo(pastPromoWebinarsArray);
      } else if (value > 1) {
        let pastPromoWebinarsArray = webinarPastPromo
          ? webinarPastPromo.slice(
              cardsPerPage * (value - 1),
              cardsPerPage * value
            )
          : [];
        setPastCardsPromo(pastPromoWebinarsArray);
      }
    }
  }

  const onChange = () => {
    setPromotional(!promotional);
    trackWebinarPromoToggle(!promotional);
  };

  if (tabId !== tabActive) {
    return '';
  }

  return (
    <div className='my-hub-webinars'>
      <h3 className='my-hub-webinars__title'>{sectionTitle?.value}</h3>
      <div className='my-hub-webinars__desc'>
        <RichText data={sectionDescription} />
      </div>
      {displayPromotionalWebinars?.value && (
        <div className='webinar-switch'>
          <Switch
            label={
              switchLabel?.value
                ? switchLabel?.value
                : 'View promotional content'
            }
            name='switch'
            value={promotional}
            field={{ onChange }}
          />
        </div>
      )}

      <div ref={webinarCardsRef} className='my-hub-webinars__cards'>
        <div className='webinar-labels'>
          <div className={`webinar-card-count${pastWebinarsTab ? "" : " webinar-card-count--active"}`} onClick={() => setPastWebinarsTab(false)}>
            {registeredLabel?.value} (
            {promotional ? webinarOngoingPromo.length : webinarOngoing.length})
          </div>
          {pastWebinarsLabel?.value && <div className={`webinar-card-count${pastWebinarsTab ? " webinar-card-count--active" : ""}`} onClick={() => setPastWebinarsTab(true)}>
            {pastWebinarsLabel?.value} (
            {promotional ? webinarPastPromo.length : webinarPast.length})
          </div>}
        </div>
        {!pastWebinarsTab && <div className='webinar-cards'>
          <div className={`webinar-cards__list${promotional ? ' active' : ''}`}>
            <div className='webinar-cards-item-list'>
              {ongoingCardsPromo &&
                ongoingCardsPromo.length > 0 &&
                ongoingCardsPromo.map((item, i) => {
                  let webinar = allRegisteredWebinars.filter((regItem) => {
                    if (regItem?.data?.externalId && (item?.document?.elements?.webinarExternalId || item?.document?.id)) {
                      if(regItem?.data?.externalId.includes(item?.document?.elements?.webinarExternalId) || regItem?.data?.externalId.includes(item?.document?.id)) {
                        return regItem;
                      }
                    }
                  });
                  let recordId = null;
                  if(webinar && webinar[0]){
                    recordId = webinar[0].recordId;
                  }
                  return (
                    <div className='webinar-cards-item-list__card'>
                      <WebinarHubCardImage
                        key={i}
                        item={item}
                        content={resources?.value?.elements}
                        userId={userId}
                        removeLabel={removeLabel}
                        token={token}
                        webinarContent={item}
                        removeCourse={removeCourse}
                        recordId={recordId}
                      />
                    </div>
                  );
                })}
            </div>
            {pageCountOngoingPromo > 1 && pageCountOngoingPromo !== 0 && (
              <div className='Pagination'>
                <Pagination
                  count={pageCountOngoingPromo}
                  page={pageOngoingPromo}
                  color='primary'
                  onChange={(e, v) => {
                    handleChange(e, v, 'ongoingPromo');
                  }}
                />
              </div>
            )}
            {pageCountOngoingPromo === 1 && (
              <div className='no-pagination-divider'></div>
            )}
          </div>
          <div
            className={`webinar-cards__list${!promotional ? ' active' : ''}`}
          >
            <div className='webinar-cards-item-list'>
              {ongoingCards &&
                ongoingCards.length > 0 &&
                ongoingCards.map((item, i) => {
                  let webinar = allRegisteredWebinars.filter((regItem) => {
                    if (regItem?.data?.externalId && (item?.document?.elements?.webinarExternalId || item?.document?.id)) {
                      if(regItem?.data?.externalId.includes(item?.document?.elements?.webinarExternalId) || regItem?.data?.externalId.includes(item?.document?.id)) {
                        return regItem;
                      }
                    }
                  });
                  let recordId = null;
                  if(webinar && webinar[0]){
                    recordId = webinar[0].recordId;
                  }
                  return (
                    <div className='webinar-cards-item-list__card'>
                      <WebinarHubCardImage
                        key={i}
                        item={item}
                        content={resources?.value?.elements}
                        userId={userId}
                        removeLabel={removeLabel}
                        token={token}
                        webinarContent={item}
                        removeCourse={removeCourse}
                        recordId={recordId}
                      />
                    </div>
                  );
                })}
            </div>
            {pageCountOngoing > 1 && pageCountOngoing !== 0 && (
              <div className='Pagination'>
                <Pagination
                  count={pageCountOngoing}
                  page={pageOngoing}
                  color='primary'
                  onChange={(e, v) => {
                    handleChange(e, v, 'ongoing');
                  }}
                />
              </div>
            )}
            {pageCountOngoing === 1 && (
              <div className='no-pagination-divider'></div>
            )}
          </div>
        </div>}
        {pastWebinarsTab && <div className='webinar-cards'>
          <div className={`webinar-cards__list${promotional ? ' active' : ''}`}>
            <div className='webinar-cards-item-list'>
              {pastCardsPromo &&
                pastCardsPromo.length > 0 &&
                pastCardsPromo.map((item, i) => {
                  let webinar = allRegisteredWebinars.filter((regItem) => {
                    if (regItem?.data?.externalId && (item?.document?.elements?.webinarExternalId || item?.document?.id)) {
                      if(regItem?.data?.externalId.includes(item?.document?.elements?.webinarExternalId) || regItem?.data?.externalId.includes(item?.document?.id)) {
                        return regItem;
                      }
                    }
                  });
                  let recordId = null;
                  if(webinar && webinar[0]){
                    recordId = webinar[0].recordId;
                  }
                  return (
                    <div className='webinar-cards-item-list__card'>
                      <WebinarHubCardImage
                        key={i}
                        item={item}
                        content={resources?.value?.elements}
                        userId={userId}
                        removeLabel={removeLabel}
                        token={token}
                        webinarContent={item}
                        removeCourse={removeCourse}
                        recordId={recordId}
                      />
                    </div>
                  );
                })}
            </div>
            {pageCountPastPromo > 1 && pageCountPastPromo !== 0 && (
              <div className='Pagination'>
                <Pagination
                  count={pageCountPastPromo}
                  page={pagePastPromo}
                  color='primary'
                  onChange={(e, v) => {
                    handleChange(e, v, 'pastPromo');
                  }}
                />
              </div>
            )}
            {pageCountPastPromo === 1 && (
              <div className='no-pagination-divider'></div>
            )}
          </div>
          <div
            className={`webinar-cards__list${!promotional ? ' active' : ''}`}
          >
            <div className='webinar-cards-item-list'>
              {pastCards &&
                pastCards.length > 0 &&
                pastCards.map((item, i) => {
                  let webinar = allRegisteredWebinars.filter((regItem) => {
                    if (regItem?.data?.externalId && (item?.document?.elements?.webinarExternalId || item?.document?.id)) {
                      if(regItem?.data?.externalId.includes(item?.document?.elements?.webinarExternalId) || regItem?.data?.externalId.includes(item?.document?.id)) {
                        return regItem;
                      }
                    }
                  });
                  let recordId = null;
                  if(webinar && webinar[0]){
                    recordId = webinar[0].recordId;
                  }
                  return (
                    <div className='webinar-cards-item-list__card'>
                      <WebinarHubCardImage
                        key={i}
                        item={item}
                        content={resources?.value?.elements}
                        userId={userId}
                        removeLabel={removeLabel}
                        token={token}
                        webinarContent={item}
                        removeCourse={removeCourse}
                        recordId={recordId}
                      />
                    </div>
                  );
                })}
            </div>
            {pageCountPast > 1 && pageCountPast !== 0 && (
              <div className='Pagination'>
                <Pagination
                  count={pageCountPast}
                  page={pagePast}
                  color='primary'
                  onChange={(e, v) => {
                    handleChange(e, v, 'past');
                  }}
                />
              </div>
            )}
            {pageCountPast === 1 && (
              <div className='no-pagination-divider'></div>
            )}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default WebinarTab;
