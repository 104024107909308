import React ,{useEffect , useState} from 'react';

//Images
import closeBtn from '../../../../assets/images/infographics-assets/circle-close-icon.svg';
import closeBtnWhite from '../../../../assets/images/infographics-assets/close-icon-white.svg';

import './references.scss';
import { SettingsPhoneTwoTone } from '@material-ui/icons';
import ReferenceTextMDTBiomaker from './referencesText/ReferencesBiomakers';
import ReferencesDopaminergeic from './referencesText/ReferencesDopaminergeic';
import {ReferencesMDTSection} from './referencesText/ReferencesMDTSection';
import {ReferencesPathology} from './referencesText/ReferencesPathology';
import RichText from 'components/reusableComponents/richText/RichText';

const References = (props) => {
    const[reference,setReference ]= useState(false)
    const opentitleRef = ()=>{
        setReference(true);
    } 
    
    let isOpenRef = props.isOpenRef;  

    useEffect(() => {
        if(isOpenRef=== true){
            setReference(true)
            isOpenRef= false; 
        }
    });

    
    const onclick = (e) => {
        e.preventDefault();
        setReference(!reference);
        isOpenRef = false;
        props.setIsOpenRef(false)
    }

    let typeReference
    if(props.type==="biomakers"){
        typeReference = (
            <ReferenceTextMDTBiomaker/>
        )
    }
    else if(props.type ==="pathology"){
        typeReference = (
            <ReferenceTextMDTBiomaker/>
        )
    }
    else if(props.type ==="dopaminergic"){
        typeReference = (
            <ReferenceTextMDTBiomaker/>
        )
    }
    else if(props.type ==="mdtSection"){
        typeReference = (
            <ReferenceTextMDTBiomaker/>
        )
    }
    let backgroundColorValue = props.backgroundColor ? props.backgroundColor : "white";
    let textColor = props.textColor;
    const styleBackground= {
        backgroundColor: backgroundColorValue,
        color:textColor
    }
   
       
    return(
        <>
          <div className="title-references">
                {
                  reference? <div className="overlay-reference"> </div> :null
                }
                <div className="references-big-container" id="referenceShow" >
                <div className="references-small-container" >
                    <div className={"references-small-section "} id="references-button" style={styleBackground} onClick={(e)=>onclick(e)}>
                        {reference ? <img src={props.backgroundColor == 'reference-blue' ? closeBtnWhite : closeBtn} className="close-btn-image"></img>:null}
                        <span className={"reference-caption "} style={reference ? {bottom: '49px', marginLeft: '-30px'} : {bottom: '60px'}}>References </span>
                    </div>
                    {reference ?
                    <div className="references-wrapper" style={styleBackground}>
                        <div className="references-block" id="block"  style={styleBackground} >
                            <RichText data={props.content} />
                        </div>
                    </div> : null
                    }
                </div>
                </div> 
            </div>
     
        </>
    )
}

export default References;