import React from 'react';
import Image from 'components/reusableComponents/image/Image';
import { AppConfig } from 'AppConfig';

import './EapcImagesPanelPdf.scss';

const EapcImagesPanelPdf = ({ content }) => {
  const { imageWithPdf, title } = content?.elements;

  const { domain } = AppConfig.acousticApi;
  return (
    <div className='images-panel-pdf'>
      {title?.value && (
        <div className='images-panel-pdf__title'>
          <h4>{title?.value}</h4>
        </div>
      )}
      <div className='images-panel-pdf__cards'>
        {imageWithPdf?.values.map((item, index) => {
          return (
            <div className='images-panel-pdf__cards__card' key={index}>
              {item?.elements?.image && <Image data={item?.elements?.image} />}
              {item?.elements?.caption?.value && (
                <p>{item?.elements?.caption?.value}</p>
              )}
              {item?.elements?.pdf?.url &&
                item?.elements?.downloadButtonText?.value && (
                  <a
                    href={`${domain}${item?.elements?.pdf?.url}`}
                    download
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {item?.elements?.downloadButtonText?.value}
                  </a>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EapcImagesPanelPdf;
