import React from 'react';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { SectionPage } from './SectionPage';
import { SectionCard } from 'modules/sectionCard/SectionCard';
import ContentCard from 'modules/contentCard/ContentCard';
import './SectionPageList.scss';
import { getAssetUrl } from 'api/acousticApi';
import { useSelector } from 'react-redux';

const SectionPageList = ({
  moduleSectionPageList, alternateSectionDisplay
}) => {
  const websiteConfigObject = useWebsiteConfig();
  const { redesign } = useSelector(state => state.ui);

  if(redesign){
    return <>
      {moduleSectionPageList?.value && (
        <SectionPage
          moduleSectionPage={moduleSectionPageList?.value}
        />
      )}
      <div className={alternateSectionDisplay?.value ? "module-section-card-container" : ""}>
          {moduleSectionPageList?.values?.length !== 0 ? moduleSectionPageList?.values?.map((item, i) => {
            const {
              sectionIcon,
              sectionCategory,
              sectionTitle,
              sectionTitleLink,
              sectionImage 
            } = item;
            const bgimg = getAssetUrl(sectionImage);
            return alternateSectionDisplay?.value ?
              <div className='Cards'>
              <div className={sectionImage?.url ? "bgimage":""} style={{background: `url(${bgimg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundOrigin: 'center'}}></div>
                <ContentCard
                  key={i}
                  theme='primary'
                  heading={sectionTitle?.value}
                  icon={sectionIcon}
                  href={sectionTitleLink?.linkURL}
                  linkText={websiteConfigObject.websiteConfig.tReadMore}
                  clickable
                />
              </div>
            :
            <SectionPage 
              key={i}
              moduleSectionPage={item}
            />
          }) : ""}
      </div>
    </>
  }
  return <>
    {moduleSectionPageList?.value && (
      <SectionPage
        moduleSectionPage={moduleSectionPageList?.value}
      />
    )}
    <div className={alternateSectionDisplay?.value ? "module-section-card-container" : ""}>
        {moduleSectionPageList?.values?.length !== 0 ? moduleSectionPageList?.values?.map((item, i) => (
          alternateSectionDisplay?.value ?
            <SectionCard 
              key={i}
              moduleSectionPage={item} 
              labelReadMore={websiteConfigObject.websiteConfig.tReadMore}
            />
          :
          <SectionPage 
            key={i}
            moduleSectionPage={item}
          />
        )) : ""}
    </div>
  </>
};

SectionPageList.propTypes = {
  moduleSectionPageList: contentItemProptype,
};

export { SectionPageList };
