import './AudioCard.scss';
import React, { useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import RichText from 'components/reusableComponents/richText/RichText';
import { getAssetUrl } from 'api/acousticApi';
import { useSelector } from 'react-redux';
import { Volume, PlayAudio, PlayAudioPause, VolumeMute } from 'components/shared/SvgIcons';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';


const AudioCard = ({ title, copy, src, image }) => {
    const isRedesign = useSelector((state) => state?.ui?.redesign);
    const { trackAudio } = useGoogleAnalytics();
    const [isResume, setIsResume] = useState(false);
    const audioTitle = src.asset.fileName;
    
    const showSlider = (e) => {
        const slider = e.target.parentNode.getElementsByClassName("rhap_progress-indicator")[0];
        slider.style.display = "block";
    }

    let previousTime = 0, currentTime = 0;
    let dragStartTime = null;

    const play = (e) => {
        if(!isResume) { 
            setIsResume(true);
            trackAudio('audio_start', e.currentTarget, audioTitle);
        } else {
            trackAudio('audio_resume', e.currentTarget, audioTitle);
        }

        const audioElements = document.getElementsByTagName("audio");
        audioElements?.forEach(element => {
            if(element !== e.target && !element.paused) element.pause();
        });
        showSlider(e);
        
    }

    const onPause = (e) => {
        const { currentTime, duration } = e.currentTarget;
        // Don't trigger event when audio ended
        if(currentTime !== duration) {
            trackAudio('audio_pause', e.currentTarget, audioTitle)
        }
    }

    const onEnded = (e) => {
        trackAudio('audio_complete', e.currentTarget, audioTitle)
        setIsResume(false);
    }

    const onTimeUpdate = (e) => {
        previousTime = currentTime;
        currentTime = e.currentTarget.currentTime;
    }

    const onSeeking = e => { 
        if(!dragStartTime) dragStartTime = previousTime 
    };

    const onSeeked = (e) => {
        if(dragStartTime) {
            trackAudio('audio_drag', e.currentTarget, audioTitle, dragStartTime)
            dragStartTime = null;
        }
    }

    return (
        <>
            <section className={`audio-section${isRedesign ? ' redesign' : ''}`}>
                {image?.src && <img src={image?.src} alt={image?.alt} />}
                <div className='audio-content'>
                    <AudioPlayer
                        src={getAssetUrl(src)}
                        layout="horizontal"
                        customAdditionalControls={[]}
                        customVolumeControls={[]}
                        showJumpControls={false}
                        customControlsSection={[]}
                        className={!image?.src && "rmPadding"}
                        customProgressBarSection={[
                            RHAP_UI.MAIN_CONTROLS,
                            RHAP_UI.CURRENT_TIME,
                            <div>/</div>,
                            RHAP_UI.DURATION,
                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.VOLUME
                        ]}
                        customIcons={{
                            play: <PlayAudio />,
                            pause: <PlayAudioPause />,
                            volume: <Volume />,
                            volumeMute: <VolumeMute />
                        }}
                        onPlay={play}
                        onPause={onPause}
                        onEnded={onEnded}
                        onListen={onTimeUpdate}
                        onSeeking={onSeeking}
                        onSeeked={onSeeked}
                    />
                    {title && <h2><RichText data={{value: title}} /></h2>}
                    <RichText data={copy} />
                </div>
            </section>
        </>
    )
}

export default AudioCard;