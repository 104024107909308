import { getAPIContent } from 'api/acousticApi';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { KKI_AUTH, OWA_AUTH, DOCCHECK_AUTH } from 'utils/authUtils';
import logoutLogo from 'assets/images/logout.svg';
import settingsLogo from 'assets/images/settings.svg';
import logoutLogoWhite from 'assets/images/logout-white.svg';
import settingsLogoWhite from 'assets/images/settings-white.svg';
import {
  LOGOUT_PATH,
  PROFILE_PAGE_PATH,
} from 'routes/routeConstants';
import {
  ONEKEY,
  getAuthServiceRedirects,
} from 'api/authService';
import { parseAPITopLevelItem } from './navigationTree';

const NAVIGATION_QUERY_KEY = 'navigation-query';

const getTopLevelNavItems = (_key, topLevelMenuIds) => {
  if (!topLevelMenuIds) {
    return null;
  }
  const navItemPromises = topLevelMenuIds.map((id) => getAPIContent(id));
  return Promise.all(navItemPromises);
};

const useAPINavigation = () => {
  const { topLevelMenuIds } = useWebsiteConfigObject();
  const { isLoading, error, data } = useQuery(
    // passs menu ids through as part of the key array
    // these will then be the second parameter passed to
    // getTopLevelNavItems
    [NAVIGATION_QUERY_KEY, topLevelMenuIds],
    getTopLevelNavItems,
    { staleTime: Infinity }, // only request the navigation tree once
  );
  const navigation = data ? data.map(parseAPITopLevelItem) : null;
  // TODO make the navigation tree out of the content array
  return { isLoading, error, navigation };
};

const useAuthServiceNavigation = () => {
  const { anonymous } = useSelector((state) => state.authentication.status);
  const { trustLevel } = useSelector((state) => state.authentication.data.additional);

  const {
    authService, tAccount, tLogout, tTrustLevel,
  } = useWebsiteConfigObject();

  const { selection } = authService || {};

  if (!authService) {
    return null;
  }

  const userNavigation = {
    kki: {
      id: 'user-top-level',
      path: null,
      title: 'Profile',
      children: [
        {
          iconUrl: settingsLogo,
          iconWhiteUrl: settingsLogoWhite,
          id: 'account',
          path: PROFILE_PAGE_PATH,
          trackingData: {
            contentType: 'profile',
            itemId: 'view',
          },
          title: tAccount,
          type: 'Link main',
        },
        {
          iconUrl: logoutLogo,
          iconWhiteUrl: logoutLogoWhite,
          id: 'logout',
          path: LOGOUT_PATH,
          title: tLogout,
          type: 'Link main',
        },
      ],
    },
    oneKey: {
      id: 'user-top-level',
      path: null,
      title: 'Profile',
      children: anonymous ? [
        {
          id: 'trustlevel',
          type: 'Component',
          title: `${tTrustLevel} ${trustLevel}`,
        },
        {
          iconUrl: settingsLogo,
          iconWhiteUrl: settingsLogoWhite,
          id: 'owa-account',
          path: PROFILE_PAGE_PATH,
          useExternalWithConfirm: true,
          externalUrl: getAuthServiceRedirects({ service: ONEKEY }).account(),
          title: `OWA ${tAccount}`,
          type: 'Link main',
        },
        {
          iconUrl: logoutLogo,
          iconWhiteUrl: logoutLogoWhite,
          id: 'logout',
          path: LOGOUT_PATH,
          useExternal: true,
          externalUrl: getAuthServiceRedirects({ service: ONEKEY }).logout(),
          title: tLogout,
          type: 'Link main',
        },
      ] :
        [
          {
            id: 'trustlevel',
            type: 'Component',
            title: `${tTrustLevel} ${trustLevel}`,
          },
          {
            iconUrl: settingsLogo,
            iconWhiteUrl: settingsLogoWhite,
            id: 'kki-account',
            path: PROFILE_PAGE_PATH,
            title: `KKI ${tAccount}`,
            type: 'Link main',
          },
          {
            iconUrl: settingsLogo,
            iconWhiteUrl: settingsLogoWhite,
            id: 'owa-account',
            path: null,
            useExternalWithConfirm: true,
            externalUrl: getAuthServiceRedirects({ service: ONEKEY }).account(),
            title: `OWA ${tAccount}`,
            type: 'Link main',
          },
          {
            iconUrl: logoutLogo,
            iconWhiteUrl: logoutLogoWhite,
            id: 'logout',
            path: null,
            useExternal: true,
            externalUrl: getAuthServiceRedirects({ service: ONEKEY }).logout(),
            title: tLogout,
            type: 'Link main',
          },
        ],
    },
    docCheck: {
      id: 'user-top-level',
      path: null,
      title: 'Profile',
      children: anonymous ? [
        {
          iconUrl: logoutLogo,
          iconWhiteUrl: logoutLogoWhite,
          id: 'logout',
          path: LOGOUT_PATH,
          title: tLogout,
          type: 'Link main',
        },
      ] : [
          {
            iconUrl: settingsLogo,
            iconWhiteUrl: settingsLogoWhite,
            id: 'kki-account',
            path: PROFILE_PAGE_PATH,
            title: `KKI ${tAccount}`,
            type: 'Link main',
          },
          {
            iconUrl: logoutLogo,
            iconWhiteUrl: logoutLogoWhite,
            id: 'logout',
            path: LOGOUT_PATH,
            title: tLogout,
            type: 'Link main',
          },
        ],
    },
  };

  switch (selection) {
    case KKI_AUTH:
      return userNavigation.kki;
    case OWA_AUTH:
      return userNavigation.oneKey;
    case DOCCHECK_AUTH:
      return userNavigation.docCheck;
    default:
      return null;
  }
};

export { useAPINavigation, useAuthServiceNavigation };
