import styles from './Table.module.scss';

const Table = ({ title, titleTag, children }) => {
  const HeadingTag = titleTag ? `${titleTag}` : `h3`;
  return (
    <div className={styles['table']}>
      {title && <HeadingTag className={styles['headline']}>{title}</HeadingTag>}
      {children}
    </div>
  );
};

export default Table;
