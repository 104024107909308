import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  useWebsiteConfigObject,
  translateFormFieldsInArrays,
} from 'websiteConfig/useWebsiteConfig';
import { useLocationSearchParameter } from 'utils/urlUtils';
import { getUrlCountryPath } from 'utils/urlUtils';
import { TabForm } from 'components/shared/form/TabForm';
import { LoginSideText } from 'components/pageTemplates/login/LoginSideText';
import { authActions } from 'redux/authentication/slice';
import { HOME_PAGE_PATH, REGISTRATION_SUCCESS } from 'routes/routeConstants';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { tabformFields, OldTabformFields, marketingFields } from './formsElements';
import { LoginButtonClickFunction, RegisterButtonClickFunction } from 'utils/tealiumUtils';

const KKILogin = ({ history }) => {
  const translations = useWebsiteConfigObject();
  const {
    tContinueText,
    tCreateYourAccount,
  } = useWebsiteConfigObject();
  const { trackEventWithMethod } = useGoogleAnalytics();
  const {
    featureSwitchDisplayNewFormFields,
    moodleRedirectConfirmationAlertOnGlobalLandingPage,
    disableMarketingConsent
  } = useWebsiteConfigObject();
  const { loggedIn, limitedAccess} = useSelector((state) => state.authentication.status);
  const dispatch = useDispatch();
  const ReturnUrl = useLocationSearchParameter('returnUrl');

  const { returnPath } =
    history.location && history.location.state ? history.location.state : {};
  // if a user is redirected to the homepage from another page and somehow gets logged in between that time
  // redirect the user to a returnPath or home page if there is none.
  const destination = returnPath ? returnPath : HOME_PAGE_PATH;

  useEffect(() => {
    if(loggedIn){
      trackEventWithMethod('login', 'KK Hub web');
    }
}, [loggedIn]);

  useEffect(() => {
    if(disableMarketingConsent) {
      const tabFields = featureSwitchDisplayNewFormFields ? tabformFields : OldTabformFields
      const registrationFields = tabFields[1].formFields.map(field => { 
        if(marketingFields.includes(field.id)) {
          return {
            ...field,
            mandatory: false,
            disabled: true,
            initialValue: null
          }
        } 

        return field
      })

      tabFields[1] = {
        ...tabFields[1],
        formFields: registrationFields
      }
    }
  }, [disableMarketingConsent, featureSwitchDisplayNewFormFields])

  const moodleRedirect = () => {
    const localReturnUrl = sessionStorage.getItem('MoodleReturnUrl');
    const MoodleRedirectionObj = JSON.parse(localReturnUrl);
    if (ReturnUrl && MoodleRedirectionObj?.ReturnUrl && !MoodleRedirectionObj?.isGlobal) {
      const MoodleRedirectionObj = {
        ReturnUrl: decodeURIComponent(ReturnUrl),
        isGlobal: getUrlCountryPath() === "" ? true : false,
        redirect: true,
      };
      const MoodleRedirection = JSON.stringify(MoodleRedirectionObj);
      sessionStorage.setItem('MoodleReturnUrl', MoodleRedirection);
    }
    if(!ReturnUrl && !MoodleRedirectionObj?.isGlobal) {
      sessionStorage.removeItem('MoodleReturnUrl');
    }
  }
  const handleUserLogin = (formValues) => {
    const { email, password } = formValues;
    if(history.action === "PUSH") {
      if(window.utag) {
        LoginButtonClickFunction(tContinueText);
      } 
    }
    dispatch(authActions.kki.login(email, password)).then(() => {
      if(moodleRedirectConfirmationAlertOnGlobalLandingPage && ReturnUrl){
        let alerMsg = moodleRedirectConfirmationAlertOnGlobalLandingPage;
        alerMsg = alerMsg.replaceAll("{externalLink}", decodeURIComponent(ReturnUrl));
        alert(alerMsg)
      }
      moodleRedirect();
      // trackEventWithMethod('login', 'KK Hub web');
      history.push(destination);
    });
  };

  const handleUserRegister = (formValues) => {
    if(formValues['information-consent']) trackEventWithMethod('sign_up', 'paywall-min');
    if(history.action === "PUSH") {
      if(window.utag) {
        RegisterButtonClickFunction(tCreateYourAccount);
      } 
    }
    const querystring = localStorage.getItem("incommingquerystring");
    if(querystring) formValues= {...formValues, utmValues: querystring};
    setTimeout(()=>{
      localStorage.removeItem("incommingquerystring");
    },2000);
    dispatch(authActions.kki.register(formValues)).then(() => {
      // trackEventWithMethod('sign_up', 'KK Hub web');
      moodleRedirect();
      history.push(REGISTRATION_SUCCESS);
    });
  };

  if (loggedIn && !limitedAccess) {
    return (
      <Redirect
        to={{
          pathname: destination,
        }}
      />
    );
  }

  return (
    <div className='login-page-component'>
      <div className='login-page-container'>
        <div className='page-content'>
          <LoginSideText />
          <div className='login-page-form'>
            <TabForm
              tabFormFields={translateFormFieldsInArrays(
                featureSwitchDisplayNewFormFields ? tabformFields : OldTabformFields,
                translations
              )}
              actions={{
                handleUserLogin,
                handleUserRegister,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

KKILogin.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
};

const KKILoginWrapper = withRouter(KKILogin);

export { KKILoginWrapper as KKILogin };
