import React from 'react';
import { ChevronRight } from 'components/shared/SvgIcons';
import PropTypes from 'prop-types';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { getValue } from 'utils/contentUtils';
import {
  getImgAttr,
} from 'api/acousticApi';
import { VideoSelectorBody } from 'components/content/mainPromoPage/VideoSelectorBody';


const VideoSelectorDesktop = ({
  selectedIndex,
  setSelectedIndex,
  selectedBackgroundColour,
  primaryColour,
  videoElements,
}) => (
  <div className="video-selector">
    <ul className="selection-controls">
      {videoElements.map((video, index) => {
        const { id, elements } = video;
        const {
          thumbnailImage,
          name,
          description,
        } = elements;
        const isSelected = index === selectedIndex;
        const buttonStyle = isSelected ? { backgroundColor: selectedBackgroundColour } : null;
        const buttonClass = isSelected ? 'selected' : null;
        const chevronStyle = isSelected ? { color: primaryColour } : null;
        const {
          src,
          alt,
        } = getImgAttr(thumbnailImage);
        const nameValue = getValue(name);
        const descriptionValue = getValue(description);

        return (
          <li key={id}>
            <button
              type="button"
              className={`${buttonClass} custom-focus`}
              onClick={() => setSelectedIndex(index)}
              style={buttonStyle}
            >
              <div className="avatar">
                {
                  src && (
                    <img
                      src={src}
                      alt={alt}
                    />
                  )
                }
              </div>
              <div className="name-description">
                {
                  nameValue && (
                    <p className="name">{nameValue}</p>
                  )
                }
                {
                  descriptionValue && (
                    <p className="description">{descriptionValue}</p>
                  )
                }
              </div>
              <div
                className="chevron-container"
                style={chevronStyle}
              >
                <ChevronRight
                  clearSize
                />
              </div>
            </button>
          </li>
        );
      })}
    </ul>
    <VideoSelectorBody
      selectedIndex={selectedIndex}
      videoElements={videoElements}
      backgroundColor={selectedBackgroundColour}
      primaryColour={primaryColour}
    />
  </div>
);

VideoSelectorDesktop.propTypes = {
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  selectedBackgroundColour: PropTypes.string,
  primaryColour: PropTypes.string,
  videoElements: PropTypes.arrayOf(contentElementProptype),
};

export { VideoSelectorDesktop };
