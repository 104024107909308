/*
  SampleHeightUtils are used for sampling the ideal image heightd based on their widths and ratio.
  It's primary use is for dynamically setting the otherwise static height that Carousels need

  It works like so:
  For an array of only 1 image:
  if the image had:
  Width height ratio: 2:1

  and the components ideal width was:
  200

  Then this would set a height of 100.

  if another image was added to the array with a ratio of 1:1 however:

  Then this would set a height of 200

  The biggest ideal height in the array is set


  Note as well, this shouldn't ever be applied to an <img /> tag. The <img /> figures this stuff out itself, it's
  only needed for stuborn wrappers.
*/


const getSetSampleHeight = ({
  imgRef,
  sampleHeights,
  id,
  idealWidth,
  addSampleHeightCallback,
  verticalPadding = 0,
}) => () => {
  const {
    naturalWidth,
    naturalHeight,
  } = imgRef.current;

  const currentSampleHeight = sampleHeights[id]; // currently set height;
  const heightRatio = naturalHeight / naturalWidth; // the ratio, ie 0.5 === 2:1 width height ratio.
  const newSampleHeight = Math.ceil(idealWidth * heightRatio); // the ideal height the component should render at
  const newSampeHeightWithPadding = newSampleHeight + verticalPadding; // the ideal height + any needed padding

  // This code fires with every rerender, so we want to make sure to only sets state if the height has changed
  if (currentSampleHeight !== newSampeHeightWithPadding) {
    addSampleHeightCallback({
      id,
      newSampleHeight: newSampeHeightWithPadding,
    });
  }
};


// to be passed to a useEffect. Essentially this only sets height when all elements have registered their image.
// NOTE:
// This doesn't work if the images in the array can change. We don't have that problem (for now at least). If that
// does ever happen though we need some way of caching the set height. ie, sampleHeightKeys.join('-') or something
const setHeightLogic = ({
  sampleHeights,
  extractedImages,
  setHeight,
  currentHeight,
}) => {
  const sampleHeightKeys = Object.keys(sampleHeights);

  if (sampleHeightKeys.length === extractedImages.length) {
    let newHeight = 0;
    sampleHeightKeys.forEach((key) => {
      const sampleHeight = sampleHeights[key];
      if (sampleHeight > newHeight) {
        newHeight = sampleHeight;
      }
    });

    // Only set the new carouselHeight if it is needed
    if (currentHeight !== newHeight) {
      setHeight(newHeight);
    }
  }
};


export {
  getSetSampleHeight,
  setHeightLogic,
};
