import React from 'react'
import Link from 'components/reusableComponents/link/Link';
import { VideoIcon, ImageIcon } from 'components/shared/SvgIcons'
import './SearchResult.scss';
import { AppConfig } from 'AppConfig';

const SearchResultMedia = ({ content }) => {

    const { fileName, path, altText, links, assetType } = content
    const { media } = links 
    const pathUrl = `${AppConfig.acousticApi.domain}/${AppConfig.acousticApi.apiPath}/${AppConfig.acousticApi.contentHubId}/${media.href}`

    const isVideo = (assetType === "video") ? true : false 

    const pathname = window.location.href.split("/");
    pathname.pop()
    const pathnameV2 = pathname.join("/")

    if (path) {
        return (
            <div className="SearchResult_Holder">

                <div className="SearchResult_Icon">
                    {isVideo &&  <VideoIcon /> }
                    {!isVideo && <ImageIcon /> }
                </div>

                <div className="SearchResult_Content">
                    <h3 className="SearchResult_Content_Heading">{ fileName}</h3>

                    <Link className="SearchResult_Content_Link" href={pathUrl}>{pathnameV2}{path}</Link>

                    {altText && <p className="SearchResult_Content_Text">{altText}</p> }

                </div>
                
            </div>
        )
    } else {
        return null;
    }
}


export default SearchResultMedia