// import Button from '../button/Button'
import "./ToastBar.scss";
import Image from "components/reusableComponents/image/Image";
import RichText from "components/reusableComponents/richText/RichText";
import Link from "components/reusableComponents/link/Link";
import { useSelector } from 'react-redux'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const ToastBar = (props) => {
  const {
    toastbarText,
    adverseReportingUrl,
    adverseReportingLinkText,
    adverseReportingLinkLogo,
  } = props;
  const { trackEventWithContentType } = useGoogleAnalytics();
  const { redesign } = useSelector(state => state.ui);
  if(redesign){
    return (
      <div className="toastbar">
        <div className="toastbar-bg">
          <div className="toastbar-content-bg">
  
          </div>
          {adverseReportingUrl ? (
            <div className="toastbar-link-bg">
  
            </div>
          ) : null}
        </div>
        <div className="toastbar-content">
          <div className="toastbar-content-wrap">
            <RichText data={toastbarText} />
            {adverseReportingUrl ? (
              <div className="toastbar-link">
                <Link onClick={() => trackEventWithContentType('link', adverseReportingLinkText) } href={adverseReportingUrl} className="adverse-link">
                  {adverseReportingLinkText ? adverseReportingLinkText : null}
                  {adverseReportingLinkLogo?.url ? (
                    <div className="toastbar-icon">
                      <Image data={adverseReportingLinkLogo} alt="Link Logo" />
                    </div>
                  ) : null}
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="toastbar">
      <div className="toastbar-content">
        <RichText data={toastbarText} />
        {adverseReportingUrl ? (
          <div className="toastbar-link">
            <Link onClick={() => trackEventWithContentType('link', adverseReportingLinkText) } href={adverseReportingUrl} className="adverse-link">
              {adverseReportingLinkText ? adverseReportingLinkText : null}
              {adverseReportingLinkLogo?.url ? (
                <div className="toastbar-icon">
                  <Image data={adverseReportingLinkLogo} alt="Link Logo" />
                </div>
              ) : null}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ToastBar;
