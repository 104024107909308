import React from 'react'
import RichText from 'components/reusableComponents/richText/RichText'
import './Subheader.scss';

const Subheader = ({ 
    content,
    colourTheme,
 }) => {
    if (!content?.value?.elements) { return null }
    const { leftColumn, rightColumn } = content?.value?.elements || {}
    const subheaderClass = colourTheme ? `subheader subheader--${colourTheme}` : 'subheader'
    
    return(
        <div className={subheaderClass}>
            <div className='container'>
                <div className='subheader__inner'>
                    <div className='subheader__column'>
                        { leftColumn?.value?.text && (
                            <RichText data={leftColumn?.value?.text}/>
                        )}
                    </div> 
                    <div className='subheader__column'>
                        { rightColumn?.value?.text && (
                            <RichText data={rightColumn?.value?.text}/>
                        )}
                    </div>                                   
                </div>
            </div>           
        </div>
    )
}


export default Subheader