  import {
    commonShouldHaveValue,
  } from 'components/shared/form/formConstants';

  const EMAIL = 'email';
  const NEW_PASSWORD = 'new-password';
  const eapcConfirmation  = 
    {
      label: null,
      id: null,
      type: '',
      mandatory: true,
      size: 50,
      options:null,
      pattern:null,
      isDataPII:null,
      initialValue: "",
      helptext: "",
      typeParams: {
        type: 'text',
        autoComplete: 'given-name',
        placeholder: 'Enter your First Name',
      },
      validation: [
        commonShouldHaveValue,
      ],
    }
    function fieldsType(type){
        const expr = type;
        switch (expr) {
        case 'input':
            return 'input'
        case 'email':
            return 'input'
        case 'dropdown':
           return 'dropdown'
        case 'complexDropdown':
            return 'complex-dropdown'  
        case 'checkbox':
            return 'checkbox-group' 
        case 'radio':
            return 'radio-eapc' 
        case 'textarea':
          return 'textarea' 
        case 'button':
          return 'button'   
        default:
            return 'input'
        }
    }

  function event (field, values){  
      let fields = field?.elements 
      let fieldType = fieldsType(fields?.fieldType?.value?.selection) 
      let eapc =JSON.parse(JSON.stringify(eapcConfirmation));
      eapc.id= fields?.fieldKey?.value;  
      if(values) {
        eapc.initialValue = values[fields?.fieldKey?.value] || ""
      }
      if(fields?.minlength?.value > 0){
        eapc.minlength= fields?.minlength?.value;
      }
      if(fields?.maxlength?.value > 0){
        eapc.maxlength= fields?.maxlength?.value;
      }
      if(fields?.maxlength?.value > 0 || fields?.minlength?.value > 0){
        eapc.typeParams.lengthErrorMsg= fields?.fieldLengthErrorMessage?.value;
      }
      eapc.label= fields?.fieldLabel?.value;
      eapc.helptext= fields?.minLengthTextareaHelptext?.value;
      eapc.name= fields?.fieldKey?.value;
      eapc.type = fieldType;
      eapc.mandatory= fields?.isMandatory?.value;
      eapc.size= fields?.maxlength?.value;
      eapc.typeParams.type = fields?.fieldType?.value?.selection
      eapc.options = fields?.dropdownOptions?.values
      eapc.typeParams.placeholder = fields?.placeholder?.value
      eapc.typeParams.autoComplete = ""
      eapc.pattern= fields?.regexPattern?.value
      eapc.isDataPII=fields?.isPii?.value
      eapc.reverseLabel=true
      if(values && fields?.fieldKey?.value === "email" && values[fields?.fieldKey?.value]) {
        eapc.typeParams.readOnly = true
      }
      //complex dropdown
      if(fields?.fieldType?.value?.selection === "complexDropdown"){
        eapc.optionsJson = fields?.optionsJson?.value;
        eapc.firstValue = fields?.firstValue?.value;
        eapc.isSecondary = fields?.isSecondary;
        eapc.primaryKey = fields?.primaryKey?.value;
      }
      return eapc
  }
 
  
  export {
    event
  };
  
  