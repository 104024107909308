import React from 'react';
import { getImgAttr } from 'api/acousticApi/index';
// import { getValue } from 'utils/contentUtils';
// import {
//   useScreenWidth,
//   SCREEN_WIDTH_MOBILE,
// } from 'components/layout/ScreenWidthContext';
import Image from 'components/reusableComponents/image/Image';
import './HeaderBannerImageBg.scss';

const HeaderBannerImageBg = ({ image, contain }) => {
  const bannerImageElement = image ? image.value : null;
  // const { currentWidthRanges } = useScreenWidth();

  if (!bannerImageElement) {
    return null;
  }
  const {
    bannerMobile,
    bannerDesktop,
    // bannerHyperlinkUrl,
  } = bannerImageElement.elements;
  // const linkURL = getValue(bannerHyperlinkUrl);
  // const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);
  const desktopBannerImage = getImgAttr(bannerDesktop);
  // const mobileBannerImage = getImgAttr(bannerMobile);

  // const desktopStyle = {
  //   backgroundImage: `url(${desktopBannerImage.src})`,
  // };
  // const mobileStyle = {
  //   backgroundImage: `url(${mobileBannerImage.src})`,
  // };

  return (
    desktopBannerImage && (
      <section className='header-banner-with-background'>
        <div className='header-banner-with-background__container desktop'>
          <Image data={bannerDesktop} />
        </div>
        <div className='header-banner-with-background__container mobile'>
          <Image data={bannerMobile} />
        </div>
      </section>
    )
  );
};

export { HeaderBannerImageBg };
