// convert an arbitrary string to a valid css id
// replacing any non alphanumeric characters with -
const stringToCssId = (string) => {
  if (!string) return null;
  const nonWordRegex = /\W/g; // matches anything that isn't a word character (a-z and 0-9)
  return string.replace(nonWordRegex, '-').toLowerCase();
};

// ensure that a string has a leading slash at the beginning
// by adding `/` to the beginning unless it is already there
const withLeadingSlash = (string) => {
  if (!string) return null;
  return string[0] !== '/' ? `/${string}` : string;
};

// extract the file name which can be downloaded from an url
const getFileName = (string) => {
  if(!string) return null;
  const matched = /[^/\\&?#]+\.\w{1,}(?=([?&#].*$|$))/g.exec(string);
  return matched && matched.length > 0 ? matched[0] : string;
}

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

const formatTextUsingFields = (string, object) => {
  if(!string || !object) return string
  let formatedText = string
  const matched = string.match(/{\w{1,}}/g)
  matched?.forEach(item => {
    let field = capitalize(item.substring(1, item.length-1))
    if(object[field]) formatedText = formatedText.replaceAll(item, object[field])
  })

  return formatedText
} 
const  convertHTMLToText = (htmlString, transform=true) => {
  if(!htmlString) return ''
  let text = htmlString

  const breaks = transform ? '\\n' : ''

  // Replace p | br tag with \n for line break
  text = text.replace(/<\/?(p|br)[^>]*>/g, breaks)

  // Replace html link with its href values
  const links = text.match(/<a(.*?)>(.*?)<\/a>/g)
  if(links){
    links.forEach(link => {
      let href = link.match(/href="(.*?)"/g)[0]
      href = href.substring(href.indexOf('"') + 1, href.length - 1)
      text = text.replace(link, href)
    })
  }

  // Remove all remaining tags
  text = text.replace(/<[^>]+>/g, '')
  
  return text
}

export {
  stringToCssId,
  withLeadingSlash,
  getFileName,
  formatTextUsingFields,
  convertHTMLToText
};
