import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getHasErrors } from 'components/shared/form/formValidation';
import { ChevronRight } from 'components/shared/SvgIcons';
import RichText from 'components/reusableComponents/richText/RichText';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
  Loader,
  TINY_LOADER,
} from 'components/shared/Loader';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
} from 'components/shared/form/formPropTypes';
import './FormButton.scss';


const FormButton = ({
  field,
  fieldType,
  formValues,
  inlineErrors,
  setAllTouched,
  actions,
  isSubmitting,
}) => {
  const { trackGTMEvent } = useGoogleAnalytics();
  const [_btnClicked, _setBtnClicked] = useState(false);
  const [_btnLoading, _setBtnLoading] = useState(false);
  const {
    typeParams,
    size = 100,
    color,
    topLabel,
    customiSSubmitting
  } = field;
  const {
    type = 'button',
    text,
    callBack,
    style,
    scrollToError,
    errorText,
    errorOccured,
    mandatoryLabel,
    gtmEvent,
    gtmType,
  } = typeParams;
  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];

  const buttonStyle = {
    backgroundColor: color,
  };

  if (style) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-${style}`);
  }
  if (isSubmitting) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-submitting`);
  }

  const hasErrors = getHasErrors({ inlineErrors });

  const onClick = (e) => {
    e.preventDefault();
    if(gtmEvent && gtmType && gtmType != "" && gtmEvent != ""){
      trackGTMEvent(gtmEvent, gtmType)
    }
    // if there are errors, do not submit the form. Instead mark all fields as touched so the user can clearly see the errors
    if (hasErrors) {
      setAllTouched();
      if(scrollToError) {
        setTimeout(() => {
          let error_position = document.getElementsByClassName("form-inline-error-text");
          if(error_position){
            let viewport_position = error_position[0].getBoundingClientRect();  
            if (
              viewport_position.top >= 0 &&
              viewport_position.left >= 0 &&
              viewport_position.right <= (window.innerWidth || document.documentElement.clientWidth) &&
              viewport_position.bottom <= (window.innerHeight || document.documentElement.clientHeight)
              ) {}
            else {
              error_position[0].scrollIntoView({ behavior: "smooth" });
            }
          }
        }, 200);
      }
      return;
    }

    _setBtnClicked(true);

    // pass form values and any form actions to the button callback
    callBack({ formValues, actions });
  };

  useEffect(() => {
    if (isSubmitting && _btnClicked) {
      _setBtnLoading(true);
    }

    if (!isSubmitting) {
      _setBtnLoading(false);
      _setBtnClicked(false);
    }
  }, [isSubmitting, _btnClicked]);


  return (
    <>
      {topLabel?.value && 
        <div className="custom-btn-form-label"> 
          <RichText data = {topLabel}/>
        </div>
      }
    <div
      className={fieldClassNames.join(' ')}
    >
      {/* lint is panicking because type is dynamic 🤷‍♀️ */}
      {/* eslint-disable-next-line react/button-has-type */}
      <div></div>
      {mandatoryLabel && (
        <div className='mandatory-text'>* {mandatoryLabel}</div>
      )}
      <button
        type={type}
        onClick={onClick}
        disabled={_btnLoading}
        className={`custom-focus ie-11-button-fix ${field?.className ? field?.className : ''}`}
        style={color && buttonStyle}
      >
        <p>{text}</p>
        <div className="form-field-button-svg-container">
          {
            _btnLoading || customiSSubmitting ? (
              <Loader
                size={TINY_LOADER}
              />
            ) : (
              <ChevronRight
                clearSize
              />
            )
          }
        </div>
      </button>
      {errorOccured && errorText?.value && 
        <div className='submission-error'>
            <RichText data={errorText} />
        </div>
      }
    </div>
    </>
  );
};

FormButton.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
  formValues: formValuesPropTypes,
  inlineErrors: inlineErrorsPropTypes,
  setAllTouched: PropTypes.func,
  actions: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
};

export {
  FormButton,
};


