/*
    Function to show or hide overlay
    It works with overlay.jsx
    param:
        status values : show, hide
 */
export function toggleOverlay(status, withScroll){
    const OverlaySel = document.getElementById('js-overlay-modal'),
          bodySel = document.querySelector('body');

    if(OverlaySel !== null){
        if(status === 'show'){
            OverlaySel.classList.remove('hide-overlay');
            !withScroll && bodySel.classList.add('no-scroll');
        } else {
            OverlaySel.classList.add('hide-overlay');
            bodySel.classList.remove('no-scroll');
        }
    }
}

/*
    Function to close the popup
*/
export function closePopup(evt){
    const OverlaySel = document.getElementById('js-overlay-modal'),
        bodySel = document.querySelector('body');
    let eventTarget = evt.target.parentNode.parentNode.parentNode;
    eventTarget.classList.remove('show');
    OverlaySel.classList.add('hide-overlay');
    bodySel.classList.remove('no-scroll');
}

/*
    Function to make the popup appears on scrolling the broswer window - Timed popup
    Using Intersection Observer API - A polyfill have been used for IE11 as not supported
*/
export function loadScrollerPopup(desktopTarget, mobileTarget=null){
    //Function for timer intervals - 5 second max
    //interval : 1 second => 1000 milliseconds, maxTime : 5
    function delayPopup(interval, maxTime, id){
        let index = 0;
        //     percentage = 100 / maxTime,  //Ex: 100% / 5 or 100% / 8 
        //     percentStyle = percentage;

        let counter = window.setInterval(function(){
            index++;

            // document.getElementById(id+'-timer').style.width = percentStyle + '%';
            // percentStyle += percentage;

            if(index >= maxTime + 1){
               window.clearInterval(counter);
               if (document.getElementById(id) != null || document.getElementById(id) != undefined) {
                    document.getElementById(id).classList.add('close');
               }
            }
        }, interval);
    }

    let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0
    };

    const targets = document.querySelectorAll(desktopTarget);

    if(targets.length > 0){

        const scrollLoad = (target) => {
            const io = new IntersectionObserver((entries, observer)=> {

                if (entries != null || entries != undefined) {

                    entries.forEach(entry => {
    
                        if(entry.isIntersecting){
                            let scrollEl = entry.target || entry.srcElement,
                                getPopupID = scrollEl.getAttribute('data-popup-target') ?? null,
                                popupSel = document.getElementById(getPopupID) ?? null;
        
                            //Reveal popup
                            if (popupSel != null || popupSel != undefined) {
                                popupSel.classList.add('fade');
                            }
        
                            observer.disconnect();
    
                            //Show Timer progress
                            window.setTimeout(function(){
                                if (popupSel != null || popupSel != undefined) {
                                    popupSel.classList.add('progress-fill');
                                }
                                delayPopup(1000, 5, getPopupID);
                            }, 1500);
                        } /*else {
                        }*/
                    });
                }
            }, options);
          
            io.observe(target);
        };
    
        targets.forEach(scrollLoad);
    }
}

//Function to add Timer to time popups and close it automatically - detail is from AOS callback
export function closeTimePopup(detail){
    function delayPopup(interval, maxTime, id){
        let index = 0;

        let counter = window.setInterval(function(){
            index++;

            if(index >= maxTime + 1){
                window.clearInterval(counter);
                if (document.getElementById(id) != null || document.getElementById(id) != undefined) {
                    document.getElementById(id).classList.add('close');
                }
            }
        }, interval);
    }

     //Check if its a time popup
     if(detail.getAttribute('data-time-popup') !== null){
        let getPopupID = detail.getAttribute('id'),
            //popupSel = document.getElementById(getPopupID),
            popupDuration = parseInt(detail.getAttribute('data-popup-duration'));

        window.setTimeout(() => {
            // if (popupSel !== null || popupSel !== undefined) {
            //     //popupSel.classList.add('progress-fill');
            //     //document.querySelector('#'+getPopupID+' .timepopups__scroll-bar').style.cssText = 'width:100%;transition:width '+popupDuration+'s ease;';
            // }
            
            try {
                document.querySelector('#'+getPopupID+' .timepopups__scroll-bar').style.cssText = 'width:100%;transition:width '+popupDuration+'s ease;';
            } catch(err) {
                console.log(err.message,getPopupID);
            }

            delayPopup(1000, popupDuration, getPopupID);
        }, 1500);
    }
}

//Function to generate random alphanumeric number
export function randomAlphanumeric(lengthVal=5){
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
        charLength = characters.length;

    let randomStr = "";

    for(let i=0; i < lengthVal; i++){
        const randomNum = Math.floor(Math.random() * charLength);
        randomStr += characters[randomNum];
    }

    return randomStr;
}

//Function permanent close for Timepopup
export function permanentCloseTimePopup(){
    const selBtn = document.querySelectorAll('.timepopups'),
        selBtnLength = selBtn.length;

    for(let i=0; i < selBtnLength; i++){
        selBtn[i].classList.add('close');
    };
}

export default { 
    toggleOverlay,
    closePopup,
    loadScrollerPopup,
    closeTimePopup,
    randomAlphanumeric,
    permanentCloseTimePopup
}