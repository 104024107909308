import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'redux/authentication/slice';
import { setAccessRules } from 'redux/ui/slice';
import { setExtendedProfileData } from 'redux/authentication/slice';
import { useWebsiteConfigDeep } from 'websiteConfig/useWebsiteConfig';

const EncapsulationLogic = () => {
  const [extendedProfileCalled, setExtendedProfileCalled] = useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.authentication);
  const { encapsulationFeature } = useSelector((state) => state.ui);
  const accessRulesSelector = useWebsiteConfigDeep().websiteConfig?.accessRulesSelector;

  if (
    !extendedProfileCalled &&
    encapsulationFeature &&
    data?.user?.userId &&
    data?.token?.token
  ) {
    setExtendedProfileCalled(true);
    const { cegedim_Security_Level, categorie, specialite1 } = data?.additional?.tokenResponse;
    if(cegedim_Security_Level && categorie && specialite1) {
      dispatch(
        setExtendedProfileData({
          owaValues: {
            attributes: {
              cegedim_security_level: [cegedim_Security_Level], 
              categorie: [categorie], 
              specialite1: [specialite1]
            }
          }
        })
      )
    } else {
      dispatch(
        authActions.oneKey.extendedUserProfile(
          data?.user?.userId,
          data?.token?.token
        )
      );
    }
  }
  
  useEffect(()=>{
    if(accessRulesSelector?.value?.elements?.accessRulesPicker?.values){
        dispatch(setAccessRules(accessRulesSelector?.value?.elements?.accessRulesPicker?.values));
    }
  },[accessRulesSelector])
  return null;
};

export { EncapsulationLogic };
