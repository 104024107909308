import {
  getLocationOrigin,
  getDomainFromUrl,
} from 'utils/urlUtils';

const ConfirmAndNavigate = ({ href, message, bypass, target, trackAnalyticsEvent }) => {

  if (bypass) {
    if (trackAnalyticsEvent) {
      trackAnalyticsEvent(href)
    }

    window.open(href, target);
    return;
  }

  // eslint-disable-next-line no-alert
  const confirmed = window.confirm(message);
  if (confirmed) {
    if (trackAnalyticsEvent) {
      trackAnalyticsEvent(href)
    }

    window.open(href, target);
  }
};

const isLinkInternal = ({
  href,
}) => {

  const thisOrigin = getLocationOrigin();
  const thisDomain = getDomainFromUrl(thisOrigin);
  const hrefDomain = getDomainFromUrl(href);

  const linkIsInternal = thisDomain === hrefDomain;
  return linkIsInternal;
};

const dynamicLinkBehaviour = ({
  event,
  history,
  message,
  trackAnalyticsEvent
}) => {
  if (!history) {
    console.error('dynamicLinkBehaviour requires history to be passed in to handle links internally');
  }
  const href = event.target.href;
  const linkIsInternal = isLinkInternal({ href });
  if (linkIsInternal && history) {
    const pathname = event.target.pathname;
    history.push(pathname);
    return;
  }

  ConfirmAndNavigate({ href, message, bypass: event.target.getAttribute('disableprompt'), trackAnalyticsEvent });
};

export {
  dynamicLinkBehaviour,
  ConfirmAndNavigate,
};


