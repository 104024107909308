import React from 'react'
import PropTypes from 'prop-types'
import { ChevronRight, ChevronDown } from 'components/shared/SvgIcons'
import Link from 'components/reusableComponents/link/Link'
import './ChevronButton.scss'

const LINK = 'link'
const BUTTON = 'button'

const LinkButton = ({ 
    external = false, 
    disabled = false, 
    href, returnPath, 
    className, 
    children, 
    onClick, 
    style,
    vvClickAttr,
    vvSnapshotAttr,
    vvSequenceAttr,
}) => {
    if (disabled) {
        return <div className={className}>{children}</div>
    }

    if (external) {
        return (
            <Link 
                className={className} 
                href={href} 
                onClick={onClick}
            >
                {children}
            </Link>
        )
    }

    return (
        <Link
            href={href}
            state={{ returnPath }}
            className={className}
            style={style}
            data-vv-action={vvClickAttr}
            data-vv-snapshot={vvSnapshotAttr}
            data-vv-seq={vvSequenceAttr}
        >
            {children}
        </Link>
    )
}

LinkButton.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    returnPath: PropTypes.string,
    external: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func,
    vvClickAttr: PropTypes.string,
    vvSnapshotAttr: PropTypes.string,
    vvSequenceAttr: PropTypes.string,
}

const NormalButton = ({ 
    className, 
    onClick, 
    children,
    vvClickAttr,
    vvSnapshotAttr,
    vvSequenceAttr,
    vvWaitAttr
}) => (
    <button 
        className={className} 
        type='button' 
        onClick={onClick}
        data-vv-action={vvClickAttr}
        data-vv-snapshot={vvSnapshotAttr}
        data-vv-seq={vvSequenceAttr}
        data-vv-waitAfter={vvWaitAttr}
    >
        {children}
    </button>
)

NormalButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    vvClickAttr: PropTypes.string,
    vvSnapshotAttr: PropTypes.string,
    vvSequenceAttr: PropTypes.string,
}

const buttonTypes = {
    [LINK]: LinkButton,
    [BUTTON]: NormalButton,
}

const ChevronButton = ({ 
    onClick, 
    text, 
    className, 
    internalHref, 
    returnPath, 
    externalHref, 
    disabled, 
    type, 
    variant, 
    chevron, 
    image, 
    style,
    vvClickAttr,
    vvSnapshotAttr,
    vvSequenceAttr,
    vvWaitAttr
}) => {
    const baseClassName = 'chevron-button-component'
    const classNames = [baseClassName, `${baseClassName}-${type}`, className, 'custom-focus', 'ie-11-button-fix']

    if (disabled) {
        classNames.push(`${baseClassName}-disabled`)
    }

    if (variant) {
        classNames.push(`${baseClassName}--${variant}`)
    }

    const ButtonComponent = buttonTypes[type]

    if (!ButtonComponent) {
        console.error(`Cannot find buttonType ${type}`)
        return null
    }

    if (externalHref && internalHref) {
        console.warning('ChevronButton was provided with both an internal and external href. Consider passing in hrefs using the getHref utility function')
    }

    const external = externalHref ? true : false
    const href = external ? externalHref : internalHref
    const Chevron = chevron === 'down' ? ChevronDown : ChevronRight

    return (
        <ButtonComponent 
            onClick={onClick} 
            className={classNames.join(' ')} 
            href={href} disabled={disabled} 
            external={external} 
            returnPath={returnPath} 
            style={style}
            vvClickAttr={vvClickAttr}
            vvSnapshotAttr={vvSnapshotAttr}
            vvSequenceAttr={vvSequenceAttr}
            vvWaitAttr={vvWaitAttr}
        >
            {image?.src && <img src={image.src} alt={image.alt} />}
            <p className='chevron-button-text'>{text}</p>
            <div className={`chevron-button-svg-container chevron-button-svg-container--${chevron}`}>
                <Chevron clearSize />
            </div>
        </ButtonComponent>
    )
}

ChevronButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    className: PropTypes.string,
    internalHref: PropTypes.string,
    returnPath: PropTypes.string,
    externalHref: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    variant: PropTypes.string,
    chevron: PropTypes.string,
    image: PropTypes.object,
    vvClickAttr: PropTypes.string,
    vvSnapshotAttr: PropTypes.string,
    vvSequenceAttr: PropTypes.string,
    vvWaitAttr: PropTypes.string
}

export { ChevronButton, LINK, BUTTON }
