import Tabs from './Tabs'
import TabsContent from './TabsContent'
import Button from 'components/kanso/atoms/button/Button'
import { lazy } from 'react'
import { getComponentName } from 'utils/acousticMappings'
import { getValue, getValues, getSelectionValue } from 'utils/utilityHelper'
import EditorialAcousticData from 'components/kanso/patterns/editorial/EditorialAcousticData'
import ContentCardAcousticData from '../contentCard/ContentCardAcousticData'
import ContentCardTileAcousticData from 'components/kanso/components/contentCardTile/ContentCardTileAcousticData'

// const EditorialAcousticData = lazy(() => import('components/kanso/patterns/editorial/EditorialAcousticData'))
// const ContentCardAcousticData = lazy(() => import('components/kanso/components/contentCard/ContentCardAcousticData'))
// const ContentCardTileAcousticData = lazy(() => import('components/kanso/components/contentCardTile/ContentCardTileAcousticData'))

const TabsAcousticData = (props) => {
  const { 
    align,
    tabContentItems 
  } = props.data

  return (
    <Tabs align={getSelectionValue(align)}>
      {tabContentItems?.values?.map((tabButton, index) => {
        return (
          <Button type="tertiary" text={getValue(tabButton?.elements?.title)} key={index} />
        )
      })}
      {tabContentItems?.values?.map((tabContent, index) => {
        return (
          <TabsContent key={index}>   
            {
              getValues(tabContent?.elements?.tabContent).map((content, index) => {
                if(getComponentName(content.type) === 'Editorial') {
                  return <EditorialAcousticData key={`${content.type}${index}`} data={content?.elements} />
                }
                if(getComponentName(content.type) === 'ContentCard') {
                  return <ContentCardAcousticData key={`${content.type}${index}`} data={content?.elements} />
                }
                if(getComponentName(content.type) === 'ContentCardTile') {
                  return <ContentCardTileAcousticData key={`${content.type}${index}`} data={content?.elements} />
                }
              })
            }
          </TabsContent>
        )
      })}
    </Tabs>
  )
}

export default TabsAcousticData
