import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import {
  INPUT,
  BUTTON,
  LINK_BUTTON,
  commonEmailValidations,
  FORGOTTEN_PASSWORD,
} from 'components/shared/form/formConstants';
import {
  LOGIN_PAGE_PATH,
} from 'routes/routeConstants';

const useForgottenPasswordFields = () => {

  const {
    tEmail,
    tEnterYourEmail,
    tContinueText,
    tCancel,
  } = useWebsiteConfigObject();

  const forgottenPasswordFields = [
    {
      id: FORGOTTEN_PASSWORD,
      formFields: [
        {
          label: tEmail,
          id: 'email',
          initialValue: '',
          type: INPUT,
          typeParams: {
            type: 'text',
            autoComplete: 'email',
            placeholder: tEnterYourEmail,
          },
          validation: [
            ...commonEmailValidations,
          ],
        },
        {
          id: 'forgotten-password',
          type: BUTTON,
          size: 50,
          typeParams: {
            callBack: ({ formValues, actions }) => {
              actions.handleForgottenPassword(formValues);
            },
            text: tContinueText,
            type: 'submit',
          },
        },
        {
          id: 'cancel-forgotten-password',
          type: LINK_BUTTON,
          size: 50,
          typeParams: {
            to: LOGIN_PAGE_PATH,
            text: tCancel,
            style: 'blank',
          },
        },
      ],
    },
  ];

  return forgottenPasswordFields;
};

export { useForgottenPasswordFields };

