import { DATA_VEEVA_URL } from './paths';
import { authGetQuery } from '../AxiosQueries';
import { getEnvApiConfigValues } from 'utils/envUtils';
import { getAuthHeaderConfig } from 'utils/authUtils';
/**
 * Call Veeva Api to validate user
 * Returns the user informations or null
 */

const { audience } = getEnvApiConfigValues();
const url = DATA_VEEVA_URL;

const validateUser = (userId) => {
  if (!userId) return null;
  const veevaUrl = `${url}/${userId}`;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
  });
  return authGetQuery({ url: veevaUrl, config: headerConfig });
};

export { validateUser };
