import React, { useState } from 'react';

import './ColumnSection.scss';

import {
	Module_Column_Container,
	Module_Accordion,
	Module_Text_Block,
	Module_Centred_Icon,
	Component_Aligned_Icon,
	Module_Infographic,
	Module_Compound_Infographic,
	Module_Image_Block,
	Module_CallOut,
	Module_Circle_CallOut,
	Module_Caroussel_Item
} from '../../../../api/acousticApi/ContentTypesInfographics';
import ContentCard from 'modules/contentCard/ContentCard';
import IconText from './IconText';
import IconLink from './IconLink';
import InfographicsDiagram from './InfographicsDiagram/InfographicsDiagram';
import InfographicsDiagramdopaminergic from './InfographicsDiagram/InfographicsDiagramdopaminergic';
import ImageBlock from './ImageBlock';
import CalloutBulletsSmallBlock from './CalloutBullets/CalloutBulletsSmallBlock';
import CalloutBullets from './CalloutBullets/CalloutBullets';
import CircleCallOut from './CircleCallOut';

import RichText from 'components/reusableComponents/richText/RichText';
import CallOut from './CallOut/CallOut';


const ColumnSection = (props) => {
	const {
		displayColumns,
		columnFour,
		columnThree,
		columnTwo,
		columnone,
		columnwiseDisplayInMobile,
	} = props?.elements?.elements

	const { hp } = props;
	const disableTimePopUp = (isDisable) => {
        props.onDisableTimePopUp(isDisable)
	}
	let columnValue = displayColumns?.value?.selection;
	let PopUpIndex= 0;
	let iconTextIndex= 0;
	const nbrLignes = columnone?.values?.length > columnTwo?.values?.length ? columnone?.values : columnTwo?.values;
	if(columnValue === "two-column-equal" && columnwiseDisplayInMobile?.value === true){
		const newColumnwiseDisplayInMobile = columnwiseDisplayInMobile?.value ? 'colum_section--columnwise-mobile':'';
		return(
			<div className={`colum_section fusion colum_section--two-column ${hp ? 'colum_section--hp':''} ${newColumnwiseDisplayInMobile}`}>
				{
					nbrLignes &&  nbrLignes.map((elements,index)=>{
								const columnOneElements = columnone?.values[index];
								const columnTwoElements = columnTwo?.values[index];
								return (
									<div className='column_section--two-fusion'>
										{
											columnone?.values[index]?.typeId === Module_Centred_Icon ? 
												<IconText key={iconTextIndex++} iconTextContent={columnOneElements} pid={`iconviews${iconTextIndex++}`} />
											: columnone?.values[index]?.typeId === Component_Aligned_Icon ?
												<IconLink key={PopUpIndex++} iconItemContent={columnOneElements} pid={`pid${PopUpIndex++}`} />
											: columnone?.values[index]?.typeId === Module_Infographic ? 
												<div>
												<InfographicsDiagramdopaminergic
												elements={columnOneElements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
												image= {columnOneElements?.elements?.imageMap?.value?.imagePicker}
												areas= {columnOneElements?.elements?.imageMap?.value?.areaSpecificContents}
												id={columnone?.values[index]?.id}
												timedPopup = {columnOneElements?.elements?.timedPopup?.value} 
												size={"small"}
												/>  
												</div>
											: columnone?.values[index]?.typeId === Module_Compound_Infographic  ?
												<div>
													<InfographicsDiagram
													elements={columnOneElements?.elements}
													onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
													infographicsName={columnOneElements?.name} 									
													/>
												</div>
											: columnone?.values[index]?.typeId === Module_Image_Block  ?
												<div>
													<ImageBlock
														size={"small"}
														elements={columnOneElements}
													/>
												</div>
											: columnone?.values[index]?.typeId === Module_CallOut  ?
												<div className="container">
													<CallOut calloutContent={columnOneElements} id={"calloutSm"}/>
												</div>
											:columnone?.values[index]?.typeId === Module_Text_Block  ?
											<div>
												<CalloutBulletsSmallBlock
													element={columnOneElements}
													index={index++}
												/> 
											</div>
											
											:columnone?.values[index]?.typeId === Module_Caroussel_Item  ?
											<div>
											<ContentCard
												key={index}
												theme='primary'
												href={columnOneElements?.elements?.link?.linkURL}
												heading={columnOneElements?.elements?.title?.value}
												category={columnOneElements?.elements?.category?.value}
												linkText={columnOneElements?.elements?.link?.linkText}
												brandLogo = {columnOneElements?.elements?.brandLogo?.value}
												bgImage={columnOneElements?.elements?.image}
												links={columnOneElements?.elements?.link}
												section={`${index+1}A`}
												clickable
												alternateBg
											/>
											</div>
											:<div></div>
										}
										{
											columnTwo?.values[index]?.typeId === Module_Centred_Icon ? 
												<IconText key={iconTextIndex++} iconTextContent={columnTwoElements} pid={`iconviews${iconTextIndex++}`} />
											:columnTwo?.values[index]?.typeId === Component_Aligned_Icon  ?
												<IconLink key={PopUpIndex++} iconItemContent={columnTwoElements} pid={`pid${PopUpIndex++}`} />
											: columnTwo?.values[index]?.typeId === Module_Infographic ? 
												<div>
													<InfographicsDiagramdopaminergic
													elements={columnTwoElements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
													image= {columnTwoElements?.elements?.imageMap?.value?.imagePicker}
													areas= {columnTwoElements?.elements?.imageMap?.value?.areaSpecificContents}
													id={columnTwo?.values[index]?.id}
													timedPopup = {columnTwoElements?.elements?.timedPopup?.value} 
													size={"small"}
													/>  
												</div>
											: columnTwo?.values[index]?.typeId === Module_Compound_Infographic  ?
											<div>
												<InfographicsDiagram
												elements={columnTwoElements?.elements}
												onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
												infographicsName={columnTwoElements?.name} 									
												/>
												</div>
											: columnTwo?.values[index]?.typeId === Module_Image_Block  ?
												<div>
													<ImageBlock
													size={"small"}
													elements={columnTwoElements}
													/>
												</div>
											: columnTwo?.values[index]?.typeId === Module_CallOut  ?
												<div className="container">
													<CallOut calloutContent={columnTwoElements} id={"calloutSm"}/>
												</div>
											:columnTwo?.values[index]?.typeId === Module_Text_Block  ?
												<div>
													<CalloutBulletsSmallBlock
														element={columnTwoElements}
														index={index++}
													/> 
												</div>
											:columnTwo?.values[index]?.typeId === Module_Caroussel_Item  ?
											<div>
												<ContentCard
													key={index}
													theme='primary'
													href={columnTwoElements?.elements?.link?.linkURL}
													heading={columnTwoElements?.elements?.title?.value}
													category={columnTwoElements?.elements?.category?.value}
													linkText={columnTwoElements?.elements?.link?.linkText}
													brandLogo = {columnTwoElements?.elements?.brandLogo?.value}
													bgImage={columnTwoElements?.elements?.image}
													links={columnTwoElements?.elements?.link}
													section={`${index+1}B`}
													clickable
													alternateBg
												/>
											</div>
											: <div></div>
										}
									</div>
								)
					})
				}
			</div>
		)	
	}

  return (
		columnValue === "two-column-equal" ?
			<div className={`colum_section colum_section--two-column ${hp ? 'colum_section--hp':''} ${columnwiseDisplayInMobile?.value ? 'colum_section--columnwise-mobile':''}`}>
				<div className="twoColumn_section-first">
					{
						columnone && columnone?.values?.map((elements, index) => (
							columnone?.values[index]?.typeId === Module_Centred_Icon ? 
								<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
							: columnone?.values[index]?.typeId === Component_Aligned_Icon ?
								<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
							: columnone?.values[index]?.typeId === Module_Infographic ? 
								<div>
								<InfographicsDiagramdopaminergic
								elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
								image= {elements?.elements?.imageMap?.value?.imagePicker}
								areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
								id={columnone?.values[index].id}
								timedPopup = {elements?.elements?.timedPopup?.value} 
								size={"small"}
								/>  
								</div>
							: columnone?.values[index]?.typeId === Module_Compound_Infographic  ?
								<div>
									<InfographicsDiagram
									elements={elements?.elements}
									onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
									infographicsName={elements?.name} 									
									/>
								</div>
							: columnone?.values[index]?.typeId === Module_Image_Block  ?
								<div>
									<ImageBlock
										size={"small"}
										elements={elements}
									/>
								</div>
							: columnone?.values[index]?.typeId === Module_CallOut  ?
								<div className="container">
									<CallOut calloutContent={elements} id={"calloutSm"}/>
								</div>
							:columnone?.values[index]?.typeId === Module_Text_Block  ?
							<div>
								<CalloutBulletsSmallBlock
									element={elements}
									index={index++}
								/> 
							</div>
							
							:columnone?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
								key={index}
								theme='primary'
								href={elements?.elements?.link?.linkURL}
								heading={elements?.elements?.title?.value}
								category={elements?.elements?.category?.value}
								linkText={elements?.elements?.link?.linkText}
								brandLogo = {elements?.elements?.brandLogo?.value}
								bgImage={elements?.elements?.image}
								links={elements?.elements?.link}
								section={`${index+1}A`}
								clickable
								alternateBg
							/>
							</div>
							:null
						))
					}
				</div>
				<div className="twoColumn_section-second"> 
					{
						columnTwo && columnTwo?.values?.map((elements, index) => (
							columnTwo?.values[index]?.typeId === Module_Centred_Icon ? 
								<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
							:columnTwo?.values[index]?.typeId === Component_Aligned_Icon  ?
								<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
							: columnTwo?.values[index]?.typeId === Module_Infographic ? 
								<div>
									<InfographicsDiagramdopaminergic
									elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
									image= {elements?.elements?.imageMap?.value?.imagePicker}
									areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
									id={columnTwo?.values[index].id}
									timedPopup = {elements?.elements?.timedPopup?.value} 
									size={"small"}
									/>  
								</div>
							: columnTwo?.values[index]?.typeId === Module_Compound_Infographic  ?
							<div>
								<InfographicsDiagram
								elements={elements?.elements}
								onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
								infographicsName={elements?.name} 									
								/>
								</div>
							: columnTwo?.values[index]?.typeId === Module_Image_Block  ?
								<div>
									<ImageBlock
									size={"small"}
									elements={elements}
									/>
								</div>
							: columnTwo?.values[index]?.typeId === Module_CallOut  ?
								<div className="container">
									<CallOut calloutContent={elements} id={"calloutSm"}/>
								</div>
							:columnTwo?.values[index]?.typeId === Module_Text_Block  ?
								<div>
									<CalloutBulletsSmallBlock
										element={elements}
										index={index++}
									/> 
								</div>
							:columnTwo?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
								<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									links={elements?.elements?.link}
									section={`${index+1}A`}
									clickable
									alternateBg
								/>
							</div>
							: null
						))
					}
				</div>
		</div>:		
		columnValue === "four-column-equal" ?
			<div className={`colum_section colum_section--four-column ${hp ? 'colum_section--hp':''}`}>
				<div className="twoColumn_section-first"> 
				{columnone && columnone?.values?.map((elements, index) => (
					columnone?.values[index]?.typeId === Module_Centred_Icon ? 
						<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
					:columnone?.values[index]?.typeId === Component_Aligned_Icon  ?
						<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
					: columnone?.values[index]?.typeId === Module_CallOut  ?
						<CallOut calloutContent={elements} id={"calloutFourColumn"} />
					: columnone?.values[index]?.typeId === Module_Circle_CallOut  ?
						<div className="container">
							<CircleCallOut circleCalloutContent={elements} />
						</div>
					: columnone?.values[index]?.typeId === Module_Infographic ? 
						<div>
							<InfographicsDiagramdopaminergic
							elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
							image= {elements?.elements?.imageMap?.value?.imagePicker}
							areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
							id={columnTwo?.values[index].id}
							timedPopup = {elements?.elements?.timedPopup?.value} 
							size={"small"}
							/>  
						</div>
					: columnone?.values[index]?.typeId === Module_Compound_Infographic  ?
					<div>
						<InfographicsDiagram
						elements={elements?.elements}
						onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
						infographicsName={elements?.name} 									
						/>
						</div>
					: columnone?.values[index]?.typeId === Module_Image_Block  ?
						<div>
							<ImageBlock
							size={"small"}
							elements={elements}
							/>
						</div>
					: columnone?.values[index]?.typeId === Module_Circle_CallOut  ?
						<div className="container">
							<CircleCallOut circleCalloutContent={elements} />
						</div>
					:columnone?.values[index]?.typeId === Module_Text_Block  ?
						<div>
							<CalloutBulletsSmallBlock
								element={elements}
								index={index++}
							/> 
						</div>
					:columnone?.values[index]?.typeId === Module_Caroussel_Item  ?
						<div>
								<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
						</div>
					: null
					))
				}
				</div>
				<div className="twoColumn_section-first"> 
				{
					columnTwo && columnTwo?.values?.map((elements, index) => (
						columnTwo?.values[index]?.typeId === Module_Centred_Icon ? 
							<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
						:columnTwo?.values[index]?.typeId === Component_Aligned_Icon  ?
							<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
						: columnTwo?.values[index]?.typeId === Module_CallOut  ?
							<CallOut calloutContent={elements} id={"calloutFourColumn"} />
						: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						: columnTwo?.values[index]?.typeId === Module_Infographic ? 
							<div>
								<InfographicsDiagramdopaminergic
								elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
								image= {elements?.elements?.imageMap?.value?.imagePicker}
								areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
								id={columnTwo?.values[index].id}
								timedPopup = {elements?.elements?.timedPopup?.value} 
								size={"small"}
								/>  
							</div>
						: columnTwo?.values[index]?.typeId === Module_Compound_Infographic  ?
						<div>
							<InfographicsDiagram
							elements={elements?.elements}
							onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
							infographicsName={elements?.name} 									
							/>
							</div>
						: columnTwo?.values[index]?.typeId === Module_Image_Block  ?
							<div>
								<ImageBlock
								size={"small"}
								elements={elements}
								/>
							</div>
						: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						:columnTwo?.values[index]?.typeId === Module_Text_Block  ?
							<div>
								<CalloutBulletsSmallBlock
									element={elements}
									index={index++}
								/> 
							</div>
						:columnTwo?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
							</div>
						: null
					))
				}
				</div>
				<div className="twoColumn_section-first"> 
				{
					columnThree && columnThree?.values?.map((elements, index) => (
						columnThree?.values[index]?.typeId === Module_Centred_Icon ? 
							<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
						:columnThree?.values[index]?.typeId === Component_Aligned_Icon  ?
							<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
						: columnThree?.values[index]?.typeId === Module_CallOut  ?
							<CallOut calloutContent={elements} id={"calloutFourColumn"} />
						:columnThree?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
							</div>
						:null 
					))
				}
				</div>
				<div className="twoColumn_section-first"> 
				{
					columnFour && columnFour?.values?.map((elements, index) => (
						columnFour?.values[index]?.typeId === Module_Centred_Icon ? 
						<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
						:columnFour?.values[index]?.typeId === Component_Aligned_Icon  ?
							<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
						: columnFour?.values[index]?.typeId === Module_CallOut  ?
							<CallOut calloutContent={elements} id={"calloutFourColumn"} />
						:columnFour?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
							</div>
						:null 
					))
				}
				</div>
		</div>:
			columnValue === "three-column-equal" ?
			<div className={`colum_section colum_section--three-column ${hp ? 'colum_section--hp':''}`}>
				<div className="threeColumn_section-first"> 
				{columnone && columnone?.values?.map((elements, index) => (
					columnone?.values[index]?.typeId === Module_Centred_Icon ? 
						<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
					:columnone?.values[index]?.typeId === Component_Aligned_Icon  ?
						<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
					: columnone?.values[index]?.typeId === Module_CallOut  ?
						<CallOut calloutContent={elements} id={"calloutThreeColumn"} />
					: columnone?.values[index]?.typeId === Module_Circle_CallOut  ?
						<div className="container">
							<CircleCallOut circleCalloutContent={elements} />
						</div>
					: columnone?.values[index]?.typeId === Module_Infographic ? 
						<div>
							<InfographicsDiagramdopaminergic
							elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
							image= {elements?.elements?.imageMap?.value?.imagePicker}
							areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
							id={columnTwo?.values[index].id}
							timedPopup = {elements?.elements?.timedPopup?.value} 
							size={"small"}
							/>  
						</div>
					: columnone?.values[index]?.typeId === Module_Compound_Infographic  ?
					<div>
						<InfographicsDiagram
						elements={elements?.elements}
						onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
						infographicsName={elements?.name} 									
						/>
						</div>
					: columnone?.values[index]?.typeId === Module_Image_Block  ?
						<div>
							<ImageBlock
							size={"small"}
							elements={elements}
							/>
						</div>
					: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
						<div className="container">
							<CircleCallOut circleCalloutContent={elements} />
						</div>
					:columnone?.values[index]?.typeId === Module_Text_Block  ?
						<div>
							<CalloutBulletsSmallBlock
								element={elements}
								index={index++}
							/> 
						</div>
					:columnone?.values[index]?.typeId === Module_Caroussel_Item  ?
						<div>
						<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
						</div>
					: null
					))
				}
				</div>
				<div className="threeColumn_section-second"> 
				{
					columnTwo && columnTwo?.values?.map((elements, index) => (
						columnTwo?.values[index]?.typeId === Module_Centred_Icon ? 
							<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
						:columnTwo?.values[index]?.typeId === Component_Aligned_Icon  ?
							<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
						: columnTwo?.values[index]?.typeId === Module_CallOut  ?
							<CallOut calloutContent={elements} id={"calloutThreeColumn"} />
						: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						: columnTwo?.values[index]?.typeId === Module_Infographic ? 
							<div>
								<InfographicsDiagramdopaminergic
								elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
								image= {elements?.elements?.imageMap?.value?.imagePicker}
								areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
								id={columnTwo?.values[index].id}
								timedPopup = {elements?.elements?.timedPopup?.value} 
								size={"small"}
								/>  
							</div>
						: columnTwo?.values[index]?.typeId === Module_Compound_Infographic  ?
						<div>
							<InfographicsDiagram
							elements={elements?.elements}
							onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
							infographicsName={elements?.name} 									
							/>
							</div>
						: columnTwo?.values[index]?.typeId === Module_Image_Block  ?
							<div>
								<ImageBlock
								size={"small"}
								elements={elements}
								/>
							</div>
						: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						:columnTwo?.values[index]?.typeId === Module_Text_Block  ?
							<div>
								<CalloutBulletsSmallBlock
									element={elements}
									index={index++}
								/> 
							</div>
						:columnTwo?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
							</div>
						: null
					))
				}
				</div>
				<div className="threeColumn_section-third"> 
				{
					columnThree && columnThree?.values?.map((elements, index) => (
						columnThree?.values[index]?.typeId === Module_Centred_Icon ? 
							<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
						:columnThree?.values[index]?.typeId === Component_Aligned_Icon  ?
							<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
						: columnThree?.values[index]?.typeId === Module_CallOut  ?
							<CallOut calloutContent={elements} id={"calloutThreeColumn"} />
						: columnThree?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						: columnThree?.values[index]?.typeId === Module_Infographic ? 
							<div>
								<InfographicsDiagramdopaminergic
								elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
								image= {elements?.elements?.imageMap?.value?.imagePicker}
								areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
								id={columnTwo?.values[index].id}
								timedPopup = {elements?.elements?.timedPopup?.value} 
								size={"small"}
								/>  
							</div>
						: columnThree?.values[index]?.typeId === Module_Compound_Infographic  ?
						<div>
							<InfographicsDiagram
							elements={elements?.elements}
							onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
							infographicsName={elements?.name} 									
							/>
							</div>
						: columnThree?.values[index]?.typeId === Module_Image_Block  ?
							<div>
								<ImageBlock
								size={"small"}
								elements={elements}
								/>
							</div>
						: columnThree?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						:columnThree?.values[index]?.typeId === Module_Text_Block  ?
							<div>
								<CalloutBulletsSmallBlock
									element={elements}
									index={index++}
								/> 
							</div>
						:columnThree?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
							</div>
						: null
					))
				}
				</div>
			</div>:
			<div className="colum_section">
				<div  className={columnValue === "two-column-wide-left" ? "one-column-left-wide":"one-column-left-sm"}> 
				{columnone && columnone?.values?.map((elements, index) => (
					columnone?.values[index]?.typeId === Module_Centred_Icon ? 
						<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
					:columnone?.values[index]?.typeId === Component_Aligned_Icon  ?
						<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
					: columnone?.values[index]?.typeId === Module_CallOut  ?
						<CallOut calloutContent={elements} id={"calloutSm"} />
					: columnone?.values[index]?.typeId === Module_Circle_CallOut  ?
						<div className="container">
							<CircleCallOut circleCalloutContent={elements} />
						</div>
					: columnone?.values[index]?.typeId === Module_Infographic ? 
						<div>
							<InfographicsDiagramdopaminergic
							elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
							image= {elements?.elements?.imageMap?.value?.imagePicker}
							areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
							id={columnTwo?.values[index].id}
							timedPopup = {elements?.elements?.timedPopup?.value} 
							size={"small"}
							/>  
						</div>
					: columnone?.values[index]?.typeId === Module_Compound_Infographic  ?
					<div>
						<InfographicsDiagram
						elements={elements?.elements}
						onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
						infographicsName={elements?.name} 									
						/>
						</div>
					: columnone?.values[index]?.typeId === Module_Image_Block  ?
						<div>
							<ImageBlock
							size={"small"}
							elements={elements}
							/>
						</div>
					: columnone?.values[index]?.typeId === Module_Circle_CallOut  ?
						<div className="container">
							<CircleCallOut circleCalloutContent={elements} />
						</div>
					:columnone?.values[index]?.typeId === Module_Text_Block  ?
						<div className="" >
							<CalloutBullets
								element={elements}
								index={index++}
							/> 
						</div>
					:columnone?.values[index]?.typeId === Module_Caroussel_Item  ?
						<div>
						<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
						</div>
					: null
					))
				}
				</div>
				<div className={columnValue === "two-column-wide-right" ? "one-column-right-wide":"one-column-right-sm"}> 
				{
					columnTwo && columnTwo?.values?.map((elements, index) => (
						columnTwo?.values[index]?.typeId === Module_Centred_Icon ? 
							<IconText key={iconTextIndex++} iconTextContent={elements} pid={`iconviews${iconTextIndex++}`} />
						:columnTwo?.values[index]?.typeId === Component_Aligned_Icon  ?
							<IconLink key={PopUpIndex++} iconItemContent={elements} pid={`pid${PopUpIndex++}`} />
						: columnTwo?.values[index]?.typeId === Module_CallOut  ?
							<CallOut calloutContent={elements} id={"calloutSm"} />
						: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						: columnTwo?.values[index]?.typeId === Module_Infographic ? 
							<div>
								<InfographicsDiagramdopaminergic
								elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
								image= {elements?.elements?.imageMap?.value?.imagePicker}
								areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
								id={columnTwo?.values[index].id}
								timedPopup = {elements?.elements?.timedPopup?.value} 
								size={"small"}
								/>  
							</div>
						: columnTwo?.values[index]?.typeId === Module_Compound_Infographic  ?
							<div>
								<InfographicsDiagram
								elements={elements?.elements}
								onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)}
								infographicsName={elements?.name} 									
								/>
							</div>
						: columnTwo?.values[index]?.typeId === Module_Image_Block  ?
							<div>
								<ImageBlock
								size={"small"}
								elements={elements}
								/>
							</div>
						: columnTwo?.values[index]?.typeId === Module_Circle_CallOut  ?
							<div className="container">
								<CircleCallOut circleCalloutContent={elements} />
							</div>
						:columnTwo?.values[index]?.typeId === Module_Text_Block  ?
							<div className="" >
								<CalloutBullets
									element={elements}
									index={index++}
								/> 
							</div>
						:columnTwo?.values[index]?.typeId === Module_Caroussel_Item  ?
							<div>
							<ContentCard
									key={index}
									theme='primary'
									href={elements?.elements?.link?.linkURL}
									heading={elements?.elements?.title?.value}
									category={elements?.elements?.category?.value}
									linkText={elements?.elements?.link?.linkText}
									brandLogo = {elements?.elements?.brandLogo?.value}
									bgImage={elements?.elements?.image}
									clickable
									alternateBg
								/>
							</div>
						: null
						)
				)
				}
				</div>
			</div>
  );
}

export default ColumnSection;


