import { useQuery } from 'react-query';
import {  useEffect } from 'react'
import { getAPIContent } from 'api/acousticApi/index';
import { getUrlCountryData } from 'utils/libraryUtils';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { useSelector,useDispatch, } from 'react-redux';
import { userActions } from 'redux/user/slice';

const AUTH_EXTENSION_QUERY_KEY = 'auth-extension';

const useAuthExtensionWhitelist = () => {

  const whitelistedDomainConfig = useWebsiteConfig()
  const whitelistedDomains = whitelistedDomainConfig?.websiteConfig?.whitelistedDomains;
  
  function retrieveDomains(){
    let domainWWW;
    let domainHttp;
    let foundWWW;
    let foundHttp;
    let regexPatternWWW = '\www\.'
    let regexPatternHttp = '//'
    let domainList = new Array();
    if(whitelistedDomains!= undefined){
      const keysDomains= Object.values(whitelistedDomains);
      for (const item of keysDomains) {
        if(item) {
          foundWWW = item.match(regexPatternWWW);
          if(foundWWW){
            domainWWW= item.substring(foundWWW.index+4);
            domainList.push(domainWWW);
          }
          else{
            foundHttp= item.match(regexPatternHttp);
            if(foundHttp){
              domainHttp= item.substring(foundHttp.index+2);
              domainList.push(domainHttp)
            }
            else{
              domainList.push(item)
            }
          }
        }
      }
    }
    
    return domainList
  } 
  
  const { data } = useQuery(
    AUTH_EXTENSION_QUERY_KEY,
    () => getAPIContent(getUrlCountryData()?.whitelistId),
    { staleTime: Infinity }, // only request the auth whitelist once
  );

  const whitelist = data?.elements?.whitelistedSites?.values.map((site) => {
      const { siteName, siteUrl, disablePrompt, allowGuest } = site?.elements || {}
      let domain= retrieveDomains(siteUrl);
      return {
          domains: domain,
          name: siteName?.value,
          url: siteUrl?.value,
          disableExternalMessage:  disablePrompt?.value,
          generateUserId: allowGuest?.value
      }
  })

  return { whitelist }
}

export default useAuthExtensionWhitelist