import React, {
  useEffect, createRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { clearAllData } from 'redux/authentication/slice';
import { ChevronButton, BUTTON } from 'components/shared/ChevronButton';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import RichText from 'components/reusableComponents/richText/RichText'
import { useHistory } from 'react-router-dom';
import './ContentGate.scss';

// This paywall is an alternative to the paywall tied to access levels for self cert markets.
// This paywall can be used for external auth markets such as markets that use DocCheck and OneKey.
const ContentGatePopUp = ({ type }) => {
  const {
    limitedProfileDescription,
    limitedProfileTitle,
    limitedProfileLabel,
    limitedProfileLink,
    tToastBarText,
    adverseReportingUrl,
    tAdverseReportingLinkText,
    adverseReportingLinkLogo,
  } = useWebsiteConfigObject();

  const history = useHistory();
  const dispatch = useDispatch();
  const goBackBtnRedirection = () => {
    if(type === "encapsulated") {
      history.goBack();
    } else {
      dispatch(clearAllData());
      history.push(limitedProfileLink);
    }
  }
  const blockModifierClass = tToastBarText?.value || adverseReportingUrl || tAdverseReportingLinkText || adverseReportingLinkLogo?.value ? " content-gate--toastbar-present" : ""

  return (
    <div
      className={`content-gate content-gate--limited-access${blockModifierClass}`}>
      <div className="container">
        <h3 className="content-gate__title">
          <RichText data={limitedProfileTitle}/>
        </h3>

        <p className="content-gate__text">
          <RichText data={limitedProfileDescription}/>
        </p>

        <div className="content-gate__footer">
          <div className="content-gate__cta-wrapper">
            <ChevronButton
              type={BUTTON}
              text={limitedProfileLabel}
              variant="paywall-btn"
              chevron="right"
              onClick={goBackBtnRedirection}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

ContentGatePopUp.propTypes = {
  fullPage: PropTypes.bool,
  withBlur: PropTypes.bool,
  buttonLogo: PropTypes.object,
  loginRedirect: PropTypes.func,
  registerRedirect: PropTypes.func,
};

const ContentGateContainer = ({
  isSecure,
  canvas,
  browserSupported,
  buttonLogo,
  loginRedirect,
  registerRedirect,
  type,
}) => {
  const contentGateRef = createRef();
  const { contentGateBlurLevel } = useWebsiteConfigObject();

  useEffect(() => {
    const contentWrapper = document.querySelectorAll('[role="main"]')[0]

    if (contentWrapper) {
      contentWrapper.setAttribute("id", "blurredContentWrapper");
    }

    if (contentGateRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(contentGateRef.current);
    }

    return () => {
      const blurWrapper = document.getElementById("blurredContentWrapper");

      if (blurWrapper) {
        blurWrapper.removeAttribute("id");
      }

      clearAllBodyScrollLocks();
    };
  }, [contentGateRef]);

  return (
    <>
      <div
        ref={contentGateRef}
        className="content-gate__overlay content-gate__overlay--limited-access">
        {
          browserSupported && (
            <>
              <svg id="svgBlur" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <filter id="blur">
                  <feGaussianBlur stdDeviation={`${contentGateBlurLevel || 10}`} />
                </filter>
              </svg>
              {
                isSecure && <img src={canvas} alt="Blurred Content" className="blur" />
              }
            </>
          )
        }
      </div>

      <ContentGatePopUp type={type} />
    </>
  );
};

export { ContentGateContainer as ContentGate };


