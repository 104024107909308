import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWebsiteConfigObject, translateFormFieldsInArrays } from 'websiteConfig/useWebsiteConfig';
import { useLocationSearchParameter } from 'utils/urlUtils';
import { getUrlCountryPath } from 'utils/urlUtils';
import { ExternalForm } from 'components/shared/form/ExternalForm';
import { LoginSideText } from 'components/pageTemplates/login/LoginSideText';
import {
  ONEKEY,
  getAuthServiceRedirects,
} from 'api/authService';
import {
  HOME_PAGE_PATH,
} from 'routes/routeConstants';
import {
  externalFormFields,
} from './formElements';
import { LoginButtonClickFunction, RegisterButtonClickFunction } from 'utils/tealiumUtils';

const OWALogin = () => {
  const history = useHistory();
  const { limitedAccess, loggedIn } = useSelector((state) => state.authentication.status);
  const { serviceDisabled, errorMessage } = useSelector((state) => state.ui);
  const { returnPath } = history.location && history.location.state ? history.location.state : {};
  // if a user is redirected to the homepage from another page and somehow gets logged in between that time
  // redirect the user to a returnPath or home page if there is none.
  const destination = returnPath ? returnPath : HOME_PAGE_PATH;
  const ReturnUrl = useLocationSearchParameter('returnUrl');

  const translations = useWebsiteConfigObject();
  const {
    moodleRedirectConfirmationAlertOnGlobalLandingPage,
    tLogIn,
    tRegister,
  } = useWebsiteConfigObject();
  const notifyUser = () => {
    alert(errorMessage);
  }
  const moodleRedirect = () => {
    const localReturnUrl = sessionStorage.getItem('MoodleReturnUrl');
    const MoodleRedirectionObj = JSON.parse(localReturnUrl);
    if (ReturnUrl && MoodleRedirectionObj?.ReturnUrl && !MoodleRedirectionObj?.isGlobal) {
      const MoodleRedirectionObj = {
        ReturnUrl: decodeURIComponent(ReturnUrl),
        isGlobal: getUrlCountryPath() === "" ? true : false,
        redirect: true,
      };
      const MoodleRedirection = JSON.stringify(MoodleRedirectionObj);
      sessionStorage.setItem('MoodleReturnUrl', MoodleRedirection);
    }
    if(!ReturnUrl && !MoodleRedirectionObj?.isGlobal) {
      sessionStorage.removeItem('MoodleReturnUrl');
    }
  }
  const loginFunction = () => {
    moodleRedirect();
    if(moodleRedirectConfirmationAlertOnGlobalLandingPage && ReturnUrl){
      let alerMsg = moodleRedirectConfirmationAlertOnGlobalLandingPage;
      alerMsg = alerMsg.replaceAll("{externalLink}", decodeURIComponent(ReturnUrl));
      alert(alerMsg)
    }
    if(history.action === "PUSH") {
      if(window.utag) {
        LoginButtonClickFunction(tLogIn ? tLogIn.toUpperCase() : "LOG IN");
      } 
    }
    getAuthServiceRedirects({ service: ONEKEY, disabled: true }).login();
  }
  const registerFunction = () => {
    moodleRedirect();
    if(moodleRedirectConfirmationAlertOnGlobalLandingPage && ReturnUrl){
      let alerMsg = moodleRedirectConfirmationAlertOnGlobalLandingPage;
      alerMsg = alerMsg.replaceAll("{externalLink}", decodeURIComponent(ReturnUrl));
      alert(alerMsg)
    }
    if(history.action === "PUSH") {
      if(window.utag) {
        RegisterButtonClickFunction(tRegister ? tRegister.toUpperCase() : "REGISTER");
      } 
    }
    getAuthServiceRedirects({ service: ONEKEY, disabled: true }).register();
  }
  const loginAction = serviceDisabled ? notifyUser : loginFunction;
  const registerAction = serviceDisabled ? notifyUser : registerFunction;

  if (loggedIn && !limitedAccess) {
    return (
      <Redirect
        to={{
          pathname: destination,
        }}
      />
    );
  }

  return (
    <div className="login-page-component">
      <div className="login-page-container">
        <div className="page-content">
          <LoginSideText />
          <div
            className="login-page-form"
          >
            <div className="tab-form-component">
              <ExternalForm
                // formFields={translateFormFieldsInArrays(externalFormFields, translations)}
                externalFormFields={translateFormFieldsInArrays(externalFormFields, translations)}
                actions={{ loginAction, registerAction }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export { OWALogin };
