import { FORGOTTEN_PASSWORD_PATH } from "routes/routeConstants";
import {
  INPUT,
  LINK,
  BUTTON,
  LOGIN,
  REGISTER,
  COMPONENT,
  CHECKBOX,
  OPTIN_HCP,
  RADIO,
  DROPDOWN_DEFAULT,
  commonShouldHaveValue,
  commonShouldHaveValueRadio,
  SHOULD_MATCH_FIELD,
  SHOULD_CONTAIN_ONLY_NUMBER,
  SHOULD_ACCEPT_CHECKBOX,
  commonShouldAcceptConsent,
  SHOULD_ACCEPT_CONSENT,
  commonEmailValidations,
  commonNewPasswordValidations,
  ROLE_DROPDOWN,
} from "components/shared/form/formConstants";
// import { WithdrawConsent } from "components/pageTemplates/login/WithdrawConsent";
import { MandatoryKey } from "components/pageTemplates/login/MandatoryKey";
import { TermsLabel } from "components/pageTemplates/login/Terms-label";
import { PrivacyConsent } from "components/pageTemplates/login/PrivacyConsent";
import { MarketingLabel } from "components/pageTemplates/login/Marketing-label";
import { MarketingConsent } from "components/pageTemplates/login/MarketingConsent";

const EMAIL = "email";
const NEW_PASSWORD = "new-password";

const logInFields = [
  {
    label: "Email",
    id: "email",
    initialValue: "",
    type: INPUT,
    typeParams: {
      type: "email",
      autoComplete: "email",
      placeholder: "Enter your Email",
    },
    validation: [...commonEmailValidations],
  },
  {
    label: "Password",
    id: "password",
    initialValue: "",
    type: INPUT,
    typeParams: {
      type: "password",
      autoComplete: "current-password",
      placeholder: "Enter your Password",
    },
    validation: [commonShouldHaveValue],
  },
  {
    id: "forgot-password",
    type: LINK,
    typeParams: {
      href: FORGOTTEN_PASSWORD_PATH,
      text: "Forgot password?",
    },
  },
  {
    id: "log-in",
    type: BUTTON,
    size: 50,
    className: "btn_event_login",
    typeParams: {
      callBack: ({ formValues, actions }) => {
        actions.handleUserLogin(formValues);
      },
      text: "CONTINUE",
      type: "submit",
      gtmEvent: "login_submit",
      gtmType: "login_page",
    },
  },
];

const OldRegisterFields = [
  {
    label: "Salutation",
    id: "salutation",
    initialValue: "",
    type: INPUT, // Designs have drop down but client say they want it as an input
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "honorific-prefix",
      placeholder: "Select your Title",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "First Name",
    id: "first-name",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "given-name",
      placeholder: "Enter your First Name",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "Last Name",
    id: "last-name",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "family-name",
      placeholder: "Enter your Last Name",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "Professional Email",
    id: EMAIL,
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "email",
      autoComplete: "email",
      placeholder: "Enter your Email",
    },
    validation: [...commonEmailValidations],
  },
  {
    label: "Password",
    id: NEW_PASSWORD,
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "password",
      autoComplete: "new-password",
      placeholder: "Enter your Password",
    },
    validation: [...commonNewPasswordValidations],
  },
  {
    id: "speciality",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {},
    validation: [commonShouldHaveValue],
  },
  {
    id: "organization-title",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    typeParams: {
      type: "text",
      autoComplete: "organization",
      placeholder: "Enter your Workplace",
    },
    validation: [commonShouldHaveValue],
  },
  
  {
    label: 'Kyowa Kirin Hub is intended for Healthcare Professionals only. By ticking this box you are confirming that you are a Healthcare professional.',
    id: OPTIN_HCP,
    initialValue: false,
    type: CHECKBOX,
    mandatory: true,
    showAsterix: true,
    typeParams: {

    },
    validation: [commonShouldAcceptConsent],
  },
  {
    label: 'I consent to receiving information from Kyowa Kirin (including promotional and patient-directed materials) and to receiving invites to webinars, e-conferences and one-on-one conversations via digital channels with Kyowa Kirin employees.',
    id: 'information-consent',
    initialValue: false,
    type: CHECKBOX,
    mandatory: false,
    typeParams: {

    },
    // validation:[commonShouldHaveValueRadio],
  },
  {
    id: "create-account",
    type: BUTTON,
    size: 50,
    className: "btn_event_register",
    typeParams: {
      callBack: ({ formValues, actions }) => {
        actions.handleUserRegister(formValues);
      },
      text: "CREATE YOUR ACCOUNT",
      type: "submit",
      gtmEvent: "register_submit",
      gtmType: "login_page",
    },
  },
  {
    id: "mandatory-key",
    type: COMPONENT,
    size: 100,
    typeParams: {
      PropComponent: MandatoryKey,
    },
  },
];

const registerFields = [
  {
    label: "Salutation",
    id: "salutation",
    initialValue: "",
    type: INPUT, // Designs have drop down but client say they want it as an input
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "honorific-prefix",
      placeholder: "Select your Title",
      takeFullWidth: true,
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "First Name",
    id: "first-name",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "given-name",
      placeholder: "Enter your First Name",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "Last Name",
    id: "last-name",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "family-name",
      placeholder: "Enter your Last Name",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "Professional Email",
    id: EMAIL,
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "email",
      autoComplete: "email",
      placeholder: "Enter your Email",
    },
    validation: [...commonEmailValidations],
  },
  {
    label: "Password",
    id: NEW_PASSWORD,
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "password",
      autoComplete: "new-password",
      placeholder: "Enter your Password",
    },
    validation: [...commonNewPasswordValidations],
  },
  {
    label: "Role",
    id: "role",
    initialValue: "",
    type: ROLE_DROPDOWN,
    mandatory: true,
    size: 50,
    typeParams: {},
    validation: [commonShouldHaveValue],
  },
  {
    id: "speciality",
    initialValue: "",
    type: DROPDOWN_DEFAULT,
    mandatory: true,
    size: 50,
    typeParams: {},
    validation: [commonShouldHaveValue],
  },
  {
    id: "jobtitle",
    initialValue: "",
    type: INPUT,
    mandatory: false,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "job-title",
      placeholder: "Enter your Job Title",
    },
    validation: [],
  },
  {
    id: "organization-title",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "organization",
      placeholder: "Enter your Workplace",
    },
    validation: [commonShouldHaveValue],
  },
  {
    id: "department",
    initialValue: "",
    type: INPUT,
    mandatory: false,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "department",
      placeholder: "Enter your Department",
    },
    validation: [],
  },
  {
    id: "departmentPhone",
    initialValue: "",
    type: INPUT,
    mandatory: false,
    size: 50,
    typeParams: {
      type: "numeric",
      autoComplete: "phone-number",
      placeholder: "Enter your Department number",
    },
    validation: [
      {
        validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
        errorText: "Should contain only number",
      },
    ],
  },
  {
    id: "phone",
    initialValue: "",
    type: INPUT,
    mandatory: false,
    size: 50,
    typeParams: {
      type: "numeric",
      autoComplete: "phone-number",
      placeholder: "Enter your Practice Number",
    },
    validation: [
      {
        validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
        errorText: "Must contain only number",
      },
      
    ],
  },
  {
    id: "terms-label",
    type: COMPONENT,
    size: 100,
    typeParams: {
      PropComponent: TermsLabel,
    },
  },
  {
    id: "privacy-text",
    type: COMPONENT,
    size: 100,
    typeParams: {
      PropComponent: PrivacyConsent,
    },
  },
  {
    label: "",
    id: "privacy-consent",
    initialValue: "",
    type: RADIO,
    mandatory: true,
    typeParams: {},
    validation: [commonShouldAcceptConsent],
  },
  {
    id: "marketing-label",
    type: COMPONENT,
    size: 100,
    typeParams: {
      PropComponent: MarketingLabel,
    },
  },
  {
    id: "marketing-text",
    type: COMPONENT,
    size: 100,
    typeParams: {
      PropComponent: MarketingConsent,
    },
  },
  {
    id: "information-consent",
    initialValue: false,
    type: RADIO,
    // mandatory: true,
    typeParams: {},
    // validation: [commonShouldHaveValueRadio],
  },
  {
    id: "create-account",
    type: BUTTON,
    size: 50,
    className: "btn_event_register",
    typeParams: {
      callBack: ({ formValues, actions }) => {
        actions.handleUserRegister(formValues);
      },
      text: "CREATE YOUR ACCOUNT",
      type: "submit",
      gtmEvent: "register_submit",
      gtmType: "login_page",
    },
  },
  {
    id: "mandatory-key",
    type: COMPONENT,
    size: 100,
    typeParams: {
      PropComponent: MandatoryKey,
    },
  },
];

const tabformFields = [
  {
    text: "Log in",
    id: LOGIN,
    formFields: logInFields,
  },
  {
    text: "Register",
    id: REGISTER,
    formFields: registerFields,
  },
];
const OldTabformFields = [
  {
    text: "Log in",
    id: LOGIN,
    formFields: logInFields,
  },
  {
    text: "Register",
    id: REGISTER,
    formFields: OldRegisterFields,
  },
];
const eapcConfirmation = [
  {
    label: "First Name",
    id: "first-name",
    initialValue: "",
    type: CHECKBOX,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "given-name",
      placeholder: "Enter your First Name",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "Last Name",
    id: "last-name",
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "text",
      autoComplete: "family-name",
      placeholder: "Enter your Last Name",
    },
    validation: [commonShouldHaveValue],
  },
  {
    label: "Speciality",
    id: "speciality",
    initialValue: "",
    type: DROPDOWN_DEFAULT,
    mandatory: true,
    size: 50,
    typeParams: {},
    validation: [commonShouldHaveValue],
  },
  {
    label: "Email",
    id: EMAIL,
    initialValue: "",
    type: INPUT,
    mandatory: true,
    size: 50,
    typeParams: {
      type: "email",
      autoComplete: "email",
      placeholder: "Enter your Email",
    },
    validation: [...commonEmailValidations],
  },
];

const marketingFields = ['marketing-label', 'marketing-text', 'information-consent']

export { tabformFields, OldTabformFields, eapcConfirmation, marketingFields };
