import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronRight } from 'components/shared/SvgIcons';
import { getAssetUrl } from 'api/acousticApi';
import { getImgAttr } from 'api/acousticApi';
import { TextCardLearnMore } from 'components/content/cards/TextCardLearnMore';
import Link from 'components/reusableComponents/link/Link';
import RichText from 'components/reusableComponents/richText/RichText';
import { WebinarTime } from 'components/shared/webinarTime';
import { getWebinarDate, getWebinarTime } from 'components/pageTemplates/webinar/webinarHelpers';
import {  useSelector } from 'react-redux';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getUrlCountryPath } from 'utils/urlUtils';


import './WebinarCard.scss';
const TextCardContainer = ({ onClickPath, externalUrl, className, children }) => {
  let  cssClass 
 
  const href = externalUrl || onClickPath || null
  if(href != undefined ){
    cssClass = `custom-focus-therapy module-section-card-item ${className ? className : ''}`
  }
 

  if (href == undefined) {
      return <div className="module-section-card-item">{children}</div>
  }

  return (
      <Link className={cssClass} href={href}>
          {children}
      </Link>
  )
}

const WebinarCard = ({ moduleSectionPage, labelReadMore, imageBanner, title ,bodyText, buttonColor,openInNewTab}) => {
  let dates = moduleSectionPage.webinarDate;
  let date = getWebinarDate(dates);
  // let time = getWebinarTime(dates);

  const history = useHistory();
  
  const { trackWebinarCardButton } = useGoogleAnalytics();
  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;
  const handleClick = () => {
    if( moduleSectionPage?.urlSlug?.value && openInNewTab){
      window.open(`/${getUrlCountryPath()+moduleSectionPage?.urlSlug?.value}`);
    }else{
      trackWebinarCardButton(userId, moduleSectionPage?.title?.value)
      history.push(moduleSectionPage?.urlSlug?.value);
    }
  }

  const styleButton = {
    backgroundColor:`#${buttonColor}`,
    borderColor:`#${buttonColor}`,
    textAlign: 'inherit',
}
    const sectionIcon = './png';
    const sectionTitle ="hjfy";
    const sectionTitleLink="com";
    const  sectionImage=imageBanner ;
      const style = {
        backgroundImage: `url(${getAssetUrl(moduleSectionPage?.webinarImage)})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'right',
        height:'175px',
      };

      const {
        src,
        alt,
      } = getImgAttr(sectionIcon);

      const Icon = src ? (
        <img
          src={src}
          alt={alt}
        />
      ) : null;

    return (
      <div className="webinar-card module-section-card-container">
         <TextCardContainer onClickPath={sectionTitleLink?.linkURL}>
          <div className="module-section-card" >
            <div className="section-card-header" style={style}>
            </div>
            <div className="card-details-container">
            <div className="title">
              <p className="title-card">{moduleSectionPage?.title?.value} </p> 
            </div>
             <div className="webinar-time-section" id="time-upcoming">
            <WebinarTime text={date} icon="calender" ></WebinarTime>
            <WebinarTime text={moduleSectionPage?.webinarTime.value} icon="clock" ></WebinarTime>
            {moduleSectionPage?.webinarType?.value && <WebinarTime icon="location" className="desktop" text={moduleSectionPage?.webinarType?.value}></WebinarTime>}
            
        </div>
        <div className="body-text">
          {moduleSectionPage?.basePage?.value?.metaDescWithHtml?.value ? <RichText data={moduleSectionPage?.basePage?.value?.metaDescWithHtml} truncate="148" /> : moduleSectionPage?.basePage?.value?.seoShortDesc?.value ? <p>{moduleSectionPage?.basePage?.value?.seoShortDesc?.value}</p> : ""}
          </div>
             <div className="read-more">
               <button style={styleButton} onClick={handleClick} className="button-learn-more">
                   {labelReadMore}
                </button>
               {/* </Link> */}
            </div>
          </div>
          </div>
        </TextCardContainer>

      </div>
    ) 
};

export { WebinarCard }