import React, { useEffect, useState } from 'react';
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics';
import { useLocation } from 'react-router-dom';
import { getUrlCountryData } from 'utils/libraryUtils';
import { getAPIContent } from 'api/acousticApi';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setTaxonomyFields } from 'redux/ga/slice';
// import { usePageContent } from 'api/acousticApi/ContentHooks';

const INTERVAL_IN_MILLIS = 10000;

const GA_TAXONOMY_QUERY_KEY = 'ga-taxonomy';

export const useGATaxonomy = () => {
  const dispatch = useDispatch();  
  const [taxonomy, setTaxonomy] = useState();
  const { data, isLoading, error } = useQuery(
    GA_TAXONOMY_QUERY_KEY,
    () => getAPIContent(getUrlCountryData()?.gaTaxonomyId),
    { staleTime: Infinity }, // only request the taxonomy reg once
  );

  useEffect(() => {
    if(!isLoading && !error) {
      const taxonomyFields =  data?.elements?.taxonomyFields?.value?.split(/\r?\n/).map(value => {
        const valueSplit = value.replace(/\s+/g, '').split('-');
        const taxonomyField = {
            parent: valueSplit[0],
            fields: []
        }
  
        if(valueSplit?.length > 1) {
            taxonomyField.fields = valueSplit[1].substring(1, valueSplit[1].length-1).split(',');
        }
  
        return taxonomyField;
      });

      setTaxonomy(taxonomyFields);
      dispatch(setTaxonomyFields(taxonomyFields))
    }
  }, [isLoading, error])
  
  return {
    taxonomyFields: taxonomy,
    isLoading,
    error
  }
}

export const extractTaxonomyValues = (taxonomyFields, taxonomy, isStatic = false) => {
  const taxonomyValues = {};

  taxonomyFields?.forEach(({ parent, fields }) => {
      fields?.forEach(field => {
        let fieldValue;
        if(!isStatic) {
          // if(!taxonomyValues[parent]) taxonomyValues[parent] = {}
          fieldValue = taxonomy && taxonomy[parent]?.value && taxonomy[parent]?.value[field]?.categories ? taxonomy[parent]?.value[field] : ''
          
          if(fieldValue?.categories?.length > 0) { 
              fieldValue = fieldValue?.categories[0] 
              fieldValue = fieldValue?.substring(fieldValue.lastIndexOf('/')+1)
          }
        } else {
          fieldValue = 'Various';
        }

        if(fieldValue) taxonomyValues[`${parent}-${field}`] = fieldValue
      })
  })


  return taxonomyValues
}

const GetTaxonomyGoogleAnalytics = (content) => {
  const { trackTaxonomy, trackPageTaxonomies } = useGoogleAnalytics();
  // const { content} = usePageContent(contentId.content)

  const { pathname } = useLocation();

  const trackTaxonomyKeywords = (keywords) => {
    if(!keywords || !(keywords instanceof Map)) return null;
    const keys = [...keywords.keys()];

    let values = {};

    keys.forEach((key, index) => {
      values[`taxonomy_keyword_${(index+1)}`] = `${key}/${keywords.get(key)}`;
    });

    trackPageTaxonomies(values);
  }

  function trackTaxonomies() {
    const keywords = new Map();
    keywords.set('Audience Type', taxonomy_audience_type);
    keywords.set('Audience Speciality', taxonomy_audience_speciality);
    keywords.set('Audience JourneyStage', taxonomy_audience_journeyStage);

    keywords.set('Usage', taxonomy_functional_usage);
    keywords.set('Channel', taxonomy_functional_channel);
    keywords.set('Branding', taxonomy_functional_branding);
    keywords.set('UsageWith', taxonomy_functional_usageWith);
    keywords.set('ValidFrom', taxonomy_functional_validFrom);
    keywords.set('ValidTo', taxonomy_functional_validTo);
    keywords.set(
      'Countries Approved To Use',
      taxonomy_functional_countriesApprovedToUse
    );
    keywords.set('Format', taxonomy_functional_format);

    keywords.set('Content Access Level', taxonomy_identity_contentAccessLevel);
    keywords.set('Regulatory Id', taxonomy_identity_regulatoryId);

    keywords.set('Brand', taxonomy_message_brand);
    keywords.set('Business Unit', taxonomy_message_businessUnit);
    keywords.set('Category', taxonomy_message_category);
    keywords.set('Indication', taxonomy_message_indication);
    keywords.set('Product Life Cycle', taxonomy_message_productLifecycle);
    keywords.set('Theme', taxonomy_message_theme);

    trackTaxonomyKeywords(keywords);
  }

  useEffect(() => {
    // Update the document title using the browser API
    // trackTaxonomies();
  }, []);

  let taxonomy_audience_type;
  let taxonomy_audience_speciality;
  let taxonomy_audience_journeyStage;
  let taxonomy_functional_usage;
  let taxonomy_functional_channel;
  let taxonomy_functional_branding;
  let taxonomy_functional_usageWith;
  let taxonomy_functional_validFrom;
  let taxonomy_functional_validTo;
  let taxonomy_functional_countriesApprovedToUse;
  let taxonomy_functional_format;
  let taxonomy_identity_contentAccessLevel;
  let taxonomy_identity_regulatoryId;
  let taxonomy_message_brand;
  let taxonomy_message_businessUnit;
  let taxonomy_message_category;
  let taxonomy_message_indication;
  let taxonomy_message_productLifecycle;
  let taxonomy_message_theme;

  const taxonomy = content?.content?.elements?.taxonomy?.value;

  taxonomy_audience_type =
    taxonomy?.audience?.value?.audienceType?.categories == undefined
      ? null
      : taxonomy?.audience?.value?.audienceType?.categories.toString();
  taxonomy_audience_speciality =
    taxonomy?.audience?.value?.audienceSpeciality?.categories == undefined
      ? null
      : taxonomy?.audience?.value?.audienceSpeciality?.categories.toString();
  taxonomy_audience_journeyStage =
    taxonomy?.audience?.value?.audienceJourneyStage?.categories == undefined
      ? null
      : taxonomy?.audience?.value?.audienceJourneyStage?.categories.toString();

  taxonomy_functional_usage =
    taxonomy?.functional?.value?.usage?.categories == undefined
      ? null
      : taxonomy?.functional?.value?.usage?.categories.toString();
  taxonomy_functional_channel =
    taxonomy?.functional?.value?.channel?.categories == undefined
      ? null
      : taxonomy?.functional?.value?.channel?.categories.toString();
  taxonomy_functional_branding =
    taxonomy?.functional?.value?.branding?.categories == undefined
      ? null
      : taxonomy?.functional?.value?.branding?.categories.toString();
  taxonomy_functional_usageWith =
    taxonomy?.functional?.value?.usage?.categories == undefined
      ? null
      : taxonomy?.functional?.value?.usage?.categories.toString();
  taxonomy_functional_validFrom =
    taxonomy?.functional?.value?.validFrom?.value == undefined
      ? null
      : new Date(taxonomy?.functional?.value?.validFrom?.value);
  taxonomy_functional_validTo =
    taxonomy?.functional?.value?.validTo?.value == undefined
      ? null
      : taxonomy?.functional?.value?.validTo?.value.toString();
  taxonomy_functional_countriesApprovedToUse =
    taxonomy?.functional?.value?.countriesApprovedToUse?.categories == undefined
      ? null
      : taxonomy?.functional?.value?.countriesApprovedToUse?.categories.toString();
  taxonomy_functional_format =
    taxonomy?.functional?.value?.format?.categories == undefined
      ? null
      : taxonomy?.functional?.value?.format?.categories.toString();

  taxonomy_identity_contentAccessLevel =
    taxonomy?.identity?.value?.contentAccessLevel?.categories == undefined
      ? null
      : taxonomy?.identity?.value?.contentAccessLevel?.categories.toString();
  taxonomy_identity_regulatoryId =
    taxonomy?.identity?.value?.regulatoryId?.value == undefined
      ? null
      : taxonomy?.identity?.value?.regulatoryId?.value.toString();

  taxonomy_message_brand =
    taxonomy?.message?.value?.brand?.categories == undefined
      ? null
      : taxonomy?.message?.value?.brand?.categories.toString();
  taxonomy_message_businessUnit =
    taxonomy?.message?.value?.businessUnit?.categories == undefined
      ? null
      : taxonomy?.message?.value?.businessUnit?.categories.toString();
  taxonomy_message_category =
    taxonomy?.message?.value?.category?.categories == undefined
      ? null
      : taxonomy?.message?.value?.category?.categories.toString();
  taxonomy_message_indication =
    taxonomy?.message?.value?.indication?.categories == undefined
      ? null
      : taxonomy?.message?.value?.indication?.categories.toString();
  taxonomy_message_productLifecycle =
    taxonomy?.message?.value?.productLifecycle?.categories == undefined
      ? null
      : taxonomy?.message?.value?.productLifecycle?.categories.toString();
  taxonomy_message_theme =
    taxonomy?.message?.value?.theme?.categories == undefined
      ? null
      : taxonomy?.message?.value?.theme?.categories.toString();

  return null;
};

export { GetTaxonomyGoogleAnalytics };
