import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import RichText from 'components/reusableComponents/richText/RichText';
import './PageHero.scss';

const PageHero = ({ title, headerText }) => {
  const { tLogIn, tLogin, tRegister } = useWebsiteConfigObject();
  const { trackGTMEvent } = useGoogleAnalytics();

  const linkTrackers = [
    {
      linkText: [tLogIn, tLogin],
      eventName: 'login_intent',
      contentType: 'homepage_hyperlink'    
    },
    {
      linkText: [tRegister],
      eventName: 'register_intent',
      contentType: 'homepage_hyperlink'
    }
  ]
  const containsLink = (link, text) => {
    link = link?.replace(/\s+/g, '').toLocaleLowerCase();
    text = text?.join('|').replace(/\s+/g, '').toLocaleLowerCase();
    
    return text?.includes(link)
  }
  const trackLink = (link, children) => {
    if(link.startsWith('#')) {
      return () => {
        trackGTMEvent(link.substring(1), 'homepage_hyperlink')
      }
    }
    if(Boolean(children?.length)) {
      let onClick;
      linkTrackers.forEach(element => {
        if(containsLink(children[0]?.data, element.linkText)) {
          onClick = () => {
            trackGTMEvent(element.eventName, element.contentType)
          }
        }
      })
      return onClick;
    }
  }

  return (
    <div
      className='page-hero'
      style={{ width: '100%', minHeight: '409px', position: 'relative' }}
    >
      {title && title.value && <h1>{title.value}</h1>}
      {headerText && <RichText className='page-hero-text' data={headerText} trackLink={trackLink} />}
    </div>
  );
}

PageHero.propTypes = {
  title: contentElementProptype,
  headerText: contentElementProptype,
};

export { PageHero };
