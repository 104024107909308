import {
  getEnvApiConfigValues,
  getEnvProxyConfigValues,
  getEnvAcousticConfigValues,
  getDocCheckLoginId,
  getLamdaFunction,
} from 'utils/envUtils';

// hard coded application config
const AppConfig = {
  // canonical URL for the site
  siteUrl: 'https://kyowakirinhub.com',
  // Initial set of axios defaults.
  // These should be fine tuned to match the acoustic API endpoint
  axios: {
    timeout: 50000,
    // follow up to 10 HTTP 3xx redirects
    maxRedirects: 10,
    // cap the maximum content length we'll accept to 50MBs
    maxContentLength: 50 * 1000 * 1000,
  },

  acousticApi: {
    ...(getEnvAcousticConfigValues()),
    domain: 'https://content-eu-1.content-cms.com',
    domainPreview: 'https://content-eu-1-preview.content-cms.com',
    loginBasicAuth: 'login/v1/basicauth?accept-privacy-notice=true',
    baseAuthUser: 'hazlitte+viewer@gmail.com',
    baseAuthPassword: 'OculusQuest2!',
    apiPath: 'api',
    deliveryPath: 'delivery/v1',
    renderContextPath: 'rendering/context',
    searchPath: 'search',
    utmCampaignsHubId: '9c8b6636-f45a-4a20-9f33-70c989199e09',
  },
  authApi: {
    // Configuration for general authentication
    general: {
      ...(getEnvApiConfigValues()),
      apiPath: 'api',
      createTokenPath: 'createtoken',
      validateTokenPath: 'validateToken',
      refreshTokenPath: 'refreshtoken',
      getTokenDataPath: 'gettokendata',
      userPath: 'Users',
      confirmRegistration: 'confirmRegistration',
      deleteUserPath: 'delete',
      forgottenPasswordPath: 'forgottenPassword',
      resetPasswordPath: 'resetpassword',
      changePasswordPath: 'changePassword',
      consentPath: 'consent',
    },
    // Configuration for owa authentication
    owa: {
      ...(getEnvApiConfigValues()),
      apiPath: 'api',
      createTokenPath: 'createtokenonekey',
      userPath: 'users',
      deleteUserPath: 'deleteByCountry',
      extendedprofilePath: 'extendedprofile',
      // Proxy paths
      ...(getEnvProxyConfigValues()),
    },
    // Configuration for owa authentication
    docCheck: {
      ...(getEnvApiConfigValues()),
      loginId: getDocCheckLoginId(),
      apiPath: 'api',
      createTokenPath: 'createtokendoccheck',
    },
  },
  reactQuery: {
    // by default react queries should go stale after five minutes
    staleTime: 5,
  },
  // These responsive grid variables need to be kept in sync
  // with the values in src/style/mixins/_responsive.scss
  // and src/style/variables.scss
  responsiveGrid: {
    // values are in pixels
    // small is 0 -> 639
    minWidthMedium: 640,
    minWidthLarge: 1024,
    minWidthXl: 1366,
    numberColumnsDefault: 12,
    numberColumnsSmall: 4,
    gutterWidthDefault: 20,
    gutterWidthXl: 30,
    containerMaxWidth: 1600,
    containerMobilePaddingPixels: 20,
    containerLargePaddingPercent: 3,
    containerXlPaddingPercent: 5,
  },
  lamda:{
    ...(getLamdaFunction()),
  },
  dynamoApi: {
    ...(getEnvApiConfigValues()),
      apiPath: 'api',
      usersPath: 'users',
      eventPath: 'event',
      dynamoPath: 'dynamo/event',
      listPath: 'list',
      deleteEventPaths: 'delete',
      anonymousPath: 'anonymousupdate'
  },
  dataCaptureApi: {
    ...(getEnvApiConfigValues()),
    apiPath: 'api',
    usersPaths: 'users',
    dataCapturePath: 'events/datacapture',
  },
  eventsApi: {
    ...(getEnvApiConfigValues()),
      apiPath: 'api/events',
      dataCapturePath: 'datacapture'
  },
  utmRegistration: {
    ...(getEnvApiConfigValues()),
    apiPath: 'api',
    userPath: 'users',
    extendedprofilePath: 'extendedProfile'
  },
  veevaApi: {
    ...(getEnvApiConfigValues()),
    apiPath: 'api',
    accessTypeQueryName: 'at',
    sendDateQueryName: 'sentDateTime',
    sourceQueryName: 'src',
    userIdQueryName: 'u',
    durationQueryName: 'ttl',
    veevaPath: 'Veeva',
    validateUser: 'validateuser'
  },
};

export { AppConfig };
