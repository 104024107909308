import { useEffect, useState } from 'react';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useLocation } from 'react-router-dom';

const ScrollingElement = () => {
  const { trackScrollEvent } = useGoogleAnalytics();
  const { pathname } = useLocation();
  const [scrolled25, setScrolled25] = useState(false);
  const [scrolled50, setScrolled50] = useState(false);
  const [scrolled75, setScrolled75] = useState(false);
  const [scrolled90, setScrolled90] = useState(false);

  function logit() {
    const h = document.documentElement,
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
    const percent = parseInt(
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    );

    if (percent >= 25 && !scrolled25) {
      setScrolled25(true);
      trackScrollEvent('25%');
    }
    if (percent >= 50 && !scrolled50) {
      setScrolled50(true);
      trackScrollEvent('50%');
    }
    if (percent >= 75 && !scrolled75) {
      setScrolled75(true);
      trackScrollEvent('75%');
    }
    if (percent >= 90 && !scrolled90) {
      setScrolled90(true);
      trackScrollEvent('90%');
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', logit);
    };
  });

  // Reset status on Route change
  useEffect(() => {
    setScrolled25(false);
    setScrolled50(false);
    setScrolled75(false);
    setScrolled90(false);
  }, [pathname]);

  return null;
};

export { ScrollingElement };
