import { fileToJson } from "utils/fileToJson";
import { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import "./VeevaPortalViewer.scss";

const VeevaPortalViewer = (element) =>{
    const url = element?.element?.elements?.veevafilescript?.url
    const [promiseData, setPromiseData] = useState(null);
    const promise = fileToJson(url);
    promise.then((data)=>{
        setPromiseData(data)
    })

    useEffect(() => {
        const portalScript = document.getElementById("veeva-portals");

        if(portalScript && document.getElementById("engage")) {
            const scriptText = portalScript.innerText;
            portalScript.remove();

            const scriptEl = document.createElement("script");
            scriptEl.innerHTML = scriptText;
            document.getElementById("veevaPortal").appendChild(scriptEl);
        }
    })

    if(promiseData){
        const parsedHTML = HTMLReactParser(promiseData, {
            replace: function(domNode) {
                if (domNode.type === 'script') {
                    return <script id="veeva-portals">{domNode.children[0].data}</script>
                } 
            }
        });

        return (
            <div id="veevaPortal">
                {
                    parsedHTML
                }
            </div>
        );
    }
    
    return <div id="veevaPortal"></div>
}

export default VeevaPortalViewer;