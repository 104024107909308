import React from 'react';
import {
  useScreenWidth,
  SCREEN_WIDTH_DESKTOP,
} from 'components/layout/ScreenWidthContext';
import { getImgAttr } from 'api/acousticApi';
import './WebinarBanner.scss';

const WebinarBanner = ({ image, bannerText, displayText }) => {
  const bannerImageElement = image ? image : null;
  const { currentWidthRanges } = useScreenWidth();
  const isDesktop = currentWidthRanges.includes(SCREEN_WIDTH_DESKTOP);
  const style = {
    backgroundImage: `url(${getImgAttr(bannerImageElement)?.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: isDesktop ? 'center top' : 'left center',
    backgroundSize: 'cover',
  };
  if(bannerText || bannerImageElement?.url){
    return (
      <section className='webinar-banner'>
        {(bannerImageElement?.url || displayText?.value && bannerText) && (
          <div className='webinar-banner__container'>
            <div
              style={style}
              className={`webinar-banner__container__image${
                displayText ? ' set-opacity' : ''
              }`}
            ></div>
          </div>
        )}
        {displayText?.value && bannerText && (
          <div className='webinar-banner__text'>
            <h4>{bannerText}</h4>
          </div>
        )}
      </section>
    );
  }
  return null;
};

export { WebinarBanner };
