import PropTypes from 'prop-types';

// prop types for component children
// children can be:
// - a string: Eg <Component>hello<Component>
// - a node: E.G <Component><span>hello<span><Component>
// - an array: E.G.
//   <Component>
//     <h1>title</h1>
//     <p>body</p>
//   </Component>
const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.string,
]);

export { childrenPropTypes };
