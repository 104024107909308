import RichText from 'components/reusableComponents/richText/RichText';
import { useSelector } from 'react-redux';
import { useWebsiteConfigObject, useWebsiteConfigDeep } from 'websiteConfig/useWebsiteConfig';
import { formatTextUsingFields } from 'utils/stringUtils';
import { Guest } from '../header/components/userStatus/UserStatus';
import Timer from '../header/timer/Timer';
import './PersonalizedMessage.scss';

const PersonalizedMessage = ({ message }) => {
  const { veevaUser, veevaUserInvalid } = useSelector((state) => state.authentication.status);
  const { status } = useSelector((state) => state.authentication);
  const { 
    personalizedLinkExpired, 
  } = useWebsiteConfigObject();
  if (status?.loggedIn || !veevaUser?.userInfo) return null;
  
  let formatedMessage = formatTextUsingFields(message, veevaUser?.userInfo);
  if(veevaUserInvalid){
    if(personalizedLinkExpired){
      formatedMessage = personalizedLinkExpired;
    } else {
      formatedMessage = "<p>Link expired</p>";
    }
  }
  return (
    <div className='PersonalizedMessage'>
      <div className='PersonalizedMessage_Content'>
        <RichText data={{ value: formatedMessage }} />
        {veevaUser?.expiry && <Timer date={veevaUser?.expiry} />}
        <Guest registerOnly={true}/>
      </div>
    </div>
  );
};

export default PersonalizedMessage;
