import React, { forwardRef } from 'react'

// styles
import './CardContent.scss'

const CardContent = forwardRef(({ className, style, children }, ref) => {

    if(!children) {
        return null
    }

    return <div ref={ref} className={className} style={style}>{children}</div>
    
})

export default CardContent

    