import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import RichText from 'components/reusableComponents/richText/RichText'
import './LoginSideText.scss';


const LoginSideText = () => {
  const {
    tLoginSideText,
  } = useWebsiteConfigObject();

  return (
    <div className="login-page-text">
      <RichText
        data={tLoginSideText}
      />
    </div>
  );
};




export { LoginSideText };

