import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { useHistory } from 'react-router-dom';
import { getImgAttr } from 'api/acousticApi';
import { TextCard } from 'components/content/TextCard';
import RichText from 'components/reusableComponents/richText/RichText'
import { ChevronRight } from 'components/shared/SvgIcons';
import {  useSelector } from 'react-redux';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getFooterTextDisplayData } from 'utils/contentUtils';
import { WebinarTime } from 'components/shared/webinarTime';
import { getWebinarDate, getWebinarTime } from 'components/pageTemplates/webinar/webinarHelpers';
import { getUrlCountryPath } from 'utils/urlUtils';


import './WebinarCard.scss'
// Component that renders the 'KK Orange Logo' along with the 'Veeva approval code' (string) above the references block.
const FeaturedWebinar = ({
    title,
    date,
    time,
    bodyText,
    link,
    buttonColor,
    backgroundColor,
    textColor,
    cardCategory,
    image,
    footerText,
    displayLearnMoreOrFooterText,
    displayExternalLinkChevron,
    buttonName,
    webinarType,
    openInNewTab,
    url
}) => {
    let webinarDate = getWebinarDate(date);
    // let webinarTime = getWebinarTime(date);

    const { trackFeatureWebinarButton } = useGoogleAnalytics();
    const { data } = useSelector((state) => state.authentication);
    const { user } = data || {};
    const userId = user.userId;
    const {
        footerTextValue,
        displayChevron,
      } = getFooterTextDisplayData({
        displayLearnMoreOrFooterText,
        displayExternalLinkChevron,
        footerText,
        link
      });
    const style = {
        backgroundColor:`#${buttonColor}`,
        borderColor:`#${buttonColor}`,
        textAlign: 'inherit',
        display: 'flex',
        justifyContent: 'space-between',
    }

    const history = useHistory();
    const handleClick = () => {
        if(openInNewTab){
            trackFeatureWebinarButton(userId, title?.value)
            window.open(`/${getUrlCountryPath()}${url?.value}`)
        }else{
            trackFeatureWebinarButton(userId, title?.value)
            history.push(url?.value);
        }
    }
 
    return (
        <TextCard
        title={title && title.value}
        category={cardCategory && cardCategory.value}
        onClickPath={link}
        image={getImgAttr(image)}
        >
        <div className="webinar-time-section">
            <WebinarTime text={webinarDate} icon="calender" ></WebinarTime>
            <WebinarTime text={time} icon="clock" ></WebinarTime>
            {webinarType && <WebinarTime text={webinarType} icon="location"></WebinarTime>}
            
        </div>
        <div className="feature-body-text">
            {bodyText?.value ? <RichText
                data={bodyText}
                truncate="450"
            />: bodyText ? <p>{bodyText}</p> : ""}
        </div>
        
        {displayChevron ? (
        buttonColor,
        <button className="sign-up-button button-learn-more" style={style} onClick={handleClick}>
            {buttonName}
             <div className="svg-container">
                <ChevronRight />
            </div>
        </button>
            
        ) : (
            <p className="meta-tags">
            {footerTextValue}
            </p>
        )}
        </TextCard>
    );
};

FeaturedWebinar.propTypes = {
  code: contentElementProptype,
};

export {
    FeaturedWebinar,
};
