import React from 'react';
import './Breadcrumbs.scss';

import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import CountrySelector from '../countrySelector/CountrySelector';
import NavLink from 'components/reusableComponents/navLink/NavLink';
import { getUrlCountryPath } from 'utils/urlUtils';
import { Helmet } from 'react-helmet-async';
import CountrySwitcher from '../event/CountrySwitcher';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { useScreenWidth, SCREEN_WIDTH_DESKTOP } from 'components/layout/ScreenWidthContext';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';

const upperCaseWords = (str) => {
  var words = str.match(/([^-]+)/g) || [];
  words.forEach(function (word, i) {
    words[i] = word[0].toUpperCase() + word.slice(1);
  });
  return words.join(' ');
};

const Breadcrumbs = (templates) => {
  // Redux navigation state
  const { main } = useSelector((state) => state.ui.navigation);
  const { redesign } = useSelector(state => state.ui);
  const contentId = templates.contentId;
  let content = usePageContent(contentId);
  let alternateLinks = content?.content?.elements?.alternateLinks;
  let currentLocale = content?.content?.elements?.currentLocale;
  let title = content?.content?.elements?.title?.value;
  let hideBreadCrumb = false;
  const {
    registrationSuccessBreadcrumbText,
    successEmailConfirmText,
  } = useWebsiteConfigObject();
  const {
    currentWidthRanges,
  } = useScreenWidth();

  const isDesktop = currentWidthRanges.includes(SCREEN_WIDTH_DESKTOP);

  if(content?.content?.elements?.hideBreadcrumb?.value) {
    hideBreadCrumb = true;
  }
  // Breadcrumb Array
  const breadcrumbs = [];
  
  // Get current path
  const location = useLocation();
  let isEvent = false;
  if(templates.templateType == "Layout – Event Page" || templates.templateType == "Layout – Event Thank You Page" ){
    isEvent = true;
  }
  // Breakdown URL to array of items in order
  const locationArray = location.pathname.substring(1).split('/');

  let schema = {
    '@context': 'http://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  };

  let matchFound = false;
  if(!getUrlCountryPath()){
    if(location.pathname === "/"){
      breadcrumbs.push(
        <NavLink key={'homeId'} href={'/'}>
          Home
        </NavLink>
      );
      breadcrumbs.push(
        <NavLink key={'globalPageId'} href={'/'}>
          {title ? title : "Global directory page"}
        </NavLink>
      );

    }
  }
  // If location is home don't push anything.
  if (locationArray[0] !== '') {
    // TODO : Home needs to translate
    breadcrumbs.push(
      <NavLink key={'homeId'} href={'/'}>
        Home
      </NavLink>
    );

    

    // Recursively Generate Breadcrums
    const generateBreadcrumbs = (data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          // If path match with current location
          const match = matchPath(location.pathname.replaceAll(' ', ''), {
            path: data[i].path?.split('?')[0].trim(),
          });

          if (match) {
            matchFound = true;
            // Push item
            breadcrumbs.push(
              <NavLink key={data[i].id} href={data[i].path}>
                {data[i].title}
              </NavLink>
            );

            // If item has children call self
            if (data[i].children) {
              generateBreadcrumbs(data[i].children);
            }
          }
        }
      }
    };

    generateBreadcrumbs(main);

    if (!matchFound && locationArray.length === 1) {
      const pathName = upperCaseWords(locationArray[0]);
      const customText = [];
      customText["registration-success"] = registrationSuccessBreadcrumbText;
      customText["confirm-email-success"] = successEmailConfirmText;

      if(customText[locationArray[0]]) {
        breadcrumbs.push(
          <NavLink key={locationArray[0]} href={`/${locationArray[0]}`}>
            {customText[locationArray[0]]}
          </NavLink>
        );
      } else {
        breadcrumbs.push(
          <NavLink key={locationArray[0]} href={`/${locationArray[0]}`}>
            {pathName.split('-').join(' ')}
          </NavLink>
        );
      }
    }
  }

  breadcrumbs.forEach((element, index) => {
    schema.itemListElement.push({
      '@type': 'ListItem',
      position: index + 1,
      name: element.props.children,
      item: `${window.location.origin}/${getUrlCountryPath()}${
        element.props.href
      }`,
    });
  });

  if(hideBreadCrumb){
    return null;
  }
  
  return (
    <>
      {breadcrumbs.length > 0 && (
        <Helmet>
          <script type='application/ld+json'>{JSON.stringify(schema)}</script>
        </Helmet>
      )}
      <div
        className={`Breadcrumbs${
          Boolean(breadcrumbs.length) || isDesktop ? '' : ' Breadcrumbs--empty'
        }${isEvent ? " events-library-wrap" : ""} ${getUrlCountryPath() ? '':'global'}`}
      >
        <div className={`Breadcrumbs_Content${isEvent ? " events-library" : ""} ${getUrlCountryPath() ? '':'global_breadcrumbs_padding'}`}>
          <ul className="Breadcrumbs_List">
            {Boolean(breadcrumbs.length) && breadcrumbs}
          </ul>
          {
            isEvent ? <CountrySwitcher  alternateLinks={alternateLinks} currentLocale={currentLocale} /> 
            : null
          }
          {
            !isEvent && !redesign && isDesktop ? <CountrySelector isMobile={false} />
            : null
          }
          
        </div>
      </div>
    </>
  );
};

export default Breadcrumbs;
