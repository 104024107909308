import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/kanso/atoms/button/Button';
import styles from './Video.module.scss';
import RichText from 'components/reusableComponents/richText/RichText';
import { stringToCssId } from 'utils/stringUtils';
import { pauseAllMedia } from 'utils/utilityHelper';

/**
 * Video Atom
 * @param {string}  title         - The title of the media to be displayed.
 * @param {string}  titleTag      - The heading tag wrapped around the media title.
 * @param {string}  caption       - The caption text to be displayed below the video.
 * @param {string}  image         - An image to be displayed.
 * @param {string}  src           - The location of the media source file.
 * @param {string}  srcType       - The type of media.
 * @param {string}  transcript    - The location of the media transcript file.
 * @param {string}  subtitle      - The location of the closed caption file.
 * @param {boolean} autoPlay      - Should the video autoplay?
 * @param {boolean} showControls  - Should the video controls display?
 * @param {boolean} isLooped      - Should the video loop?
 * @param {boolean} clickToPlay   - Should the video play on click?
 * @param {boolean} isMuted       - Should the video be muted?
 * @returns React Component
 */

const VideoInstance = React.forwardRef((props, ref) => {
  const {
    source,
    options = null,
    title,
    titleTag,
    caption,
    transcript,
  } = props;
  const { poster, sources } = source;
  const { autoPlay, muted } = options;
  const srcType = Boolean(sources?.length) ? sources[0].type : 'video/mp4';

  const videoSrc = Boolean(source.sources.length) && source.sources[0].src;

  const HeadingTag = titleTag ? `${titleTag}` : `h3`;
  const [videoReady, setVideoReady] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoDuration, setVideoDuration] = useState();
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [posterEnabled, setPosterEnabled] = useState(poster);
  const posterRef = useRef();

  const onLoadedData = (e) => {
    console.log(`onLoadedData`);
    setVideoReady(true);
  };

  const onCanPlay = (e) => {
    console.log(`onCanPlay`);
    setVideoDuration(e.target.duration);
  };

  const onPlaying = (e) => {
    console.log(`onPlaying`);
    setPlaying(true);
  };

  const onEnded = (e) => {
    console.log(`onEnded`);
    setVideoEnded(true);
  };

  const onPause = (e) => {
    console.log(`onPause`);

    setCurrentTime(e.target.currentTime);
  };

  const togglePoster = (e) => {
    e?.preventDefault()
    pauseAllMedia()
    ref.current.play();
    ref.current.classList.remove(`${styles['video-player--paused']}`);
    setPosterEnabled(!posterEnabled);
  };

  const togglePlayPause = (e) => {
    e.preventDefault();
    if (ref.current) {
      if (ref.current.paused) {
        pauseAllMedia()
        ref.current.play();
      } else {
        ref.current.pause();
      }
    }
  };

  useEffect(() => {
    if (currentTime) {
      //dataLayer.push({event: 'video pause', event_name: 'video pause', event_label: title, duration_seconds: currentTime});
      console.log('Video is paused');
    }
  }, [currentTime]);

  useEffect(() => {
    if (playing) {
      console.log('Video is playing');
      //dataLayer.push({event: 'video start', event_name: 'video start', event_label: title, duration_seconds: videoDuration});
      setPlaying(false);
    }
  }, [playing]);

  useEffect(() => {
    if (videoEnded) {
      //dataLayer.push({event: 'video complete', event_name: 'video complete', event_label: title, duration_seconds: videoDuration});
      console.log('Video has ended');
    }
  }, [videoEnded]);

  useEffect(() => {
    console.log("autoplay")
    if (autoPlay) {
      if (posterEnabled) {
        togglePoster();
      } else {
        ref.current.play();
      }
    }
  }, [autoPlay]);

  const posterStyle = {
    backgroundImage: `url(${poster})`,
  };

  return (
    <>
      {title && <HeadingTag className={styles['headline']}>{title}</HeadingTag>}
      <div className={`${styles.plyrContainer}`}>
        <div className={`${styles['video-wrapper']}`}>
          <video
            controls={options?.controls}
            controlsList='nodownload'
            ref={ref}
            className={`${styles['video-player']} ${
              posterEnabled ? styles['video-player--paused'] : ''
            }`}
            onLoadedData={onLoadedData}
            onCanPlay={onCanPlay}
            onPlaying={onPlaying}
            onPause={onPause}
            onClick={togglePlayPause}
            onEnded={onEnded}
            muted={muted}
            crossorigin="anonymous"
          >
            <source src={videoSrc} type={srcType} />
            {source?.tracks?.map((track, index) => (
              <track key={index} {...track} />
            ))}
            Your browser does not support the video tag.
          </video>
          {poster && posterEnabled && (
            <div
              ref={posterRef}
              className={styles['video-poster']}
              style={posterStyle}
              onClick={togglePoster}
            ></div>
          )}
          {posterEnabled && (
            <Button
              icon='videoPlay'
              className={styles['play-btn']}
              onClick={togglePoster}
            />
          )}
        </div>
      </div>
      {caption && (
        <p className={styles['video-caption']}>
          <RichText data={{ value: caption }} />
        </p>
      )}
      {transcript && (
        <div className={styles['video-transcript']}>
          <Button
            type='tertiary'
            text='Download Transcript'
            link={transcript}
            icon='download'
            iconAlign='right'
            download='transcript.txt'
          />
        </div>
      )}
    </>
  );
});

const Video = (props) => {
  const {
    title,
    titleTag,
    caption,
    image,
    src,
    srcType,
    transcript,
    subtitle,
    autoPlay,
    showControls,
    isLooped,
    clickToPlay,
    isMuted,
  } = props;

  const mediaSrc = {
    type: 'video',
    title: title,
    sources: [
      {
        src: src,
        type: srcType,
      },
    ],
    poster: image ? image : null,
    tracks: [],
  };

  if (subtitle) {
    mediaSrc.tracks.push({
      kind: 'subtitles',
      label: 'English',
      srcLang: 'en',
      src: subtitle,
      default: true,
    });
  }

  const mediaOptions = {
    controls: showControls
      ? [
          'play-large', // The large play button in the center
          'play', // Play/pause playback
          'progress', // The progress bar and scrubber for playback and buffering
          'current-time', // The current time of playback
          'duration', // The full duration of the media
          'mute', // Toggle mute
          'volume', // Volume control
          'captions', // Toggle captions
          'settings', // Settings menu
          'fullscreen', // Toggle fullscreen
        ]
      : '',
    autoPause: true,
    autoPlay: autoPlay ? autoPlay : false,
    clickToPlay: clickToPlay,
    invertTime: false,
    loop: { active: isLooped },
    muted: isMuted,
    tooltips: { controls: false, seek: true },
  };

  const videoRef = useRef(null);
  // const id = title && stringToCssId(title)
  return (
    <div className={`${styles.video}`}>
      <VideoInstance
        ref={videoRef}
        source={mediaSrc}
        options={mediaOptions}
        title={title}
        titleTag={titleTag}
        caption={caption}
        transcript={transcript}
      />
    </div>
  );
};

Video.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.string.isRequired,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  caption: PropTypes.string,
  image: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcType: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  transcript: PropTypes.string,
  autoPlay: PropTypes.bool,
  showControls: PropTypes.bool,
  isLooped: PropTypes.bool,
  clickToPlay: PropTypes.bool,
  isMuted: PropTypes.bool,
};

Video.defaultProps = {
  titleTag: 'h3',
  srcType: 'video/mp4',
  autoPlay: false,
  showControls: true,
  isLooped: false,
  clickToPlay: true,
  isMuted: false,
};

export default Video;
