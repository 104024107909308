import styles from "./CheckboxTile.module.scss";
import checkboxIcon from "assets/kanso/images/checkbox-tiles-icon.png";
import checkboxIconDisabled from "assets/kanso/images/checkbox-icon-disabled.png";

/**
 * Input Component
 * @param {string} type - it's the type checkbox - required
 * @param {string} name - it's the name of the checkbox - required
 * @param {string} id - will be used to have a unique label for- required
 * @param {string} error - it's the error message - optional
 * @param {string} checkboxLabel - checkbox label - required
 * @param {string} checkboxAssistiveText - checkbox assistive text - required
 * @param {string} checkboxIsRequired -check if the checkbox field is required or not - required
 * @param {string} checkboxIsDisabled -check if the checkbox field is disabled or not - required
 * @param {Object} tileImage - the image of the tile - optional
 * @param {string} checked -get the value of checkbox if check or unchecked for styling - required
 * @param {Object} field - field object contains the value of the checkbox and this parameter comes from Formik - required
 * @return React component
 **/

const CheckboxTile = (props) => {
  const {
    type,
    name,
    id,
    error,
    checkboxLabel,
    checkboxAssistiveText,
    checkboxIsRequired,
    checkboxIsDisabled,
    checked,
    tileImage,
    field,
    ...rest
  } = props;

  return (
    <div
      className={`
				${styles[`checkbox-tiles`]} 
				${checkboxIsDisabled ? styles[`checkbox-tiles_disabled`] : ""}
				${error ? styles[`checkbox-tiles_error`] : ""}
				${checked === true && !error ? styles[`checkbox-tiles-focused`] : ""}
			`}
    >
      <label htmlFor={id} className={styles[`checkbox-tiles_input`]}>
        <input
          name={name}
          type={type}
          id={id}
          required={checkboxIsRequired}
          disabled={checkboxIsDisabled}
          {...field}
          {...rest}
        />
        <div className={styles[`checkbox-checkmark`]}>
          <div className={styles[`checkmark`]}>
            <div className={styles[`checkmark-tick`]}></div>
          </div>
        </div>

        <div className={styles[`checkbox-tiles_label-container`]}>
          <img
            className={tileImage ? styles[`checkbox-tiles_icon--image`] : styles[`checkbox-tiles_icon`]}
            src={
              tileImage
                ? tileImage
                : checkboxIsDisabled
                ? checkboxIconDisabled
                : checkboxIcon
            }
            alt="icon"
          ></img>
          {(checkboxLabel || checkboxAssistiveText) && (
            <div className={styles[`checkbox-tiles_label`]}>
              <div>
                {checkboxLabel && (
                  <p className={styles[`checkbox-label__title`]}>
                    {checkboxLabel}
                  </p>
                )}
                {checkboxAssistiveText && (
                  <p className={styles[`checkbox-label_description`]}>
                    {checkboxAssistiveText}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default CheckboxTile;
