import { childrenPropTypes } from 'propTypes/generic';
import React, { createContext, useState } from 'react';

const verifyNotificationContext = createContext({});

const VerifyNotificationProvider = ({ children }) => {
  const [verifyNotificationIsOpen, setVerifyNotificationIsOpen] = useState(false);

  const showVerifyNotification = () => setVerifyNotificationIsOpen(true);
  const closeVerifyNotification = () => setVerifyNotificationIsOpen(false);

  const value = {
    verifyNotificationIsOpen,
    showVerifyNotification,
    closeVerifyNotification,
  };
  return (
    <verifyNotificationContext.Provider value={value}>
      {children}
    </verifyNotificationContext.Provider>
  );
};

VerifyNotificationProvider.propTypes = {
  children: childrenPropTypes,
};

export {
  VerifyNotificationProvider,
  verifyNotificationContext,
};
