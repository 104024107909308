import React from "react";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import RichText from "components/reusableComponents/richText/RichText";
import './MarketingConsent.scss';

const MarketingConsent = () => {
  const { tMarketingText } = useWebsiteConfigObject();
  const MarketingTextValue = { value: tMarketingText };
  return (
    <p className="marketing-consent">
      <RichText data={MarketingTextValue} />
    </p>
  );
};

export { MarketingConsent };
