import React, { useState, useEffect } from "react";
import Select from 'react-select';

import { InputError } from "assets/kanso/svgIcons/SvgIcons";    //SVG icon   

//Custom CSS
import styles from './DropdownList.module.scss';

/**
* Dropdown Component
* @param {string} id - required - its the id of the dropdown component, useful if we want to target the component
* @param {string} name - required - its the name of the dropdown, important for validation and to get the value
* @param {string} placeholder - required - its the placeholder name
* @param {string} label - required - its the label that will be place before the dropdown - but this can change as per the design or requirements
* @param {array} options - required - its an array of objects that contains the values of the dropdown list
* @param {boolean} error - required - validates the dropdown 
* @param {boolean} disabled - optional - disabled the dropdown
* @param {boolean} required - optional - to make the dropdown require or not
* @param {function} onchangeHandler - required - use to validate the dropdown list for onchange event
* @param {function} onblurHandler - required - use to validate the dropdown list for onblur event
  - react select plugin was used for this dropdown
  - Link: https://react-select.com/, https://www.npmjs.com/package/react-select
*/

const DropdownList = (props) => {
    const { 
      id, 
      name, 
      placeholder, 
      label, 
      options, 
      error, 
      disabled, 
      required, 
      onchangeHandler, 
      onblurHandler,
      defaultValue
    } = props;

    return (
      <label 
        aria-label="dropdown" 
        className={`${styles['form-select__field']} 
        ${ error ? styles['form-select__field--error']+' form-select__field--error' : ''} 
        ${ disabled ? styles['form-select__field--disabled']+' form-select__field--disabled' : ''}`}
      >      
        <span className={styles['form-select__label']}>{required && '* '}{label}</span>
        <Select 
          id={id}
          placeholder={placeholder}
          name={name}
          className="form-select"
          classNamePrefix="form-select"
          options={options}
          isDisabled={disabled}
          isSearchable={false}
          onChange={onchangeHandler}
          onBlur={onblurHandler}
          openMenuOnFocus={true}
          defaultValue={defaultValue}
        />
        {error && (
          <InputError className={styles['form-select__field__error-icon']} />
        )}
      </label>
    );
}


export default DropdownList;