import React from 'react'
import Link from 'components/reusableComponents/link/Link';
import RichText from 'components/reusableComponents/richText/RichText';
import { ExampleCategoryIcon, VideoIcon, ImageIcon } from 'components/shared/SvgIcons'
import './SearchResult.scss';
import { getUrlCountryPath } from 'utils/urlUtils';

const SearchResultContent = (props) => {

    const { tags, description, elements,  } = props.content;
    const { title, urlSlug, headerText, searchResultsData, basePage, openInNewTab } = elements;
    // const { title: searchResultTitle, linkText, showImageIcon, showVideoIcon } = searchResultsData?.value?.elements || {};
    const { shortDescription } = searchResultsData?.value?.elements || {};

    const { metaDescWithHtml, seolinkTitle, seoPageTitle, showImageIcon, showVideoIcon  } = basePage?.value || {};
    const pathname = window.location.href.split("/");
    //remove the last part of the pathname
    pathname.pop()
    const pathnameV2 = pathname.join("/")

    const showRichText = (headerText?.value || shortDescription?.value?.text.value || metaDescWithHtml?.value) ? true : false ;

    const resultsTitle = (title?.value) ? title?.value : seoPageTitle?.value
    console.log({ seoPageTitle, seolinkTitle })
    const urlText = (seolinkTitle?.value) ? seolinkTitle?.value : seolinkTitle?.value || pathnameV2 + urlSlug?.value
    const shortDesc = (metaDescWithHtml?.value || shortDescription?.value?.text.value) ? metaDescWithHtml || shortDescription?.value.text : headerText
    
    const handleClick = (e) => {
        if(openInNewTab?.value === true) {
            e.preventDefault()
            window.open(`/${getUrlCountryPath()}${urlSlug?.value}`)
        }
    }

    if (urlSlug?.value) {
        return (
            <div className="SearchResult_Holder">
                <div className="SearchResult_Icon"> 
                    {DisplayIcon(showImageIcon?.value, showVideoIcon?.value)} 
                </div>

                <div className="SearchResult_Content">
                    <h3 className="SearchResult_Content_Heading">{ resultsTitle }</h3>

                    <Link className="SearchResult_Content_Link" onClick={handleClick} href={urlSlug?.value}>{urlText}</Link>

                    {showRichText && <RichText className="SearchResult_Content_RichText" data={shortDesc} /> }

                    {!showRichText && description && <p className="SearchResult_Content_Text">{description}</p> }

                    <p className="SearchResult_Content_Text">
                        <span>{tags.join(" | ")}</span>
                    </p>
                </div>
                
            </div>
        )
    } else {
        return null;
    }
}

const DisplayIcon = (image, video) => {
    console.log({ video, image })
    if(video) { return <VideoIcon /> } 
    else if (image) { return <ImageIcon /> } 
    else { return <ExampleCategoryIcon /> }

  }


export default SearchResultContent