import React from 'react';
import PropTypes from 'prop-types';
import './InlineError.scss';


const InlineError = ({
  inlineErrorText,
}) => (
  <p className="form-inline-error-text">
    {inlineErrorText}
  </p>
);


const GenericError = ({
  GenericErrorText,
}) => (
  <p className="form-general-error-text">
    {GenericErrorText}
  </p>
);


InlineError.propTypes = {
  inlineErrorText: PropTypes.string,
};

GenericError.propTypes = {
  GenericErrorText: PropTypes.string,
};


export { InlineError, GenericError };

