import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import PropTypes from 'prop-types';
import { Ellipsis } from 'components/shared/SvgIcons';
import {
  Calender,
  Location,
  Clock,
} from "components/shared/SvgIcons";
import './WebinarTime.scss';


const WebinarTime = ({
    text,
    className,
    icon,
    type,
}) => {

  return (
      <div className={`timeIcon-section${className ? " "+className : ""}`}>
        <div className="time-icon">
          {icon === "calender" && <Calender />}
          {icon === "clock" && <Clock />}
          {icon === "location" && <Location />}
        </div>
        <div className="text-time">{text}</div>
      </div> 
  );
};


WebinarTime.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};


export {
    WebinarTime,
};
