import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './VideoSelector.scss';
import { useScreenWidth, SCREEN_WIDTH_DESKTOP } from 'components/layout/ScreenWidthContext';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { VideoSelectorDesktop } from './VideoSelectorDesktop';
import { VideoSelectorMobile } from './VideoSelectorMobile';



const VideoSelector = ({
  primaryColour,
  primaryLightColour,
  videoElements,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    currentWidthRanges,
  } = useScreenWidth();

  const isDesktop = currentWidthRanges.includes(SCREEN_WIDTH_DESKTOP);
  const Component = isDesktop ? VideoSelectorDesktop : VideoSelectorMobile;

  return (
    <Component
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      primaryColour={primaryColour}
      selectedBackgroundColour={primaryLightColour}
      videoElements={videoElements}
    />
  );
};

VideoSelector.propTypes = {
  primaryColour: PropTypes.string,
  primaryLightColour: PropTypes.string,
  videoElements: PropTypes.arrayOf(contentElementProptype),
};

export { VideoSelector };
