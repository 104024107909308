import AudioAcousticData from "components/kanso/atoms/audio/AudioAcousticData"
import DividerAcousticData from "components/kanso/atoms/divider/DividerAcousticData"
import EditorialTextAcousticData from "components/kanso/atoms/editorialText/EditorialTextAcousticData"
import Radio from "components/kanso/atoms/formElements/radio/Radio"
import Slider from "components/kanso/atoms/formElements/slider/Slider"
import ImageAcousticData from "components/kanso/atoms/image/ImageAcousticData"
import SectionFooterAcousticData from "components/kanso/atoms/sectionFooter/SectionFooterAcousticData"
import SectionTitleAcousticData from "components/kanso/atoms/sectionTitle/SectionTitleAcousticData"
import TableAcousticData from "components/kanso/atoms/table/TableAcousticData"
import VideoAcousticData from "components/kanso/atoms/video/VideoAcousticData"
import AccordionAcousticData from "components/kanso/components/accordion/AccordionAcousticData"
import CalloutQuoteAcousticData from "components/kanso/components/calloutQuote/CalloutQuoteAcousticData"
import CarouselAcousticData from "components/kanso/components/carousel/CarouselAcousticData"
import ContentCardAcousticData from "components/kanso/components/contentCard/ContentCardAcousticData"
import ContentCardItem from "components/kanso/components/contentCard/ContentCardItem"
import ContentCardTile from "components/kanso/components/contentCardTile/ContentCardTile"
import ContentCardTileItem from "components/kanso/components/contentCardTile/ContentCardTileItem"
import HeroHeaderAcousticData from "components/kanso/components/heroHeader/HeroHeaderAcousticData"
import Person from "components/kanso/components/person/Person"
import TabsAcousticData from "components/kanso/components/tabs/TabsAcousticData"
import EditorialAcousticData from "components/kanso/patterns/editorial/EditorialAcousticData"
import FeedbackFormAcousticData from "components/kanso/patterns/feedbackForm/FeedbackFormAcousticData"
import ImageListAcousticData from "components/kanso/patterns/imageList/ImageListAcousticData"
import InlineHelpAcousticData from "components/kanso/patterns/inlineHelp/InlineHelpAcousticData"
import PageSectionAcousticData from "components/kanso/patterns/pageSection/PageSectionAcousticData"
import PersonListAcousticData from "components/kanso/patterns/personList/PersonListAcousticData"
import QuizAcousticData from "components/kanso/patterns/quiz/QuizAcousticData"

export const componentsMapping = [
  { componentName: 'HeroHeader', acousticComponentName: 'Kanso-Component-HeroHeader', component: HeroHeaderAcousticData },
  { componentName: 'StandardHeader', acousticComponentName: 'Kanso-Component-StandardHeader' },
  { componentName: 'PageSection', acousticComponentName: 'Kanso-Pattern-PageSection', component: PageSectionAcousticData },
  { componentName: 'SectionTitle', acousticComponentName: 'Kanso-Atom-SectionTitle', component: SectionTitleAcousticData },
  { componentName: 'SectionFooter', acousticComponentName: 'Kanso-Atom-SectionFooter', component: SectionFooterAcousticData },
  { componentName: 'ContentCard', acousticComponentName: 'Kanso-Component-ContentCard', component: ContentCardAcousticData },
  { componentName: 'ContentCardItem', acousticComponentName: 'Kanso-Component-ContentCardItem', component: ContentCardItem },
  { componentName: 'ContentCardTile', acousticComponentName: 'Kanso-Component-ContentCardTile', component: ContentCardTile },
  { componentName: 'ContentCardTileItem', acousticComponentName: 'Kanso-Component-ContentCardTileItem', component: ContentCardTileItem },
  { componentName: 'Accordion', acousticComponentName: 'Kanso-Component-Accordion', component: AccordionAcousticData },
  { componentName: 'Tabs', acousticComponentName: 'Kanso-Component-Tabs', component: TabsAcousticData },
  { componentName: 'Carousel', acousticComponentName: 'Kanso-Component-Carousel', component: CarouselAcousticData },
  { componentName: 'Editorial', acousticComponentName: 'Kanso-Pattern-Editorial', component: EditorialAcousticData },
  { componentName: 'EditorialText', acousticComponentName: 'Kanso-Atom-EditorialText', component: EditorialTextAcousticData },
  { componentName: 'Image', acousticComponentName: 'Kanso-Atom-Image', component: ImageAcousticData },
  { componentName: 'Video', acousticComponentName: 'Kanso-Atom-Video', component: VideoAcousticData },
  { componentName: 'Audio', acousticComponentName: 'Kanso-Atom-Audio', component: AudioAcousticData },
  { componentName: 'CalloutQuote', acousticComponentName: 'Kanso-Component-CalloutQuote', component: CalloutQuoteAcousticData },
  { componentName: 'InlineHelp', acousticComponentName: 'Kanso-Pattern-InlineHelp', component: InlineHelpAcousticData },
  { componentName: 'Person', acousticComponentName: 'Kanso-Component-Person', component: Person },
  { componentName: 'PersonList', acousticComponentName: 'Kanso-Pattern-PersonList', component: PersonListAcousticData },
  { componentName: 'ImageList', acousticComponentName: 'Kanso-Pattern-ImageList', component: ImageListAcousticData },
  { componentName: 'Divider', acousticComponentName: 'Kanso-Atom-Divider', component: DividerAcousticData },
  { componentName: 'FeedbackForm', acousticComponentName: 'Kanso-Pattern-FeedbackForm', component: FeedbackFormAcousticData },
  { componentName: 'Table', acousticComponentName: 'Kanso-Component-Table', component: TableAcousticData },
  { componentName: 'RadioGroup', acousticComponentName: 'Kanso-Atom-Radio-Group', component: Radio },
  { componentName: 'Slider', acousticComponentName: 'Kanso-Atom-Slider', component: Slider },
  { componentName: 'Quiz', acousticComponentName: 'Kanso-Pattern-Quiz', component: QuizAcousticData }
]

export const getComponentName = (componentType) => {
  if (componentType) {
    let componentMapped = componentsMapping.find((item) => item.acousticComponentName === componentType)
    if (componentMapped) {
      return componentMapped.componentName
    }
  }
}

export const templateTypes = ['Kanso-Template-Flexi']
