import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/reusableComponents/richText/RichText'

import { contentItemProptype } from 'propTypes/contentProptypes';
import { castElementToBool } from 'utils/contentUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { CollapsableDetails } from './CollapsableDetails';
import './References.scss';


const References = ({
  className,
  references,
  veevaApprovalCode
}) => {
  const {
    tReferences,
    referenceBoxToggle,
  } = useWebsiteConfigObject();

  if (!castElementToBool(references)) {
    return null;
  }

  return (
    <CollapsableDetails
      title={tReferences}
      defaultState={referenceBoxToggle?.value ? true : false}
      className={`references ${className ? className : ''}`}
      vvClickAttr='click'
      vvSnapshotAttr='after'
      vvSequenceAttr= '2'
      
    >
    <RichText data={references} />
    {
        veevaApprovalCode && (
          <RichText data = {veevaApprovalCode}/>
        )
      }
      
    </CollapsableDetails>
  );
};

References.propTypes = {
  className: PropTypes.string,
  references: contentItemProptype,
};

export { References };
