const checkCookie = (groupId = 'C0002') => {
    const verifyCookie=(cname)=> {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    const  getCookie=() =>{
        let cookie = verifyCookie("OptanonConsent");
        // let cookie = "isIABGlobal=false&datestamp=Tue+Feb+25+2020+12:17:39+GMT-0500+(Eastern+Standard+Time)&version=5.12.0&landingPath=NotLandingPage&groups=C0001:1,C0002:0,C0003:1,C0004:0&hosts=H2:1,H9:1,H11:1,H12:1,H7:1&consentId=3ca3da17-dafe-4a3b-af91-7e54b3ceb21d&interactionCount=2&geolocation=;&AwaitingReconsent=false"
        let cookieAccept = false
        if(cookie){

            let pattern1 =  new RegExp(`groups(.*?)${groupId}..`);
            const found = cookie?.match(pattern1);
            let result = [];
            if(found){
                result = found[0]?.split(`${groupId}:`);
            }
            if(result[1] == 1){
                cookieAccept= true
            }
            else{
                cookieAccept= false
            }
        }
        return cookieAccept
    }
    return getCookie()
}
export {
    checkCookie,
};
