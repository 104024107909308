import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/reusableComponents/richText/RichText'
import { contentElementProptype } from 'propTypes/contentProptypes';
import { stringToCssId } from 'utils/stringUtils';
import './ArticleSection.scss';

// component for an article section
const ArticleSection = ({
  titleElement,
  bodyElement,
  className,
}) => {
  if (!bodyElement || !bodyElement.value) { return null; }
  const title = titleElement ? titleElement.value : null;
  const id = stringToCssId(title);
  return (
    <section
      className={`article-section-component ${className}`}
      id={id}
    >
      <h2 className="article-section-title">
        {title}
      </h2>
      <RichText data={bodyElement} />
    </section>
  );
};

ArticleSection.propTypes = {
  titleElement: contentElementProptype,
  bodyElement: contentElementProptype,
  className: PropTypes.string,
};

export { ArticleSection };
