import React from 'react';
import Video from 'components/reusableComponents/video/Video';
import { StorySelectorBody } from 'components/shared/story-selectors/StorySelectorBody';
import PropTypes from 'prop-types';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { getValue } from 'utils/contentUtils';
import {
  getAssetUrl,
} from 'api/acousticApi';


const VideoSelectorBody = ({
  selectedIndex,
  videoElements,
  backgroundColor,
  primaryColour,
}) => {
  const selectedItem = videoElements[selectedIndex];
  const selectedVideo = selectedItem ? selectedItem.elements.video : null;
  const selectedTab = selectedItem ? selectedItem.elements.tab : null;
  const hasVideo = getAssetUrl(selectedVideo); // checks to see if this is just an object of {elementType: "video"}
  const selectedTabValue = getValue(selectedTab);
  const selectedContent = hasVideo ? selectedVideo : selectedTabValue;


  return (
    <>
      {
        selectedContent && (
          hasVideo ? (
            <div className="video-container">
              <Video
                element={selectedContent}
              />
            </div>
          ) : (
            <StorySelectorBody
              story={selectedContent}
              backgroundColor={backgroundColor}
              primaryColour={primaryColour}
            />
          )
        )
      }
    </>
  );
};

VideoSelectorBody.propTypes = {
  selectedIndex: PropTypes.number,
  videoElements: PropTypes.arrayOf(contentElementProptype),
  backgroundColor: PropTypes.string,
  primaryColour: PropTypes.string,
};

export { VideoSelectorBody };
