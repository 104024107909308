import React, { createContext, useEffect } from 'react';
import { AppConfig } from 'AppConfig';
import { childrenPropTypes } from 'propTypes/generic';

// React context to maintain the current screen width in one place
// allowing the react app to render conditionally based on screen width
// this is based on the following article https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

// screen width range definitions
const SCREEN_WIDTH_SMALL = 'small';
const SCREEN_WIDTH_MEDIUM = 'medium';
const SCREEN_WIDTH_LARGE = 'large';
const SCREEN_WIDTH_XL = 'xl';

// mobile spans small and medium
const SCREEN_WIDTH_MOBILE = 'mobile';
// desktop spans large and xl
const SCREEN_WIDTH_DESKTOP = 'desktop';

// return an array of screen width ranges that the current viewport width applies to
// for example a viewport width of between minWidthMedium and minWidthLarge would return
// [SCREEN_WIDTH_MEDIUM, SCREEN_WIDTH_DESKTOP]
const getCurrentWidthRanges = (width) => {
  const {
    minWidthMedium,
    minWidthLarge,
    minWidthXl,
  } = AppConfig.responsiveGrid;
  if (width < minWidthMedium) {
    return [SCREEN_WIDTH_SMALL, SCREEN_WIDTH_MOBILE];
  }
  if (width < minWidthLarge) {
    return [SCREEN_WIDTH_MEDIUM, SCREEN_WIDTH_MOBILE];
  }
  if (width < minWidthXl) {
    return [SCREEN_WIDTH_LARGE, SCREEN_WIDTH_DESKTOP];
  }
  return [SCREEN_WIDTH_XL, SCREEN_WIDTH_DESKTOP];
};

// create a react context to contain the current screen width
const screenWidthContext = createContext({});

// context provider component. This component will keep a local state with the screen width
// and set up an event listener to capture screen resize. It will the provide the current width
// in the context
const ScreenWidthProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <screenWidthContext.Provider value={{ width }}>
      {children}
    </screenWidthContext.Provider>
  );
};

ScreenWidthProvider.propTypes = {
  children: childrenPropTypes,
};

// custom hook, allowing components in the tree access to the screen width and width ranges
const useScreenWidth = () => {
  const { width } = React.useContext(screenWidthContext);
  const currentWidthRanges = getCurrentWidthRanges(width);
  return { width, currentWidthRanges };
};

// same as useScreenWidth, except it doesn't auto update and is not nested in react. Is only useful for utility functions
// that don't need to trigger a rerender/are dependant on renders.
const getScreenWidth = () => {
  const width = window.innerWidth;
  const currentWidthRanges = getCurrentWidthRanges(width);
  return { width, currentWidthRanges };
};

export {
  useScreenWidth,
  getScreenWidth,
  ScreenWidthProvider,
  SCREEN_WIDTH_SMALL,
  SCREEN_WIDTH_MEDIUM,
  SCREEN_WIDTH_LARGE,
  SCREEN_WIDTH_XL,
  SCREEN_WIDTH_MOBILE,
  SCREEN_WIDTH_DESKTOP,
};
