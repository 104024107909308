import { searchQuery } from 'api/acousticApi'
import { getUrlCountryPath } from 'utils/urlUtils'
import { getLibraryByCode } from 'utils/libraryUtils'
import { pageTemplateTypesWithDynamicRoutes } from 'components/pageTemplates/pageTemplateTypes'

// note that the search query for content appears to be paginated. You have to send in the number of results you want
// and the start number. We won't know the actual maximum until we have enough content, so for now we send up a big number
const MAX_ROWS = 10000

const pageTemplateTypeParamValue = pageTemplateTypesWithDynamicRoutes.map((type) => `"${type}"`).join(' OR ')

const routesSearchParams = {
  q: '*:*',
  fl: 'name,string1,id,classification,type,status',
  fq: `type:(${pageTemplateTypeParamValue})`,
  rows: MAX_ROWS,
  start: 0
}

const params = new URLSearchParams(routesSearchParams)

const culture = getUrlCountryPath()

const { libraryId } = getLibraryByCode(culture)

params.append('fq', `libraryId:("${libraryId}")`)

const routesQuery = () => searchQuery(params)

export { routesQuery }
