import styles from './Quiz.module.scss'

const QuizQuestion = (props) => {  
  const { 
    id
  } = props

  return (
    <div className={styles["quiz-question-wrapper"]}>
      <div className={styles["quiz-question"]}>
        {props.children}
      </div>
    </div>
  )
}

export default QuizQuestion