import React,{useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';
import {
  InlineError,
} from 'components/shared/form/InlineError';
import { DropdownDownIcon } from '../SvgIcons';
import './Input.scss';
import './Dropdown.scss';
import { NavItem } from 'react-bootstrap';

const Dropdown = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
  requiredLabel
}) => {

  const {
    label,
    id,
    name,
    typeParams,
    mandatory,
    size = 100,
    reverseLabel=false
  } = field;

  const {
    type,
    // stuff that html likes. Used for stuff like remembering password and username ect
    autoComplete,
    placeholder,
    readOnly,
  } = typeParams;
  const value1 = field?.options[0]?.elements?.optionValue?.value;
  const touched = touchedFields[id];
  const inlineError = inlineErrors[id];
  const viewableError = InlineError && touched;
  const onChange = (e) => {
    const newValue = e.target.value;
    if(newValue === "default") {
      setFormValues((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    }else {
      setFormValues((prevState) => ({
        ...prevState,
        [id]: newValue,
      }));
    }
  };
  
  const dropdownRef = useRef(null);

  const setTouched = () => setTouchedFields((prevState) => ({
    ...prevState,
    [id]: true,
  }));

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];

  if (readOnly) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-read-only`);
  }

  const [checkedValue, setIsChecked] = useState(value1);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const ignoreClickOnMeElement = dropdownRef.current;
    document.addEventListener('click', (event) => {
        let isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
        if (!isClickInsideElement) {
          setOpen(false);
        } 
    })
  },[]);

  const clickOnSelect = () => {
    setOpen(!isOpen);
  }
  return (
    <div
      className={fieldClassNames.join(' ')}
    >
      <div className="field-meta-data dropdown-meta">
        {
          label && (
            <label
              htmlFor={id}
            >
              {!reverseLabel && label}
              {
                mandatory && (
                  <span
                    className="mandatory-astrix"
                  >
                    *
                  </span>
                )
              }
              {reverseLabel && label}
            </label>
          )
        }
        {
          viewableError && inlineError && (
            <InlineError
              inlineErrorText={requiredLabel || inlineError}
            />
          )
        }
      </div>
      <div className={`input-section dropdown-section${isOpen ? " dropdown-open" : ""}`} onClick={()=>clickOnSelect()}> 
          <select ref={dropdownRef} className="custom-focus dropdown-select" defaultValue={value1} onChange={(e)=>onChange(e)}>
          {
          field?.options.map((item, index) => (
              <option key={index} value={item?.elements?.optionValue?.value}>{item?.elements?.optionText?.value}</option>
          ))}
          </select>
          <DropdownDownIcon />
      </div> 
    </div>
  );
};

Dropdown.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
  formValues: formValuesPropTypes,
  setFormValues: PropTypes.func,
  inlineErrors: inlineErrorsPropTypes,
  touchedFields: touchedPropTypes,
  setTouchedFields: PropTypes.func,
};


export {
  Dropdown,
};


