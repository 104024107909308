import Editorial from './Editorial';
import { getComponentName } from 'utils/acousticMappings';
import { getValue, getValues } from 'utils/utilityHelper';
import AudioAcousticData from 'components/kanso/atoms/audio/AudioAcousticData';
import EditorialTextAcousticData from 'components/kanso/atoms/editorialText/EditorialTextAcousticData';
import CalloutQuoteAcousticData from 'components/kanso/components/calloutQuote/CalloutQuoteAcousticData';
import ImageAcousticData from 'components/kanso/atoms/image/ImageAcousticData';
import ImageListAcousticData from '../imageList/ImageListAcousticData';
import PersonListAcousticData from '../personList/PersonListAcousticData';
import VideoAcousticData from 'components/kanso/atoms/video/VideoAcousticData';
import InlineHelpAcousticData from '../inlineHelp/InlineHelpAcousticData';
import { getComponent } from 'pages/flexiTemplate/FlexiTemplate';

const EditorialAcousticData = ({ data, pageContent }) => {
  const { contentLeft, contentRight, cssClass, id } = data;

  const columnCount = [contentLeft?.values, contentRight?.values].reduce(
    (count, current) => {
      if(current && current.length > 0) return (count?.length > 0 ? 1 : 0) + 1
      return count?.length > 0 ? 1 : 0
    }
  );

  return (
    <Editorial cssClass={getValue(cssClass)} id={getValue(id)}>
      {getValues(contentLeft) && (
        <div>
          {contentLeft?.values?.map((content, index) => {
            const {
              component: Component,
              acousticComponentName,
              props,
            } = getComponent(content, pageContent);
            return Component && <Component key={index} columnCount={columnCount} {...props} />;
          })}
        </div>
      )}
      {getValues(contentRight) && (
        <div>
          {contentRight?.values?.map((content, index) => {
            const {
              component: Component,
              acousticComponentName,
              props,
            } = getComponent(content, pageContent);
            return Component && <Component key={index} {...props} columnCount={columnCount} />;
          })}
        </div>
      )}
    </Editorial>
  );
};

export default EditorialAcousticData;
