import React, { useState } from 'react';

//Component
import StaticChart from 'components/pageTemplates/infographics/components/StaticChart';
import RichText from 'components/reusableComponents/richText/RichText';
import OnOffCallOut from './OnOffCallOut';
import TimePopupsWrap from './timepopups/timepopupswrap';
import InfographicsDiagram from './InfographicsDiagram/InfographicsDiagram';
import InfographicsDiagramdopaminergic from './InfographicsDiagram/InfographicsDiagramdopaminergic';
import IconText from './IconText';
import ImageBlock from './ImageBlock';
import ColumnSection from './ColumnSection';
import CalloutBullets from './CalloutBullets/CalloutBullets';
import CallOut from './CallOut/CallOut';

import {
	Module_Column_Container,
	Module_Accordion,
	Module_Text_Block,
	Module_Centred_Icon,
	Component_Aligned_Icon,
	Module_Infographic,
	Module_Compound_Infographic,
	Module_Image_Block,
    Module_CallOut
} from '../../../../api/acousticApi/ContentTypesInfographics';

//Images
import plusIcon from '../../../../assets/images/infographics-assets/plus-icon.svg';
import plusIconBlue from '../../../../assets/images/infographics-assets/plus-icon-blue.svg';


//CSS
import './Accordion.scss';


const Accordion = (props) => {

    const { toggle, accordionId, color } = props;
    const [isOpen, setOpen] = useState( (toggle !== undefined && toggle === 'open') ? true : false);

    const disableTimePopUp = (isDisable) => {
        props.onDisableTimePopUp(isDisable)
    }

    const {
        accordionTitle,
        accordionComponents,
        backgroundColor,
        accordionBackgroundHexColour,
        accordionBodyDescription,
		accordionTitleFontHexColour,
        components,
        accordionCtaLabel,
        accordionReferenceId
    } = props.element.elements;

    let accordionID = (accordionId !== undefined) ? accordionId : ''; 
    let staticChartList = accordionComponents?.values;
    let  descriptionBackgroundColor= backgroundColor?.value;

    let accordionContentStyle = {
        backgroundColor: accordionBackgroundHexColour?.value,
        borderColor: descriptionBackgroundColor
    }

    return (
        <div className={`accordion-wrapper ${props.theme}`}>
            {props.accordionTimedPopups !=null && typeof props.accordionTimedPopups !== undefined && <TimePopupsWrap contents={props.accordionTimedPopups} onDisableTimePopUp={props.onDisableTimePopUp} wrapClass="timepopups-accordion-wrap" /> }   

            <div id={accordionReferenceId?.value} className={`accordion-title ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)} style={{backgroundColor:descriptionBackgroundColor }}>
                <div className="accordion-title-inner_wrapper" style={{ color: accordionTitleFontHexColour?.value }}>
                    <RichText data={accordionTitle} />
                </div>
                <div className="accordion-icon__wrapper">
				{
					accordionTitleFontHexColour?.value == "#ffffff" ?   <img src={plusIcon} /> :
					<img src={plusIconBlue}/>
				}
                   
                </div>
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content" style={accordionContentStyle}>
                    <RichText data={accordionBodyDescription} />
                        <div className="accordion-chart">
                        {
                            staticChartList && staticChartList?.map((elements, index) => (
                                <StaticChart 
                                    value={staticChartList[index]} 
                                />
                            )
                        )}
                    </div>
                    {(accordionCtaLabel?.values && accordionCtaLabel?.values?.map((elements, index) => (
                            <OnOffCallOut key={index} content={elements} />
                        ))
                    )}

                    {components && components?.values?.map((elements, index) => (
								((components?.values[index]?.typeId) === Module_Infographic ? 
									<InfographicsDiagramdopaminergic
									elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
									image= {elements?.elements?.imageMap?.value?.imagePicker}
									areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
									id={components?.values[index].id}
									timedPopup = {elements?.elements?.timedPopup?.value} 
									/> :								
								components?.values[index]?.typeId === Module_Compound_Infographic  ?
									<InfographicsDiagram
									elements={elements?.elements}
									onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)} 
									infographicsName={elements?.name}
									/>:
									
								components?.values[index]?.typeId === Module_Image_Block  ?
									<div className="container">
										<ImageBlock
											elements={elements}
										/>
									</div> :	
								components?.values[index]?.typeId === Module_Column_Container  ?
									<div>
											<ColumnSection
												elements={elements}
											/>
									</div> :
								components?.values[index]?.typeId === Module_Centred_Icon  ?
								<div className="icon-center">
									<IconText key={index} iconTextContent={elements} pid={`iconviews${index}`} />
								</div>:
                                components?.values[index]?.typeId === Module_Text_Block  ?
                                <div className="callout_Bullet_Image" >
                                    <CalloutBullets
                                        element={elements}
                                        index={index++}
                                    /> 
                                </div>
                                : components?.values[index]?.typeId === Module_CallOut  ?
                                <div className="container">
                                    <CallOut calloutContent={elements} />
                                </div> :null)
							))}
                </div>
            </div>
        </div>
    );
}

export default Accordion;
