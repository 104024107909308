// styles
import CountryByContinent from "components/layout/CountryByContinent/CountryByContinent";
import RichText from "components/reusableComponents/richText/RichText";
import "./GlobalPage.scss";

const GlobalPage = ({ content }) => {
    const {
        title,
        headerText,
        contentitems
    } = content?.elements;

    return (
        <section className="global_landing_page" role="main">
            <div className="header">
                <div className="header-container">
                    <h1>{title?.value ? title?.value : "Global directory page"}</h1>
                    <RichText data={headerText} />
                </div>
            </div>
            <div className="container">
                {
                    contentitems.values?.map((elements) => {
                        return <CountryByContinent key={elements.id} content={elements} />
                    })
                }
            </div>
        </section>
    );
}

export default GlobalPage;