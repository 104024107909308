import { element } from 'prop-types';
import { useEffect } from 'react';
import { getTealiumConfigValues } from 'utils/envUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setOneTrustAccepted } from 'redux/ui/slice';


const Tealium = ({ content,page }) => {

  const websiteConfig = useWebsiteConfigObject();
  const cookieVersionValue = websiteConfig.cookieBannerVersion?.selection;
  const { oneTrustAccepted } = useSelector(state => state.ui)
  const dispatch = useDispatch();

  const businessUnit = content?.elements?.taxonomy?.value?.message?.value?.businessUnit?.categories?.map(category => (
    category?.substring(category?.lastIndexOf('/') + 1, category?.length)
  ))[0];

  //authentication state
  const {  data  } = useSelector((state) => state.authentication);
  const { userId } = data.user && businessUnit === 'RDBU' ? data.user : {};

  //url querystring
  const urlParams = new URLSearchParams(window.location.search);

  //video data
  const video_id = content?.elements?.headerVideo?.asset?.id
    ? content?.elements?.headerVideo?.asset?.id
    : '';

  const videoObj = document.getElementById(video_id);
  
  const video_duration = videoObj !== null ? videoObj.duration : '';
  
  var utag_data={};
  let acousticFlag = false;
  let staticRoute = false;
 

  if(page === undefined || page === null)
  {
    //from acoustic
    acousticFlag = true;
    staticRoute= false;
  }

  if(page !== undefined)
  {  
    if(page.length > 1)
    {
      //not homepage
      staticRoute = true;
      acousticFlag= false;
    }
    else
    {
      //homepage -> should get value from acoustic
      acousticFlag = true;
      staticRoute = false;
    }  
  }

  
  if(acousticFlag)
  {
    //utag object when content is loaded from Acoustic
    
    utag_data.page_name= content?.elements?.title?.value
    ? content?.elements?.title?.value
    : '';
    utag_data.page_section= '';
    utag_data.page_category= content?.elements?.taxonomy?.value?.message?.value?.category?.categories !== undefined
      ? content?.elements?.taxonomy?.value?.message?.value?.category?.categories[0]
      : '';
    utag_data.page_subcategory= '';
    utag_data.page_type= content?.type ? content?.type : '';
    utag_data.video_id= video_id;
    utag_data.video_name= content?.elements?.headerVideo?.asset?.fileName
    ? content?.elements?.headerVideo?.asset?.fileName.replace('.mp4', '')
    : '';
    utag_data.video_duration= video_duration;
    utag_data.video_offset= ''; 
    utag_data.video_state= '';
    utag_data.video_player= ''; 
    utag_data.video_player_version= '';
    utag_data.utm_source= urlParams.get('utm_source');
    utag_data.utm_medium= urlParams.get('utm_medium');
    utag_data.utm_campaign= urlParams.get('utm_campaign');
    utag_data.referrer= '';
    utag_data.utm_content=urlParams.get('utm_content');
    utag_data.utm_term= urlParams.get('utm_term');
    utag_data.page_theme= content?.elements?.taxonomy?.value?.message?.value?.theme?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.message?.value?.theme?.categories
    : [];
    utag_data.page_therapy_area= content?.elements?.taxonomy?.value?.message?.value?.therapyArea?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.message?.value?.therapyArea?.categories[0]
    : '';
    utag_data.page_brand= content?.elements?.taxonomy?.value?.message?.value?.brand?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.message?.value?.brand?.categories
    : [];
    utag_data.page_business_unit= content?.elements?.taxonomy?.value?.message?.value?.businessUnit?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.message?.value?.businessUnit?.categories[0]
    : '';
    utag_data.page_indication= content?.elements?.taxonomy?.value?.message?.value?.indication?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.message?.value?.indication?.categories[0]
    : '';
    utag_data.page_product_lifecycle= content?.elements?.taxonomy?.value?.message?.value?.productLifecycle?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.message?.value?.productLifecycle?.categories[0]
    : '';
    utag_data.page_audience_type= content?.elements?.taxonomy?.value?.audience?.value?.audienceType?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.audience?.value?.audienceType?.categories
    : [];
    utag_data.page_audience_speciality= content?.elements?.taxonomy?.value?.audience?.value?.audienceSpeciality?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.audience?.value?.audienceSpeciality?.categories
    : [];
    utag_data.page_audience_journey_stage= content?.elements?.taxonomy?.value?.audience?.value?.audienceJourneyStage?.categories !== undefined
    ? content?.elements?.taxonomy?.value?.audience?.value?.audienceJourneyStage?.categories[0]
    : '';    
    utag_data.user_Id= userId;

    window.utag_data = utag_data;
  }
  if(staticRoute)
  {    
    //utag object for static routes
    utag_data.page_name=page.substring(1); 
    utag_data.page_type=page.substring(1); 
    utag_data.user_Id= userId;

    if(utag_data.page_name.length > 0)
    {
      window.utag_data = utag_data; 
    }
  }



  //make utag global so tealium can see object

  useEffect(() => {
    //overriding tealium defaults
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true

    const { tealiumUtag: url } = getTealiumConfigValues();

    //create tealium script
    const script = document.createElement('script');
    script.src = url;
    script.async = false;
    script.type = 'text/javascript'; 
    
    //prevent script from appending everytime
    let isTealiumScriptExist = false;
    const allScript = [...document.getElementsByTagName("script")];

    allScript.map(s => {
        if(s.src === url){
          isTealiumScriptExist = true;
        }
    });

    if(!isTealiumScriptExist){
      document.body.appendChild(script);
    }
    if(window.utag === undefined || window.utag === null){
      if(!(cookieVersionValue && cookieVersionValue === 'OneTrust')){
        setTimeout(()=> {
          window.utag.view(utag_data);
        }, 3000); 
      }
    }
    else{
      window.utag.view(utag_data);
    }
    

  },[]);

  useEffect(() => {
    if(oneTrustAccepted){
      if(window.utag === undefined || window.utag === null){
        setTimeout(()=> {
          window.utag.view(utag_data);
        }, 3000); 
      }
      else{
        window.utag.view(utag_data);
      }
      dispatch(setOneTrustAccepted(false));
    }
  },[oneTrustAccepted]);

  return null;
};

export { Tealium };