import EditorialText from './EditorialText';
import { getValue, getValues, getSelectionValue } from 'utils/utilityHelper';
import RichText from 'components/reusableComponents/richText/RichText';
import ButtonAcousticData from 'components/kanso/atoms/button/ButtonAcousticData';

const EditorialTextAcousticData = (props) => {
  const { align, caption, text, titleTag, title, columns, linksButtons } =
    props.data;

  const getRichText = (text) => {
    if (!text) return null;
    return <RichText data={{ value: text }} />;
  };

  return (
    <EditorialText
      align={getSelectionValue(align)}
      caption={getValue(caption)}
      titleTag={getSelectionValue(titleTag)}
      title={getValue(title)}
      columns={getValue(columns)}
      text={getRichText(getValue(text))}
    >
      {linksButtons && getValues(linksButtons) && (
        <>
          {getValues(linksButtons).map((button, i) => {
            return <ButtonAcousticData key={i} data={button?.elements} />;
          })}
        </>
      )}
    </EditorialText>
  );
};

export default EditorialTextAcousticData;
