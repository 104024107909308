import React from "react";

import styles from "./Checkbox.module.scss";

/**
 * Input Component
 * @param {string} type - it's the type checkbox - required
 * @param {string} name - it's the name of the checkbox - required
 * @param {string} id - will be used to have a unique label for- required
 * @param {string} error - it's the error message - optional
 * @param {string} checkboxLabel - checkbox label - required
 * @param {string} checkboxAssistiveText - checkbox assistive text - required
 * @param {string} checkboxIsRequired -check if the checkbox field is required or not - required
 * @param {string} checkboxIsDisabled -check if the checkbox field is disabled or not - required
 * @param {Object} field - field object contains the value of the checkbox and this parameter comes from Formik - required
 * @return React component
 **/
const CheckboxTiles = (props) => {
  const {
    type,
    name,
    id,
    error,
    checkboxLabel,
    checkboxAssistiveText,
    checkboxIsRequired,
    checkboxIsDisabled,
    field,
    ...rest
  } = props;

  return (
    <div
      className={`
				${styles[`checkbox`]} 
				${checkboxIsDisabled ? styles[`checkbox-disabled`] : ""}
				${error ? styles[`checkbox-error`] : ""}
			`}
    >
      <label for={id} className={styles[`checkbox-label`]}>
        <input
          name={name}
          type={type}
          id={id}
          required={checkboxIsRequired}
          disabled={checkboxIsDisabled}
          {...field}
				  {...rest}
        />
        <div className={styles[`checkbox-checkmark`]}>
          <div className={`${styles["checkmark"]}`}>
            <div className={styles[`checkmark-tick`]}></div>
          </div>
        </div>
        <div className={styles[`checkbox-labels`]}>
          <p className={styles[`checkbox-label__title`]}>{checkboxLabel}</p>
          {checkboxAssistiveText && (
            <p className={styles[`checkbox-label__description`]}>
              {checkboxAssistiveText}
            </p>
          )}
        </div>
      </label>
    </div>
  );
};

export default CheckboxTiles;
