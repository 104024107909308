import React from 'react';
import PropTypes from 'prop-types';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import RichText from 'components/reusableComponents/richText/RichText'
import { ApprovalCode } from 'components/content/ApprovalCode';
import { References } from 'components/content/References';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { PromoBanner } from 'components/layout/PromoBanner';
import Content from 'modules/content/Content';
import FormBuilder from 'modules/formBuilder/FormBuilder';
import './GenericPage.scss';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';
/*
  Template for a generic page (cookies etc)
  Figma design here https://www.figma.com/file/sseXCDDCoBI1Sge6080EpI/WF_KK?node-id=829%3A1621
  7.1/7.2/7.3/7.4  - Generic page
*/
const GenericPage = ({
  content,
  isLimited,
}) => {

  const {
    contentitems,
    headerText,
    mainBodyCopy,
    mainBodyCopyTemp,
    title,
    subtitle,
    veevaApprovalCode,
    references,
  } = content ? content.elements : {};
  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  return (
    <section role="main" className="generic-page-component">
      <MainAreaTitleSection
        title={title?.value}
        description={headerText}
        category={subtitle?.value}
      />
      <section className="content-section">
        <div className="container">
          {
            ( isLimited && (
              <PromoBanner
                content={{
                  value: {
                    title: {
                      elementType: 'text',
                      value: 'Nearly there...',
                    },
                    bodyText: {
                      elementType: 'formattedtext',
                      value: '<p>We must verify your account before you can see this page. Our team will try to complete this process as quickly as possible.</p>',
                    },
                    closingText: {
                      elementType: 'text',
                      value: 'Thank you for your patience.',
                    },
                  },
                }}
                darkerBg
                shadow
              />
            )) || (
            <>
              <div className="generic-page">
                <div className="column-left" />
                <article>
                  {(contentitems === undefined || contentitems === null || !contentitems || contentitems?.values === undefined) && 
                    <>
                      <RichText
                        data={mainBodyCopy}
                      />
                      <RichText
                        data={mainBodyCopyTemp}
                      />
                    </>
                  }
                  {contentitems?.values?.map((section, index) => {
                      const elements = (section.elements?.carousel?.value?.elements?.item?.values || []);
                      if(section?.type === "Component – Form Builder"){
                        return (
                          <div className='container'>
                            <FormBuilder element={section}/>
                          </div>
                        )
                      } 
                      let isImageSlide = true;
                      let props = {
                          content: section,
                          key: index
                      }
                      for ( let carouselItem of elements ) {
                          if (typeof carouselItem.elements?.image?.url === 'undefined')
                              isImageSlide = false;
                              break;
                      }
                      if (isImageSlide) {
                          props.componentType = 'slides';
                      }
                      return <Content cardClass='VideoTitleCard' {...props} />
                  })}
                  <ApprovalCode
                    code={veevaApprovalCode}
                  />
                </article>
                <div className="column-right" />
              </div>
              {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
              <References
                references={references}
              />
            </>
            )
          }
        </div>
      </section>
    </section>
  );
};

GenericPage.propTypes = {
  content: contentItemProptype,
  isLimited: PropTypes.bool,
};


export { GenericPage };
