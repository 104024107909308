/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import PropTypes from 'prop-types'
import { childrenPropTypes } from 'propTypes/generic'
import { ExampleCategoryIcon } from 'components/shared/SvgIcons'
import Link from 'components/reusableComponents/link/Link'
import './TextCard.scss'
import Image from '../reusableComponents/image/Image';
import { useScreenWidth, SCREEN_WIDTH_MOBILE } from 'components/layout/ScreenWidthContext';

const TextCardContainer = ({ onClickPath, externalUrl, className, children }) => {

    const cssClass = `text-card custom-focus ${className ? className : ''}`
        
    const href = externalUrl || onClickPath || null

    if (!href) {
        return <div className={cssClass}>{children}</div>
    }
    
    return (
        <Link className={cssClass} href={href}>
            {children}
        </Link>
    )
}

const TextCardMoodle = ({
    category,
    title,
    logoSrc,
    logoAlt = '',
    onClickPath, // route for when the card itself is clicked
    externalUrl,
    children,
    className,
    image,
}) => {
    const { currentWidthRanges } = useScreenWidth();
    const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);
    return (
        <TextCardContainer className={className} externalUrl={externalUrl} >
            <div className='card-section' id="textCardMoodle">
                <div className='text-section'>
                    <div className='top-section'>
                        {category && (
                            <h3 className='category'>
                                <ExampleCategoryIcon />
                                <span className="categoryText"> {category}</span>
                            </h3>
                        )}
                        {title && <h2 className='text-card-title'>{title}</h2>} 
                    </div>
                    <div className='bottom-section'>
                        <div className="description-section">
                        {isMobile?  <div className="logo-container">
                            <div className="logo-card-section">
                                {logoSrc?.url &&<Image data={logoSrc} className="text-card-logo" alt={logoAlt}/>}   
                            </div> 
                            </div>:null }
                            {children}
                        </div> 
                    </div>
                </div>
                {isMobile? null : <div className="logo-container">
                    <div className="logo-card-section">
                        {logoSrc?.url &&<Image data={logoSrc} className="text-card-logo" alt={logoAlt}/>}   
                    </div> 
                </div>}
                {image?.src && (
                    <div className='image-section'>
                        <img className='' alt={image?.alt} src={image?.src} />
                    </div>
                )}
            </div>
        </TextCardContainer>
    )
}

TextCardMoodle.propTypes = {
    category: PropTypes.string,
    title: PropTypes.string,
    logoSrc: PropTypes.string,
    logoAlt: PropTypes.string,
    onClickPath: PropTypes.string,
    externalUrl: PropTypes.string,
    children: childrenPropTypes,
}

export { TextCardMoodle }
