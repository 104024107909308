import React from 'react'
import './MobileToggle.scss'

import { useSelector, useDispatch } from 'react-redux'
import { toggleMobileNav } from '../../../../../redux/ui/slice'

const MobileToggle = () => {

    const { mobileNavStatus } = useSelector(state => state.ui)
    const dispatch = useDispatch()

    const handleToggle = () => {
        dispatch(toggleMobileNav())
    }

    return (
        <div className={`MobileToggle ${mobileNavStatus?'MobileToggle--open':''}`} onClick={handleToggle}>
            <span /><span /><span /><span />
        </div>
    )
}

export default MobileToggle