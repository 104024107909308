import React, { useState } from 'react';
import Link from 'components/reusableComponents/link/Link';
import { getCourseDate } from 'components/pageTemplates/webinar/webinarHelpers';
import { deleteCourse } from 'api/dynamoApi';
import { Bin } from './SvgIcons';
import { Loader, TINY_LOADER } from 'components/shared/Loader';

import './OngoingCourseCard.scss';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useHistory } from 'react-router-dom';

const OngoingCourseCard = ({ item, content, userId, token, removeCourse }) => {
  const {
    registeredDateLabel,
    continueLabel,
    startDateLabel,
    continueCourseText,
    removeCourseTooltipText,
    removeCourseAlertMessage,
  } = content;
  const history = useHistory();
  const [mouseOver, setMouseOver] = useState(false);
  const [removing, setRemove] = useState(false);
  const { registration, started, url, name, externalId } = item?.data;
  const {
    recordId
  } = item;
  const {trackContinueCourses} = useGoogleAnalytics();
  const deleteCourseAction = () => {
    setMouseOver(false);
    let body = {};
    body['userId'] = userId;
    body['externalId'] = externalId;
    if(recordId){
      body['recordId'] = recordId;
    }
    if (window.confirm(removeCourseAlertMessage?.value)) {
      setRemove(true);
      deleteCourse({ body, token })
        .then((response) => {
          if (response.status === 200) {
            removeCourse(externalId);
            setRemove(false);
          }
        })
        .catch((error) => {
          setRemove(false);
          console.log(error);
        });
    } else {
      setRemove(false);
    }
  };
  const callGA = ()=>{
    trackContinueCourses(name,userId);
  }

  return (
    <div className='course-card'>
      <div className='course-card__title'>
        <h3>{name}</h3>
      </div>
      <div className='course-card__dates'>
        <div className='course-card__dates__registered'>
          <span className='label'>
            {registeredDateLabel?.value
              ? registeredDateLabel?.value
              : 'Registered '}
          </span>
          <span className='date'>{getCourseDate(registration)}</span>
        </div>
        <div className='course-card__dates__start'>
          <span className='label'>{startDateLabel?.value || 'Start Date'}</span>
          <span className='date'>{getCourseDate(started)}</span>
        </div>
      </div>
      <div className='course-card__continue'>
        <div className='course-card__continue__label'>
          {continueCourseText?.value}
        </div>
        <div className='course-card__continue__btn'>
          <Link href={url} onClick={callGA}>
            <span>{continueLabel?.value}</span>
          </Link>
        </div>
      </div>
      <div
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
        className='delete-btn'
        onClick={deleteCourseAction}
      >
        {removing && <Loader size={TINY_LOADER} />}
        {!removing && <Bin />}
      </div>
      <div className={`delete-btn-tooltip${mouseOver ? ' active' : ''}`}>
        {removeCourseTooltipText?.value}
      </div>
    </div>
  );
};

export default OngoingCourseCard;
