import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { childrenPropTypes } from 'propTypes/generic';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import './GenericMessagePage.scss';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useGATaxonomy } from 'hooks/taxonomyGoogleAnalytics';

const GenericMessagePage = ({
  titleText,
  headerText,
  categoryText,
  children,
}) => {
  const headerTextHtml = headerText
    ? {
        elementType: 'formattedtext',
        value: `<p>${headerText}</p>`,
      }
    : null;

  const hasTitleSection = titleText || headerText || categoryText;

  const { trackPageView } = useGoogleAnalytics();
  document.body.setAttribute('data-page-status', titleText)
  
  useEffect(() => {
    if(titleText === '404') {
      const taxonomy = {
        'message-businessUnit': '404 Page not Found',
        status: titleText
      }
      trackPageView(window.location.pathname, titleText, false, taxonomy)
    } 
  }, [])

  return (
    <>
      {titleText === '404' && (
        <Helmet>
          <meta name='prerender-status-code' content='404'></meta>
        </Helmet>
      )}
      <section role='main' className='generic-message-page'>
        {hasTitleSection && (
          <MainAreaTitleSection
            title={titleText}
            description={headerTextHtml}
            category={categoryText}
          />
        )}
        {children && (
          <section className='content-section'>
            <div className='container'>
              <div className='generic-message-content'>{children}</div>
            </div>
          </section>
        )}
      </section>
    </>
  );
};

GenericMessagePage.propTypes = {
  titleText: PropTypes.string,
  headerText: PropTypes.string,
  categoryText: PropTypes.string,
  children: childrenPropTypes,
};

export { GenericMessagePage };
