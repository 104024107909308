import React, { useState, useRef, useEffect } from 'react';
import { DropdownArrowDown } from '../SvgIcons';
import {
  InlineError,
} from 'components/shared/form/InlineError';
import './WebinarDropdown.scss';

const WebinarDropdown = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
  requiredLabel,
  setPrimaryValue,
}) => {
  const {
    label,
    id,
    mandatory,
    placeholder,
    disabled,
    values,
    showAsterix,
    size = 100,
  } = field;

  const value = formValues[id];
  const touched = touchedFields[id];
  const inlineError = mandatory ? inlineErrors[id] || formValues[id] === "" : '';
  const viewableError = InlineError && touched;
  const onChange = (e) => {
    const newValue = e.target.value;
    setFormValues((prevState) => ({
      ...prevState,
      [id]: newValue,
    }));
    setTouchedFields((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    if (setPrimaryValue) {
      setPrimaryValue(newValue);
    }
  };
  const dropdownRef = useRef(null);

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];
  const [isOpen, setOpen] = useState(false);
  const clickOnSelect = () => {
    setOpen(!isOpen);
  }
  
  useEffect(() => {
    const ignoreClickOnMeElement = dropdownRef.current;
    document.addEventListener('click', (event) => {
        let isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
        if (!isClickInsideElement) {
          setOpen(false);
        } 
    })
  },[]);
  
  if(!values || values === ""){
    return null;
  }

  return (
    <div className={`form-field-dropdown-webinar ${fieldClassNames.join(' ')}`}>
      <div className='field-meta-data'>
        {label && (
          <label htmlFor={id}>
            {mandatory && (
              <span className='mandatory-astrix'>*</span>
            )}
            {label}
          </label>
        )}
        {
          viewableError && inlineError && (
            <InlineError
              inlineErrorText={requiredLabel || inlineError}
            />
          )
        }
      </div>
      <div className={`webinar-dropdown-select${isOpen ? " dropdown-open" : ""}`} >
        <select
          className='custom-dropdown-select'
          onChange={onChange}
          onClick={()=>clickOnSelect()}
          disabled={disabled}
          ref={dropdownRef}
        >
          <option value=''>{placeholder}</option>
          {values &&
            values.map((item) => {
              return (
                <option key={item?.value} value={item?.value} default>
                  {item?.name}
                </option>
              );
            })}
        </select>
        <DropdownArrowDown />
      </div>
    </div>
  );
};

export { WebinarDropdown };
