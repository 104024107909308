/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { getUrlCountryPath } from 'utils/urlUtils';
import useAuthExtensionWhitelist from 'hooks/useAuthExtensionWhitelist'
import { isElement } from 'react-dom/test-utils';

const hasParams = (url) => {
    return url.includes('?')
}

const useAppendAuthParams = (value) => {

    const { guest } = useSelector((state) => state.authentication?.status)
    const { token } = useSelector((state) => state.authentication?.data?.token)

    const { whitelist } = useAuthExtensionWhitelist()

    let disableExternalMessageAlert = false
    let isMoodleDomain= false;

    if (!whitelist || !value) return value

    whitelist.forEach((site) => {
        const { domains,url, disableExternalMessage, generateUserId } = site || {}
        const keysDomains= Object.values(domains);
        for (const item of keysDomains) {
            if(value?.indexOf(item) != -1){
                isMoodleDomain= true
                break;
            }
        }
        if (value?.indexOf(url) == -1) {
            return value
        }
        disableExternalMessageAlert = disableExternalMessage || false

        if (!token) {

            if (guest && generateUserId) {
                // TODO - Add logic to append generated guest userid/token to params
                value = `${value}${hasParams(value) ? '&' : '?'}userId=123test&locale=${getUrlCountryPath()}&returnUrl=${window.location.origin}/${getUrlCountryPath()}`
            }
        }
        else {
            value = `${value}${hasParams(value) ? '&' : '?'}at=${token}&locale=${getUrlCountryPath()}&returnUrl=${window.location.origin}/${getUrlCountryPath()}`
        }
    })

    return {
        value,
        isMoodleDomain,
        disableExternalMessageAlert,
    }
}

export default useAppendAuthParams