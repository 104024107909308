import React from 'react';
import Link from 'components/reusableComponents/link/Link';
import {
  ARTICLE_PATH,
  AVAILABLE_TREATMENTS_PATH,
  CONTENT_PROMO_PAGE_PATH,
  GENERIC_PAGE_PATH,
  HOME_PAGE_PATH,
  VEEVA_PLAYER_PAGE_PATH,
  LIST_CONTENT_PATH,
  MAIN_PAGE_PATH,
  MAIN_PAGE_PROMO_PATH,
  LOGIN_PAGE_PATH,
  RESET_PASSWORD_PATH,
  FORGOTTEN_PASSWORD_PATH,
  PROFILE_PAGE_PATH,
} from 'routes/routeConstants';
import { useApiRoutes } from 'routes/useApiRoutes';




const COMPLETE_STATUS = { name: 'Complete', color: '#00cc00' };
const PENDING_STATUS = { name: 'Pending', color: '#cc0000' };
const IN_PROGRESS_STATUS = { name: 'In Progress', color: '#0000cc' };

const pageTemplates = [
  {
    title: 'Article page',
    path: ARTICLE_PATH,
    status: COMPLETE_STATUS,
  },
  {
    title: 'Available treatments home',
    path: AVAILABLE_TREATMENTS_PATH,
    status: COMPLETE_STATUS,
  },
  {
    title: 'Content page',
    path: '/content',
    status: COMPLETE_STATUS,
  },
  {
    title: 'Content Promo',
    path: CONTENT_PROMO_PAGE_PATH,
    status: PENDING_STATUS,
  },
  {
    title: 'Generic Page',
    path: GENERIC_PAGE_PATH,
    status: COMPLETE_STATUS,
  },
  {
    title: 'Veeva Player Page',
    path: VEEVA_PLAYER_PAGE_PATH,
    status: COMPLETE_STATUS,
  },
  {
    title: 'Home Page',
    path: HOME_PAGE_PATH,
    status: COMPLETE_STATUS,
  },
  {
    title: 'List Content Page',
    path: LIST_CONTENT_PATH,
    status: COMPLETE_STATUS,
  },
  {
    title: 'Main page',
    path: MAIN_PAGE_PATH,
    status: IN_PROGRESS_STATUS,
  },
  {
    title: 'Main page promo',
    path: MAIN_PAGE_PROMO_PATH,
    status: PENDING_STATUS,
  },
];


const miscPages = [
  {
    title: 'Login',
    path: LOGIN_PAGE_PATH,
    status: IN_PROGRESS_STATUS,
  },
  {
    title: 'Profile',
    path: PROFILE_PAGE_PATH,
    status: IN_PROGRESS_STATUS,
  },
  {
    title: 'Reset Password',
    path: RESET_PASSWORD_PATH,
    status: IN_PROGRESS_STATUS,
  },
  {
    title: 'Forgotten Password',
    path: FORGOTTEN_PASSWORD_PATH,
    status: IN_PROGRESS_STATUS,
  },
];


// temporary page to allow easy navigation to the different templates, as we are building them
const PageTemplates = () => {
  const { routes } = useApiRoutes();
  return (
    <section role="main" className="main-page">
      <div className="container">
        <h1>Page Templates</h1>
        {pageTemplates.map(({ path, title, status }) => (
          <p key={path}>
            <Link
              href={path}
            >
              {title}
            </Link>
            {status && (
              <span
                // doing inline styles here rather than sass as this page will be
                // thrown away
                style={{ color: status.color, paddingLeft: '10px' }}
              >
                {status.name}
              </span>
            )}
          </p>
        ))}
        <h1>Misc Pages</h1>
        {
          miscPages.map(({
            path,
            title,
            status,
          }) => (
            <p key={path}>
              <Link
                href={path}
              >
                {title}
              </Link>
              {status && (
                <span
                  // doing inline styles here rather than sass as this page will be
                  // thrown away
                  style={{ color: status.color, paddingLeft: '10px' }}
                >
                  {status.name}
                </span>
              )}
            </p>
          ))
        }
        <h1>Acoustic content pages</h1>
        {
          routes && routes.map(({ path, contentId }) => (
            <p key={contentId}>
              <Link
                href={path}
              >
                <strong>{path}</strong>
                {` (contentId: ${contentId})`}
              </Link>
            </p>
          ))
        }
      </div>
    </section>
  );
};

export { PageTemplates };
