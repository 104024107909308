import React, { useState, useEffect, useRef } from 'react';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { SectionPageList } from 'modules/sectionPage/SectionPageList';
import { PromoBanner } from 'components/layout/PromoBanner';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { References } from 'components/content/References';
import { getValue, getSelectionValue } from 'utils/contentUtils';
import { FeaturedWebinar } from 'components/content/webinar/FeatureWebinar';

import Subnavigation from 'modules/subNavigation/SubNavigation';
import {WebinarCard} from '../../content/webinar/WebinarCard';
import RelatedContent from 'modules/relatedContent/RelatedContent';

import '../ContentListPage.scss';
import './WebinarListPage.scss';
import RichText from 'components/reusableComponents/richText/RichText';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { selectedPage } from 'redux/search/slice';

import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';
import { ApprovalCode } from 'components/content/ApprovalCode';

const WebinarListPage = ({
  content,
  template
}) => {
  const {
    category,
    title,
    headerText,
    headerLogo,
    headerBackgroundImage,
    headerTitleHexColour,
    headerBackgroundHexColour,
    cardSubcategoryFilter,
    cards,
    moduleSectionPageList,
    callout,
    bannerImage,
    references,
    backgroundcolor,
    subnavigation,
    upcomingWebinarsCountPerPage,
    webinarLearnMoreLabel,
    webinarSignUpLabel,
    upcomingWebinarsTitle,
    featuredWebinarsTitle,
    relatedContentCards,
    relatedContentLabel,
    basePage,
    pastWebinarsLabel,
    veevaApprovalCode
  } = content.elements;
  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  const { tags } = content;
  const excerpt = basePage?.value?.metaDescWithHtml;
  const { webinars } = useSelector((state) => state.user);
  const [webinarFeatured, setWebinarFeatured] = useState(null);
  const [webinarUpcoming, setWebinarUpcoming] = useState(null);
  const [pastWebinars, setPastWebinars] = useState(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 

  const displaycard = (webinars, tags) => {
    let cardsElementsList = new Array();
    let featuredWebinar = new Array();
      const configList = Object.values(webinars?.documents);
  
      for (const item of configList) {
          const items = item?.document?.elements
          const itemTag = item?.document?.tags;
          if(tags[0] && itemTag.includes(tags[0])){
            if(items?.featuredWebinar?.value){
              featuredWebinar.push(items);
              cardsElementsList.push(items);
              }
            else{
              cardsElementsList.push(items);
            } 
          }
  
      }
      return( {cardsElementsList,featuredWebinar} )
  }

  let openInNewTab;

  useEffect(() => {
    if(webinars){
      let webinarList = null;
      let pastWebinarList = null;
      let featuredWebinarList = null;
      if(webinars && webinars?.data){
        webinarList = displaycard(webinars?.data, tags);
        pastWebinarList = displaycard(webinars?.data, tags);
        featuredWebinarList = displaycard(webinars?.data, tags);
        if(webinarList){
          webinarList.cardsElementsList.sort(function(a,b){
            return new Date(a.webinarDate.value) - new Date(b.webinarDate.value);
          });
          webinarList.cardsElementsList = webinarList.cardsElementsList.filter((list) => {
            if(list?.webinarEndDate?.value){
              return (new Date(new Date(list.webinarEndDate.value).toLocaleDateString("en-ZA"))).getTime() >= (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
            } else {
              return (new Date(new Date(list.webinarDate.value).toLocaleDateString("en-ZA"))).getTime() >= (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
            }
          })
        }
        if(pastWebinarList){
          pastWebinarList.cardsElementsList.sort(function(a,b){
            return new Date(b.webinarDate.value) - new Date(a.webinarDate.value);
          });
          pastWebinarList.cardsElementsList = pastWebinarList.cardsElementsList.filter((list) => {
            if(list?.displayPastWebinars?.value){
              return list;
            } 
          })
          pastWebinarList.cardsElementsList = pastWebinarList.cardsElementsList.filter((list) => {
            if(list?.webinarEndDate?.value){
              return (new Date(new Date(list.webinarEndDate.value).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
            } else {
              return (new Date(new Date(list.webinarDate.value).toLocaleDateString("en-ZA"))).getTime() < (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
            }
          })
        }
        if(featuredWebinarList.featuredWebinar){
          featuredWebinarList.featuredWebinar.sort(function(a,b){
            return new Date(a.webinarDate.value) - new Date(b.webinarDate.value);
          });
          featuredWebinarList.featuredWebinar = featuredWebinarList.featuredWebinar.filter((list) => {
            if(list?.webinarEndDate?.value){
              return (new Date(new Date(list.webinarEndDate.value).toLocaleDateString("en-ZA"))).getTime() >= (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
            } else {
              return (new Date(new Date(list.webinarDate.value).toLocaleDateString("en-ZA"))).getTime() >= (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
            }
          })
        }
        if(featuredWebinarList.featuredWebinar && featuredWebinarList.featuredWebinar[0]) {
          setWebinarFeatured(featuredWebinarList.featuredWebinar[0]);
        }
        setWebinarUpcoming(webinarList.cardsElementsList);
        setPastWebinars(pastWebinarList.cardsElementsList);
      }
    }
  },[webinars]);

  let numOfCards= upcomingWebinarsCountPerPage?.value;
  if(numOfCards === undefined){
    numOfCards = 6;
  }
  const CARDS_PER_PAGE = numOfCards;
  let handleNumOfResult = 7;
  if(webinarUpcoming){
    handleNumOfResult = webinarUpcoming.length;
  }
  let handleNumOfResultPast = 7;
  if(pastWebinars){
    handleNumOfResultPast = pastWebinars.length;
  }
  let pageCount = Math.ceil(handleNumOfResult/CARDS_PER_PAGE);
  let pageCountPast = Math.ceil(handleNumOfResultPast/CARDS_PER_PAGE);
  const [page, setPage] = useState(pageSelected);
  const [pagePast, setPagePast] = useState(1);
  const [startNumber, setStartNumber] = useState(0);
  const [startNumberPast, setStartNumberPast] = useState(0);
  const paginatesCards = webinarUpcoming ? webinarUpcoming.slice(startNumber, startNumber+CARDS_PER_PAGE) : [];
  const paginatesCardsPast = pastWebinars ? pastWebinars.slice(startNumberPast, startNumberPast+CARDS_PER_PAGE) : [];
  const hasMorePages = webinarUpcoming ? webinarUpcoming.length > paginatesCards.length : false;
  const hasMorePagesPast = pastWebinars ? pastWebinars.length > paginatesCardsPast.length : false;
  const pageBackgroundColor = getSelectionValue(backgroundcolor) === 'grey' ? true : false;
  const {pageSelected } = useSelector(
    (state) => state.search
  );
  
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const refPastWebinars = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const executeScrollPast = () => scrollToRef(refPastWebinars);
  
  function handleChange(event,value){
    setPage(value);
    dispatch(selectedPage(value));
    const startNumbers = value > 1 ? (value - 1) * CARDS_PER_PAGE : 0;
    setStartNumber(startNumbers);
    executeScroll()
  };

  function handleChangePast(event,value){
    setPagePast(value);
    const startNumbers = value > 1 ? (value - 1) * CARDS_PER_PAGE : 0;
    setStartNumberPast(startNumbers);
    executeScrollPast()
  };
  //on load
  useEffect(() => {
    // set cards on load
    setPage(pageSelected);
  }, []);

  const defaultSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 3,
    infinite: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1340,
        settings: { slidesToShow: 2, arrows: true },
      },
      {
        breakpoint: 950,
        settings: { slidesToShow: 1, arrows: false },
      },
    ],
  }
  
  return (
    <div>  
      <section role="main"  className='list-content' id="webinarList"
        style={{ backgroundColor: pageBackgroundColor ? '#faf6f4' : '',
              marginBottom: pageBackgroundColor ? '1em' : '' }}>
        <MainAreaTitleSection
          category={category && category.value}
          title={title && title.value}
          headline={headerText}
          logo={headerLogo}
          fontHexColour={headerTitleHexColour}
          backgroundHexColour={headerBackgroundHexColour}
        />
      
        <section >
          <div className="container">
            <div className="list-content-page">
              <div className="column-left" id="column-left">
                <section className="main-page-subnavsection">
                  {subnavigation?.value &&
                    <Subnavigation element={getValue(subnavigation)} />
                  }
                </section>
              </div>
              <div className="main-content">
                <div>
                    <div className="main-content-body">
                      <RichText data={excerpt}/>
                    </div>
                    {webinarFeatured && <RichText data={featuredWebinarsTitle} className="title-feature"/>}
                </div>
                {webinarFeatured&&
                  <FeaturedWebinar 
                    title={webinarFeatured?.title} 
                    url={webinarFeatured?.urlSlug}
                    bodyText={webinarFeatured?.basePage?.value?.metaDescWithHtml?.value ? webinarFeatured?.basePage?.value?.metaDescWithHtml : webinarFeatured?.basePage?.value?.seoShortDesc?.value} 
                    image={webinarFeatured.webinarImage}  
                    buttonName={webinarSignUpLabel?.value}
                    buttonColor={callout.value.buttonHexColour.value} 
                    date={webinarFeatured.webinarDate}
                    time={webinarFeatured.webinarTime.value}
                    webinarType={webinarFeatured?.webinarType?.value}
                    openInNewTab={webinarFeatured?.openInNewTab?.value}
                  />
                }
                <div id="webinar-cards-list" ref={myRef}>
                {webinarUpcoming?.length > 0 && <RichText data={upcomingWebinarsTitle} className="title-feature"/>}  
                </div>
                <div className="cards-section-list">
                  {paginatesCards? paginatesCards &&  Object.values(paginatesCards).map((item, i) => {
                     return <WebinarCard 
                        key={i}
                        labelReadMore={webinarLearnMoreLabel.value}
                        moduleSectionPage={item}
                        buttonColor={callout.value.buttonHexColour.value}
                        openInNewTab={item?.openInNewTab?.value}
                      />
                  }):null
                  }
                </div>
                {hasMorePages && ( // hide the view more button once all the results are loaded
                  <div className="view-more-button-container">
                    <div className='Pagination'>
                      <Pagination
                        count={pageCount}
                        page={page}
                        color='primary'
                        onChange={(e,v)=>{
                          handleChange(e,v)
                        }}
                      />
                    </div>
                  </div>
                )}
                <div id="webinar-cards-list" ref={refPastWebinars}>
                {pastWebinars?.length > 0 && pastWebinarsLabel?.value && <RichText data={pastWebinarsLabel} className="title-feature"/>}  
                </div>
                <div className="cards-section-list">
                  {paginatesCardsPast? paginatesCardsPast &&  Object.values(paginatesCardsPast).map((item, i) => {
                     return <WebinarCard 
                        key={i}
                        labelReadMore={webinarLearnMoreLabel.value}
                        moduleSectionPage={item}
                        buttonColor={callout.value.buttonHexColour.value}
                        openInNewTab={item?.openInNewTab?.value}
                      />
                  }):null
                  }
                </div>
                {hasMorePagesPast && ( // hide the view more button once all the results are loaded
                  <div className="view-more-button-container">
                    <div className='Pagination'>
                      <Pagination
                        count={pageCountPast}
                        page={pagePast}
                        color='primary'
                        onChange={(e,v)=>{
                          handleChangePast(e,v)
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <SectionPageList moduleSectionPageList={moduleSectionPageList} />
            </div>
          {relatedContentCards && (
            <RelatedContent
              content={relatedContentCards}
              carouselSettings={defaultSettings}
              headingText={relatedContentLabel?.value ? relatedContentLabel?.value : ""}
              fluid
            />
          )}
          {callout?.value?.bodyText?.value && <PromoBanner content={callout} whiteBg={ pageBackgroundColor ? true : false}/> } 
          <section className='list-content' style={{ marginTop: pageBackgroundColor ? '2em' : '' }}>  
          {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />} 
          {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}   
          {
            references && (
              <section className="container-references">
                  <References
                      references={references}
                  />
                </section>
            )
          } 
          </section>
        </div>
        </section>
       
      </section>
    </div>
  );
};

WebinarListPage.propTypes = {
  content: contentItemProptype,
};



export { WebinarListPage };
