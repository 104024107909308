import React from 'react';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { contentItemProptype } from 'propTypes/contentProptypes';
import './VeevaPlayerPage.scss';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';

const VeevaPlayerPage = ({
  content,
}) => {

  const {
    title,
    embedCode,
  } = content ? content.elements : {};
  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  return (
    <section role="main" className="veeva-page-component">
      <MainAreaTitleSection
        title={title && title.value}
      />

      <div className="veeva-container">
        <div className="veeva-aspect-ratio">
          <div className="veeva-player" id="engage">
            <iframe 
              className="VeevaIframe" 
              src={`${process.env.PUBLIC_URL}/veeva-player.html?code=${embedCode && embedCode.value}`} 
              title="Veeva Player" 
              scrolling="no" 
              data-vv-snapshot='after'
              data-vv-waitAfter='5000'
            />
          </div>
        </div>
      </div>
      {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
    </section>
  );
};

VeevaPlayerPage.propTypes = {
  content: contentItemProptype,
};


export { VeevaPlayerPage };
