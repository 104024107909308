import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { checkCookie } from './CheckCookie';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { TARGETING_COOKIE } from 'components/layout/OneTrustEventInteraction';
import { setSfmcInitialized } from 'redux/ui/slice';

const useSfmc = () => {
  const [initialized, setInitialized] = useState(false);
  const { oneTrustConsents } = useSelector(
    (state) => state.ui
  );
  const SalesforceInteractions = window.SalesforceInteractions;
  const [consents, setConsents] = useState(SalesforceInteractions?.getConsents() || [])

  const [cookies, setCookie] = useCookies(['SfmcConsentUpdated', 'SfmcConsentInit']);
  const dispatch = useDispatch();

  const updateConsents = useCallback(() => {
    if (!cookies.SfmcConsentUpdated) {
      if (SalesforceInteractions) {
        if (Boolean(consents?.length)) {
          const isPerformanceAccepted = checkCookie(), isTargetingAccepted = checkCookie(TARGETING_COOKIE);
          const updated = [
            {
              provider: 'OneTrust',
              purpose: 'Performance',
              status: isPerformanceAccepted
              ? SalesforceInteractions.ConsentStatus.OptIn
              : SalesforceInteractions.ConsentStatus.OptOut,
            },
            {
              provider: 'OneTrust',
              purpose: 'Personalization',
              status: isTargetingAccepted
              ? SalesforceInteractions.ConsentStatus.OptIn
              : SalesforceInteractions.ConsentStatus.OptOut,
            }
          ]

          let hasChanged = updated.some((consent, index) => consent.status !== consents[index].status);

          if(hasChanged) {
            SalesforceInteractions.updateConsents(updated);
            setCookie('SfmcConsentUpdated', true, {
              maxAge: 365 * 86400,
            });
            setConsents(updated)
          }
        }
      }
    }
  }, [
    SalesforceInteractions,
    consents,
    cookies.SfmcConsentUpdated,
    setCookie
  ]);

  useEffect(() => {
    if (initialized) updateConsents();
  }, [
    initialized,
    updateConsents,
    oneTrustConsents
  ]);

  const init = useCallback(() => {
    if (
      SalesforceInteractions &&
      !Boolean(consents?.length) &&
      oneTrustConsents?.isTargetingAccepted
    ) {
      const isPerformanceAccepted = checkCookie(), isTargetingAccepted = checkCookie(TARGETING_COOKIE);
      const initialConsents = [
        {
          provider: 'OneTrust',
          purpose: 'Performance',
          status: isPerformanceAccepted
            ? SalesforceInteractions.ConsentStatus.OptIn
            : SalesforceInteractions.ConsentStatus.OptOut,
        },
        {
          provider: 'OneTrust',
          purpose: 'Personalization',
          status: isTargetingAccepted
          ? SalesforceInteractions.ConsentStatus.OptIn
          : SalesforceInteractions.ConsentStatus.OptOut,
        },
      ]
      SalesforceInteractions.init({
        consents: initialConsents
      });
      setInitialized(true);
      setConsents(initialConsents);
      dispatch(setSfmcInitialized(true))
    }
  }, [SalesforceInteractions, consents?.length, dispatch, oneTrustConsents]);

  return {
    init,
  };
};

export default useSfmc;
