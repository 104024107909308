import React from 'react'
import { contentItemProptype } from 'propTypes/contentProptypes'

// modules
import Logo from 'modules/logo/Logo'
import Hero from 'modules/hero/Hero'
import Header from 'modules/header/Header'
import Subnavigation from '../../modules/subNavigation/SubNavigation'
import Content from 'modules/content/Content'

// components
import { References } from 'components/content/References'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode'

// styles
import './CardListPage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'


/*
  Brand Content List Page
  Figma design here https://www.figma.com/file/iHhNCUBqTgthhUCuU6KCbx/KKI_Hub_Release2-3?node-id=315%3A112
*/

const CardListPage = ({ content }) => {
    const {      
        logo,
        brandColours,
        hero,
        title,
        header,
        subnavigation,
        content: contentSection,
        bannerImage,
        veevaApprovalCode,
        references,
    } = content.elements

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { primary, tertiary } = brandColours?.value?.elements || {}

    return (
        <section className='brand-content-list-page' role="main">
            <section className='brand-content-list-page__logo'>
                <Logo content={logo} />
            </section>
            <section className='brand-content-list-page__hero'>
                <Hero content={hero} />
            </section>
            <section className='brand-content-list-page__header'>
                <Header title={title} content={header} backgroundColour={primary} />
            </section>           
            <section className='brand-content-list-page__content-section'>
                <div className='container'>
                    <div className='brand-content-list-page__content-section-inner'>
                        <div className='brand-content-list-page__subnavigation'>
                            {subnavigation?.value &&
                                <Subnavigation element={subnavigation?.value} colour={primary?.value} /> 
                            }
                        </div>
                        <div className='brand-content-list-page__content'>                            
                            {contentSection?.values?.map((section, index) => (
                                <Content content={section} colour={tertiary} cardClass='VideoTitleCard' key={index} />
                            ))}                            
                        </div>
                    </div>
                </div>                
            </section>
            {bannerImage && <BannerImage image={bannerImage} />}
            <ApprovalCode code={veevaApprovalCode} />     
            {adverseEventsComplianceText?.value && (
                <section className='brand-content-list-page__references-section'>
                    <div className='container'>
                        <div className='brand-content-list-page__references'>
                            {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}      
                        </div>
                    </div>
                </section>
            )}    
            {references && (
                <section className='brand-content-list-page__references-section'>
                    <div className='container'>
                        <div className='brand-content-list-page__references'>
                            <References references={references} />
                        </div>
                    </div>
                </section>
            )}
        </section>
    )
}

CardListPage.propTypes = {
    content: contentItemProptype,
}

export default CardListPage
