import React from 'react';

export function contentPopup(status){
    let content = null;

    if(status === 'clinical-symptoms-popup'){
        content = (
            <ul>
                <li>Click on the numbers to find out more about the different phases in the progression of PD.</li>
                <li>Click on symptoms to retrieve more information on those.</li>
                <li>Click on the legend to help you sort your selections.</li>
            </ul>
        );
    } else if(status === 'treatment-fluctuations-popup'){
        content = (
            <p>Click on the boxes to learn more about the potential mechanisms in the pathophysiology of treatment-related fluctuations.</p>
        ); 
    } else if(status === 'dopaminergic-therapeutic-popup'){
        content = (
            <p>Click on the orange labels to find out more about the different states of ‘OFF’</p>
        );
    } else if(status === 'global-prevalence-popup'){
        content = (
            <p>Click on the numbers to find out more</p>
        );
    } else if(status === 'patients-early-onset-popup'){
        content = (
            <p>Click here to learn more</p>
        );
    } else if(status === 'brain-pathology-popup'){
        content = (
            <p>Click on the different brain areas to learn more about the PD pathology staging.</p>
        );
    } else if(status === 'cellular-processes-popup'){
        content = (
            <p>Select the different boxes describing key cellular processes involved in the pathogenesis of PD to learn more.</p>
        );
    } else if(status === 'cellular-processes-pd-popup'){
        content = (
            <p>Select the different cellular processes involved in the pathogenesis of PD you want to see in the image and learn more about those.</p>
        );
    } else if(status === 'biomarkers-popup'){
        content = (
            <p>Select the orange labels to learn more about potential biomarkers and techniques for diagnosis of PD.</p>
        );
    } else if(status === 'biomarkers-link-popup'){
        content = (
            <p>Select the potential biomarkers for diagnosis of PD to find out more.</p>
        );
    } else if(status === 'accordion-popup'){
        content = (
            <p>Click here to learn more</p>
        );
    } else if(status === 'mdt-popup'){
        content = (
            <p>Click on the MDT members to learn more about their roles and responsibilities in the management of Parkinson’s disease</p>
        );
    } else if(status === 'potential-reflection-popup'){
        content = (
            <p>Click on the boxes to learn more about mitigating the situation when MDTs are not available</p>
        );
    } 

    return content;
}

export default {
    contentPopup
}