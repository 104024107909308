import React, { useEffect } from "react";
import gsap from "gsap";

import styles from "./Switch.module.scss";

/**
 * Switch Component
 * @param {string} name - it's the name of the Switch - required
 * @param {string} label - it's the label of the Switch - required
 * @param {boolean} fullWidth -wether to make the switch take all the spaces available - optional
 * @param {boolean} disabled - wether the Switch is disabled or not - optional
 * @param {boolean} value - wether the Switch is true or false - optional
 * @param {string} showLabel - wether the label of the switch is true or false - optional
 * @param {Object} field - field object contains the value of the Switch and this parameter comes from Formik - optional
 * @return React component
 */

const Switch = (props) => {
  const { name, label, fullWidth, disabled, value, field } = props;

  //animation for the switch added on change
  useEffect(() => {
    const className = "." + styles["form-switch__input"];
    document.querySelectorAll(className).forEach((elem) => {
      let svg = elem.querySelector("svg"),
        input = elem.querySelector("input");

      input.addEventListener("change", () => {
        let checked = input.checked;
        let hide = checked ? "default" : "dot",
          show = checked ? "dot" : "default";
        gsap.fromTo(
          svg,
          {
            "--default-s": checked ? 1 : 0,
            "--default-x": checked ? "0px" : "25px",
            "--dot-s": checked ? 0 : 1,
            "--dot-x": checked ? "-25px" : "0px",
          },
          {
            ["--" + hide + "-s"]: 0,
            ["--" + hide + "-x"]: checked ? "20px" : "-20px",
            duration: 0.4,
            delay: 0.2,
          }
        );
        gsap.to(svg, {
          keyframes: [
            {
              ["--" + show + "-x"]: checked ? "2px" : "-2px",
              ["--" + show + "-s"]: 1,
              duration: 0.35,
            },
            {
              ["--" + show + "-x"]: "0px",
              duration: 0.3,
              clearProps: true,
            },
          ],
        });
      });
    });
  }, []);

  return (
    <div
      className={`${styles["form-switch"]} ${
        disabled ? styles["form-switch--disabled"] : ""
      }
      ${fullWidth ? styles["form-switch--full-width"] : ""}`}
    >
      <div className={styles["form-switch__label"]}>{label}</div>
      <label className={styles["form-switch__input"]}>
        <input
          aria-label={label}
          type="checkbox"
          name={name}
          disabled={disabled}
          checked={value}
          {...field}
        />
        <svg viewBox="0 0 38 24">
          <circle className={styles["default"]} cx="10" cy="10.1" r="8" />
          <circle className={styles["dot"]} cx="28" cy="10.1" r="8" />
        </svg>
      </label>
    </div>
  );
};

export default Switch;
