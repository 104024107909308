import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getAssetUrl, getImgAttr } from 'api/acousticApi'

import { Play } from 'components/shared/SvgIcons'

// modules
import MediaModal from 'modules/mediaModal/MediaModal'

// components
import BasicCard from '../basicCard/BasicCard'
import CardContent from '../../components/reusableComponents/card/components/cardContent/CardContent'
import CardHeading from '../../components/reusableComponents/card/components/cardHeading/CardHeading'
import CardRichText from '../../components/reusableComponents/card/components/cardRichText/CardRichText'
import Video from '../../components/reusableComponents/video/Video'

// styles
import './VideoCard.scss'


const VideoCard = ({ href, video, image, heading, subHeading,externalVideoLink, content, maxWidth, postDate, className, style, isFullWidth, webvttChaptersFile, subtitles, videoPoster, videoPosterImage, fileAsset }) => {
    const isRedesign = useSelector((state) => state?.ui?.redesign);
    const [showModal, setShowModal] = useState(false);
    const cardClass = className ? className : 'VideoCard'
    const cardContentClass = isFullWidth ? 'CardContent--Full-Width' : 'CardContent'
    const imageElement = getImgAttr(image)
    const videoUrl = getAssetUrl(video)

    return (
        <>
            <div onClick={(videoUrl || externalVideoLink?.value) && !isFullWidth && !href  ? () => setShowModal(true) : null}>
                <BasicCard href={href} className={cardClass} maxWidth={maxWidth} fileAsset={fileAsset}>
                    {(videoUrl || externalVideoLink?.value) && (
                        <div className={isFullWidth ? `${cardClass}_Video--Full-Width` : `${cardClass}_Video`}>
                            <Video element={video} externalVideo={externalVideoLink}  videoPoster={videoPoster} videoPosterImage={videoPosterImage} webvttChaptersFile={isFullWidth ? webvttChaptersFile : null} subtitles={subtitles} showControls={isFullWidth ? true : false} disablePlay />
                            <Play className='shadow' />
                        </div>
                    )}
                    {imageElement.src && (
                        <div className={`${cardClass}_Image`}>
                            <img src={imageElement?.src} alt={imageElement?.alt} />
                            <Play className='shadow' />
                        </div>
                    )}
                    <CardContent style={{
                        ...style,
                        color: isRedesign ? style?.color && style?.color !== "" ? style?.color : '#333' : style?.color && style?.color !== "" ? style?.color : '#FFFFFF'
                    }} className={cardContentClass}>
                        {heading && <div><CardHeading heading={heading} /></div>}
                        {subHeading && <p className={`${cardClass}_SubHeading`}>{subHeading}</p>}
                        {content && <CardRichText content={content} truncate />}
                        {postDate && <p className={`${cardClass}_PostDate`}>{dayjs(postDate).format('D MMM YYYY')}</p>}
                    </CardContent>
                </BasicCard>
                

            </div>
            <MediaModal
                showModal={showModal}
                video={video}
                subtitles={subtitles}
                videoPoster={videoPoster}
                videoPosterImage={videoPosterImage}
                onClose={() => setShowModal(false)}
                externalVideo={externalVideoLink}
            />
        </>
    )
}

export default VideoCard
