import React from 'react';
import { getWebinarDateWithoutOrdinal } from 'components/pageTemplates/webinar/webinarHelpers';
// styles
import './cardDate.scss';

const CardDate = ({ date }) => {
  if (!date) return null;

  if (date) {
    const fullDate = getWebinarDateWithoutOrdinal(date);
    return <div className='CardDate'>{fullDate}</div>;
  }
};

export default CardDate;
