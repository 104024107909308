import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { useAuthServiceCMSPage } from 'utils/authUtils';
import { acceptPromotionalConsent } from 'redux/authentication/slice';
import {
  useWebsiteConfigObject,
} from 'websiteConfig/useWebsiteConfig';
import ChatBot from 'components/reusableComponents/chatbot/ChatBot';
import { Features } from 'components/shared/feature/Features';
import {
  CHATBOT_COMPONENT,
  GIOSG,
  GRAPHICAL_BANNER,
  RATING_COMPONENT,
  SFMC,
  TEALIUM,
} from 'api/acousticApi/ContentTypesFeatures';
import { ContentSentimentRatings } from 'components/layout/ContentSentimentRating/ContentSentimentRatings';
import { GetTaxonomyGoogleAnalytics, extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics';
import { Tealium } from 'components/shared/tealium/tealium';
import { checkCookie } from '../../../hooks/CheckCookie';
import UtmRegistration from 'components/shared/utmRegistration/UtmRegistration';
import { setMobileNavigation } from 'redux/ui/slice';
import NoticeBar from 'components/layout/header/components/noticeBar/NoticeBar';
import Giosg from 'components/shared/giosg/Giosg';
import Sfmc from 'components/shared/sfmc/Sfmc';
import GTag from 'components/shared/ga/gtag';
import OneTrustEventInteraction from 'components/layout/OneTrustEventInteraction';
import useUtmUnlock, { ExpiredCampaignModal } from 'utmCampaign/useUtmUnlock';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const CMSPage = (props) => {
  const dispatch = useDispatch();
  const CMSPageDisplay = useAuthServiceCMSPage();
  const { contentId, templateType, path } = props || {};

  const { content, isLoading, error } = usePageContent(contentId);
  const {
    globalPromotionalContent,
    cookieBannerVersion,
    giosgScriptUrl,
    giosgEnvironment,
    giosgDomainId,
    sfmcDataset
  } = useWebsiteConfigObject();
  let cookieVersionValue = cookieBannerVersion?.selection;

  const [cookies, setCookie] = useCookies(['analyticsCookiesAccepted']);
  const { oneTrustAccepted, graphicalBanners } = useSelector((state) => state.ui);
  const { trackUtmCampaign } = useGoogleAnalytics();
  const { status } = useSelector((state) => state.authentication);
  const promotionalContent = !globalPromotionalContent
    ? false
    : content?.elements?.promotionalContent?.value;

  const { taxonomyFields } = useSelector((state) => state.ga);
  const taxonomy = extractTaxonomyValues(taxonomyFields, content?.elements?.taxonomy?.value);

  useEffect(() => {
    document.body.setAttribute('data-page-id', contentId);
  }, [contentId]);

  useEffect(() => {
    if (content && !promotionalContent) {
      dispatch(acceptPromotionalConsent(false));
    }
  }, [content, promotionalContent, dispatch]);

  const isChatbotDisable = content?.elements?.basePage?.value?.disableChatbot
    ?.value
    ? true
    : false;

  let isSentimentRatingEnable;
  const getCookie = checkCookie();
  if(cookieVersionValue === "OneTrust" ) {
    isSentimentRatingEnable= getCookie;

  }
  else{
    isSentimentRatingEnable =
    cookies?.analyticsCookiesAccepted === 'false' ||
    content?.elements?.basePage?.value?.disableContentSentiment?.value === true
      ? false
      : true;
  }
  useEffect(() => {
    if(oneTrustAccepted){
      isSentimentRatingEnable= getCookie;
    }
  }, [oneTrustAccepted]);
  useEffect(() => {
    if (content) {
      dispatch(setMobileNavigation(content?.elements?.subnavigation?.value));
    }
  }, [content]);

  const { utmState, expiredMessage } = useUtmUnlock();

  useEffect(() => {
    if(utmState !== 'loading' && utmState !== 'invalid' ) {
      if(utmState === 'expired') {  
        trackUtmCampaign("utm_campaign_expired", path); 
      } else {
        trackUtmCampaign("utm_campaign_page_view", path, taxonomy); 
      }
    }
  }, [utmState])
  

  if (isLoading || utmState === 'loading') {
    return (
      <section role='main' className='loading-page'>
        <Loader className='center-aligned-loader' size={LARGE_LOADER} />
      </section>
    );
  }

  if (error) {
    console.error(error); // todo add an error page https://ocasta.atlassian.net/browse/RKK-62
  }

  return (
    <>
      <OneTrustEventInteraction content={content} />
      <Features contentType={GRAPHICAL_BANNER}>
        <section className='banner-section'>
          {graphicalBanners?.map((element) => {
            const businessUnit = element?.elements?.businessUnit?.categories?.map(item => item?.substring(item?.lastIndexOf('/')+1));
            const pageTaxonomy = content?.elements?.taxonomy?.value?.message?.value?.businessUnit?.categories;
            const templateExclusionList =
              element?.elements?.templateExclusionList?.values?.map(item => item?.replace(/\s+/g, '').toLowerCase());

            let showBanner =
              !businessUnit ||
              (Boolean(
                pageTaxonomy
              ) &&
                businessUnit.includes(
                  pageTaxonomy[0]?.substring(pageTaxonomy[0]?.lastIndexOf('/')+1)
                ));

            showBanner =
              showBanner && !templateExclusionList?.includes(templateType?.replace(/\s+/g, '').toLowerCase());

            return showBanner ? <NoticeBar element={element} /> : null;
          })}
        </section>
      </Features>
      <CMSPageDisplay
        promotional={promotionalContent}
        content={content}
        templateType={templateType}
        path={path}
        utmState={utmState}
      />
      <Features pageName={templateType} contentType={RATING_COMPONENT}>
        <ContentSentimentRatings isSentimentRatingEnable={isSentimentRatingEnable}/>
      </Features>
      <Features pageName={templateType} contentType={CHATBOT_COMPONENT}>
        {!isChatbotDisable && (status?.guest || status?.loggedIn ) ? (
          <ChatBot basePage={content?.elements?.basePage} />
        ) : (
          ''
        )}
      </Features>
      <GetTaxonomyGoogleAnalytics content={content} />

      <Features pageName={templateType} contentType={TEALIUM}>
        <Tealium content={content} />
      </Features>
      <Features pageName={templateType} contentType={GIOSG}>
        <Giosg
          id={giosgDomainId}
          environment={giosgEnvironment}
          url={giosgScriptUrl}
        />
      </Features>
      <UtmRegistration />
      { utmState === 'expired' && expiredMessage && <ExpiredCampaignModal content={expiredMessage}/> }
    </>
  );
};

export { CMSPage };
