import PropTypes from 'prop-types';

const contentElementProptype = PropTypes.shape({
  value: PropTypes.any,
  // might neet type as well
  elementType: PropTypes.string,
});

const contentItemProptype = PropTypes.shape({
  id: PropTypes.string,
  elements: PropTypes.objectOf(contentElementProptype),
  // lots of other things, add them as we need them
});


export {
  contentElementProptype,
  contentItemProptype,
};
