import { useContext, useEffect, useRef } from 'react';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { getUrlCountryData } from 'utils/libraryUtils';
import { useSelector } from 'react-redux';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import {checkCookie} from './CheckCookie';
import { TARGETING_COOKIE } from 'components/layout/OneTrustEventInteraction';

const useGoogleAnalytics = () => {
    const { 
        cookieBannerVersion,
        oneTrustAnalyticsGroupId
    } = useWebsiteConfigObject();
    let cookieVersionValue = cookieBannerVersion?.selection;
    const cookieAcceptance= useContext(cookieConfirmationContext) ;
    let analyticsCookiesAccepted;
   
    // validate cookie for OptanonConsent
    const getCookie = checkCookie(oneTrustAnalyticsGroupId) || checkCookie(TARGETING_COOKIE);
    if(cookieVersionValue === "OneTrust" ) {
        analyticsCookiesAccepted= getCookie;
    }
    else{
        analyticsCookiesAccepted = cookieAcceptance?.analyticsCookiesAccepted;
    }

    const { gaMeasurementId, gtmMeasurementId, code,isoCode } = getUrlCountryData();
    const { status, data } = useSelector((state) => state.authentication);
    const { loggedIn } = status || {};
    const { userId } = data.user || {};
    if(!window.lastUserStatus) window.lastUserStatus = loggedIn;

    useEffect(() => {

        // switchConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);
    const updateDataLayer = (parameters) => {
        let dataLayer = window.dataLayer || [];
        if(!analyticsCookiesAccepted) return;
        
        const eventData = { ...parameters, 'user_id': userId };
        if(!userId) delete eventData['user_id']

        // if(!dataLayer[0].event || dataLayer[0].event !== 'gtm.js') {
        //     dataLayer.unshift({
        //         'gtm.start': new Date().getTime(),
        //         event:'gtm.js'
        //     })
        // }

        dataLayer.push(eventData);
    }
    window.gtag = (command, action, params) => {
        if(command === 'event') {
            const eventData = Object.assign({ 'event': action, 'event_name': action }, params)
            updateDataLayer(eventData)
        }
    }
    const switchConfig = () => {
        const switchAllowed = window.lastUserStatus !== loggedIn;

        if(switchAllowed) {
            if (analyticsCookiesAccepted && typeof window !== 'undefined') {
                window.gaMeasurementId = null;
                if(loggedIn) {
                    initialiseAnalytics({ method: 'denied' });
                }
                else { initialiseAnalytics(); }
                window.lastUserStatus = loggedIn;
            }
        }
    }

    const getCountryCode=() =>{
        let extractedLocale = code.split('-');
        if(extractedLocale.length > 1)
        {
            return extractedLocale[1].toUpperCase();
        }
        else
        {
            return extractedLocale[0].toUpperCase();
        }
    };

    const disableAnalytics = () => {
        window[`ga-disable-${gaMeasurementId}`] = true;
    }
    const setCookie = (cName, cValue, expDays) => {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; domain=.kyowakirinhub.com ; path=/;";
    }
    const getCookieJs = (cName) => {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = cDecoded .split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res;
    }
    const initialiseAnalytics = (parameters = {}) => {
        if (typeof window !== 'undefined') {
            // const cookieExpiryInSeconds = 420 * 24 * 60 * 60;
            const currentMeasurementId = window.gtmMeasurementId;

            if(currentMeasurementId && currentMeasurementId === gtmMeasurementId) { 
                return null;
            }

            window.gtmMeasurementId = gtmMeasurementId;

            // let sendPageView = true;
            // if(parameters?.method) {
            //     sendPageView = parameters.method !== 'denied';
            //     delete parameters.method;
            // }
            if(window.dataLayer){
                if(analyticsCookiesAccepted) {
                    updateDataLayer(
                        {
                            'gtm.start': new Date().getTime(),
                            event:'gtm.js'
                        }
                    )
                }
            }
            // window.gtag('js', new Date());
            // if (loggedIn) {
            //     window.gtag('config', gaMeasurementId, {
            //         //"debug_mode": true,
            //         'cookie_expires': cookieExpiryInSeconds,
            //         send_page_view: false,
            //         anonymize_ip: true,
            //         user_id: userId,
            //         country: getCountryCode(),
            //         ...parameters,
            //         'custom_map': {
            //             'dimension1': 'custom_country_id'
            //         }
            //     });
            // } else {
            //     window.gtag('config', gaMeasurementId, {
            //         //"debug_mode": true,
            //         'cookie_expires': cookieExpiryInSeconds,
            //         send_page_view: false,
            //         anonymize_ip: true,
            //         country: getCountryCode(),
            //         ...parameters,
            //         'custom_map': {
            //             'dimension1': 'custom_country_id'
            //         }
            //     });
            // }
            // let _ga = getCookieJs("_ga");
            // if(_ga){
            //     setCookie('_ga', _ga, 420);
            // }
        }
    };
    const trackGTMEvent = (event, content) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            updateDataLayer(
                {
                    event: event,
                    event_name: event,
                    content_type: content,
                    country_code: getCountryCode(),
                    country_code_id: getCountryCode(),
                    page_path: window.location.pathname
                }
            )
        }
    }
    const trackGTMEventWithItemId = (event, content, itemId, contentTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            updateDataLayer(
                {
                    event: event,
                    event_name: event,
                    content_type: content,
                    country_code: getCountryCode(),
                    country_code_id: getCountryCode(),
                    page_path: window.location.pathname,
                    item_id: itemId,
                    content_title: contentTitle
                }
            )
        }
    }
    const trackUtmCampaign = (event, path, taxonomy) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            updateDataLayer(
                {
                    event: event,
                    event_name: event,
                    country_code: getCountryCode(),
                    country_code_id: getCountryCode(),
                    page_path: window.location.pathname,
                    qs: window.location.search,
                    item_id: document.body.getAttribute('data-page-id') || path,
                    status: '200',
                    ...taxonomy
                }
            )
        }
    }
    const trackAudio = (event, audio, title, dragStartTime) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            const { currentTime, duration, src } = audio;
            let gtmEvent = {
                event: event,
                event_name: event,
                audio_current_time: currentTime,
                audio_duration: duration,
                audio_percent: Math.round((currentTime / duration) * 100),
                audio_title: title,
                audio_url: src,
                country_code: getCountryCode(),
                country_code_id: getCountryCode(),
                page_path: window.location.pathname,
            }

            if(dragStartTime) { 
                gtmEvent['audio_current_time'] = dragStartTime;
                gtmEvent['audio_drag_end'] = currentTime;
            }

            updateDataLayer(gtmEvent);
        }
    }
    const trackPageView = (path, title, overrideCookies = false, taxonomy) => {

        setTimeout(() => {
            let contentGates = document.querySelectorAll(".content-gate"); 
            let paywalls = document.querySelectorAll(".paywall__overlay"); 
            let promoPopIn = document.querySelectorAll(".ContentGate_Wrapper"); 
            let marketOptin = document.querySelectorAll(".content-gate-market"); 
            let anonymousGate = document.querySelectorAll(".content-gate-anonymous"); 
            if(contentGates.length === 0 && paywalls.length === 0 && promoPopIn.length === 0 && marketOptin.length === 0 && anonymousGate.length === 0){
                if (analyticsCookiesAccepted || overrideCookies) {
                    // initialiseAnalytics();
                    if (typeof window !== 'undefined') {
                        console.log('track page_view')
                        window.gtag('event', 'page_view', {
                            page_title: title,
                            page_path: path,
                            item_id: document.body.getAttribute('data-page-id') || path,
                            send_to: gaMeasurementId,
                            country_code: getCountryCode(),
                            'custom_country_id': getCountryCode(),
                            status: '200',
                            ...taxonomy
                        });
                    }
                }
            } else {
                if (analyticsCookiesAccepted || overrideCookies) {
                    // initialiseAnalytics();
                    if (typeof window !== 'undefined') {
                        window.gtag ('event', 'paywall_view', {
                            content_title: title,    
                            country_code:getCountryCode(),
                        })
                    }
                }
            }
        },300);
    };

    const trackEventWithMethod = (action, method) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            window.gtag('event', action, { 
                method, 
                country_code: getCountryCode(), 
                'custom_country_id': getCountryCode(), 
                'page_location': window.location.origin + window.location.pathname
            });
        }
    };
    const trackSelfCertRegistrationFlows = (event, action, userId) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', event, {
                action, 
                pre_registration_uid: userId,
                country_code: getCountryCode(), 
                'custom_country_id': getCountryCode(), 
                'page_location': window.location.origin + window.location.pathname
            });
        }
    };
    const trackEventCookies = (path, title, method, taxonomies) => {
        window.gtag('event', 'click', {
            analytics_storage : method,
            country_code: getCountryCode(),
            'custom_country_id': getCountryCode(),
        });
        if(method === 'granted') {
            // initialiseAnalytics();
            /**
             * commented gateway check sa ga normally raises the page_view even if it encounters a gateway
             */
            let contentGates = document.querySelectorAll(".content-gate"); 
            let paywalls = document.querySelectorAll(".paywall__overlay"); 
            let promoPopIn = document.querySelectorAll(".ContentGate_Wrapper"); 
            let marketOptin = document.querySelectorAll(".content-gate-market"); 
            let anonymousGate = document.querySelectorAll(".content-gate-anonymous"); 
            if(contentGates.length === 0 && paywalls.length === 0 && promoPopIn.length === 0 && marketOptin.length === 0 && anonymousGate.length === 0){
                window.gtag('event', 'page_view', {
                    page_title: title,
                    page_path: path,
                    send_to: gaMeasurementId,
                    item_id: document.body.getAttribute('data-page-id') || path,
                    country_code: getCountryCode(),
                    'custom_country_id': getCountryCode(),
                    ...taxonomies
                });
            } else {
                window.gtag ('event', 'paywall_view', {
                    content_title: title,    
                    country_code:getCountryCode()
                })
            }
        }
        if(method === 'denied') {
            // setTimeout(() => {
                disableAnalytics();
            // }, 10000);
        }
    };

    const trackScrollEvent = (value) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'custom_scroll', { percentage_scrolled: value, country_code: getCountryCode(), 'custom_country_id': getCountryCode(), });
        }
    };

    const trackEventWithContentType = (contentType, itemId) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', { content_type: contentType, item_id: itemId, country_code: getCountryCode(), 'custom_country_id': getCountryCode(), page_path: window.location.pathname});
        }
    };
    const trackEventWithContentTypeSource = (contentType, itemId,source) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', { content_type: contentType, token: itemId, source_frame:source, send_to: gaMeasurementId });
        }
      };

    const trackVideo = (action, video, percentage) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            const track = video.querySelector('track');
            const hasChapters = track && track.readyState === 2 && track.kind === 'chapters';

            if(hasChapters) {
                const cues = track.track.cues;
                let currentChapter = percentage === '100' ?  cues[cues.length - 1].text : cues[0].text;
                if(Boolean(track.track.activeCues.length)) currentChapter = track.track.activeCues[0].text;

                trackVideoChapter(action, video, currentChapter, null, percentage);
            }
            else {
                // initialiseAnalytics();
                window.gtag('event', action, {
                    video_current_time: video.currentTime,
                    video_duration: video.duration,
                    video_percent: percentage,
                    video_provider: 'HTML5',
                    video_title: video.title,
                    video_url: video.id, // JIRA KKICH-508: pass the Acoustic Id here instead of the full delivery URL
                    visible: true,
                    country_code: getCountryCode(),
                    'custom_country_id': getCountryCode(),
                    page_path: window.location.pathname
                });
            }
        }
    };

    const trackVideoChapter = (action, video, currentChapter, selectedChapter, percentage) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', action, {
                video_title: video.title,
                current_chapter_name: currentChapter,
                selected_chapter_name: selectedChapter,
                video_current_time: video.currentTime,
                video_percent: percentage,
                video_duration: video.duration,
                country_code: getCountryCode(),
                video_provider: 'HTML5',
                video_url: video.id, // JIRA KKICH-508: pass the Acoustic Id here instead of the full delivery URL
                'custom_country_id': getCountryCode(),
                page_path: window.location.pathname
            });
        }
    };

    const trackOutboundLink = (url) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'click', {
                event_category: 'outbound',
                event_label: url,
                transport_type: 'beacon',
                country_code: getCountryCode(),
                'custom_country_id': getCountryCode(),
            });
        }
    };

    const trackSearchTerm = (searchTerm) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'view_search_results', {
                search_term: searchTerm,
                country_code: getCountryCode(),
                'custom_country_id': getCountryCode(),
            });
        }
    };
    const trackRegisterOption = (method)=>{
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', {
                content_type:'button',
                item_id : method,
                country_code: getCountryCode(),
            });
        }
    }
    const trackPageTaxonomies = (values) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'page_taxonomy', {
                ...values,
                country_code: getCountryCode(), 
                date:new Date().toString()
            });
        }
    }
    const trackTaxonomy = (taxonomykeyword, value) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'page_taxonomy',{ 
                taxonomy_keyword: `${taxonomykeyword}/${value}`, 
                country_code: getCountryCode(), 
                date:new Date().toString()
            });
        }
    };
    const trackCourses = (name, id) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content',{ 
                content_type:'button',
                course_title: name,
                item_id: 'course-continue-button', 
                country_code: getCountryCode(), 
                user_id:id,
            });
        }
    };
    const trackContinueCourses = (title, id) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content',{ 
                content_type:'button',
                item_id: 'course-continue-button',
                course_title : title,
                country_code: getCountryCode(), 
                user_id:id,
            });
        }
    };
    const trackWebinarPromoToggle = (isToggled) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', { 
                user_id: userId || 0,
                country_code:getCountryCode(),    
                item_id: isToggled ? 'on' : 'off',
                content_type: 'toggle'
            });
        }
    };
    const trackWebinarRegistration = (userId,webinarId,webinarTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'webinar_registrations', { 
                user_id: userId,
                webinar_id: webinarId,
                country_code:getCountryCode(),    
                webinar_title: webinarTitle,
                registration_count: 1
            });
        }
    };
    const trackJoinWebinarButton = (userId,webinarId,webinarTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'join_webinar', { 
                user_id: userId,
                webinar_id: webinarId,
                country_code:getCountryCode(),    
                webinar_title: webinarTitle,
                joinwebinar_count: 1
            });
        }
    };
    const trackAddwebinarToCalender = (userId,webinarTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'add_to_calendar', { 
                user_id: userId,
                topic_type: 'webinar',
                country_code: getCountryCode(),    
                topic_title: webinarTitle,
                topic_count: 1
            });
        }
    };
    const trackWebinarButton = (userId,webinarId) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', { 
                content_type: 'webinar-button',
                item_id: webinarId,
                country_code:getCountryCode(),    
                user_id: userId           
            });
        }
    };

    const trackFeatureWebinarButton = (userId, webinarTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', { 
                content_type: 'button',
                item_id: 'webinar-button-signup',
                webinar_title: webinarTitle,
                country_code:getCountryCode(),    
                user_id: userId           
            });
        }
    };

    const trackWebinarCardButton = (userId, webinarTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', { 
                content_type: 'button',
                item_id: 'webinar-button-learnmore',
                webinar_title: webinarTitle,
                country_code:getCountryCode(),    
                user_id: userId           
            });
        }
    };

    const trackRequestContent = () => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', {
                content_type: 'eapc-request-button',
                item_id: 'eapc 2021 request content',       
            });  
        }    
    }
    const trackContentRating = (rate) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag ('event', 'feedback', {
                selected_rating: rate, //<user selected option starts 1,2,3,4,5>
                rating_view: 1 // send value as  1       
            });
        }
    }
    const trackEapcRegistration = (id,title) =>{ 
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag ('event', 'eapc_registration', {
                eapc_registration_id: id,
                requested_content: title,     
            });
        }  
    }
    const trackPDFDownload = (filename, userId) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag ('event', 'select_content', {
                pdf_filename: filename,
                user_id: userId || 0,
                country_code:getCountryCode(),
                pdf_download_count: 1,
            });
        }
    }
    const trackPayall = (title,userId) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            if(userId){
                window.gtag ('event', 'paywall_view', {
                    content_title: title,    
                    country_code:getCountryCode(),
                    user_id: userId
                });
            } else {
                window.gtag ('event', 'paywall_view', {
                    content_title: title,    
                    country_code:getCountryCode()
                });
            }
        }
    };
    const trackUpdateProfileAnonymous = (contentTitle) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics(contentTitle);
            window.gtag('event', 'sign_up', {
                content_title :"",  
                method: 'paywall',    
                country_code:getCountryCode(),   
            });
        }
    };
    const trackSignUpAnonymous = () => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'sign_up', {
                method: 'paywall-min',    
                country_code:getCountryCode(),   
            });
        }
    };
    const trackGoBackAnonymous = (contentTitle, callback) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag ('event', 'select_content', {
                content_title :contentTitle ,
                'event_callback': callback,
                content_type: 'button',
                item_id: 'paywall-back',
                country_code:getCountryCode(),    
            });
        }
    };
    const trackRemoveAccount = (userId) =>{
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', 'select_content', {
                content_type : 'button', 
                item_id: 'remove-account-button',    
                country_code:getCountryCode(),   
                user_id : userId
            });
        }
    }
    const trackQuizAction = (userId, action, extras) => {
        if (analyticsCookiesAccepted && typeof window !== 'undefined') {
            // initialiseAnalytics();
            window.gtag('event', action, {
                // item_id: 'remove-account-button',    
                country_code:getCountryCode(),   
                user_id : userId,
                page_path: window.location.pathname,
                send_to: gaMeasurementId,
                'custom_country_id': getCountryCode(),
                ...extras
            });
        }
    }

    return (
        {
            initialiseAnalytics,
            trackPageView,
            trackEventWithMethod,
            trackEventWithContentType,
            trackScrollEvent,
            trackVideo,
            trackVideoChapter,
            trackOutboundLink,
            trackSearchTerm,
            trackEventWithContentTypeSource,
            trackEventCookies,
            trackRegisterOption,
            trackTaxonomy,
            trackPageTaxonomies,
            trackWebinarRegistration,
            trackJoinWebinarButton,
            trackWebinarButton,
            trackFeatureWebinarButton,
            trackWebinarCardButton,
            trackRequestContent,
            trackEapcRegistration,
            trackContentRating,
            trackAddwebinarToCalender,
            trackCourses,
            trackContinueCourses,
            trackPayall,
            trackUpdateProfileAnonymous,
            trackSignUpAnonymous,
            trackGoBackAnonymous,
            trackRemoveAccount,
            trackPDFDownload,
            trackWebinarPromoToggle,
            trackGTMEvent,
            trackSelfCertRegistrationFlows,
            trackGTMEventWithItemId,
            trackAudio,
            trackUtmCampaign,
            trackQuizAction,
        }
    );
};

export {
    useGoogleAnalytics,
};
