import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { any } from 'prop-types';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { LinksIcon, PointIcon } from 'components/shared/SvgIcons';
import { stringToCssId } from 'utils/stringUtils';
import Link from 'components/reusableComponents/link/Link';
import './ArticleContentsList.scss';
import {
  SCREEN_WIDTH_MOBILE,
  useScreenWidth,
} from 'components/layout/ScreenWidthContext';
import { OpenClosedIndicator } from 'components/shared/OpenClosedIndicator';
import RichText from 'components/reusableComponents/richText/RichText';

// component for the content box for an article
const DynamicArticleContentsList = ({ elements, containerRef }) => {
  const { tContents } = useWebsiteConfigObject();
  const { currentWidthRanges } = useScreenWidth();
  const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);
  const [collapsed, setCollapsed] = useState(false);
  const [isCollapsible, setIsCollapsible] = useState(false);
  const [menuTitle, setMenuTitle] = useState(tContents);
  const [isSticky, setIsSticky] = useState(false);
  const stickyMenu = useRef();

  const setCurrentActive = () => {
    const chapters = document.getElementsByClassName("article-content-list-container");
    if(chapters.length > 0 && chapters[0] && elements[0] && elements.length > 0) {
      let activeChap = "";
      elements.forEach((element, index) => {
        const title = element?.elements?.title?.value || element?.elements?.titleRichText?.value || null;
        if (!title) {
          return null;
        }
        const id = stringToCssId(title);
        const chapterDiv = document.getElementById(id);
        const rect = chapterDiv.getBoundingClientRect();
        if(rect.y < 10 && !isMobile){
          activeChap = title;
        }
        if(rect.y < 100 && isMobile){
          activeChap = title;
        }
        if(rect.y < 150 && isMobile && activeChap === ""){
          activeChap = title;
        }
      })
      if(activeChap !== "") {
        setMenuTitle(activeChap);
      } else {
        setMenuTitle(tContents);
      }
    }
  }
  useEffect(() => {
    window.addEventListener(
      "scroll",
      setCurrentActive
    );
    const title = elements[0]?.elements?.title?.value || elements[0]?.elements?.titleRichText?.value || null;
    const id = stringToCssId(title);
    const sectionElement = document.getElementById(id);
    if(sectionElement) {
      if(stickyMenu?.current) { 
        sectionElement.before(stickyMenu?.current)
      }
    }
    if(!isMobile){
      setCollapsed(false)
      if (stickyMenu?.current) {
        containerRef?.current?.appendChild(stickyMenu?.current);
      }
    }
    return () => {
        window.removeEventListener(
        "scroll",
        setCurrentActive
      );
    }
  },[isMobile]);

  useEffect(() => {
    if(isMobile){
      if(menuTitle !== "" && menuTitle !== tContents) {
        setCollapsed(true);
        setIsSticky(true);
        setIsCollapsible(true)
      } else if (menuTitle === tContents) {
        setCollapsed(false);
        setIsSticky(false);
        setIsCollapsible(false)
      }
    }
  },[menuTitle])

  const toggleMenu = () => {
    if(isMobile && isCollapsible){
      setCollapsed(!collapsed);
    }
  }

  const clickOnAnchor = (e, id) => {
    if(isMobile && id){
      e.preventDefault();
      const chapterDiv = document.getElementById(id);
      const pos = chapterDiv.offsetTop - 300;
      window.scrollTo(0, pos);
    }
  }

  return (
    <div 
      ref={stickyMenu}
      className={`article-content-list-container-wrapper ${(isMobile && isSticky) ? 'article-content-list-container-wrapper--sticky' : ''}`}
    >
      <aside
        className="article-content-list-container"
        onClick={() => toggleMenu()}
      >
        <h4 className='article-content-list-title'>
          {(isMobile && menuTitle !== tContents) ? <PointIcon /> : <LinksIcon />}
          <span><RichText data={{ value : isMobile ? menuTitle : tContents }} /></span>
          {(isMobile && menuTitle !== tContents) && <OpenClosedIndicator open={collapsed} />}
        </h4>
        <ul
          className={`contents article-content-list article-content-list${ (isMobile &&
            collapsed) ? '--collapsed' : ''
          }`}
        >
          {elements.map((element, index) => {
            const titleElement = element.elements.title;
            const title = titleElement ? titleElement.value : null;
            if (!title) {
              return null;
            }
            const id = stringToCssId(title);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li
                key={`${id}-${index}`}
                className={`${
                  menuTitle === title
                    ? 'selected'
                    : ''
                }`}
              >
                <>
                  {(menuTitle !== tContents && menuTitle === title) && <PointIcon />}
                  <Link onClick={(e) => clickOnAnchor(e, id)} href={`#${id}`}><RichText data={{ value: title }} /></Link>
                </>
              </li>
            );
          })}
        </ul>
      </aside>
    </div>
  );
};

DynamicArticleContentsList.propTypes = {
  elements: PropTypes.arrayOf(any),
};

export { DynamicArticleContentsList };
