import React from 'react';
import './Logo.scss';

import { useDispatch, useSelector } from 'react-redux';
import { closeMobileNav } from 'redux/ui/slice';
import useResponsive from 'hooks/useResponsive';
import Link from 'components/reusableComponents/link/Link';
import kkLogo from 'assets/images/kki_logo_orange_new.png';
import kkLogoOrangeHub from 'assets/images/KK_logo_orange_hub.png';
import kkLogoHighRes from 'assets/images/kki_logo_high_res.png';
import kkLogoWhite from 'assets/images/KKI_Logo.png';
import EventsLogo from 'assets/images/Events.png';

const Logo = ({library}) => {
  const { isMobileOnly } = useResponsive();
  const { redesign } = useSelector(state => state.ui);

  const dispatch = useDispatch();

  const closeNav = () => {
    dispatch(closeMobileNav());
  };

let logoimg = kkLogoOrangeHub;
if(library){
  logoimg = kkLogo;
}
  if(redesign || library === ""){
    return (
      <>
      <div className="header-logos">
        <Link href='/' onClick={closeNav} className={`Logo${library === "events" ? " logo-events" : ""}`}>
          <img
            src={kkLogoHighRes}
            width={isMobileOnly ? '162' : '206'}
            alt='KYOWA KIRIN HUB'
          />
        </Link>
      </div>
      </>
    );
  }
  return (
    <>
    <div className="header-logos">
    {library !== "events" && 
      <Link href='/' onClick={closeNav} className={`Logo${library === "events" ? " logo-events" : ""}`}>
        <img
          src={kkLogoWhite}
          width={isMobileOnly ? '162' : '206'}
          height={isMobileOnly ? '21' : '27'}
          alt='KYOWA KIRIN HUB'
        />
      </Link>
    }
    {library === "events" && 
      <>
        <Link href='#' onClick={closeNav} className={`Logo${library === "events" ? " logo-events" : ""}`}>
          <img
            src={kkLogoWhite}
            width={isMobileOnly ? '162' : '206'}
            height={isMobileOnly ? '21' : '27'}
            alt='KYOWA KIRIN HUB'
          />
        </Link>
        <Link href='#' onClick={closeNav} className='Logo events'>
          <img
            src={EventsLogo}
            width={isMobileOnly ? '60' : '77'}
            height={isMobileOnly ? '13' : '15'}
            alt='EVNTS'
          />
        </Link>
      </>
    }
    </div>
    </>
  );
};

export default Logo;
