import { createSlice } from '@reduxjs/toolkit'
import { clearPreviousAnswers } from '../patterns/quiz/AnswerStorage'

const initialState = {
  quizData: {
    answer: {},
    startQuiz: false,
    questionClosed: 1,
    quizId: null,
    content: {},
    result: null
  }
}

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    quizData: (state, action) => {
      state.quizData = action.payload
    },
    answer: (state, action) => {
      state.quizData.answer = action.payload
    },
    startQuiz: (state, action) => {
      state.quizData.startQuiz = action.payload
    },
    questionClosed: (state, action) => {
      state.quizData.questionClosed = action.payload
    },
    quizId: (state, action) => {
      state.quizData.quizId = action.payload
    },
    quizPDF: (state, action) => {
      state.quizData.quizPDF = action.payload
    },
    quizContent: (state, action) => {
      state.quizData.content = action.payload
    },
    result: (state, action) => {
      state.quizData.result = action.payload
    }
  }
})

//Export Actions
export const { answer, startQuiz, questionClosed, quizId, quizPDF, quizContent, result } = quizSlice.actions

export const setStartQuiz = (value) => (dispatch) => {
  if(value === true) clearPreviousAnswers();
  dispatch(startQuiz(value))
}

export const setQuestionClosed = (value) => (dispatch) => {
  dispatch(questionClosed(value))
}

export const setAnswer = value => (dispatch) => {
  dispatch(answer(value))
}

export const setQuizId = value => (dispatch) => {
  dispatch(quizId(value))
}

export const setQuizPDF = value => (dispatch) => {
  dispatch(quizPDF(value))
}

export const setQuizContent = value => (dispatch) => {
  dispatch(quizContent(value))
}

export const setResult = value => (dispatch) => {
  dispatch(result(value))
}

export default quizSlice.reducer