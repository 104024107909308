import React, { useEffect } from 'react';
import HTMLReactParser from 'html-react-parser';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { Helmet } from 'react-helmet-async';
import {
    getUrlCountryPath,
    getLanguageCode,
  } from 'utils/urlUtils';

const OPTANON_CONSENT_COOKIE = "OptanonConsent"
const OPTANON_ALERT_BOX_COOKIE = "OptanonAlertBoxClosed"

const OneTrustCookie = (props) => {

    // const langCode = getLanguageCode();
    const market = getUrlCountryPath();
    const domain = window.location.hostname

    const deleteCookie = (cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain}`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/${market};`;
    }

    useEffect(() => {
        const regex = new RegExp(OPTANON_CONSENT_COOKIE, 'g');
        const matches = document.cookie.match(regex);
        const count = matches ? matches.length : 0;

        if(count > 1) {
            const cookies = document.cookie.split("; ");
                for (const cookie of cookies) {
                const [name, value] = cookie.split("=");
                if (name === OPTANON_CONSENT_COOKIE || name === OPTANON_ALERT_BOX_COOKIE) {
                    deleteCookie(name);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        {props?.id && props?.url && (
        <Helmet htmlAttributes={{ lang: market?.trim() || 'en' }}>
            <script src={props.url}  type="text/javascript" data-document-language="true" charset="UTF-8" data-domain-script={props?.id} ></script>
        <script type="text/javascript">
        {`function OptanonWrapper() { }`}
        </script>
        </Helmet> )}
       </> 
    );
}

export default OneTrustCookie;