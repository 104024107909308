import React from 'react'
import { getAssetUrl } from 'api/acousticApi';

// styles
import './CardImage.scss'

const CardImage = ({ bgImage, icon, brandLogo }) => {

    if (!bgImage?.url && !icon?.url && !brandLogo?.url) {
        return null
    }

    return (
        <div className={`CardImage${bgImage ? ' CardImage--bg-image' : ''}${icon ? ' CardImage--icon-image' : ''}${brandLogo ? ' CardImage--brand-logo' : ''}`} style={bgImage ? { backgroundImage: `url(${getAssetUrl(bgImage) || bgImage})`} : null }>
           {
               (icon || brandLogo) && <img alt="" src={(getAssetUrl(icon) || icon) || (getAssetUrl(brandLogo) || brandLogo)} />
           }
        </div>
    )
}

export default CardImage