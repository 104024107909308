import React from 'react';
import PropTypes from 'prop-types';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { AuthorsIcon } from 'components/shared/SvgIcons';
import Link from 'components/reusableComponents/link/Link';
//TODO: retrieve access course name from website configuration and svg
const AccessCourse = ({
  text,
  onClickPath,
  label
}) => {
  const { accessCourseButtonLabel } = useWebsiteConfigObject();
  let usedText;
  if(label != null){
    usedText = text ? text: label;
  }
  else{
    usedText = text ? text : accessCourseButtonLabel;
  }
  return (
   
    onClickPath
      ? (
        <Link
          className="learn-more"
          href={onClickPath}
          vvClickAttr='click'
        >
          <p className="learn-more-text">
            {usedText}
          </p>
          <div className="svg-container svg-course">
            <AuthorsIcon />
          </div>
        </Link>
      )
      : (
        <div className="learn-more">
          <p className="learn-more-text">
            {usedText}
          </p>
          <div className="svg-container svg-course">
            <AuthorsIcon />
          </div>
        </div>
      )
  );
};



AccessCourse.propTypes = {
  text: PropTypes.string,
  onClickPath: PropTypes.string,
};

export { AccessCourse };
