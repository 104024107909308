import React, { useState } from 'react';

import Link from 'components/reusableComponents/link/Link';
import {
  getWebinarDate,
  getWebinarTime,
} from 'components/pageTemplates/webinar/webinarHelpers';
import { deleteCourse } from 'api/dynamoApi';
import { Bin } from './SvgIcons';
import { Loader, TINY_LOADER } from 'components/shared/Loader';
import Image from 'components/reusableComponents/image/Image';
import { Calender, Clock, ChevronRight } from 'components/shared/SvgIcons';
import webinarImgDefault from 'assets/images/webinar-card-img.png';

import './WebinarHubCardImage.scss';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { getUrlCountryPath } from 'utils/urlUtils';


const WebinarHubCardImage = ({
  item,
  content,
  userId,
  token,
  removeCourse,
  webinarContent,
  recordId,
}) => {
  const {
    learnMoreLabel,
    removeWebinarAlertMessage,
    removeWebinarTooltipText,
  } = content;

  const { promotionalContentStickerText } = useWebsiteConfigObject();

  const [mouseOver, setMouseOver] = useState(false);
  const [removing, setRemove] = useState(false);
  const { trackCourses } = useGoogleAnalytics();
  const { description } = webinarContent?.document;

  const {
    title,
    webinarDate,
    urlSlug,
    promotionalWebinar,
    webinarImage,
    webinarTime,
    openInNewTab,
    webinarExternalId
  } = webinarContent?.document?.elements;
  const {
    id
  } = webinarContent?.document;
  const deleteCourseAction = () => {
    setMouseOver(false);
    let body = {};
    body['userId'] = userId;
    if(webinarExternalId?.value){
      body['externalId'] = id+"|"+webinarExternalId?.value;
      if(recordId){
        body['recordId'] = recordId;
      }
      if (window.confirm(removeWebinarAlertMessage?.value)) {
        setRemove(true);
        deleteCourse({ body, token })
          .then((response) => {
            if (response.status === 200) {
              removeCourse(id);
              setRemove(false);
            }
          })
          .catch((error) => {
            setRemove(false);
            console.log(error);
          });
      } else {
        setRemove(false);
      }
    } else {
      body['externalId'] = id;
      if(recordId){
        body['recordId'] = recordId;
      }
      if (window.confirm(removeWebinarAlertMessage?.value)) {
        setRemove(true);
        deleteCourse({ body, token })
          .then((response) => {
            if (response.status === 200) {
              removeCourse(id);
              setRemove(false);
            }
          })
          .catch((error) => {
            setRemove(false);
            console.log(error);
          });
      } else {
        setRemove(false);
      }
    }
  };
  const callGA = () => {
    trackCourses(id, userId);
  };

  const handleClick = (e) => {
    if(openInNewTab?.value === true) {
        e.preventDefault()
        window.open(`/${getUrlCountryPath()}${urlSlug?.value}`)
    }
}

  return (
    <div className='webinar-image-card'>
      {promotionalWebinar?.value && promotionalContentStickerText?.value && (
        <div className='webinar-image-card__promotional'>
          {promotionalContentStickerText?.value}
        </div>
      )}

      <div className='webinar-image-card__image'>
        {webinarImage?.url && (
          <Image data={webinarImage} alt={webinarImage?.fileName} />
        )}
        {(webinarImage?.url === '' ||
          webinarImage?.url === undefined ||
          webinarImage?.url === null) && (
            <img
            src={webinarImgDefault}
            alt='Webinar image'
          />
        )}
      </div>
      <div className='webinar-image-card__details'>
        {title?.value && (
          <h3 className='webinar-card-title'>
            {title?.value}
          </h3>
        )}
        {description && <p className='webinar-card-desc'>{description}</p>}
        {webinarDate?.value && (
          <div className='webinar-card-dates'>
            <div className='webinar-card-dates__detail'>
              <Calender />
              <div className='date'>{getWebinarDate(webinarDate)}</div>
            </div>
            <div className='webinar-card-dates__detail'>
              <Clock />
              <div className='date'>
                {webinarTime?.value}
              </div>
            </div>
          </div>
        )}

        <div className='webinar-card-btns' onClick={callGA}>
          <Link onClick={handleClick} href={urlSlug?.value}>
            <span>
              {learnMoreLabel?.value ? learnMoreLabel?.value : 'View'}
            </span>
            <ChevronRight />
          </Link>
          <div
            className='delete-btn'
            onClick={deleteCourseAction}
            onMouseEnter={() => {
              setMouseOver(true);
            }}
            onMouseLeave={() => {
              setMouseOver(false);
            }}
          >
            {removing && <Loader size={TINY_LOADER} />}
            {!removing && <Bin />}
          </div>
        </div>
      </div>

      <div className={`delete-btn-tooltip${mouseOver ? ' active' : ''}`}>
        {removeWebinarTooltipText?.value}
      </div>
    </div>
  );
};

export default WebinarHubCardImage;
