import React from 'react';
import PropTypes from 'prop-types';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import {
  LOGIN,
  REGISTER,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  ACCOUNT_DETAILS,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  CODE_400,
  CODE_401,
  CODE_404,
  CODE_406,
  CODE_415,
  CODE_422,
  UNEXPECTED_ERROR,
} from 'components/shared/form/formConstants';
import './FormError.scss';

const FormError = ({ error }) => {
  const {
    tAnUnexpectedError,
    tUsernamePasswordIncorrect,
    tUserAccountExists,
    tUserNotFound,
    tTheLinkIsInvalid,
    tYouAreNotAuthorised,
    tCurrentPasswordIsIncorrect,
    tThePasswordIsIncorrect,
  } = useWebsiteConfigObject();
  // Destructuring the formId and errorKey passed down in error prop from form.
  const { formId, errorKey } = error;

  const responseValidations = [
    {
      id: LOGIN,
      validationKeys: [
        {
          errorKey: CODE_400,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_401,
          errorText: tUsernamePasswordIncorrect,
        },
        {
          errorKey: CODE_406,
          errorText: tAnUnexpectedError,
        },
      ],
    },
    {
      id: REGISTER,
      validationKeys: [
        {
          errorKey: CODE_400,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_406,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_415,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_422,
          errorText: tUserAccountExists,
        },
      ],
    },
    {
      id: FORGOTTEN_PASSWORD,
      validationKeys: [
        {
          errorKey: CODE_400,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_404,
          errorText: tUserNotFound,
        },
        {
          errorKey: CODE_406,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_415,
          errorText: tAnUnexpectedError,
        },
      ],
    },
    {
      id: RESET_PASSWORD,
      validationKeys: [
        {
          errorKey: CODE_400,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_401,
          errorText: tTheLinkIsInvalid,
        },
        {
          errorKey: CODE_406,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_415,
          errorText: tAnUnexpectedError,
        },
      ],
    },
    {
      id: ACCOUNT_DETAILS,
      validationKeys: [
        {
          errorKey: CODE_400,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_401,
          // TODO not sure if this should just be the generic error
          // as you are meant to be logged in as the user to update profile details
          errorText: tYouAreNotAuthorised,
        },
        {
          errorKey: CODE_406,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_415,
          errorText: tAnUnexpectedError,
        },
      ],
    },
    {
      id: CHANGE_PASSWORD,
      validationKeys: [
        {
          errorKey: CODE_400,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_401,
          errorText: tCurrentPasswordIsIncorrect,
        },
        {
          errorKey: CODE_406,
          errorText: tAnUnexpectedError,
        },
        {
          errorKey: CODE_415,
          errorText: tAnUnexpectedError,
        },
      ],
    },
    {
      id: DELETE_ACCOUNT,
      validationKeys: [
        {
          errorKey: CODE_401,
          errorText: tThePasswordIsIncorrect,
        },
        {
          errorKey: UNEXPECTED_ERROR,
          errorText: tAnUnexpectedError,
        },
      ],
    },
  ];

  // Using formId from error props match to id in responseValidation array to return the correct validation object
  const validation = formId && errorKey ? responseValidations.find((x) => x.id === formId).validationKeys : false;

  // if there is a validation object to return find the correct error key matching error passed down to props from form
  const validationKey = validation ? validation.find((x) => x.errorKey === errorKey) : false;

  // if validationKey is not undefined return correct error text from object else return null
  if (validationKey) {
    return (
      <p className="form-error-text">
        {validationKey.errorText}
      </p>
    );
  }

  return null;
};

FormError.propTypes = {
  error: PropTypes.objectOf(PropTypes.any),
};

export { FormError };
