import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
} from 'components/layout/ScreenWidthContext';
import {
  ChevronLeft,
  ChevronRight,
  ChevronUpCarousel,
  ChevronDownCarousel,
  LeftArrow,
  RightArrow,
} from 'components/shared/SvgIcons';
import Equalizer from 'react-equalizer';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
  Dot,
} from 'pure-react-carousel';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Carousel.scss';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useSelector } from 'react-redux';

// The standard DotGroup shows a single dot for each slide
// but we need a dot for each page of slides, so we roll our own
const CarouselDots = ({
  totalSlides,
  slidesPerPage,
  handleClick,
  setSliderBtnClicked,
}) => {
  const slideIndexesForDots = [];
  for (let i = 0; i < totalSlides; i++) {
    // if there are visibleSlides slides on each page
    // the index of the first slide on a page will divide exactly into
    // visibleSlides. E.g if visibleSlides = 3, the index of the first slide
    // will be 0, 3, 6, 9 etc.
    const firstIndexInPage = i % slidesPerPage === 0;
    if (firstIndexInPage) {
      slideIndexesForDots.push(i);
    }
  }

  return (
    <div className='dots' style={{ minHeight: '13px' }}>
      {slideIndexesForDots.map((slide) => (
        <Dot
          key={slide}
          className='custom-focus'
          slide={slide}
          onClick={() => {
            handleClick('dots');
            if(setSliderBtnClicked){
              setSliderBtnClicked(true);
            }
          }}
        />
      ))}
    </div>
  );
};

CarouselDots.propTypes = {
  slidesPerPage: PropTypes.number,
  totalSlides: PropTypes.number,
  handleClick: PropTypes.func,
  setSliderBtnClicked: PropTypes.func,
};

const getContainerClassName = ({
  slideDirection,
  className,
  showArrows,
  showDots,
}) => {
  const classNames = [`carousel-container ${slideDirection}`];
  if (className) {
    classNames.push(className);
  }
  if (!showArrows) {
    classNames.push('no-arrows');
  }
  if (!showDots) {
    classNames.push('no-dots');
  }
  return classNames.filter(Boolean).join(' ');
};

const Carousel = ({
  slides,
  slideWidth,
  slideHeight,
  visibleSlides,
  className,
  orientation,
  gaTrackingContentType,
  timeSlider,
  autoSlide,
  setSliderBtnClicked
}) => {
  const { currentWidthRanges } = useScreenWidth();
  const { trackEventWithContentType } = useGoogleAnalytics();
  const { redesign } = useSelector(state => state.ui);
  const handleClick = (itemId) => {
    if (gaTrackingContentType) {
      trackEventWithContentType(gaTrackingContentType, itemId);
    }
  };

  if (!slides || slides.length === 0) {
    return null;
  }

  // validate the orientation - use horizontal by default if there's a problem
  const orientationValues = ['horizontal', 'vertical'];
  const slideDirection = orientationValues.includes(orientation)
    ? orientation
    : orientationValues[0];

  const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
  // There is no need for controls if all the slides are in view
  const noControls = slides.length <= visibleSlides;
  // dont show arrows if there are noControls or the screen is small and orientation is horizontal
  const showArrows = !(
    noControls ||
    (isSmall && slideDirection === 'horizontal')
  );

  // dont show dots if there are no controls or if the orientation is vertical
  const showDots = !(noControls || slideDirection === 'vertical');

  const containerClassName = getContainerClassName({
    slideDirection,
    className,
    showArrows,
    showDots,
  });
  if(redesign){
    return (
      <CarouselProvider
        naturalSlideWidth={slideWidth}
        naturalSlideHeight={slideHeight}
        totalSlides={slides.length}
        visibleSlides={visibleSlides}
        step={visibleSlides}
        orientation={slideDirection}
        interval={timeSlider}
        isPlaying={autoSlide}
        playDirection='forward'
        infinite={true}
      >
        <div className='carousel-header'>
          {showArrows && (
            <div className='carousel-navigation'>
              <ButtonBack
                className='arrow-nav custom-focus'
                onClick={() => {
                  handleClick('previous');
                  if(setSliderBtnClicked){
                    setSliderBtnClicked(true);
                  }
                }}
              >
                <LeftArrow />
              </ButtonBack>
              <ButtonNext
                className='arrow-nav .custom-focus'
                onClick={() => handleClick('next')}
                data-vv-action='click'
                data-vv-count={Math.ceil(slides.length / visibleSlides)}
                data-vv-snapshot='before'
              >
                <RightArrow />
              </ButtonNext>
            </div>
          )}
        </div>
        <div className={containerClassName} style={{ minHeight: '370px' }}>
          <div className='slider-container'>
            <Slider>
              <Equalizer>
                {slides.map((item, index) => (
                  <Slide
                    key={uuid()}
                    index={index}
                    tabIndex={-1} // the content should dictate if it can be focused. This is just a wrapper
                  >
                    {item}
                  </Slide>
                ))}
              </Equalizer>
            </Slider>
          </div>
        </div>
        {showDots && (
          <CarouselDots
            totalSlides={slides.length}
            slidesPerPage={visibleSlides}
            setSliderBtnClicked={setSliderBtnClicked}
            handleClick={handleClick}
          />
        )}
      </CarouselProvider>
    );
  }
  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={slideHeight}
      totalSlides={slides.length}
      visibleSlides={visibleSlides}
      step={visibleSlides}
      orientation={slideDirection}
      interval={timeSlider}
      isPlaying={autoSlide}
      playDirection='forward'
      infinite={true}
    >
      <div className={containerClassName} style={{ minHeight: '370px' }}>
        {showArrows && (
          <div className='carousel-nav carousel-nav-back'>
            <ButtonBack
              className='chevron custom-focus'
              onClick={() => {
                handleClick('previous');
                if(setSliderBtnClicked){
                  setSliderBtnClicked(true);
                }
              }}
            >
              {slideDirection === 'horizontal' ? (
                <ChevronLeft />
              ) : (
                <ChevronUpCarousel />
              )}
            </ButtonBack>
          </div>
        )}
        <div className='slider-container'>
          <Slider>
            <Equalizer>
              {slides.map((item, index) => (
                <Slide
                  key={uuid()}
                  index={index}
                  tabIndex={-1} // the content should dictate if it can be focused. This is just a wrapper
                >
                  {item}
                </Slide>
              ))}
            </Equalizer>
          </Slider>
        </div>
        {showArrows && (
          <div className='carousel-nav carousel-nav-next'>
            <ButtonNext
              className='chevron custom-focus'
              onClick={() => {
                handleClick('next');
                if(setSliderBtnClicked){
                  setSliderBtnClicked(true);
                }
              }}
              data-vv-action='click'
              data-vv-count={Math.ceil(slides.length / visibleSlides)}
              data-vv-snapshot='before'
            >
              {slideDirection === 'horizontal' ? (
                <ChevronRight />
              ) : (
                <ChevronDownCarousel />
              )}
            </ButtonNext>
          </div>
        )}
      </div>
      {showDots && (
        <CarouselDots
          totalSlides={slides.length}
          slidesPerPage={visibleSlides}
          setSliderBtnClicked={setSliderBtnClicked}
          handleClick={handleClick}
        />
      )}
    </CarouselProvider>
  );
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.element),
  slideWidth: PropTypes.number,
  slideHeight: PropTypes.number,
  visibleSlides: PropTypes.number,
  gaTrackingContentType: PropTypes.string,
  setSliderBtnClicked: PropTypes.func
};

export { Carousel };
