const warnUndefinedKeys = (params, sourceFile) => {
  Object.keys(params).forEach((dataKey) => {
    const field = params[dataKey];
    if (field === undefined) {
      console.error(`Unable to find value for key: ${dataKey}. This is most likely because a formField id changed and was not updated in ${sourceFile}`);
    }
  });
};

const getServerParametersForUserForm = (formValues) => ({
  salutation: formValues.salutation,
  firstName: formValues['first-name'],
  lastName: formValues['last-name'],
  speciality: formValues.speciality,
  workplace: formValues['organization-title'],
  promotionalMaterialsOptIn: formValues['information-consent'],
  username: formValues['username'] ? formValues['username'] : formValues.email,
  role: formValues['role'],
  phone: formValues['phone'],
  jobTitle: formValues['jobtitle'],
  department: formValues['department'],
  departmentPhone: formValues['departmentPhone'],
  optinHCP: true,
  utmValues: formValues.utmValues,
  customProperties: null, // something the server wants but doesn't use for anything yet.
});

const getServerParametersForProfileForm = (formValues) => {
  const userParams = getServerParametersForUserForm(formValues);
  warnUndefinedKeys(userParams, 'userDataUtils.js');
  return userParams;
};

const getServerParametersForChangePasswordForm = (formValues) => {
  const passwordParams = {
    originalPassword: formValues.password,
    newPassword: formValues['new-password'],
    newConfirmPassword: formValues['new-password'],
  };
  warnUndefinedKeys(passwordParams, 'userDataUtils.js');
  return passwordParams;
};

export {
  warnUndefinedKeys,
  getServerParametersForUserForm,
  getServerParametersForProfileForm,
  getServerParametersForChangePasswordForm,
};


