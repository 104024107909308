import React from 'react'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import SideNavigation from '../../modules/sideNavigation/SideNavigation'

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { ApprovalCode } from 'components/content/ApprovalCode'
import { References } from 'components/content/References'
import RichText from 'components/reusableComponents/richText/RichText'
import { BannerImage } from 'components/content/BannerImage'
import { getValue } from 'utils/contentUtils'
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
  } from 'components/layout/ScreenWidthContext';

// styles
import './ContentPage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'

const ContentPage = ({ content }) => {
    const {
        headerText,
        title,
        subtitle,
        headerLogo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        section1BodyCopy,
        section2BodyCopy,
        carouselImages,
        relatedContent,
        bannerImage,
        veevaApprovalCode,
        references,
        subnavigation,
    } = content ? content.elements : {}
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

    const settings = {
        infinite: relatedContent?.value?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        autoplay: isSmall ? false  : relatedContent?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: relatedContent?.value?.elements?.automationInterval?.value ? parseFloat(relatedContent?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: { arrows: false },
            },
        ],
    }

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;

    return (
        <section role='main' className='global_layout' data-layout-type='content-page'>
            <MainAreaTitleSection
                title={title && title.value}
                description={headerText}
                category={subtitle && subtitle.value}
                logo={headerLogo}
                backgroundImage={headerBackgroundImage}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-2-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>
                        <section className='content_wrapper content_wrapper--columns-2-center'>
                            {section1BodyCopy && section1BodyCopy?.value && (
                                <div className='content_item'>
                                    <RichText data={section1BodyCopy} />
                                </div>
                            )}
                            {carouselImages && carouselImages?.values?.length > 0 && (
                                <div className='content_item'>
                                    <RelatedContent bgColor='#F6F6F5' content={carouselImages} componentType='slides' carouselSettings={settings} cardTheme='secondary' fluidRight removeLeftPadding />{' '}
                                </div>
                            )}
                            {section2BodyCopy && section2BodyCopy?.value && (
                                <div className='content_item'>
                                    <RichText data={section2BodyCopy} />
                                </div>
                            )}
                        </section>
                        <div className='content_wrapper content_wrapper--columns-2-right'></div>
                    </div>
                    {relatedContent && relatedContent?.value && <RelatedContent content={relatedContent} fluid />}
                    {bannerImage && <BannerImage image={bannerImage} contain={false} />}
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
                    {references && <References references={references} />}
                </div>
            </section>
        </section>
    )
}

export default ContentPage
