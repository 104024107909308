/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonModal } from 'components/reusableComponents/buttonModal/ButtonModal'
import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react';

const tags = [
    {
        name: "IframeOverlay",
        regex: /\[IframeOverlay (.+?)\]/g,
    }
]

let scopedAggregator = 0;

const replaceAsciiInString = (str) => str && str.replace(/&#(\d+);/g, (m, n) => String.fromCharCode(n))

export const extractTagsFromContent = (html) => {
    let tagsContent = new Map();
    tags.forEach( tag => {
        const { regex, name } = tag
        const pattern = regex
        if (pattern.test(html)) {
            const matchTagList = html.match(pattern)
            matchTagList.forEach( instance => {
                const uuid = `${name}-${scopedAggregator}`
                tagsContent.set(uuid, instance)
                scopedAggregator++
            });
        }
    })
    return tagsContent
}

export const replaceTagsToPlaceholder = (html, tags) => {
    let htmlWithPlaceholder = html
    tags.forEach( (toReplace, placeholderKey) => {
        let placeholder = `<span id = '${placeholderKey}'></span>`
        htmlWithPlaceholder = htmlWithPlaceholder.replace( toReplace, placeholder )
    });
    return htmlWithPlaceholder 
}

export const buildEdetailerInstance = (tags) => {
    const Edetailers = []
    tags.forEach( (rawEdetailer, index) => {
        const props = getPropsFromTag(rawEdetailer)
        Edetailers.push(<PortalWatcher targetId = {index} key = {index}><ButtonModal {...props} /></PortalWatcher>)
    })
    return Edetailers
}

export const getPropsFromTag = (tag) => {
    let props = {}
    tag.match(/[\w-]+=".+?"/g)?.forEach((attribute) => {
        attribute = attribute.match(/([\w-]+)="(.+?)"/);
        props = { ...props, [attribute[1]]: attribute[2] }
    });
    return props
}

const PortalWatcher = ({targetId, children}) => {
    const isReady = useDomObserver(targetId)
    if ( !isReady )
        return null
    const targetElement = document.getElementById(targetId)
    return ReactDOM.createPortal(children, targetElement)
}

const useDomObserver = (targetId) => {
    const [ isReady, setIsReady ] = useState(false)
    useEffect(() => {
        const isAttachedOnDOM = setInterval(() => {
            const element = document.getElementById(targetId)
            if ( !element )
                return
            clearInterval(isAttachedOnDOM)
            setIsReady(true)
        }, 200);
        return () => clearInterval(isAttachedOnDOM)
    }, [targetId])
    return isReady
}

export const withEdetailerParser = (Component) => {
    function WithEdetailerParser (props) {
        const [ tagList, setTagList ] = useState(new Map())
        const { data, fromWebconfig } = props
        let { value } = data || {}
        if( fromWebconfig === true)  {
            value = data
        }
        let formattedValue = replaceAsciiInString(value)
        const tags = extractTagsFromContent(formattedValue)
        useEffect(() => {
            if (tags.size > 0 && tagList.size === 0)
                setTagList(tags)
        }, [])
        if (tags.size === 0)
            return <Component {...props} />
        if (tagList.size === 0)
            return null
        const htmlResult = replaceTagsToPlaceholder(formattedValue, tagList)
        const Edetailers = buildEdetailerInstance(tagList)
        let newProps = {
            ...props,
            data: {
                value: htmlResult
            }
        }
        if ( fromWebconfig === true) {
            newProps.data = htmlResult
        }
        return <>
            <Component {...newProps} />
            { Edetailers }
        </>
    }
    return WithEdetailerParser
}