import { AppConfig } from 'AppConfig'
import { getAuthHeaderConfig } from 'utils/authUtils'
import { CREATE_TOKEN_URL } from 'api/doccheckApi/paths'
import { getHostnameFromUrl, getLocationOrigin, getUrlCountryPath, getDocheckUrlRedirect } from 'utils/urlUtils'
import { authGetQuery } from '../AxiosQueries'

const {
  audience,
  loginId,
} = AppConfig.authApi.docCheck

const docCheckAuth = {
  docCheckRegisterRedirect: () => {

    const url = 'https://www.doccheck.com/register'

    window.open(url, '_blank')

  },
  docCheckLoginRedirect: () => {

    const url = `https://login.doccheck.com/code/fullscreen_dc/de/${loginId}/return_uri=${window.location.protocol}%7Bslash%7D%7Bslash%7D${getHostnameFromUrl(getLocationOrigin())}${getDocheckUrlRedirect()}`

    window.location.href = url

  },
  createUserToken: ({ authCode, doccheckuserconsent, taxonomyRegistration }) => {
    doccheckuserconsent = doccheckuserconsent ? `&doccheckuserconsent=${doccheckuserconsent}` : '';
    const url = `${CREATE_TOKEN_URL}?code=${authCode}${doccheckuserconsent}&additionalInfo=${JSON.stringify(taxonomyRegistration).replaceAll('&','%26')}`

    return authGetQuery({ url, config: getAuthHeaderConfig({ audienceUrl: audience }) }).then((response) => response)

  },
}

export { docCheckAuth }
