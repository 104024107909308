import React, { useEffect, forwardRef, useState } from 'react'
import Link from '../link/Link'

// styles
import './Card.scss'

const Card = forwardRef(({ children, alternateBg, bgColor, maxWidth, height, className, hasBgImage, href, removeShadow, removeBorderRadius, actionPdf, snapshotPdf, onClick, fileAsset }, ref) => {
    const [_height, setHeight] = useState(height)

    useEffect(() => {
        if (ref?.current && hasBgImage) {
            setHeight(ref.current.offsetHeight)
        }
    }, [ref, hasBgImage])

    if (href) {
        return (
            <Link
                ref={ref}
                href={href}
                className={`Card${alternateBg ? ' Card--alternate-bg' : ''}${removeShadow ? ' Card--remove-shadow' : ''}${className ? ` ${className}` : ''}${removeBorderRadius ? ' Card--remove-border-radius' : ''}${className ? ` ${className}` : ''}`}
                style={{
                    maxWidth,
                    backgroundColor: bgColor || null,
                    height: _height,
                }}
                onClick={onClick}
                assetName={fileAsset?.asset?.fileName}
            >
                {children}
            </Link>
        )
    }

    return (
        <div
            ref={ref}
            className={`Card${alternateBg ? ' Card--alternate-bg' : ''}${removeShadow ? ' Card--remove-shadow' : ''}${removeBorderRadius ? ' Card--remove-border-radius' : ''}${className ? ` ${className}` : ''}`}
            style={{
                maxWidth,
                backgroundColor: bgColor || null,
                height: _height,
            }}
            data-vv-action={actionPdf}
            data-vv-snapshot={snapshotPdf}
        >
            {children}
        </div>
    )
})

export default Card
