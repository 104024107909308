import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { getUrlCountryData } from 'utils/libraryUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { useSelector } from 'react-redux';
import { checkCookie } from 'hooks/CheckCookie';
import { TARGETING_COOKIE } from 'components/layout/OneTrustEventInteraction';

const GTag = () => {
  const websiteConfig = useWebsiteConfigObject();
  // const cookieAcceptance = useContext(cookieConfirmationContext);
  const { oneTrustAccepted, oneTrustTargetingAccepted, sfmcInitialized } = useSelector(state => state.ui)
  const { initialiseAnalytics } = useGoogleAnalytics();

  const cookieVersionValue = websiteConfig?.cookieBannerVersion?.selection;
  const groupId = websiteConfig?.oneTrustAnalyticsGroupId || 'C0002';
  const { gaMeasurementId, gtmMeasurementId } = getUrlCountryData();
  // const url = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`;
  const gtmSrc = `https://www.googletagmanager.com/gtm.js?id=${gtmMeasurementId}`;

  const loadScript = () => {
    const gtmId = 'gtm-script';
    let gtmScript = document.getElementById(gtmId);

    if(gtmScript) gtmScript.remove();

    gtmScript = document.createElement('script');
    gtmScript.id = gtmId
    gtmScript.src = gtmSrc;
    gtmScript.type = 'text/plain';

    const isPerformanceAccepted = checkCookie(), isTargetingAccepted = checkCookie(TARGETING_COOKIE);
    const scriptActivation = (isTargetingAccepted && sfmcInitialized) || (isPerformanceAccepted && !isTargetingAccepted)
    if (cookieVersionValue && cookieVersionValue === 'OneTrust') {
      if(scriptActivation) {
        gtmScript.type = 'text/javascript';
      } 
    }
    gtmScript.className = `optanon-category-${groupId}`;

    if(scriptActivation) document.head.appendChild(gtmScript);
  }

  useEffect(() => {
    const isPerformanceAccepted = checkCookie(), isTargetingAccepted = checkCookie(TARGETING_COOKIE);
    const scriptActivation = (isTargetingAccepted && sfmcInitialized) || (isPerformanceAccepted && !isTargetingAccepted)
    if(scriptActivation) {
      initialiseAnalytics()
    }

    loadScript();
  }, [oneTrustAccepted, oneTrustTargetingAccepted, sfmcInitialized])
  

  return null;
};

export default GTag;
