import React from 'react';

//components
import { AccountDetailsFormMyHub } from '../../profile/AccountDetailsFormMyHub';
import { ChangePasswordForm } from '../../profile/ChangePasswordForm';
import { DeleteAccountForm } from '../../profile/DeleteAccountForm';

//utils
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { KKI_AUTH } from 'utils/authUtils';

//scss
import './MyHubSettings.scss';

const MyHubSettings = ({ title, tabId, tabActive }) => {
  const { authService, tProfilePageVeevaCode } = useWebsiteConfigObject();
  if (tabId !== tabActive) {
    return '';
  }

  return (
    <div className='my-hub__tabs__right'>
      <div className='my-hub-settings'>
        {title?.value && (
          <section className='content-title'>
            <h2>{title?.value}</h2>
          </section>
        )}

        <section className='content-section'>
          <AccountDetailsFormMyHub />
        </section>
        {authService.selection === KKI_AUTH && (
          <section className='content-section'>
            <ChangePasswordForm />
          </section>
        )}
        <section className='content-section'>
          <DeleteAccountForm />
        </section>
      </div>
    </div>
  );
};

export default MyHubSettings;
