import React from 'react';

//components
import { ProfilePage } from './ProfilePage';
import MyHubProfile from '../myhub/MyHubProfile';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';


const ProfileType = () => {
  let activateMyHub = useWebsiteConfig()?.websiteConfig?.activateMyHub;
  
  if (activateMyHub) {
    return <MyHubProfile />;
  }
  return <ProfilePage />;
};

export default ProfileType;
