import React from 'react'
import { contentItemProptype } from 'propTypes/contentProptypes'

// components
import Link from 'components/reusableComponents/link/Link'
import Image from 'components/reusableComponents/image/Image'

// styles
import './Logo.scss'

const Logo = ({ content }) => {
    if(!content) { return null }
    const { logo, logoLink } = content?.value?.elements || {}
    return (
        <div className='logo'>
            {logoLink?.linkURL ? (
                <div className='logo__holder'>
                    <Link href={logoLink.linkURL}>
                        <Image data={logo} />
                    </Link>
                </div>
            ) : logo?.url && (
                <div className="logo__holder">
                    <Image data={logo} />
                </div>
            )}
        </div>
)}

Logo.propTypes = {
    content: contentItemProptype,
}

export default Logo