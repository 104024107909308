import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import './MandatoryKey.scss';

const MandatoryKey = () => {
  const { tMandatory } = useWebsiteConfigObject();
  return (
    <p className="mandatory-key">{tMandatory}</p>
  );
};


export { MandatoryKey };

