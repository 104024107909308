import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { clearStoreLocation } from 'redux/location/slice';
import { getPageTemplateForType } from 'components/pageTemplates/pageTemplateTypes';
import { ContentGateWrapper } from 'components/layout/ContentGateWrapper';
import ContentGate from 'modules/contentGate/ContentGate';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { PayWall } from 'components/layout/payWall/PayWall';
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import { LANDING_PAGE } from 'api/acousticApi/contentTypes'
import { userActions } from 'redux/user/slice';
import { Features } from 'components/shared/feature/Features';
import { ContentGateWrapperAnonymous } from 'components/layout/ContentGateWrapperAnonymous';
import useVeevaUserAccess, { VEEVA_SRC } from 'hooks/useVeevaUserAccess';
import { AppConfig } from 'AppConfig';
import useTaxonomyRegistration from 'hooks/useTaxonomyRegistration';
import useUtmUnlock from 'utmCampaign/useUtmUnlock';

// component representing a page whos content is retrieved from the acoustic api
// accepts the contentId and templateType as props
// TODO: the templateType might be better retrieved from the content itself if for whatever reason
// we can't go through the router
const DocCheckCMS = ({ content, templateType, promotional, utmState }) => {
  const dispatch = useDispatch();
  const { secureContentGate } = useWebsiteConfigObject();
  const { pathname } = useSelector((state) => state.location.location);
  const { status, data } = useSelector((state) => state.authentication);
  const { details } = useSelector((state) => state.user);
  const { veevaUser, veevaUserInvalid} = useSelector((state) => state.authentication.status);

  const anonymous = data?.additional?.tokenResponse?.anonymous;
  const loggedIn = status?.loggedIn;
  const userData = data?.user;
  const acceptedPromtionalConsent = data?.consent?.acceptedPromotional;

  const [baseUserData, setBaseUserData] = useState(checkMainRequiredData(userData));

  const PageTemplate = getPageTemplateForType(templateType);

  const accessLevel =
    (content &&
      content.elements &&
      content.elements.contentAccess &&
      content.elements.contentAccess.value &&
      content.elements.contentAccess.value.selection) ||
    null;

    const requiresLogin = accessLevel !== 'a' && templateType !== LANDING_PAGE;
  const { promotionalWebinar } = content?.elements || null;
  const pageTitle = content?.elements?.title?.value || content?.elements?.title || null;
  const relatedPageUrl =
    (content &&
      content.elements &&
      content.elements.relatedPageUrl &&
      content.elements.relatedPageUrl.value) ||
    '';
  // fetch details for content type c

  useTaxonomyRegistration({ requiresLogin, path: content?.elements?.urlSlug?.value, taxonomy: content?.elements?.taxonomy?.value });

  useEffect(
    () => {
      dispatch(userActions.global.details.fetch())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedIn]
  );

  useEffect(() => {
    return () => {
      if (pathname) dispatch(clearStoreLocation());
    };
  }, [dispatch, pathname]);

  useEffect(() => {
    setBaseUserData(checkMainRequiredData(details));
  }, [userData.firstName, userData.lastName, userData.email, details.firstname, details.lastname, details.email])

  const urlParams = new URLSearchParams(window.location.search);

  // Veeva users session management
  const veevaAccessGranted = useVeevaUserAccess(accessLevel);
  let userId = urlParams.get(AppConfig.veevaApi.userIdQueryName) ?? urlParams.get('actid');
  userId = userId ?? urlParams.get('actId');

  if(utmState === 'unlocked') {
    return <PageTemplate content={content} template={templateType} />;
  }

  if((userId || veevaUser === true) && veevaUserInvalid){
    return (
      <Redirect
        to={{
          pathname: "/",
          // state provided in the `to` field object is accessible in the redirected
          // route as props.location.state. Using this to provide a return path
          // for after successful login
          // https://reactrouter.com/web/api/Redirect/to-object
        }}
      />
    );
  }


  if(veevaUser === true 
    || ( anonymous === false && baseUserData ===  false) 
    || (veevaUser === null && userId)
    ) {
    return (
      <section role='main' className='loading-page'>
        <Loader className='center-aligned-loader' size={LARGE_LOADER} />
      </section>
    );
  }
  
  if(veevaAccessGranted){
    return <PageTemplate content={content} />;
  }

  if (!loggedIn && requiresLogin) {
    return (
      <>
        <ContentGateWrapper secure={secureContentGate || false} content={content}>
          <PageTemplate content={content} />
        </ContentGateWrapper>
      </>
    );
  }

  //level c to transfer to dockcheck
  // const { user } = data || {};
  function checkMainRequiredData(data) {
    let allDataAvailable = true;
    if (
      data?.firstname === null ||
      data?.lastname === null ||
      data?.email === null ||
      data?.firstname === '' ||
      data?.lastname === '' ||
      data?.email === ''
    ) {
      allDataAvailable = false;
    }
    return allDataAvailable;
  }
  
  function checkOptionMarketing(data) {
    return data?.promotionalMaterialsOptIn;
  }
  
  //check access level c pages
  // let baseUserData = checkMainRequiredData(userData);
  let optionMarketing = checkOptionMarketing(userData);
  if (loggedIn && accessLevel === 'c' && (!baseUserData || !optionMarketing)) {
    return (
      <>
        <Features pageName='' contentType='feature-anonymous-flow'>
          <ContentGateWrapperAnonymous
            secure={secureContentGate || false}
            optionMarketing={optionMarketing}
            baseInformation={baseUserData}
            pageTitle={pageTitle}
            content={content}
          ></ContentGateWrapperAnonymous>
        </Features>
        <PageTemplate content={content} />
      </>
    );
  }
  if ((promotional || promotionalWebinar?.value) && !acceptedPromtionalConsent) {
    return <ContentGate type='promotional' />;
  }

  return <PageTemplate content={content} />;
};

DocCheckCMS.propTypes = {
  content: PropTypes.objectOf(PropTypes.any),
  templateType: PropTypes.string,
};

export { DocCheckCMS };
