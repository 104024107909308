import React from "react";
import { useSelector } from "react-redux";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import { Form } from "components/shared/form/Form";
import {
  INPUT,
  BUTTON,
  COMPONENT,
  RADIO,
  ACCOUNT_DETAILS,
  SHOULD_HAVE_VALUE,
  SHOULD_MATCH_FIELD,
  DROPDOWN_DEFAULT,
  commonEmailValidations,
  commonShouldHaveValue,
  SHOULD_BE_EMAIL_FORMAT,
  ROLE_DROPDOWN,
  SHOULD_ACCEPT_CONSENT,
  SHOULD_CONTAIN_ONLY_NUMBER,
  SHOULD_HAVE_VALUE_RADIO,
} from "components/shared/form/formConstants";
import { AnonymousFormConsent } from "./AnonymousFormConsent";
import { PROFILE_SUCCESS_PATH } from "routes/routeConstants";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import { TermsLabel } from "components/pageTemplates/login/Terms-label";
import { PrivacyConsent } from "components/pageTemplates/login/PrivacyConsent";
import { MarketingLabel } from "components/pageTemplates/login/Marketing-label";
import { MarketingConsent } from "components/pageTemplates/login/MarketingConsent";
import "./AnonymousForm.scss";
const AnonymousForm = ({ actions, isSubmitting }) => {
  const {
    tEmail,
    tEnterYourEmail,
    tFirstName,
    tEnterYourFirstName,
    tLastName,
    tEnterYourLastName,
    tIConsentTo,
    tSave,
    tSpeciality,
    tAccountDetails,
    tThisFieldIsRequired,
    tEmailsDoNotMatch,
    tInvalidEmail,
    tConfirmEmail,
    tMandatory,
    tRoleLabel,
    tJobTitleLabel,
    jobTitlePlaceholder,
    tDepartmentLabel,
    tDepartmentPlaceholder,
    tPracticePhoneNumberLabel,
    tPracticePhoneNumberPlaceholder,
    tDepartmentPhoneNumberLabel,
    tDepartmentPhoneNumberPlaceholder,
    anonymousFlowFormTitle,
    mustContainOnlyNumber,
    tWorkplace,
    tEnterYourWorkplace,
    featureSwitchDisplayNewFormFields,
    tmarketingPreferenceErrorMessage,
    disableMarketingConsent
  } = useWebsiteConfigObject();
  const { trackEventWithContentType } = useGoogleAnalytics();

  const EMAIL = "email";

  const getAccountDetailsFormFields = ({
    email,
    firstname,
    lastname,
    department,
    phone,
    departmentPhone,
    promotionalMaterialsOptIn,
    jobTitle,
    role,
    speciality,
    workplace,
  }) => [
    {
      label: tFirstName,
      id: "first-name",
      initialValue: firstname || "",
      size: 50,
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: tEnterYourFirstName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tLastName,
      id: "last-name",
      initialValue: lastname || "",
      type: INPUT,
      size: 50,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "family-name",
        placeholder: tEnterYourLastName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tEmail,
      id: "email",
      initialValue: "",
      mandatory: true,
      size: 50,
      type: INPUT,
      typeParams: {
        type: "text",
        autoComplete: "email",
        placeholder: tEnterYourEmail,
      },
      validation: [
        ...commonEmailValidations,
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
        {
          validationMethodKey: SHOULD_BE_EMAIL_FORMAT,
          errorText: tInvalidEmail,
        },
      ],
    },
    {
      label: tRoleLabel,
      id: "role",
      initialValue: role || "",
      type: ROLE_DROPDOWN,
      mandatory: true,
      size: 50,
      typeParams: {
        type: "anonymous"
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tSpeciality,
      id: "speciality",
      initialValue: speciality || "",
      type: DROPDOWN_DEFAULT,
      mandatory: true,
      size: 50,
      typeParams: {
        type: "anonymous"
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tJobTitleLabel,
      id: "jobtitle",
      initialValue: jobTitle || "",
      type: INPUT,
      mandatory: false,
      size: 50,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: jobTitlePlaceholder,
      },
      validation: [],
    },
    {
      label: tWorkplace,
      id: "organization-title",
      initialValue: workplace || "",
      type: INPUT,
      mandatory: true,
      size: 50,
      typeParams: {
        type: "text",
        autoComplete: "organization",
        placeholder: tEnterYourWorkplace,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tDepartmentLabel,
      id: "department",
      initialValue: department || "",
      type: INPUT,
      mandatory: false,
      size: 50,
      typeParams: {
        type: "text",
        autoComplete: "department",
        placeholder: tDepartmentPlaceholder,
      },
      validation: [],
    },
    {
      label: tDepartmentPhoneNumberLabel,
      id: "departmentPhone",
      initialValue: departmentPhone || "",
      type: INPUT,
      mandatory: false,
      size: 50,
      typeParams: {
        type: "numeric",
        autoComplete: "phone-number",
        placeholder: tDepartmentPhoneNumberPlaceholder,
      },
      validation: [
        {
          validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
          errorText: mustContainOnlyNumber ? mustContainOnlyNumber : "Should contain only number",
        },
      ],
    },
    {
      label: tPracticePhoneNumberLabel,
      id: "phone",
      initialValue: phone || "",
      type: INPUT,
      mandatory: false,
      size: 50,
      typeParams: {
        type: "numeric",
        autoComplete: "phone-number",
        placeholder: tPracticePhoneNumberPlaceholder,
      },
      validation: [
        {
          validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
          errorText: mustContainOnlyNumber ? mustContainOnlyNumber : "Should contain only number",
        },
      ],
    },
    {
      id: "terms-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: TermsLabel,
      },
    },
    {
      id: "privacy-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: PrivacyConsent,
      },
    },
    {
      label: "",
      id: "privacy-consent",
      initialValue: "",
      type: RADIO,
      mandatory: true,
      typeParams: {},
      validation: [{
        validationMethodKey: SHOULD_ACCEPT_CONSENT,
        errorText: tThisFieldIsRequired ? tThisFieldIsRequired : 'This field is required',
      }],
    },
    {
      id: "marketing-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingLabel,
      },
      disabled: disableMarketingConsent
    },
    {
      id: "marketing-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingConsent,
      },
      disabled: disableMarketingConsent
    },
    {
      label: "",
      id: "information-consent",
      initialValue: disableMarketingConsent ? null : false,
      type: RADIO,
      // mandatory: true,
      typeParams: {},
      // validation: [{
      //   validationMethodKey: SHOULD_HAVE_VALUE_RADIO,
      //   errorText: tmarketingPreferenceErrorMessage ? tmarketingPreferenceErrorMessage : 'Please select either "Yes" or "No"',
      // }],
      disabled: disableMarketingConsent
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          trackEventWithContentType("profile", "save");
          actions.anonymousUserRegistration(formValues);
        },
        text: tSave ? tSave.toUpperCase() : "",
        type: "submit",
      },
    },
  ];
  const getAccountDetailsFormFieldsOld = ({
    email,
    firstname,
    lastname,
    department,
    phone,
    departmentPhone,
    promotionalMaterialsOptIn,
    jobTitle,
    role,
    speciality,
    workplace,
  }) => [
    {
      label: tFirstName,
      id: "first-name",
      initialValue: firstname || "",
      size: 50,
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: tEnterYourFirstName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tLastName,
      id: "last-name",
      initialValue: lastname || "",
      type: INPUT,
      size: 50,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "family-name",
        placeholder: tEnterYourLastName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tEmail,
      id: "email",
      initialValue: "",
      mandatory: true,
      size: 50,
      type: INPUT,
      typeParams: {
        type: "text",
        autoComplete: "email",
        placeholder: tEnterYourEmail,
      },
      validation: [
        ...commonEmailValidations,
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tSpeciality,
      id: "speciality",
      initialValue: speciality || "",
      type: DROPDOWN_DEFAULT,
      mandatory: true,
      size: 50,
      typeParams: {
        type: "anonymous"
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tWorkplace,
      id: "organization-title",
      initialValue: workplace || "",
      type: INPUT,
      mandatory: true,
      size: 50,
      typeParams: {
        type: "text",
        autoComplete: "organization",
        placeholder: tEnterYourWorkplace,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      id: "terms-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: TermsLabel,
      },
    },
    {
      id: "privacy-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: PrivacyConsent,
      },
    },
    {
      label: "",
      id: "privacy-consent",
      initialValue: "",
      type: RADIO,
      mandatory: true,
      typeParams: {},
      validation: [{
        validationMethodKey: SHOULD_ACCEPT_CONSENT,
        errorText: tThisFieldIsRequired ? tThisFieldIsRequired : 'This field is required',
      }],
    },
    {
      id: "marketing-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingLabel,
      },
      disabled: disableMarketingConsent
    },
    {
      id: "marketing-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingConsent,
      },
      disabled: disableMarketingConsent
    },
    {
      label: "",
      id: "information-consent",
      initialValue: disableMarketingConsent ? null : false,
      type: RADIO,
      // mandatory: true,
      typeParams: {},
      // validation: [{
      //   validationMethodKey: SHOULD_HAVE_VALUE_RADIO,
      //   errorText: tThisFieldIsRequired ? tThisFieldIsRequired : 'This field is required',
      // }],
      disabled: disableMarketingConsent
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          trackEventWithContentType("profile", "save");
          actions.anonymousUserRegistration(formValues);
        },
        text: tSave ? tSave.toUpperCase() : "",
        type: "submit",
      },
    },
  ];
  const { fetching, details } = useSelector((state) => state.user);

  const formFields = featureSwitchDisplayNewFormFields ? getAccountDetailsFormFields(details) : getAccountDetailsFormFieldsOld(details);

  const anonymousUserRegistration = actions.anonymousUserRegistration;
  return (
    <div className="anonymous-form">
      <h3 className="anonymous-form__title">{anonymousFlowFormTitle}</h3>
      <Form
        fields={formFields}
        formId={ACCOUNT_DETAILS}
        actions={{ anonymousUserRegistration }}
        isSubmitting={isSubmitting}
      />
      <div className="anonymous-form__mandatory">
        {tMandatory || "Mandatory"}
      </div>
    </div>
  );
};

export { AnonymousForm };
