import { getSelectionValue, getSelectionValues } from 'utils/contentUtils';
import { useLocationSearchParameter } from 'utils/urlUtils';

// constants for the therapy sub area values defined in acoustic
const MEDICAL_EDUCATION = 'Medical education';
const DISEASE_PAGES = 'Disease pages';
// Client now wants Medical applications -> Medical resources. Not sure if it matters what it's called in accoustic though
const MEDICAL_APPLICATIONS = 'Medical applications';

const THERAPY_SUB_AREA_PARAM = 'area';

// util function to filter content items by therapySubArea
const filterContentItemsByTherapySubArea = (items, therapySubArea) => {
  // if no sub area filter provide, do not filter
  if (!therapySubArea) { return items; }
  return items?.filter((item) => {
    const { therapySubAreas } = item.elements;
    const subAreaValues = getSelectionValues(therapySubAreas);
    // only include items associated with the passed in therapySubArea
    return subAreaValues ? subAreaValues.includes(therapySubArea) : false;
  });
};

// add a get parameter to the path for the therapySubArea element passed in
// E.G `/article1` => `/article1?area=Medical education`
const addSubareaParameterToPath = (path, therapySubArea) => {
  if (!therapySubArea) { return path; }
  const subAreaValue = getSelectionValue(therapySubArea);
  return `${path}?${THERAPY_SUB_AREA_PARAM}=${subAreaValue}`;
};

const useSubareaParameterFromPath = () => useLocationSearchParameter(THERAPY_SUB_AREA_PARAM);

export {
  MEDICAL_EDUCATION,
  DISEASE_PAGES,
  MEDICAL_APPLICATIONS,
  filterContentItemsByTherapySubArea,
  addSubareaParameterToPath,
  useSubareaParameterFromPath,
};
