const EDETAILER_COMPONENT = 'Component - Edetailer'; // testing with acoustic
const THIRD_PARTY_COMPONENT ='Page - Third-Party';
const COURSE_LIST_COMPONENT = 'Page - Course-List';
const COURSE_DETAIL_COMPONENT ='Page - Course-Detail';
const CHATBOT_COMPONENT = 'Component - Chatbot';    
const RATING_COMPONENT = 'content-sentiment';
const TEALIUM = 'tealium';
const GRAPHICAL_BANNER = 'Component - Graphical Banner';
const GIOSG = 'Component - Giosg';
const SFMC = 'Component - SFMC';

export {
    EDETAILER_COMPONENT,
    THIRD_PARTY_COMPONENT,
    COURSE_LIST_COMPONENT,
    COURSE_DETAIL_COMPONENT,
    CHATBOT_COMPONENT,
    RATING_COMPONENT,
    TEALIUM,
    GRAPHICAL_BANNER,
    GIOSG,
    SFMC
}