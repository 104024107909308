import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import { MarketingLabel } from "components/pageTemplates/login/Marketing-label";
import { MarketingConsent } from "components/pageTemplates/login/MarketingConsent";
import { userActions } from "redux/user/slice";
import { Form } from "components/shared/form/Form";
import {
  INPUT,
  BUTTON,
  CHECKBOX,
  COMPONENT,
  RADIO,
  ACCOUNT_DETAILS,
  SHOULD_HAVE_VALUE,
  DROPDOWN_DEFAULT,
  ROLE_DROPDOWN,
  SHOULD_CONTAIN_ONLY_NUMBER,
  commonShouldHaveValue,
  SHOULD_HAVE_VALUE_RADIO,
} from "components/shared/form/formConstants";
import { WithdrawConsent } from "components/pageTemplates/login/WithdrawConsent";
import { PROFILE_SUCCESS_PATH, PROFILE_PAGE_PATH } from "routes/routeConstants";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import { useAuthService } from 'utils/authUtils';

import "./AccountDetailsFormMyHub.scss";
const AccountDetailsFormMyHub = () => {
  const {
    tEmail,
    tEnterYourEmail,
    tSalutation,
    tEnterYourTitle,
    tFirstName,
    tEnterYourFirstName,
    tLastName,
    tEnterYourLastName,
    tSpeciality,
    tEnterYourSpeciality,
    tWorkplace,
    tEnterYourWorkplace,
    tIConsentTo,
    tSave,
    tAccountDetails,
    tCheckAndChange,
    tThisFieldIsRequired,
    tRoleLabel,
    tRoleValues,
    tJobTitleLabel,
    jobTitlePlaceholder,
    tDepartmentLabel,
    tDepartmentPlaceholder,
    tPracticePhoneNumberLabel,
    tPracticePhoneNumberPlaceholder,
    tDepartmentPhoneNumberLabel,
    tDepartmentPhoneNumberPlaceholder,
    featureMarketingPreferenceActivation,
    featureSwitchDisplayNewFormFields,
    disableMarketingConsent
  } = useWebsiteConfigObject();
  const { trackEventWithContentType } = useGoogleAnalytics();
  let featureMandatory = featureMarketingPreferenceActivation;
  let boolOptionMarketing = false;
  let boolMandatory = false;
  const { fetching, details } = useSelector((state) => state.user);
  const history = useHistory();
  const service = useAuthService();

  let type = history.location?.state?.state || null;

  const isAnonymous = details.username != details.email;
  const dispatch = useDispatch();
  let detailsChange = details;
  if (type && type.type === "Anonymous-user-flow") {
    if (featureMandatory === true) {
      boolOptionMarketing = null;
      boolMandatory = true;
    }
  }

  if (featureMarketingPreferenceActivation) {
    detailsChange = JSON.parse(JSON.stringify(details));
    detailsChange.email = detailsChange.username || detailsChange.email;
  }

  const getAccountDetailsFormFieldsOld = ({
    email,
    salutation,
    firstname,
    lastname,
    speciality,
    workplace,
    promotionalMaterialsOptIn,
  }) =>
  [
    {
      label: tEmail,
      id: "email",
      initialValue: `${ (email !== details?.username ? details?.username : email) || "" }`,
      type: INPUT,
      typeParams: {
        type: "text",
        autoComplete: "email",
        // placeholder: tEnterYourEmail, removed as per ticket 838 and figma
        readOnly: true,
      },
    },
    {
      label: tSalutation,
      id: "salutation",
      initialValue: salutation || "",
      type: INPUT, // Designs have drop down but client say they want it as an input
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "honorific-prefix",
        placeholder: tEnterYourTitle,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tFirstName,
      id: "first-name",
      initialValue: firstname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: tEnterYourFirstName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tLastName,
      id: "last-name",
      initialValue: lastname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "family-name",
        placeholder: tEnterYourLastName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tSpeciality,
      id: "speciality",
      initialValue: speciality || "",
      type: DROPDOWN_DEFAULT,
      mandatory: true,
      typeParams: {
        type: "profile",
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tWorkplace,
      id: "organization-title",
      initialValue: workplace || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "organization",
        placeholder: tEnterYourWorkplace,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      id: "marketing-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingLabel,
      },
      disabled: promotionalMaterialsOptIn === null
    },
    {
      id: "marketing-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingConsent,
      },
      disabled: promotionalMaterialsOptIn === null
    },
    {
      label: "",
      id: 'information-consent',
      initialValue: promotionalMaterialsOptIn ?? null,
      type: RADIO,
      mandatory: false,
      typeParams: {},
      disabled: disableMarketingConsent|| promotionalMaterialsOptIn === null
    },
    {
      id: "information-consents",
      type: COMPONENT,
      typeParams: {
        PropComponent: WithdrawConsent,
      },
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          trackEventWithContentType("profile", "save");
          actions.updateDetails(formValues);
        },
        text: tSave ? tSave.toUpperCase() : "",
        scrollToError: true,
        type: "submit",
      },
    },
  ];
  const getAccountDetailsFormFields = ({
    email,
    salutation,
    firstname,
    lastname,
    speciality,
    workplace,
    role,
    jobTitle,
    departmentPhone,
    department,
    phone,
    promotionalMaterialsOptIn,
  }) => [
    {
      label: tEmail,
      id: "email",
      initialValue: `${ (email !== details?.username ? details?.username : email) || "" }`,
      type: INPUT,
      typeParams: {
        type: "text",
        autoComplete: "email",
        // placeholder: tEnterYourEmail, removed as per ticket 838 and figma
        readOnly: true,
      },
    },
    {
      label: tSalutation,
      id: "salutation",
      initialValue: salutation || "",
      type: INPUT, // Designs have drop down but client say they want it as an input
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "honorific-prefix",
        placeholder: tEnterYourTitle,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tFirstName,
      id: "first-name",
      initialValue: firstname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: tEnterYourFirstName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tLastName,
      id: "last-name",
      initialValue: lastname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "family-name",
        placeholder: tEnterYourLastName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tRoleLabel,
      id: "role",
      initialValue: role ? role : "",
      type: ROLE_DROPDOWN,
      mandatory: true,
      typeParams: {
        type: "profile",
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tSpeciality,
      id: "speciality",
      initialValue: speciality || "",
      type: DROPDOWN_DEFAULT,
      mandatory: true,
      typeParams: {
        type: "profile",
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tJobTitleLabel,
      id: "jobtitle",
      initialValue: jobTitle || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "text",
        placeholder: jobTitlePlaceholder,
      },
      validation: [],
    },
    {
      label: tWorkplace,
      id: "organization-title",
      initialValue: workplace || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "organization",
        placeholder: tEnterYourWorkplace,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tDepartmentLabel,
      id: "department",
      initialValue: department || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "text",
        autoComplete: "department",
        placeholder: tDepartmentPlaceholder,
      },
      validation: [
      ],
    },
    {
      label: tDepartmentPhoneNumberLabel,
      id: "departmentPhone",
      initialValue: departmentPhone || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "numeric",
        autoComplete: "phone-number",
        placeholder: tDepartmentPhoneNumberPlaceholder,
      },
      validation: [
        {
          validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
          errorText: "Should contain only number",
        },
      ],
    },
    {
      label: tPracticePhoneNumberLabel,
      id: "phone",
      initialValue: phone || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "numeric",
        autoComplete: "phone-number",
        placeholder: tPracticePhoneNumberPlaceholder,
      },
      validation: [
        {
          validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
          errorText: "Should contain only number",
        },
      ],
    },
    {
      id: "marketing-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingLabel,
      },
      disabled: promotionalMaterialsOptIn === null
    },
    {
      id: "marketing-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingConsent,
      },
      disabled: promotionalMaterialsOptIn === null
    },
    {
      label: "",
      id: 'information-consent',
      initialValue: promotionalMaterialsOptIn ?? null,
      type: RADIO,
      // mandatory: true,
      typeParams: {},
      // validation:[
      //   {
      //     validationMethodKey: SHOULD_HAVE_VALUE_RADIO,
      //     errorText: tThisFieldIsRequired,
      //   },
      // ],
      disabled: promotionalMaterialsOptIn === null
    },
    {
      id: "information-consents",
      type: COMPONENT,
      typeParams: {
        PropComponent: WithdrawConsent,
      },
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          trackEventWithContentType("profile", "save");
          actions.updateDetails(formValues);
        },
        scrollToError: true,
        text: tSave ? tSave.toUpperCase() : "",
        type: "submit",
      },
    },
  ];
  const formFields = featureSwitchDisplayNewFormFields ? getAccountDetailsFormFields(detailsChange) : getAccountDetailsFormFieldsOld(detailsChange);
  const updateDetails = (formData) => {
    if (
      featureMarketingPreferenceActivation &&
      type &&
      type.type === "Anonymous-user-flow"
    ) {
      if (formData["information-consent"]) {
        dispatch(userActions.global.details.update(formData)).then(() => {
          history.push({ pathname: type.redirect }, { state: null });
        });
      } else {
        history.push(
          { pathname: PROFILE_PAGE_PATH },
          {
            state: {
              type: "Anonymous-user-flow",
              redirect: type.redirect,
              openTooltip: true,
            },
          }
        );
      }
    } else {
      dispatch(userActions.global.details.update(formData)).then(() => {
        history.push(PROFILE_SUCCESS_PATH);
      });
    }
  };
  return (
    <div className="profile-form-content my-hub-form">
      <h3 className="account-details">{tAccountDetails}</h3>
      <p className="check-details">{tCheckAndChange}</p>
      <div className="my-hub-divider1"></div>
      <Form
        fields={formFields}
        formId={ACCOUNT_DETAILS}
        actions={{ updateDetails }}
        isSubmitting={fetching}
      />
    </div>
  );
};

export { AccountDetailsFormMyHub };
