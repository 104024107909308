import React, {useRef} from 'react'
import Equalizer from 'react-equalizer'
import Slider from 'react-slick'
import isEmpty from 'lodash.isempty'
import { useSelector } from 'react-redux';

// modules
import TitleCard from '../titleCard/TitleCard'
import ContentCard from '../contentCard/ContentCard'

// components
import Image from '../../components/reusableComponents/image/Image'
import { ChevronLeftCarousel, ChevronRightCarousel } from '../../components/shared/SvgIcons'
import WebinarCard from 'modules/webinarCard/WebinarCard'

// hooks
import useMultipleRefs from 'hooks/useMultipleRefs'
import useResponsive from 'hooks/useResponsive'

// styles
import './Carousel.scss'
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import { getAssetUrl } from 'api/acousticApi'
import { getSelectionValue, getValue } from 'utils/contentUtils'
import VideoCard from 'modules/videoCard/VideoCard';

const Carousel = ({ settings, data, theme, componentType, alternateCardBg, mobileGap, tabletGap, desktopGap,brandCoulours }) => {
    const { isDesktopUp, isTabletOnly } = useResponsive()
    const { redesign } = useSelector(state => state.ui);

    const { tLearnMore } = useWebsiteConfigObject();
    const sliderRef = useRef(null);

    const brandColor = getValue(brandCoulours);
    const brandColourStyle = {
        backgroundColor: `${brandColor?.elements?.videoCardCarouselBackgroundColor?.value}`
    };

    // Default Settings
    // For more settings : https://react-slick.neostack.com/docs/api/
    const defaultSettings = {
        dots: true,
        appendDots: (dots) => (
            <ul style={{ display: 'block', border: 'red' }} onClick={() => sliderRef?.current?.slickPause()}> {dots} </ul>
        ),
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SlickArrowRight handleClick={() => { sliderRef?.current?.slickPause(); sliderRef?.current?.slickNext()}} slidesToShow={ settings?.slidesToShow ? settings.slidesToShow : 1 } />,
        prevArrow: <SlickArrowLeft handleClick={() => { sliderRef?.current?.slickPause(); sliderRef?.current?.slickPrev()}} />,
        pauseOnHover: true,
        pauseOnDotsHover: true,
    }

    // Merge settings
    const _settings = !isEmpty(settings) ? { ...defaultSettings, ...settings } : { ...defaultSettings }

    // Get length of carousel slides
    const carouselLength = data?.length

    // Create multiple refs based on an array length
    const { elementRefs: cardRefs } = useMultipleRefs({ length: carouselLength })

    // Set the gap between slides from the props
    const gap = isDesktopUp ? desktopGap ?? tabletGap ?? mobileGap ?? 0 : isTabletOnly ? tabletGap ?? mobileGap ?? 0 : mobileGap ?? 0

    if (!data) {
        return null
    }
    if(redesign){
        return (
            <div className={`Carousel ${theme ? `Carousel--theme-${theme}` : ''} ${componentType ? `Carousel--type-${componentType}` : ''}`}>
                <div className='Carousel_Container'>
                    {(componentType === 'cards' && (
                        <Equalizer nodes={cardRefs?.current[0]?.current ? () => cardRefs?.current?.map((ref) => ref?.current ) : () => []} >
                            <Slider ref={sliderRef}  {..._settings}>
                                {data?.map((slide, i) => {
                                    let href = slide?.elements?.link?.linkURL || (slide?.elements?.link?.values ? slide?.elements?.link?.values[0]?.linkURL : null) || slide?.elements?.urlSlug?.value;
                                    const assetUrl = getAssetUrl(slide?.elements?.fileAsset);
                                    href = assetUrl ? assetUrl : href;
                                    let cardType = getSelectionValue(slide?.elements?.cardType);
                                    let linkText = null;
                                    if(slide?.elements?.link?.values){
                                        if(slide?.elements?.link?.values[0]?.linkText && slide?.elements?.link?.values[0]?.linkText.trim() != ""){
                                            linkText = slide?.elements?.link?.values[0]?.linkText;
                                        }
                                    }
                                    if (cardType) {
                                        if(cardType==="VideoCard"){
                                            if(slide?.elements?.videoContent?.value){
                                                const videoCard = slide?.elements?.videoContent?.value?.elements;
                                                const href = videoCard?.videoCardLink?.linkURL;
                                                return (
                                                    <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                                        <VideoCard
                                                            href={href}
                                                            content={videoCard?.videoCardContent?.value?.text}
                                                            maxWidth={isTabletOnly ? 400 : null}
                                                            heading={videoCard?.videoCardHeading?.value}
                                                            subHeading={videoCard?.videoCardSubheading?.value}
                                                            image={videoCard?.videoCardImage}
                                                            style={brandColourStyle}
                                                            video={videoCard?.videoCardVideo}
                                                            webvttChaptersFile={videoCard?.webvttChaptersFile}
                                                            subtitles={videoCard?.subtitles}
                                                            videoPoster={videoCard?.videoPoster}
                                                            videoPosterImage={videoCard?.videoPosterImage}
                                                             externalVideoLink={slide?.elements?.externalVideoLink?.value ? slide?.elements?.externalVideoLink : videoCard?.externalVideoLink}
                                                             fileAsset={slide?.elements?.fileAsset}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                                    <VideoCard
                                                        href={href}
                                                        content={slide?.elements?.text?.value?.text || slide?.elements?.text}
                                                        maxWidth={isTabletOnly ? 400 : null}
                                                        heading={slide?.elements?.title?.value}
                                                        image={slide?.elements?.image}
                                                        style={brandColourStyle}
                                                        linkText={slide?.elements?.link?.linkText || (linkText ? linkText : null) || tLearnMore}
                                                        video={slide?.elements?.video}
                                                        externalVideoLink={slide?.elements?.externalVideoLink}
                                                        fileAsset={slide?.elements?.fileAsset}
                                                    />
                                                </div>
                                            )
                                        }
                                        return (
                                            <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                                <ContentCard
                                                    ref={{ cardRef: cardRefs.current[i] }}
                                                    theme={theme}
                                                    category={cardType === "IconCard" ? slide?.elements?.category?.value : ""}
                                                    brandLogo={slide?.elements.brandLogo || slide?.elements.logoImage}
                                                    heading={!(cardType === "ImageCard") ? slide?.elements?.title?.value : ""}
                                                    icon={cardType === "IconCard" ? slide?.elements.icon : ""}
                                                    content={slide?.elements?.text?.value?.text || slide?.elements?.text}
                                                    bgImage={ cardType === "ImageCard" ? slide?.elements.image : ""}
                                                    href={href}
                                                    alternateBg={alternateCardBg}
                                                    linkText={slide?.elements?.link?.linkText || (linkText ? linkText : null) || tLearnMore}
                                                    maxWidth={isTabletOnly ? 400 : null}
                                                    center={isTabletOnly}
                                                    date={slide?.elements?.webinarDate || ""}
                                                    clickable
                                                    fileAsset={slide?.elements?.fileAsset}
                                                />
                                            </div>
                                        )
                                    }
                                    
                                    else {
                                        return (
                                            <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                                <ContentCard
                                                    ref={{ cardRef: cardRefs.current[i] }}
                                                    theme={theme}
                                                    category={slide?.elements?.category?.value}
                                                    brandLogo={slide?.elements.brandLogo || slide?.elements.logoImage}
                                                    heading={slide?.elements?.title?.value}
                                                    icon={slide?.elements.icon}
                                                    content={slide?.elements?.text?.value?.text || slide?.elements?.text}
                                                    bgImage={slide?.elements.image}
                                                    href={href}
                                                    alternateBg={alternateCardBg}
                                                    linkText={slide?.elements?.link?.linkText || (linkText ? linkText : null)  || tLearnMore}
                                                    maxWidth={isTabletOnly ? 400 : null}
                                                    center={isTabletOnly}
                                                    date={slide?.elements?.webinarDate || ""}
                                                    clickable
                                                    fileAsset={slide?.elements?.fileAsset}
                                                />
                                            </div>
                                        )
                                    }
                                    
                                })}
                            </Slider>
                        </Equalizer>
                    )) || (
                        <Slider ref={sliderRef} {..._settings}>
                            {data?.map((slide, i) => {
                                const assetUrl = getAssetUrl(slide?.elements?.fileAsset);
                                let linkText = null;
                                if(slide?.elements?.link?.values){
                                    if(slide?.elements?.link?.values[0]?.linkText && slide?.elements?.link?.values[0]?.linkText.trim() != ""){
                                        linkText = slide?.elements?.link?.values[0]?.linkText;
                                    }
                                }
                                return (<div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                    {(componentType === 'contents' || componentType === 'slides') && (
                                        <>
                                            {(slide?.elements?.image?.url || slide?.url) && (
                                                <div className='Carousel_Slide_Image'>
                                                    <Image data={slide?.elements?.image || slide} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {componentType === 'title-cards' && (
                                        <TitleCard
                                            heading={slide?.elements?.link?.linkText || (linkText ? linkText : null)}
                                            href={assetUrl ? assetUrl : slide?.elements?.link?.linkURL || (slide?.elements?.link?.values ? slide?.elements?.link?.values[0]?.linkURL : null)}
                                            image={slide.elements.image}
                                            maxWidth={isTabletOnly ? 400 : null}
                                            center={isTabletOnly}
                                            bgColor="#fff"
                                            clickable
                                            fileAsset={slide?.elements?.fileAsset}
                                        />
                                    )}
                                    {componentType === 'webinar-card' && (
                                        <WebinarCard
                                            data={slide}
                                            heading={slide?.elements?.title?.value}
                                            href={slide?.elements?.urlSlug?.value}
                                            image={slide?.elements?.webinarImage}
                                            webinarDate={slide?.elements?.webinarDate}
                                            webinarTime={slide?.elements?.webinarTime}
                                            excerpt={slide?.description || slide?.elements?.basePage?.value?.metaDescWithHtml}
                                        />
                                    )}
                                </div>)
                            }
                            )}
                        </Slider>
                    )}
                </div>
            </div>
        )
    }
    return (
        <div className={`Carousel ${theme ? `Carousel--theme-${theme}` : ''} ${componentType ? `Carousel--type-${componentType}` : ''}`}>
            <div className='Carousel_Container'>
                {(componentType === 'cards' && (
                    <Equalizer nodes={cardRefs?.current[0]?.current ? () => cardRefs?.current?.map((ref) => ref?.current ) : () => []} >
                        <Slider ref={sliderRef}  {..._settings}>
                            {data?.map((slide, i) => {
                                let href = slide?.elements?.link?.linkURL || (slide?.elements?.link?.values ? slide?.elements?.link?.values[0]?.linkURL : null) || slide?.elements?.urlSlug?.value;
                                const assetUrl = getAssetUrl(slide?.elements?.fileAsset);
                                href = assetUrl ? assetUrl : href;
                                let cardType = getSelectionValue(slide?.elements?.cardType);


                                let linkText = null;
                                if(slide?.elements?.link?.values){
                                    if(slide?.elements?.link?.values[0]?.linkText && slide?.elements?.link?.values[0]?.linkText.trim() != ""){
                                        linkText = slide?.elements?.link?.values[0]?.linkText;
                                    }
                                }
                                if(cardType && cardType==="VideoCard"){
                                    if(slide?.elements?.videoContent?.value){
                                        const videoCard = slide?.elements?.videoContent?.value?.elements;

                                        const href = videoCard?.videoCardLink?.linkURL;
                                        return (
                                            <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                                <VideoCard
                                                    href={href}
                                                    content={videoCard?.videoCardContent?.value?.text}
                                                    maxWidth={isTabletOnly ? 400 : null}
                                                    heading={videoCard?.videoCardHeading?.value}
                                                    subHeading={videoCard?.videoCardSubheading?.value}
                                                    image={videoCard?.videoCardImage}
                                                    style={brandColourStyle}
                                                    video={videoCard?.videoCardVideo}
                                                    webvttChaptersFile={videoCard?.webvttChaptersFile}
                                                    subtitles={videoCard?.subtitles}
                                                    videoPoster={videoCard?.videoPoster}
                                                    videoPosterImage={videoCard?.videoPosterImage}
                                                    externalVideoLink={slide?.elements?.externalVideoLink?.value ? slide?.elements?.externalVideoLink : videoCard?.externalVideoLink}
                                                    fileAsset={slide?.elements?.fileAsset}
                                                />
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                            <VideoCard
                                                href={href}
                                                content={slide?.elements?.text?.value?.text || slide?.elements?.text}
                                                maxWidth={isTabletOnly ? 400 : null}
                                                heading={slide?.elements?.title?.value}
                                                style={brandColourStyle}
                                                image={slide?.elements?.image}
                                                linkText={slide?.elements?.link?.linkText || (linkText ? linkText : null) || tLearnMore}
                                                video={slide?.elements?.video}
                                                externalVideoLink={slide?.elements?.externalVideoLink}
                                                fileAsset={slide?.elements?.fileAsset}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                        <ContentCard
                                            ref={{ cardRef: cardRefs.current[i] }}
                                            theme={theme}
                                            category={slide?.elements?.category?.value}
                                            brandLogo={slide?.elements.brandLogo || slide?.elements.logoImage}
                                            heading={slide?.elements?.title?.value}
                                            icon={slide?.elements.icon}
                                            content={slide?.elements?.text?.value?.text || slide?.elements?.text}
                                            bgImage={slide?.elements.image}
                                            href={href}
                                            alternateBg={alternateCardBg}
                                            linkText={slide?.elements?.link?.linkText || (linkText ? linkText : null) || tLearnMore}
                                            maxWidth={isTabletOnly ? 400 : null}
                                            center={isTabletOnly}
                                            date={slide?.elements?.webinarDate || ""}
                                            clickable
                                            fileAsset={slide?.elements?.fileAsset}
                                        />
                                    </div>
                                )
                            })}
                        </Slider>
                    </Equalizer>
                )) || (
                    <Slider ref={sliderRef} {..._settings}>
                        {data?.map((slide, i) => {
                            const assetUrl = getAssetUrl(slide?.elements?.fileAsset);
                            let linkText = null;
                            if(slide?.elements?.link?.values){
                                if(slide?.elements?.link?.values[0]?.linkText && slide?.elements?.link?.values[0]?.linkText.trim() != ""){
                                    linkText = slide?.elements?.link?.values[0]?.linkText;
                                }
                            }
                            return (<div key={`${i} - ${slide?.id}`} className={`Carousel_Item Carousel_Item--gap-${gap}`}>
                                {(componentType === 'contents' || componentType === 'slides') && (
                                    <>
                                        {(slide?.elements?.image?.url || slide?.url) && (
                                            <div className='Carousel_Slide_Image'>
                                                <Image data={slide?.elements?.image || slide} />
                                            </div>
                                        )}
                                    </>
                                )}
                                {componentType === 'title-cards' && (
                                    <TitleCard
                                        heading={slide?.elements?.link?.linkText || (linkText ? linkText : null)}
                                        href={assetUrl ? assetUrl : slide?.elements?.link?.linkURL || (slide?.elements?.link?.values ? slide?.elements?.link?.values[0]?.linkURL : null)}
                                        image={slide.elements.image}
                                        maxWidth={isTabletOnly ? 400 : null}
                                        center={isTabletOnly}
                                        fileAsset={slide?.elements?.fileAsset}
                                        clickable
                                    />
                                )}
                                {componentType === 'webinar-card' && (
                                    <WebinarCard
                                        data={slide}
                                        heading={slide?.elements?.title?.value}
                                        href={slide?.elements?.urlSlug?.value}
                                        image={slide?.elements?.webinarImage}
                                        webinarDate={slide?.elements?.webinarDate}
                                        webinarTime={slide?.elements?.webinarTime}
                                        excerpt={slide?.description || slide?.elements?.basePage?.value?.metaDescWithHtml}
                                    />
                                )}
                            </div>)
                        }
                        )}
                    </Slider>
                )}
            </div>
        </div>
    )

}

// Left Arrow
const SlickArrowLeft = ({ currentSlide, slideCount, handleClick, ...props }) => (
    <button {...props}  onClick={handleClick} className={'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')} aria-hidden='true' aria-disabled={currentSlide === 0 ? true : false} type='button'>
        <ChevronLeftCarousel />
    </button>
)

// Right Arrow
const SlickArrowRight = ({ currentSlide, slideCount, handleClick, slidesToShow, ...props }) => (
    <button
        {...props}
        className={'slick-next slick-arrow' + (currentSlide === slideCount - slidesToShow ? ' slick-disabled' : '')}
        aria-hidden='true'
        aria-disabled={currentSlide === slideCount - slidesToShow ? true : false}
        type='button'
        onClick={handleClick}
        data-vv-action="click"
        data-vv-count={Math.ceil(slideCount/slidesToShow)}
        data-vv-snapshot="before"
    >
        <ChevronRightCarousel />
    </button>
)

export default Carousel
