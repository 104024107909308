import { AppConfig } from 'AppConfig';
import axios from 'axios';
import http from 'http';
import https from 'https';

const {
  timeout,
  maxRedirects,
  maxContentLength,
} = AppConfig.axios;
const {
  baseAuthUser,
  baseAuthPassword,
  previewMode,
} = AppConfig.acousticApi;

axios.defaults.timeout = timeout;
axios.defaults.withCredentials = previewMode;
axios.defaults.maxRedirects = maxRedirects;
axios.defaults.maxContentLength = maxContentLength;
// keepAlive pools and reuses TCP connections, so it's faster
axios.defaults.httpAgent = new http.Agent({ keepAlive: true });
axios.defaults.httpsAgent = new https.Agent({ keepAlive: true });

// Acoustic axios instance
const axiosInstance = axios.create();

const basicToken = Buffer.from(`${baseAuthUser}:${baseAuthPassword}`, 'utf8').toString('base64');
const basicAuthAxiosInstance = axios.create({
  withCredentials: true,
  headers: {
    Authorization: `Basic ${basicToken}`,
  },
});

const getQuery = (url) => (
  axiosInstance.get(url)
    .catch((error) => {
      console.error('api request error1', error);
      // TODO any shared error handling goes here
    })
);

const getQueryWithParams = (url, params) => (
  axiosInstance.get(url, { params })
    .catch((error) => {
      console.error('api request error11', error);
      // TODO any shared error handling goes here
    })
);

const getQueryWithParamsWithoutCreds = (url, params) => (
  axiosInstance.get(url, { withCredentials: false, params })
    .catch((error) => {
      console.error('api request error11', error);
      // TODO any shared error handling goes here
    })
);

const getQueryWithBaseAuth = (url, url_preview) => {
  return basicAuthAxiosInstance.get(url, { withCredentials: true })
    .then(() => {
      return basicAuthAxiosInstance.get(url_preview, { withCredentials: true })
    })
    .catch((error) => {
      console.error('api request error6', error);
    })
};

// Auth axios instance
const authAxiosInstance = axios.create({
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
  },
});

const authGetQuery = ({ url, config = null } = {}) => (
  authAxiosInstance.get(url, config)
    .catch((error) => {
      console.error('api request error', error);
      throw error;
    })
);

const authPostQuery = ({ url, data = null, config = null } = {}) => (
  authAxiosInstance.post(url, JSON.stringify(data), config)
    .catch((error) => {
      console.error('api request error', error);
      throw error;
    })
);

const authPutQuery = ({ url, data = null, config = null } = {}) => (
  authAxiosInstance.put(url, JSON.stringify(data), config)
    .catch((error) => {
      console.error('api request error', error);
      throw error;
    })
);

// axios.delete does not support data in the body, but that's fine (we don't need it)
// https://github.com/axios/axios/issues/897
const authDeleteQuery = ({
  url,
  config = null,
} = {}) => (
  authAxiosInstance.delete(url, config)
    .catch((error) => {
      console.error('api request error', error);
      throw error;
    })
);

export {
  getQuery,
  getQueryWithParams,
  getQueryWithBaseAuth,
  authPostQuery,
  authGetQuery,
  authPutQuery,
  authDeleteQuery,
  getQueryWithParamsWithoutCreds,
};
