import React from "react";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import "./TermsLabel.scss";

const MarketingLabel = () => {
  const { tMarketingLabel } = useWebsiteConfigObject();
  return (
    <p className="terms-label">
      {tMarketingLabel}
    </p>
  );
};

export { MarketingLabel };
