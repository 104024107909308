import { searchQuery } from 'api/acousticApi'
import { getUrlCountryPath } from 'utils/urlUtils'
import { getLibraryByCode } from 'utils/libraryUtils'

// note that the search query for content appears to be paginated. You have to send in the number of results you want
// and the start number. We won't know the actual maximum until we have enough content, so for now we send up a big number

const routesSearchParams = {
  q: '*:*',
  fl: 'name,string1,document:[json],id,classification,type,status',
  fq: `type:("layout f - webinar page")`,
  rows: 10000,
  start: 0,
}

const params = new URLSearchParams(routesSearchParams)

const culture = getUrlCountryPath()

const { libraryId } = getLibraryByCode(culture)

params.append('fq', `libraryId:("${libraryId}")`)

const webinarQuery = () => searchQuery(params)

export { webinarQuery }
