import React, {
  useEffect, createRef, useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { ChevronButton, BUTTON } from 'components/shared/ChevronButton';
import { useWebsiteConfigObject, useWebsiteConfigDeep } from 'websiteConfig/useWebsiteConfig';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './ContentGateAnonymous.scss';
import { Features } from 'components/shared/feature/Features';
import { useHistory, useLocation  } from 'react-router-dom';
import {  PROFILE_PAGE_PATH } from 'routes/routeConstants';
import RichText from 'components/reusableComponents/richText/RichText';
import { AnonymousForm } from './AnonymousForm';
import { MarketOptinForm } from './MarketOptinForm';
import { anonymousRegistration } from 'api/dynamoApi';
import { PROFILE_SUCCESS_PATH } from 'routes/routeConstants';
import { KKI_AUTH, OWA_AUTH, DOCCHECK_AUTH } from 'utils/authUtils';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { userActions } from 'redux/user/slice';
import { authActions } from 'redux/authentication/slice';
import { utmRegistration } from 'api/utmRegistration';
import { extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics';

// This paywall is an alternative to the paywall tied to access levels for self cert markets.
// This paywall can be used for external auth markets such as markets that use DocCheck and OneKey.

const ContentGateContainer = ({
  isSecure,
  canvas,
  browserSupported,
  buttonLogo,
  loginRedirect,
  registerRedirect,
  optionMarketing,
  baseInformation,
  pageTitle,
  content
}) => {
  //data from webconfig fields
  const {
    tContentGateTitle,
    tContentGateText,
    tContentGateLoginButtonText,
    tContentGateRegisterButtonText,
    tContentGateLeftSmallPrintText,
    tContentGateRightSmallPrintText,
    featureMarketingPreferenceActivation,
    tSave,
    disableMarketingConsent
  } = useWebsiteConfigObject();

  const {
    anonymousFlowHeading,
    anonymousFlowLeftColumnText,
    anonymousFlowFormTitle,
    anonymousFlowMarketingOptIn,
    anonymousFlowMarketingHeading,
    anonymousFlowMarketingDescription,
    anonymousFlowMarketingRejectMessage,
    anonymousFlowGoBackButtonLabel,
  } = useWebsiteConfigDeep().websiteConfig
  const { contentGateBlurLevel, authService } = useWebsiteConfigObject();
  
  // to check if we should show the contentGate 
  const[isContentGateActive, setContentGateActive]= useState(true); 
  const[isSubmitting, setIsSubmitting]= useState(false); 
  const[optionMarketingValue, setOptionMarketingValue]= useState(optionMarketing); 

  useEffect(() => {
    setOptionMarketingValue(optionMarketing);
  }, [optionMarketing])

  const { trackPageView,trackGoBackAnonymous } = useGoogleAnalytics();
  // button go back function 
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentPage = location.pathname;
  const goBack = () =>{
    trackGoBackAnonymous(pageTitle, function() {
      history.goBack();
    });
  }
  const { fetching, details } = useSelector((state) => state.user);
  const { taxonomyFields } = useSelector((state) => state.ga);
  const taxonomy = extractTaxonomyValues(taxonomyFields, content?.elements?.taxonomy?.value);

  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user?.userId;
  const { token } = data?.token;
  const marketOptinSubmission = (formData) => {
    setIsSubmitting(true);
    let body = {};
    body['firstname'] = details["firstname"];
    body['lastname'] = details["lastname"];
    body['username'] = details["username"];
    body['workplace'] = details["workplace"];
    body['jobTitle'] = details["jobTitle"];
    body['phone'] = details["phone"];
    body['role'] = details["role"];
    body['speciality'] = details["speciality"];
    body['department'] = details["department"];
    body['departmentPhone'] = details["departmentPhone"];
    body["promotionalMaterialsOptin"] = formData["information-consent"];
    if( formData["information-consent"] ){ //if terms and conditions consent is accepted
      anonymousRegistration({ body, token })
        .then((response) => {
          setIsSubmitting(false);
          if (response?.status === 200) {
            trackPageView(pageTitle,window.location.pathname, false, taxonomy);
            setContentGateActive(false);
            setOptionMarketingValue(true);
            // window.location.reload(false);
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
        });
    } else {
      setIsSubmitting(false);
      goBack();
    }
  }

  const anonymousUserRegistration = (formData) => {
    setIsSubmitting(true);
    const optInValue = formData["information-consent"];
    let body = {}
    body['firstname'] = formData["first-name"];
    body['lastname'] = formData["last-name"];
    body['username'] = formData["email"];
    body['promotionalMaterialsOptin'] = formData["information-consent"];
    body['speciality'] = formData["speciality"];
    body['role'] = formData["role"];
    body['jobTitle'] = formData["jobtitle"];
    body['workplace'] = formData["organization-title"];
    body['department'] = formData["department"];
    body['phone'] = formData["phone"];
    body['departmentPhone'] = formData["departmentPhone"];
    anonymousRegistration({ body, token })
    .then((response) => {
      setIsSubmitting(false);
      
      if (response?.status === 200) {
        if(!optInValue && featureMarketingPreferenceActivation) {
          dispatch(userActions.global.details.updateAnonymousState(formData["first-name"]));
          dispatch(authActions.global.updateAnonymousStateAuthentication(formData["first-name"]));
          const querystring = localStorage.getItem("incommingquerystring");
          if(querystring){
              const body = {
                  "type": "UTMRegistration",
                  "additionalValues": querystring
              };
              utmRegistration(body, userId);
          }
          setTimeout(()=>{
              localStorage.removeItem("incommingquerystring");
          },2000);
          history.push({pathname:PROFILE_PAGE_PATH},{state: { type: "Anonymous-user-flow" , redirect : currentPage }})
        }
        else{
          trackPageView(pageTitle,window.location.pathname, false, taxonomy);
          dispatch(userActions.global.details.updateAnonymousState(formData["first-name"]));
          dispatch(authActions.global.updateAnonymousStateAuthentication(formData["first-name"]));
          const querystring = localStorage.getItem("incommingquerystring");
          if(querystring){
              const body = {
                  "type": "UTMRegistration",
                  "additionalValues": querystring
              };
              utmRegistration(body, userId);
          }
          setTimeout(()=>{
              localStorage.removeItem("incommingquerystring");
          },2000);
        }
        setContentGateActive(false);
      }
    })
    .catch((error) => {
      setIsSubmitting(false);
      console.log(error);
    });
  }
  const contentGateRef = createRef();
  useEffect(() => {
    const contentWrapper = document.querySelectorAll('[role="main"]')[0]
    if (contentWrapper) {
       if(!baseInformation ||(baseInformation && !optionMarketing) && isContentGateActive){
        contentWrapper.setAttribute("id", "blurredContentWrapper");
       }
    }

    if (contentGateRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(contentGateRef.current);

      const headerContent = document.getElementsByClassName("Header_Content")[0];

      if(headerContent) {
        const rect = headerContent.getBoundingClientRect();
        let headerTop = window.pageYOffset || document.documentElement.scrollTop;
        headerTop += rect.top + 80;
        contentGateRef.current.style.height = `calc(100% - ${headerTop}px)`;
      }
    }

    return () => {
      const blurWrapper = document.getElementById("blurredContentWrapper");
      if (blurWrapper) {
              blurWrapper.removeAttribute("id");
      }
      clearAllBodyScrollLocks();
    };
  }, [contentGateRef]);

  return (
    <>
    {isContentGateActive === true ?
    !baseInformation && ( // to put true to test
      <div ref={contentGateRef} className="content-gate-anonymous__overlay-anonymous">
        {
          browserSupported && (
          
            <>
              <svg id="svgBlur" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <filter id="blur">
                  <feGaussianBlur stdDeviation={`${contentGateBlurLevel || 10}`} />
                </filter>
              </svg>
              {
                isSecure && <img src={canvas} alt="Blurred Content" className="blur" />
              }
            </>
          )
        }
        <div className='content-gate-anonymous'>
          <div className="container">
            <h3 className="content-gate-anonymous__title">{anonymousFlowHeading?.value && (<RichText data={anonymousFlowHeading}/>)}</h3>

            <div className="content-gate-anonymous__content">
              <div className="content-gate-anonymous__content__desc">
                {anonymousFlowLeftColumnText?.value && (<RichText data={anonymousFlowLeftColumnText}/>)}
                <ChevronButton
                  type={BUTTON}
                  text={anonymousFlowGoBackButtonLabel || "Go Back"}
                  variant="paywall-btn"
                  chevron="right"
                  // image={buttonLogo}
                  onClick={goBack}
                />
              </div>
              <div className="content-gate-anonymous__content__form">
                {/**
                 * you can declare a function in content.jsx and pass it to the AnonymousForm.jsx form or do the logic in the AnonymousForm.jsx form itself
                 */}
                <AnonymousForm actions={{anonymousUserRegistration}} isSubmitting={isSubmitting} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    :null     
    }
    {!disableMarketingConsent && featureMarketingPreferenceActivation && 
      baseInformation &&
      !optionMarketingValue && (
      <div ref={contentGateRef} className="content-gate-market__overlay">
        {
          browserSupported && (
            <>
              <svg id="svgBlur" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <filter id="blur">
                  <feGaussianBlur stdDeviation={`${contentGateBlurLevel || 10}`} />
                </filter>
              </svg>
            </>
          )
        }
        <div className='content-gate-market'>
          <div className="container">
            <h3 className="content-gate-market__title">{anonymousFlowMarketingHeading?.value && <RichText data={anonymousFlowMarketingHeading} />}</h3>
            <div className="content-gate-market__body">
              <div className="desc">
                {anonymousFlowMarketingDescription?.value && <RichText data={anonymousFlowMarketingDescription} />}
              </div>
              <div className="form">
                <MarketOptinForm actions={{ marketOptinSubmission }} isSubmitting={isSubmitting} />
              </div>
              <div className="reject-message">
                {anonymousFlowMarketingRejectMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

ContentGateContainer.propTypes = {
  canvas: PropTypes.string,
  buttonLogo: PropTypes.object,
  loginRedirect: PropTypes.func,
  registerRedirect: PropTypes.func,
  baseInformation:PropTypes.bool
};

export { ContentGateContainer as ContentGate };


