import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useWebsiteConfigObject, useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import { PageTemplates } from 'components/pageTemplates/PageTemplatesIndex';
import { useApiRoutes } from 'routes/useApiRoutes';
import { CMSPage } from 'components/pages/CMSPage';
import { GenericMessagePage } from 'components/pageTemplates/GenericMessagePage';
import { staticRoutes } from 'routes/staticRoutes';
import { Footer } from '../layout/Footer';
import Breadcrumbs from '../layout/header/components/breadcrumbs/Breadcrumbs';
import './MainArea.scss';
import { Tealium } from 'components/shared/tealium/tealium';
import {
  SFMC,
   TEALIUM,
} from 'api/acousticApi/ContentTypesFeatures';
import { Features } from 'components/shared/feature/Features';
import { getUrlCountryPath } from 'utils/urlUtils';
import Sfmc from 'components/shared/sfmc/Sfmc';
import GTag from 'components/shared/ga/gtag';
import OneTrustEventInteraction from './OneTrustEventInteraction';

const MainArea = () => {
  const { routes, isLoading, error } = useApiRoutes();
  const {
    authService,
    hideHeaderComponents,
    tThePageYouAreLooking,
    tUnfortunatelyThePage,
    tServiceTemporarilyUnavailable,
    tUnfortunatelyServiceUnavailable,
    tError,
    tSearchRelatedPageUrl,
  } = useWebsiteConfigObject();
  const searchPath = tSearchRelatedPageUrl ? tSearchRelatedPageUrl.split("?") : null

  if (!authService) {
    return null;
  }
  if (isLoading) {
    return (
      <section role='main' className='loading-page'>
        <Loader className='center-aligned-loader' size={LARGE_LOADER} />
      </section>
    );
  }

  if (error) {
    console.error(error); // todo add an error page https://ocasta.atlassian.net/browse/RKK-62
  }
  return (
    <Switch>
      {
        // add dynamic routes retrieved from acoustic api
        routes &&
          routes.map(({ contentId, templateType, path }) => {
            return (
              <Route path={path} exact key={contentId}>
                {!hideHeaderComponents && (
                  <Breadcrumbs
                    templateType={templateType}
                    contentId={contentId}
                  />
                )}
                <CMSPage
                  path={path}
                  contentId={contentId}
                  templateType={templateType}
                />
                <Footer templateType={templateType} contentId={contentId} />
              </Route>
            );
          })
      }
      {
        // add routes for the static pages for the site
        // E.G home page, login page
        staticRoutes[authService.selection].map(
          ({ path, Component, exact = true }) => {
            if(path==="/search-results"){
              path = searchPath ? (searchPath[0].startsWith("/") ? searchPath[0] : "/"+searchPath[0]) : "/search-results"
            }
            return (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={(props) => (
                  <>
                    {!hideHeaderComponents && <Breadcrumbs />}
                    <StaticPage path={path} props={props} Component={Component} />
                    {/* <Component path={path} routeProps={props} /> */}
                    <Features contentType={TEALIUM}>
                      {path.length > 1 ? <Tealium page={path} /> : null}
                    </Features>
                    <Footer />
                  </>
                )}
              />
            );
          }
        )
      }
      <Route
        // temporary templates index page
        path='/templates'
        exact
      >
        <Breadcrumbs />
        <PageTemplates />
        <OneTrustEventInteraction />
        <Footer />
      </Route>
      <Route path='/503' exact>
        <Breadcrumbs />
        <GenericMessagePage
          titleText='HTTP 503'
          headerText={tServiceTemporarilyUnavailable}
          categoryText={tError}
        >
          <p className='no-top-margin'>{tUnfortunatelyServiceUnavailable}</p>
        </GenericMessagePage>
        <OneTrustEventInteraction />
        <Footer />
      </Route>
      <Route
        // temporarily show generic page template for missing routes
        // TODO this should be a notfound page (which there is no design for)
        path='*'
      >
        <Breadcrumbs />
        <GenericMessagePage
          titleText='404'
          headerText={tThePageYouAreLooking}
          categoryText={tError}
        >
          <p className='no-top-margin'>{tUnfortunatelyThePage}</p>
        </GenericMessagePage>
        <OneTrustEventInteraction />
      </Route>
      <Footer />
    </Switch>
  );
};

const StaticPage = ({ path, Component, props }) => {
  const countryCode = getUrlCountryPath();

  useEffect(() => {
    if(!Boolean(countryCode.length) || path !== '/') {
      document.body.removeAttribute('data-page-id');
    }
  }, [countryCode.length, path])

  return <>
    <Component path={path} routeProps={props} />
    {(!Boolean(countryCode.length) || path !== '/') &&  <OneTrustEventInteraction />}
  </>;
}

export { MainArea };
