import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { ChevronButton, BUTTON } from 'components/shared/ChevronButton';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import './CookieBannerModal.scss';

const CookieBannerModal = (
  {
    showModal,
    onClose,
    onSavePreferences,
    templateType, 
    contentId 
  },
) => {
  let {
    tCookiePreferencesTitle,
    tCookiePreferencesSubtitle,
    tNecessaryCookiesTitle,
    tNecessaryCookiesText,
    tNecessaryCookiesAlwaysActiveText,
    tAnalyticsCookiesTitle,
    tAnalyticsCookiesText,
    tAnalyticsCookiesActiveText,
    tCookiePreferencesSaveButtonText,
    tCookiePreferencesCancelButtonText,
  } = useWebsiteConfigObject();
  const [optinAnalytics, setOptinAnalytics] = useState(false);
  const handleOptinAnalyticsChange = () => setOptinAnalytics(!optinAnalytics);

  let cookieContent = usePageContent(contentId);

  if(templateType === "Layout – Event Page" || templateType === "Layout – Event Thank You Page" )
  { 
    let cookieBanner = cookieContent?.content?.elements?.cookieBanner;
    
    if(cookieBanner !==null && typeof cookieBanner !== "undefined")
    {      
      tCookiePreferencesTitle = cookieBanner?.value?.elements?.cookiePreferencesTitle.value;
      tCookiePreferencesSubtitle = cookieBanner?.value?.elements?.cookiePreferencesSubtitle.value;
      tNecessaryCookiesTitle = cookieBanner?.value?.elements?.necessaryCookiesTitle.value;
      tNecessaryCookiesText = cookieBanner?.value?.elements?.necessaryCookiesText.value;
      tNecessaryCookiesAlwaysActiveText = cookieBanner?.value?.elements?.necessaryCookiesAlwaysActiveText.value;
      tAnalyticsCookiesTitle = cookieBanner?.value?.elements?.analyticsCookiesTitle.value;
      tAnalyticsCookiesText = cookieBanner?.value?.elements?.analyticsCookiesText.value;
      tAnalyticsCookiesActiveText = cookieBanner?.value?.elements?.analyticsCookiesActiveText.value;
      tCookiePreferencesSaveButtonText = cookieBanner?.value?.elements?.cookiePreferencesSaveButtonText.value;
      tCookiePreferencesCancelButtonText = cookieBanner?.value?.elements?.cookiePreferencesCancelButtonText.value;
    } 
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-title"
      centered
      show={showModal}
      animation={false}
      onHide={onClose}
      className="cookie-banner-modal"
    >
      <Modal.Header>
        <Modal.Title id="modal-title">
          <h3 className="modal-title__main">{tCookiePreferencesTitle}</h3>
          <p className="modal-title__subtitle">{tCookiePreferencesSubtitle}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body__section">
          <h4 className="modal-body__section-title">
            {tNecessaryCookiesTitle}
          </h4>
          <p className="modal-body__section-text">
            {tNecessaryCookiesText}
          </p>
          <div className="modal-body__section-controls">
            {tNecessaryCookiesAlwaysActiveText}
          </div>
        </div>
        <div className="modal-body__section">
          <h4 className="modal-body__section-title">
            {tAnalyticsCookiesTitle}
          </h4>
          <p className="modal-body__section-text">
            {tAnalyticsCookiesText}
          </p>
          <div className="modal-body__section-controls">
            <div className="form-field form-field-checkbox form-field-checkbox--cookie-banner">
              <div className="field-meta-data">
                <label htmlFor="optinAnalytics">
                  <input
                    className={`checkbox checkbox-${optinAnalytics ? 'checked' : 'unchecked'} custom-focus`}
                    id="optinAnalytics"
                    type="checkbox"
                    value={optinAnalytics}
                    onChange={handleOptinAnalyticsChange}
                  />
                  {tAnalyticsCookiesActiveText}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ChevronButton
          type={BUTTON}
          text={tCookiePreferencesSaveButtonText}
          onClick={() => onSavePreferences(optinAnalytics)}
        />
        <ChevronButton
          type={BUTTON}
          text={tCookiePreferencesCancelButtonText}
          className="blank"
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

CookieBannerModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSavePreferences: PropTypes.func,
};

export { CookieBannerModal };
