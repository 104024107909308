import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from 'redux/user/slice';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { Form } from 'components/shared/form/Form';
import {
  INPUT,
  BUTTON,
  SHOULD_MATCH_FIELD,
  CHANGE_PASSWORD,
  commonShouldHaveValue,
  commonNewPasswordValidations,
} from 'components/shared/form/formConstants';
import { CHANGE_PASSWORD_SUCCESS_PATH } from 'routes/routeConstants';

const NEW_PASSWORD = 'new-password';

const ChangePasswordForm = () => {
  const {
    tCurrentPassword,
    tEnterYourPassword,
    tNewPassword,
    tEnterNewPassword,
    tConfirmNewPassword,
    tPasswordsDoNotMatch,
    tSubmit,
    tPassword,
    tIfYouWishToChange,
    activateMyHub
  } = useWebsiteConfigObject();
  const changePasswordFormFields = [
    {
      label: tCurrentPassword,
      id: 'password',
      initialValue: '',
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: 'password',
        autoComplete: 'off', // < - this is the correct method but it's no longer supported. Most browsers just auto complete regardless
        // placeholder: tEnterYourPassword, removed as per ticket 838 and figma
      },
      validation: [
        commonShouldHaveValue,
      ],
    },
    {
      label: tNewPassword,
      id: NEW_PASSWORD,
      initialValue: '',
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: 'password',
        autoComplete: 'new-password',
        // placeholder: tEnterNewPassword,
      },
      validation: [
        ...commonNewPasswordValidations,
      ],
    },
    {
      id: 'change-password',
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          actions.changePassword(formValues);
        },
        text: tSubmit,
        type: 'submit',
      },
    },
  ];

  const history = useHistory();
  const dispatch = useDispatch();

  const changePassword = (formData) => {
    dispatch(userActions.kki.password.change(formData)).then(() => {
      history.push(CHANGE_PASSWORD_SUCCESS_PATH);
    });
  };

  return (
    <div className="profile-form-content">
      <h3 className="account-details">{tPassword}</h3>
      <p className="check-details">{tIfYouWishToChange}</p>
      {activateMyHub && <div className='my-hub-divider'></div>}
      <Form
        formId={CHANGE_PASSWORD}
        fields={changePasswordFormFields}
        actions={{ changePassword }}
      />
    </div>
  );
};

export { ChangePasswordForm };

