import React from 'react';

import { getImgAttr } from 'api/acousticApi/index';
import { getValue } from 'utils/contentUtils';
import { stringToCssId } from 'utils/stringUtils';
import { contentElementProptype } from 'propTypes/contentProptypes';

// layouts
import Grid from 'layouts/grid/Grid';

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent';
import VideoCard from 'modules/videoCard/VideoCard';

// components
import RichText from 'components/reusableComponents/richText/RichText';
import Video from 'components/reusableComponents/video/Video';

// styles
import './ArticleSection.scss';


// component for an article section
const EapcDynamicArticleSection = ({  
  element,
  brandColours
}) => {
  const {
    title,
    copy,
    image,
    video,
    videoTitle,
    videoPoster,
    webvttChaptersFile,
    subtitles,
    className,
    videoCardGrid,
    videoPosterImage,
    externalVideoLink,
    backgroundColour,
  } = element.elements;


  const style = {
    backgroundColor: getValue(backgroundColour),
    width: '100vw',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    padding: '40px 0'
  }

  const titleValue = getValue(title);
  const img = getImgAttr(image);

  const id = stringToCssId(title.value);
  const isFullWidth = videoCardGrid?.value?.elements?.fullWidthVideoCards?.value
 
  const imageBlock = (img) => {
    if (img?.link) {
      // If img.link is not null or undefined
      return img?.src ? (
        <a href={img.link} target={img?.target || "_self"}>
          <img src={img.src} alt={img?.alt} className="eapc-image" />
        </a>
      ) : null;
    } else {
      // If img.link is null or undefined
      return img?.src ? (
        <img src={img.src} alt={img?.alt} className="eapc-image" />
      ) : null;
    }
  };
  
  const separatorColor = brandColours?.value?.elements?.separatorColor?.value;
  const separatorStyle = {
    borderBottom: `1px dashed ${separatorColor || "#EA5504"}`
  }
  
  return (
    <section className={`article-section-component ${className ?? ''}`} id={id} style={style}>
      <div className='eapc-content'>
        {titleValue && <h2 className={`article-section-title ${separatorColor?"":"article-border"}`} style={separatorStyle}>{titleValue}</h2>}
      {imageBlock(img)}
        <RichText data={copy} />
        <Video
          title={videoTitle}
          element={video}
          webvttChaptersFile={webvttChaptersFile}
          subtitles={subtitles}
          videoPoster={videoPoster}
          videoPosterImage={videoPosterImage}
          externalVideo={externalVideoLink}
        />
        {videoCardGrid?.value && (
            <RelatedContent bgColor='#ffffff' headingText={videoCardGrid?.value?.elements?.relatedVideosHeading?.value} fluid removeTopPadding>
              <Grid wrapperStyle={{ marginTop: 40 }} itemsPerRow={{ sm: 1, md: 2, lg: isFullWidth ? 1 : 3 }} gap={{ sm: 20, md: 25, lg: 25 }}>
                {videoCardGrid?.value?.elements?.relatedVideosVideoCards?.values?.map((video) => {
                    const { id, lastModified } = video || {}

                    const { videoCardContent, videoCardHeading, videoCardImage, videoCardLink,externalVideoLink:videoCardExternalLink, videoCardSubheading, videoCardVideo, webvttChaptersFile, subtitles, videoPoster } = video?.elements || {}

                    return (
                        <VideoCard
                            key={id}
                            href={videoCardLink?.linkURL}
                            image={videoCardImage}
                            video={videoCardVideo}
                            heading={videoCardHeading?.value}
                            subHeading={videoCardSubheading?.value}
                            content={videoCardContent?.value?.text}
                            postDate={lastModified}
                            isFullWidth={isFullWidth}
                            webvttChaptersFile={webvttChaptersFile}
                            subtitles={subtitles}
                            videoPoster={videoPoster}
                            externalVideoLink={videoCardExternalLink}
                        />
                    )
                })}
              </Grid>
            </RelatedContent>
          )}
        </div>
    </section>
  );
};

EapcDynamicArticleSection.propTypes = {
  element: contentElementProptype,
};

export { EapcDynamicArticleSection };
