import React, { useRef } from 'react';

// components
import CardLink from 'components/reusableComponents/card/components/cardLink/CardLink';
import Image from 'components/reusableComponents/image/Image';

import RichText from 'components/reusableComponents/richText/RichText';

//import functions related to date of webinar
import {
  getWebinarDate,
  getWebinarTime,
} from '../../components/pageTemplates/webinar/webinarHelpers';
import { AppConfig } from 'AppConfig';
import {
  Calender,
  Clock,
} from "components/shared/SvgIcons";
// styles
import './WebinarCard.scss';

const WebinarCard = ({
  image,
  heading,
  href,
  webinarDate,
  webinarTime,
  excerpt,
  data
}) => {
  const backgroundImage = image?.url ? `url(${AppConfig.acousticApi.domain + image?.url})` : "";
  const style = {
    backgroundImage: backgroundImage,
  };

  const openInNewTab = data?.elements?.openInNewTab?.value;

  return (
    <div className='webinar-card-container'>
      <div className='webinar-card'></div>
      <div className='webinar-card__image' style={style}>
      </div>
      <div className='webinar-card__details'>
        <h2>{heading}</h2>
        {excerpt?.value && <RichText data={excerpt} />}
        <div className='webinar-tags'>
          <div className='webinar-tags__list'>
            {webinarDate && (
              <div className='webinar-tags__list__detail'>
                <div className='webinar-tags__list__detail__gray'><Calender /></div>
                <div className='webinar-tags__list__detail__text'>
                  {getWebinarDate(webinarDate)}
                </div>
              </div>
            )}
            {webinarDate && (
              <div className='webinar-tags__list__detail'>
                <div className='webinar-tags__list__detail__gray'><Clock /></div>
                <div className='webinar-tags__list__detail__text'>
                  {`${webinarTime?.value}`}
                </div>
              </div>
            )}
          </div>
        </div>
        <CardLink text='Learn more' openInNewTab={openInNewTab} href={href} />
      </div>
    </div>
  );
};

export default WebinarCard;
