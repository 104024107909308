import React, { useEffect, useRef } from 'react'
import dayjs from 'dayjs'

// config
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import { getValue } from 'utils/contentUtils'

// layouts
import Grid from 'layouts/grid/Grid'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import SideNavigation from '../../modules/sideNavigation/SideNavigation'
import VideoCard from 'modules/videoCard/VideoCard'
import { ChaptersNav } from 'modules/chaptersNav/ChaptersNav'
import { ChaptersPagination } from 'modules/chaptersPagination/ChaptersPagination'

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { AuthorSection } from 'components/content/article/AuthorSection'
import { References } from 'components/content/References'
import { DynamicArticleContentsList } from 'components/content/article/DynamicArticleContentsList'
import { DynamicArticleSection } from 'components/content/article/DynamicArticleSection'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode'

// styles
import './DynamicArticlePage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'
import { SCREEN_WIDTH_MOBILE, useScreenWidth } from 'components/layout/ScreenWidthContext'

const DynamicArticlePage = ({ content }) => {
    const {
        title,
        titleRichText,
        subtitle,
        headerLogo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        authors,
        date,
        articleSection,
        bannerImage,
        veevaApprovalCode,
        relatedVideos,
        relatedContent,
        references,
        contentChapters,
        subnavigation,
    } = content.elements

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;

    const { tPublicationDate } = useWebsiteConfigObject()
    const { currentWidthRanges } = useScreenWidth();
    const stickyMenuRef = useRef();

    const formattedDate = date && date.value ? dayjs(date.value).format('D MMM YYYY') : null

    const dateStr = formattedDate ? `${tPublicationDate}, ${formattedDate}` : null

    const sectionElements = articleSection ? articleSection.values : null
    const chapter = contentChapters ? contentChapters.value : null

    const hasReferences = references && references.value

    const isFullWidth = relatedVideos?.value?.elements?.fullWidthVideoCards?.value

    return (
        <section role='main' className={`global_layout ${sectionElements && ' anchorsFix'}`} data-layout-type='dynamic-article-page'>
            <MainAreaTitleSection
                title={title && title.value}
                titleRichText={titleRichText}
                category={subtitle && subtitle.value}
                logo={headerLogo}
                backgroundImage={headerBackgroundImage}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-3-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>
                        <section className='content_wrapper content_wrapper--columns-3-center'>
                            { chapter && <div className='content_item'> <ChaptersPagination chapter={chapter} /></div> }
                            <div className='content_item'>
                                { dateStr && <p className='date'>{dateStr}</p> }
                                <AuthorSection authors={authors} />
                            </div>
                            <div className='content_item'>
                                {/* TODO: This DynamicArticleSection can be replaced with the newer Content.jsx module - just need to bring Content styles into the DynamicArticlePage.scss file */}
                                {sectionElements && sectionElements.map((section, index) => (
                                    <DynamicArticleSection
                                        element={section}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                    />
                                ))}
                                </div>
                            {chapter && (
                                <div className='content_item'>
                                    <ChaptersPagination chapter={chapter} />
                                </div>
                            )}
                            <div className='content_item'>
                                {relatedVideos && (
                                    <RelatedContent bgColor='#ffffff' headingText={relatedVideos?.value?.elements?.relatedVideosHeading?.value} fluid removeTopPadding>
                                        <Grid wrapperStyle={{ marginTop: 40 }} itemsPerRow={{ sm: 1, md: 2, lg: isFullWidth ? 1 : 3 }} gap={{ sm: 20, md: 20, lg: 20 }}>
                                            {relatedVideos?.value?.elements?.relatedVideosVideoCards?.values.map((video) => {
                                                const { id, lastModified } = video || {}

                                                const { videoCardContent, videoCardHeading,externalVideoLink:videoCardExternalLink, videoCardImage, videoCardLink, videoCardSubheading, videoCardVideo, webvttChaptersFile, subtitles, videoPoster } = video?.elements || {}

                                                return (
                                                    <VideoCard
                                                        key={id}
                                                        href={videoCardLink?.linkURL}
                                                        image={videoCardImage}
                                                        video={videoCardVideo}
                                                        heading={videoCardHeading?.value}
                                                        subHeading={videoCardSubheading?.value}
                                                        content={videoCardContent?.value?.text}
                                                        postDate={lastModified}
                                                        isFullWidth={isFullWidth}
                                                        webvttChaptersFile={webvttChaptersFile}
                                                        subtitles={subtitles}
                                                        videoPoster={videoPoster}
                                                        externalVideoLink={videoCardExternalLink}
                                                    />
                                                )
                                            })}
                                        </Grid>
                                    </RelatedContent>
                                )}
                            </div>
                        </section>
                        <div ref={stickyMenuRef} className='content_wrapper content_wrapper--columns-3-right'>
                            {chapter ? <ChaptersNav chapter={chapter} /> : sectionElements && <DynamicArticleContentsList elements={sectionElements} containerRef={stickyMenuRef} />}
                        </div>
                    </div>
                    {relatedContent && relatedContent?.value && <RelatedContent content={relatedContent} fluid />}
                    {bannerImage && <BannerImage image={bannerImage} contain={false} />}
                    {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    {hasReferences && <References references={references} />}
                </div>
            </section>
        </section>
    )
}

export default DynamicArticlePage
