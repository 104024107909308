import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { LinksIcon } from 'components/shared/SvgIcons';
import Link from 'components/reusableComponents/link/Link';
import './ChaptersNav.scss';


// component for the chapter links in sidebar for dynamic article page
const ChaptersNav = ({ chapter }) => {
  const { tContents } = useWebsiteConfigObject();
  const chapterLinks = chapter.elements.chapter.values;

  return (
    <aside className="article-content-list-container article-content-list-container--chapters-nav">
      <h4 className="article-content-list-title">
        <LinksIcon />
        <span>{tContents} Contenido</span>
      </h4>
      <ul className="contents article-content-list">
        { chapterLinks.map((chapterLink, index) => {
          const linkText = chapterLink.linkText;
          const linkURL = chapterLink.linkURL;

          // mark current page as active chapter
          const currentPath = window.location.href;
          const isCurrentPage = currentPath.indexOf(linkURL) !== -1;

          if (!linkURL) { return null; }

          return (
            // eslint-disable-next-line react/no-array-index-key
            isCurrentPage ? (
              <li className='article-content-list--current' key={index}>
                <span>{linkText}</span>
              </li>
            ) : (
              <li key={index}>
                <Link href={linkURL}>
                  {linkText}
                </Link>
              </li>
            )
          );
        })
      }
      </ul>
    </aside>
  );
};

ChaptersNav.propTypes = {
  chapter: contentElementProptype,
};

export { ChaptersNav };
