import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'redux/authentication/slice';

// component that doesn't render anything, but scrolls to the top every time the path changes
// taken from react router documentation: https://reactrouter.com/web/guides/scroll-restoration
// checks for JWT token expiry every time the path changes
const OnRouteActions = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.authentication);
  const { loggedIn } = status || {};
  const { token, refreshToken } = data.token || {};

  // SCROLL TO TOP ON ROUTE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);

  // lint disabled here due to only wanting side effects when pathname/route changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // validate token on page change, if token is invalid or expired refresh the
  // token if refresh token fails force logout user
  useEffect(() => {
    if (loggedIn) {
      dispatch(authActions.global.token.validate({ token, refreshToken }));
    }

    // lint disabled here due to only wanting side effects when pathname/route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, token, refreshToken]);

  return null;
};

export { OnRouteActions };
