import React from 'react';
import PropTypes from 'prop-types';
import { formTabsPropTypes } from 'components/shared/form/formPropTypes';
import './FormTabs.scss';


const FormTabs = ({
  formTabs,
  setActiveTab,
  activeTab,
  triggerClick
}) => (
  <div
    className="form-tabs-component"
  >
    {
      formTabs.map((tab, i) => {
        const {
          text,
          id,
        } = tab;

        return (
          <button
            key={id}
            type="button"
            className={`form-tab custom-focus-forced ${i === activeTab ? 'form-tab-active' : ''}`}
            onClick={() => {setActiveTab(i);triggerClick(i)}}
        >
            <p>{text}</p>
          </button>
        );
      })
    }
  </div>
);



FormTabs.propTypes = {
  formTabs: formTabsPropTypes,
  setActiveTab: PropTypes.func,
  triggerClick:PropTypes.func,
  activeTab: PropTypes.number,
};




export { FormTabs };

