import ButtonAcousticData from 'components/kanso/atoms/button/ButtonAcousticData'
import InlineHelp from './InlineHelp'
import { getValue } from 'utils/utilityHelper'

const InlineHelpAcousticData = (props) => {
  const { 
    link
  } = props.data

  return (
    <InlineHelp>
        <ButtonAcousticData data={getValue(link)?.elements} />
    </InlineHelp>
  )
}

export default InlineHelpAcousticData