import React from 'react';
import PropTypes from 'prop-types';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { LinksIcon } from 'components/shared/SvgIcons';
import { stringToCssId } from 'utils/stringUtils';
import { contentElementProptype } from 'propTypes/contentProptypes';
import Link from 'components/reusableComponents/link/Link'
import './ArticleContentsList.scss';


// component for the content box for an article
const ArticleContentsList = ({
  titleElements,
}) => {
  const { tContents } = useWebsiteConfigObject();
  return (
    <aside className="article-content-list-container">
      <h4 className="article-content-list-title">
        <LinksIcon />
        <span>{tContents}</span>
      </h4>
      <ul className="contents article-content-list">
        {
        titleElements.map((element, index) => {
          const title = element ? element.value : null;
          if (!title) { return null; }
          const id = stringToCssId(title);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${id}-${index}`}>
              <Link href={`#${id}`}>{title}</Link>
            </li>
          );
        })
      }
      </ul>
    </aside>
  );
};

ArticleContentsList.propTypes = {
  titleElements: PropTypes.arrayOf(contentElementProptype),
};

export { ArticleContentsList };
