import React, { useState } from 'react';
import { FormTabs } from 'components/shared/form/FormTabs';
import { Form } from 'components/shared/form/Form';
import { formTabsPropTypes } from 'components/shared/form/formPropTypes';
import PropTypes from 'prop-types';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
const TabForm = ({
  tabFormFields,
  actions,
}) => {
  
  const { trackRegisterOption, trackGTMEvent } = useGoogleAnalytics();
  const formTabs = tabFormFields.map((formTab) => ({
    text: formTab.text,
    id: formTab.id,
  }));

  const {
    featureSwitchDisplayNewFormFields,
  } = useWebsiteConfigObject();

  const [activeTab, setActiveTab] = useState(0);
  const activeTabId = formTabs[activeTab].id;
 
  const formDescription = tabFormFields[activeTab].formDescription ? tabFormFields[activeTab].formDescription : null;
  const fields = tabFormFields[activeTab].formFields;
  const triggerClick=(i)=>{
    if(i === 0){
      trackGTMEvent("login_intent", "login_page");
    }
    if(i === 1){
      trackGTMEvent("register_intent", "login_page");
      trackRegisterOption('register');
    }
  } 

  return (
    <div
      className={`tab-form-component${featureSwitchDisplayNewFormFields ? " tab-form-component--new-fields" : ""}`}
    >
      <FormTabs
        formTabs={formTabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        triggerClick={triggerClick}
      />
      {
        formDescription && (
        <div className="tab-form-inner">
          <p className="form-p">
            {formDescription}
          </p>
          <hr style={{ marginBottom: 20 }} />
        </div>

        )
      }
      <Form
        fields={fields}
        formId={activeTabId}
        key={activeTab}
        actions={actions}
        wrapped
      />
    </div>
  );
};






TabForm.propTypes = {
  tabFormFields: formTabsPropTypes,
  actions: PropTypes.objectOf(PropTypes.func),
};



export { TabForm };

