/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import PropTypes from 'prop-types'
import { childrenPropTypes } from 'propTypes/generic'
import { ExampleCategoryIcon } from 'components/shared/SvgIcons'
import Link from 'components/reusableComponents/link/Link'
import './TextCard.scss'
import Image from '../reusableComponents/image/Image';

const TextCardContainer = ({ onClickPath, externalUrl, className, children, fileAsset }) => {

    const cssClass = `text-card custom-focus ${className ? className : ''}`
        
    const href = externalUrl || onClickPath || null

    if (!href) {
        return <div className={cssClass}>{children}</div>
    }

    return (
        <Link className={cssClass} href={href} assetName={fileAsset?.asset?.fileName}>
            {children}
        </Link>
    )
}

const TextCard = ({
    category,
    title,
    logoSrc,
    logoAlt = '',
    onClickPath, // route for when the card itself is clicked
    externalUrl,
    children,
    className,
    image,
    ...rest
}) => {
    return (
        <TextCardContainer className={className} externalUrl={externalUrl} onClickPath={onClickPath} {...rest}>
            <div className='card-section'>
                <div className='text-section'>
                    <div className='top-section'>
                        {category && (
                            <h3 className='category'>
                                <ExampleCategoryIcon />
                                <span className="categoryText"> {category}</span>
                            </h3>
                        )}
                        {title && <h2 className='text-card-title'>{title}</h2>}
                        {logoSrc &&<Image data={logoSrc} className="text-card-logo" alt={logoAlt}/>}
                    </div>
                    <div className='bottom-section'>{children}</div>
                </div>
                {image?.src && (
                    <div className='image-section'>
                        <img className='' alt={image?.alt} src={image?.src} />
                    </div>
                )}
            </div>
        </TextCardContainer>
    )
}

TextCard.propTypes = {
    category: PropTypes.string,
    title: PropTypes.string,
    logoSrc: PropTypes.string,
    logoAlt: PropTypes.string,
    onClickPath: PropTypes.string,
    externalUrl: PropTypes.string,
    children: childrenPropTypes,
}

export { TextCard }
