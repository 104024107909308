import { getAssetUrl } from 'api/acousticApi';
import { addSubareaParameterToPath } from 'components/content/contentListPage/therapySubAreas';
import { getUseExternal, getValue } from 'utils/contentUtils';
import { withLeadingSlash } from 'utils/stringUtils';

// navigation item types
// values are the values defined in acoustic for the type field of a menu item
const NAV_ITEM_COLLAPSE = 'Collapsible';
const NAV_ITEM_OPEN_RIGHT = 'Open right';
const NAV_ITEM_LINK = 'Link';
const NAV_ITEM_LINK_MAIN = 'Link main';



// recursive function to parse an array of nav item from the API
const parseAPINavItems = (items) => {
  const values = items ? items.values : null;
  if (!values) { return null; }
  return values.map((child) => {
    const { id, elements } = child;
    const {
      children,
      icon,
      slug,
      title,
      type,
      whiteIcon,
      externalLink,
      linkToInternalPageOrExternalLink,
    } = elements;
    const parsedChildren = parseAPINavItems(children);
    const slugValue = slug && slug.value ? withLeadingSlash(slug.value) : null;
    const path = addSubareaParameterToPath(slugValue);
    const typeStr = type && type.value ? type.value.selection : null;
    const iconUrl = getAssetUrl(icon);
    const iconWhiteUrl = getAssetUrl(whiteIcon);
    const externalUrl = getValue(externalLink);
    const useExternal = getUseExternal(linkToInternalPageOrExternalLink);
    return ({
      id,
      path,
      externalUrl,
      useExternal,
      iconUrl,
      iconWhiteUrl,
      title: title ? title.value : null,
      type: typeStr,
      children: parsedChildren,
    });
  });
};


// method to generate a top level nav item from the content model returned by the API
const parseAPITopLevelItem = (content) => {
  const { id, elements } = content;
  const { slug, title, children } = elements;
  const parsedChildren = parseAPINavItems(children);
  return ({
    id,
    title: title ? title.value : null,
    path: slug ? slug.value : null,
    children: parsedChildren,
  });
};

// method to retrieve search for a navigation item by it's id.
// Does a recursive tree search to retrieve the item that matches
// the passed in id. The method also populates the id of the parent node
// to make it easier to navigate up the tree (useful for the mobile navigation)
const searchTreeForItemById = ({
  id,
  items,
  parentId,
}) => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.id === id) {
      const withParent = {
        ...item,
        parentId,
      };
      return withParent;
    }
    if (item.children) {
      // recurse down one level, passing in the item id as the parent
      const matchingChild = searchTreeForItemById({
        items: item.children,
        id,
        parentId: item.id,
      });
      if (matchingChild) { return matchingChild; }
    }
  }
  return null;
};

// Method to retrieve a navigation item by it's id
const getNavItemWithParentById = (id, navigationTree) => {
  return searchTreeForItemById({
    id,
    items: navigationTree,
  });
};

export {
  NAV_ITEM_COLLAPSE,
  NAV_ITEM_OPEN_RIGHT,
  NAV_ITEM_LINK,
  NAV_ITEM_LINK_MAIN,
  getNavItemWithParentById,
  parseAPITopLevelItem,
};
