import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { userActions } from 'redux/user/slice';
import { Form } from 'components/shared/form/Form';

import {
  BUTTON,
  COMPONENT,
  RADIO,
  ACCOUNT_DETAILS,
  SHOULD_ACCEPT_CONSENT,
} from 'components/shared/form/formConstants';
import { AnonymousFormConsent } from './AnonymousFormConsent';
import { PROFILE_SUCCESS_PATH } from 'routes/routeConstants';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import './AnonymousForm.scss';
import { MarketingConsent } from 'components/pageTemplates/login/MarketingConsent';
import { MarketingLabel } from 'components/pageTemplates/login/Marketing-label';
import { PrivacyConsent } from 'components/pageTemplates/login/PrivacyConsent';
import { TermsLabel } from 'components/pageTemplates/login/Terms-label';
const MarketOptinForm = ({ actions, isSubmitting }) => {
  const {
    tIConsentTo,
    tSave,
    anonymousFlowMarketingDescription,
    anonymousFlowMarketingRejectMessage,
    tThisFieldIsRequired,
  } = useWebsiteConfigObject();
  const { trackEventWithContentType } = useGoogleAnalytics();

  const getAccountDetailsFormFields = ({ promotionalMaterialsOptIn }) => [
    {
      id: "marketing-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingLabel,
      },
    },
    {
      id: "marketing-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingConsent,
      },
    },
    {
      label: "",
      id: "information-consent",
      initialValue: promotionalMaterialsOptIn || false,
      type: RADIO,
      mandatory: false,
      typeParams: {},
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          actions.marketOptinSubmission(formValues);
        },
        text: tSave ? tSave.toUpperCase() : "",
        type: "submit",
      },
    },

  ];
  const { fetching, details } = useSelector((state) => state.user);
  // const history = useHistory();
  // const dispatch = useDispatch();

  const formFields = getAccountDetailsFormFields(details);

  // const updateDetails = (formData) => {
  //   dispatch(userActions.global.details.update(formData)).then(() => {
  //     history.push(PROFILE_SUCCESS_PATH);
  //   });
  // };

  const marketOptinSubmission = actions.marketOptinSubmission;
  return (
    <div className='anonymous-market-form'>
      <Form
        fields={formFields}
        formId={ACCOUNT_DETAILS}
        actions={{ marketOptinSubmission }}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export { MarketOptinForm };
