import { getLocationOrigin, getUrlCountryPath } from 'utils/urlUtils';
import { getEnvApiConfigValues } from 'utils/envUtils';
import { owaAuth } from './owaApi';
import { docCheckAuth } from './doccheckApi';
import {
  createUserToken,
  verifyUserToken,
  refreshUserToken,
  registerNewUser,
  deleteUser,
  fetchUserDetails,
  updateUserDetails,
  forgottenPassword,
  passwordReset,
  changeUserPassword,
  updateUserConsent,
} from './genericApi';

const GENERIC = 'generic';
const KKI = 'kki';
const ONEKEY = 'oneKey';
const DOCCHECK = 'docCheck';
const { owasecureURL } = getEnvApiConfigValues();

const authService = {
  service: {
    generic: {
      redirects: null,
      api: {
        consent: {
          update: updateUserConsent,
        },
        token: {
          verify: verifyUserToken,
          refresh: refreshUserToken,
        },
        user: {
          details: {
            fetch: fetchUserDetails,
            update: updateUserDetails,
            deleteExternal: owaAuth.deleteUser,
          },
        },
      },
    },
    kki: {
      redirects: null,
      api: {
        token: {
          create: createUserToken,
        },
        user: {
          register: registerNewUser,
          delete: deleteUser,
          password: {
            forgot: forgottenPassword,
            reset: passwordReset,
            change: changeUserPassword,
          },
        },
      },
    },
    oneKey: {
      redirects: {
        register: owaAuth.owaRegisterRedirect,
        login: owaAuth.owaLoginRedirect,
        account: () => `${owasecureURL}login?_flowId=myaccount-webflow`,
        logout: () => `${owasecureURL}logout?service=${getLocationOrigin()}/${getUrlCountryPath()}/?action=logged-out`,
      },
      api: {
        token: {
          create: owaAuth.createUserToken,
        },
        callExtendedUserProfile: {
          extendedUserProfile: owaAuth.callExtendedUserProfile,
        },
      },
    },
    docCheck: {
      redirects: {
        register: docCheckAuth.docCheckRegisterRedirect,
        login: docCheckAuth.docCheckLoginRedirect,
      },
      api: {
        token: {
          create: docCheckAuth.createUserToken,
        },
      },
    },
  },
};

const getAuthService = ({ service = null } = {}) => (service ? authService.service[service] : authService.service.generic);
const getAuthServiceAPI = ({ service = null } = {}) => getAuthService({ service }).api;
const getAuthServiceRedirects = ({ service = null } = {}) => getAuthService({ service }).redirects;

export {
  GENERIC,
  KKI,
  ONEKEY,
  DOCCHECK,
  getAuthService,
  getAuthServiceAPI,
  getAuthServiceRedirects,
};
