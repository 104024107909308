import React from 'react';
import { useAuthServiceCMSPage } from 'utils/authUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';

const SearchResultWrapper = ({path}) => {
    const CMSPageDisplay = useAuthServiceCMSPage();
    const { tSearchRelatedPageUrl } = useWebsiteConfigObject();
    const content = {
        elements: {
            contentAccess: {
                value: {
                    selection: 'b'
                }
            },
            relatedPageUrl: {
                value: tSearchRelatedPageUrl
            }
        }
    }
    return <CMSPageDisplay
        content = {content}
        templateType = {'Search_Result'}
        path = {path}
    />
}

export default SearchResultWrapper;