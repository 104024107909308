import React from 'react';

import { getImgAttr } from 'api/acousticApi/index';
import { getValue } from 'utils/contentUtils';
import { stringToCssId } from 'utils/stringUtils';
import { contentElementProptype } from 'propTypes/contentProptypes';

// layouts
import Grid from 'layouts/grid/Grid';

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent';
import VideoCard from 'modules/videoCard/VideoCard';

// components
import RichText from 'components/reusableComponents/richText/RichText';
import Video from 'components/reusableComponents/video/Video';

// styles
import './ArticleSection.scss';
import AudioCard from 'modules/audioCard/AudioCard';


// component for an article section
const DynamicArticleSection = ({ element }) => {
  if (!element) {
    return null;
  }

  const {
    title,
    copy,
    image,
    video,
    videoTitle,
    videoPoster,
    videoPosterImage,
    webvttChaptersFile,
    subtitles,
    className,
    videoCardGrid,
    titleRichText,
    audioFile,
    externalVideoLink
  } = element.elements;

  const titleValue = getValue(title);

  let titleRichTextValue = getValue(titleRichText);
  if(titleRichTextValue) titleRichTextValue = titleRichTextValue.substring(3, titleRichTextValue.length - 4);
  
  const titleRichTextElement = { value: titleRichTextValue}

  const img = getImgAttr(image);

  const id = stringToCssId(title?.value);
  const isFullWidth = videoCardGrid?.value?.elements?.fullWidthVideoCards?.value

  if(audioFile?.url){
    const title = titleRichText?.value ? titleRichTextValue  : titleValue
    return (
      <AudioCard image={img} copy={copy} src={audioFile} title={title} />
    )
  }

  return (
    <section className={`article-section-component ${className}`} id={id}>
      {titleRichText?.value ? <RichText data={titleRichTextElement} className='article-section-title'/> : titleValue && <h2 className='article-section-title'>{titleValue}</h2>}
      <RichText data={copy} />
      {image.url && <img src={img.src} alt={img.alt} />}
      <Video
        title={videoTitle}
        element={video}
        webvttChaptersFile={webvttChaptersFile}
        subtitles={subtitles}
        videoPoster={videoPoster}
        videoPosterImage={videoPosterImage}
        externalVideo={externalVideoLink}
      />
      {videoCardGrid && (
          <RelatedContent bgColor='#ffffff' headingText={videoCardGrid?.value?.elements?.relatedVideosHeading?.value} fluid removeTopPadding>
            <Grid wrapperStyle={{ marginTop: 40 }} itemsPerRow={{ sm: 1, md: 2, lg: isFullWidth ? 1 : 3 }} gap={{ sm: 20, md: 25, lg: 25 }}>
              {videoCardGrid?.value?.elements?.relatedVideosVideoCards?.values?.map((video) => {
                  const { id, lastModified } = video || {}

                  const { videoCardContent, videoCardHeading, videoCardImage,externalVideoLink:videoCardExternalLink, videoCardLink, videoCardSubheading, videoCardVideo, webvttChaptersFile, subtitles, videoPoster } = video?.elements || {}

                  return (
                      <VideoCard
                          key={id}
                          href={videoCardLink?.linkURL}
                          image={videoCardImage}
                          video={videoCardVideo}
                          heading={videoCardHeading?.value}
                          subHeading={videoCardSubheading?.value}
                          content={videoCardContent?.value?.text}
                          postDate={lastModified}
                          isFullWidth={isFullWidth}
                          webvttChaptersFile={webvttChaptersFile}
                          subtitles={subtitles}
                          videoPoster={videoPoster}
                          videoPosterImage={videoPosterImage}
                          externalVideoLink={videoCardExternalLink}
                      />
                  )
              })}
            </Grid>
          </RelatedContent>
        )}
    </section>
  );
};

DynamicArticleSection.propTypes = {
  element: contentElementProptype,
};

export { DynamicArticleSection };
