import React from 'react';
import Image from 'components/reusableComponents/image/Image';
import { PlayIcon } from 'components/shared/SvgIcons';
//scss
import './MyHubTab.scss';

const MyHubTab = ({ title, icon, iconHover, tabActive, tabId }) => {
  return (
    <div className={`my-hub-tabs${tabActive === tabId ? ' active' : ''}`}>
      {icon?.asset ? <Image className='no-hover' data={icon} /> : <div className='no-hover no-hover--no-image'><PlayIcon /></div>}
      {iconHover?.asset ? <Image className='hover' data={iconHover} /> : <div className='hover hover--no-image'><PlayIcon /></div>}
      <div className="title">{title}</div>
    </div>
  );
};

export default MyHubTab;
