import React, {useState, useEffect, useRef} from "react";
import ReactHtmlParser from 'react-html-parser';
import { toggleOverlay } from './../helper';
import RichText from 'components/reusableComponents/richText/RichText';
import Link from 'components/reusableComponents/link/Link';
import ImageMapper from 'react-img-mapper';
import yearsArrow from '../../../../../assets/images/infographics-assets/brain/arrow-year-brain.png';
import close from '../../../../../assets/images/infographics-assets/circle-close-icon.svg';
import TimePopupsWrap from '../timepopups/timepopupswrap';
import parse from 'html-react-parser'; 


import './InfographicsDiagram.scss';
import { AppConfig } from 'AppConfig';
import Audio from "../Audio";

const InfographicsDiagram = (props) => {
 const soundIcon =  `
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
</svg>
`;
	const imagePicker	=props?.image
  	const mapValue = props?.elements
  	const areasText = props.areas?.values
      const [selectedArea, setSelectedArea] = useState();

	let areaArray = new Array();

	const areasObj = (name, shape, coords, fillColor,img, onclickColor,hoverColor, popupTitle, popUpDesc ) => {
		var obj = {
		  name:  name,
		  shape: shape,
		  coords: coords,
		  fillColor: fillColor,
		  imagePath: img,
		  onclickColor: onclickColor,
		  hoverColor: hoverColor,
		  popUp: {
			  title: popupTitle,
			  desc: popUpDesc
		  }
		};

        if(selectedArea?.area && selectedArea?.area?.name === name) { 
            obj.preFillColor = hoverColor
        }

		return obj;
	  }
	
	const toNumbers = arr => arr.map(Number);

    const convertHex = (hexCode,opacity = 100) => {
        let hex = hexCode.replace('#','');
    
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
    
        let r = parseInt(hex.substring(0,2), 16),
            g = parseInt(hex.substring(2,4), 16),
            b = parseInt(hex.substring(4,6), 16);
    
        return 'rgba('+r+','+g+','+b+','+opacity/100+')';
    }

	let imageSource = AppConfig.acousticApi.domain

    let imageSourceValue

    if(imagePicker?.url !=undefined){
        imageSourceValue = imageSource+""+ imagePicker?.url;   
    }
	
    if(imagePicker !=undefined ){
        let htmlImageMapParser = ReactHtmlParser(String(mapValue));

		const filterImageMapParser = htmlImageMapParser[0]?.props?.children
        let unsetCount = 0;

		filterImageMapParser?.map((ele) => {
			let hoverColor,
			onclickColor,
			fillColor;
			if(ele?.props?.onhovercolor === undefined ){
				hoverColor=""
			}
			else{
				
                if(ele?.props?.opacity=== undefined){
					hoverColor= convertHex(ele?.props?.onhovercolor)
				}
				else{
					hoverColor = convertHex(ele?.props?.onhovercolor,ele?.props?.opacity)
				}
			}
			if(ele?.props?.onclickcolor === undefined){
				onclickColor=""
			}
			else{
				onclickColor= ele?.props?.onclickcolor
			}
			if(ele?.props?.color===""){
				fillColor = convertHex("#ffffff", 0)
			}
			else{
				if(ele?.props?.opacity=== undefined){
					fillColor= convertHex(ele?.props?.color)
				}
				else{
					fillColor = convertHex(ele?.props?.color,ele?.props?.opacity)
				}
				
			}
            let name = ele?.props?.title
            if(!name) {
                unsetCount++;
                name = `custom-${unsetCount}`
            }
			areaArray.push(areasObj(name, ele?.props?.shape,toNumbers(ele?.props?.coords?.replaceAll('NaN', '0').split(',')), hoverColor,imagePicker?.url,onclickColor,hoverColor,ele?.props?.title,ele?.props?.title))
			
		})
    }
		var randomInt = Math.floor(100000 + Math.random() * 900000);   
    let randomId = String(randomInt);
    randomId= randomId.substring(0,4);
	let MAP = {
		name: props.id+randomId,
		areas: areaArray
	}

    const [popupOpen, setPopupOpen] = useState(false);
    const [width, setWidth] = useState(0);
    const [popupTitle, setPopupTitle] = useState();
    const [popupDetail, setPopupDetail] = useState();
    const [popupAccordionId,  setPopupAccordionId] = useState();
    const [popupAudioFile, setPopupAudioFile] = useState();
		const [popupAudioType, setPopupAudioType] = useState();
    const [popupAccordionLinkLabel, setPopupAccordionLinkLabel] = useState();
    const [popupLinkTarget, setPopupLinkTarget] = useState();
    const [popupLinkText, setPopupLinkText] = useState();
    const [popupLinkDescription, setPopupLinkDescription] = useState();
    const [brainImage, setBrainImage] = useState(imageSource && imagePicker?.url ? imageSource +""+ imagePicker?.url : null);
    const [isActive, setIsActive] = useState(true);
    const imageRef = useRef();
    useEffect(() => {
        if(imageRef?.current && imagePicker?.asset?.altText) {
            imageRef.current.querySelector('img').alt = imagePicker?.asset?.altText;
        }
        const OverlaySel = document.getElementById('js-overlay-modal');
        setWidth(window.innerWidth);
        function handleResize() {
            setWidth(window.innerWidth);
        }
        function togglePopup() {
            setPopupOpen(false);
						
            toggleOverlay('hide');
        }
        window.addEventListener("resize", handleResize);
        OverlaySel.addEventListener("click", togglePopup);

        const containerEl = document.getElementById('image-mapper-container');
        const containerWidth = containerEl?.getBoundingClientRect().width;
        const imageWidth = imagePicker?.asset?.width;
        const imageHeight = imagePicker?.asset?.height;
        if(containerWidth < imageWidth) {
            const scaleRatio = containerWidth / imageWidth;
            containerEl.children[0].style.transform = `scale(${scaleRatio})`;
            containerEl.style.height = `${scaleRatio * imageHeight}px`
        }else{
            containerEl.children[0].style.transform = 'none'
            containerEl.removeAttribute("style")
        }

        if(!popupOpen) { 
            setSelectedArea(null) 
        }
    });
    let title = new Array();
    let description = new Array();
    let accordionId = new Array();
    let audioFile= new Array();
		let audioType= new Array();
    let accordionLinkLabel = new Array();
    let linkTarget = new Array();
    let linkDescription = new Array();
    let linkText = new Array();
    areasText?.map((element,index) => {
        // if(element?.elements?.action?.value?.selection ==="displayPopup"){
            title.push(element?.elements?.title?.value)
            description.push (element?.elements?.description.value)
            accordionId.push(element?.elements?.accordionId?.value)
            accordionLinkLabel.push(element?.elements?.accordionLinkLabel?.value)
            linkText.push(element?.elements?.linkTarget?.linkText)
            linkTarget.push(element?.elements?.linkTarget?.linkURL)
            linkDescription.push(element?.elements?.linkTarget?.linkDescription)
        // }
				// if(element?.elements?.action?.value?.selection ==="playAudio"){
					audioFile.push(element?.elements?.audioFile?.url)
					audioType.push(element?.elements?.audioFile?.asset?.mediaType)
				// }
    })
	const openTooltip = (area,index,event) => {
		// if(area.onclickColor != ""){
		// 	area.fillColor = convertHex(area.onclickColor)
		// }
        // setIsActive(false)
        
	   	setPopupOpen(true);
	   	toggleOverlay('show', true);
	   	setPopupTitle(title[parseInt(area.name)-1]);
    	setPopupDetail(description[parseInt(area.name)-1]);
			setPopupAccordionId(accordionId[parseInt(area.name)-1]);
    	setPopupAudioFile(audioFile[parseInt(area.name)-1]);
    	setPopupAccordionLinkLabel(accordionLinkLabel[parseInt(area.name)-1]);
    	setPopupLinkTarget(linkTarget[parseInt(area.name)-1]);
    	setPopupLinkText(linkText[parseInt(area.name)-1]);
    	setPopupLinkDescription(linkDescription[parseInt(area.name)-1]);
			setPopupAudioType(audioType[parseInt(area.name)-1])
			setBrainImage(imageSource && area?.imagePath ? imageSource +""+ area?.imagePath : null)
            setSelectedArea({ area, coords: event.target.getAttribute("coords").split(",") })
    }
    const showTabHandler = (ev,tabID) => {
        let event = ev.target || ev.srcElement,
            elementSel = document.getElementById(tabID);
        
        if(!elementSel?.classList?.contains('open')){
            elementSel.click();
            elementSel.scrollIntoView({ behavior: 'smooth', block: 'start'});
            toggleOverlay('hide');
            setPopupOpen(!popupOpen);

        }
    }
		let big
		if(props?.size==="small"){
			big = {left: 0}
		}else{
			big = {
				left: "calc(50% - 250px)"
			}
		}

        const imageAlignment = props.element?.imageMap?.value?.imageAlignment?.value?.selection;
    return (
        MAP.areas.length > 0 ? (
        <div className="infographics-diagram__wrapper">
        {props.timedPopup !=null && typeof props.timedPopup !== undefined && <TimePopupsWrap contents={props.timedPopup} onDisableTimePopUp={props.onDisableTimePopUp} wrapClass="timepopups-infographic-wrap" /> }   

        <div className="">
            <div className="mdt-main-wrapper">
                <div className="infographics-diagram__infographics js-scroll-target" data-popup-target="brain-pathology-popup">
                    <div className={`mdt__infographics-image-container1${imageAlignment ? ` image--${imageAlignment.toLowerCase()}` : ''}`}>
                        {brainImage &&
                            <div className="caption-container1">
                                <div id="image-mapper-container" className="caption-container-wrap full-screen">
                                    {/* <img id="imageSource" src={imageSourceValue} style={{display:"none"}}/> */}
                                    <ImageMapper src={brainImage}
                                        containerRef={imageRef}
                                        map={MAP}
                                        active={isActive}
                                        width={imagePicker?.asset?.width}
                                        height={imagePicker?.asset?.height}
                                        onClick={(area,index,event) => openTooltip(area,index,event)}
                                        alt={imagePicker?.asset?.altText}
                                    /> 
                                </div>      
                            </div>
                        }
                            <div className={popupOpen?"custom-popup":"hide"} style={big} id="popup">
                                <div className="custom-popup__close">
                                    <img className="close" src={close} onClick={() => { 
                                        setPopupOpen(!popupOpen);
																				let audio = document.getElementById("audio");
																				if(audio){
																					audio.pause();
																					audio.currentTime = 0;
																					let mute= document.getElementById("player-button")
																					mute.innerHTML = soundIcon;
																				}
                                        toggleOverlay('hide');
                                        }} />
                                </div>
                                <div className="custom-popup__detail">
                                    {popupTitle &&<div className="title">{parse(popupTitle)} </div>}
						            						{popupDetail && <div className="desc">{parse(popupDetail)}</div>}
                                        <>
                                            <p>{popupLinkDescription}</p>
                                            <Link href={popupLinkTarget}>{popupLinkText}</Link>
                                        </>
																				{
																					popupAccordionId &&popupAccordionLinkLabel && <div className="timepopups__content-cta">
																					<button type="button" className="btn-popups" onClick={(ev) => {showTabHandler(ev,popupAccordionId)}} >
																					{popupAccordionLinkLabel}
																					</button>
																			</div>
																				}
																				{popupAudioFile && popupAudioType &&
																					<Audio
																						url={imageSource+""+popupAudioFile}
																						type={popupAudioType}
																					/>
																				}
                                        
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    ):null
    );
}

export default InfographicsDiagram;