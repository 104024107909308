import React from 'react'
import { getValues } from 'utils/contentUtils'
import { contentElementProptype, contentItemProptype } from 'propTypes/contentProptypes'

// modules
import Logo from 'modules/logo/Logo'
import Hero from 'modules/hero/Hero'
import Header from 'modules/header/Header'
import Subheader from 'modules/subheader/Subheader'
import RelatedContent from 'modules/relatedContent/RelatedContent'
import LinkPanel from '../../modules/linkPanel/LinkPanel'
import Subnavigation from '../../modules/subNavigation/SubNavigation'

// components
import { VideoSelector } from 'components/content/mainPromoPage/VideoSelector'
import { References } from 'components/content/References'
import Video from 'components/reusableComponents/video/Video'
import Image from 'components/reusableComponents/image/Image'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode';
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
  } from 'components/layout/ScreenWidthContext';

// styles
import './BrandLandingPage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'

/*
  Brand Landing Page (supercedes the Main Promo Page)
  Figma design here https://www.figma.com/file/iHhNCUBqTgthhUCuU6KCbx/KKI_Hub_Release2-3?node-id=315%3A112
*/

const BrandLandingPageHeaderMedia = ({ headerVideo, headerImage, videoPoster,externalVideoLink }) => {
    // Will only ever be a video or image selected to be placed in this position on the page - not both
    if (headerVideo && headerVideo.url) {
        return (
            <div className='media-container'>
                <Video element={headerVideo} externalVideo={externalVideoLink} videoPoster={videoPoster} />
            </div>
        )
    }
    if (headerImage && headerImage.url) {
        return (
            <div className='media-container'>
                <figure className='image'>
                    <Image data={headerImage} />
                </figure>
            </div>
        )
    }
    return null
}

BrandLandingPageHeaderMedia.propTypes = {
    headerImage: contentElementProptype,
    headerVideo: contentElementProptype,
}

const BrandLandingPage = ({ content }) => {
    const {      
        logo,
        brandColours,
        hero,
        title,
        header,
        subheader,
        subcontent,
        subnavigation,
        expertVideoSelectorTitle,
        expertVideoSelector,
        linkPanelGroup,
        carousel,
        relatedContent,
        bannerImage,
        references,
        veevaApprovalCode,
        
    } = content.elements

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const videos = getValues(expertVideoSelector)
    const { currentWidthRanges } = useScreenWidth();
    const hasVideos = videos && videos.length > 0
    const { primary, secondary } = brandColours?.value?.elements || {}
    const { panelCategory, panelTitle, moduleLinkPanels } = linkPanelGroup?.value?.elements || {}
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
    const settings = {
        infinite: carousel?.value?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 2,
        autoplay: isSmall ? false  : carousel?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.value?.elements?.automationInterval?.value ? parseFloat(carousel?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }

    return (
        <section className='brand-landing-page' role="main">
            <section className='brand-landing-page__logo'>
                <Logo content={logo} />
            </section>
            <section className='brand-landing-page__hero'>
                <Hero content={hero} />
            </section>
            <section className='brand-landing-page__header'>
                <Header title={title} content={header} backgroundColour={primary} />
            </section>
            <section className='brand-landing-page__subheader'>
                <Subheader content={subheader} />
            </section>            

            {/* This needs to be created into a module when there's time - it is currently not in use anywhere */}
            {hasVideos && (
                <section className='brand-landing-page__video-selector-section'>
                    <div className='container'>
                        <div className='brand-landing-page__video-selector'>
                            <h3 className='brand-landing-page__video-selector-h3'>{expertVideoSelectorTitle?.value}</h3>
                            <VideoSelector primaryColour='' primaryLightColour='' videoElements={videos} />
                        </div>
                    </div>
                </section>
            )}

            <section className='brand-landing-page__content-section'>
                <div className='container'>
                    <div className='brand-landing-page__content-section-inner'>
                        <div className='brand-landing-page__subnavigation'>
                            {subnavigation?.value &&
                                <Subnavigation element={subnavigation?.value} colour={primary?.value} /> 
                            }
                        </div>
                        <div className='brand-landing-page__content'>
                            <LinkPanel linkColour={secondary?.value} introTitleElement={panelTitle} category={panelCategory} linkElements={moduleLinkPanels?.values} />                           
                            {carousel && (
                                <RelatedContent 
                                    content={carousel} 
                                    componentType='cards' 
                                    bgColor='#ffffff' 
                                    carouselSettings={settings}
                                    cardTheme='secondary'
                                    alternateCardBg={true}
                                    fluid
                                />
                            )}
                        </div>
                    </div>
                </div>                
            </section>
            <section className='brand-landing-page__related-content'>
                {relatedContent && <RelatedContent content={relatedContent} cardTheme='secondary' contain />}
            </section>
            {bannerImage && <BannerImage image={bannerImage} />}
            <section className='brand-landing-page__subheader footer-subcontent-section'>
                <Subheader content={subcontent} />
            </section> 
            {adverseEventsComplianceText?.value && (
                <section className='brand-landing-page__references-section'>
                    <div className='container'>
                        <div className='brand-landing-page__references'>
                            {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                        </div>
                    </div>
                </section>
            )}
            {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
            {references?.value && (
                <section className='brand-landing-page__references-section'>
                    <div className='container'>
                        <div className='brand-landing-page__references'>
                            <References references={references} />
                        </div>
                    </div>
                </section>
            )}
        </section>
    )
}

BrandLandingPage.propTypes = {
    content: contentItemProptype,
}

export default BrandLandingPage
