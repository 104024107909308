/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getUrlCountryPath } from 'utils/urlUtils';

const hasParams = (url) => {
    return url.includes('?')
}

const shortcodes = [
    {
        name: "SSOLink",
        regex: /\[SSOLink (.+?)\]/g,
    }
]

const replaceAsciiInString = (str) => str && str.replace(/&#(\d+);/g, (m, n) => String.fromCharCode(n))

const useShortcodeParser = (value) => {

    const [stringFinal, setStringFinal] = useState('')

    const [extractedShortcodes, setExtractedShortcodes] = useState(null)

    const { guest } = useSelector((state) => state.authentication?.status)
    const { token } = useSelector((state) => state.authentication?.data?.token);

    let formattedValue = replaceAsciiInString(value)

    let attributes = []

    useEffect(() => {
        if (!extractedShortcodes) {

            shortcodes.forEach(shortcode => {
                const patt = shortcode.regex

                if (patt.test(formattedValue)) {
                    setExtractedShortcodes(formattedValue.match(patt))
                }

                setStringFinal(value)
            })

        }

        if (extractedShortcodes && extractedShortcodes.length) {

            extractedShortcodes.forEach((shortcode) => {

                let itemAttributes = {}

                shortcode.match(/[\w-]+=".+?"/g)?.forEach((attribute) => {
                    attribute = attribute.match(/([\w-]+)="(.+?)"/);

                    itemAttributes = { ...itemAttributes, [attribute[1]]: attribute[2] }
                });

                attributes.push(itemAttributes)
            })


            for (let i = 0; i < extractedShortcodes.length; i++) {
                const { name } = shortcodes.find((shortcode) => extractedShortcodes[i].includes(shortcode?.name))

                switch (name) {
                    case 'SSOLink':
                        if (!token) {
                            if (guest && ('allowGuest' in attributes[i]) && (attributes[i]?.allowGuest === 'true')) {
                                // TODO - Add logic to append generated guest userid/token to params
                                formattedValue = formattedValue.replace(extractedShortcodes[i], `<a href="${attributes[i]?.url}${hasParams(attributes[i]?.url) ? '&' : '?'}userId=123test&locale=${getUrlCountryPath()}" ${('disablePrompt' in attributes[i]) && (attributes[i]?.disablePrompt === 'true') ? 'disableprompt="true"' : ''}>${attributes[i]?.text}</a>`)
                            }
                            else 
                            {
                                formattedValue = formattedValue.replace(extractedShortcodes[i], `<a href="${attributes[i]?.url}" ${('disablePrompt' in attributes[i]) && (attributes[i]?.disablePrompt === 'true') ? 'disableprompt="true"' : ''}>${attributes[i]?.text}</a>`)
                            }
                        }
                        else 
                        {
                            formattedValue = formattedValue.replace(extractedShortcodes[i], `<a href="${attributes[i]?.url}${hasParams(attributes[i]?.url) ? '&' : '?'}at=${token}&locale=${getUrlCountryPath()}" ${('disablePrompt' in attributes[i]) && (attributes[i]?.disablePrompt === 'true') ? 'disableprompt="true"' : ''}>${attributes[i]?.text}</a>`)
                        }
                        break
                    default:
                        console.error("Shortcode Error: Something has gone wrong")
                }
            }

            setStringFinal(formattedValue)
        }
    }, [extractedShortcodes, value])


    return stringFinal 
}

export default useShortcodeParser