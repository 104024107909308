import { API_URL } from './paths';
import { authPostQuery } from '../AxiosQueries';
import { getEnvApiConfigValues } from 'utils/envUtils';
import { getAuthHeaderConfig } from 'utils/authUtils';

const { audience } = getEnvApiConfigValues();
const apiurl = API_URL;

const utmRegistration = (body, userId) => {
    const url = `${apiurl}/${userId}`;
    const headerConfig = getAuthHeaderConfig({
        audienceUrl: audience
    });
    return authPostQuery({ url, data: body, config: headerConfig });
};

export { utmRegistration };
