import React from 'react';
import { Form } from 'components/shared/form/Form';
import PropTypes from 'prop-types';
import './ExternalForm.scss';


const ExternalForm = ({
  externalFormFields,
  actions,
  isSubmitting,
}) => {
  const {
    id,
    formHeaderText,
    formDescription,
    formFields,
  } = externalFormFields[0];

  const fields = formFields;

  return (
    <div
      className="external-form-component"
    >
      <div
        className="external-form-inner"
      >
        <h2 className="form-title">
          {formHeaderText}
        </h2>
        <p className="form-p">
          {formDescription}
        </p>
        <hr style={{ marginBottom: 20 }} />
        <Form
          formId={id}
          fields={fields}
          actions={actions}
          isSubmitting={isSubmitting}
          wrapped
        />
      </div>
    </div>
  );
};






ExternalForm.propTypes = {
  externalFormFields: PropTypes.arrayOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
};



export { ExternalForm };

