import React from "react";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import "./TermsLabel.scss";

const TermsLabel = () => {
  const { tTermsLabel } = useWebsiteConfigObject();
    return <p className="terms-label">
        {tTermsLabel}
    </p>;
};

export { TermsLabel };
