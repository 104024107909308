import RichText from 'components/reusableComponents/richText/RichText';
import './NoticeBar.scss';
import { OpenClosedIndicator } from 'components/shared/OpenClosedIndicator';
import { useToggleOpen } from 'utils/toggleOpen';
import { getAssetUrl } from 'api/acousticApi';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const NoticeBar = ({ element }) => {
  const {
    logoImage,
    notificationText,
    moreInformation,
    showMoreLabel,
    showLessLabel,
  } = element.elements;
  const { open, toggleOpen } = useToggleOpen(false);
  const { trackEventWithContentType } = useGoogleAnalytics();

  const image = getAssetUrl(logoImage);

  return (
    <div className='notice-bar'>
      <div className='notice-bar-content'>
        {image && (
          <div className='logo'>
            <img src={image} alt={`${ logoImage?.asset?.altText ? logoImage?.asset?.altText : 'notice-bar-logo' }`} />
          </div>
        )}
        <div className='notice-expandable-section'>
          <RichText className='notice-text' data={notificationText} />
          {(moreInformation?.value && open) && (
            <RichText className='more-information' data={moreInformation} />
          )}
          {moreInformation?.value && 
            <button
              className='more-information-button'
              type='button'
              onClick={() => { 
                toggleOpen()
                trackEventWithContentType('collapsible-panel', 'collapsible-banner');
              }}
            >
              {!open ? showMoreLabel?.value : showLessLabel?.value}
              <OpenClosedIndicator open={open} />
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default NoticeBar;
