import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import domtoimage from 'dom-to-image';
import { useAuthService, KKI_AUTH, DOCCHECK_AUTH } from 'utils/authUtils';
import { storeLocation } from 'redux/location/slice';
import { DOCCHECK, ONEKEY, getAuthServiceRedirects } from 'api/authService';
import { ContentGate } from './components/ContentGate';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
    Loader,
    LARGE_LOADER,
} from 'components/shared/Loader';
import docCheckLogo from 'assets/images/doccheck-white.png';
import onekeyLogo from 'assets/images/onekey-white.png';

const blurNotSupported = () => {
    const ua = navigator.userAgent;
    return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
};

const useDomToImageBlurredCanvas = ({ loggedIn }) => {
    const [canvas, setCanvas] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (blurNotSupported()) {
            setLoading(false);
            setError(true);
            return;
        }

        // if user not logged in grab screenshot of dom and set to be used for bg image.
        // Remove real dom also.
        if (!loggedIn) {
            const node = document.getElementById('dom-to-img__wrapper');

            if (node) {
                setTimeout(() => {
                    domtoimage.toJpeg(node, { bgcolor: '#ffffff', quality: 1 }).then((dataUrl) => {
                        setCanvas(dataUrl);
                    }).catch((error) => {
                        setError(true);
                        console.error('oops, something went wrong!', error);
                    }).finally(() => {
                        setLoading(false);
                    });
                }, 175);
            }
        }
    }, [loggedIn]);


    return ({
        canvas,
        loading,
        error,
    });
}

// This is required to hide actual page for a bit longer so page can be 
// mounted fully for page screenshot to replace dom.
const ContentGateLoader = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 80,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            zIndex: 1010
        }}>
            <Loader
                className="center-aligned-loader"
                size={LARGE_LOADER}
            />
        </div>
    )
}

const ContentGateWrapper = ({ secure = false, children }) => {
    const {trackRegisterOption } = useGoogleAnalytics();
    const service = useAuthService();
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const { loggedIn, anonymous } = useSelector((state) => state.authentication.status);
    const { canvas, loading, error } = useDomToImageBlurredCanvas({ loggedIn });

    const { data } = useSelector((state) => state.authentication);

    const { consent } = data || {};
    const { optinHCP } = consent || {};
    const AUTH_TYPE = service === "OWA" ? ONEKEY : DOCCHECK

    const buttonLogo = {
        src: service === "OWA" ? onekeyLogo : docCheckLogo,
        alt: service === "OWA" ? 'OneKey' : 'DocCheck',
    };

    const loginRedirectAction = () => {
        dispatch(storeLocation({ pathname, search }));
        getAuthServiceRedirects({ service: AUTH_TYPE }).login();
    }

    const registerRedirectAction = () => {
        trackRegisterOption('register Option Pressed')
        getAuthServiceRedirects({ service: AUTH_TYPE }).register();
    }

    if (!loggedIn || optinHCP || anonymous || service === DOCCHECK_AUTH || service === null || service === KKI_AUTH) {
        return null;
    }

    const ContentGateProps = {
        props: {
            buttonLogo,
            loginRedirect: loginRedirectAction,
            registerRedirect: registerRedirectAction,
        }
    }

    if (error || !secure) {
        return (
            <>
                <ContentGate {...ContentGateProps.props} browserSupported={blurNotSupported() ? false : true} />
                {children}
            </>
        );
    }

    return (
        <>
            <ContentGate {...ContentGateProps.props} isSecure={secure} canvas={canvas} browserSupported={blurNotSupported() ? false : true} />

            {
                !canvas && (
                    <>
                        {
                            loading && (
                                <ContentGateLoader />
                            )
                        }
                        <div
                            id="dom-to-img__wrapper"
                            style={{
                                overflow: 'hidden',
                                height: '100vh',
                            }}>
                            {children}
                        </div>
                    </>
                )
            }
        </>
    );
}


export {
    ContentGateWrapper,
}