import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//components
import { Form } from 'components/shared/form/Form';
import RichText from 'components/reusableComponents/richText/RichText';
import { event } from '../../components/pageTemplates/event/formBuilder';

//api
import { eventRegistration } from 'api/eventsApi';

//svg
import { Tick } from 'components/shared/SvgIcons';

//styles
import './FormBuilder.scss';

const FormBuilder = (props) => {
  const {
    cancelButton,
    clearButton,
    errorResponse,
    formBuilder,
    formFooterDescription,
    formName,
    invalidFieldLabel,
    mandatoryLabel,
    requiredLabel,
    submissionType,
    submitButton,
    successPage,
    successResponse,
    successfulRedirection,
    fieldLengthErrorMessage,
  } = props?.element?.elements;

  const { webinarId, webinarProvider, customSubmitAction} = props;

  const history = useHistory();

  const { id } = props?.element;
  const [errorOccured, setErrorOccured] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);

  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user?.userId || null;
  let userDetails = userId ? user : null;
  let customSubmit = null;
  /**
   * body of the request
   */
  let body = {};
  body['eventType'] = submissionType?.value?.selection || '';
  body['eventProvider'] = 'hub';
  if(userId) {
    body['userId'] = userId;
  }
  if(webinarId){
    body['eventId'] = webinarId;
  } else {
    body['eventId'] = id;
  }
  if(webinarProvider){
    body['eventProvider'] = webinarProvider;
  }
  /**
   * Form variables definition
   */
  const formFields = formBuilder?.values;
  const FormbuilderRegisteredMessageRef = useRef(null);

  const handleConfirmation = (formValues) => {
    let errors = document.querySelectorAll(".textarea-error"); 
    if(errors.length === 0) {
      setIsSubmitting(true);
      let bodyFields = new Array();
      formFields.map((field) => {
        let fieldSubset = {};
        let fieldKey = field?.elements?.fieldKey?.value;
        let isPii = field?.elements?.isPii?.value;
        fieldSubset['fieldKey'] = fieldKey;
        fieldSubset['fieldValue'] = formValues[fieldKey];
        fieldSubset['isPii'] = isPii;
        bodyFields.push(fieldSubset);
        if(field?.elements?.fieldType?.value?.selection === "complexDropdown"){
          let fieldSubset1 = {};
          let fieldKey1 = field?.elements?.fieldKeySecondary?.value;
          let isPii1 = field?.elements?.secondaryIsPii?.value;
          if(fieldKey1){
            fieldSubset1['fieldKey'] = fieldKey1;
            fieldSubset1['fieldValue'] = formValues[fieldKey1];
            fieldSubset1['isPii'] = isPii1;
            bodyFields.push(fieldSubset1);
          }
        }
      });
      body['fields'] = bodyFields;
      eventRegistration({ body })
        .then((response) => {
          setIsSubmitting(false);
          if (response.data.status === 200) {
            setHasRegistered(true);
            if (successfulRedirection?.value && successPage?.value) {
              history.push(successPage?.value);
            } else {
              setRegistrationSuccess(true);
            }
          } else {
            setErrorOccured(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          setErrorOccured(true);
        });
    }
  };

  if(customSubmitAction && customSubmitAction?.webinarSignUpDataCaptureAction){
    customSubmit = customSubmitAction?.webinarSignUpDataCaptureAction || null;
  }

  const handleConfirmationExtern = (formValues) => {
    let errors = document.querySelectorAll(".textarea-error"); 
    if(errors.length === 0) {
      setIsSubmitting(true);
      let bodyFields = new Array();
      formFields.map((field) => {
        let fieldSubset = {};
        let fieldKey = field?.elements?.fieldKey?.value;
        let isPii = field?.elements?.isPii?.value;
        fieldSubset['fieldKey'] = fieldKey;
        fieldSubset['fieldValue'] = formValues[fieldKey];
        fieldSubset['isPii'] = isPii;
        bodyFields.push(fieldSubset);
        if(field?.elements?.fieldType?.value?.selection === "complexDropdown"){
          let fieldSubset1 = {};
          let fieldKey1 = field?.elements?.fieldKeySecondary?.value;
          let isPii1 = field?.elements?.secondaryIsPii?.value;
          if(fieldKey1){
            fieldSubset1['fieldKey'] = fieldKey1;
            fieldSubset1['fieldValue'] = formValues[fieldKey1];
            fieldSubset1['isPii'] = isPii1;
            bodyFields.push(fieldSubset1);
          }
        }
      });
      customSubmit(bodyFields);
    }
  };

  /**
   * looping and making a custom array of form fields
   */
  let builder = formBuilder?.values;
  let array = new Array();
  function getbuilder() {
    if (builder && builder.length) {
      for (let i = 0; i < builder.length; i++) {
        if(builder[i]?.elements?.fieldType?.value?.selection === "complexDropdown"){
          const { 
            fieldType,
            optionsJson,
            fieldKey,
            fieldLabel,
            firstValuePrimary,
            isMandatory,
            isPii,
            fieldKeySecondary,
            fieldLabelSecondary,
            firstValueSecondary,
            secondaryIsMandatory,
            secondaryIsPii,
          } = builder[i]?.elements
          let firstDropdown = {};
          firstDropdown.elements = {
            fieldType: fieldType,
            isPii: isPii,
            isMandatory: isMandatory,
            fieldLabel: fieldLabel,
            fieldKey: fieldKey,
            optionsJson: optionsJson,
            firstValue: firstValuePrimary,
          }
          let secondDropdown = {};
          secondDropdown.elements = {
            fieldType: fieldType,
            isPii: secondaryIsPii,
            isMandatory: secondaryIsMandatory,
            fieldLabel: fieldLabelSecondary,
            fieldKey: fieldKeySecondary,
            optionsJson: optionsJson,
            firstValue: firstValueSecondary,
            isSecondary: true,
            primaryKey: fieldKey,
          }
          array.push(event(firstDropdown, userDetails));
          array.push(event(secondDropdown, userDetails));

        } else {
          array.push(event(builder[i], userDetails));
        }
      }
    }
  }
  
  getbuilder();
  const submitBtn = {
    id: 'confirm-event',
    type: 'button',
    size: 50,
    customiSSubmitting: isSubmitting,
    // color: primaryColor?.value ? primaryColor?.value : '',
    typeParams: {
      callBack: ({ formValues, actions }) => {
        if(customSubmitAction){
          actions.handleConfirmationExtern(formValues);
        } else {
          actions.handleConfirmation(formValues);
        }
      },
      text: submitButton?.value || 'Submit',
      errorText: errorResponse,
      errorOccured: errorOccured,
      mandatoryLabel: mandatoryLabel?.value,
      type: 'submit',
    },
  };

  array.push(submitBtn);
  useEffect(() => {
    setTimeout(() => {
      if (hasRegistered && FormbuilderRegisteredMessageRef.current) {
        window.scrollTo({
          top: FormbuilderRegisteredMessageRef.current.offsetTop - 200,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }, [hasRegistered]);
  return (
    <>
      <div 
        className='content_item--form'
        ref={FormbuilderRegisteredMessageRef}
      >
        {!hasRegistered && (
          <>
            {formName?.value && (
              <div className='formName'> {formName?.value}</div>
            )}
            <Form
              formId='eapc-form'
              fields={array}
              actions={{ handleConfirmationExtern ,handleConfirmation }}
              requiredLabel={requiredLabel?.value}
              invalidFieldLabel={invalidFieldLabel?.value}
              wrapped
            />
            <div className='form-labels'>
              {formFooterDescription?.value && (
                <div className='form-bottom-label'>
                  <RichText data={formFooterDescription} />
                </div>
              )}
            </div>
          </>
        )}
        {registrationSuccess && (
          <div
            className='formbuilder-sign-up__success-modal'
          >
            <div className='success-modal__content'>
              <Tick />
              {successResponse?.value ? (
                <RichText data={successResponse} />
              ) : (
                <p>You have successfully registered</p>
              )}
              <button
                onClick={() => {
                  setRegistrationSuccess(false);
                }}
              >
                {clearButton?.value ? clearButton?.value : 'Close'}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FormBuilder;
