import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import Link from 'components/reusableComponents/link/Link';
import { ChevronRight } from 'components/shared/SvgIcons';
import './ChaptersPagination.scss';


// Component for the chapter pagination for dynamic article page
const ChaptersPagination = ({ chapter }) => {
  const currentPath = window.location.href;
  const chapters = chapter.elements.chapter.values;
  const chaptersCount = chapters.length;
  let currentChapterIndex = -1;
  let prevChapterHref = null;
  let nextChapterHref = null;
  let currentChapterText = null;

  // Iterate through all chapters to find a match and index for current page URL
  if (chapters) {
    for (let index in chapters) {
      const currentChapter = chapters[index];
      if (currentPath.indexOf(currentChapter.linkURL) !== -1) {
        currentChapterIndex = Number(index);
        break;
      }
    }

    // currentPage is not part of chapters - do not show chapters pagination component
    if (currentChapterIndex === -1) return null;

    // If currentPage is part of chapters
    if (currentChapterIndex !== -1){
      // Get current page link text
      currentChapterText = chapters[currentChapterIndex].linkText;

      // Get previous page link URL
      if (currentChapterIndex >= 1){
        prevChapterHref = chapters[currentChapterIndex - 1].linkURL;
      }

      // Get next page link URL
      if (currentChapterIndex < chaptersCount-1){
        nextChapterHref = chapters[currentChapterIndex + 1].linkURL;
      }
    }
  }

  return (
    <div className="chapters-pagination">
      {prevChapterHref && (
        <Link
          className="chapters-pagination__button chapters-pagination__button--prev"
          href={prevChapterHref}
        >
          <ChevronRight />
        </Link>
      )}
      {currentChapterText && (
        <>
          <span className="chapters-pagination__chapter-count">{(++currentChapterIndex) + ' / ' + chaptersCount}</span>
          <p className="chapters-pagination__chapter-text">{currentChapterText}</p>
        </>
      )}
      {nextChapterHref && (
        <Link
          className="chapters-pagination__button chapters-pagination__button--next"
          href={nextChapterHref}
          vvClickAttr='click'
          vvCountAttr={chaptersCount}
        >
          <ChevronRight />
        </Link>
      )}
    </div>
  );
};

ChaptersPagination.propTypes = {
  chapter: contentElementProptype,
};

export { ChaptersPagination };
