import { useMediaQuery } from 'react-responsive'

const useResponsive = () => {
    const TABLET_MIN = 768, DESKTOP_MIN = 1024

    const isDesktopUp = useMediaQuery({ minWidth: DESKTOP_MIN })
    const isTabletUp = useMediaQuery({ minWidth: TABLET_MIN })
    const isMobileTabletOnly = useMediaQuery({ maxWidth: DESKTOP_MIN - 1 })
    const isTabletOnly = useMediaQuery({ minWidth: TABLET_MIN, maxWidth: DESKTOP_MIN - 1 })
    const isMobileOnly = useMediaQuery({ maxWidth: TABLET_MIN - 1 })

    const Desktop = ({ children }) => isDesktopUp ? children : null
    const Tablet = ({ children }) => isTabletOnly ? children : null
    const Mobile = ({ children }) => isMobileOnly ? children : null

    return {
        isDesktopUp,
        isTabletUp,
        isTabletOnly,
        isMobileTabletOnly,
        isMobileOnly,
        Desktop,
        Tablet,
        Mobile
    }
}

export default useResponsive