import React from 'react'
import { MainAreaTitleSection, MAIN_AREA_TITLE_CONTENT_PROMO } from 'components/layout/MainAreaTitleSection'
import { ApprovalCode } from 'components/content/ApprovalCode'
import { References } from 'components/content/References'
import { getColourHashValue, getValue } from 'utils/contentUtils'
import { getAssetUrl } from 'api/acousticApi'
import SideNavigation from '../../modules/sideNavigation/SideNavigation'
import RichText from 'components/reusableComponents/richText/RichText'
import { PDFPreview } from 'components/reusableComponents/pdfPreview/PDFPreview'
import Image from 'components/reusableComponents/image/Image'
import Link from 'components/reusableComponents/link/Link'
import RelatedContent from 'modules/relatedContent/RelatedContent'
import { BannerImage } from 'components/content/BannerImage'
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
  } from 'components/layout/ScreenWidthContext';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'

/*
  Content promo page
  Figma design here https://www.figma.com/file/rzkpaL4Xk0TFxbXRxlNhtN/Global-Navigation-V5.0?node-id=42%3A8317
  2.2.1 Promo page
*/

const ContentPromoPage = ({ content }) => {
    const {
        headerText,
        section1BodyCopy,
        section2BodyCopy,
        title,
        subtitle,
        veevaApprovalCode,
        references,
        primaryColourHashNumber,
        subnavigation,
        pdfPreview,
        carousel,
        medicationLogo,
        medicationLogoLink,
        relatedContent,
        bannerImage
    } = content ? content.elements : {}
    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

    const settings = {
        infinite: carousel?.value?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        autoplay: isSmall ? false  : carousel?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.value?.elements?.automationInterval?.value ? parseFloat(carousel?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: { arrows: false },
            },
        ],
    }

    const pdfUrl = getAssetUrl(pdfPreview)
    const cssClass = 'content-page-promo-content-keyinfo'
    const cssClasses = [cssClass]

    if (pdfUrl) {
        cssClasses.push('--pdf')
    }

    return (
        <section role='main' className='global_layout' data-layout-type='content-promo-page'>
            {medicationLogoLink?.linkURL && (
                <section className='brand_logo_section'>
                    <div className='container'>
                        <Link href={medicationLogoLink.linkURL}>
                            <Image data={medicationLogo} />
                        </Link>
                    </div>
                </section>
            )}

            <MainAreaTitleSection title={title?.value} description={headerText} category={subtitle?.value} backgroundHexColour={primaryColourHashNumber} type={MAIN_AREA_TITLE_CONTENT_PROMO} />

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-2-left'>
                            {subnavigation?.value && <SideNavigation element={getValue(subnavigation)} colour={getColourHashValue(primaryColourHashNumber)} />}
                        </section>
                        <section className='content_wrapper content_wrapper--columns-2-center'>
                            {(section1BodyCopy && section1BodyCopy?.value) && (
                                <div className='content_item'>
                                    <RichText data={section1BodyCopy} />
                                </div>
                            )}
                            {pdfUrl && (
                                <div className='content_item'>
                                    <PDFPreview pdfUrl={pdfUrl} src={pdfPreview} />
                                </div>
                            )}

                            {(carousel && carousel?.value) && (
                                <div className='content_item'>
                                    <RelatedContent bgColor='#F6F6F5' content={carousel} componentType='slides' carouselSettings={settings} cardTheme='secondary' fluidRight removeLeftPadding />
                                </div>
                            )}

                            {(section2BodyCopy && section2BodyCopy?.value) && (
                                <div className='content_item'>
                                    <RichText data={section2BodyCopy} />
                                </div>
                            )}

                            {veevaApprovalCode && veevaApprovalCode?.value && (
                                <div className='content_item'>
                                    <ApprovalCode code={veevaApprovalCode} />
                                </div>
                            )}
                        </section>
                        <div className='content_wrapper content_wrapper--columns-2-right'></div>
                    </div>
                    {relatedContent && <RelatedContent content={relatedContent} cardTheme='secondary' fluid />}
                    {bannerImage && <BannerImage image={bannerImage} />}
                    <ApprovalCode code={veevaApprovalCode} />
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    <References references={references} />
                </div>
            </section>
        </section>
    )
}

export default ContentPromoPage
