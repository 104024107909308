import React from 'react'
import './DesktopSubNavigation.scss'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import NavLink from 'components/reusableComponents/navLink/NavLink'


const DesktopSubNavigation = () => {

    // Redux navigation state
    const { main } = useSelector(state => state.ui.navigation)

    // Get current path
    const location = useLocation()

    // Breakdown URL to array of items in order
    const locationArray = location.pathname.substring(1).split("/")

    // If it's home page and "main" not loaded yet don't render anything.
    if(main === null || locationArray[0] === undefined  ||  locationArray[0] === '') return null

    // Get selected main link children
    const firstSubLevel = main.find(item => item.path === `/${locationArray[0]}`)

    // Get selected first level children
    let secondSubLevel = null 
    if(locationArray.length >= 2 && firstSubLevel) {
        secondSubLevel = firstSubLevel.children.find(item => item.path === `/${locationArray[0]}/${locationArray[1]}`)
    }

    return (
        <>
            { firstSubLevel?.children && <SubNav level={1} items={firstSubLevel.children} /> }
            { secondSubLevel?.children && <SubNav level={2} items={secondSubLevel.children} /> }
        </>
    )
}

const SubNav = ({ level, items }) => {
    const location = useLocation();
    const { pathname } = location;

    return (
        <div className={`DesktopSubNavigation${level}`}>
            <ul className={`SubNavigation${level}`}>
                {
                    items.map((navItem, index) => (
                        <li key={navItem.id}>
                            <NavLink 
                                href={navItem.path || '#'} 
                                activeClassName={level === 2 && navItem.path !== pathname ? "" : "active"}
                                vvClickAttr='click'
                                vvSequenceAttr={`${index+1}`}
                            >
                                {navItem.title}
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default DesktopSubNavigation