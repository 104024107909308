import React from 'react';
import PropTypes from 'prop-types';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { EmbeddedPdf } from 'components/shared/EmbeddedPdf';
import Link from '../link/Link';

const PDFPreview = ({
  pdfUrl,
  src
}) => {
  const {
    tDownloadPdf,
  } = useWebsiteConfigObject();
  return (
    <div className="pdf-preview">
      <EmbeddedPdf
        pdf={pdfUrl}
      />
      <p>
        <Link target="_blank" href={pdfUrl} rel="noopener" noConfirm assetName={src?.asset?.fileName}>{tDownloadPdf}</Link>
      </p>
    </div>
  );
};

PDFPreview.propTypes = {
  pdfUrl: PropTypes.string,
  src: PropTypes.object
};

export { PDFPreview };
