import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

//components
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import Image from 'components/reusableComponents/image/Image';
import MyHubSettings from './components/MyHubSettings';
import RichText from 'components/reusableComponents/richText/RichText';
//tabs
import MyHubTab from './components/MyHubTab';
import CoursesTab from './components/CoursesTab';
import WebinarTab from './components/WebinarTab';

//utils
import { useWebsiteConfigDeep, useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { LOGIN_PAGE_PATH, PROFILE_PAGE_PATH } from 'routes/routeConstants';
import { searchQuery } from 'api/acousticApi';
import { useQuery } from 'react-query';
import { getUrlCountryData } from 'utils/libraryUtils';
import { usePageContent } from 'api/acousticApi/ContentHooks';
//redux
import { userActions } from 'redux/user/slice';

import './MyHubProfile.scss';
import '../profile/TooltipAnonymous.scss';
import MyHubDocNumber from './components/MyHubDocNumber';

import { useScreenWidth, SCREEN_WIDTH_MOBILE } from 'components/layout/ScreenWidthContext';

const MyHubProfile = () => {
  /**
   * configs is used to get the data for the whole my hub section
   */
  
  const configs = useWebsiteConfigDeep().websiteConfig?.myHubComponent;
  const { currentWidthRanges } = useScreenWidth();
  const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.global.details.fetch());
  }, []);

  const { loggedIn } = useSelector((state) => state.authentication.status);

  const { fetching, details } = useSelector((state) => state.user);
 
  const [tabActive, setTabActive] = useState('default');
  const [close, setClose] = useState(false);

  let type = history.location?.state?.state || null;
  let reOpenTooltip = false;

  if( type ) {
    reOpenTooltip = type.openTooltip;
  }

  useEffect(() => {
    if( close && reOpenTooltip ){
      history.push({pathname:PROFILE_PAGE_PATH},{state: { type: "Anonymous-user-flow" , redirect : type.redirect , openTooltip : false }});
      setClose(false);
    }
  })



  const {
    profileMarketOptInPopupCloseLabel,
    profileMarketOptInPopupText,
    tProfilePageVeevaCode,
    disableMarketingConsent
  } = useWebsiteConfigObject();

  const { libraryId } = getUrlCountryData();

  const routesSearchParams = {
    q: '*:*',
    fl: 'name,string1,document:[json],id,classification,type,status,boolean1,string3',
    fq: `type:("layout f - webinar page")`,
    rows: 10000,
    start: 0,
  };

  const params = new URLSearchParams(routesSearchParams);

  params.append('fq', `libraryId:("${libraryId}")`);

  const test = () => searchQuery(params);
  let listOfWebinars = useQuery(
    'webinar-query1',
    test,
    { staleTime: Infinity } // only request the api routes once
  );
  
  if (!loggedIn) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE_PATH,
          state: { returnPath: PROFILE_PAGE_PATH },
        }}
      />
    );
  }

  if (!configs || fetching) {
    return (
      <section role='main' className='loading-page'>
        <Loader className='center-aligned-loader' size={LARGE_LOADER} />
      </section>
    );
  }

  /**
   *
   * const for my hub sections
   * */
  const {
    sectionPicker,
    settingsIcon,
    settingsLabel,
    specialityLabel,
    title,
    workPlaceLabel,
    settingsTitle,
    myHubIcon,
    settingsIconHover,
  } = configs?.elements;

  const { firstname, lastname, speciality, workplace, role, jobTitle, department, practiceNum, departmentNum,marketingConsent, promotionalMaterialsOptIn } = details;

  /**
   *
   * Function for changing tabs
   */
  const handleClick = (changedTab) => {
    setTabActive(changedTab);
  };
  return (
    <>
      {!disableMarketingConsent && type && !promotionalMaterialsOptIn && tabActive === 'default' && !close &&
        <div className='container-anonymous-tooltip'>
          <div className='container anonymous-tooltip'>
            <div className='tooltip-text'>
              {profileMarketOptInPopupText?.value && (
                <RichText data={profileMarketOptInPopupText} />
              )}
            </div>
            <div className='tooltip-button'>
              <button onClick={() => setClose(true)}>
                {profileMarketOptInPopupCloseLabel || 'Close'}
              </button>
            </div>
          </div>
        </div>
      }
      <div className='my-hub'>
        <div className='my-hub__title'>
          <h1>{title?.value}</h1>
        </div>
        <div className='my-hub__tabs'>
          <div className='my-hub__tabs__left'>
            <div className='my-hub__tabs__left__details'>
              <Image data={myHubIcon} />
              <div className='my-hub__tabs__left__details__texts'>
                <div className='my-hub__tabs__left__details__texts__name'>
                  {firstname} {lastname}
                </div>
                <div className='my-hub__tabs__left__details__texts__detail'>
                  {specialityLabel?.value} {speciality}
                </div>
                <div className='my-hub__tabs__left__details__texts__detail desktop'>
                  {workPlaceLabel?.value} {workplace}
                </div>
              </div>
            </div>
            <div className='my-hub__tabs__left__anchors'>
              <div
                className='my-hub__tabs__left__anchors__tabs'
                onClick={() => handleClick('default')}
              >
                <MyHubTab
                  title={settingsLabel?.value}
                  tabActive={tabActive}
                  tabId='default'
                  icon={settingsIcon}
                  iconHover={settingsIconHover}
                />
              </div>

              {sectionPicker?.values.map((section) => {
                if(section?.elements?.activate?.value === true) {
                  return (
                    <div
                      key={section.id}
                      className='my-hub__tabs__left__anchors__tabs'
                      onClick={() => handleClick(section.id)}
                    >
                      <MyHubTab
                        tabActive={tabActive}
                        tabId={section.id}
                        title={section?.elements?.sectionText?.value}
                        iconHover={section?.elements?.sectionIconHover}
                        icon={section?.elements?.sectionIcon}
                      />
                    </div>
                  );
                }
                return;
              })}
            </div>
            {!isMobile && <MyHubDocNumber docNumber={tProfilePageVeevaCode} />}
          </div>
          <div className='my-hub__tabs__right'>
            <MyHubSettings
              tabActive={tabActive}
              tabId='default'
              title={settingsTitle}
              type={type}
            />
            {sectionPicker?.values.map((section) => {
              if (section?.elements?.resources?.value?.type === "Module - My Hub E-learning") {
                if(section?.elements?.activate?.value === true) {
                  return (
                    <CoursesTab
                      key={section.id}
                      tabActive={tabActive}
                      tabId={section.id}
                      section={section}
                    />
                  );
                }
              }
              if (section?.elements?.resources?.value?.type === "Module - My Hub Webinar") {
                if(section?.elements?.activate?.value === true) {
                  return (
                    <WebinarTab
                      key={section.id}
                      tabActive={tabActive}
                      tabId={section.id}
                      section={section}
                      listOfWebinars={listOfWebinars?.status === "success" ? listOfWebinars?.data : null}
                    />
                  );
                }
              }
              return;
            })}
            {isMobile && <MyHubDocNumber docNumber={tProfilePageVeevaCode} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyHubProfile;
