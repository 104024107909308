import React, { useState, useRef, useEffect } from 'react'
import './UserStatus.scss'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Link from 'components/reusableComponents/link/Link'
import NavLink from 'components/reusableComponents/navLink/NavLink'
import { closeMobileNav, toggleUserMenu } from 'redux/ui/slice'
import { UserIcon, NewDownArrow } from 'components/shared/SvgIcons'
import { LOGIN_PAGE_PATH } from 'routes/routeConstants'
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const UserStatus = () => {
    const dispatch = useDispatch()

    const [showUserStatus, setShowUserStatus] = useState(false);
    const { trackGTMEvent } = useGoogleAnalytics();

    setTimeout(()=>{
        if(!showUserStatus){
          setShowUserStatus(!showUserStatus);
        }
      },3500);

    const { status, data } = useSelector((state) => state.authentication)

    const closeNav = () => {
        trackGTMEvent("login_intent", "header_button");
        dispatch(closeMobileNav())
    }

    return <div className='UserStatus'>{status?.loggedIn && showUserStatus && !status?.limitedAccess ? <Authenticated firstName={data?.user?.firstName} closeNav={closeNav} /> : <Guest closeNav={closeNav} />}</div>
}

// Guest tHeaderLoginButtonText
export const Guest = ({ closeNav, registerOnly }) => {
    const { tHeaderLoginButtonText, tRegister } = useWebsiteConfigObject()
    const buttonText = registerOnly ? tRegister : (tHeaderLoginButtonText || 'Login / Register')

    return (
        <div className='Guest'>
            <Link className='UserStatus_CTA' onClick={closeNav} href={LOGIN_PAGE_PATH}>
                <span>{buttonText}</span>
            </Link>
        </div>
    )
}

// Authenticated
const Authenticated = ({ firstName, closeNav }) => {
    const userStatusRef = useRef()
    const { redesign } = useSelector(state => state.ui);

    const location = useLocation()

    const dispatch = useDispatch()

    const { userMenuStatus, navigation } = useSelector((state) => state.ui)

    const keyPress = (e) => {
        if (e.charCode === 13)
            toggleMenu();
    }

    const onClick = (event) => {
        event.stopPropagation()
        toggleMenu();
    }

    const toggleMenu = () => {
        closeNav()
        dispatch(toggleUserMenu())
    }

    const handleClickOutside = (event) => {
        if (userStatusRef.current && !userStatusRef.current.contains(event.target)) {
            event.stopPropagation()

            closeNav()
            dispatch(toggleUserMenu())
        }
    }

    // Handle toggle off of user nav dropdown when click outside of dropdown itself
    useEffect(() => {    
        if (userMenuStatus) {
            document.addEventListener('click', handleClickOutside, true)
        }    

        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMenuStatus])

    return (
        <div className='Authenticated' onClick={onClick} onKeyPress={keyPress} tabIndex="0">
            <span className='UserStatus_Name'>{firstName}</span>
            <div className={`UserStatus_Icon ${userMenuStatus ? 'UserStatus_Icon--open' : ''}`}>
                <UserIcon />
                <ul ref={userStatusRef} className={`UserStatus_Menu ${userMenuStatus ? 'UserStatus_Menu--open' : ''}`}>
                    {navigation?.user?.children?.map((item) => {

                        const path = item?.externalUrl || item?.path || null

                        if (!path) {
                            return null
                        }
                        
                        // Todo: Add trust level to this component here
                        return (
                            <li key={item.id}>
                                <NavLink href={path} state={{ prevPath: location.pathname }} activeClassName='active' noConfirm={item.id === 'logout'} sameTab>
                                    <span className='UserStatus_Menu_Icon Grey' style={{ backgroundImage: `url(${item.iconUrl})` }}></span>
                                    <span className='UserStatus_Menu_Icon White' style={{ backgroundImage: `url(${item.iconWhiteUrl})` }}></span>
                                    <span className='UserStatus_Menu_Title'>{item.title}</span>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
                {redesign && <NewDownArrow className="UserStatus_down-arrow" />}
            </div>
        </div>
    )
}

export default UserStatus
