import { createRef, useRef } from 'react'

const useMultipleRefs = ({ length }) => {
    const elementRefs = useRef([])

    elementRefs.current = Array(length).fill(0).map((ele, i) => elementRefs.current[i] ?? createRef())

    return {
        elementRefs,
    }
}

export default useMultipleRefs