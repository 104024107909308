import { useQuery } from "react-query";
import { getValue, getValues } from "utils/contentUtils";
import { websiteConfigQuery } from "./websiteConfigQuery";
import { usePageContent } from "../api/acousticApi/ContentHooks";

const WEBSITE_CONFIG_QUERY_KEY = "website-config";

// parse the search results from the api query into a useable config data object.
// config data includes:
// - seoTitle for the page headers
// - seoDescription for the page headers
const parseWebsiteConfigQueryResults = (queryResults) => {
  // search results have the document element in queryResults.data.documents[0].document
  const data = queryResults ? queryResults.data : null;
  const documents = data ? data.documents : null;
  const documentElem = documents ? documents[0] : null;
  const document = documentElem ? documentElem.document : null;

  if (!document) {
    return null;
  }

  const {
    seoDescription,
    seoTitle,
    cookiePageSlug,
    privacyPageSlug,
    privacyAndCookiePageSlug,
    footer,
    homePageUuid,
    availableTreatmentsMenuUuid,
    therapyAreasMenuUuid,
    hideHeaderComponents,

    // Translation Keys
    thisSiteUsesCookies,
    privacyCookiePolicy,
    accept,
    refuse,
    areYouSureLogout,
    contents,
    authors,
    learnMore,
    filter,
    thePageYouAreLooking,
    unfortunatelyThePage,
    account,
    logout,
    publicationDate,
    forgottenPassword,
    pleaseAddBelow,
    email,
    enterYourEmail,
    continueText,
    cancel,
    password,
    enterYourPassword,
    salutation,
    enterYourTitle,
    firstName,
    enterYourFirstName,
    lastName,
    enterYourLastName,
    confirmEmail,
    emailsDoNotMatch,
    confirmPassword,
    passwordsDoNotMatch,
    speciality,
    enterYourSpeciality,
    workplace,
    enterYourWorkplace,
    kyowaKirinHub,
    createYourAccount,
    logIn,
    register,
    medicalEducation,
    diseasePages,
    medicalResources,
    all,
    genericChatbotSnippet,
    searchFilterOthersLabel,
    viewAll,
    clickHere,
    mandatory,
    resetPassword,
    enterYourNewPassword,
    deletingAccount,
    pleaseStayOnDelete,
    confirmingEmailAddress,
    pleaseStayOnConfirm,
    accountDetails,
    checkAndChange,
    ifYouWishToChange,
    ifYouWishToBeRemoved,
    deleteAccount,
    youAreAboutToLeave,
    viewMore,
    therapyPageReadMoreLabel,
    required,
    thisFieldIsRequired,
    invalidEmail,
    mustContain,
    capsLockLabel,
    characters,
    mustContainNumber,
    mustContainUpperCase,
    mustContainLowerCase,
    mustContainSpecialCharacter,
    anUnexpectedError,
    usernamePasswordIncorrect,
    userAccountExists,
    userNotFound,
    theLinkIsInvalid,
    youAreNotAuthorised,
    currentPasswordIsIncorrect,
    thePasswordIsIncorrect,
    nextStep,
    youreAlmostThereEmail,
    somethingWentWrong,
    invalidLink,
    anUnexpectedErrorGoToLogin,
    theLinkYouHaveFollowedConfirmationEmail,
    goToLoginPage,
    success,
    yourRegistrationComplete,
    weWereUnable,
    theLinkYouHaveFollowedDeletionProcess,
    accountRemoved,
    yourAccountRemoved,
    accountDetailsUpdated,
    yourAccountDetailsUpdated,
    youreAlmostTherePassword,
    passwordUpdated,
    thankYouPasswordReset,
    passwordChanged,
    yourPasswordChanged,
    login,
    profile,
    profilePageVeevaCode,
    error,
    weHaveSent,
    save,
    enterNewPassword,
    confirmNewPassword,
    submit,
    currentPassword,
    newPassword,
    iConsentTo,
    iUnderstandThat,
    downloadPdf,
    serviceTemporarilyUnavailable,
    unfortunatelyServiceUnavailable,
    logInOrRegister,
    pleaseSelectTheRelevant,
    trustlevel,
    references,
    navigationContactLink,
    searchPlaceholder,
    searchRelatedPageUrl,
    searchResultsHeader,
    searchResultsSubtitle,
    searchResultsText,
    searchNoResultsText,
    displaySearchResultsBox,
    termsAndConditionLabel,
    termsAndConditionMandatoryMessage,
    roleLabel,
    roleValues,
    jobTitleLabel,
    jobTitlePlaceHolder,
    departmentLabel,
    departmentPlaceholder,
    practicePhoneNumberLabel,
    practicePhoneNumberPlaceholder,
    departmentPhoneNumberLabel,
    departmentPhoneNumberPlaceholder,
    marketingPreferencesLabel,
    marketingPreferencesMessage,
    termsSlug,
    conditions,
    emailBlacklistDomains,
    emailProviderErrorMsg,
    toastBarText,
    personalizedMessage,
    adverseReportingUrl,
    adverseReportingLinkText,
    adverseReportingLinkLogo,
    adverseReportingText,

    // Component Based Translation Keys
    headerLoginButtonText,
    loginSideText,
    cookiePreferencesButtonText,
    cookiePreferencesTitle,
    cookiePreferencesSubtitle,
    countrySelector,
    graphicBanner,
    necessaryCookiesTitle,
    necessaryCookiesText,
    necessaryCookiesAlwaysActiveText,
    analyticsCookiesTitle,
    analyticsCookiesText,
    analyticsCookiesActiveText,
    cookiePreferencesSaveButtonText,
    cookiePreferencesCancelButtonText,
    externalLoginErrorPageTitle,
    externalLoginErrorPageSubHeading,
    externalLoginErrorPageButtonText,
    selfCertificationTitleLevelB,
    selfCertificationText,
    selfCertificationHcpButtonText,
    selfCertificationHcpButtonSmallprint,
    selfCertificationGeneralPublicButtonText,
    selfCertificationGeneralPublicSmallprint,
    selfCertificationTitleLevelC,
    selfCertificationDurationInMinutes,
    contentGateTitle,
    contentGateText,
    jointVentureNotificationText,
    contentGateLoginButtonText,
    contentGateRegisterButtonText,
    contentGateLeftSmallPrintText,
    contentGateRightSmallPrintText,
    contentRatingConfirmation,
    contentRatingPrompt,
    consentFormTitle,
    consentFormText,
    consentFormHcpConsentText,
    consentFormMarketingConsentText,
    consentFormButtonText,
    promotionalContentGateTitle,
    promotionalContentGateSubContext,
    promotionalContentGateCtaAText,
    promotionalContentGateCtaASmallPrintText,
    promotionalContentGateCtaBText,
    promotionalContentGateCtaBSmallPrintText,

    // Feature Switches
    featureSwitchCookieBannerWithPreferences,
    featureSwitchAuthService,
    featureSwitchTrustlevelMin,
    featureSwitchSecureContentGate,
    featureSwitchContentGateBlurLevel,
    featureSwitchDisableMarketingConsent,

    features,

    globalPromotionalContent,

    //external content
    externalContent404Error,
    externalContentMobileMessage,
    externalContentButtonText,
    externalContentImage,

    //for edetailer button and image
    eDetailerButtonText,
    eDetailerImage,

    //svg
    timeToReadIcon,

    //moodle
    whitelistedDomains,

    //accesscoures
    accessCourseButtonLabel,
    accessCourseDetailPageLabel,

    //anonymous user flow
    featureMarketingPreferenceActivation,
    anonymousFlowHeading,
    anonymousFlowLeftColumnText,
    anonymousFlowFormTitle,
    anonymousRadioButtonYesLabel,
    anonymousRadioButtonNoLabel,
    anonymousFlowMarketingOptIn,
    anonymousFlowMarketingHeading,
    anonymousFlowMarketingDescription,
    anonymousFlowMarketingRejectMessage,
    anonymousFlowGoBackButtonLabel,
    profileMarketOptInPopupCloseLabel,
    profileMarketOptInPopupText,
    anonymousSpecialityDropdownValues,

    //my hub
    activateMyHub,
    myHubComponent,
    forMoreInformation,
    promotionalContentStickerText,
    //webinar
    webinarsKeynoteSpeakersLabel,
    webinarSignUpLabel,
    webinarRegisterTitle,
    relatedWebinarsLabel,
    webinarScheduleLabel,
    joinWebinarLabel,
    webinarRegistrationSuccess,
    webinarRegistrationSuccessCloseButtonLabel,
    webinarRegistrationFailure,
    webinarCountryLabel,
    webinarCountryPlaceholder,
    webinarStateLabel,
    webinarStatePlaceholder,
    webinarCountriesAndStateJson,
    emailProviderErrorMessage,
    jobTitlePlaceholder,
    mustContainOnlyNumber,
    featureSwitchDisplayNewFormFields,
    cookieBannerVersion,
    oneTrustDomainId,
    oneTrustScriptUrl,
    limitedProfileTitle,
    limitedProfileDescription,
    limitedProfileLabel,
    limitedProfileLink,
    limitedAccessConditionPicker,
    marketingPreferenceErrorMessage,
    accessRulesSelector,
    oneTrustAnalyticsGroupId,
    personalizedLinkExpired,
    consentWarningMessage,
    registrationSuccessBreadcrumbText,
    successEmailConfirmText,
    moodleRedirectConfirmationAlertOnGlobalLandingPage,
    referenceBoxToggle,
    disableCountrySwitcher,

    //Giosg
    giosgScriptUrl,
    giosgEnvironment,
    giosgDomainId,

    //Sfmc
    sfmcDataset
  } = document.elements;

  const footerValue = getValue(footer);
  const footerId = footerValue ? footerValue.id : null;
  const therapyMenuId = getValue(therapyAreasMenuUuid);
  const availableTreatmentsMenuId = getValue(availableTreatmentsMenuUuid);

  const topLevelMenuIds = [therapyMenuId, availableTreatmentsMenuId].filter(
    function (el) {
      return el != null;
    }
  );

  return {
    id: document.id,
    footerId,
    topLevelMenuIds,
    hideHeaderComponents: getValue(hideHeaderComponents),
    seoDescription: getValue(seoDescription),
    seoTitle: getValue(seoTitle),
    cookiesPath: getValue(cookiePageSlug),
    privacyPath: getValue(privacyPageSlug),
    privacyAndCookiePath: getValue(privacyAndCookiePageSlug),
    termsAndConditionsPath: getValue(termsSlug),
    landingPageId: getValue(homePageUuid),
    availableTreatmentsMenuId: getValue(availableTreatmentsMenuUuid),
    selfCertificationDurationInMinutes: getValue(
      selfCertificationDurationInMinutes
    ),

    // Feature Switches
    featureSwitchCookieBannerWithPreferences: getValue(
      featureSwitchCookieBannerWithPreferences
    ),
    authService: getValue(featureSwitchAuthService),
    trustLevelMin: getValue(featureSwitchTrustlevelMin),
    secureContentGate: getValue(featureSwitchSecureContentGate),
    contentGateBlurLevel: getValue(featureSwitchContentGateBlurLevel),
    disableMarketingConsent: getValue(featureSwitchDisableMarketingConsent),
    globalPromotionalContent: getValue(globalPromotionalContent),

    oneTrustAnalyticsGroupId: getValue(oneTrustAnalyticsGroupId) || 'C0002',   

    //feature activation
    features: getValues(features),

    // t - Translation variables
    tThisSiteUsesCookies: getValue(thisSiteUsesCookies),
    tPrivacyCookiePolicy: getValue(privacyCookiePolicy),
    tAccept: getValue(accept),
    tRefuse: getValue(refuse),
    tAreYouSureLogout: getValue(areYouSureLogout),
    tContents: getValue(contents),
    tAuthors: getValue(authors),
    tLearnMore: getValue(learnMore),
    tFilter: getValue(filter),
    tThePageYouAreLooking: getValue(thePageYouAreLooking),
    tUnfortunatelyThePage: getValue(unfortunatelyThePage),
    tAccount: getValue(account),
    tLogout: getValue(logout),
    tPublicationDate: getValue(publicationDate),
    tForgottenPassword: getValue(forgottenPassword),
    tPleaseAddBelow: getValue(pleaseAddBelow),
    tEmail: getValue(email),
    tEnterYourEmail: getValue(enterYourEmail),
    tContinueText: getValue(continueText),
    tCancel: getValue(cancel),
    tPassword: getValue(password),
    tEnterYourPassword: getValue(enterYourPassword),
    tSalutation: getValue(salutation),
    tEnterYourTitle: getValue(enterYourTitle),
    tFirstName: getValue(firstName),
    tEnterYourFirstName: getValue(enterYourFirstName),
    tLastName: getValue(lastName),
    tEnterYourLastName: getValue(enterYourLastName),
    tConfirmEmail: getValue(confirmEmail),
    tEmailsDoNotMatch: getValue(emailsDoNotMatch),
    tConfirmPassword: getValue(confirmPassword),
    tPasswordsDoNotMatch: getValue(passwordsDoNotMatch),
    tSpeciality: getValue(speciality),
    tEnterYourSpeciality: getValue(enterYourSpeciality),
    tWorkplace: getValue(workplace),
    tEnterYourWorkplace: getValue(enterYourWorkplace),
    tKyowaKirinHub: getValue(kyowaKirinHub),
    tCreateYourAccount: getValue(createYourAccount),
    tLogIn: getValue(logIn),
    tRegister: getValue(register),
    tMedicalEducation: getValue(medicalEducation),
    tDiseasePages: getValue(diseasePages),
    tMedicalResources: getValue(medicalResources),
    tAll: getValue(all),
    tChatBot: getValue(genericChatbotSnippet),
    tOther: getValue(searchFilterOthersLabel),
    tViewAll: getValue(viewAll),
    tClickHere: getValue(clickHere),
    tMandatory: getValue(mandatory),
    tResetPassword: getValue(resetPassword),
    tEnterYourNewPassword: getValue(enterYourNewPassword),
    tDeletingAccount: getValue(deletingAccount),
    tPleaseStayOnDelete: getValue(pleaseStayOnDelete),
    tConfirmingEmailAddress: getValue(confirmingEmailAddress),
    tPleaseStayOnConfirm: getValue(pleaseStayOnConfirm),
    tAccountDetails: getValue(accountDetails),
    tCheckAndChange: getValue(checkAndChange),
    tIfYouWishToChange: getValue(ifYouWishToChange),
    tIfYouWishToBeRemoved: getValue(ifYouWishToBeRemoved),
    tDeleteAccount: getValue(deleteAccount),
    tYouAreAboutToLeave: getValue(youAreAboutToLeave),
    tViewMore: getValue(viewMore),
    tReadMore: getValue(therapyPageReadMoreLabel),
    tRequired: getValue(required),
    tThisFieldIsRequired: getValue(thisFieldIsRequired),
    tInvalidEmail: getValue(invalidEmail),
    tMustContain: getValue(mustContain),
    tCharacters: getValue(characters),
    tMustContainNumber: getValue(mustContainNumber),
    tMustContainUpperCase: getValue(mustContainUpperCase),
    tMustContainLowerCase: getValue(mustContainLowerCase),
    tMustContainSpecialCharacter: getValue(mustContainSpecialCharacter),
    tAnUnexpectedError: getValue(anUnexpectedError),
    tUsernamePasswordIncorrect: getValue(usernamePasswordIncorrect),
    tUserAccountExists: getValue(userAccountExists),
    tUserNotFound: getValue(userNotFound),
    tTheLinkIsInvalid: getValue(theLinkIsInvalid),
    tYouAreNotAuthorised: getValue(youAreNotAuthorised),
    tCurrentPasswordIsIncorrect: getValue(currentPasswordIsIncorrect),
    tThePasswordIsIncorrect: getValue(thePasswordIsIncorrect),
    tNextStep: getValue(nextStep),
    tYoureAlmostThereEmail: getValue(youreAlmostThereEmail),
    tSomethingWentWrong: getValue(somethingWentWrong),
    tInvalidLink: getValue(invalidLink),
    tAnUnexpectedErrorGoToLogin: getValue(anUnexpectedErrorGoToLogin),
    tTheLinkYouHaveFollowedConfirmationEmail: getValue(
      theLinkYouHaveFollowedConfirmationEmail
    ),
    tGoToLoginPage: getValue(goToLoginPage),
    tSuccess: getValue(success),
    tYourRegistrationComplete: getValue(yourRegistrationComplete),
    tWeWereUnable: getValue(weWereUnable),
    tTheLinkYouHaveFollowedDeletionProcess: getValue(
      theLinkYouHaveFollowedDeletionProcess
    ),
    tAccountRemoved: getValue(accountRemoved),
    tYourAccountRemoved: getValue(yourAccountRemoved),
    tAccountDetailsUpdated: getValue(accountDetailsUpdated),
    tYourAccountDetailsUpdated: getValue(yourAccountDetailsUpdated),
    tYoureAlmostTherePassword: getValue(youreAlmostTherePassword),
    tPasswordUpdated: getValue(passwordUpdated),
    tThankYouPasswordReset: getValue(thankYouPasswordReset),
    tPasswordChanged: getValue(passwordChanged),
    tYourPasswordChanged: getValue(yourPasswordChanged),
    tLogin: getValue(login),
    tProfile: getValue(profile),
    tProfilePageVeevaCode: getValue(profilePageVeevaCode),
    tError: getValue(error),
    tWeHaveSent: getValue(weHaveSent),
    tSave: getValue(save),
    tEnterNewPassword: getValue(enterNewPassword),
    tConfirmNewPassword: getValue(confirmNewPassword),
    tSubmit: getValue(submit),
    tCurrentPassword: getValue(currentPassword),
    tNewPassword: getValue(newPassword),
    tIConsentTo: getValue(iConsentTo),
    tIUnderstandThat: getValue(iUnderstandThat),
    tDownloadPdf: getValue(downloadPdf),
    tTrustLevel: getValue(trustlevel),
    tReferences: getValue(references),
    tServiceTemporarilyUnavailable: getValue(serviceTemporarilyUnavailable),
    tUnfortunatelyServiceUnavailable: getValue(unfortunatelyServiceUnavailable),
    tTermsLabel: getValue(termsAndConditionLabel),
    tTermsMandatoryMsg: getValue(termsAndConditionMandatoryMessage),
    tRoleLabel: getValue(roleLabel),
    tRoleValues: getValue(roleValues),
    tJobTitleLabel: getValue(jobTitleLabel),
    tJobTitlePlaceHolder: getValue(jobTitlePlaceHolder),
    tDepartmentLabel: getValue(departmentLabel),
    tDepartmentPlaceholder: getValue(departmentPlaceholder),
    tPracticePhoneNumberLabel: getValue(practicePhoneNumberLabel),
    tPracticePhoneNumberPlaceholder: getValue(practicePhoneNumberPlaceholder),
    tDepartmentPhoneNumberLabel: getValue(departmentPhoneNumberLabel),
    tDepartmentPhoneNumberPlaceholder: getValue(
      departmentPhoneNumberPlaceholder
    ),
    tMarketingLabel: getValue(marketingPreferencesLabel),
    tMarketingText: getValue(marketingPreferencesMessage),

    tPolicyText: getValue(termsAndConditionMandatoryMessage),
    tConditions: getValue(conditions),
    tPrivacyText: getValue(termsAndConditionMandatoryMessage),
    emailBlacklistDomains: getValue(emailBlacklistDomains),
    tEmailProviderErrorMsg: getValue(emailProviderErrorMsg),
    tToastBarText: toastBarText,
    tPersonalizedMessage: getValue(personalizedMessage),
    adverseReportingUrl: getValue(adverseReportingUrl),
    tAdverseReportingLinkText: getValue(adverseReportingLinkText),
    adverseReportingLinkLogo: adverseReportingLinkLogo,
    tAdverseReportingText: getValue(adverseReportingText),
    //carousel control


    // t - Component Based Translation Variables
    tHeaderLoginButtonText: getValue(headerLoginButtonText),
    tLoginSideText: loginSideText,
    tCapsLockLabel: getValue(capsLockLabel),
    tCookiePreferencesButtonText: getValue(cookiePreferencesButtonText),
    tCookiePreferencesTitle: getValue(cookiePreferencesTitle),
    tCookiePreferencesSubtitle: getValue(cookiePreferencesSubtitle),
    tCountrySelector: getValue(countrySelector),
    tNecessaryCookiesTitle: getValue(necessaryCookiesTitle),
    tNecessaryCookiesText: getValue(necessaryCookiesText),
    tNecessaryCookiesAlwaysActiveText: getValue(
      necessaryCookiesAlwaysActiveText
    ),
    tAnalyticsCookiesTitle: getValue(analyticsCookiesTitle),
    tAnalyticsCookiesText: getValue(analyticsCookiesText),
    tAnalyticsCookiesActiveText: getValue(analyticsCookiesActiveText),
    tCookiePreferencesSaveButtonText: getValue(cookiePreferencesSaveButtonText),
    tCookiePreferencesCancelButtonText: getValue(
      cookiePreferencesCancelButtonText
    ),
    texternalLoginErrorPageTitle: getValue(externalLoginErrorPageTitle),
    texternalLoginErrorPageSubHeading: getValue(
      externalLoginErrorPageSubHeading
    ),
    texternalLoginErrorPageButtonText: getValue(
      externalLoginErrorPageButtonText
    ),
    tSelfCertificationTitleLevelB: getValue(selfCertificationTitleLevelB),
    tSelfCertificationText: getValue(selfCertificationText),
    tSelfCertificationHcpButtonText: getValue(selfCertificationHcpButtonText),
    tSelfCertificationHcpButtonSmallprint: getValue(
      selfCertificationHcpButtonSmallprint
    ),
    tSelfCertificationGeneralPublicButtonText: getValue(
      selfCertificationGeneralPublicButtonText
    ),
    tSelfCertificationGeneralPublicSmallprint: getValue(
      selfCertificationGeneralPublicSmallprint
    ),
    tSelfCertificationTitleLevelC: getValue(selfCertificationTitleLevelC),
    tContentGateTitle: getValue(contentGateTitle),
    tContentGateText: getValue(contentGateText),
    tJointVentureNotificationText: getValue(jointVentureNotificationText),
    tContentGateLoginButtonText: getValue(contentGateLoginButtonText),
    tContentGateRegisterButtonText: getValue(contentGateRegisterButtonText),
    tContentGateLeftSmallPrintText: getValue(contentGateLeftSmallPrintText),
    tContentGateRightSmallPrintText: getValue(contentGateRightSmallPrintText),
    tContentRatingConfirmation: getValue(contentRatingConfirmation),
    tContentRatingPrompt: getValue(contentRatingPrompt),
    tConsentFormTitle: getValue(consentFormTitle),
    tConsentFormText: getValue(consentFormText),
    tConsentFormHcpConsentText: getValue(consentFormHcpConsentText),
    tConsentFormMarketingConsentText: getValue(consentFormMarketingConsentText),
    tConsentFormButtonText: getValue(consentFormButtonText),
    tNavigationContactLink: navigationContactLink,
    tSearchPlaceholder: getValue(searchPlaceholder),
    tSearchRelatedPageUrl: getValue(searchRelatedPageUrl),
    tSearchResultsHeader: getValue(searchResultsHeader),
    tSearchResultsSubtitle: getValue(searchResultsSubtitle),
    tSearchResultsText: getValue(searchResultsText),
    tSearchNoResultsText: getValue(searchNoResultsText),
    tDisplaySearchResultsBox: getValue(displaySearchResultsBox),
    tPromotionalContentGateTitle: getValue(promotionalContentGateTitle),
    tPromotionalContentGateSubContext: getValue(
      promotionalContentGateSubContext
    ),
    tPromotionalContentGateCtaAText: getValue(promotionalContentGateCtaAText),
    tPromotionalContentGateCtaASmallPrintText: getValue(
      promotionalContentGateCtaASmallPrintText
    ),
    tPromotionalContentGateCtaBText: getValue(promotionalContentGateCtaBText),
    tPromotionalContentGateCtaBSmallPrintText: getValue(
      promotionalContentGateCtaBSmallPrintText
    ),

    //external content
    externalContent404Error: getValue(externalContent404Error),
    externalContentMobileMessage: getValue(externalContentMobileMessage),
    externalContentImage: externalContentImage,
    externalContentButtonText: getValue(externalContentButtonText),
    //edetailer
    eDetailerButtonText: getValue(eDetailerButtonText),
    eDetailerImage: eDetailerImage,

    //svg
    timeToReadIcon: getValue(timeToReadIcon),

    //moodle
    whitelistedDomains: getValues(whitelistedDomains),
    //accessCourse
    accessCourseButtonLabel: getValue(accessCourseButtonLabel),
    accessCourseDetailPageLabel: getValue(accessCourseDetailPageLabel),

    //anonymous flow
    featureMarketingPreferenceActivation: getValue(
      featureMarketingPreferenceActivation
    ),
    anonymousFlowHeading: anonymousFlowHeading,
    anonymousFlowLeftColumnText: anonymousFlowLeftColumnText,
    anonymousFlowFormTitle: getValue(anonymousFlowFormTitle),
    anonymousRadioButtonYesLabel: getValue(anonymousRadioButtonYesLabel),
    anonymousRadioButtonNoLabel: getValue(anonymousRadioButtonNoLabel),
    anonymousFlowMarketingOptIn: anonymousFlowMarketingOptIn,
    anonymousFlowMarketingHeading: anonymousFlowMarketingHeading,
    anonymousFlowMarketingDescription: anonymousFlowMarketingDescription,
    anonymousFlowMarketingRejectMessage: getValue(
      anonymousFlowMarketingRejectMessage
    ),
    anonymousFlowGoBackButtonLabel: getValue(anonymousFlowGoBackButtonLabel),
    profileMarketOptInPopupCloseLabel: getValue(
      profileMarketOptInPopupCloseLabel
    ),
    profileMarketOptInPopupText: profileMarketOptInPopupText,
    anonymousSpecialityDropdownValues: getValue(
      anonymousSpecialityDropdownValues
    ),

    //my hub configs
    activateMyHub: getValue(activateMyHub),
    myHubComponent: getValue(myHubComponent),
    forMoreInformation: forMoreInformation,
    promotionalContentStickerText: promotionalContentStickerText,
    //webinar
    webinarScheduleLabel: getValue(webinarScheduleLabel),
    emailProviderErrorMessage: getValue(emailProviderErrorMessage),
    jobTitlePlaceholder: getValue(jobTitlePlaceholder),
    mustContainOnlyNumber: getValue(mustContainOnlyNumber),
    featureSwitchDisplayNewFormFields: getValue(featureSwitchDisplayNewFormFields),
    webinarsKeynoteSpeakersLabel:getValue(webinarsKeynoteSpeakersLabel),
    webinarSignUpLabel:getValue(webinarSignUpLabel),
    webinarRegisterTitle:getValue(webinarRegisterTitle),
    relatedWebinarsLabel:getValue(relatedWebinarsLabel),
    joinWebinarLabel:getValue(joinWebinarLabel),
    webinarRegistrationSuccess:getValue(webinarRegistrationSuccess),
    webinarRegistrationFailure:getValue(webinarRegistrationFailure),
    webinarRegistrationSuccessCloseButtonLabel:getValue(webinarRegistrationSuccessCloseButtonLabel),
    webinarCountryLabel:getValue(webinarCountryLabel),
    webinarCountryPlaceholder:getValue(webinarCountryPlaceholder),
    webinarStateLabel:getValue(webinarStateLabel),
    webinarStatePlaceholder:getValue(webinarStatePlaceholder),
    webinarCountriesAndStateJson:getValue(webinarCountriesAndStateJson),
    
    //one trust cookie 
    cookieBannerVersion:getValue(cookieBannerVersion),
    oneTrustDomainId:getValue(oneTrustDomainId),
    oneTrustScriptUrl:getValue(oneTrustScriptUrl),
    limitedProfileTitle:limitedProfileTitle,
    limitedProfileDescription:limitedProfileDescription,
    limitedProfileLabel:getValue(limitedProfileLabel),
    limitedProfileLink:getValue(limitedProfileLink),
    personalizedLinkExpired:getValue(personalizedLinkExpired),
    tmarketingPreferenceErrorMessage: getValue(marketingPreferenceErrorMessage),
    tconsentWarningMessage: getValue(consentWarningMessage),
    registrationSuccessBreadcrumbText: getValue(registrationSuccessBreadcrumbText),
    successEmailConfirmText: getValue(successEmailConfirmText),
    moodleRedirectConfirmationAlertOnGlobalLandingPage: getValue(moodleRedirectConfirmationAlertOnGlobalLandingPage),
    limitedAccessConditionPicker:limitedAccessConditionPicker,
    referenceBoxToggle:referenceBoxToggle,
    accessRulesSelector:accessRulesSelector,
    disableCountrySwitcher: disableCountrySwitcher,
    graphicBanner: graphicBanner,

    //Giosg
    giosgScriptUrl: getValue(giosgScriptUrl),
    giosgEnvironment: getValue(giosgEnvironment),
    giosgDomainId: getValue(giosgDomainId),

    //Sfmc
    sfmcDataset: getValue(sfmcDataset),

    // Form Translations
    formTranslations: {
      login: {
        text: getValue(logIn),
      },
      register: {
        text: getValue(register),
      },
      "external-login": {
        formHeaderText: getValue(logInOrRegister),
        formDescription: getValue(pleaseSelectTheRelevant),
      },
      email: {
        label: getValue(email),
        placeholder: getValue(enterYourEmail),
        validation: [
          { errorText: getValue(invalidEmail) },
          { errorText: getValue(thisFieldIsRequired) },
          { errorText: getValue(emailProviderErrorMessage) },
        ],
      },
      password: {
        label: getValue(password),
        placeholder: getValue(enterYourPassword),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "forgot-password": {
        text: getValue(forgottenPassword),
      },
      "log-in": {
        text: getValue(continueText),
      },
      "log-in-external": {
        text: logIn.value ? getValue(logIn).toUpperCase() : "",
      },
      "register-in-external": {
        text: register.value ? getValue(register).toUpperCase() : "",
      },
      salutation: {
        label: getValue(salutation),
        placeholder: getValue(enterYourTitle),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "first-name": {
        label: getValue(firstName),
        placeholder: getValue(enterYourFirstName),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "last-name": {
        label: getValue(lastName),
        placeholder: getValue(enterYourLastName),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "confirm-email": {
        label: getValue(confirmEmail),
        placeholder: getValue(enterYourEmail),
        validation: [
          { errorText: getValue(invalidEmail) },
          { errorText: getValue(thisFieldIsRequired) },
          { errorText: getValue(emailProviderErrorMessage) },
          { errorText: getValue(emailsDoNotMatch) },
        ],
      },
      "new-password": {
        label: getValue(password),
        placeholder: getValue(enterYourPassword),
        validation: [
          { errorText: `${getValue(mustContain)} 8 ${getValue(characters)}` },
          { errorText: getValue(mustContainNumber) },
          { errorText: getValue(mustContainUpperCase) },
          { errorText: getValue(mustContainLowerCase) },
          { errorText: getValue(mustContainSpecialCharacter) },
          { errorText: getValue(thisFieldIsRequired) },
        ],
      },
      "confirm-password": {
        label: getValue(confirmPassword),
        placeholder: getValue(enterYourPassword),
        validation: [
          { errorText: `${getValue(mustContain)} 8 ${getValue(characters)}` },
          { errorText: getValue(mustContainNumber) },
          { errorText: getValue(mustContainUpperCase) },
          { errorText: getValue(mustContainLowerCase) },
          { errorText: getValue(mustContainSpecialCharacter) },
          { errorText: getValue(thisFieldIsRequired) },
          { errorText: getValue(passwordsDoNotMatch) },
        ],
      },
      speciality: {
        label: getValue(speciality),
        placeholder: getValue(enterYourSpeciality),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      role: {
        label: getValue(roleLabel),
        placeholder: getValue(roleValues),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "organization-title": {
        label: getValue(workplace),
        placeholder: getValue(enterYourWorkplace),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "jobtitle": {
        label: getValue(jobTitleLabel),
        placeholder: getValue(jobTitlePlaceholder),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "department": {
        label: getValue(departmentLabel),
        placeholder: getValue(departmentPlaceholder),
        validation: [{ errorText: getValue(thisFieldIsRequired) }],
      },
      "phone" : {
        label: getValue(practicePhoneNumberLabel),
        placeholder: getValue(practicePhoneNumberPlaceholder),
        validation: [
          { errorText: getValue(mustContainOnlyNumber) },
        ],
      },
      "departmentPhone" : {
        label: getValue(departmentPhoneNumberLabel),
        placeholder: getValue(departmentPhoneNumberPlaceholder),
        validation: [
          { errorText: getValue(mustContainOnlyNumber) },
        ],
      },
      "privacy-consent": {
        validation: [
          { errorText: getValue(consentWarningMessage) },
        ],
      },
      "information-consent": {
        label: getValue(iConsentTo),
        validation: [
          { errorText: getValue(marketingPreferenceErrorMessage) },
        ],
      },
      optinHCP: {
        label: getValue(kyowaKirinHub),
        validation: [{ errorText: getValue(marketingPreferenceErrorMessage) }],
      },
      "create-account": {
        text: getValue(createYourAccount),
      },
    },
  };
};

const useWebsiteConfig = () => {
  const { isLoading, error, data } = useQuery(
    WEBSITE_CONFIG_QUERY_KEY,
    websiteConfigQuery,
    { staleTime: Infinity } // only request the website config once
  );

  const websiteConfig = parseWebsiteConfigQueryResults(data);
  return { websiteConfig, isLoading, error };
};
const useWebsiteConfigDeep = () => {
  let webconfig = useWebsiteConfig();
  const { content, isLoading, error } = usePageContent(
    webconfig.websiteConfig?.id
  );
  if (isLoading || error) return {};
  const websiteConfig = parseWebsiteConfigQueryResults({
    data: {
      documents: [
        {
          document: content,
        },
      ],
    },
  });
  return { websiteConfig, isLoading, error };
};

// convenience function to retrieve the website config object directly
// ignoring loading or error
const useWebsiteConfigObject = () => {
  const { websiteConfig } = useWebsiteConfig();
  return websiteConfig || {};
};

const translateFormFieldsInArrays = (forms, translations) => {
  const { formTranslations } = translations;

  if (formTranslations) {
    forms.forEach(({ formFields }, i) => {
      if (formTranslations.hasOwnProperty(forms[i].id)) {
        forms[i].text = formTranslations[forms[i].id].text || forms[i].text;
        forms[i].formHeaderText =
          formTranslations[forms[i].id].formHeaderText ||
          forms[i].formHeaderText;
        forms[i].formDescription =
          formTranslations[forms[i].id].formDescription ||
          forms[i].formDescription;
      }
      formFields.forEach((field, index) => {
        if (formTranslations.hasOwnProperty(field.id)) {
          forms[i].formFields[index].label =
            formTranslations[field.id].label ||
            forms[i].formFields[index].label;
          forms[i].formFields[index].typeParams.placeholder =
            formTranslations[field.id].placeholder ||
            forms[i].formFields[index].typeParams.placeholder;
          forms[i].formFields[index].typeParams.text =
            formTranslations[field.id].text ||
            forms[i].formFields[index].typeParams.text;
          if (forms[i].formFields[index].validation) {
            forms[i].formFields[index].validation.map((obj, idx) => {
              obj.errorText =
                formTranslations[field.id].validation[idx].errorText ||
                obj.errorText;
              return obj;
            });
          }
        }
      });
    });
  }

  return forms;
};

export {
  useWebsiteConfig,
  useWebsiteConfigDeep,
  useWebsiteConfigObject,
  translateFormFieldsInArrays,
};
