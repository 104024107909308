import React, { useState, useRef, useEffect } from 'react';
import utilityHelper from 'utils/utilityHelperPlus';
import PropTypes from 'prop-types';
import Button from 'components/kanso/atoms/button/Button';
import styles from './Audio.module.scss';
import Image from 'components/kanso/atoms/image/Image';
import RichText from 'components/reusableComponents/richText/RichText';
import { stringToCssId } from 'utils/stringUtils';
import { pauseAllMedia } from 'utils/utilityHelper';
/**
 * Audio
 * @param {string}  title          - The title of the media to be displayed.
 * @param {string}  titleTag       - The heading tag wrapped around the media title.
 * @param {string}  duration       - The duration of the media.
 * @param {string}  description    - The description to be displayed.
 * @param {string}  image          - An image to be displayed.
 * @param {string}  src            - The location of the media source file.
 * @param {string}  srcType        - The type of media.
 * @param {string}  transcript     - The location of the media transcript file.
 * @returns React Component
 */
const AudioInstance = React.forwardRef((props, ref) => {
  const {
    source,
    options = null,
    image,
    duration,
    title,
    titleTag,
    description,
    layout,
  } = props;
  const HeadingTag = `${titleTag}`;
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioReady, setAudioReady] = useState(false);
  const [audioEnded, setAudioEnded] = useState(false);
  const [seeked, setSeeked] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const progressRef = useRef();

  const audioSrc = Boolean(source.sources.length) && source.sources[0].src;

  const formatTime = (totalSeconds) => {
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = Math.floor(totalSeconds % 60);
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  };

  const onPause = (e) => {
    setAudioPlaying(false)
  }

  const onLoadedData = (e) => {
    setAudioReady(true);
  };

  const onLoadedMetadata = (e) => {
    setAudioDuration(e.target.duration);
  };

  const onTimeUpdate = (e) => {
    progressRef.current.value =
      (e.target.currentTime * 100) / e.target.duration;
    setAudioDuration(e.target.duration);
    setCurrentTime(e.target.currentTime);
  };

  const onSeeked = (e) => {
    if (e.target.currentTime !== currentTime) setSeeked(true);
    setCurrentTime(e.target.currentTime);
  };

  const onEnded = (e) => {
    setAudioEnded(true);
    setAudioPlaying(false);
  };

  useEffect(() => {
    if (seeked) {
      if (audioPlaying) {
        // dataLayer.push({
        //   event: "podcast pause",
        //   event_name: "podcast pause",
        //   event_label: title,
        //   duration_seconds: currentTime,
        // });
        // dataLayer.push({
        //   event: "podcast start",
        //   event_name: "podcast start",
        //   event_label: title,
        //   duration_seconds: audioDuration,
        // });
      }
      setSeeked(false);
    }
  }, [seeked]);

  useEffect(() => {
    if (!seeked) {
      if (audioPlaying) {
        // dataLayer.push({
        //   event: "podcast start",
        //   event_name: "podcast start",
        //   event_label: title,
        //   duration_seconds: audioDuration,
        // });
      } else {
        // currentTime > 0 &&
        // dataLayer.push({
        //   event: "podcast pause",
        //   event_name: "podcast pause",
        //   event_label: title,
        //   duration_seconds: currentTime,
        // });
      }
    }
  }, [audioPlaying]);

  useEffect(() => {
    if (audioEnded) {
      // dataLayer.push({
      //   event: "podcast complete",
      //   event_name: "podcast complete",
      //   event_label: title,
      //   duration_seconds: audioDuration,
      // });
    }
  }, [audioEnded]);

  const togglePlay = (e) => {
    e.preventDefault();
    if(!audioPlaying) {
      pauseAllMedia()
    }
    !audioPlaying ? ref.current.play() : ref.current.pause();
    setAudioPlaying(!audioPlaying);
  };

  return (
    <div
      className={`${styles['audio-container']} ${
        layout ? styles[`audio-container-${image ? layout : 'compact'}`] : ''
      }`}
    >
      {image && (
        <div className={styles['audio-image']}>
          <Image src={image} altText='' />
          <span className={`${styles['playIcon']}`}>
            <Button
              type='tertiary'
              text=''
              link='#'
              icon={audioPlaying ? 'audioPause' : 'audioPlay'}
              iconAlign='left'
              onClick={togglePlay}
            />
          </span>
        </div>
      )}
      <div className={styles.plyrContainer}>
        <div className={styles['audio-player-container']}>
          <Button
            icon={audioPlaying ? 'audioPause' : 'audioPlay'}
            className={styles['audio-play-btn']}
            onClick={togglePlay}
          />
          <div className={styles['audio-time']}>
            {formatTime(currentTime)}
            <span> / {formatTime(audioDuration)}</span>
          </div>
          <ProgressBar ref={progressRef} audioRef={ref} />
          <VolumeControl audioRef={ref} />
          <audio
            // controls={options?.controls}
            ref={ref}
            src={audioSrc}
            className={styles['audio-player']}
            onLoadedData={onLoadedData}
            onLoadedMetadata={onLoadedMetadata}
            onEnded={onEnded}
            onTimeUpdate={onTimeUpdate}
            onSeeked={onSeeked}
            onPause={onPause}
          />
        </div>
        {duration && (
          <div className={styles['audio-label']}>Audio | {duration}</div>
        )}
        {title && (
          <HeadingTag className={styles['headline']}>{title}</HeadingTag>
        )}
        {description && (
          <div className={styles['audio-description']}>
            <RichText data={{ value: description }} />
          </div>
        )}
      </div>
    </div>
  );
});

const ProgressBar = React.forwardRef((props, ref) => {
  const { audioRef } = props;
  const [progress, setProgress] = useState(0);

  const getBackgroundSize = () => {
    return {
      backgroundSize: `${progress}% 100%`,
    };
  };

  const handleProgressChange = (e) => {
    audioRef.current.currentTime =
      (e.target.value * audioRef.current.duration) / 100;
    setProgress(e.target.value);
  };

  useEffect(() => {
    if (ref.current) setProgress(ref.current.value);
  }, [ref.current?.value]);

  return (
    <div className={styles['audio-progress-container']}>
      <div className={styles['audio-progress']}>
        <input
          ref={ref}
          style={getBackgroundSize()}
          onChange={handleProgressChange}
          className={styles['progress']}
          type='range'
          min='0'
          max='100'
          defaultValue='0'
          autoComplete='off'
        />
      </div>
    </div>
  );
});

const VolumeControl = (props) => {
  const { audioRef } = props;
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);

  const toggleMute = () => {
    audioRef.current.muted = !muted;
    setMuted(!muted);
  };
  const handleVolumeChange = (e) => {
    const volumeLevel = e.target.value
    audioRef.current.volume = volumeLevel;
    setVolume(volumeLevel);
    setMuted(volumeLevel === '0')
  };
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${volume * 100}% 100%`,
    };
  };

  return (
    <div className={styles['audio-volume']}>
      <Button
        icon={!muted ? 'audioVolume' : 'audioMute'}
        className={styles['audio-volume-btn']}
        onClick={toggleMute}
      />
      <input
        type='range'
        style={getBackgroundSize()}
        onChange={handleVolumeChange}
        min='0'
        max='1'
        step='0.05'
        defaultValue='1'
        autoComplete='off'
      />
    </div>
  );
};

const Audio = (props) => {
  const {
    title,
    titleTag,
    duration,
    description,
    image,
    src,
    srcType,
    transcript,
    columnCount
  } = props;

  const audioPlayerRef = useRef(null);
  const audioRef = useRef();
  const { winWidth } = utilityHelper.useScreenDimensions();
  const [layout, setLayout] = useState('compact');
  const mediaSrc = {
    type: 'audio',
    title: title,
    sources: [
      {
        type: srcType,
        src: src,
      },
    ],
  };
  const mediaOptions = {
    controls: [
      'play',
      'current-time',
      'duration',
      'progress',
      'mute',
      'volume',
    ],
    tooltips: { controls: false, seek: true },
  };

  useEffect(() => {
    const { width } = audioRef?.current?.getBoundingClientRect();
    const isBreakPoint = utilityHelper.detectComponentBreakpoint('m', width);
    isBreakPoint || columnCount > 1 ? setLayout('compact') : setLayout('list');
  }, [audioRef, winWidth]);

  return (
    <div className={styles.audio} ref={audioRef}>
      {mediaSrc && (
        <AudioInstance
          ref={audioPlayerRef}
          source={mediaSrc}
          options={mediaOptions}
          image={image}
          duration={duration}
          title={title}
          titleTag={titleTag}
          description={description}
          layout={layout}
        />
      )}
      {transcript && (
        <div className={styles['audio-transcript']}>
          <Button
            type='tertiary'
            text='Download Transcript'
            link={transcript}
            icon='download'
            iconAlign='right'
            isDownload={true}
          />
        </div>
      )}
    </div>
  );
};

Audio.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  duration: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcType: PropTypes.string.isRequired,
  srcType: PropTypes.oneOf(['audio/wav', 'audio/mpeg', 'audio/mp3']),
  transcript: PropTypes.string.isRequired,
};

Audio.defaultProps = {
  titleTag: 'h3',
  srcType: 'audio/mpeg',
};

export default Audio;
