import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import useAuthServiceMaintenance from 'hooks/useAuthServiceMaintenance';
import { setServiceDisabled, setErrorMessage } from 'redux/ui/slice';
import { getUrlCountryPath } from 'utils/urlUtils';

const Maintenance = ({ service }) => {
  // Redux
  const dispatch = useDispatch();
  const { authServiceMaintenance } = useAuthServiceMaintenance();
  const currentCountry = getUrlCountryPath();
  useEffect(() => {
    if(authServiceMaintenance){
      const { authService, defaultError, disabled, listOfMessages } = authServiceMaintenance?.elements || {};
      if(disabled?.value){
        const authServices = authService?.values || [];
        const isCurrentServiceDisabled = authServices.filter(item => { return item?.selection === service; });
        if(isCurrentServiceDisabled.length > 0){
          dispatch(setServiceDisabled());
          if(listOfMessages?.values) {
            const localisedErrorMessage = listOfMessages?.values.filter(item => { return item?.elements?.language?.value === currentCountry; });
            if(localisedErrorMessage.length > 0){
              if(localisedErrorMessage[0]?.elements?.errorMessage?.value){
                dispatch(setErrorMessage(localisedErrorMessage[0]?.elements?.errorMessage?.value));
              } else {
                if(defaultError?.value) {
                  dispatch(setErrorMessage(defaultError?.value));
                }
              }
            } else {
              if(defaultError?.value) {
                dispatch(setErrorMessage(defaultError?.value));
              }
            }
          }  
        }
      }
    }
  }, [authServiceMaintenance])
  return null;
};


export { Maintenance };
