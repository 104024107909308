import { useQuery } from 'react-query';
import { getAPIContent } from 'api/acousticApi/index';

const AUTH_MAINTENANCE_QUERY_KEY = 'auth-maintenance';

const useAuthServiceMaintenance = () => {
  const { data } = useQuery(
    AUTH_MAINTENANCE_QUERY_KEY,
    () => getAPIContent('5208fdcc-a850-4aa5-8d47-0a3f1ef9ce22'),
    { staleTime: Infinity } // only request the auth whitelist once
  );

  const authServiceMaintenance = data;

  return { authServiceMaintenance };
};

export default useAuthServiceMaintenance;
