import {
  BUTTON,
  COMPONENT,
  CHECKBOX,
  OPTIN_HCP,
  INFORMATION_CONSENT,
  commonShouldHaveValue,
  SHOULD_ACCEPT_CONSENT,
  CONSENT_FORM,
  SHOULD_HAVE_VALUE,
} from 'components/shared/form/formConstants';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { WithdrawConsent } from 'components/pageTemplates/login/WithdrawConsent';
import { MandatoryKey } from 'components/pageTemplates/login/MandatoryKey';

const useConsentFormFieldsOld = () => {
  const {
    tConsentFormTitle,
    tConsentFormText,
    tConsentFormHcpConsentText,
    tConsentFormMarketingConsentText,
    tConsentFormButtonText,
    tThisFieldIsRequired,
    disableMarketingConsent
  } = useWebsiteConfigObject();

  const consentFormFields = [
    {
      id: CONSENT_FORM,
      formHeaderText: tConsentFormTitle,
      formDescription: tConsentFormText,
      formFields: [
        {
          label: tConsentFormHcpConsentText,
          id: OPTIN_HCP,
          initialValue: false,
          type: CHECKBOX,
          mandatory: true,
          showAsterix: true,
          typeParams: {
  
          },
          validation: [
            {
              validationMethodKey: SHOULD_ACCEPT_CONSENT,
              errorText: tThisFieldIsRequired ? tThisFieldIsRequired : 'This field is required',
            },
          ],
        },
        {
          label: tConsentFormMarketingConsentText,
          id: INFORMATION_CONSENT,
          initialValue: disableMarketingConsent ? null : false,
          type: CHECKBOX,
          // mandatory: false,
          typeParams: {
  
          },
          disabled: disableMarketingConsent
        },
        {
          id: 'withdraw-consent',
          type: COMPONENT,
          typeParams: {
            PropComponent: WithdrawConsent,
          },
        },
        {
          id: 'consent-continue',
          type: BUTTON,
          size: 50,
          typeParams: {
            callBack: ({ formValues, actions }) => {
              actions.updateConsent({ formValues });
            },
            text: tConsentFormButtonText,
            type: 'button',
          },
        },
        {
          id: 'mandatory-key',
          type: COMPONENT,
          size: 100,
          typeParams: {
            PropComponent: MandatoryKey,
          },
        },
      ],
    },
  ];

  return consentFormFields;
}

export {
  useConsentFormFieldsOld,
};

