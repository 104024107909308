import { getValue, getFullUrl } from 'utils/utilityHelper'
import CalloutQuote from './CalloutQuote'
import Person from 'components/kanso/components/person/Person'
import Image from 'components/kanso/atoms/image/Image'

const CalloutQuoteAcousticData = (props) => {
  const { 
    personContent,
    citeLink,
    text,
    brandColours
  } = props.data

  return (
    <CalloutQuote
      text={getValue(text)}
      citeLink={getValue(citeLink)}
      backgroundColor={getValue(getValue(brandColours)?.elements?.pullQuoteBackground)}
    >
      <Person image={<Image src={getFullUrl(personContent?.value?.elements?.image?.url)} altText="" />} name={getValue(personContent?.value?.elements?.name)} role={getValue(personContent?.value?.elements?.role)} />
    </CalloutQuote>
  )
}

export default CalloutQuoteAcousticData