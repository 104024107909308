import React, { useEffect, useRef, useState } from 'react'
import PropTypes, { any } from 'prop-types'
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'

// components
import { LinksIcon, PointIcon } from 'components/shared/SvgIcons'
import { stringToCssId } from 'utils/stringUtils'
import RichText from 'components/reusableComponents/richText/RichText'

// styles
import './ContentAnchors.scss'
import { SCREEN_WIDTH_MOBILE, useScreenWidth } from 'components/layout/ScreenWidthContext'
import { OpenClosedIndicator } from 'components/shared/OpenClosedIndicator'
import { useLocation } from 'react-router-dom'
import { getUrlCountryPath } from 'utils/urlUtils'

const Anchors = ({ elements, containerRef }) => {
  const { tContents } = useWebsiteConfigObject()
  let titles = []
  const { currentWidthRanges } = useScreenWidth();
  const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);
  const [collapsed, setCollapsed] = useState(false);
  const [isCollapsible, setIsCollapsible] = useState(false);
  const [menuTitle, setMenuTitle] = useState(tContents);
  const [isSticky, setIsSticky] = useState(false);
  const stickyMenu = useRef();
  const location = useLocation();

  const setCurrentActive = () => {
    const chapters = document.getElementsByClassName("anchors-wrapper-div");
    if(chapters.length > 0 && chapters[0] && elements[0] && elements.length > 0) {
      let activeChap = "";
      elements.forEach((element, index) => {
        let title = element?.elements?.title?.value || element?.elements?.titleRichText?.value || null;
        title = title?.elements?.title?.value ? title?.elements?.title?.value : title;
        if (!title) {
          return null;
        }
        const id = stringToCssId(title);
        const chapterDiv = document.getElementById(id);
        // console.log({ id, chapterDiv })
        const rect = chapterDiv.getBoundingClientRect();
        if(rect.y < 10 && !isMobile){
          activeChap = title;
        }
        if(rect.y < 100 && isMobile){
          activeChap = title;
        }
        if(rect.y < 150 && isMobile && activeChap === ""){
          activeChap = title;
        }
      })
      if(activeChap !== "") {
        setMenuTitle(activeChap);
      } else {
        setMenuTitle(tContents);
      }
    }
  }
  useEffect(() => {
    window.addEventListener(
      "scroll",
      setCurrentActive
    );
    let title = elements[0]?.elements?.title?.value || elements[0]?.elements?.titleRichText?.value || null;
    title = title?.elements?.title?.value ? title?.elements?.title?.value : title;
    const id = stringToCssId(title);
    const sectionElement = document.getElementById(id);
    if(sectionElement) {
      if(stickyMenu?.current) { 
        sectionElement.before(stickyMenu?.current)
      }
    }
    if(!isMobile){
      setCollapsed(false)
      if (stickyMenu?.current) {
        containerRef?.current?.appendChild(stickyMenu?.current);
      }
    }
    return () => {
      window.removeEventListener(
      "scroll",
      setCurrentActive
    );
  }
  },[isMobile]);

  useEffect(() => {
    if(isMobile){
      if(menuTitle !== "" && menuTitle !== tContents) {
        setCollapsed(true);
        setIsSticky(true);
        setIsCollapsible(true)
      } else if (menuTitle === tContents) {
        setCollapsed(false);
        setIsSticky(false);
        setIsCollapsible(false)
      }
    }
  },[menuTitle])

  const toggleMenu = () => {
    if(isMobile && isCollapsible){
      setCollapsed(!collapsed);
    }
  }

  const clickOnAnchor = (e, id) => {
    if(isMobile && id){
      e.preventDefault();
      const chapterDiv = document.getElementById(id);
      const pos = chapterDiv.offsetTop - 300;
      window.scrollTo(0, pos);
    }
  }

  // This checks to see if there are any titles and shows the list based on that.
  // In the Figma no anchor list appears for a solitary title on the page. 
  // A gotcha here is if a title appears in a section at the bottom of the page.
  // For now will add the anchor list if more than 1 titles exist.
  elements.forEach(element => {
    let title = element.elements.title?.value || element.elements.titleRichText?.value
    title = title?.elements?.title?.value ? title?.elements?.title?.value : title;

    if (title) {
      titles.push(title)
    }
  })

  return (
    <>
      {titles.length > 1 && (
        <div
          className={`anchors-container ${(isMobile && isSticky) ? 'anchors-container--sticky' : ''}`}
          ref={stickyMenu}
        >
          <aside  className="anchors-wrapper-div anchors" onClick={() => toggleMenu()}>
            <h4 className='anchors__title'>
              {(isMobile && menuTitle !== tContents) ? <PointIcon /> : <LinksIcon />}
              <span><RichText data={{ value : isMobile ? menuTitle : tContents }} /></span>
              {(isMobile && menuTitle !== tContents) && <OpenClosedIndicator open={collapsed} />}
            </h4>
            <ul className={`anchors__list${collapsed ? '--collapsed' : ''}`}>
              {
                elements.map((element, index) => {
                  let title = element?.elements?.title?.value || element.elements.titleRichText?.value
                  title = title?.elements?.title?.value ? title?.elements?.title?.value : title;
                  const locale = element?.elements?.locale?.value;
    
                  let sameLocale = !locale || locale?.toLowerCase().includes(getUrlCountryPath());

                  if (!title || !sameLocale) { return null; }
                  const id = stringToCssId(title)
                  return (
                    <li key={`${id}-${index}`}
                      className={`${(
                        menuTitle === title)
                        ? 'selected'
                        : ''
                        }`}
                    >
                      <>
                        {(menuTitle !== tContents && menuTitle === title) && <PointIcon />}
                        <a href={`#${id}`} onClick={(e) => clickOnAnchor(e, id)}><RichText data={{ value: title }} /></a>
                      </>
                    </li>
                  );
                })
              }
            </ul>
          </aside>
        </div>
      )}
    </>
  )
}

Anchors.propTypes = {
  elements: PropTypes.arrayOf(any)
};

export default Anchors
