import React from 'react';
import { OpenClosedIndicator } from 'components/shared/OpenClosedIndicator';
import PropTypes from 'prop-types';
import { getValue } from 'utils/contentUtils';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { VideoSelectorBody } from 'components/content/mainPromoPage/VideoSelectorBody';
import { unfocusMobile } from 'utils/unfocusMobile';



const VideoSelectorMobile = ({
  selectedIndex,
  setSelectedIndex,
  selectedBackgroundColour,
  primaryColour,
  videoElements,
}) => {
  const toggleVideo = (index) => {
    setSelectedIndex((prevSelectedIndex) => {
      const isSelected = prevSelectedIndex === index;
      return isSelected ? null : index;
    });
  };
  return (
    <div className="video-selector-mobile">
      <ul className="videos">
        {videoElements.map((videoElement, index) => {
          const { id, elements } = videoElement;
          const {
            name,
            description,
          } = elements;
          const isSelected = index === selectedIndex;
          const liStyle = isSelected ? { backgroundColor: selectedBackgroundColour } : null;
          const className = isSelected ? 'selected' : null;
          const chevronStyle = isSelected ? { color: primaryColour } : null;
          const nameValue = getValue(name);
          const descriptionValue = getValue(description);
          const unfocusKey = unfocusMobile({
            baseKey: id,
            isSelected,
          });

          return (
            <li
              key={id}
              className={className}
              style={liStyle}
            >
              <button
                key={unfocusKey}
                type="button"
                className="custom-focus"
                onClick={() => toggleVideo(index)}
              >
                <div className="name-description">
                  {
                    nameValue && (
                      <p className="name">{nameValue}</p>
                    )
                  }
                  {
                    descriptionValue && (
                      <p className="description">{descriptionValue}</p>
                    )
                  }
                </div>
                <div
                  className="chevron-container"
                  style={chevronStyle}
                >
                  <OpenClosedIndicator
                    open={isSelected}
                  />
                </div>
              </button>
              {isSelected && (
                <VideoSelectorBody
                  selectedIndex={selectedIndex}
                  videoElements={videoElements}
                  primaryColour={primaryColour}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

VideoSelectorMobile.propTypes = {
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  primaryColour: PropTypes.string,
  selectedBackgroundColour: PropTypes.string,
  videoElements: PropTypes.arrayOf(contentElementProptype),
};

export { VideoSelectorMobile };
