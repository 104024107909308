import { lazy } from 'react';
import ContentCard from './ContentCard';
import ContentCardItem from './ContentCardItem';
import {
  getFullUrl,
  getValue,
  getValues,
  getSelectionValue,
} from 'utils/utilityHelper';
import ButtonAcousticData from 'components/kanso/atoms/button/ButtonAcousticData';
import Image from 'components/kanso/atoms/image/Image';
import RichText from 'components/reusableComponents/richText/RichText';
// const ButtonAcousticData = lazy(() => import('components/kanso/atoms/button/ButtonAcousticData'))
// const Image = lazy(() => import('components/kanso/atoms/image/Image'))
// const RichText = lazy(() => import('@lib/richText/RichText'))

const ContentCardAcousticData = (props) => {
  const { contentCardItems } = props.data;

  const getRichText = (text) => {
    if (!text) return null;
    return <RichText data={{ value: text }} />;
  };

  return (
    <ContentCard>
      {contentCardItems?.values?.map((contentCardItem, index) => {
        return (
          <ContentCardItem
            key={index}
            image={
              <Image
                src={getFullUrl(contentCardItem?.elements?.image?.url)}
                mediaSrc={contentCardItem?.elements?.image.asset}
                altText={getValue(contentCardItem?.elements?.image?.altText)}
              />
            }
            title={getValue(contentCardItem?.elements?.title)}
            titleTag={getSelectionValue(contentCardItem?.elements?.titleTag)}
            subtitle={getValue(contentCardItem?.elements?.subtitle)}
            subtitleTag={getSelectionValue(
              contentCardItem?.elements?.subtitleTag
            )}
            label={getValue(contentCardItem?.elements?.label)}
            text={getRichText(getValue(contentCardItem?.elements?.text))}
            imageAlign={getSelectionValue(
              contentCardItem?.elements?.imageAlign
            )}
          >
            {contentCardItem?.elements?.linksButtons &&
              getValues(contentCardItem?.elements?.linksButtons) && (
                <>
                  {getValues(contentCardItem?.elements?.linksButtons).map(
                    (button, i) => {
                      return (
                        <ButtonAcousticData key={i} data={button.elements} />
                      );
                    }
                  )}
                </>
              )}
          </ContentCardItem>
        );
      })}
    </ContentCard>
  );
};

export default ContentCardAcousticData;
