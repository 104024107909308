import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import {
  INPUT,
  BUTTON,
  SHOULD_MATCH_FIELD,
  commonNewPasswordValidations,
  LINK_BUTTON,
} from 'components/shared/form/formConstants';
import { HOME_PAGE_PATH } from 'routes/routeConstants';
// 26/11/2020 - Moved these fields into the ResetPassword.jsx file to handle translations. This file can be possibly be removed - KB

const NEW_PASSWORD = 'new-password';

const useResetPasswordFields = () => {
  const {
    tPassword,
    tEnterNewPassword,
    tConfirmPassword,
    tPasswordsDoNotMatch,
    tContinueText,
    tCancel,
  } = useWebsiteConfigObject();


  const resetPasswordFields = [
    {
      label: tPassword,
      id: NEW_PASSWORD,
      initialValue: '',
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: 'password',
        autoComplete: 'new-password',
        placeholder: tEnterNewPassword,
      },
      validation: [
        ...commonNewPasswordValidations,
      ],
    },
    {
      id: 'reset-password',
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          actions.handleResetPassword(formValues);
        },
        text: tContinueText,
        type: 'submit',
      },
    },
    {
      id: 'cancel-reset-password',
      type: LINK_BUTTON,
      size: 50,
      typeParams: {
        to: HOME_PAGE_PATH,
        text: tCancel,
        style: 'blank',
      },
    },
  ];

  return resetPasswordFields;
};







export { useResetPasswordFields };

