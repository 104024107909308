import React, { useEffect, useRef } from 'react'
import { contentItemProptype } from 'propTypes/contentProptypes'

// modules
import Logo from 'modules/logo/Logo'
import Hero from 'modules/hero/Hero'
import Header from 'modules/header/Header'
import Subnavigation from '../../modules/subNavigation/SubNavigation'
import RelatedContent from 'modules/relatedContent/RelatedContent'
import Content from 'modules/content/Content'
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
    SCREEN_WIDTH_MOBILE,
  } from 'components/layout/ScreenWidthContext';

// components
import { References } from 'components/content/References'
import Anchors from 'modules/contentAnchors/ContentAnchors'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode'

// styles
import './BrandArticlePage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'
import { getAssetUrl } from 'api/acousticApi'


/*
  Brand Article Page (supercedes the Content Promo Page)
  Figma design here https://www.figma.com/file/iHhNCUBqTgthhUCuU6KCbx/KKI_Hub_Release2-3?node-id=315%3A112
*/

const BrandArticlePage = ({ content }) => {
    const {      
        logo,
        brandColours,
        hero,
        title,
        titleRichText,
        header,
        subnavigation,
        content: contentSection,
        bannerImage,
        veevaApprovalCode,
        references,
        relatedContent,
        basePage
    } = content.elements

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { primary, pullQuoteBackground } = brandColours?.value?.elements || {}
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
    const stickyMenuRef = useRef();

    const settings = {
        infinite: contentSection?.values?.elements?.carousel?.value?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        autoplay: isSmall ? false  : contentSection?.values?.elements?.carousel?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: contentSection?.values?.elements?.carousel?.value?.elements?.automationInterval?.value ? parseFloat(contentSection?.values?.elements?.carousel?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: { arrows: false },
            },
        ],
    }

    const { pageBackgroundImage, pageBackgroundImageMobile } =
    basePage?.value || {};
    const backgroundImageUrl = getAssetUrl(pageBackgroundImage),
    backgroundImagemobileUrl = getAssetUrl(pageBackgroundImageMobile);

    const backgroundStyle =
    backgroundImageUrl || backgroundImagemobileUrl
      ? {
          backgroundImage: `url(${
            !isSmall ? backgroundImageUrl : backgroundImagemobileUrl
          })`,
        //   backgroundRepeat: 'no-repeat',
          backgroundPositionY: '180px',
          backgroundPositionX: 'center',
          backgroundSize: 'contain',
        }
      : null;

    return (
        <section className={`brand-article-page ${contentSection?.values && ' anchorsFix'}`} role="main" style={backgroundStyle}>
            <section className='brand-article-page__logo'>
                <Logo content={logo} />
            </section>
            <section className='brand-article-page__hero'>
                <Hero content={hero} />
            </section>
            <section className='brand-article-page__header'>
                <Header title={title} titleRichText={titleRichText} content={header} backgroundColour={primary} />
            </section>           
            <section className='brand-article-page__content-section'>
                <div className='container'>
                    <div className='brand-article-page__content-section-inner'>
                        <div className='brand-article-page__subnavigation'>
                            {subnavigation?.value &&
                                <Subnavigation element={subnavigation?.value} colour={primary?.value} /> 
                            }
                        </div>
                        <div className='brand-article-page__content'>                            
                            {contentSection?.values?.map((section, index) => {
                                const elements = (section.elements?.carousel?.value?.elements?.item?.values || []);
                                let isImageSlide = true;
                                let props = {
                                    content: section,
                                    colour: primary,
                                    key: index,
                                    pageContent: content
                                }
                                for ( let carouselItem of elements ) {
                                    if (typeof carouselItem.elements?.image?.url === 'undefined')
                                        isImageSlide = false;
                                        break;
                                }
                                if (isImageSlide) {
                                    props.componentType = 'slides';
                                    props.settings = settings;
                                }
                                if(pullQuoteBackground){
                                    props.pullQuoteBackground = pullQuoteBackground;
                                }
                                return <Content  brandColours={brandColours} cardClass='VideoTitleCard' {...props} />
                            })}
                        </div>
                        <div ref={stickyMenuRef} className='brand-article-page__content-titles'>
                            {contentSection?.values && (
                                <Anchors elements={contentSection.values} containerRef={stickyMenuRef}/>
                            )}                            
                        </div>
                    </div>
                </div>                
            </section>
            <section className='brand-landing-page__related-content'>
                {relatedContent && <RelatedContent content={relatedContent} cardTheme='secondary' contain />}
            </section>
            {bannerImage && <BannerImage image={bannerImage} />}
            <ApprovalCode code={veevaApprovalCode} />  
            {adverseEventsComplianceText?.value && (
                <section className='brand-article-page__references-section'>
                    <div className='container'>
                        <div className='brand-article-page__references'>
                            {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />} 
                        </div>
                    </div>
                </section>
            )}            
            {references && (
                <section className='brand-article-page__references-section'>
                    <div className='container'>
                        <div className='brand-article-page__references'>
                            <References references={references} />
                        </div>
                    </div>
                </section>
            )}
        </section>
    )
}

BrandArticlePage.propTypes = {
    content: contentItemProptype,
}

export default BrandArticlePage
