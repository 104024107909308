import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { utmCampaignQuery } from './utmCampaignQuery';
import { useLocation } from 'react-router-dom';
import { getValue } from 'utils/utilityHelper';
import { getUrlCountryPath } from 'utils/urlUtils';
import OneTrustCookie from 'components/shared/onetrustCookie/OneTrustCookie';
import Modal from 'components/kanso/components/modal/Modal';
import ModalContent from 'components/kanso/components/modal/ModalContent';
import RichText from 'components/reusableComponents/richText/RichText';

const UTM_CAMPAIGN_QUERY_KEY = 'UTM_CAMPAIGN';

export const ExpiredCampaignModal = (props) => {
  const [show, showModal] = useState(true);

  const toggleModal = (value) => showModal(value);

  return (
    <Modal
      show={show}
      onClose={() => {
        toggleModal(false);
      }}
      align='center'
      cssClass='expired-modal'
    >
      <ModalContent>
        <RichText
          data={{
            value: props.content,
          }}
        />
      </ModalContent>
    </Modal>
  );
};

const useUtmUnlock = () => {
  const { data, error, isLoading } = useQuery(
    UTM_CAMPAIGN_QUERY_KEY,
    utmCampaignQuery,
    { staleTime: Infinity } // only request the api routes once
  );

  const { pathname: path, search } = useLocation();
  const locale = getUrlCountryPath();

  if (!search || error || !data || isLoading)
    return {
      utmState: isLoading ? 'loading' : 'invalid',
    };

  const campaigns = data?.data?.documents;

  const hasExpired = (date) => {
    date.setHours(23, 59, 59);
    return date - new Date() < 0;
  };

  const isValid = (date) => {
    date.setHours(0, 0, 0, 0);
    return date - new Date() <= 0;
  };

  const qsMatch = (utmQs, qs) => {
    const utmParams = utmQs.split('&');
    let match = true;

    for (const param of utmParams) {
      const [key, value] = param.split('=');
      if(!qs.includes(param) && 
        !qs.includes(`${key}=${encodeURIComponent(value)}`) &&
        !qs.includes(`${key}=${decodeURIComponent(value)}`)) match = false;
    }

    return match;
  };

  const urlMatch = (url) => {
    const paths = [path, `/${locale}${path}`, window.location.href.substring(0, window.location.href.indexOf('?'))];
  
    return paths.some(value => {
      if(url?.replaceAll('/','') === value?.replaceAll('/','')) { 
        return true; 
      }
      return false;
    });
  }

  let validCampaign = null;

  const campaignList = campaigns
    ?.filter((item) => {
      const { url, utmQs, hubLocale, startDate, endDate } =
        item?.document?.elements;
      const start = new Date(getValue(startDate)),
        end = new Date(getValue(endDate));

      const campaignCheck =
        locale === getValue(hubLocale) &&
        urlMatch(getValue(url)) &&
        qsMatch(getValue(utmQs), search) &&
        isValid(start);

      if (campaignCheck && !hasExpired(end))
        validCampaign = item?.document?.elements;

      return campaignCheck;
    });

  return {
    utmState: validCampaign !== null ? 'unlocked' : 'expired',
    expiredMessage:
      !validCampaign && Boolean(campaignList?.length)
        ? getValue(campaignList[0]?.document?.elements?.expiredMessage)
        : null,
  };
};

export default useUtmUnlock;
