import React from 'react'
import Link from 'components/reusableComponents/link/Link'
import { useSelector } from 'react-redux';

import { getAssetUrl } from 'api/acousticApi'

// styles
import './CardHeading.scss'

const CardHeading = ({ icon, heading, href }) => {
    const { redesign } = useSelector(state => state.ui);

    if (!heading) return null
    if(redesign){
        if (href) {
            return (
                <Link href={href} className='CardHeading'>
                    <p>{heading}</p>
                    {icon && <img src={getAssetUrl(icon) || null} alt='' />}
                </Link>
            )
        }
    
        return (
            <h3 className='CardHeading'>
                <p>{heading}</p>
                {icon && <img src={getAssetUrl(icon) || null} alt='' />}
            </h3>
        )
    }
    if (href) {
        return (
            <Link href={href} className='CardHeading'>
                <span>{heading}</span>
                {icon && <img src={getAssetUrl(icon) || null} alt='' />}
            </Link>
        )
    }

    return (
        <h3 className='CardHeading'>
            <span>{heading}</span>
            {icon && <img src={getAssetUrl(icon) || null} alt='' />}
        </h3>
    )
}

export default CardHeading
