import React, { useState, useEffect } from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { ViewMoreButton } from 'components/shared/ViewMoreButton';
import { ContentListPageCard } from 'components/content/contentListPage/ContentListPageCard';
import { SectionPageList } from 'modules/sectionPage/SectionPageList';
import { PromoBanner } from 'components/layout/PromoBanner';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { PromoKit } from 'components/shared/PromoKit';
import { SectionPage } from 'modules/sectionPage/SectionPage';
import { BannerImage } from 'components/content/BannerImage';
import { References } from 'components/content/References';
import { ApprovalCode } from 'components/content/ApprovalCode';
import {
  DISEASE_PAGES,
  MEDICAL_APPLICATIONS,
  MEDICAL_EDUCATION,
  filterContentItemsByTherapySubArea,
  useSubareaParameterFromPath,
} from 'components/content/contentListPage/therapySubAreas';
import { getValue, getValues, getSelectionValue } from 'utils/contentUtils';
import './ContentListPage.scss';
import Subnavigation from '../../modules/subNavigation/SubNavigation';
import { AppConfig } from 'AppConfig';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
} from 'components/layout/ScreenWidthContext';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';

const CARDS_PER_PAGE = 9;

const VIEW_ALL_ID = 'view-all';

// function to filter cards based on the therapy sub areas associated with that card
const filterCards = (pageContentFilters, cards, selectedFilter) => {
  const contentFilter = pageContentFilters.find((filter) => filter.id === selectedFilter);
  const { therapySubArea } = contentFilter;
  return filterContentItemsByTherapySubArea(cards, therapySubArea);
};

// function to paginate the list of cards based on the defined number of cards per page
const paginateCards = (cards, pagesShown = 1, subcategory) => {
  let cardArray = [];
  if(subcategory) {
    cards.forEach(element => {
      if(element?.elements?.subcategory?.value === subcategory) {
        cardArray.push(element);
      }
    });    
  } else {
    cardArray = cards ? [...cards] : [];
  }
  const numCardsToDisplay = pagesShown * CARDS_PER_PAGE;
  const paginatedCards = cardArray ? cardArray.slice(0, numCardsToDisplay) : [];
  const hasMorePages = cardArray ? cardArray.length > paginatedCards.length : false;
  return ({
    paginatedCards,
    hasMorePages,
  });
};

// get the id for the initial filter
// based either on a therapy sub are parameter from the url
// or the default, which is "View all"
const useInitialFilterId = (pageContentFilters) => {
  const defaultId = VIEW_ALL_ID;
  const initialSubArea = useSubareaParameterFromPath();
  if (!initialSubArea) { return defaultId; }
  // use decodeURIComponent to convert E.G `Medical%20education` to `Medical education`
  const decoded = decodeURIComponent(initialSubArea);
  const initialFilter = pageContentFilters.find((filter) => filter.therapySubArea === decoded);
  return initialFilter ? initialFilter.id : VIEW_ALL_ID;
};

const ContentListPage = ({
  content,
}) => {
  const {
    tAll,
    tViewAll,
    tMedicalEducation,
    tDiseasePages,
    tMedicalResources,
  } = useWebsiteConfigObject();
  const pageContentFilters = [
    {
      id: VIEW_ALL_ID,
      text: tViewAll,
    },
    {
      id: 'medical-education',
      text: tMedicalEducation,
      therapySubArea: MEDICAL_EDUCATION,
    },
    {
      id: 'disease-pages',
      text: tDiseasePages,
      therapySubArea: DISEASE_PAGES,
    },
    {
      id: 'medical-applications',
      text: tMedicalResources,
      therapySubArea: MEDICAL_APPLICATIONS,
    },
  ];
  const initialFilterId = useInitialFilterId(pageContentFilters);
  const [selectedFilter, setFilter] = useState(initialFilterId);
  const [pagesShown, setPagesShown] = useState(1);
  const [subcategory, setSubcategory] = useState('');
  // if initialFilterId changes while we are on this page (e.g using the navigation)
  // we need to use useEffect to change the filter in state
  useEffect(
    () => setFilter(initialFilterId),
    [initialFilterId, setFilter],
  );
  const {
    category,
    title,
    headerText,
    headerLogo,
    headerBackgroundImage,
    headerTitleHexColour,
    headerBackgroundHexColour,
    cardSubcategoryFilter,
    cards,
    moduleSectionPageList,
    alternateSectionDisplay,
    headerAlternateDisplay,
    callout,
    bannerImage,
    references,
    backgroundcolor,
    subnavigation,
    basePage,
    informationCards,
    veevaApprovalCode
  } = content.elements;
  
  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;

  const { currentWidthRanges } = useScreenWidth();
  const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
  const {pageBackgroundImage, pageBackgroundImagemobile} = basePage?.value || {};

  // retrieve filter and paginate the card elements
  const cardValues = getValues(cards);
  const filteredCards = filterCards(pageContentFilters, cardValues, selectedFilter);
  const { paginatedCards, hasMorePages } = paginateCards(filteredCards, pagesShown, subcategory);
  const hasCards = paginatedCards ? paginatedCards.length > 0 : false;
  const pageBackgroundColor = getSelectionValue(backgroundcolor) === 'grey' ? true : false;
  let bgImage = {
    backgroundImage: pageBackgroundImage?.url ? `url(${pageBackgroundImage?.url ? AppConfig.acousticApi.domain + pageBackgroundImage?.url : null})` : "",
  }
  let bgImageMobile = {
    backgroundImage: pageBackgroundImagemobile?.url ? `url(${pageBackgroundImagemobile?.url ? AppConfig.acousticApi.domain + pageBackgroundImagemobile?.url : null})` : "",
  }
  const SubcategoryFilter = ({ cardSubcategoryFilter, cards }) => {
    const cardSubcategoryFilterValue = getValue(cardSubcategoryFilter);
    const filteredSubcategories = new Map([
      ...cards.map(card => (getSelectionValue(card.elements?.subcategory)) ? [getSelectionValue(card.elements?.subcategory)] : [getValue(card.elements?.subcategory)])
    ]);

    const showAllCards = () => {
      setSubcategory('');
    }

    const showSubcategoryCards = (subcategory) => {
      setSubcategory(subcategory);
    }
    
    if (!cardSubcategoryFilterValue)
      return null;

    return (
      <ul className="module-subcategory-filter"> 
        <li className="subcategory-item">
          <button id="btnFilterShowAll" className={subcategory === '' ? 'subcategory-button selected' : 'subcategory-button'} type="button" onClick={showAllCards}>
            <p>{ tAll }</p>
          </button>
        </li>
        {[...filteredSubcategories].map(([subCategory], i) => subCategory && (
          <li key={i} className="subcategory-item">
            <button className={subcategory === subCategory ? 'subcategory-button selected' : 'subcategory-button' }
            type="button" onClick={() => {showSubcategoryCards(subCategory)}}>
              <p>{ subCategory }</p>
            </button>
          </li>
        ))}
      </ul>
    )
  };


  return (
    <>
    <section role="main"  className='list-content'
    style={{ backgroundColor: pageBackgroundColor ? pageBackgroundImage?.url ? '' : '#faf6f4' : '',
             marginBottom: pageBackgroundColor ? '1em' : '' }}>
      <MainAreaTitleSection
        category={category && category.value}
        title={title && title.value}
        description={headerText}
        headerAlternateDisplay={headerAlternateDisplay}
        logo={headerLogo}
        backgroundImage={headerBackgroundImage}
        fontHexColour={headerTitleHexColour}
        backgroundHexColour={headerBackgroundHexColour}
      />
      
      <section className="content-section" style={isSmall ? bgImageMobile : bgImage}>
        <div className="container">
            <div className="list-content-page">
              <div className="column-left">
              <section className="main-page-subnavsection">
                {subnavigation?.value &&
                  <Subnavigation element={getValue(subnavigation)} />
                }
              </section>
              </div>
              {hasCards && (
                <div className="main-content">
                  <SubcategoryFilter cardSubcategoryFilter={cardSubcategoryFilter} cards={cardValues} />
                  <div className="cards-list">
                    {paginatedCards.map((card) => {
                        const categoryClass = (card.elements?.subcategory?.value?.selection) ? card.elements?.subcategory?.value?.selection : card.elements?.subcategory?.value
                        return (
                          <div
                            key={card.id}
                            className={"list-content-page-card-wrapper subcategoryfilter subcategory-filter-" 
                            + categoryClass?.split(' ').join('-') }
                          >
                            <ContentListPageCard card={card} />
                          </div>
                        )                
                      })
                    }
                  </div>
                  {
                    hasMorePages && ( // hide the view more button once all the results are loaded
                      <div className="view-more-button-container">
                        <ViewMoreButton
                          text="View more"
                          onClick={() => setPagesShown(pagesShown + 1)}
                        />
                      </div>
                    )
                  }
                </div>
              )}
            </div>


            <SectionPageList moduleSectionPageList={moduleSectionPageList} alternateSectionDisplay={alternateSectionDisplay} />
            {
              informationCards?.values && informationCards?.values.map((element) => {
                const {
                  id,
                  icon,
                  leftColumnText,
                  leftColumnTitle,
                  link,
                  open,
                  paragraphCards,
                  disableAccordion,
                  subtitle
                } = element.elements;
                return <PromoKit key={id} subtitle={subtitle} resources={paragraphCards} disableAccordion={disableAccordion} startOpen={disableAccordion?.value ? true : open?.value} titlePage={link?.linkURL} leftColTitle={leftColumnTitle} leftColContent={leftColumnText} icon={icon} />
              })
            }
            {callout?.value?.bodyText?.value && <PromoBanner content={callout} whiteBg={pageBackgroundColor ? true : false} />}

          </div>
        </section>
        {bannerImage && <BannerImage image={bannerImage} />}
      </section>
      <section className='list-content' style={{ marginTop: pageBackgroundColor ? '2em' : '' }}>
        {
          adverseEventsComplianceText?.value && (
            <div className="container">
              <div className="references-outer">
                {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
              </div>
            </div>
          )
        }
        {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
        {
          references && (
            <div className="container">
              <div className="references-outer">
                <References
                  references={references}
                />
              </div>
            </div>
          )
        }
      </section>
    </>
  );
};

ContentListPage.propTypes = {
  content: contentItemProptype,
};



export { ContentListPage };
