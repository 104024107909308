import { authGetQuery } from 'api/AxiosQueries';
import { AppConfig } from 'AppConfig';


export const fileToJson = async (url) =>{
    let json = null
        const fileUrl = AppConfig.acousticApi.domain + url
        await authGetQuery({ url: fileUrl}).then((response)=>{
            json = response?.data
        });
    return json
}