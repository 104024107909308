import { AppConfig } from 'AppConfig';

const {
  minWidthMedium,
  minWidthXl,
  minWidthLarge,
  numberColumnsDefault,
  numberColumnsSmall,
  gutterWidthDefault,
  gutterWidthXl,
  containerMaxWidth,
  containerMobilePaddingPixels,
  containerLargePaddingPercent,
  containerXlPaddingPercent,
} = AppConfig.responsiveGrid;

const isXl = (screenWidth) => screenWidth >= minWidthXl;
const isSmall = (screenWidth) => screenWidth < minWidthMedium;
const isMobile = (screenWidth) => screenWidth < minWidthLarge;

// container maxes out at 1600px
const getContainerWidth = (screenWidth) => (
  screenWidth > containerMaxWidth ? containerMaxWidth : screenWidth
);

// left and right padding within the container is either 20px on mobile
// 3% of width on large
// or 5% of width on xl
const getContainerPadding = (screenWidth) => {
  if (isMobile(screenWidth)) {
    return containerMobilePaddingPixels;
  }
  const containerWidth = getContainerWidth(screenWidth);
  const paddingPercent = isXl(screenWidth) ? containerXlPaddingPercent : containerLargePaddingPercent;
  return paddingPercent * containerWidth / 100;
};

// calculate all the relevant screen dimensions to use in javascript for the responsive grid
const getScreenDimensions = (screenWidth = 0) => {
  const xl = isXl(screenWidth);
  const small = isSmall(screenWidth);
  const containerWidth = getContainerWidth(screenWidth);
  const numberColumns = small ? numberColumnsSmall : numberColumnsDefault;
  const gutterWidth = xl ? gutterWidthXl : gutterWidthDefault;
  const containerPadding = getContainerPadding(screenWidth);
  // width of a grid row is the container width - padding either side
  const gridWidth = containerWidth - (containerPadding * 2);
  const numberGutters = numberColumns - 1;
  const columnWidth = (gridWidth - (numberGutters * gutterWidth)) / numberColumns;
  return ({
    containerWidth,
    numberColumns,
    gutterWidth,
    containerPadding,
    columnWidth,
    gridWidth,
  });
};

const widthOfNColumns = (numberColumns, screenWidth) => {
  const {
    columnWidth,
    gutterWidth,
  } = getScreenDimensions(screenWidth);
  const numberGutters = numberColumns - 1;
  return (columnWidth * numberColumns) + (gutterWidth * numberGutters);
};

const getMaxContainerWidth = (screenWidth) => {
  const {
    numberColumns,
  } = getScreenDimensions(screenWidth);
  return widthOfNColumns(numberColumns, screenWidth);
};

export {
  widthOfNColumns,
  getScreenDimensions,
  getMaxContainerWidth,
};
