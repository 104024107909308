import React from 'react';
import { contentItemProptype } from 'propTypes/contentProptypes';
import {
  getValue,
} from 'utils/contentUtils';
import RichText from 'components/reusableComponents/richText/RichText';
import {TextCardMoodle} from 'components/content/TextCardMoodle';
import { TextCardLearnMore } from './TextCardLearnMore';
import { AccessCourse } from './AccessCourse';

// text card that is an excerpt view of a CMS page
const CMSMoodleCard = ({ page }) => {
  if (!page) { return null; }
  const {
    title,
    cardCategory,
    excerpt,
    urlSlug,
    courseExternalLink, //need to check if other page are affected
    headerLogo,
  } = page.elements;
  return (
    <TextCardMoodle
      title={getValue(title)}
      category={getValue(cardCategory)}
      onClickPath={getValue(urlSlug)}
      logoSrc={headerLogo}
    >
      <RichText
        data={excerpt}
      />
      <div className="learnMore-button-section">
        <div className="learnText">
        <TextCardLearnMore onClickPath={urlSlug?.value}/>
        </div>
        <div className="accessText">
        <AccessCourse onClickPath={courseExternalLink?.value} />
        </div>
      </div> 
    </TextCardMoodle>
  );
};

CMSMoodleCard.propTypes = {
  page: contentItemProptype,
};

export { CMSMoodleCard };

