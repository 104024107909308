import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropTypes } from 'propTypes/generic';
import { useToggleOpen } from 'utils/toggleOpen';
import { unfocusMobile } from 'utils/unfocusMobile';
import { OpenClosedIndicator } from '../shared/OpenClosedIndicator';
import './CollapsableDetails.scss';
import { propTypes } from 'react-bootstrap/esm/Image';


const CollapsableDetails = ({
  className,
  children,
  defaultState,
  title,
  vvClickAttr,
  vvSnapshotAttr,
  vvSequenceAttr

}) => {
  const { open, toggleOpen } = useToggleOpen(defaultState);
  const cssClass = `collapsible-details${className ? ` ${className}` : ''}`;
  const unfocusKey = unfocusMobile({
    baseKey: 'collapsable-detail',
    isSelected: open,
  });

  return (
    <div className={cssClass}>
      <button
        type="button"
        key={unfocusKey}
        onClick={toggleOpen}
        className="custom-focus"
        data-vv-action={vvClickAttr}
        data-vv-snapshot={vvSnapshotAttr}
        data-vv-seq={vvSequenceAttr}
      >
        {title}
        <OpenClosedIndicator open={open} />
      </button>
      {open && (
        <div className="contents">
          {children}
        </div>
      )}
    </div>
  );
};

CollapsableDetails.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes,
  title: PropTypes.string,
  vvClickAttr: PropTypes.string,
  vvSnapshotAttr: PropTypes.string,
};

export { CollapsableDetails };
