import React from 'react';

//modules
import RichText from 'components/reusableComponents/richText/RichText';
import Image from 'components/reusableComponents/image/Image';
import { ChevronRight } from 'components/shared/SvgIcons';
import Link from 'components/reusableComponents/link/Link';

import './imagePanelFooterCopy.scss';

const ImagePanelFooterCopy = ({ ctaBackground, content, backgroundColor }) => {
  const {
    heading,
    leftButtonText,
    leftImage,
    leftImageFooter,
    leftText,
    leftTextLink,
    rightImage,
    rightImageFooter,
  } = content?.elements;

  const style = {
    backgroundColor: backgroundColor?.value
      ? backgroundColor?.value
      : '#F9F4F2',
  };

  const ctaStyle = {
    backgroundColor: ctaBackground?.value
      ? ctaBackground?.value
      : '#ea5504',
  };

  return (
    <div className='ImagePanelFooterCopy' style={style}>
      {heading?.value && (
        <div className='ImagePanelFooterCopy__title'>
          <h4>{heading?.value}</h4>
        </div>
      )}
      <div className='ImagePanelFooterCopy__sections'>
        <div className={`left${rightImage?.asset ? '' : ' full-width'}`}>
          {leftImage?.asset && (
            <div className='left-image'>
              <Image data={leftImage} />
            </div>
          )}
          {leftText?.value && (
            <div className='left-text'>
              <RichText data={leftText} />
            </div>
          )}
          {leftTextLink?.value && leftButtonText?.value && (
            <Link className='learn-more' href={leftTextLink?.value} style={ctaStyle}>
              <p className='learn-more-text'>{leftButtonText?.value}</p>
              <ChevronRight />
            </Link>
          )}
          {leftImageFooter?.value && (
            <div className='left-footer-text'>{leftImageFooter?.value}</div>
          )}
        </div>
        <div className={`right${rightImage?.asset ? '' : ' d-none'}`}>
          {rightImage?.asset && (
            <div className='right-image'>
              <Image data={rightImage} />
            </div>
          )}
          {rightImageFooter?.value && (
            <div className='right-footer-text'>{rightImageFooter?.value}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImagePanelFooterCopy;
