import React from 'react';
import { getImgAttr } from 'api/acousticApi/index';
import './BannerImageBackground.scss';
import Image from 'components/reusableComponents/image/Image';

const BannerImageBackground = ({ image, contain }) => {
  const bannerImageElement = image ? image.value : null;

  if (!bannerImageElement) {
    return null;
  }
  const {
    bannerMobile,
    bannerDesktop,
  } = bannerImageElement.elements;
  const desktopBannerImage = getImgAttr(bannerDesktop)

  return (
    desktopBannerImage && (
      <section className='banner-with-background'>
        <div
          className='banner-with-background__container desktop'
        >
         <Image data={bannerDesktop} />
        </div>
        <div
          className='banner-with-background__container mobile'
        >
         <Image data={bannerMobile} />
        </div>
      </section>
    )
  );
};

export { BannerImageBackground };
