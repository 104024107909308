import { getSelectionValue, getValue, getValues } from 'utils/utilityHelper';
import Table from './Table';
import TableItem from './TableItem';

const TableAcousticData = (props) => {
  const { title, titleTag, tableHead, tableBody } = props.data;

  const generateRows = (rows) => {
    return rows?.map((row, index) => {
      const columns = getValues(row?.elements?.columns);

      return (
        <tr key={`row-${index}`}>
          {columns?.map((column, index) => {
            const { text, type, colspan, rowspan } = column?.elements;
            const colType = getValue(type)?.selection;

            return (
              <TableItem
                key={`col-${index}`}
                type={colType ? colType : 'td'}
                colSpan={getValue(colspan)}
                rowSpan={getValue(rowspan)}
              >
                {colType && colType !== 'td' ? (
                  <strong>{getValue(text)}</strong>
                ) : (
                  getValue(text)
                )}
              </TableItem>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <Table title={getValue(title)} titleTag={getSelectionValue(titleTag)}>
      <table cellSpacing='0' cellPadding='0' border='0'>
        {getValues(tableHead)?.length > 0 && (
          <thead>{generateRows(getValues(tableHead))}</thead>
        )}
        {getValues(tableBody)?.length > 0 && (
          <thead>{generateRows(getValues(tableBody))}</thead>
        )}
      </table>
    </Table>
  );
};

export default TableAcousticData;
