import React, {useRef} from "react";
import ReactTooltip from 'react-tooltip';
import { getAssetUrl } from "api/acousticApi";

import './IconText.scss';
import RichText from "components/reusableComponents/richText/RichText";
import { toggleOverlay, closePopup } from 'components/pageTemplates/inforgraphicstb/components/helper';
import close from '../../../../assets/images/infographics-assets/circle-close-icon.svg';


const IconText = (props) => {

    const {
        description,
        displayCTAVersion,
        icon,
        tooltip
    } = props?.iconTextContent?.elements;

    let toolTipBtn = useRef(null);

    if (displayCTAVersion?.value) {
        return (
            <div className="icon-text-popup__item">
                <div className="icon-text-popup__imgWrapper">
                    <img src={getAssetUrl(icon)} alt={icon?.asset?.altText} />
                </div>
                <div className="label" data-popup-for={props.pid} ref={ref => toolTipBtn = ref}  data-tip data-event='click' data-for={props.pid}>
                    <RichText data={description} />
                </div>
                <ReactTooltip
                    id={props.pid}
                    data-id={props.pid}  
                    globalEventOff='click' 
                    place="bottom" 
                    textColor='#3D3F5B' 
                    backgroundColor='#ffffff' 
                    effect='solid'
                    afterShow={(evt) => toggleOverlay('show')}
                    afterHide={(evt) => toggleOverlay('hide')}
                    wrapper='span'>
                        <div className="tool-tip__wrapper">
                            <div className="tool-tip__close">
                                <img src={close} onClick={(evt) => closePopup(evt) } />
                            </div>
                            <div className="tool-tip__content">
                            <RichText data={tooltip} />
                            </div>
                        </div>
                </ReactTooltip>
            </div>
        );
    } else {
        return (
            <div className="icon-text__item">
                <div className="icon-text-item__imgWapper">
                    <img src={getAssetUrl(icon)} alt={icon?.asset?.altText} />
                </div>
                <div className="icon-text-item__text">
                    <RichText data={description} />
                </div>
            </div>
        );
    }
}

export default IconText;