import React, { useEffect, useRef } from 'react';
import Header from 'modules/header/Header';
import Logo from 'modules/logo/Logo';
import FlexiColumnContainer from 'modules/flexiColumnContainer/FlexiColumnContainer';
import Subnavigation from 'modules/subNavigation/SubNavigation';
import Content from 'modules/content/Content';
import Anchors from 'modules/contentAnchors/ContentAnchors';

// import '../../assets/kanso/styles/global.scss';
// import '../../assets/kanso/design-tokens.scss';
import 'assets/kanso/theme/ctcl/styles.scss';
import { AppConfig } from 'AppConfig';
import HeroHeaderAcousticData from 'components/kanso/components/heroHeader/HeroHeaderAcousticData';
import EditorialTextAcousticData from 'components/kanso/atoms/editorialText/EditorialTextAcousticData';
import { getValue } from 'utils/contentUtils';
import { setMultipleCSSProperties } from 'utils/utilityHelper';
import {
  SCREEN_WIDTH_SMALL,
  useScreenWidth,
} from 'components/layout/ScreenWidthContext';
import { getAssetUrl } from 'api/acousticApi';
import PageSectionAcousticData from 'components/kanso/patterns/pageSection/PageSectionAcousticData';
import { componentsMapping } from 'utils/acousticMappings';
import { useSelector } from 'react-redux';
import ModalAcousticData from 'components/kanso/components/modal/ModalAcousticData';
import ShareMenuAcousticData from 'components/kanso/components/shareMenu/ShareMenuAcousticData';
import AlertAcousticData from 'components/kanso/components/alert/AlertAcousticData';
import { References } from 'components/content/References';
import { ApprovalCode } from 'components/content/ApprovalCode';

export const getComponent = (componentItem, pageContent) => {
  const { title, brandColours } = pageContent?.elements || {};
  const { primary } = brandColours?.value?.elements || {};

  const mapping = [
    ...componentsMapping,
    {
      acousticComponentName: 'Module - Header',
      component: Header,
      props: {
        title,
        content: { value: componentItem },
        backgroundColour: primary,
      },
    },
    {
      acousticComponentName: 'Module - Logo',
      component: Logo,
      props: { content: { value: componentItem } },
    },
    {
      acousticComponentName: 'Flexi Column Container',
      component: FlexiColumnContainer,
      props: { content: { value: componentItem }, pageContent },
    },
    {
      acousticComponentName: 'Subnavigation',
      component: Subnavigation,
      props: { element: componentItem, colour: primary?.value },
    },
    {
      acousticComponentName: 'Anchors',
      component: Anchors,
    },
    {
      acousticComponentName: 'Kanso-Component-HeroHeader',
      override: true,
      props: {
        data: { ...componentItem?.elements, brandColours },
        pageContent,
      },
    },
    {
      acousticComponentName: 'Kanso-Pattern-PageSection',
      override: true,
      component: PageSectionAcousticData,
    },
    {
      acousticComponentName: 'Kanso-Component-CalloutQuote',
      override: true,
      props: {
        data: { ...componentItem?.elements, brandColours },
        pageContent,
      },
    },
    {
      acousticComponentName: 'Kanso-Pattern-Quiz',
      override: true,
      props: {
        data: { ...componentItem?.elements, embedded: true, pageContent },
      },
    },
  ];

  let matched;
  mapping.forEach((item) => {
    if (
      item.component &&
      (item.acousticComponentName === componentItem?.type ||
        item.acousticComponentName === componentItem?.elements?.category?.value)
    ) {
      matched = { ...item };
    }

    if (
      matched &&
      item.acousticComponentName === matched.acousticComponentName &&
      item.override
    ) {
      matched = {
        ...matched,
        ...item,
      };
    }
  });

  // If we don't have a match then use Module - Content component
  if (!matched)
    matched = {
      acousticComponentName: componentItem?.type,
      component: Content,
      props: {
        content: componentItem,
        colour: primary,
        brandColours,
      },
    };

  if (!matched?.props)
    matched['props'] = { data: componentItem?.elements, pageContent };

  return matched;
};

const FlexiTemplate = ({ content }) => {
  const showModal = useSelector((state) => state?.modal?.open);
  const showModalData = useSelector(
    (state) => state?.modal?.modalData?.content
  );
  const showModalType = useSelector((state) => state?.modal?.modalData?.type);
  const showShare = useSelector((state) => state?.share?.shareOpen);
  const showShareData = useSelector(
    (state) => state?.share?.shareData?.content
  );
  const elementRef = useSelector(
    (state) => state?.share?.shareData?.elementRef
  );
  const showAlert = useSelector((state) => state?.alert?.alertOpen);
  const showAlertData = useSelector(
    (state) => state?.alert?.alertData?.content
  );
  const { basePage, brandColours, references , veevaApprovalCode} = content.elements;
  const { primary, ctaButtonsBackground } = brandColours?.value?.elements || {};
  const { pageBackgroundImage, pageBackgroundImagemobile } =
    basePage?.value || {};

  const backgroundImageUrl = getAssetUrl(pageBackgroundImage),
    backgroundImagemobileUrl = getAssetUrl(pageBackgroundImagemobile);

  const { currentWidthRanges } = useScreenWidth();
  const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

  const backgroundStyle =
    backgroundImageUrl || backgroundImagemobileUrl
      ? {
          backgroundImage: `url(${
            !isSmall ? backgroundImageUrl : backgroundImagemobileUrl
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: '180px',
          backgroundPositionX: 'center',
          backgroundSize: 'cover',
        }
      : null;

  useEffect(() => {
    const btnBrandColor = getValue(ctaButtonsBackground);
    if (btnBrandColor) {
      const cssProperties = {
        // Primary Buttons
        '--primary-btn-bgcolor': btnBrandColor,
        '--primary-btn-hover-bgcolor': btnBrandColor,
        '--primary-btn-active-bgcolor': btnBrandColor,
        '--primary-btn-border-color': btnBrandColor,
        '--primary-btn-hover-border-color': btnBrandColor,
        '--primary-btn-active-border-color': btnBrandColor,

        // Secondary Buttons
        '--secondary-btn-hover-bgcolor': btnBrandColor,
        '--secondary-btn-active-bgcolor': btnBrandColor,
        '--secondary-btn-border-color': btnBrandColor,
        '--secondary-btn-hover-border-color': btnBrandColor,
        '--secondary-btn-icon-color': btnBrandColor,
        '--secondary-btn-active-border-color': btnBrandColor,
        '--secondary-btn-text-color': btnBrandColor,

        // Tertiary Buttons
        '--tertiary-btn-text-color': btnBrandColor,
        '--tertiary-btn-active-text-color': btnBrandColor,
        '--tertiary-btn-icon-color': btnBrandColor,
        '--tertiary-btn-active-icon-color': btnBrandColor,
        '--tertiary-btn-hover-text-color': btnBrandColor,
        '--tertiary-btn-hover-icon-color': btnBrandColor,
      };
      setMultipleCSSProperties(document.documentElement, cssProperties);
    }
  }, []);

  return (
    <div className={`flexi-template-container`} style={backgroundStyle}>
      {content?.elements?.contentList?.values?.map((componentItem) => {
        const { component: Component, props } = getComponent(
          componentItem,
          content
        );

        return Component && <Component key={componentItem?.id} {...props} />;
      })}
      {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
      {references && (
        <div className='flexi-template__references'>
          <div className='container'>
            <References references={references} />
          </div>
        </div>
      )}

      {showModal && showModalData && (
        <ModalAcousticData data={showModalData} type={showModalType} />
      )}

      {showShare && showShareData && (
        <ShareMenuAcousticData data={showShareData} elementRef={elementRef} />
      )}

      {showAlert && showAlertData && <AlertAcousticData data={showAlertData} />}
    </div>
  );
};

export default FlexiTemplate;
