import { lazy } from 'react'
import Carousel from './Carousel'
import CarouselContent from './CarouselContent'
import { getComponentName } from 'utils/acousticMappings'
import { getValue, getSelectionValue } from 'utils/utilityHelper'
import AudioAcousticData from 'components/kanso/atoms/audio/AudioAcousticData'
import ContentCard from '../contentCard/ContentCard'
import ContentCardItemAcousticData from 'components/kanso/components/contentCard/ContentCardItemAcousticData'
import ImageAcousticData from 'components/kanso/atoms/image/ImageAcousticData'
import VideoAcousticData from 'components/kanso/atoms/video/VideoAcousticData'
import HeroHeaderAcousticData from '../heroHeader/HeroHeaderAcousticData'
// const AudioAcousticData = lazy(() => import('components/kanso/atoms/audio/AudioAcousticData'))
// const ContentCard = lazy(() => import('components/kanso/components/contentCard//ContentCard'))
// const ContentCardItemAcousticData = lazy(() => import('components/kanso/components/contentCard/ContentCardItemAcousticData'))
// const HeroHeaderAcousticData = lazy(() => import('components/kanso/components/heroHeader/HeroHeaderAcousticData'))
// const ImageAcousticData = lazy(() => import('components/kanso/atoms/image/ImageAcousticData'))
// const VideoAcousticData = lazy(() => import('components/kanso/atoms/video/VideoAcousticData'))

const CarouselAcousticData = (props) => {
  const { 
    adaptiveHeight,
    autoplay,
    autoplaySpeed,
    carouselContentItems,
    cssEase,
    dots,
    fade,
    infinite,
    lazyLoad,
    navigationStyle,
    pauseOnHover,
    rtl,
    slidesToShow,
    slidesToScroll,
    speed,
    swipeToSlide,
    title,
    titleTag
  } = props.data

  const settings = {
    adaptiveHeight: getValue(adaptiveHeight),
    arrows: false,
    autoplay: getValue(autoplay),
    autoplaySpeed: getValue(autoplaySpeed),
    cssEase: getSelectionValue(cssEase),
    dots: getValue(dots),    
    fade: getValue(fade),
    infinite: getValue(infinite),
    lazyLoad: getValue(lazyLoad),
    pauseOnHover: getValue(pauseOnHover),
    rtl: getValue(rtl),
    slidesToShow: getValue(slidesToShow),
    slidesToScroll: getValue(slidesToScroll),
    speed: getValue(speed),
    swipeToSlide: getValue(swipeToSlide)
  }

  return (
    <Carousel 
      heading={getValue(title)} 
      headingTag={getSelectionValue(titleTag)} 
      settings={settings} 
      navigationStyle={getSelectionValue(navigationStyle)}
    >
       {carouselContentItems?.values?.map((carouselItem, index) => {
        if(getComponentName(carouselItem.type) === 'Image') {
          return (
            <CarouselContent key={index}>
              <ImageAcousticData data={carouselItem?.elements} />
            </CarouselContent>
          )
        }
        if(getComponentName(carouselItem.type) === 'Audio') {
          return (
            <CarouselContent key={index}>
              <AudioAcousticData data={carouselItem?.elements} />
            </CarouselContent>
          )
        }
        if(getComponentName(carouselItem.type) === 'Video') {
          return (
            <CarouselContent key={index}>
              <VideoAcousticData data={carouselItem?.elements} />
            </CarouselContent>
          )
        }
        if(getComponentName(carouselItem.type) === 'ContentCardItem') {
          return (
            <CarouselContent key={index}>
              <ContentCard isMultiple={true}>
                <ContentCardItemAcousticData data={carouselItem?.elements} />
              </ContentCard>
            </CarouselContent>
          )
        }
        if(getComponentName(carouselItem.type) === 'HeroHeader') {
          return (
            <CarouselContent key={index}>
              <HeroHeaderAcousticData key={index} data={carouselItem?.elements} />
            </CarouselContent>
          )
        }
      })}
    </Carousel>
  )
}

export default CarouselAcousticData
