import React, { useState } from 'react';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
  SCREEN_WIDTH_MEDIUM,
} from 'components/layout/ScreenWidthContext';
import { widthOfNColumns } from 'utils/responsiveUtils';
import PropTypes from 'prop-types';
import { Carousel } from './Carousel';
import { getCountryCode } from 'utils/urlUtils';

const CARD_SIZE_STANDARD = 'standard';
const CARD_SIZE_LARGE = 'large';

const CAROUSEL_COLUMNS = 10;
const CAROUSEL_COLUMNS_SMALL = 4;
const CARDS_VISIBLE_LARGE = 1;
const CARDS_VISIBLE_DESKTOP = 3;
const CARDS_VISIBLE_MEDIUM = getCountryCode() === 'de' ? 1 : 2;
const CARDS_VISIBLE_SMALL = 1;
const CARD_HEIGHT = 370;

const getVisibleCards = ({ currentWidthRanges, cardSize }) => {
  if (cardSize === CARD_SIZE_LARGE) {
    return CARDS_VISIBLE_LARGE;
  } // large card sizes take up the full width
  if (currentWidthRanges.includes(SCREEN_WIDTH_SMALL)) {
    return CARDS_VISIBLE_SMALL;
  }
  if (currentWidthRanges.includes(SCREEN_WIDTH_MEDIUM)) {
    return CARDS_VISIBLE_MEDIUM;
  }
  return CARDS_VISIBLE_DESKTOP;
};

const getCarouselColumns = ({ currentWidthRanges }) => {
  if (currentWidthRanges.includes(SCREEN_WIDTH_SMALL)) {
    return CAROUSEL_COLUMNS_SMALL;
  }
  return CAROUSEL_COLUMNS;
};

const CardCarousel = ({
  gaTrackingContentType,
  cards,
  autoplay,
  autoplaySpeed,
  cardSize = CARD_SIZE_STANDARD,
}) => {
  const [autoSlider, setAutoSlider] = useState(true);
  const [sliderBtnClicked, setSliderBtnClicked] = useState(false);
  const { width, currentWidthRanges } = useScreenWidth();
  const visibleCards = getVisibleCards({
    currentWidthRanges,
    cardSize,
  });
  const carouselColumns = getCarouselColumns({
    currentWidthRanges,
    cardSize,
  });
  const carouselWidth = widthOfNColumns(carouselColumns, width);
  const cardWidth = carouselWidth / visibleCards;
  const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

  return (
    <div
      onMouseEnter={() => setAutoSlider(false)}
      onMouseLeave={() => !sliderBtnClicked ? setAutoSlider(true) : setAutoSlider(false)}
    >
      <Carousel
        slideWidth={cardWidth}
        slideHeight={CARD_HEIGHT}
        visibleSlides={visibleCards}
        autoSlide={!isSmall && autoSlider && autoplay}
        timeSlider={autoplaySpeed || 1.5}
        setSliderBtnClicked={setSliderBtnClicked}
        slides={cards?.map((card, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className='carousel-card-slide'
          >
            {card}
          </div>
        ))}
        gaTrackingContentType={gaTrackingContentType}
      />
    </div>
  );
};

CardCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.element),
  cardSize: PropTypes.string,
  gaTrackingContentType: PropTypes.string,
};

export { CardCarousel };
