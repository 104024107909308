import { createSlice } from '@reduxjs/toolkit';
import {
  KKI,
  getAuthServiceAPI,
} from 'api/authService';
import {
  ACCOUNT_DETAILS,
  CHANGE_PASSWORD,
  errorCodesWithMessage,
  UNEXPECTED_ERROR,
  DELETE_ACCOUNT,
} from 'components/shared/form/formConstants';
import { getServerParametersForProfileForm, getServerParametersForChangePasswordForm } from 'utils/userDataUtils';
import { hasError, loading, resetFormState } from '../form/slice';
import { webinarSignUp } from 'api/dynamoApi';

const initialState = {
  fetching: false,
  details: {
    email: null,
    salutation: null,
    firstname: null,
    lastname: null,
    speciality: null,
    workplace: null,
    optinHCP: null,
    promotionalMaterialsOptIn: null,
    username:null,
  },
  webinars: null,
};
let body = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchDetails: (state) => {
      state.fetching = true;
    },
    // eslint-disable-next-line consistent-return
    setDetails: (state, action) => {
      state.fetching = false;
      const userData = action.payload;
      if (!userData) { console.error('Fetching User Error: No User Data'); return state; }
      state.details.email = userData.email;
      state.details.salutation = userData.salutation;
      state.details.firstname = userData.firstname;
      state.details.lastname = userData.lastname;
      state.details.speciality = userData.speciality;
      state.details.workplace = userData.workplace;
      state.details.role = userData.role;
      state.details.jobTitle = userData.jobTitle;
      state.details.department = userData.department;
      state.details.departmentPhone = userData.departmentPhone;
      state.details.phone = userData.phone;
      state.details.optinHCP = userData.optinHCP;
      state.details.promotionalMaterialsOptIn = userData.promotionalMaterialsOptIn;
      state.details.username = userData.username
    },
    updateState : (state, action) =>
    {
      const userName = action.payload;
      state.details.username = userName;
      state.details.optinHCP= true;
    },
    setWebinars: (state, action) => {
      state.webinars = action.payload || null;
    },
  },
});

export const {
  fetchDetails, setDetails, updateState, setWebinars
} = userSlice.actions;


export const userActions = {
  global: {
    details: {
      updateAnonymousState :(userName) => (dispatch) =>
      {
        dispatch(updateState(userName));
      },	  
      fetch: () => (dispatch, getState) => {
        const { token: _token, user } = getState().authentication.data || {};
        const { token } = _token || {};
        const { userId } = user || {};

        if (!userId || !token) { return; }

        dispatch(fetchDetails());

        getAuthServiceAPI().user.details.fetch(userId, token).then((response) => {
          dispatch(setDetails(response.data));
        });
      },
      update: (formData) => async (dispatch, getState) => {
        dispatch(loading(true));
        const { token: _token, user } = getState().authentication.data || {};
        const { token } = _token || {};
        const { userId } = user || {};
        const userData = getServerParametersForProfileForm(formData);
        const latestOptInValue = userData.promotionalMaterialsOptIn;
        const lastSavedOptInValue = getState().authentication.data.user
          .promotionalMaterialsOptIn;
        body['userId'] = userId ? userId : '';
        body["provider"] = "KKIHUB";
        body["type"] = "mpaudit";
        body["data"] = {"name" : `updated MP from ${lastSavedOptInValue} to ${latestOptInValue}`}
        
        try {
          await getAuthServiceAPI().user.details.update({
            userId,
            userData,
            authToken: token,
          }).then((response) => {
            if (latestOptInValue != lastSavedOptInValue && response.status == 200) {
              webinarSignUp({ body, token }).then((response) => {
            
                if (response.data.status === 200) {
                  console.log("Successfully submitted.")
                } else {
                  console.log("Error Occured")
                }
              })
                .catch((err) => {
                  if (errorCodesWithMessage.includes(err?.response?.status)) {
                    dispatch(hasError({ id: ACCOUNT_DETAILS, error: err }));
                  }
                });
            }
            dispatch(setDetails(response.data));
            dispatch(resetFormState());
          });
        }
        catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: ACCOUNT_DETAILS, error: e }));
          }

          throw e;
        }
      },
      delete: () => async (dispatch, getState) => {
        dispatch(loading(true));
        const { token: _token, user } = getState().authentication.data || {};
        const { token } = _token || {};
        const { userId } = user || {};

        try {
          await getAuthServiceAPI().user.details.deleteExternal({
            id: userId,
            token,
          }).then(() => {
            dispatch(resetFormState());
          });
        }
        catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: DELETE_ACCOUNT, error: e }));
          }
          else {
            dispatch(hasError({ id: DELETE_ACCOUNT, error: { message: UNEXPECTED_ERROR, response: { status: UNEXPECTED_ERROR } } }));
          }

          throw e;
        }
      },
    },
  },
  kki: {
    details: {
      delete: ({ password }) => async (dispatch, getState) => {
        dispatch(loading(true));
        const { token: _token, user } = getState().authentication.data || {};
        const { token } = _token || {};
        const { userId } = user || {};

        try {
          await getAuthServiceAPI({ service: KKI }).user.delete({
            password,
            id: userId,
            token,
          }).then(() => {
            dispatch(resetFormState());
          });
        }
        catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: DELETE_ACCOUNT, error: e }));
          }
          else {
            dispatch(hasError({ id: DELETE_ACCOUNT, error: { message: UNEXPECTED_ERROR, response: { status: UNEXPECTED_ERROR } } }));
          }

          throw e;
        }
      },
    },
    password: {
      change: (formData) => async (dispatch, getState) => {
        dispatch(loading(true));
        const { token: _token, user } = getState().authentication.data || {};
        const { token } = _token || {};
        const { userId } = user || {};
        const passwordData = getServerParametersForChangePasswordForm(formData);
        try {
          await getAuthServiceAPI({ service: KKI }).user.password.change({
            userId,
            passwordData,
            authToken: token,
          }).then(() => {
            dispatch(resetFormState());
          });
        }
        catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: CHANGE_PASSWORD, error: e }));
          }

          throw e;
        }
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default userSlice.reducer;
