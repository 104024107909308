import React, { useState, useEffect } from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { ViewMoreButton } from 'components/shared/ViewMoreButton';
import { ContentListPageCard } from 'components/content/contentListPage/ContentListPageCard';
import { SectionPageList } from 'modules/sectionPage/SectionPageList';
import { PromoBanner } from 'components/layout/PromoBanner';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { BannerImage } from 'components/content/BannerImage';
import { References } from 'components/content/References';
import { ApprovalCode } from 'components/content/ApprovalCode';
import {
  DISEASE_PAGES,
  MEDICAL_APPLICATIONS,
  MEDICAL_EDUCATION,
  filterContentItemsByTherapySubArea,
  useSubareaParameterFromPath,
} from 'components/content/contentListPage/therapySubAreas';
import { getValue, getValues, getSelectionValue } from 'utils/contentUtils';
import { SubcategoryFilterMoodle } from 'modules/subcategoryFilter/SubcategoryFilterMoodle';

import Subnavigation from 'modules/subNavigation/SubNavigation';


import '../ContentListPage.scss';
import './MoodleList.scss';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';

const CARDS_PER_PAGE = 3;

const VIEW_ALL_ID = 'view-all';

// function to filter cards based on the therapy sub areas associated with that card
const filterCards = (pageContentFilters, cards, selectedFilter) => {
  const contentFilter = pageContentFilters.find((filter) => filter.id === selectedFilter);
  const { therapySubArea } = contentFilter;
  return filterContentItemsByTherapySubArea(cards, therapySubArea);
};


// get the id for the initial filter
// based either on a therapy sub are parameter from the url
// or the default, which is "View all"
const useInitialFilterId = (pageContentFilters) => {
  const defaultId = VIEW_ALL_ID;
  const initialSubArea = useSubareaParameterFromPath();
  if (!initialSubArea) { return defaultId; }
  // use decodeURIComponent to convert E.G `Medical%20education` to `Medical education`
  const decoded = decodeURIComponent(initialSubArea);
  const initialFilter = pageContentFilters.find((filter) => filter.therapySubArea === decoded);
  return initialFilter ? initialFilter.id : VIEW_ALL_ID;
};
//TODO: learn more link to retrieve from acoustic
const MoodleList = ({
  content,
  template
}) => {
  const {
    tViewAll,
    tMedicalEducation,
    tDiseasePages,
    tMedicalResources,
  } = useWebsiteConfigObject();
  const pageContentFilters = [
    {
      id: VIEW_ALL_ID,
      text: tViewAll,
    },
    {
      id: 'medical-education',
      text: tMedicalEducation,
      therapySubArea: MEDICAL_EDUCATION,
    },
    {
      id: 'disease-pages',
      text: tDiseasePages,
      therapySubArea: DISEASE_PAGES,
    },
    {
      id: 'medical-applications',
      text: tMedicalResources,
      therapySubArea: MEDICAL_APPLICATIONS,
    },
  ];
  const initialFilterId = useInitialFilterId(pageContentFilters);
  const [selectedFilter, setFilter] = useState(initialFilterId);
  const [pagesShown, setPagesShown] = useState(1);
  const [startNumber, setStartNumber] = useState(0);
  // if initialFilterId changes while we are on this page (e.g using the navigation)
  // we need to use useEffect to change the filter in state
  useEffect(
    () => setFilter(initialFilterId),
    [initialFilterId, setFilter],
  );
  const {
    category,
    title,
    headerText,
    headerLogo,
    headerBackgroundImage,
    headerTitleHexColour,
    headerBackgroundHexColour,
    cardSubcategoryFilter,
    cards,
    moduleSectionPageList,
    callout,
    bannerImage,
    references,
    backgroundcolor,
    subnavigation,
    numbersOfCardPerPage,
    veevaApprovalCode
  } = content.elements;

  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  // retrieve filter and paginate the card elements
  const cardValues = getValues(cards);

  const pageBackgroundColor = getSelectionValue(backgroundcolor) === 'grey' ? true : false;
  let numOfCards= numbersOfCardPerPage?.value
  if(numOfCards === undefined){
    numOfCards = 10;
  }
  return (
    <div>  
    <section role="main"  className='list-content' id="moodleList"
    style={{ backgroundColor: pageBackgroundColor ? '#faf6f4' : '',
             marginBottom: pageBackgroundColor ? '1em' : '' }}>
      <MainAreaTitleSection
        category={category && category.value}
        title={title && title.value}
        headline={headerText}
        logo={headerLogo}
        backgroundImage={headerBackgroundImage}
        fontHexColour={headerTitleHexColour}
        backgroundHexColour={headerBackgroundHexColour}
      />
      
      <section className="content-section">
        <div className="container">
            <div className="list-content-page">
              <div className="column-left" id="column-left">
              <section className="main-page-subnavsection">
                {subnavigation?.value &&
                  <Subnavigation element={getValue(subnavigation)} />
                }
              </section>
              </div>
              <div className="main-content">
                <SubcategoryFilterMoodle cardSubcategoryFilter={cardSubcategoryFilter} paginatedCards={cardValues}  cardsNumber={numOfCards} />
              </div>
            </div>
        <SectionPageList moduleSectionPageList={moduleSectionPageList} />

        {callout?.value?.bodyText?.value && <PromoBanner content={callout} whiteBg={ pageBackgroundColor ? true : false}/> } 
        
        </div>
      </section>
      {bannerImage && <BannerImage image={bannerImage} />}
    </section>
    <section className='list-content' style={{ marginTop: pageBackgroundColor ? '2em' : '' }}>      
      {
        adverseEventsComplianceText?.value && (
          <div className="container">
            {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} removeBottomSpacing={true} />}
          </div>
        )
      } 
      {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />} 
      {
        references && (
          <div className="container">
            <div className="references-outer">
              <References
                references={references}
              />
            </div>
          </div>
        )
      } 
    </section>
    </div>
  );
};

MoodleList.propTypes = {
  content: contentItemProptype,
};



export { MoodleList };
