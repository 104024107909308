import { AppConfig } from 'AppConfig';
import { getLocationOrigin, getUrlCountryPath } from 'utils/urlUtils';
import { getAuthHeaderConfig } from 'utils/authUtils';
import { getServerParametersForUserForm, warnUndefinedKeys } from 'utils/userDataUtils';
import { OPTIN_HCP } from 'components/shared/form/formConstants';
import {
  CONFIRM_EMAIL,
  CONFIRM_DELETE_USER_ACCOUNT,
} from 'routes/routeConstants';
import {
  CREATE_TOKEN_URL,
  VALIDATE_TOKEN_URL,
  REFRESH_TOKEN_URL,
  USER_URL,
  CONFIRM_REGISTRATION_URL,
  DELETE_USER_URL,
  CONFIRM_DELETE_URL,
  FORGOTTEN_PASS_URL,
  RESET_PASS_URL,
  CHANGE_PASS_URL,
  UPDATE_CONSENT_URL,
} from 'api/genericApi/paths';
import {
  authGetQuery,
  authPostQuery,
  authPutQuery,
  authDeleteQuery,
} from '../AxiosQueries';

const hcpProviderID = 1;

const {
  audience,
  audienceSecret,
} = AppConfig.authApi.general;

const createUserToken = (username, password) => {
  const url = CREATE_TOKEN_URL;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
  });

  return authPostQuery({ url, data: { username, password }, config: headerConfig });
};

const verifyUserToken = ({ authToken }) => {
  const url = VALIDATE_TOKEN_URL;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
    authToken,
  });

  return authGetQuery({ url, config: headerConfig });
};

const refreshUserToken = (authToken, refreshToken) => {
  const url = REFRESH_TOKEN_URL;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience, authToken, refreshToken, secret: audienceSecret,
  });

  return authGetQuery(
    {
      url,
      config: headerConfig,
    },
  );
};

const registerNewUser = ({
  newUserData,
  taxonomyRegistration
}) => {
  const url = USER_URL;

  const submissionData = {
    redirectUrl: `${getLocationOrigin()}/${getUrlCountryPath()}${CONFIRM_EMAIL}`,
    email: newUserData.email,
    username: newUserData.email,
    password: newUserData['new-password'],
    confirmPassword: newUserData['new-password'],
    hcpProviderID,
    [OPTIN_HCP]: newUserData[OPTIN_HCP], // poorly names server field, this is not an optin!
    ...getServerParametersForUserForm(newUserData),
    businessInfo: JSON.stringify(taxonomyRegistration)
  };

  warnUndefinedKeys(submissionData, 'genericApi.js');

  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience, secret: audienceSecret,
  });

  return authPostQuery({ url, data: submissionData, config: headerConfig });
};

const confirmRegisterEmail = ({
  tokenValue,
}) => {
  const url = `${CONFIRM_REGISTRATION_URL}/${tokenValue}`;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience, secret: audienceSecret,
  });

  return authPostQuery({ url, config: headerConfig });
};

const confirmDeleteEmail = ({
  tokenValue,
}) => {
  const url = `${CONFIRM_DELETE_URL}/${tokenValue}`;


  return authDeleteQuery({
    url,
    config: getAuthHeaderConfig({
      audienceUrl: audience, secret: audienceSecret,
    }),
  });
};

const deleteUser = ({
  password,
  id,
  token,
}) => {
  const url = `${DELETE_USER_URL}/${id}`;


  const params = {
    password,
    redirectUrl: `${getLocationOrigin()}/${getUrlCountryPath()}${CONFIRM_DELETE_USER_ACCOUNT}`,
  };

  warnUndefinedKeys(params, 'genericApi.js');

  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience, authToken: token,
  });

  return authPostQuery({
    url, data: params, config: headerConfig,
  });
};

const fetchUserDetails = (userId, authToken) => {
  const url = `${USER_URL}/${userId}`;
  const headerConfig = getAuthHeaderConfig({ audienceUrl: audience, authToken });

  return authGetQuery({
    url,
    config: headerConfig,
  });
};

const updateUserDetails = ({
  userId,
  userData,
  authToken,
}) => {
  const url = `${USER_URL}/${userId}`;
  const headerConfig = getAuthHeaderConfig({ audienceUrl: audience, authToken });
  return authPutQuery({ url, data: userData, config: headerConfig });
};

const forgottenPassword = (email) => {
  const url = FORGOTTEN_PASS_URL;
  const headerConfig = getAuthHeaderConfig({ audienceUrl: audience, secret: audienceSecret });

  return authPostQuery({ url, data: { emailAddress: email }, config: headerConfig });
};

const passwordReset = (
  tokenValue,
  password,
  confirmPassword,
) => {
  const url = `${RESET_PASS_URL}/${tokenValue}`;
  const headerConfig = getAuthHeaderConfig({ audienceUrl: audience, secret: audienceSecret });

  return authPostQuery({ url, data: { password, confirmPassword }, config: headerConfig });
};

const changeUserPassword = ({
  userId,
  passwordData,
  authToken,
}) => {
  const url = `${CHANGE_PASS_URL}/${userId}`;
  const headerConfig = getAuthHeaderConfig({ audienceUrl: audience, authToken });
  return authPutQuery({ url, data: passwordData, config: headerConfig });
};

const updateUserConsent = ({ userId, token, formValues }) => {

  const url = UPDATE_CONSENT_URL;

  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience, authToken: token,
  });

  const { optinHCP, informationConsent } = formValues;

  const data = {
    optInHCP: optinHCP,
    promotionalMaterialsOptIn: informationConsent,
  };

  return authPutQuery({ url: `${url}/${userId}`, data, config: headerConfig });
};

export {
  createUserToken,
  verifyUserToken,
  refreshUserToken,
  registerNewUser,
  confirmRegisterEmail,
  confirmDeleteEmail,
  deleteUser,
  fetchUserDetails,
  updateUserDetails,
  forgottenPassword,
  passwordReset,
  changeUserPassword,
  updateUserConsent,
};

