import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

const TINY_LOADER = 'tiny';
const SMALL_LOADER = 'small';
const MEDIUM_LOADER = 'medium';
const LARGE_LOADER = 'large';


const Loader = ({
  className,
  size = SMALL_LOADER,
}) => (
  <div className={`loader-component loader-component-${size} ${className ? className : ''}`} />
);


Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};


export {
  Loader,
  TINY_LOADER,
  SMALL_LOADER,
  MEDIUM_LOADER,
  LARGE_LOADER,
};
