import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import {
  LOGIN,
  REGISTER,
  CONSENT_FORM,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  errorCodesWithMessage,
} from "components/shared/form/formConstants";
import {
  KKI,
  ONEKEY,
  DOCCHECK,
  getAuthServiceAPI,
  GENERIC,
} from "api/authService";
import { SESSION_ID, removeAuthKeys } from "utils/localStorageUtils";
// import { checkAuthExists } from 'utils/authUtils';
import { hasError, loading, resetFormState } from "../form/slice";

const ANONYMOUS = "Anonymous";

const initialState = {
  status: {
    guest: false,
    guestUserId: null,
    guestUserEmail: null,
    anonymous: false,
    loggedIn: false,
    limitedAccess: false,
    bot: false,
    veevaUser: null,
    veevaUserInvalid: null,
  },
  data: {
    token: {
      token: null,
      refreshToken: null,
    },
    user: {
      userId: null,
      firstName: null,
      lastName: null,
      speciality: null,
      email: null,
      promotionalMaterialsOptIn: false,
      username: null,
      role: null,
      jobTitle: null,
      department: null,
      phone: null,
      departmentPhone: null,
      workplace: null
    },
    consent: {
      optinHCP: false,
      acceptedPromotional: false
    },
    additional: {
      login: null,
      trustLevel: null,
      professionOneKey: null,
      isHCP: false,
      tokenResponse: {},
      signUpGaEventRegistered: false,
      taxonomyRegistration: null,
    },
    extendedProfile: {
      owaValues: {},
      userValues: {},
    },
  },
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const {
        anonymous,
        userId,
        firstname: firstName,
        lastName,
        speciality: speciality,
        email: email,
        role: role,
        jobTitle,
        department,
        phone,
        workplace,
        departmentPhone,
        token,
        refreshToken,
        cegedimSecurityLevel: trustLevel,
        login,
        hcp: isHCP,
        optinHCP,
        promotionalMaterialsOptIn,
        username,
        professionOneKey,
      } = action.payload || {};

      state.status.guest = false;
      state.status.anonymous = anonymous ? true : false;
      state.status.loggedIn = token ? true : false;
      state.data.token.token = token || null;
      state.data.token.refreshToken = refreshToken || null;
      state.data.user.userId = userId || null;
      state.data.user.firstName = firstName || null;
      state.data.user.lastName = lastName || null;
      state.data.user.speciality = speciality || null;
      state.data.user.email = email || null;
      state.data.user.role = role || null;
      state.data.user.jobTitle = jobTitle || null;
      state.data.user.department = department || null;
      state.data.user.phone = phone || null;
      state.data.user.departmentPhone = departmentPhone || null;
      state.data.user.workplace = workplace || null;
      state.data.consent.optinHCP = optinHCP || false;
      state.data.additional.login = login || null;
      state.data.additional.isHCP = isHCP || false;
      state.data.additional.trustLevel = trustLevel || null;
      state.data.additional.professionOneKey = professionOneKey || null;
      state.data.user.promotionalMaterialsOptIn =
        promotionalMaterialsOptIn || false;
      state.data.user.username = username || null;
      state.data.additional.tokenResponse = action.payload || {};
    },
    setUserAsBot: (state) => {
      state.status.bot = true;
    },
    setGuestUserId: (state, action) => {
      state.status.guestUserId = action.payload || null;
    },
    setGuestUserEmail: (state, action) => {
      state.status.guestUserEmail = action.payload || null;
    },
    setVeevaUser: (state, action) => {
      state.status.veevaUser = action.payload;
    },
    setveevaUserInvalid: (state, action) => {
      state.status.veevaUserInvalid = action.payload;
    },
    setGuestUserData: (state, action) => {
      state.status.guest = action.payload;
    },
    setGaStatus: (state) => {
      state.data.additional.signUpGaEventRegistered = true;
    },
    setLimitedAccess: (state) => {
      state.status.limitedAccess = true;
    },
    setRefreshAuthData: (state, action) => {
      const { userId, token, refreshToken } = action.payload || {};

      state.data.user.userId = userId || null;
      state.data.token.token = token || null;
      state.data.token.refreshToken = refreshToken || null;
    },
    updateConsentData: (state, action) => {
      state.data.consent.optinHCP = action.payload || null;
    },
    acceptPromotionalConsent: (state, action) => {
      state.data.consent.acceptedPromotional = action.payload;
    },
    setAnonymousData: (state, action) => {
      state.status.anonymous = false;
      state.data.user.username = action.payload;
      state.data.user.firstName = action.payload;
      state.data.consent.optinHCP = true;
    },
    setExtendedProfileData: (state, action) => {
      state.data.extendedProfile.owaValues = action.payload?.owaValues || {};
      state.data.extendedProfile.userValues = action.payload?.userValues || {};
    },
    setTaxonomyRegistration: (state, action) => {
      state.data.additional.taxonomyRegistration = action.payload;
    },
    clearAllData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(REHYDRATE, (state, action) => {
        // On rehydration of user data if user already logged in or
        // user has token then set user logged in state to true else false
        if (action.key === "auth") {
          const { data } = action.payload || {};
          const { token: _token, user } = data || {};
          const { token } = _token || {};
          const { userId, firstName } = user || {};

          state.status.loggedIn = token && userId && firstName ? true : false;
          state.status.anonymous =
            token && userId && firstName === ANONYMOUS ? true : false;
        }
      })
      .addCase("user/setDetails", (state, action) => {
        const {
          firstname: firstName,
          lastname: lastName,
          email: email,
          speciality: speciality,
          promotionalMaterialsOptIn: promotionalMaterialsOptIn,
          username: username,
          role: role,
          jobTitle: jobTitle,
          department: department,
          phone: phone,
          departmentPhone: departmentPhone,
          workplace: workplace
        } = action.payload || {};
        state.data.user.firstName = firstName || null;
        state.data.user.lastName = lastName || null;
        state.data.user.speciality = speciality || null;
        state.data.user.email = email || null;
        state.data.user.promotionalMaterialsOptIn =
          promotionalMaterialsOptIn || false;
        state.data.user.username = username || null;
        state.data.user.role = role || null;
        state.data.user.jobTitle = jobTitle || null;
        state.data.user.department = department || null;
        state.data.user.phone = phone || null;
        state.data.user.departmentPhone = departmentPhone || null;
        state.data.user.workplace = workplace || null;
        
      });
  },
});

export const {
  setGaStatus,
  setGuestUserId,
  setGuestUserEmail,
  setAuthData,
  setGuestUserData,
  setRefreshAuthData,
  clearAllData,
  updateConsentData,
  acceptPromotionalConsent,
  setAnonymousData,
  setLimitedAccess,
  setUserAsBot,
  setVeevaUser,
  setExtendedProfileData,
  setveevaUserInvalid,
  setTaxonomyRegistration,
} = authSlice.actions;

export const authActions = {
  global: {
    updateAnonymousStateAuthentication: (userName) => (dispatch) => {
      dispatch(setAnonymousData(userName));
    },
    logout: ({
      text = "Are you sure you want to logout?",
      withConfirm = true,
    } = {}) => (dispatch) => {
      let confirmed = false;

      if (withConfirm) {
        // eslint-disable-next-line no-alert
        confirmed = window.confirm(text);
      }

      if (!withConfirm || confirmed) {
        dispatch(clearAllData());
      }
    },
    token: {
      validate: ({ token, refreshToken }) => (dispatch) => {
        getAuthServiceAPI()
          .token.verify({ authToken: token })
          .catch((error) => {
            const { status } = error.response || {};

            if (status === 409) {
              console.error("Error: Authentication confilct");
              dispatch(clearAllData());
              return;
            }

            getAuthServiceAPI()
              .token.refresh(token, refreshToken)
              .then((response) => {
                dispatch(setRefreshAuthData(response.data));
              })
              .catch(() => {
                dispatch(clearAllData());
              });
          });
      },
    },
    consent: {
      update: ({ formValues }) => async (dispatch, getState) => {
        dispatch(loading(true));

        const { token: _token, user } = getState().authentication.data || {};
        const { token } = _token || {};
        const { userId } = user || {};

        try {
          await getAuthServiceAPI({ service: GENERIC })
            .consent.update({ userId, token, formValues })
            .then(() => {
              dispatch(updateConsentData(true));
              dispatch(resetFormState());
            });
        } catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: CONSENT_FORM, error: e }));
          }

          throw e;
        }
      },
    },
  },
  kki: {
    register: (formValues) => async (dispatch, getState) => {
      dispatch(loading(true));
      const { additional } = getState().authentication.data || {};      

      try {
        await getAuthServiceAPI({ service: KKI })
          .user.register({ newUserData: formValues, taxonomyRegistration: additional?.taxonomyRegistration })
          .then((response) => {
            let redirectUrlString = response?.data?.redirectURL;
            if(redirectUrlString){
              let userID = redirectUrlString.split("userId=");
              if(userID && userID[1]){
                dispatch(setGuestUserId(parseInt(userID[1])));
              }
            }
            if(formValues?.email){
              dispatch(setGuestUserEmail(formValues?.email));
            }
            dispatch(resetFormState());
          });
      } catch (e) {
        if (errorCodesWithMessage.includes(e?.response?.status)) {
          dispatch(hasError({ id: REGISTER, error: e }));
        }

        throw e;
      }
    },
    login: (email, password) => async (dispatch) => {
      dispatch(loading(true));

      try {
        await getAuthServiceAPI({ service: KKI })
          .token.create(email, password)
          .then((response) => {
            const {
              userId,
              firstname,
              lastname,
              email,
              speciality,
              role,
              jobTitle,
              department,
              phone,
              departmentPhone,
              marketingConsent,
              token,
              refreshToken,
              promotionalMaterialsOptIn,
              username,
              utmValues
            } = response.data || {};

            const payload = {
              firstname,
              lastname,
              email,
              speciality,
              userId,
              token,
              refreshToken,
              optinHCP: true,
              promotionalMaterialsOptIn,
              role,
              jobTitle,
              department,
              phone,
              departmentPhone,
              marketingConsent,
              username,
              utmValues
            };

            dispatch(setAuthData(payload));
            dispatch(resetFormState());
          });
      } catch (e) {
        if (errorCodesWithMessage.includes(e?.response?.status)) {
          dispatch(hasError({ id: LOGIN, error: e }));
        }

        throw e;
      }
    },
    password: {
      forgot: (email) => async (dispatch) => {
        dispatch(loading(true));

        try {
          await getAuthServiceAPI({ service: KKI })
            .user.password.forgot(email)
            .then(() => {
              dispatch(resetFormState());
            });
        } catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: FORGOTTEN_PASSWORD, error: e }));
          }

          throw e;
        }
      },
      reset: (tokenValue, password, confirmPassword) => async (dispatch) => {
        dispatch(loading(true));

        try {
          await getAuthServiceAPI({ service: KKI })
            .user.password.reset(tokenValue, password, confirmPassword)
            .then(() => {
              dispatch(resetFormState());
            });
        } catch (e) {
          if (errorCodesWithMessage.includes(e?.response?.status)) {
            dispatch(hasError({ id: RESET_PASSWORD, error: e }));
          }

          throw e;
        }
      },
    },
    guestLogin: () => (dispatch) => {
      dispatch(setGuestUserData(true));
    },
    guestLogout: () => (dispatch) => {
      dispatch(setGuestUserData(false));
    },
  },
  oneKey: {
    login: () => async (dispatch, getState) => {
      dispatch(loading(true));

      const { additional } = getState().authentication.data || {};      

      try {
        await getAuthServiceAPI({ service: ONEKEY })
          .token.create({ taxonomyRegistration: additional?.taxonomyRegistration })
          .then((response) => {
            dispatch(setAuthData(response.data));
            dispatch(resetFormState());
            removeAuthKeys({ key: SESSION_ID });
            return false;
          });
      } catch (e) {
        console.error(e);

        throw e;
      }
    },
    extendedUserProfile: (id, token) => async (dispatch) => {
      dispatch(loading(true));
      try {
        await getAuthServiceAPI({ service: ONEKEY })
          .callExtendedUserProfile.extendedUserProfile(id, token)
          .then((response) => {
            dispatch(setExtendedProfileData(response.data));
            dispatch(resetFormState());
            removeAuthKeys({ key: SESSION_ID });
            return false;
          });
      } catch (e) {
        console.error(e);

        throw e;
      }
    },
  },
  docCheck: {
    login: ({ authCode, doccheckuserconsent }) => async (dispatch, getState) => {
      dispatch(loading(true));

      const { additional } = getState().authentication.data || {};      

      try {
        await getAuthServiceAPI({ service: DOCCHECK })
          .token.create({ authCode, doccheckuserconsent, taxonomyRegistration: additional?.taxonomyRegistration })
          .then((response) => {
            dispatch(setAuthData(response.data));
            dispatch(resetFormState());
            return false;
          });
      } catch (e) {
        console.error(e);

        throw e;
      }
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default authSlice.reducer;
