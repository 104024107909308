import React, { useRef, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import parse from 'html-react-parser'; 
//Components
import InfoSection from './infoSection';

import SectionHeader from './SectionHeader';
import { toggleOverlay } from './helper';
import References from './references';
import ReactHtmlParser from 'react-html-parser';
import { closePopup } from './helper';
import Accordion from './accordion'; 

//Images
import graphProgression from '../../../../assets/images/infographics-assets/clinical-symptoms-graph.png';
import close from '../../../../assets/images/infographics-assets/circle-close-icon.svg';
import signs from '../../../../assets/images/infographics-assets/graph-radio-signs.png';

import './MDTSection.scss'
import './checkmark.scss';
import './tooltips.scss';
import InfoCard from './InfoCard';
import IconLink from './IconLink';
import CircleCallOut from './CircleCallOut';
import CalloutBullets from './CalloutBullets/CalloutBullets';
import IconText from './IconText';
import CalloutBulletsImage from './CalloutBullets/CalloutBulletsImage';
import InfographicsDiagram from './InfographicsDiagram/InfographicsDiagram';
import InfographicsDiagramdopaminergic from './InfographicsDiagram/InfographicsDiagramdopaminergic';
import CallOut from './CallOut/CallOut';
import { element } from 'prop-types';
import {
	Module_Column_Container,
	Module_Accordion,
	Module_Text_Block,
	Module_Centred_Icon,
	Component_Aligned_Icon,
	Module_Infographic,
	Module_Compound_Infographic,
	Module_Image_Block,
	Module_CallOut
} from '../../../../api/acousticApi/ContentTypesInfographics';
import ImageBlock from './ImageBlock';
import ColumnSection from './ColumnSection';
import { AppConfig } from 'AppConfig';
import { BannerImageBackground } from 'components/content/eapc/BannerImageBackground';
import './InfographicsSection.scss';

const InfographicsSection = (props) => {
   
    const {
        sectionId,
        backgroundColor,
        sectionTitle,
        infographicsDetail,
        accordionPicker,
        informationComponentPicker,
        accordionColor,
        iconPicker,
        backgroundHexColour,
        backgroundImage,
        ctaLabel,
        hideSecondaryContainer,
        infoContainerList,
        secondaryInfoContainerList,
        circleCalloutsPicker,
        iconViewsPicker,
        accordionTimedPopups,
        imageInfoGraphicsPicker,
		components,
		sectionTextFontColour,
        fontColour,
		displayMode
    } = props?.element?.elements;
	let typeId = "";
	if(components?.values){
		typeId = components?.values[0]?.typeId;
	}

    const [isOpenRef, setIsOpenRef] = useState(false);
    const openRef = () =>{
        return setIsOpenRef(!isOpenRef)
    }
    useEffect(() => {
        if(isOpenRef=== true){
            setIsOpenRef(false)
        }
		let allToolTipContent = document.querySelectorAll(".tool-tip__content");
        [...allToolTipContent].map((ele) =>{
            if (ele.offsetHeight >= 165) {
                ele.classList.add("activeScrollBar");
            }
        });
    },[]);

    let toolTipBtn = useRef(null);

    let infoTextOne = "Parkinson's disease is a heterogeneous condition and, while patterns in its course may be evident at a population-wide level, each patient is unique in how Parkinson's disease progresses and impacts their life.<sup>3</sup>",
        accordionTile1 = "Treatment strategies for motor and non-motor symptoms evolve as the disease progresses",
        accordionTile2 = "As Parkinson's disease progresses, patients may experience fluctuations in response to dopaminergic therapy",
        accordionTile3 = "In early PD, symptoms develop in people under the age of 50 years",
        accordionTile4 = "Treatment-related motor complications are more likely to occur on early-onset PD than late-onset PD",
        accordionTile5 = "Early-onset PD significantly reduces QoL in patients compared with late-onset PD";

    
    const symptomsArray_1 = ['constipation', 'eds', 'rdb', 'hyposmia'];
    
    const [timeCourseActive, setTimeCourse] = useState('');     //Time course
    const [captionList, setCaptionList] = useState('');

    const showTimeCourse = (event, status) => {

        if(status === 'time_course'){
            //Show Highlighted Time course : Ex 1, 2 ... 7
            setTimeCourse('active');
            setCaptionList('');
        } else {
            //Remove Highlighted Time course
            setTimeCourse('');
            setCaptionList(status);
        }
    }

    const showTabHandler = (ev, tabID) => {
        let event = ev.target || ev.srcElement;

        if(event.checked){
            document.getElementById(tabID).click();
            document.getElementById(tabID).scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
    }

    const showTabPopupHandler = (event,tabID) => {
        const tabSel = document.getElementById(tabID);

        closePopup(event);

        setTimeout(() => {
            if(tabSel.classList.contains('open')){
                tabSel.scrollIntoView({ behavior: 'smooth', block: 'start'});
            } else {
                tabSel.click();
                tabSel.scrollIntoView({ behavior: 'smooth', block: 'start'});
            }
        },800);
    }

    const openReferenceOnTooltips = (event) => {
        openRef();
        ReactTooltip.hide(toolTipBtn);
        toggleOverlay('hide');

        let eventTarget = event.target.parentNode.parentNode.parentNode.parentNode;
        eventTarget.classList.remove('show');
    }

    const disableTimePopUp = (isDisable) => {
        props.onDisableTimePopUp(isDisable)
    }

	const url = AppConfig.acousticApi.domain + backgroundImage?.url

	const imgStyleBackground={ 
		backgroundImage: "url(" + url + ")",
		backgroundRepeat: "no-repeat",
		order:"1"
	}

	if(displayMode?.value?.selection === 'fit-to-content') {
		return (
			<>
			  <section id={sectionId?.value ?? ''} className={`mdt-main-section`}>
			  	<div className="container">
					<div className='container_inner'>
						<section className='content_wrapper content_wrapper--columns-2-left'></section>

						<section id={sectionId?.value ?? ''} className={`mdt-main-section content_wrapper content_wrapper--columns-2-center`}>
                    <div className="mdt-main" style={{ backgroundColor: backgroundColor?.value , color: sectionTextFontColour?.value ?? fontColour?.value ?? '' }} id="main">
						<div style={imgStyleBackground ? imgStyleBackground : null} className="imageGirl">
						<div className="container js-scroll-target" data-popup-target="accordion-popup-test">
							<References 
									title={ReactHtmlParser(sectionTitle?.value)}
									subtitle={ReactHtmlParser(infographicsDetail?.value?.subtitle?.value)}
									sup2=""
									type="mdtSection"
									textColor= "text--blue"
									backgroundColor= "reference-orange"
									isOpenRef={isOpenRef}
									setIsOpenRef={setIsOpenRef}
							/>
							{
								(sectionTitle?.value || infographicsDetail?.value?.subtitle) && <SectionHeader title={sectionTitle} subtitle={infographicsDetail?.value?.subtitle} />
							}	
							{components && components?.values?.map((elements, index) => (
								((components?.values[index]?.typeId) === Module_Infographic ? 
									<InfographicsDiagramdopaminergic
									element={elements?.elements}
									elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
									image= {elements?.elements?.imageMap?.value?.imagePicker}
									areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
									id={components?.values[index].id}
									timedPopup = {elements?.elements?.timedPopup?.value} 
									/> :
								components?.values[index]?.typeId === Module_Accordion ?
									<Accordion 
										key={index}
										element={elements}
										color={accordionColor} 
										showTimePopUp={props.showTimePopUp} 
										onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)} 
										accordionId="treatment-tab" 
										function={openRef} title={accordionTile1} 
										theme="element-1"
										accordionTimedPopups={elements?.elements?.timedPopup?.value} 
									/>
									: components?.values[index]?.typeId === Module_CallOut  ?
										<div className="container">
											<CallOut calloutContent={elements} />
										</div> 
								: components?.values[index]?.typeId === Module_Compound_Infographic  ?
									<InfographicsDiagram
									elements={elements?.elements}
									onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)} 
									infographicsName={elements?.name}
									areas= {elements?.elements?.defaultImageMap?.value?.areaSpecificContents?.values}
									/>:
								components?.values[index]?.typeId === Module_Image_Block  ?
									<div className="container">
										<ImageBlock
											elements={elements}
											size="large"
										/>
									</div> :	
								components?.values[index]?.typeId === Module_Column_Container  ?
									<div>
											<ColumnSection
												elements={elements}
											/>
									</div> :
								components?.values[index]?.typeId === Module_Centred_Icon  ?
								<div className="icon-center">
									<IconText key={index} iconTextContent={elements} pid={`iconviews${index}`} />
								</div>:
								components?.values[index]?.typeId === Module_Text_Block  ?
									<div className="callout_Bullet_Image" >
										<CalloutBullets
											element={elements}
											index={index++}
										/> 
									</div>:null)
							))}
						</div>
						</div>
          </div>
        </section>

						<div className='content_wrapper content_wrapper--columns-2-right'></div>
					</div>
				</div>
			  </section>
			</>
		  );
	}

    return (
      <>
        <section id={sectionId?.value ?? ''} className={`mdt-main-section`}>
                    <div className="mdt-main" style={{ backgroundColor: backgroundColor?.value , color: sectionTextFontColour?.value ?? fontColour?.value ?? '' }} id="main">
						<div style={imgStyleBackground ? imgStyleBackground : null} className="imageGirl">
						<div className="container js-scroll-target" data-popup-target="accordion-popup-test">
							<References 
									title={ReactHtmlParser(sectionTitle?.value)}
									subtitle={ReactHtmlParser(infographicsDetail?.value?.subtitle?.value)}
									sup2=""
									type="mdtSection"
									textColor= "text--blue"
									backgroundColor= "reference-orange"
									isOpenRef={isOpenRef}
									setIsOpenRef={setIsOpenRef}
							/>
							{
								(sectionTitle?.value || infographicsDetail?.value?.subtitle) && <SectionHeader title={sectionTitle} subtitle={infographicsDetail?.value?.subtitle} />
							}	
							{components && components?.values?.map((elements, index) => (
								((components?.values[index]?.typeId) === Module_Infographic ? 
									<InfographicsDiagramdopaminergic
									element={elements?.elements}
									elements={elements?.elements?.imageMap?.value?.imageMapWithCoordinates?.value}
									image= {elements?.elements?.imageMap?.value?.imagePicker}
									areas= {elements?.elements?.imageMap?.value?.areaSpecificContents}
									id={components?.values[index].id}
									timedPopup = {elements?.elements?.timedPopup?.value} 
									/> :
								components?.values[index]?.typeId === Module_Accordion ?
									<Accordion 
										key={index}
										element={elements}
										color={accordionColor} 
										showTimePopUp={props.showTimePopUp} 
										onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)} 
										accordionId="treatment-tab" 
										function={openRef} title={accordionTile1} 
										theme="element-1"
										accordionTimedPopups={elements?.elements?.timedPopup?.value} 
									/>
									: components?.values[index]?.typeId === Module_CallOut  ?
										<div className="container">
											<CallOut calloutContent={elements} />
										</div> 
								: components?.values[index]?.typeId === Module_Compound_Infographic  ?
									<InfographicsDiagram
									elements={elements?.elements}
									onDisableTimePopUp={isEnable => disableTimePopUp(isEnable)} 
									infographicsName={elements?.name}
									areas= {elements?.elements?.defaultImageMap?.value?.areaSpecificContents?.values}
									/>:
								components?.values[index]?.typeId === Module_Image_Block  ?
									<div className="container">
										<ImageBlock
											elements={elements}
											size="large"
										/>
									</div> :	
								components?.values[index]?.typeId === Module_Column_Container  ?
									<div>
											<ColumnSection
												elements={elements}
											/>
									</div> :
								components?.values[index]?.typeId === Module_Centred_Icon  ?
								<div className="icon-center">
									<IconText key={index} iconTextContent={elements} pid={`iconviews${index}`} />
								</div>:
								components?.values[index]?.typeId === Module_Text_Block  ?
									<div className="callout_Bullet_Image" >
										<CalloutBullets
											element={elements}
											index={index++}
										/> 
									</div>:null)
							))}
						</div>
						</div>
          </div>
        </section>
      </>
    );
}

export default InfographicsSection;