import React from 'react'

import { AppConfig } from 'AppConfig';


const Image = (props) => {
    let altText = "";
    if(props?.data?.asset?.altText){
        altText = props?.data?.asset?.altText; 
    } else if (props?.alt) {
        altText = props?.alt; 
    }
    const {data, ...rest} = props
    return (data) ? <img {...rest} src={AppConfig.acousticApi.domain + data.url} alt={altText} /> : null
}

export default Image
