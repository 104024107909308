import React from "react";
import styles from "./RadioButton.module.scss";

/**
 * RadioButton Component
 * @param {string} name - it's the name of the radio button - required
 * @param {string} value - it's the value of the radio button - required
 * @param {string} label - it's the label of the radio button - required
 * @param {string} assistiveText - it's the assistive text of the radio button - optional
 * @param {string} disabled - wether the radio button is disabled or not - optional
 * @param {string} error - it's the error message - optional
 * @param {Object} field - field object contains the value of the radio button and this parameter comes from Formik - optional
 * @return React component
 */

const RadioButton = (props) => {
  const {
    name,
    value,
    label,
    assistiveText,
    disabled,
    error,
    field,
  } = props;

  return (
    <label
      className={`${styles["form-radio"]} ${
        error && !disabled ? styles["form-radio--error"] : ""
      } ${disabled ? styles["form-radio--disabled"] : ""}`}
    >
      <input
        className={`${styles["form-radio__input"]} ${styles["input--hidden"]}`}
        name={name}
        type="radio"
        value={value}
        id={name}
        disabled={disabled}
        {...field}
      />
      <div className={styles["form-radio__button"]}></div>
      <div className={styles["form-radio__texts"]}>
        <p className={styles["form-radio__texts__title"]}>{label}</p>
        {assistiveText && (
          <p className={styles["form-radio__texts__assistive"]}>
            {assistiveText}
          </p>
        )}
      </div>
    </label>
  );
};

export default RadioButton;
