import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';
import { InlineError } from 'components/shared/form/InlineError';
import './Input.scss';
import './RadioButton.scss';

const RadioButtonEAPC = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
  requiredLabel
}) => {
  const {
    label,
    id,
    name,
    typeParams,
    mandatory,
    size = 100,
    reverseLabel = false,
  } = field;

  const {
    type,
    // stuff that html likes. Used for stuff like remembering password and username ect
    autoComplete,
    placeholder,
    readOnly,
  } = typeParams;
  const touched = touchedFields[id];
  const inlineError = inlineErrors[id];
  const viewableError = InlineError && touched;

  const onCheck = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const setTouched = (value) =>
    setTouchedFields((prevState) => ({
      ...prevState,
      [id]: value,
    }));

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];

  if (readOnly) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-read-only`);
  }

  const checkValue = (value) => {
    setIsChecked(value);
    onCheck(value);
    setTouched(value);
  };

  const [checkedValue, setIsChecked] = useState();
  return (
    <div className={fieldClassNames.join(' ')}>
      <div className='field-meta-data radio-btn'>
        {label && (
          <label htmlFor={id}>
            {!reverseLabel && label}
            {mandatory && <span className='mandatory-astrix'>*</span>}
            {reverseLabel && label}
          </label>
        )}
        {viewableError && inlineError && <InlineError inlineErrorText={requiredLabel || inlineError} />}
      </div>
      <div className='radio-button-section'>
        <div className='input-radio-section'>
          {field?.options?.map((radio, index) => {
            return (
              <div
                key={index}
                className={`options${
                  radio?.elements?.optionValue?.value === checkedValue
                    ? ' checked'
                    : ''
                }`}
                onClick={() => {
                  checkValue(radio?.elements?.optionValue?.value);
                }}
              >
                <div tabindex="0" className='round-div'>
                  <div className='background-div'></div>
                </div>
                <div className='radio-option-label'>
                  {radio?.elements?.optionText?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

RadioButtonEAPC.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
  formValues: formValuesPropTypes,
  setFormValues: PropTypes.func,
  inlineErrors: inlineErrorsPropTypes,
  touchedFields: touchedPropTypes,
  setTouchedFields: PropTypes.func,
};

export { RadioButtonEAPC };