import styles from './Quiz.module.scss'

import EditorialTextAcousticData from '../../atoms/editorialText/EditorialTextAcousticData'
import ImageAcousticData from 'components/kanso/atoms/image/ImageAcousticData'
import VideoAcousticData from 'components/kanso/atoms/video/VideoAcousticData'

const QuizQuestionText = (props) => {  
  const { 
    caption,
    current,
    total,
    text,
    questionCounterPlaceholder,
    embedded
  } = props

  const questionHeader = () => {
    let acousticText = questionCounterPlaceholder;
    let currentCount = acousticText.replace("{current}", current);
    let finalCount = currentCount.replace("{total}", total);

    return finalCount
  }

  return (
    <div className={styles['quiz-question-text']}>
      <p className={styles['quiz-question-counter']}>{questionHeader()}</p>
      {caption && (
        <p className={styles['quiz-question-caption']}>{caption}</p>
      )}
      {text?.map((content, index) => {
        if(content.type === "Kanso-Atom-EditorialText") {
          let data = content?.elements

          if(embedded) {
            data = {
              ...data,
              caption: null
            }
          }
          return (
            <EditorialTextAcousticData key={index} data={data} />
          )
        }
        if(content.type === "Kanso-Atom-Image") {
          return (
            <ImageAcousticData key={index} data={content?.elements} />
          )
        }
        if(content.type === "Kanso-Atom-Video") {
          return (
            <VideoAcousticData key={index} data={content?.elements} />
          )
        }
      })}            
    </div>
  )
}

export default QuizQuestionText