import { useEffect, useRef } from 'react';
import styles from './Quiz.module.scss';
import Button from 'components/kanso/atoms/button/Button';
import { CheckCircle, CircleIndicator } from 'assets/kanso/svgIcons/SvgIcons';
import { getValue } from 'utils/utilityHelper';

/**
 * QuizProgress Component
 * @param {string} progress - The progress of the quiz
 * @returns React Component
 */

const QuizProgress = (props) => {
  const { progress, total, questions } = props;

  const progressRef = useRef(null);

  const updateProgress = () => {
    const indicatorEl = document.getElementById(`progress-step-${progress}`);
    // const rect = indicatorEl.getBoundingClientRect();

    progressRef.current.style.setProperty(
      '--qt-header-progress-width',
      `${indicatorEl.offsetLeft + 30}px`
    );
  };

  useEffect(() => {
    // progressRef.current.style.setProperty(
    //   '--qt-header-progress-width',
    //   `${progress}`
    // );

    updateProgress();
  }, [progress]);

  useEffect(() => {
    const handleResize = () => {
      updateProgress();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles['quiz-progress']}>
      <div
        className={styles['quiz-progress--completed']}
        ref={progressRef}
      ></div>
      <div className={styles['quiz-progress-indications']}>
        {questions.map((question, k) => {
          const category = getValue(question.elements.category)
          return (
            <div
              key={`progress-step-${k}`}
              id={`progress-step-${k + 1}`}
              className={styles['progress-step']}
            >
              {k + 1 >= progress ? <CircleIndicator /> : <CheckCircle />}
              {category}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuizProgress;
