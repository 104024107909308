import styles from './Quiz.module.scss';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setStartQuiz,
  setQuestionClosed,
  setQuizId,
  setResult,
} from '../../ducks/quizSlice';
import { getValue, getValues } from '../../../../utils/utilityHelper';

import QuizHeader from './QuizHeader';
import QuizProgress from './QuizProgress';
import QuizQuestion from './QuizQuestion';
import QuizQuestionText from './QuizQuestionText';
import QuizQuestionAnswer from './QuizQuestionAnswer';
import QuizFooter from './QuizFooter';
import Button from '../../atoms/button/Button';
import { getUrlCountryPath } from 'utils/urlUtils';

import TNMBMapping from './TNMB-result-mapping.json'
import QuizResult from './QuizResult';
import { clearQuizData, getSelectedOptions } from './AnswerStorage';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics';

const quizLogics = {
  'tnmb': {
    trackStartKey: 'tnmb_calculator_start',
    trackCompleteKey: 'tnmb_calculator_complete',
    trackResultKey: 'tnmb_result',
    trackViewKey: 'tnmb_calculator_view',
    getResult:  (quizIdValue, currentLanguage) => {
      const selectedAnswers = JSON.parse(localStorage.getItem(quizIdValue + '-' + currentLanguage + '-selected'))
      let result = null;
      TNMBMapping.forEach((resultMap) => {
        const patternSplit = resultMap.pattern.split("|")
        let matched = true

        patternSplit.forEach((value, index) => {
          const isAnyIncluded = Object.values(selectedAnswers).some((answer) => value.split(",").includes(answer.trim()))
          if(!isAnyIncluded) {
            matched = false;
            return;
          }
        })
        if(matched) {
          result = resultMap.value
          return;
        }
      })
  
      return result
    }
  }
}

/**
 * Quiz Component
 * @param {string} logo           - The logo image to be displayed
 * @param {string} logoAltText    - The logo alt text to be displayed
 * @param {string} exitButtonText - The exit button text to be displayed
 * @param {string} questions      - The questions to be displayed
 * @param {string} backButtonText - The back button text to be displayed
 * @param {string} nextButtonText - The next button text to be displayed
 * @returns React Component
 */

const Quiz = (props) => {
  const {
    quizId,
    logo,
    logoAltText,
    exitButtonText,
    caption,
    questions,
    restartButtonText,
    backButtonText,
    nextButtonText,
    submitButtonText,
    results,
    questionCountPlaceholder,
    embedded,
    resultContent,
    taxonomy
  } = props;

  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;

  const { trackQuizAction } = useGoogleAnalytics();

  const [submitted, setSubmitted] = useState(false);
  const contentRef = useRef(null);
  const quizIdValue = getValue(quizId);
  const [currentQuestion, setCurrentQuestion] = useState(
    useSelector((state) => state?.quiz?.quizData?.questionClosed)
  );
  const dispatch = useDispatch();
  const currentLanguage = getUrlCountryPath();
  const result = useSelector((state) => state?.quiz?.quizData?.result)
  
  const [progress, setProgress] = useState(
    (currentQuestion / questions?.length) * 100 + '%'
  );

  const [showError, setShowError] = useState(false);

  const getDefaultValue = (currentQuestion) => {
    if (
      localStorage.getItem(
        quizIdValue + '-' + currentLanguage + '-' + currentQuestion
      )
    ) {
      return JSON.parse(
        localStorage.getItem(
          quizIdValue + '-' + currentLanguage + '-' + currentQuestion
        )
      );
    }
  };

  const defaultValue = getDefaultValue(currentQuestion)

  const [btnIsDisabled, setBtnIsDisabled] = useState(!defaultValue ? true : false);

  // const computeFinalResult = () => {
  //   const selectedAnswers = JSON.parse(localStorage.getItem(quizIdValue + '-' + currentLanguage + '-selected'))
  //   let result = null;
  //   TNMBMapping.forEach((resultMap) => {
  //     const patternSplit = resultMap.pattern.split("|")
  //     let matched = true
  //     patternSplit.forEach((value, index) => {
  //       if(!value.split(",").includes(selectedAnswers[index+1])) {
  //         matched = false;
  //         return;
  //       }
  //     })
  //     if(matched) {
  //       result = resultMap.value
  //       return;
  //     }
  //   })

  //   return result
  // }

  useEffect(() => {
    trackQuizAction(userId, quizLogics[quizIdValue]?.trackViewKey, taxonomy)
  }, [quizIdValue])

  useEffect(() => {
    dispatch(setQuizId(quizIdValue));
    localStorage.setItem(
      quizIdValue + '-' + currentLanguage + '-data',
      JSON.stringify(questions)
    );
    localStorage.setItem('cqid', JSON.stringify(quizIdValue));
  }, [questions]);

  useEffect(() => {
    contentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [currentQuestion, submitted]);

  useEffect(() => {
    setBtnIsDisabled(!defaultValue ? true : false)
  }, [defaultValue])

  useEffect(() => {
    if(submitted) {
      const quizResult = quizLogics[quizIdValue]?.getResult(quizIdValue, currentLanguage)
      const { trackCompleteKey, trackResultKey } = quizLogics[quizIdValue]
      dispatch(setResult(quizResult))
      // trackQuizAction(userId, trackCompleteKey, { [trackResultKey]: quizResult, ...taxonomy })
      trackQuizAction(userId, [trackCompleteKey, quizResult].join('_'), { ...taxonomy })
    }
  }, [submitted])

  const exitAction = () => {
    dispatch(setStartQuiz(false));
  };

  const validateQuestion = () => {
    if (questions[currentQuestion - 1]?.elements?.isRequired?.value) {
      if (!defaultValue) {
        setShowError(true);
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      } else {
        nextQuestion();
      }
    } else {
      nextQuestion();
    }
  };

  const restartQuiz = (e) => {
    e?.preventDefault()
    clearQuizData(quizIdValue, currentLanguage)
    setBtnIsDisabled(false)
    setCurrentQuestion(1)
    setSubmitted(false)
    dispatch(setQuestionClosed(1))
  }

  const nextQuestion = (e) => {
    e?.preventDefault();
    setShowError(false);
    if (currentQuestion === questions?.length) {
      setBtnIsDisabled(true);
      // router.push(getValue(results?.elements?.pageSlug)).then(() => {
      //   dispatch(setStartQuiz(false))
      // })
      setSubmitted(true)
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setProgress(((currentQuestion + 1) / questions?.length) * 100 + '%');
      dispatch(setQuestionClosed(currentQuestion + 1));
      if(currentQuestion === 1) { 
        trackQuizAction(userId, quizLogics[quizIdValue]?.trackStartKey, taxonomy) 
      }
    }
  };

  const previousQuestion = (e) => {
    e?.preventDefault();
    setShowError(false);
    setCurrentQuestion(currentQuestion - 1);
    setProgress(((currentQuestion - 1) / questions?.length) * 100 + '%');
    dispatch(setQuestionClosed(currentQuestion - 1));
  };

  const storeValues = (name, value) => {
    if (Object.keys(value).length > 0) {
      setBtnIsDisabled(false)
      localStorage.setItem(
        quizIdValue + '-' + currentLanguage + '-' + name,
        JSON.stringify(value)
      );
      const storedKey = quizIdValue + '-' + currentLanguage + '-selected'
      const storedValue = JSON.parse(localStorage.getItem(storedKey))
      const answerIndex = parseInt(Object.keys(value)[0])
      const answerValue = getValue(getValues(questions[name-1]?.elements?.questionAnswers)[answerIndex]?.elements?.value)
      localStorage.setItem(
        storedKey,
        JSON.stringify({
          ...storedValue,
          [name]: answerValue
        })
      )
    }
  };

  if (embedded) {
    if(submitted) {
      return <div className={`${styles['quiz-embedded']} ${styles['quiz-submitted']}`}>
        <div className={styles['quiz-content']}>
          <span ref={contentRef}></span>
          <QuizResult 
            result={result} 
            restartButtonText={restartButtonText} 
            restartQuiz={restartQuiz} 
            content={resultContent.elements} 
            selectedOptions={getSelectedOptions(quizIdValue, currentLanguage)}
          />
        </div>
      </div>
    }

    return (
      <div className={styles['quiz-embedded']}>
        <QuizHeader>
          <QuizProgress questions={questions} progress={currentQuestion} total={questions?.length ?? 0} />
        </QuizHeader>
        <div className={styles['quiz-content']}>
          <span ref={contentRef}></span>
          {showError && (
            <div className={styles['quiz-question-error-wrapper']}>
              <div className={styles['quiz-question-error']}>
                <div className={styles['quiz-question-error-container']}>
                  <p>
                    {getValue(
                      questions[currentQuestion - 1]?.elements?.validationMessage
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          {questions.map((content, index) => {
            if (index === currentQuestion - 1) {
              const text = getValues(content?.elements?.questionText);
              const reference =
                text?.length > 0
                  ? getValue(text[0]?.elements?.caption)
                  : null;

              return (
                <QuizQuestion id={currentQuestion} key={index}>
                  <QuizQuestionText
                    // caption={caption}
                    current={currentQuestion}
                    total={questions.length}
                    questionCounterPlaceholder={questionCountPlaceholder}
                    text={text}
                    embedded={embedded}
                  />
                  <QuizQuestionAnswer
                    quizId={quizIdValue}
                    locale={currentLanguage}
                    current={currentQuestion}
                    answers={getValues(content?.elements?.questionAnswers)}
                    selectedValue={storeValues}
                    defaultValue={defaultValue}
                  />
                  {reference && (
                    <div className={styles['quiz-reference']}>{reference}</div>
                  )}
                </QuizQuestion>
              );
            }
          })}
        </div>
        <QuizFooter>
          <Button
            type='primary'
            text={backButtonText}
            link=''
            onClick={previousQuestion}
            isDisabled={parseInt(currentQuestion) === 1}
          />
          <Button
            type='primary'
            text={
              parseInt(currentQuestion) === questions.length
                ? submitButtonText
                : nextButtonText
            }
            icon='chevronRight'
            iconAlign='right'
            link=''
            onClick={validateQuestion}
            isDisabled={btnIsDisabled}
          />
        </QuizFooter>
      </div>
    );
  }

  return (
    <div className={styles['quiz']}>
      <QuizHeader
        logo={true}
        // logoAltText={logoAltText}
        exitButtonText={exitButtonText}
        exitAction={exitAction}
      >
        <QuizProgress progress={currentQuestion} total={questions.length} />
      </QuizHeader>

      <div className={styles['quiz-content']}>
        <span ref={contentRef}></span>
        {showError && (
          <div className={styles['quiz-question-error-wrapper']}>
            <div className={styles['quiz-question-error']}>
              <div className={styles['quiz-question-error-container']}>
                <p>
                  {getValue(
                    questions[currentQuestion - 1]?.elements?.validationMessage
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        {questions.map((content, index) => {
          if (index === currentQuestion - 1) {
            return (
              <QuizQuestion id={currentQuestion} key={index}>
                <QuizQuestionText
                  caption={caption}
                  current={currentQuestion}
                  total={questions?.length}
                  questionCounterPlaceholder={questionCountPlaceholder}
                  text={getValues(content?.elements?.questionText)}
                />
                <QuizQuestionAnswer
                  quizId={quizIdValue}
                  locale={currentLanguage}
                  current={currentQuestion}
                  answers={getValues(content?.elements?.questionAnswers)}
                  selectedValue={storeValues}
                  defaultValue={defaultValue}
                />
              </QuizQuestion>
            );
          }
        })}
      </div>
      <QuizFooter>
        <Button
          type='primary'
          text={backButtonText}
          link=''
          onClick={previousQuestion}
          isDisabled={parseInt(currentQuestion) === 1}
        />
        <Button
          type='primary'
          text={
            parseInt(currentQuestion) === questions?.length
              ? submitButtonText
              : nextButtonText
          }
          link=''
          icon='chevronRight'
          onClick={validateQuestion}
          isDisabled={btnIsDisabled}
        />
      </QuizFooter>
    </div>
  );
};

export default Quiz;
