import React,{useEffect,useState} from 'react';

import './CalloutBullets.scss';
import parse from 'react-html-parser';

const CalloutBulletsSmallBlock = (props) => {
    const {
			backgroundColor,
			displayWidth,
			infoText,
			fontColour,
			referenceId,
			isVisible
    } = props?.element.elements
	let visible = isVisible?.value;
	let reference = referenceId?.value ;	
	let index= props?.index +1

		let backgroundColorValue = backgroundColor?.value 
		if((backgroundColorValue?.indexOf("#") == -1)){
			backgroundColorValue = "#"+backgroundColor?.value 
	
		}
		let foregroundColorValue = fontColour?.value 
		if((foregroundColorValue?.indexOf("#") == -1)){
			foregroundColorValue = "#"+fontColour?.value 
	
		}
		const displayWidthValue= displayWidth?.value?.selection
		const infoTextValue = infoText?.value

		let ul = new DOMParser().parseFromString(infoTextValue, 'text/html').body;

		let arrayUlThreeInColumn = new Array();
		let arrayUlTwoInColumn = new Array();
		let arrayUlOneInColumn =new Array(); 
			for(let i = 0; i<ul.childNodes.length ;i++){
				{for(let li of ul.childNodes[i].childNodes){
					arrayUlThreeInColumn.push(li)
				}}
			}

    return (
		<div id ={reference} className={visible === true? "show" : "hide"}>
		<div id="calloutBullets">
			 <div id="mdt-section-members" className="mdt-section-members mdt-main-section " style={{color:foregroundColorValue}}>
        		<div className="mdt-section-members-content__wrapper" style={{ backgroundColor: backgroundColorValue }}>
            <div className="row one-column" style={{ backgroundColor: backgroundColorValue}}>
            {arrayUlThreeInColumn?.map((elements, index) => (
                        <div className="mdt-section-members-content__item" style={{width:"100%"}}>{elements.outerHTML === undefined?elements.textContent:parse(elements.outerHTML)}</div>
                ))}
            </div> 
        	</div>
    		</div> 
		</div> 
		</div>
    )
}

export default CalloutBulletsSmallBlock;