import React from 'react';
import styles from './Quiz.module.scss';
import Button from 'components/kanso/atoms/button/Button';
import RichText from 'components/reusableComponents/richText/RichText';
import { getValue } from 'utils/utilityHelper';
import ButtonAcousticData from 'components/kanso/atoms/button/ButtonAcousticData';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const QuizResult = (props) => {
  const { restartButtonText, restartQuiz, result, content, selectedOptions } = props;
  const { resultText, supportLink, defaultMessage } = content || {};
  let text = result ? getValue(resultText)?.replaceAll('{result}', result) : getValue(defaultMessage);
    
  const getSelectedOptionsText = () => {
    return Object.keys(selectedOptions).map(key => selectedOptions[key]).join(',')
  }

  text = text?.replaceAll('{selected}', getSelectedOptionsText())

  return (
    <div className={styles['quiz-result']}>
      <div className={styles['quiz-result-content']}>
        <RichText
          className={styles['quiz-result-content-text']}
          data={{ value: text }}
        />
        <ButtonAcousticData
          data={getValue(supportLink)?.elements}
        />
      </div>

      <div>
        <Button
          type='primary'
          text={restartButtonText}
          link=''
          onClick={restartQuiz}
        />
      </div>
    </div>
  );
};

export default QuizResult;
