import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { confirmDeleteEmail } from 'api/genericApi';
import { GenericMessagePage } from 'components/pageTemplates/GenericMessagePage';
import {
  Loader,
  LARGE_LOADER,
} from 'components/shared/Loader';

import {
  CONFIRM_DELETE_USER_ACCOUNT_FAILURE,
  CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH,
} from 'routes/routeConstants';


const ConfirmDeleteAccountPage = () => {
  const { tDeletingAccount, tPleaseStayOnDelete } = useWebsiteConfigObject();

  const params = useParams();
  const history = useHistory();

  const {
    tokenValue,
  } = params;

  useEffect(() => {
    confirmDeleteEmail({ tokenValue }).then(() => {
      history.push(CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH);
    })
    .catch((error) => {
      const responseCode = error.response.status;
      history.push(`${CONFIRM_DELETE_USER_ACCOUNT_FAILURE}/${responseCode}`);
    });
  }, [history, tokenValue])

  return (
    <GenericMessagePage
      titleText={tDeletingAccount}
      headerText={tPleaseStayOnDelete}
    >
      <Loader
        className="center-aligned-loader"
        size={LARGE_LOADER}
      />
    </GenericMessagePage>
  )
};

export { ConfirmDeleteAccountPage };
