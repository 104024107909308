import React from 'react';

import Image from 'components/reusableComponents/image/Image';

import './EapcSpeaker.scss';

const EapcSpeaker = ({ speaker }) => {
  const {
    speakerAvatar,
    speakerName,
    speakerWorkplace,
    webinarsKeynoteSpeakersLabel,
  } = speaker?.elements;
  return (
    <div className='key-speaker'>
      <div className='key-speaker__image'><Image data={speakerAvatar} /></div>
      <div className='key-speaker__text'>
        {webinarsKeynoteSpeakersLabel?.value}
        {webinarsKeynoteSpeakersLabel?.value && <span>&nbsp;</span>}
        {speakerName?.value}
        {speakerName?.value && <span>&nbsp;</span>}
        {speakerWorkplace?.value}
        {speakerWorkplace?.value && <span>&nbsp;</span>}
      </div>
    </div>
  );
};

export default EapcSpeaker;
