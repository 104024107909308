import React, { forwardRef } from 'react';
import { Form } from 'components/shared/form/Form';
import PropTypes from 'prop-types';
import './ExternalForm.scss';


const ConsentForm = forwardRef(({
  consentFormFields,
  actions,
  isSubmitting,
  version,
}, ref) => {

  const {
    id,
    formHeaderText,
    formDescription,
    formFields,
  } = consentFormFields[0];

  const fields = formFields;
  const classModfier = version === "new" ? " external-form-component--new" : "";
  
  return (
    <div
      ref={ref}
      style={{ maxWidth: version === "new" ? "auto" : 600 }}
      className={`external-form-component external-form-component--scroll${classModfier}`}
    >
      <div
        className="external-form-inner"
      >
        <h2 className="form-title">
          {formHeaderText}
        </h2>
        <p className="form-p">
          {formDescription}
        </p>
       {version != "new" && <hr style={{ marginBottom: 20 }} />} 
        <Form
          formId={id}
          fields={fields}
          actions={actions}
          isSubmitting={isSubmitting}
          wrapped
          removeBottomMargin
        />
      </div>
    </div>
  );
});

ConsentForm.propTypes = {
  consentFormFields: PropTypes.arrayOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
};


export { ConsentForm };

