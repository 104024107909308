import { quizId } from "components/kanso/ducks/quizSlice"
import { locale } from "dayjs"

export const clearPreviousAnswers = () => {
  // localStorage.removeItem(KEY);
  // localStorage.removeItem(TITLE_KEY);
}

export const getQuizData = (quizId, locale) => {
  return JSON.parse(localStorage.getItem(quizId+"-"+locale+"-data"))
}

export const getSelectedOptions = (quizId, locale) => {
  return JSON.parse(localStorage.getItem(quizId+"-"+locale+"-selected"))
}

export const getQuizResultsData = (quizId, locale) => {  
  let arrayResults = []
  for (let [key, value] of Object.entries(localStorage)) {
    if(key.includes(quizId+"-"+locale) && key !== quizId+"-"+locale+"-data") {
      arrayResults.push({key, value})
    }    
  }
  var arrayResultsSorted = arrayResults.sort(function(a, b) {
    if (parseInt(a.key.split('-')[3]) > parseInt(b.key.split('-')[3])) {
      return 1
    } else if (parseInt(a.key.split('-')[3]) < parseInt(b.key.split('-')[3])) {
      return -1
    }
    return 0
  })
  return arrayResultsSorted
}

export const clearQuizData = (quizId, locale) => {
  for (let [key, value] of Object.entries(localStorage)) {
    if(key.includes(quizId+"-"+locale) && key !== quizId+"-"+locale+"-data") {
      localStorage.removeItem(key)
    }
  }
}
