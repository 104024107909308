import { getLocationOrigin, getUrlCountryPath, isLocalhost } from 'utils/urlUtils';

const LIVE = 'live';
const UAT = 'uat';
const STAGING = 'staging';
const DEV = 'develop';
const MLR = 'mlrVnext';

const envHostnames = [
  { env: DEV, hostname: 'localhost' },
  { env: DEV, hostname: 'kki-hub-dev.rapp-customers.co.uk' },
  { env: STAGING, hostname: 'kki-hub.rapp-customers.co.uk' },
  { env: UAT, hostname: 'uat.kyowakirinhub.com' },
  { env: LIVE, hostname: 'kyowakirinhub.com' },
  { env: MLR, hostname: 'prelive.kyowakirinhub.com' }
];

const getEnv = () => {
  let hostname = window.location.hostname;
  hostname = hostname.replace(/^www\./, '')
  const env = envHostnames.filter((host) => host.hostname === hostname)[0].env;
  return env;
}

const getAudience = (localhostBypassURL) => {
  if (isLocalhost) {
    if (localhostBypassURL) {
      return localhostBypassURL
    }

    return `https://kki-hub-dev.rapp-customers.co.uk/${getUrlCountryPath()}`;
  }

  return `${getLocationOrigin()}/${getUrlCountryPath()}`;
};

const getEnvApiConfigValues = () => {
  const configs = {
    live: {
      audience: `https://www.kyowakirinhub.com/${getUrlCountryPath()}`,
      audienceSecret: '4UcM378dxmFmfz6DG5yxSQ03e36yg9I+xVP8DJczq4OzYcrf4Kl9uAhJSG4zfjxkxG7SmteWEXi94VHyaz8j7QZIHsQpvrCnvFVDlAIEeROeoEQUAaQSdvAOWENU1HEsl4RYw==',
      domain: 'https://live-api-usermanagement.kyowakirinhub.com',
      owasecureURL: 'https://www.owa-secure.com/sso/'
    },
    mlr: {
      audience: getAudience(),
      audienceSecret: '4DuIugvP5TbpuaViN+zQFC49gZKnXTE2bXHBY5R15X8KxBeSSzALihXXZpJZiiPZpi1+rj8WX0XkNuDcbesM47nzGAYNrCZhM5IMr2GAryaP2gQ3dx8uB9ovU01oJFSymiIA==',
      domain: 'https://uat-api-usermanagement.kyowakirinhub.com',
      owasecureURL: 'https://www.rowa-secure.com/sso/',
      redirectUrl: 'https://prelive.kyowakirinhub.com'
    },
    uat: {
      audience: getAudience(),
      audienceSecret: '4DuIugvP5TbpuaViN+zQFC49gZKnXTE2bXHBY5R15X8KxBeSSzALihXXZpJZiiPZpi1+rj8WX0XkNuDcbesM47nzGAYNrCZhM5IMr2GAryaP2gQ3dx8uB9ovU01oJFSymiIA==',
      domain: 'https://uat-api-usermanagement.kyowakirinhub.com',
      owasecureURL: 'https://www.rowa-secure.com/sso/'
    },
    staging: {
      audience: getAudience(),
      audienceSecret: 'MyInitialSecretForCreatingUsers',
      domain: 'https://stg-api-usermanagement-kki.rapp-customers.co.uk',
      owasecureURL: 'https://www.rowa-secure.com/sso/'
    },
    develop: {
      audience: getAudience(),
      audienceSecret: 'MyInitialSecretForCreatingUsers',
      domain: 'https://dev-api-usermanagement-kki.rapp-customers.co.uk',
      owasecureURL: 'https://www.rowa-secure.com/sso/'
    },
    
  };

  switch (getEnv()) {
    case LIVE:
      return configs.live;
    case UAT:
      return configs.uat;
    case STAGING:
      return configs.staging;
    case DEV:
      return configs.develop;
    case MLR:
      return configs.mlr
    default:
      return configs.live;
  }
};

const getEnvProxyConfigValues = () => {
  const configs = {
    live: {
      proxyDomain: 'https://onekeycasproxy.rapp-customers.co.uk',
      controllerPath: 'Home',
      validateUserPath: 'CasClientRedirectResponse',
    },
    mlr: {
      proxyDomain: 'https://uat-onekeycasproxy.rapp-customers.co.uk',
      controllerPath: 'Home',
      validateUserPath: 'CasClientRedirectResponse',
    },
    uat: {
      proxyDomain: 'https://uat-onekeycasproxy.rapp-customers.co.uk',
      controllerPath: 'Home',
      validateUserPath: 'CasClientRedirectResponse',
    },
    staging: {
      proxyDomain: 'https://stg-onekeycasproxy.rapp-customers.co.uk',
      controllerPath: 'Home',
      validateUserPath: 'CasClientRedirectResponse',
    },
    develop: {
      proxyDomain: 'https://dev-web-onekeycasproxy.rapp-customers.co.uk',
      controllerPath: 'Home',
      validateUserPath: 'CasClientRedirectResponse',
    },
  };

  switch (getEnv()) {
    case LIVE:
      return configs.live;
    case UAT:
      return configs.uat;
    case STAGING:
      return configs.staging;
    case DEV:
      return configs.develop;
    case MLR:
      return configs.mlr
    default:
      return configs.live;
  }
};

const getTealiumConfigValues = () => {
  const configs = {
    develop: {
      tealiumUtag: "https://tags.tiqcdn.com/utag/kyowakirin-international/main/dev/utag.js",
    },
    staging: {
      tealiumUtag: "https://tags.tiqcdn.com/utag/kyowakirin-international/main/qa/utag.js",
    },
    uat: {
      tealiumUtag: "https://tags.tiqcdn.com/utag/kyowakirin-international/main/dev/utag.js",    
    },
    mlr: {
      tealiumUtag: "https://tags.tiqcdn.com/utag/kyowakirin-international/main/qa/utag.js",    
    },
    live: {
      tealiumUtag: "https://tags.tiqcdn.com/utag/kyowakirin-international/main/prod/utag.js"
    },
  };

  switch (getEnv()) {
    case DEV:
      return configs.develop;
    case STAGING:
      return configs.staging;
    case UAT:
      return configs.uat;
    case MLR:
      return configs.mlr
    case LIVE:
      return configs.live;
    default:
      return configs.live;
  }
};

const getEnvAcousticConfigValues = () => {
  const configs = {
    live: {
      contentHubId: '8a9bc0cd-7d62-466f-ba07-697c50cf8eb0', //  KKI Central Hub - MLR vNext
      previewMode: false,
    },
    mlr: {
      contentHubId: '8d91cb19-ea2d-486c-88f7-f3578ba5c4d7', // KKI Central Hub - MLR vNext
      previewMode: false,
    },
    uat: {
      contentHubId: 'a590dc33-59b3-4b9b-b542-612ac16a7b39', // KKI Central Hub - Rapp UAT
      previewMode: false,
    },
    staging: {
      contentHubId: '37dd7bf6-5628-4aac-8464-f4894ddfb8c4', // KKI Central Hub - Rapp UAT - using non prod 8
      previewMode: false,
    },
    develop: {
      contentHubId: '37dd7bf6-5628-4aac-8464-f4894ddfb8c4', // KKI Central Hub - Rapp UAT - using non prod 8
      previewMode: true,
    },
  };

  switch (getEnv()) {
    case LIVE:
      return configs.live;
    case UAT:
      return configs.uat;
    case STAGING:
      return configs.staging;
    case DEV:
      return configs.develop;
    case MLR:
      return configs.mlr
    default:
      return configs.live;
  }
};
const getLamdaFunction = () => {
  const configs = {
    live: {
      checkSiteHead: 'https://yj88zwk22i.execute-api.eu-west-1.amazonaws.com/default/checkURLHeaders_LIVE?env=live', // KKI Central Hub - Rapp Live Release 2
    },
    mlr: {
      checkSiteHead: 'https://sldp966jag.execute-api.eu-west-1.amazonaws.com/default/checkURLHeaders_UAT?env=mlr', // KKI Central Hub - MLR vNext
    },
    uat: {
      checkSiteHead: 'https://sldp966jag.execute-api.eu-west-1.amazonaws.com/default/checkURLHeaders_UAT?env=uat', // KKI Central Hub - Rapp UAT
    },
    staging: {
      checkSiteHead: 'https://a0yt8y3tg1.execute-api.eu-west-1.amazonaws.com/default/checkURLHeaders_DEV?env=staging', // KKI Central Hub - Rapp UAT - using non prod 8
    },
    develop: {
      checkSiteHead: 'https://a0yt8y3tg1.execute-api.eu-west-1.amazonaws.com/default/checkURLHeaders_DEV?env=develop', // KKI Central Hub - Rapp UAT - using non prod 8
    },
  };

  switch (getEnv()) {
    case LIVE:
      return configs.live;
    case UAT:
      return configs.uat;
    case STAGING:
      return configs.staging;
    case DEV:
      return configs.develop;
    case MLR:
      return configs.mlr;
    default:
      return configs.live;
  }
};
const getDocCheckLoginId = () => {
  switch (getEnv()) {
    case LIVE:
      return '2000000017036'
    default:
      return '2000000016434'
  }
}

export {
  getEnv,
  getEnvApiConfigValues,
  getEnvProxyConfigValues,
  getTealiumConfigValues,
  getEnvAcousticConfigValues,
  getDocCheckLoginId,
  getLamdaFunction,
};
