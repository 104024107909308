import React from 'react';

import Image from 'components/reusableComponents/image/Image';

import './WebinarSpeakers.scss';

const WebinarSpeakers = ({ speaker }) => {
  const { speakerAvatar, speakerName, speakerWorkplace } = speaker
    ? speaker.elements
    : {};
  return (
    <div className='keynote-speaker'>
      {speakerAvatar && (
        <div className='keynote-speaker__profile'>
          <Image data={speakerAvatar}></Image>
        </div>
      )}
      {speakerName?.value && (
        <div className='keynote-speaker__detail'>
          <div className='keynote-speaker__detail__name'>{speakerName?.value}</div>
          <div className='keynote-speaker__detail__address'>{speakerWorkplace?.value}</div>
        </div>
      )}
    </div>
  );
};

export default WebinarSpeakers;
