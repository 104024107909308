import { AppConfig } from 'AppConfig';

const {
  domain,
  apiPath,
  createTokenPath,
  validateTokenPath,
  refreshTokenPath,
  userPath,
  confirmRegistration,
  deleteUserPath,
  forgottenPasswordPath,
  resetPasswordPath,
  changePasswordPath,
  consentPath,
} = AppConfig.authApi.general;

const API_URL = `${domain}/${apiPath}`;
const CREATE_TOKEN_URL = `${API_URL}/${createTokenPath}`;
const VALIDATE_TOKEN_URL = `${API_URL}/${validateTokenPath}`;
const REFRESH_TOKEN_URL = `${API_URL}/${refreshTokenPath}`;
const USER_URL = `${API_URL}/${userPath}`;
const CONFIRM_REGISTRATION_URL = `${API_URL}/${userPath}/${confirmRegistration}`;
const DELETE_USER_URL = `${USER_URL}/${deleteUserPath}`;
const CONFIRM_DELETE_URL = `${USER_URL}/${deleteUserPath}`; // same as DELETE_USER_URL, just appends a token on the end.
const FORGOTTEN_PASS_URL = `${USER_URL}/${forgottenPasswordPath}`;
const RESET_PASS_URL = `${USER_URL}/${resetPasswordPath}`;
const CHANGE_PASS_URL = `${USER_URL}/${changePasswordPath}`;
const UPDATE_CONSENT_URL = `${USER_URL}/${consentPath}`;

export {
  CREATE_TOKEN_URL,
  VALIDATE_TOKEN_URL,
  REFRESH_TOKEN_URL,
  USER_URL,
  CONFIRM_REGISTRATION_URL,
  DELETE_USER_URL,
  CONFIRM_DELETE_URL,
  FORGOTTEN_PASS_URL,
  RESET_PASS_URL,
  CHANGE_PASS_URL,
  UPDATE_CONSENT_URL,
};
