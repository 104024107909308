import axios from 'axios';
import * as actions from "../api";
import { AppConfig } from 'AppConfig';

//This is also we can use /rendering in the api call
const axiosInstance = axios.create({
  withCredentials: false,
});

const api = ({ dispatch }) => next => async action => {
    if (action.type !== actions.apiCallBegan.type) return next(action);
  
    const { url, method, data, onStart, onSuccess, onError } = action.payload;
  
    if (onStart) dispatch({ type: onStart });
  
    next(action);

    try {
      const response = await axiosInstance.request({
        baseURL: `${AppConfig.acousticApi.domain}/${AppConfig.acousticApi.apiPath}/${AppConfig.acousticApi.contentHubId}/${AppConfig.acousticApi.deliveryPath}/rendering`,
        url,
        method,
        data
      });
      // General
      dispatch(actions.apiCallSuccess(response.data));
      
      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
      // General
      dispatch(actions.apiCallFailed(error.message));
      // Specific
      if (onError) dispatch({ type: onError, payload: error.message });
    }
  };
  
  export default api;

