import React from 'react';
import RichText from 'components/reusableComponents/richText/RichText';

import { contentElementProptype } from 'propTypes/contentProptypes';
import Video from 'components/reusableComponents/video/Video';
import './DynamicContentSection.scss';
import { getImgAttr } from 'api/acousticApi';
import { ImageCarousel } from 'components/content/ImageCarousel';
import FormBuilder from 'modules/formBuilder/FormBuilder';
import AudioCard from 'modules/audioCard/AudioCard';
import { getValue } from 'utils/contentUtils';

// component for an article section
const DynamicContentSection = ({ element, formHidden, className }) => {
  if (!element) {
    return null;
  }
  //dynamic content section props
  const {
    title,
    copy,
    image,
    video,
    videoPoster,
    videoPosterImage,
    webvttChaptersFile,
    videoTitle,
    subtitles,
    carousel,
    audioFile,
    externalVideoLink
  } = element?.elements;
  const {
    type
  } = element;

  const titleValue = getValue(title);
  const img = getImgAttr(image);
  const carouselElements = carousel?.value?.elements;

  if(type === "Component – Form Builder"){
    if(!formHidden){
      return (
        <div className='container'>
          <FormBuilder element={element}/>
        </div>
      )
    }
    return null;
  } 

  if(audioFile?.url){
    return (
      <AudioCard image={img} copy={copy} src={audioFile} title={titleValue} />
    )
  }

  return (
    <div className={`dynamic-content-section ${className}`}>
      {copy?.value && (
        <div className='container'>
          <RichText className='content-page-article' data={copy} />
        </div>
      )}
      {image?.url && (
        <div className='container'>
          <div className='media-container'>
            <img src={img.src} alt={img.alt} />
          </div>
        </div>
      )}
      {(video?.url || externalVideoLink?.value) && (
        <div className='container'>
          <div className='media-container'>
            <Video
              element={video}
              webvttChaptersFile={webvttChaptersFile}
              title={videoTitle}
              subtitles={subtitles}
              videoPoster={videoPoster}
              videoPosterImage={videoPosterImage}
              externalVideo={externalVideoLink}
            />
          </div>
        </div>
      )}
      {carouselElements?.images?.values?.length > 0 && (
        <div className='outer-container'>
          <div className='container'>
            <ImageCarousel
              images={carouselElements?.images}
              orientation={carouselElements?.orientation?.value?.selection}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DynamicContentSection.propTypes = {
  element: contentElementProptype,
};

export { DynamicContentSection };
