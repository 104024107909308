// javascript object defining the project routes
// For now each page template that we style up has a dev content item in acoustic
// The naming of the paths and templates is a bit confusing, but we are copying the naming used in the designs
// Each unconnected template page has information linking back to the design

// dev paths while nodding styling and connection tickets
const ARTICLE_PATH = '/example-article';
const AVAILABLE_TREATMENTS_PATH = '/dev-available-treatments-landing-page';
const CONTENT_PROMO_PAGE_PATH = '/dev-content-promo';
const GENERIC_PAGE_PATH = '/contact';
const VEEVA_PLAYER_PAGE_PATH = '/veeva-engage-poc';
const LIST_CONTENT_PATH = '/dev-content-list-page';
const MAIN_PAGE_PATH = '/dev-main-therapy-page';
const MAIN_PAGE_PROMO_PATH = '/dev-available-treatment-page';

// Fixed routes within the app that are not acoustic pages
const HOME_PAGE_PATH = '/';
const LOGIN_PAGE_PATH = '/login';
const FORGOTTEN_PASSWORD_PATH = '/forgotten-password';
const FORGOTTEN_PASSWORD_SUCCESS = '/forgotten-password-success';
const FORGOTTEN_PASSWORD_SUCCESS_PATH = `${FORGOTTEN_PASSWORD_SUCCESS}`; // temporary replacement for emails remember to remove
const RESET_PASSWORD = '/reset-password';
const RESET_PASSWORD_PATH = `${RESET_PASSWORD}/:tokenValue`;
const RESET_PASSWORD_SUCCESS_PATH = '/reset-password-success';
const REGISTRATION_SUCCESS = '/registration-success';
const REGISTRATION_SUCCESS_PATH = `${REGISTRATION_SUCCESS}`;
const CONFIRM_EMAIL = '/confirm-email';
const CONFIRM_EMAIL_PATH = `${CONFIRM_EMAIL}/:tokenValue`;
const CONFIRM_EMAIL_ERROR = '/confirm-email-error';
const CONFIRM_EMAIL_ERROR_PATH = `${CONFIRM_EMAIL_ERROR}/:errorCode`;
const CONFIRM_EMAIL_SUCCESS_PATH = '/confirm-email-success';
const DELETE_USER_ACCOUNT_SUCCESS = '/delete-user-success';
const DELETE_USER_ACCOUNT_SUCCESS_PATH = `${DELETE_USER_ACCOUNT_SUCCESS}`;
const CONFIRM_DELETE_USER_ACCOUNT = '/confirm-delete-user-account';
const CONFIRM_DELETE_USER_ACCOUNT_PATH = `${CONFIRM_DELETE_USER_ACCOUNT}/:tokenValue`;
const CONFIRM_DELETE_USER_ACCOUNT_FAILURE = '/confirm-delete-user-account-failure';
const CONFIRM_DELETE_USER_ACCOUNT_FAILURE_PATH = `${CONFIRM_DELETE_USER_ACCOUNT_FAILURE}/:errorCode`;
const CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH = '/confirm-delete-user-account-success';
const PROFILE_PAGE_PATH = '/profile';
const PROFILE_SUCCESS_PATH = '/profile-success';
const CHANGE_PASSWORD_SUCCESS_PATH = '/change-password-success';
const EXTERNAL_LOGIN_ERROR_PATH = '/external-login-error';
const LOGOUT_PATH = '/logout';
const SEARCH_RESULTS = '/search-results';
const INFOGRAPHICS = '/therapy-areas/neurology/parkinsons-disease/think-beyond-medical-education/e-learning/managingparkinsonsasmdt-internal-obsolete';
const INFOGRAPHICSTB = '/therapy-areas/neurology/parkinsons-disease/think-beyond-medical-education/e-learning/progressionofpd-internal-obsolete';
const GLOBAL_LANDING_PAGE = '/';

export {
  ARTICLE_PATH,
  AVAILABLE_TREATMENTS_PATH,
  CONTENT_PROMO_PAGE_PATH,
  GENERIC_PAGE_PATH,
  VEEVA_PLAYER_PAGE_PATH,
  HOME_PAGE_PATH,
  LIST_CONTENT_PATH,
  MAIN_PAGE_PATH,
  MAIN_PAGE_PROMO_PATH,
  REGISTRATION_SUCCESS,
  REGISTRATION_SUCCESS_PATH,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_PATH,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_ERROR_PATH,
  CONFIRM_EMAIL_SUCCESS_PATH,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_SUCCESS_PATH,
  CONFIRM_DELETE_USER_ACCOUNT,
  CONFIRM_DELETE_USER_ACCOUNT_PATH,
  CONFIRM_DELETE_USER_ACCOUNT_FAILURE,
  CONFIRM_DELETE_USER_ACCOUNT_FAILURE_PATH,
  CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH,
  PROFILE_SUCCESS_PATH,
  CHANGE_PASSWORD_SUCCESS_PATH,
  LOGIN_PAGE_PATH,
  FORGOTTEN_PASSWORD_PATH,
  FORGOTTEN_PASSWORD_SUCCESS,
  FORGOTTEN_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  PROFILE_PAGE_PATH,
  EXTERNAL_LOGIN_ERROR_PATH,
  LOGOUT_PATH,
  SEARCH_RESULTS,
  INFOGRAPHICS,
  INFOGRAPHICSTB,
  GLOBAL_LANDING_PAGE
};
