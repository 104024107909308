import React from 'react';
import PropTypes from 'prop-types';
import {
  ChevronButton,
  LINK,
} from 'components/shared/ChevronButton';
import {
  fieldPropTypes,
} from 'components/shared/form/formPropTypes';
import './FormLinkButton.scss';


const FormLinkButton = ({
  field,
  fieldType,
}) => {

  const {
    typeParams,
    size = 100,
  } = field;

  const {
    text,
    style,
    to,
  } = typeParams;

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];

  if (style) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-${style}`);
  }


  return (
    <div
      className={fieldClassNames.join(' ')}
    >
      <ChevronButton
        text={text}
        type={LINK}
        internalHref={to}
      />
    </div>
  );
};

FormLinkButton.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
};

export {
  FormLinkButton,
};

