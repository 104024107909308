import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHasLimitedAccess } from 'utils/authUtils';
import { verifyNotificationContext } from 'components/layout/VerifyNotificationContext';
import { CloseButton } from 'components/shared/CloseButton';
import './VerifyNotification.scss';

const VerifyNotification = () => {
  const { status, data } = useSelector((state) => state.authentication);
  const { loggedIn } = status || {};
  const { trustLevel } = data.additional || {};

  const userHasLimitedAccess = useHasLimitedAccess({ trustLevel });

  const {
    verifyNotificationIsOpen,
    showVerifyNotification,
    closeVerifyNotification,
  } = useContext(verifyNotificationContext);

  useEffect(() => {
    if (loggedIn && userHasLimitedAccess) {
      showVerifyNotification();
    }

    if (!loggedIn) {
      closeVerifyNotification();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, trustLevel, userHasLimitedAccess]);

  if (!verifyNotificationIsOpen) {
    return null;
  }

  return (
    <section className="verify-notification">
      <div className="container">
        <p>
          <strong>Your account is awaiting verification.</strong>
          {' '}
          Our team will try to complete this process as quickly as possible.
        </p>
        <div className="close-container">
          <CloseButton onClick={closeVerifyNotification} />
        </div>
      </div>
    </section>
  );
};


export { VerifyNotification };

