import { useHistory } from 'react-router-dom';

const videoPause = (e) => {
  var t = buildTrackableVideoInfoEvent(e);
  var percComplete = (t.currentTime / t.duration) * 100;
  window.utag.link({
    tealium_event: 'video_pause',
    video_percent: percComplete,
    video_url: t.currentSrc,
    video_playhead: t.currentTime,
    video_id: t.id,
    video_length: t.duration,
    video_name: t.title,
    video_platform: 'HTML5',
  });
};

const videoPlay = (e) => {
  var t = buildTrackableVideoInfoEvent(e);
  window.utag.link({
    tealium_event: 'video_start',
    video_percent: t.percComplete,
    video_url: t.currentSrc,
    video_playhead: t.currentTime,
    video_id: t.id,
    video_length: t.duration,
    video_name: t.title,
    video_platform: 'HTML5',
  });
};

const buildTrackableVideoInfoEvent = (e) => {
  return {
    event: 'video',
    baseURI: e.currentTarget.baseURI,
    currentSrc: e.currentTarget.currentSrc,
    id: e.currentTarget.id,
    volume: e.currentTarget.volume,
    action: e.type === 'timeupdate' ? 'percentageComplete' : e.type,
    currentTime: e.currentTarget.currentTime,
    duration: e.currentTarget.duration,
    title: e.currentTarget.title,
  };
};

const videoEnd = (e) => {
  var t = buildTrackableVideoInfoEvent(e);
  for (var i = 0; i < window.videoplayers.length; i++)
    if (window.videoplayers[i].playerId === t.id) {
      window.videoplayers[i].mileStones = JSON.parse(
        JSON.stringify(window.mileStones)
      );
      break;
    }
  window.utag.link({
    tealium_event: 'video_end',
    video_percent: 100,
    video_milestone: 100,
    video_url: t.currentSrc,
    video_playhead: t.currentTime,
    video_id: t.id,
    video_length: t.duration,
    video_name: t.title,
    video_platform: 'HTML5',
  });
};

const videoTimeUpdate = (e) => {
  var t = buildTrackableVideoInfoEvent(e);
  var obj;
  for (var i = 0; i < window.videoplayers.length; i++)
    if (window.videoplayers[i].playerId === t.id) {
      obj = window.videoplayers[i];
      break;
    }
  var duration = t.duration;
  var playhead = t.currentTime;
  var percComplete = (playhead / duration) * 100;
  var ms_len = obj.mileStones.length;
  if (ms_len > 0) {
    var next_ms = obj.mileStones[0];
    if (next_ms <= percComplete) {
      obj.mileStones.shift();
      t.percComplete = next_ms;
      window.utag.link({
        tealium_event: 'video_milestone',
        video_percent: t.percComplete,
        video_milestone: t.percComplete,
        video_url: t.currentSrc,
        video_playhead: t.currentTime,
        video_id: t.id,
        video_length: t.duration,
        video_name: t.title,
        video_platform: 'HTML5',
      });
    }
  }
};

const RegisterButtonClickFunction = (btnText) => {
  let buttontext = btnText || "CREATE YOUR ACCOUNT";
  let button_data = {
    tealium_event: 'Register Button Click',
    buttontext: buttontext,
  };
  window.utag.ut.merge(button_data, window.utag_data, 1);
  window.utag.view(button_data, null, [2]);
};

const LoginButtonClickFunction = (btnText) => {
  let buttontext = btnText || "Continue";
  let button_data = {
    tealium_event: 'Login Button Click',
    buttontext: buttontext,
  };
  window.utag.ut.merge(button_data, window.utag_data, 1);
  window.utag.view(button_data, null, [2]);
};

const InitialiseTealiumEvents = () => {

};


export {
  videoPause,
  videoPlay,
  buildTrackableVideoInfoEvent,
  videoEnd,
  videoTimeUpdate,
  RegisterButtonClickFunction,
  LoginButtonClickFunction,
  InitialiseTealiumEvents,
};
