import { contentItemProptype } from 'propTypes/contentProptypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getValue } from 'utils/contentUtils';
import ContentPage from 'pages/contentPage/ContentPage';

const PreRegistrationPage = ({ content }) => {
  const {
    seoPageTitle,
    seoDescription,
  } = content.elements;

  const title = getValue(seoPageTitle);
  const description = getValue(seoDescription);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && (
          <meta name="description" content={description} />
        )}
      </Helmet>
      <ContentPage
        content={content}
      />
    </>
  );
};

PreRegistrationPage.propTypes = {
  content: contentItemProptype,
};

export { PreRegistrationPage };
