import { withLeadingSlash } from './stringUtils';

const USE_EXTERNAL_LINK_VALUE = 'External link';
const DISPLAY_FOOTER_TEXT_VALUE = 'Footer text';
const DISPLAY_CHEVRON = 'Display';

// this could be improved to check for empty objects
const isValueEmpty = (value) => {
  if (!value) {
    return true;
  }

  if (value.length !== undefined) {
    if (value.length === 0) {
      return true;
    }
  }

  return false;
};


const getValue = (element) => {
  if (!element) {
    return null;
  }

  if (element.value) {
    return element.value;
  }

  return null;
};


const getValues = (element) => {
  if (!element) {
    return null;
  }

  if (element.values) {
    return element.values;
  }

  return null;
};

// reduces an element down to true/false based on if it has a value and if that value is just []/'' ect
const castElementToBool = (element) => {
  const value = getValue(element);
  if (!value) {
    return false;
  }

  // checks for length === 0 ect
  if (isValueEmpty(value)) {
    return false;
  }

  return true;
};


const getSelectionValue = (element) => {
  const elementValue = getValue(element);
  return elementValue ? elementValue.selection : null;
};

const getFirstSelectionValue = (element) => {
  const elementValues = getValues(element);
  if (!elementValues) {
    return null;
  }

  const firstValue = elementValues[0];

  return firstValue ? firstValue.selection : null;
};

const getSelectionValues = (element) => {
  const elementValues = getValues(element);
  if (!elementValues) {
    return [];
  }
  return elementValues.map((value) => (
    value ? value.selection : null
  ));
};

const getInternalUrl = (page) => {
  const pageValue = page ? page.value : null;
  const elements = pageValue ? pageValue.elements : null;
  const urlSlug = elements ? elements.urlSlug : null;
  // ensure all internal urls start with /
  return urlSlug ? withLeadingSlash(urlSlug.value) : null;
};


const getFooterTextDisplayData = ({
  displayLearnMoreOrFooterText,
  displayExternalLinkChevron,
  footerText,
  linkText,
}) => {
  // If linkText then this is coming from a Link inside a Therapy Card: Use the text & add a chevron
  if(linkText) {
    return ({
      footerTextValue: linkText,
      displayChevron: true,
    })
  }

  /*
    This little nightmare essentially boils down to returning two things:
    -footerTextValue:
    The text to be displayed in the footer of a carousel item. Note, if this
    returns a falsy value then the component that renders this data uses it's
    fallback 'LEARN MORE'
    -displayChevron:
    This determins if the text in rendered in the "tag" style of the chevron
    style (from TextCardLearnMore).

    The conditions are as followed:
    #if it is set that the component will not show it's footer text:
      return:
        footerTextValue: null
        displayChevron: true
    #if it is set that the component will show it's footer text:
      #if it is set that the component will not display a chevron:
        return:
          footerTextValue: provided value
          displayChevron: false
      #if it is set that the component will display a chevron:
        return:
          footerTextValue: provided value
          displayChevron: true
  */

  const footerTextChosenValue = getSelectionValue(displayLearnMoreOrFooterText);
  const footerTextChosen = footerTextChosenValue === DISPLAY_FOOTER_TEXT_VALUE;
  const footerTextValue = footerText ? footerText.value : null;

  const displayExternalLinkChevronValue = getSelectionValue(displayExternalLinkChevron);
  const displayChevron = displayExternalLinkChevronValue === DISPLAY_CHEVRON;


  const showFooterText = footerTextValue && footerTextChosen;
  const shouldDisplayChevron = showFooterText ? displayChevron : true;

  return ({
    footerTextValue: showFooterText ? footerTextValue : null,
    displayChevron: shouldDisplayChevron,
  });
};


const getUseExternal = (linkToInternalPageOrExternalLink) => {
  const useExternalValue = getSelectionValue(linkToInternalPageOrExternalLink);
  return useExternalValue === USE_EXTERNAL_LINK_VALUE;
};


const getHrefs = ({ link, externalLink, page, linkToInternalPageOrExternalLink }) => {
  if (link) {
    if (link.startsWith('http') || link.startsWith('www')) {
      return ({ internalHref: null, externalHref: link });
    } else {
      return ({ internalHref: link, externalHref: null });
    }
  }

  //TODO: Remove the code below after "cleaning" Acoustic
  const externalUrl = externalLink ? externalLink.value : null;
  let internalUrl = getInternalUrl(page);
  const useExternal = getUseExternal(linkToInternalPageOrExternalLink);
  if (!useExternal && !internalUrl && externalLink)
    internalUrl = externalLink.value;
  return ({
    internalHref: useExternal ? null : internalUrl,
    externalHref: useExternal ? externalUrl : null,
  });
};

const getColourHashValue = (element) => {
  const hashNumber = getValue(element);
  // if they have entered `CCCCC` instead of `#CCCCCC`, prefix the `#`
  if (hashNumber && hashNumber.charAt(0) !== '#') {
    return `#${hashNumber}`;
  }
  return hashNumber;
};

const truncateContent = ({ input, charLimit }) => input.length > charLimit ? `${input.substring(0, charLimit)}...` : input;


export {
  getSelectionValue,
  getFirstSelectionValue,
  getSelectionValues,
  getInternalUrl,
  getFooterTextDisplayData,
  getUseExternal,
  getHrefs,
  getValue,
  getValues,
  getColourHashValue,
  castElementToBool,
  truncateContent,
  USE_EXTERNAL_LINK_VALUE,
  DISPLAY_FOOTER_TEXT_VALUE,
};
