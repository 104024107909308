import { useEffect } from "react";

const shortcodes = [
  {
    name: 'CookieList',
    regex: /\[CookieList(.+?)?\]/g,
  },
];

const replaceAsciiInString = (str) =>
  str && str.replace(/&#(\d+);/g, (m, n) => String.fromCharCode(n));

export const extractTagsFromContent = (html) => {
  let tags = [];
  shortcodes.forEach((shortcode) => {
    const patt = shortcode.regex;

    if (patt.test(html)) {
      tags = html.match(patt);
    }
  });

  return tags;
};

export const getAttributesFromTag = (tag) => {
  let attributes = [];
  tag.match(/[\w-]+=".+?"/g)?.forEach((attribute) => {
    attributes.push(attribute.match(/([\w-]+)="(.+?)"/)[0]);
  });
  return attributes;
};

export const getPropsFromTag = (tag) => {
  let props = {};
  tag.match(/[\w-]+=".+?"/g)?.forEach((attribute) => {
    attribute = attribute.match(/([\w-]+)="(.+?)"/);
    props = { ...props, [attribute[1]]: attribute[2] };
  });
  return props;
};

export const replaceTags = (html, tags) => {
  let htmlWithPlaceholder = html;
  tags.forEach((toReplace) => {
    const attributes = getAttributesFromTag(toReplace);
    const replacement = `<div id='ot-sdk-cookie-policy' ${attributes.join(
      ' '
    )}></div>`;
    htmlWithPlaceholder = htmlWithPlaceholder.replace(toReplace, replacement);
  });
  return htmlWithPlaceholder;
};

const useCookieListParser = (value) => {
  let finalValue = value;
  let formattedValue = replaceAsciiInString(value);
  const tags = extractTagsFromContent(formattedValue);

  if (tags && tags.length > 0) {
    finalValue = replaceTags(finalValue, tags);
  }

  useEffect(() => {
    if(window.OneTrust && document.getElementById('ot-sdk-cookie-policy')) {
      window.OneTrust.initializeCookiePolicyHtml();
    }
  });

  return finalValue;
};

export default useCookieListParser;
