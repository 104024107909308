import RichText from 'components/reusableComponents/richText/RichText';
import './AdverseEventsCompliance.scss'

const AdverseEventsCompliance = (props) => {
    const { data, removeBottomSpacing } = props;
    return (
        <div className={`AdverseEvents${removeBottomSpacing ? " AdverseEvents--remove-bottom-spacing" : ""}`}>
            <RichText data={data} />
        </div>
    );
}

export default AdverseEventsCompliance;