// Keys for local storage
const SESSION_ID = 'session-id';
const AUTH_CODE = 'auth-code';

const browserHasLocalStorageCheck = () => {
  if (typeof (Storage) !== 'undefined') {
    return true;
  }

  // Sorry! No Web Storage support..
  console.error('Browser does not support storage. Login state will not be persisted');
  return false;

};

const removeAuthKeys = ({ key }) => {
  if (typeof (Storage) !== 'undefined') {
    if (window.localStorage.getItem(key)) {
      window.localStorage.removeItem(key);
    }
  }
  else {
    // Sorry! No Web Storage support..
    console.error('Browser does not support storage. Login state will not be persisted');
  }
};

const setAuthKeys = ({ key, payload }) => {

  if (typeof (Storage) !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(payload));
  }
  else {
    // Sorry! No Web Storage support..
    console.error('Browser does not support storage. Login state will not be persisted');
  }
};

const getSessionFromStorage = () => {
  if (browserHasLocalStorageCheck()) {
    return (window.localStorage.getItem(SESSION_ID) ? JSON.parse(window.localStorage.getItem(SESSION_ID)) : null);
  }

  return null;
};

export {
  SESSION_ID,
  AUTH_CODE,
  setAuthKeys,
  removeAuthKeys,
  getSessionFromStorage,
  browserHasLocalStorageCheck
};
