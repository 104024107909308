import React, { useState, useEffect } from 'react';
import './SearchResults.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { search, selectedPage } from 'redux/search/slice';
import { buildSearchParams } from 'utils/searchUtils';
import SearchResultContent from 'modules/searchResult/SearchResultContent';
import uuid from 'react-uuid';
import Pagination from '@material-ui/lab/Pagination';
import SearchResultMedia from 'modules/searchResult/SearchResultMedia';

const SearchResults = () => {
  const {
    tSearchRelatedPageUrl
  } = useWebsiteConfigObject();
  const searchPath = tSearchRelatedPageUrl ? tSearchRelatedPageUrl.split("?") : null

  const dispatch = useDispatch();
  const history = useHistory();

  const searchLocation = useLocation().search;
  const searchText = new URLSearchParams(searchLocation).get(searchPath ? searchPath[1] : 'searchText');
  console.log("searchText: ",searchText)
  const pageNum = new URLSearchParams(searchLocation).get('pageNum');
  const { documents, numFound } = useSelector( (state) => state.search.searchResults );
  const { searchTerm, loading, pageSelected } = useSelector( (state) => state.search );
  const { tSearchResultsHeader, tSearchResultsSubtitle, tSearchResultsText, tSearchNoResultsText, } = useWebsiteConfigObject();

  const displayResults = numFound > 0 ? true : false;
  const numberOfResults = numFound > 10 ? 10 : numFound;

  //Work out number of pages for paginantion
  let quotient = Math.floor(numFound / numberOfResults);
  let remainder = numFound % numberOfResults;
  const pageCount = remainder > 0 ? quotient + 1 : quotient;
  //change page
  const [page, setPage] = useState(parseInt(pageNum));
  useEffect(() => {
    if(searchText) {
      const startNumber = pageNum > 1 ? (pageNum - 1) * 10 : 0;
      dispatch(search(searchText, startNumber));
      dispatch(selectedPage(parseInt(pageNum)));
      setPage(parseInt(pageNum));
      window.scrollTo(0, 0);
    }
  }, [dispatch, searchText, pageNum, pageSelected]);

  const handleChange = (event, value) => {
    history.push({
      pathname: searchPath ? (searchPath[0].startsWith("/") ? searchPath[0] : "/"+searchPath[0]) : '/search-results',
      search: buildSearchParams(searchTerm, value, searchPath ? searchPath[1] : null)
    })
    window.scrollTo(0, 0);
    
  };

  //replace text from cms with numbers
  const resultStr = (tSearchResultsText || '')
    .replace('[RESULTSDISPLAYED]', documents?.length)
    .replace('[NUMBEROFRESULTS]', numFound);

  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
      <section className='Search_Results' role="main">
        {loading && (
          <section role='main' className='loading-page'>
            <Loader className='center-aligned-loader' size={LARGE_LOADER} />
          </section>
        )}

        <MainAreaTitleSection
          title={tSearchResultsHeader}
          standardText={tSearchResultsSubtitle}
          emphasisedText={searchTerm}
        />

        <div className='container'>
          {documents && documents[0]?.id && (
            <p className='Results-Text'>{resultStr}</p>
          )}

          {documents?.map((item) => {
            if (!item.document) return null;

            if (item.document.classification === 'asset') {
              return <SearchResultMedia key={uuid()} content={item.document} />;
            }

            if (item.document.classification === 'content') {
              return (
                <SearchResultContent key={uuid()} content={item.document} />
              );
            }

            return null;
          })}

          {displayResults && (
            <div className='Pagination'>
              <Pagination
                count={pageCount}
                page={page}
                color='primary'
                onChange={handleChange}
              />
            </div>
          )}

          {!displayResults && <h4 className='No-Results'>{tSearchNoResultsText}</h4>}
        </div>
      </section>
    </>
  );
};

export default SearchResults;
