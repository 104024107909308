import React from 'react'

// components
import RichText from '../../../richText/RichText'

// styles
import './CardRichText.scss'

const CardRichText = ({ content, truncate }) => {
    
    if (!content || !content?.value) return null

    return (
        <div className='CardRichText'>
            <RichText data={content} truncate={truncate ? 152 : null} />
        </div>
    )
}

export default CardRichText
