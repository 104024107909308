import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/reusableComponents/link/Link';
import { HomepageCollapsableSection } from 'components/content/homepage/HomepageCollapableSection';
import { getImgAttr } from 'api/acousticApi';
import RichText from 'components/reusableComponents/richText/RichText';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { ChevronRight } from 'components/shared/SvgIcons';
import { getValue, getValues, getInternalUrl } from 'utils/contentUtils';
import { withLeadingSlash } from 'utils/stringUtils';
import { LinkButton } from 'components/content/LinkButton';
import './PromoKit.scss';
import { addSubareaParameterToPath } from 'components/content/contentListPage/therapySubAreas';

const TitleElement = ({ leftColTitleValue, href }) => {
  const Title = () => <h2>{leftColTitleValue}</h2>;

  if (href) {
    return (
      <Link href={href}>
        <Title />
      </Link>
    );
  }

  return <Title />;
};

TitleElement.propTypes = {
  leftColTitleValue: PropTypes.string,
  href: PropTypes.string,
};

// Naming convention based off of "Press Kits" which are "pre-packaged set of promotional materials that provide information"
// which is essentially what this is... I just couldn't think of a better name :sweat:
const PromoKit = ({
  icon,
  subtitle,
  leftColTitle,
  titlePage,
  leftColContent,
  resources,
  startOpen,
  isCollapsable,
  disableAccordion,
}) => {
  const { src, alt } = getImgAttr(icon);

  const subtitleValue = getValue(subtitle);
  const leftColTitleValue = getValue(leftColTitle);
  const titleHref = titlePage ? withLeadingSlash(titlePage) : null;
  const resourcesValues = getValues(resources);

  const Icon = src ? <img src={src} alt={alt} height='32' width='32' /> : null;
  const collapsable = !!(!disableAccordion?.value || isCollapsable);
  return (
    <div className='promo-kit-component'>
      {/* {subtitleValue && (
        <div className='category-divider'>
          <span>{subtitleValue}</span>
          <div className='line' />
        </div>
      )} */}

      <HomepageCollapsableSection
        title={leftColTitleValue ? leftColTitleValue : ''}
        subtitle={subtitleValue ? subtitleValue : null}
        icon={Icon}
        startOpen={startOpen || !isCollapsable}
        isCollapsable={collapsable}
        vvClickAttr='click'
        vvSnapshotAttr='after'
      >
        <div className='title-description'>
          {leftColTitleValue && (
            <TitleElement
              leftColTitleValue={leftColTitleValue}
              href={titleHref}
            />
          )}
          {leftColContent && <RichText data={leftColContent} />}
        </div>
        {resourcesValues && resourcesValues.length && (
          <ul className='links'>
            {resourcesValues.map((resource) => {
              const { id, elements: resourcesElements } = resource;
              const {
                title,
                page,
                paragraph,
                therapySubAreaFilter,
                externalLink,
                link,
              } = resourcesElements;
              // the url is the page reference, also filtered by therapy sub area
              const pageUrl = link?.linkURL
                ? link?.linkURL
                : getInternalUrl(page);
              const href = pageUrl
                ? addSubareaParameterToPath(pageUrl, therapySubAreaFilter)
                : getValue(externalLink);
              return (
                <li key={id}>
                  <LinkButton to={href}>
                    {getValue(title)}
                    <span className='chevron'>
                      <ChevronRight />
                    </span>
                  </LinkButton>
                  {paragraph && <RichText data={paragraph} />}
                </li>
              );
            })}
          </ul>
        )}
      </HomepageCollapsableSection>
    </div>
  );
};

PromoKit.propTypes = {
  icon: contentElementProptype,
  subtitle: contentElementProptype,
  leftColTitle: contentElementProptype,
  leftColContent: contentElementProptype,
  titlePage: PropTypes.string,
  resources: contentElementProptype,
  startOpen: PropTypes.bool,
};

export { PromoKit };
