import {
  getValue,
  getValues,
  getFullUrl,
  getSelectionValue,
  getFullImageStr,
} from 'utils/utilityHelper';
import HeroHeader from './HeroHeader';
import RichText from 'components/reusableComponents/richText/RichText';
import { lazy } from 'react';
import ButtonAcousticData from 'components/kanso/atoms/button/ButtonAcousticData';
// const ButtonAcousticData = lazy(() => import('components/kanso/atoms/button/ButtonAcousticData'))

const HeroHeaderAcousticData = (props) => {
  const {
    align,
    backgroundImage,
    backgroundImageMobile,
    backgroundVideo,
    description,
    headingTag,
    heading,
    linksButtons,
    sideImage,
    sideVideo,
    title,
    brandColours,
  } = props.data;
  const { primary, ctaButtonsBackground } = brandColours?.value?.elements || {};

  const getRichText = (text) => {
    if (!text) return null;
    return <RichText data={{ value: text }} />;
  };

  return (
    <HeroHeader
      align={getSelectionValue(align)}
      title={getValue(title)}
      headingTag={getSelectionValue(headingTag)}
      heading={getValue(heading)}
      description={getRichText(getValue(description))}
      bgColour={getValue(primary)}
      bgImage={backgroundImage?.url ? backgroundImage : null}
      bgImageMobile={backgroundImageMobile?.url ? backgroundImageMobile : null}
      bgVideo={backgroundVideo?.url ? getFullUrl(backgroundVideo?.url) : null}
      ctaButtonsBackground={getValue(ctaButtonsBackground)}
      sideImage={sideImage?.value?.elements ? sideImage?.value?.elements : null}
      sideVideo={sideVideo?.value?.elements ? sideVideo?.value?.elements : null}
    >
      {getValues(linksButtons) && (
        <>
          {getValues(linksButtons).map((button, i) => {
            return <ButtonAcousticData key={i} data={button.elements} />;
          })}
        </>
      )}
    </HeroHeader>
  );
};

export default HeroHeaderAcousticData;
