import React, { useState, useEffect } from 'react';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { getValue, getSelectionValue } from 'utils/contentUtils';
import { ContentListPageCard } from 'components/content/contentListPage/ContentListPageCard';
import PropTypes from 'prop-types';
import './SubcategoryFilterMoodle.scss';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { search, selectedPage } from 'redux/search/slice';

// const CARDS_PER_PAGE = 3;
const showAllCards = () => {
  // [].forEach.call(document.querySelectorAll('.subcategoryfilter'), function (el) {
  //   el.style.display = '';
  // });

  [].forEach.call(document.querySelectorAll('.subcategory-button'), function (el) {
      el.classList.remove("selected");
  });
  document.getElementById("btnFilterShowAll")?.classList?.add("selected");
}


const showSubcategoryCards = ( subcategory) => {
 
  [].forEach.call(document.querySelectorAll('.subcategory-button'), function (el) {
    if (subcategory && el.className.includes("subcategory-button-" + subcategory.split(' ').join('-')))
      el.classList.add("selected");
    else
      el.classList.remove("selected");
  });

}


const SubcategoryFilterMoodle = ({ cardSubcategoryFilter, paginatedCards,cardsNumber }) => { 
  const CARDS_PER_PAGE = cardsNumber;

  function setAllCards(subcategory,paginatedCards) {
    const cards= paginatedCards;
    const keysCardList= Object.values(cards);
    let arrayCardList = new Array ();
    if(subcategory === 'all'){
      showAllCards();
      arrayCardList= keysCardList;
    }
    else{
      for (const item of keysCardList) {
        const items= item.elements?.cardCategory?.value;
        if(items === subcategory){
          arrayCardList.push(item);
        }
      }
      showSubcategoryCards(subcategory)
    }
    setPaginateCards(arrayCardList,cardsNumber)
  
  }

  function setPaginateCards(cards,cardsNumber){
    const CARDS_PER_PAGE =cardsNumber ;
    const paginatesCards = cards ? cards.slice(startNumber, startNumber+CARDS_PER_PAGE) : [];
    const hasMorePages = cards ? cards.length > paginatesCards.length : false;
    const numberOfResults = cards.length;

    setHandleSubcategoryCards(paginatesCards);
    setHandleHasCards(hasMorePages);
    setHandleNumOfResult(numberOfResults);  
  
  }

  function capitalizeFirstLetter(str) {
    const arr = str.toString().toLowerCase().split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  }

  function handleChange(event,value){
    setPage(value);
    dispatch(selectedPage(value));
    const startNumbers = value > 1 ? (value - 1) * CARDS_PER_PAGE : 0;
    setStartNumber(startNumbers);
    window.scrollTo(0, 0);
  };

  const[handleSubcategoryCards,setHandleSubcategoryCards] = useState();
  const[handleHasCards,setHandleHasCards] = useState();
  const[handleNumOfResult,setHandleNumOfResult] = useState(paginatedCards.length);

  const dispatch = useDispatch();
  const {pageSelected } = useSelector(
    (state) => state.search
  );
   // change page
  const [page, setPage] = useState(pageSelected);
  const [startNumber, setStartNumber] = useState(0);
  const[subCategory,setSubCategory] = useState('all');
  const [selectedOption, setSelectedOption] = useState('other');
 
  //on load
  useEffect(() => {
    // set cards on load
    setAllCards(subCategory,paginatedCards);
    setPage(pageSelected);
  }, [pageSelected]);

  // let quotient = Math.floor(numberOfResults/numFound );
  // let remainder =   numFound%numberOfResults;
  const pageCount = Math.ceil(handleNumOfResult/CARDS_PER_PAGE);
  
 
  const { tAll } = useWebsiteConfigObject();
  const websiteConfigObject = useWebsiteConfig();
  const cardSubcategoryFilterValue = getValue(cardSubcategoryFilter);
  const filteredSubcategories = new Map([
    ...paginatedCards.map(card => (getSelectionValue(card.elements?.cardCategory)) ? [getSelectionValue(card.elements?.subcategory)] : [getValue(card.elements?.cardCategory)])
  ]);

  const filteredSubcategoriesSize = [...filteredSubcategories].length;

  const otherSubCategoryDropdownSelected = (event) => {
    const valueToParse = event.target.value;
    const itemSelected = JSON.parse(valueToParse);
    setSelectedOption(itemSelected);
    setAllCards(itemSelected,paginatedCards); 
    handleChange(null,1);
    setSubCategory(itemSelected);
  }

  if (!cardSubcategoryFilterValue)
    return null;

  
  return<div>
          <ul className="module-subcategory-filter"> 
          <li className="subcategory-item">
            <button id="btnFilterShowAll" className="subcategory-button selected" type="button" onClick={()=>{
                setAllCards('all',paginatedCards)
                handleChange(null,1)
                setSubCategory("all") 
                
            }}>
              <p>{ tAll }</p>
            </button>
          </li>
          {[...filteredSubcategories].slice(0, 4).map(([subCategory], i) => subCategory && (
            <li key={i} className="subcategory-item">
              <button className={"subcategory-button subcategory-button-" + subCategory.split(' ').join('-') }
              type="button" onClick={() => {
                setAllCards(subCategory,paginatedCards); 
                handleChange(null,1);
                setSubCategory(subCategory) 
              }
                }>
                <p>{ capitalizeFirstLetter(subCategory) }</p>
              </button>
            </li>
          ))}
          <li className="subcategory-item">
            {filteredSubcategoriesSize > 4 ?
              <select onChange={otherSubCategoryDropdownSelected} style={{width: `${selectedOption.length <= 5 ? (11*selectedOption.length) + 12 : (8*selectedOption.length) + 10}px`}}>
                  <option value={JSON.stringify(websiteConfigObject.websiteConfig.tOther)} disabled="disabled" selected="selected">{websiteConfigObject.websiteConfig.tOther}</option>
                {[...filteredSubcategories].slice(4, filteredSubcategoriesSize).map(([subCategory], i) => subCategory && (
                  <option key={i} value={JSON.stringify(subCategory)}>{capitalizeFirstLetter(subCategory)}</option>
                ))}
              </select>
              : ""
            }
          </li>
          </ul>
          <div id="mainContent"></div>
           <div className="cards-list" id="cardList">
              {
                  handleSubcategoryCards && handleSubcategoryCards.map((card) => {
                    // const categoryClass = (card.elements?.subcategory?.value?.selection) ? card.elements?.subcategory?.value?.selection : card.elements?.cardCategory?.value
                    let id = card.id[card]
                    return (
                      <div
                        key={id}
                        className={"list-content-page-card-wrapper subcategoryfilter subcategory-filter-" 
                        + subCategory?.split(' ').join('-') }
                      >
                        <ContentListPageCard card={card} type="moodleCard" />
                      </div>
                    )
                  })
                
              }
            </div>
            {handleHasCards && ( // hide the view more button once all the results are loaded
              <div className="view-more-button-container">
                <div className='Pagination'>
                  <Pagination
                    count={pageCount}
                    page={page}
                    color='primary'
                    onChange={(e,v)=>{
                      handleChange(e,v)
                    }}
                  />
                </div>
              </div>
            )
            }
        </div>
};

SubcategoryFilterMoodle.propTypes = {
  cardSubcategoryFilter: contentItemProptype,
  paginatedCards: PropTypes.arrayOf(contentItemProptype),
};

export { SubcategoryFilterMoodle };

// export const setSubcategoryFilterAll = () => {
//   showAllCards();
// }