import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// components
import Video from 'components/reusableComponents/video/Video';

// styles
import './MediaModal.scss';

const MediaModal = (
  {
    showModal,
    onClose,
    video,
    videoPoster,
    videoPosterImage,
    subtitles,
    externalVideo
  },
) => {

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-title"
      centered
      show={showModal}
      animation={false}
      onHide={onClose}
      className="media-modal"
    >
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body__section">
          <Video element={video} externalVideo={externalVideo} videoPoster={videoPoster} videoPosterImage={videoPosterImage} subtitles={subtitles}/>
        </div>
      </Modal.Body>
    </Modal>
  );
};

MediaModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default MediaModal;
