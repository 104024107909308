import { useEffect } from 'react';


const ChatBotDiv = ({jsSnippet}) =>{

    useEffect(() => {
        let parser = new DOMParser();
    
        const doc = parser.parseFromString(jsSnippet, 'text/html');
       
        if(!document.getElementById("ch-script")) {
            document.body.appendChild(doc.firstElementChild);
        
            const src = document.getElementById("ch-script").getAttribute("src");
            document.getElementById("ch-script").remove();
        
            const script = document.createElement("script");
            script.setAttribute("id", "ch-script");
            script.setAttribute("src",src);
            document.body.appendChild(script);
        }
    }, []);

    return null
}

export default ChatBotDiv;