/* eslint-disable no-unused-vars */
import {
  LOGIN_PAGE_PATH,
  LOGOUT_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  FORGOTTEN_PASSWORD_PATH,
  FORGOTTEN_PASSWORD_SUCCESS_PATH,
  PROFILE_PAGE_PATH,
  HOME_PAGE_PATH,
  REGISTRATION_SUCCESS_PATH,
  CONFIRM_EMAIL_PATH,
  CONFIRM_EMAIL_ERROR_PATH,
  CONFIRM_EMAIL_SUCCESS_PATH,
  DELETE_USER_ACCOUNT_SUCCESS_PATH,
  CONFIRM_DELETE_USER_ACCOUNT_PATH,
  CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH,
  CONFIRM_DELETE_USER_ACCOUNT_FAILURE_PATH,
  PROFILE_SUCCESS_PATH,
  CHANGE_PASSWORD_SUCCESS_PATH,
  EXTERNAL_LOGIN_ERROR_PATH,
  SEARCH_RESULTS,
  GLOBAL_LANDING_PAGE
} from 'routes/routeConstants';
import { LoginPage } from 'components/pageTemplates/login/LoginPage';
// import { ProfilePage } from 'components/pageTemplates/profile/ProfilePage';
import ProfileType from 'components/pageTemplates/profile/ProfileType';
import { ResetPassword } from 'components/pageTemplates/login/ResetPassword';
import { ForgottenPassword } from 'components/pageTemplates/login/ForgottenPassword';
import { Logout } from 'components/pageTemplates/login/Logout';
import { LandingPage } from 'components/pages/LandingPage';
import { ConfirmRegisterPage } from 'components/pageTemplates/login/confirm-email-pages/ConfirmRegisterPage';
import { ConfirmDeleteAccountPage } from 'components/pageTemplates/login/confirm-email-pages/ConfirmDeleteAccountPage';
import SearchResultWrapper from '../pages/searchResults/SearchResultWrapper';
import { getUrlCountryPath } from 'utils/urlUtils';

// import { InfographicsPage } from 'components/pageTemplates/infographics';
// import { InfographicsTBPage } from 'components/pageTemplates/inforgraphicstb';

import {
  OWA_AUTH,
  KKI_AUTH,
  DOCCHECK_AUTH,
} from 'utils/authUtils';
import {

  SuccesfulRegistration,
  ConfirmEmailFailure,
  ConfirmEmailSuccess,
  ConfirmDeleteAccountFailure,
  ConfirmDeleteAccountSuccess,
  SuccessfulProfileUpdate,
  SuccesfulForgottenPassword,
  PasswordResetSuccess,
  SuccessfulPasswordChange,
  ExternalLoginError,
  SuccesfulDeleteProfile,
} from './MessagePages';
import GlobalPage from 'pages/globalPage/GlobalPage';

// The fixed routes in the site that are not driven by acoustic
// by default the matches are all exact$


const countryCode = getUrlCountryPath();
const PATH = "/";
let components = GlobalPage;
if(countryCode.length > 0){
  components = LandingPage;
}
const staticRoutes = {
  [KKI_AUTH]: [
    {
      path: PATH,
      Component: components,
    },
    {
      path: LOGIN_PAGE_PATH,
      Component: LoginPage,
    },
    {
      path: LOGOUT_PATH,
      Component: Logout,
    },
    {
      path: FORGOTTEN_PASSWORD_PATH,
      Component: ForgottenPassword,
    },
    {
      path: FORGOTTEN_PASSWORD_SUCCESS_PATH,
      Component: SuccesfulForgottenPassword,
    },
    {
      path: RESET_PASSWORD_PATH,
      Component: ResetPassword,
    },
    {
      path: RESET_PASSWORD_SUCCESS_PATH,
      Component: PasswordResetSuccess,
    },
    {
      path: PROFILE_PAGE_PATH,
      Component: ProfileType,
    },
    {
      path: REGISTRATION_SUCCESS_PATH,
      Component: SuccesfulRegistration,
      exact: false,
    },
    {
      path: CONFIRM_EMAIL_PATH,
      Component: ConfirmRegisterPage,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_PATH,
      Component: ConfirmDeleteAccountPage,
      exact: false,
    },
    {
      path: CONFIRM_EMAIL_ERROR_PATH,
      Component: ConfirmEmailFailure,
      exact: false,
    },
    {
      path: CONFIRM_EMAIL_SUCCESS_PATH,
      Component: ConfirmEmailSuccess,
    },
    {
      path: DELETE_USER_ACCOUNT_SUCCESS_PATH,
      Component: SuccesfulDeleteProfile,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_FAILURE_PATH,
      Component: ConfirmDeleteAccountFailure,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH,
      Component: ConfirmDeleteAccountSuccess,
    },
    {
      path: PROFILE_SUCCESS_PATH,
      Component: SuccessfulProfileUpdate,
    },
    {
      path: CHANGE_PASSWORD_SUCCESS_PATH,
      Component: SuccessfulPasswordChange,
    },
    {
      path: SEARCH_RESULTS,
      Component: SearchResultWrapper
    },
    // {
    //   path: INFOGRAPHICS,
    //   Component: InfographicsPage,
    // },
  ],
  [OWA_AUTH]: [
    {
      path: HOME_PAGE_PATH,
      Component: LandingPage,
    },
    {
      path: LOGIN_PAGE_PATH,
      Component: LoginPage,
    },
    {
      path: PROFILE_PAGE_PATH,
      Component: ProfileType,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_PATH,
      Component: ConfirmDeleteAccountPage,
      exact: false,
    },
    {
      path: DELETE_USER_ACCOUNT_SUCCESS_PATH,
      Component: SuccesfulDeleteProfile,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_FAILURE_PATH,
      Component: ConfirmDeleteAccountFailure,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH,
      Component: ConfirmDeleteAccountSuccess,
    },
    {
      path: PROFILE_SUCCESS_PATH,
      Component: SuccessfulProfileUpdate,
    },
    {
      path: EXTERNAL_LOGIN_ERROR_PATH,
      Component: ExternalLoginError,
    },
    {
      path: LOGOUT_PATH,
      Component: Logout,
    },
    {
      path: SEARCH_RESULTS,
      Component: SearchResultWrapper
    },
    // {
    //   path: INFOGRAPHICS,
    //   Component: InfographicsPage,
    // },
    // {
    //   path: INFOGRAPHICSTB,
    //   Component: InfographicsTBPage,
    // }
  ],
  [DOCCHECK_AUTH]: [
    {
      path: HOME_PAGE_PATH,
      Component: LandingPage,
    },
    {
      path: LOGIN_PAGE_PATH,
      Component: LoginPage,
    },
    {
      path: PROFILE_PAGE_PATH,
      Component: ProfileType,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_PATH,
      Component: ConfirmDeleteAccountPage,
      exact: false,
    },
    {
      path: DELETE_USER_ACCOUNT_SUCCESS_PATH,
      Component: SuccesfulDeleteProfile,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_FAILURE_PATH,
      Component: ConfirmDeleteAccountFailure,
      exact: false,
    },
    {
      path: CONFIRM_DELETE_USER_ACCOUNT_SUCCESS_PATH,
      Component: ConfirmDeleteAccountSuccess,
    },
    {
      path: PROFILE_SUCCESS_PATH,
      Component: SuccessfulProfileUpdate,
    },
    {
      path: EXTERNAL_LOGIN_ERROR_PATH,
      Component: ExternalLoginError,
    },
    {
      path: LOGOUT_PATH,
      Component: Logout,
    },
    {
      path: SEARCH_RESULTS,
      Component: SearchResultWrapper
    },
    // {
    //   path: INFOGRAPHICS,
    //   Component: InfographicsPage,
    // },
    // {
    //   path: INFOGRAPHICSTB,
    //   Component: InfographicsTBPage,
    // }
  ],
};



export { staticRoutes };
