import { getUrlCountryPath, getLanguageCode } from 'utils/urlUtils';
import { OrdinalFormat } from 'utils/ordinalFormatting';

const getWebinarDate = (webinarDate, separator = ' ') => {
  if (webinarDate?.value) {
    let newDate = new Date(webinarDate?.value);
    // let date = newDate.getUTCDate()
    let date = parseInt(newDate.toLocaleString('en-GB', {
      day: 'numeric',
    }));
    const dayWithSuffix = new OrdinalFormat(getLanguageCode()).withOrdinalSuffix(date);
    // let dateString = date + getDateOrdinal(date);
    let dateString = dayWithSuffix;
    const month1 = newDate.toLocaleString(getUrlCountryPath(), {
      month: 'long',
    });
    let year = newDate.getUTCFullYear();
    return `${dateString}${separator}${month1}${separator}${year}`;
  }
};

const getCalendarUrl = (calendarUrl, name, webinarDetails, externalId, startDate, endDate, webinarUrl, extLinkLabel, internalLinkLabel) => {
  const internalURL = internalLinkLabel ? internalLinkLabel : "Webinar URL";
  const externalUrl = extLinkLabel ? extLinkLabel : "Zoom Link";
  let detail = webinarDetails.replace("{hubLink}", internalURL.link(window.location.href));
  detail = detail.replace("{externalLink}", externalUrl.link(webinarUrl));
  if (calendarUrl.includes("{webinarname}")) { //for outlook
    calendarUrl = calendarUrl.replace('{webinarname}', encodeURIComponent(name));
  calendarUrl = calendarUrl.replace('{startdatetime}', encodeURIComponent(startDate));
  calendarUrl = calendarUrl.replace('{enddatetime}', encodeURIComponent(endDate));
    calendarUrl = calendarUrl.replace('{itemid}', encodeURIComponent(externalId));
    calendarUrl = calendarUrl.replace('{webinardetails}', `${(detail)}`);
  }
  else { // for gmail
    let newStartDate = startDate.replace(/[^a-zA-Z0-9 ]/g, "");
    let newEndDate = endDate.replace(/[^a-zA-Z0-9 ]/g, "");
    calendarUrl = calendarUrl.replace('{webinar+name}', encodeURIComponent(name));
  calendarUrl = calendarUrl.replace('{startdatetime}', encodeURIComponent(newStartDate));
  calendarUrl = calendarUrl.replace('{enddatetime}', encodeURIComponent(newEndDate));
  calendarUrl = calendarUrl.replace('{itemid}', encodeURIComponent(externalId));
  calendarUrl = calendarUrl.replace('{Webinar+details}', encodeURIComponent(detail));
  }
  
  return calendarUrl;
}

const getWebinarDateWithoutOrdinal = (webinarDate, separator = ' ') => {
  if (webinarDate) {
    let newDate = new Date(webinarDate?.value);
    let date = newDate.getUTCDate();
    const month1 = newDate.toLocaleString(getUrlCountryPath(), {
      month: 'long',
    });
    let year = newDate.getUTCFullYear();
    return `${date}${separator}${month1}${separator}${year}`;
  }
};

const getWebinarDate1 = (webinarDate, separator = ' ') => {
  if (webinarDate) {
    let newDate = new Date(webinarDate?.value);
    let date = newDate.getUTCDate();
    const month1 = newDate.toLocaleString(getUrlCountryPath(), {
      month: 'long',
    });
    return `${month1}${separator}${date}`;
  }
};

const getWebinarDateWithLocal = (webinarDate, separator = ' ') => {
  if (webinarDate) {
    let newDate = new Date(webinarDate?.value);
    return new Intl.DateTimeFormat(getUrlCountryPath(), {
      day: "2-digit",
      month: "long",
    }).format(newDate).split(" ").join(separator)
  }
}

const getWebinarTime = (webinarDate) => {
  if (webinarDate) {
    let time = new Date(webinarDate?.value).toLocaleString('en-GB', {
      // timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    });
    return time;
  }
};

const getWebinarTime1 = (webinarDate) => {
  if (webinarDate) {
    let time = new Date(webinarDate?.value).toLocaleString('en-GB', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    });

    let hours = new Date(webinarDate?.value).toLocaleString('en-GB', {
      timeZone: 'UTC',
      hour: 'numeric',
    });

    let ampm = hours >= 12 ? 'pm' : 'am';
    let full_time = time + ' ' + ampm;
    return full_time;
  }
};

const getDateOrdinal = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const getCourseDate = (courseDate, separator = '/') => {
  if (courseDate) {
    let newDate = new Date(courseDate);
    let date = newDate.getUTCDate();
    let month = newDate.getUTCMonth()+1;
    const year = newDate.toLocaleString(getUrlCountryPath(), {
      year: 'numeric',
    });
    return `${date}${separator}${month}${separator}${year}`;
  }
};

export {
  getWebinarDate,
  getWebinarTime,
  getDateOrdinal,
  getWebinarDate1,
  getWebinarTime1,
  getCourseDate,
  getWebinarDateWithoutOrdinal,
  getCalendarUrl,
  getWebinarDateWithLocal
};
