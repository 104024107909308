import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { acceptPromotionalConsent } from 'redux/authentication/slice'
import { ChevronButton, LINK, BUTTON } from 'components/shared/ChevronButton'
import { HOME_PAGE_PATH } from 'routes/routeConstants'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import './ContentGate.scss'
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import { extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics'

const PromtionalContentGate = (props) => {
    const { pageTitle, content } = props;
    let gaPageTitle = pageTitle;
    if(pageTitle){
        gaPageTitle = pageTitle.concat(" ", " – Kyowa Kirin Hub");
    }
    let pathName = window.location.pathname;
    const { trackPageView } = useGoogleAnalytics();

    const location = useLocation()

    const dispatch = useDispatch()

    const {
        tPromotionalContentGateTitle,
        tPromotionalContentGateSubContext,
        tPromotionalContentGateCtaAText,
        tPromotionalContentGateCtaASmallPrintText,
        tPromotionalContentGateCtaBText,
        tPromotionalContentGateCtaBSmallPrintText,
    } = useWebsiteConfigObject()
    const { taxonomyFields } = useSelector((state) => state.ga);
    const taxonomy = extractTaxonomyValues(taxonomyFields, content?.elements?.taxonomy?.value);

    const returnLocation = location.state ? location?.state?.global.prevPath : HOME_PAGE_PATH

    return (
        <div className='ContentGate ContentGate--Promotional'>
            <h3 className='ContentGate_Title'>{tPromotionalContentGateTitle}</h3>
            <p className='ContentGate_Text'>{tPromotionalContentGateSubContext}</p>
            <div className='ContentGate_Footer'>
                <div className='ContentGate_CTA_Wrapper'>
                    <ChevronButton
                        className='ContentGate_CTA'
                        type={BUTTON}
                        text={tPromotionalContentGateCtaAText}
                        variant='paywall-btn'
                        chevron='down'
                        vvClickAttr='click'
                        vvWaitAttr='5000'
                        onClick={() => {
                            if(pageTitle && pathName){
                                setTimeout(() => {
                                    trackPageView(pathName, gaPageTitle, false, taxonomy);
                                }, 500);
                            }
                            dispatch(acceptPromotionalConsent(true))
                        }}
                    />
                    <div className='ContentGate_CTA_Smallprint'>{tPromotionalContentGateCtaASmallPrintText}</div>
                </div>
                <div className='ContentGate_CTA_Wrapper'>
                    <ChevronButton className='ContentGate_CTA' type={LINK} internalHref={returnLocation} text={tPromotionalContentGateCtaBText} variant='paywall-btn' chevron='right' />
                    <div className='ContentGate_CTA_Smallprint'>{tPromotionalContentGateCtaBSmallPrintText}</div>
                </div>
            </div>
        </div>
    )
}

const ContentGateWrapper = ({ type, pageTitle, content }) => {
    const {
        tToastBarText,
        adverseReportingUrl,
        tAdverseReportingLinkText,
        adverseReportingLinkLogo,
    } = useWebsiteConfigObject();
    const blockModifierClass = tToastBarText?.value || adverseReportingUrl || tAdverseReportingLinkText || adverseReportingLinkLogo?.value ? " ContentGate--toastbar-present" : ""

    return <div className={`ContentGate_Wrapper${blockModifierClass}`}>{type === 'promotional' && <PromtionalContentGate pageTitle={pageTitle} content={content} />}</div>
}

export default ContentGateWrapper
