import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
// import { useHistory } from 'react-router-dom';
// import { dynamicLinkBehaviour } from 'utils/hyperlinkUtils';
import useShortcodeParser from 'hooks/useShortcodeParser';
import useCookieListParser from 'hooks/useCookieListParser';
// import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { truncateContent } from 'utils/contentUtils';
// import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import './RichText.scss';
import { withEdetailerParser } from 'hooks/useEdetailerParser'
import Link from 'components/reusableComponents/link/Link' 
import { useSelector } from 'react-redux';
import { getAssetUrl } from 'api/acousticApi';


const RichText = ({ data, className, style, truncate, fromWebconfig, pullQuoteBackground, trackLink }) => {

  const { countryLibrary } = useSelector(state => state.ui);

  //pull quote background style
  const quoteStyle = {
    backgroundColor: pullQuoteBackground ? pullQuoteBackground?.value : "#faf6f4"
  };
  
  const transformHTMLToReactElement = (node, index) => {
    if (node.type === 'tag' && node.name === 'a') {
      if (node?.attribs?.href && !node?.attribs?.href.includes('mailto')) {
        if(trackLink) { 
          const handleClick = trackLink(node.attribs?.href, node.children);
          if(handleClick) {
            return <Link href={node.attribs?.href} key={index} onClick={() => handleClick()}>
              {
                node.children.map( (child, key) => convertNodeToElement( child, key, transformHTMLToReactElement ) )
              }
            </Link>
          }
        }

        return <Link href = { node.attribs?.href } key = {index}>
            {
                node.children.map( (child, key) => convertNodeToElement( child, key, transformHTMLToReactElement ) )
            }
        </Link>
      }
    }

    //new if statement to check if tag is blocquote, then apply the style quoteStyle defined above 
    if (node.type === 'tag' && node.name === 'blockquote') {
      return <blockquote style={quoteStyle} key = {index} >
            {
                node.children.map( (child, key) => convertNodeToElement( child, key, transformHTMLToReactElement ) )
            }
        </blockquote>
    }

    //fix for img tag
    if (node.type === 'tag' && node.name === 'img') {
      node.attribs['src'] = getAssetUrl({ url: node.attribs['src'] })
    }

    return convertNodeToElement( node, index, transformHTMLToReactElement )
  }
//   const { trackOutboundLink } = useGoogleAnalytics();
//   const history = useHistory();

//   const { tYouAreAboutToLeave } = useWebsiteConfigObject();

    let { value } = data || {}
    if( fromWebconfig === true ) {
        value = data;
    }
    let text = useShortcodeParser(value)
    text = useCookieListParser(text);
    
  // Handles links inside rich text fields
//   const handleRichTextLink = (evt) => {
//     const href = evt?.target?.href;
//     const trackAnalyticsEvent = (url) => {
//       trackOutboundLink(href);
//     };

//     if (!href) return null;

//     evt.preventDefault();
//     evt.stopPropagation();

//     const message =
//       tYouAreAboutToLeave ||
//       'You are about to leave the Kyowa Kirin site. Are you sure you want to continue?';

//     dynamicLinkBehaviour({ event: evt, history, message, trackAnalyticsEvent });
//   };

  if (!data || !text) {
    return null;
  }

  
  text = countryLibrary?.title ? text?.replaceAll('{country}', countryLibrary?.title.split('-')[0].trim()) : text

  return (
    <div
      className={`RichText ${className ? className : ''}`}
      style={style}
    >
      {ReactHtmlParser(
        truncate ? truncateContent({ input: text, charLimit: truncate }) : text
      , { transform: transformHTMLToReactElement })}
    </div>
  );
};

export default withEdetailerParser(RichText);
