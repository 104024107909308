import React from 'react';
import dayjs from 'dayjs';

// config
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent';

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { AuthorSection } from 'components/content/article/AuthorSection';
import { ArticleContentsList } from 'components/content/article/ArticleContentsList';
import Video from 'components/reusableComponents/video/Video';
import { ArticleSection } from 'components/content/article/ArticleSection';
import { CMSTextCard } from 'components/content/cards/CMSTextCard';
import { BannerImage } from 'components/content/BannerImage';
import { ApprovalCode } from 'components/content/ApprovalCode';
import { References } from 'components/content/References';
import './ArticlePage.scss';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';

const ArticlePage = ({ content }) => {
  const {
    title,
    subtitle,
    headerLogo,
    headerBackgroundImage,
    headerTitleHexColour,
    headerBackgroundHexColour,
    authors,
    cards,
    cardsTitle,
    date,
    section1Title,
    section1BodyCopy,
    section2Title,
    section2BodyCopy,
    section3Title,
    section3BodyCopy,
    section4Title,
    section4BodyCopy,
    section5Title,
    section5BodyCopy,
    relatedContent,
    bannerImage,
    veevaApprovalCode,
    references,
    video,
    webvttChaptersFile,
    videoTitle,
    subtitles,
    externalVideoLink
  } = content.elements;

  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  const { tPublicationDate } = useWebsiteConfigObject();
  const formattedDate =
    date && date.value ? dayjs(date.value).format('D MMM YYYY') : null;
  const dateStr = formattedDate
    ? `${tPublicationDate}, ${formattedDate}`
    : null;
  const cardElements = cards ? cards.values : null;
  const hasReferences = references && references.value;

  return (
    <section role='main' className='article-page-component'>
      <MainAreaTitleSection
        title={title && title.value}
        category={subtitle && subtitle.value}
        logo={headerLogo}
        backgroundImage={headerBackgroundImage}
        backgroundHexColour={headerBackgroundHexColour}
        fontHexColour={headerTitleHexColour}
      />
      <section className='content-section'>
        <div className='container'>
          <div className='article-page'>
            <div className='column-left'>
              <AuthorSection authors={authors} />
              <p className='date'>{dateStr}</p>
            </div>
            <article className='article-page-content'>
              <ArticleSection
                titleElement={section1Title}
                bodyElement={section1BodyCopy}
              />
              <ArticleSection
                titleElement={section2Title}
                bodyElement={section2BodyCopy}
              />
              <Video
                element={video}
                webvttChaptersFile={webvttChaptersFile}
                title={videoTitle}
                subtitles={subtitles}
                externalVideo={externalVideoLink}
              />
              <ArticleSection
                titleElement={section3Title}
                bodyElement={section3BodyCopy}
              />
              <ArticleSection
                titleElement={section4Title}
                bodyElement={section4BodyCopy}
              />
              <ArticleSection
                titleElement={section5Title}
                bodyElement={section5BodyCopy}
              />
            </article>
            <div className='column-right'>
              <ArticleContentsList
                titleElements={[
                  section1Title,
                  section2Title,
                  section3Title,
                  section4Title,
                  section5Title,
                ]}
              />
            </div>
          </div>
        </div>
      </section>
      <section className='related-content-section'>
        <div className='container'>
          <h2 className='related-content-header'>
            {cardsTitle && cardsTitle.value}
          </h2>
          <ul className='related-content-list'>
            {cardElements &&
              cardElements.map((card) => (
                <li key={card.id}>
                  <CMSTextCard element={card} />
                </li>
              ))}
          </ul>
        </div>
      </section>
      {relatedContent && (
        <div className='container'>
          <RelatedContent
            content={relatedContent}
            itemType='cards'
            alternateBackground={true}
          />
        </div>
      )}
      {bannerImage && <BannerImage image={bannerImage} />}
      <ApprovalCode code={veevaApprovalCode} />
      {adverseEventsComplianceText?.value && (
        <div className='container'>
          {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} removeBottomSpacing={true} />}
        </div>
      )}
      {hasReferences && (
        <section className='references-section'>
          <div className='container'>
            <References references={references} />
          </div>
        </section>
      )}
    </section>
  );
};

export default ArticlePage;
