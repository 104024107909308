import React from 'react'
import { getImgAttr } from 'api/acousticApi/index'
import { getValue } from 'utils/contentUtils'
import { useScreenWidth, SCREEN_WIDTH_MOBILE } from 'components/layout/ScreenWidthContext'
import Link from 'components/reusableComponents/link/Link'
import './BannerImage.scss'

const BannerImage = ({ image, contain }) => {
    const bannerImageElement = image ? image.value : null
    const { currentWidthRanges } = useScreenWidth()

    if (!bannerImageElement) {
        return null
    }
    const { bannerMobile, bannerDesktop, bannerHyperlinkUrl } = bannerImageElement.elements
    const linkURL = getValue(bannerHyperlinkUrl)
    const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE)
    const currentBannerImage = isMobile ? getImgAttr(bannerMobile) : getImgAttr(bannerDesktop)

    return (
        currentBannerImage && (
            <section className='full-width-banner'>
                {(contain && (
                    <div className='container'>
                        {linkURL ? (
                            <Link className='full-width-banner__link' href={linkURL}>
                                <img src={currentBannerImage.src} alt={currentBannerImage.alt} className='full-width-banner__image' />
                            </Link>
                        ) : (
                            <img src={currentBannerImage.src} alt={currentBannerImage.alt} className='full-width-banner__image' />
                        )}
                    </div>
                )) || (
                    <>
                        {linkURL ? (
                            <Link className='full-width-banner__link' href={linkURL}>
                                <img src={currentBannerImage.src} alt={currentBannerImage.alt} className='full-width-banner__image' />
                            </Link>
                        ) : (
                            <img src={currentBannerImage.src} alt={currentBannerImage.alt} className='full-width-banner__image' />
                        )}
                    </>
                )}
            </section>
        )
    )
}

export { BannerImage }
