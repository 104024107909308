// Group arrays by Key
export const groupBy = (arr, key) => {
  return (arr || []).reduce(
    (acc, x = {}) => ({
      ...acc,
      [x[key]]: [...(acc[x[key]] || []), x],
    }),
    {}
  );
};

export const sortRandomly = (array) => array.sort(() => Math.random() - 0.5);

export const sortByLastModifiedDate = (sourceArray) => {
  sourceArray.sort(function (a, b) {
    // console.log(new Date(a.lastModified));
    return (
      new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()
    );
  });
  return sourceArray;
}
