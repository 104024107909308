import React from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';
import {
  GenericError,
} from 'components/shared/form/InlineError';
import './Checkbox.scss';




const Checkbox = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
  requiredLabel
}) => {
  const {
    label,
    id,
    mandatory,
    showAsterix,
    size = 100,
  } = field;
  const value = formValues[id];
  const touched = touchedFields[id];
  const inlineError = inlineErrors[id];
  const viewableError = GenericError && touched;


  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setTouchedFields((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];


  return (
    <div
      className={fieldClassNames.join(' ')}
    >
      <input
        className={`checkbox checkbox-${value ? 'checked' : 'unchecked'} custom-focus`}
        required={mandatory}
        id={id}
        value={value}
        onChange={onChange}
        type="checkbox"
        checked={value}
      />
      <div className="field-meta-data">
        {
          label && (
            <label
              htmlFor={id}
            >
              {label}
              {
                mandatory && (
                  <span
                    className="mandatory-astrix"
                  >
                    {
                      showAsterix ? (
                        <>*</>
                      ) : (
                        <></>
                      )
                    }
                  </span>
                )
              }
            </label>
          )
        }
        {
          viewableError && (
            <GenericError
              GenericErrorText={requiredLabel || inlineError}
            />
          )
        }
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
  formValues: formValuesPropTypes,
  setFormValues: PropTypes.func,
  inlineErrors: inlineErrorsPropTypes,
  touchedFields: touchedPropTypes,
  setTouchedFields: PropTypes.func,
};


export {
  Checkbox,
};


