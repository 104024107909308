import React, { useEffect, useState, useRef } from 'react';
import uuid from 'react-uuid';
import { CardCarousel } from 'components/content/CardCarousel';
import { HomepageCard } from 'components/content/homepage/HomepageCard';
import { PromoKit } from 'components/shared/PromoKit';
import { PageHero } from 'components/shared/PageHero';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { References } from 'components/content/References';
import { ApprovalCode } from 'components/content/ApprovalCode';
import { PromoBanner } from 'components/layout/PromoBanner';
import { getInternalUrl } from 'utils/contentUtils';
import './HomePage.scss';
import { sortByLastModifiedDate, sortRandomly } from 'utils/arrayUtils';
import { AppConfig } from 'AppConfig';
import FormBuilder from 'modules/formBuilder/FormBuilder';
import { getAssetUrl, getImgAttr } from 'api/acousticApi';
import ColumnSection from './inforgraphicstb/components/ColumnSection';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'
import { useSelector } from 'react-redux';
import { CloseIconV2 } from 'components/shared/SvgIcons';

import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
} from 'components/layout/ScreenWidthContext';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
  Module_Column_Container
} from '../../api/acousticApi/ContentTypesInfographics';
import RichText from 'components/reusableComponents/richText/RichText';

/*
  Template for the Landing page
  Figma design here https://www.figma.com/file/sseXCDDCoBI1Sge6080EpI/WF_KK?node-id=829%3A3327
  0.0 Landing Page/ UK
*/

const HomePage = ({ content }) => {
  const {
    title,
    headerText,
    carsousel,
    sectionTitle,
    section1Icon,
    section1Subtitle,
    section1PageUrlSlug,
    section1LeftColumnTitle,
    section1LeftColumnText,
    section1ParagraphCards,
    section2Icon,
    section2Subtitle,
    section2PageUrlSlug,
    section2LeftColumnTitle,
    section2LeftColumnText,
    section2ParagraphCards,
    contentSection,
    callout,
    aboutUsSectionHeader,
    aboutUsSectionText,
    references,
    carouselModule,
    basePage,
    therapyAreaQuickAccessText,
    veevaApprovalCode
  } = content.elements;
  const { trackGTMEvent } = useGoogleAnalytics();
  const { redesign } = useSelector(state => state.ui);

  const therapyAreaRef = useRef(null);
  const [therapyAreaHelper, setTherapyAreaHelper] = useState(true);

  let headerImage = getAssetUrl(content.elements?.headerImage);
  const { pageBackgroundImage, pageBackgroundImagemobile } = basePage?.value || {};
  
  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  const hasReferences = references && references.value;
  const { currentWidthRanges } = useScreenWidth();
  const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
  let bgImage = {
    backgroundImage: `url(${pageBackgroundImage?.url ? AppConfig.acousticApi.domain + pageBackgroundImage?.url : null})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '180px',
    backgroundPositionX: 'center',
    backgroundSize: "cover",
  }
  let bgImageMobile = {
    backgroundImage: `url(${pageBackgroundImagemobile?.url ? AppConfig.acousticApi.domain + pageBackgroundImagemobile?.url : null})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '180px',
    backgroundPositionX: 'center',
    backgroundSize: "cover",
  }
  const carouselContent = (array) => {
    return carouselModule?.value?.elements?.carouselOrder?.value?.selection == "random" ? sortRandomly(array) : carouselModule?.value?.elements?.carouselOrder?.value?.selection == "lastModifiedDate" ? sortByLastModifiedDate(array) : array
  }
  const settings = {
    infinite: carouselModule?.value?.elements?.automateCarousel?.value ? true : false,
    dots: true,
    arrows: true,
    slidesToShow: 3,
    autoplay: isSmall ? false : carouselModule?.value?.elements?.automateCarousel?.value ? true : false,
    autoplaySpeed: carouselModule?.value?.elements?.automationInterval?.value ? parseFloat(carouselModule?.value?.elements?.automationInterval?.value) * 1000 : 2000,
    responsive: [
      {
        breakpoint: 1365,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 1023,
        settings: { slidesToShow: 1 },
      },
    ],
  }
  useEffect(() => {
    const handleScroll = event => {
      const { offsetTop } = therapyAreaRef.current
      if(window.scrollY > offsetTop){
        setTherapyAreaHelper(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTherapyArea = () => {
    therapyAreaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    
    trackGTMEvent('therapyarea_scroll_mobile', 'mobile_scroll');
    setTimeout(()=>{
      setTherapyAreaHelper(false);
    },500);
  }

  const alt = getImgAttr(content?.elements?.headerImage).alt;

  return (
    <section role='main' className='home-page'>
      <section className='home-page-header'>

        <div className='container container-hp'>
          {redesign && <div className='hero-image-container'><img className={`hero-image ${headerImage ? 'hero-image--headerImage' : ''}`} src={`${headerImage ? headerImage : process.env.PUBLIC_URL + '/hero-image.png'}`} alt={alt ? alt : 'Hero Image'} /></div>}
          <PageHero title={title} headerText={headerText} />

        </div>
      </section>

      <div className="background-container" style={isSmall ? bgImageMobile : bgImage} >

        <section className="carousel-section">
          <div className="container">
            {carouselModule?.value?.elements?.item?.values &&
              <div style={{ minHeight: '414px' }}>
                <div className='home-page-carousel'>
                  <CardCarousel
                    gaTrackingContentType='slider-homepage'
                    autoplay={settings?.autoplay}
                    autoplaySpeed={settings?.autoplaySpeed}
                    cards={carouselContent([...carouselModule?.value?.elements?.item?.values])?.map((elementContent) => (
                      <HomepageCard element={elementContent} />
                    ))}
                  />
                </div>
              </div>
            }
            {!carouselModule?.value?.elements?.item?.values && carsousel?.values && carsousel?.values?.length > 0 && (
              <div style={{ minHeight: '414px' }}>
                <div className='home-page-carousel'>
                  <CardCarousel
                    gaTrackingContentType='slider-homepage'
                    autoplay={settings?.autoplay}
                    autoplaySpeed={settings?.autoplaySpeed}
                    cards={carouselContent([...carsousel.values])?.map((elementContent) => (
                      <HomepageCard element={elementContent} />
                    ))}
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        <section className='home-page-secondary'>
          <div className='container' ref={therapyAreaRef}>
            {sectionTitle && sectionTitle.value && (
              <h2 className='section-title'>{sectionTitle.value}</h2>
            )}
            {section1Subtitle?.value && (
              <PromoKit
                icon={section1Icon}
                subtitle={section1Subtitle}
                leftColTitle={section1LeftColumnTitle}
                titlePage={section1PageUrlSlug ? section1PageUrlSlug.value : null}
                leftColContent={section1LeftColumnText}
                resources={section1ParagraphCards}
                startOpen
              />
            )}

            {section2Subtitle?.value && (
              <PromoKit
                icon={section2Icon}
                subtitle={section2Subtitle}
                leftColTitle={section2LeftColumnTitle}
                titlePage={section2PageUrlSlug ? section2PageUrlSlug.value : null}
                leftColContent={section2LeftColumnText}
                resources={section2ParagraphCards}
                startOpen={false}
              />
            )}

            {contentSection?.values?.map((section) => {
              //this is a process to slowly move "page" out of acoustic this will improve page speed
              if (section?.type === "Component – Form Builder") {
                return (
                  <FormBuilder element={section} />
                )
              }

              if (section?.typeId === Module_Column_Container) {
                return (<ColumnSection
                  elements={section}
                  hp={true}
                />)
              }
              const linkTo = section?.elements?.link?.linkURL
                ? section?.elements?.link?.linkURL
                : getInternalUrl(section?.elements?.page);

              return (
                <PromoKit
                  icon={section?.elements?.icon}
                  subtitle={section?.elements?.subtitle}
                  leftColTitle={section?.elements?.leftColumnTitle}
                  titlePage={linkTo}
                  leftColContent={section?.elements?.leftColumnText}
                  resources={section?.elements?.paragraphCards}
                  startOpen={section?.elements?.open?.value}
                  isCollapsable={false}
                  key={uuid()}
                />
              );
            })}
            {callout && <PromoBanner content={callout} />}
            {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} removeBottomSpacing={true} />}
            {
              (aboutUsSectionHeader?.value && aboutUsSectionText?.value) && (
                <section className="aboutUs-section" id="about-us">
                    <h2 className="aboutUs-section_title">{aboutUsSectionHeader?.value}</h2>
                    <RichText data={aboutUsSectionText} />
                </section>
              )
            }
            {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}   
            {hasReferences && (
              <section className='references-section'>
                <References references={references} />
              </section>
            )}
          </div>
        </section>
      </div>
      
      {therapyAreaQuickAccessText?.value && therapyAreaHelper && 
        <div className='home-page__therapy-quick-access'>
          <div className='quick-access-contents'>
            <p className='quick-access-text' onClick={() => {scrollToTherapyArea()}}>{therapyAreaQuickAccessText?.value}</p>
            <CloseIconV2 className="quick-access-close" onClick={() => setTherapyAreaHelper(false)}/>
          </div>
        </div>
      }
    </section>
  );
};

HomePage.propTypes = {
  content: contentItemProptype,
};

export { HomePage };
