import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { ContentGateWrapper as ContentGateWrapperLimitedAccess } from 'components/layout/ContentGateWrapperLimitedAccess';
import {
  useWebsiteConfigObject,
} from 'websiteConfig/useWebsiteConfig';

const EncapsulationLayout = (props) => {
  const { encapsulationFeature, accessRules } = useSelector((state) => state.ui);

  const { data } = useSelector((state) => state.authentication);
  const { categorie:categoryValue, specialite1, cegedim_security_level } = data?.extendedProfile?.owaValues?.attributes || {}

  const { contentId } = props;
  const { content } = usePageContent(contentId) || {};
  const { functional, message } = content?.elements?.taxonomy?.value || {};
  const { activateAccessLevelManagement } = content?.elements?.basePage?.value || {};
  const { categories:branding } = functional?.value?.branding || [];
  const { categories:brand } = message?.value?.brand || [];
  const {
    limitedProfileDescription,
    limitedProfileTitle,
    limitedProfileLabel,
    limitedProfileLink,
  } = useWebsiteConfigObject();

  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const accessBypass = urlParams.get('acc3ss-byp4ss');

  if(['tru3', 'true'].includes(accessBypass)) {
    return null;
  }

  //check if page is branded (promo)
  if((activateAccessLevelManagement?.value && encapsulationFeature) && !specialite1){
    return (
      <>
        {(limitedProfileDescription?.value ||
          limitedProfileLabel ||
          limitedProfileLink ||
          limitedProfileTitle?.value) && <ContentGateWrapperLimitedAccess type="encapsulated"/>}
      </>
    );
  }
  if(!brand || !specialite1 || !activateAccessLevelManagement?.value || !encapsulationFeature){
    return null;
  }
  let categorie = [];
  if(categoryValue){
    categorie = categoryValue;
  } 

  if(activateAccessLevelManagement?.value && encapsulationFeature && !(cegedim_security_level.includes("2") || cegedim_security_level.includes("4"))){
    return (
      <>
        {(limitedProfileDescription?.value ||
          limitedProfileLabel ||
          limitedProfileLink ||
          limitedProfileTitle?.value) && <ContentGateWrapperLimitedAccess type="encapsulated"/>}
      </>
    );
  }
  //check if access mgt is activated at page level

let taxonomy = brand[0];
let splittedValues = taxonomy.split("/");
let brandName = splittedValues[splittedValues.length -1]; //take last value of array of splitted values
  if(activateAccessLevelManagement?.value && encapsulationFeature && (cegedim_security_level.includes("2") || cegedim_security_level.includes("4"))){
    if(branding && branding.includes("KKI_Taxonomy_v2/Functional/Branding/Branded (product)")) {
      if(accessRules && accessRules.length > 0) {
        const rulesFilteredByPromo = accessRules.filter(rule => {
          return rule?.elements?.isPromo?.value;
        });
        const rulesFilteredByBrand = rulesFilteredByPromo.filter(rule => {
          return rule?.elements?.brandPicker?.categories[0].includes(brandName);
        });
        
        const rulesFilteredBySpecialite = rulesFilteredByBrand.filter(rule => {
          const rulesFilteredBySpecialiteSelection = rule?.elements?.speciality?.values.filter(specialitiesInAccessRules => {
            if(specialite1){
              return specialitiesInAccessRules?.selection.includes(specialite1[0]);
            }
          });
          if(rulesFilteredBySpecialiteSelection.length !== 0){
            return rule;
          }
        });
        if(rulesFilteredBySpecialite.length === 2){
          return null;
        }
        const rulesFilteredByCategory = rulesFilteredBySpecialite.filter(rule => {
          let accessRulesSpeciality = rule?.elements?.category?.value?.selection;
          if(accessRulesSpeciality.includes("NOT(")){
            return !rule?.elements?.category?.value?.selection.includes(categorie[0]);
          } else {
            return rule?.elements?.category?.value?.selection.includes(categorie[0]);
          }
        });

        if(rulesFilteredByCategory.length > 0){
          return null;
        }
      }
    } else {
      if(accessRules && accessRules.length > 0) {
        const rulesFilteredByNonPromo = accessRules.filter(rule => {
          return !rule?.elements?.isPromo?.value;
        });
        const rulesFilteredByBrand = rulesFilteredByNonPromo.filter(rule => {
          return rule?.elements?.brandPicker?.categories[0].includes(brandName);
        });
        const rulesFilteredBySpecialite = rulesFilteredByBrand.filter(rule => {
          const rulesFilteredBySpecialiteSelection = rule?.elements?.speciality?.values.filter(specialitiesInAccessRules => {
            if(specialite1){
              return specialitiesInAccessRules?.selection.includes(specialite1[0]);
            }
          });
          if(rulesFilteredBySpecialiteSelection.length !== 0){
            return rule;
          }
        });

        if(rulesFilteredBySpecialite.length === 2){
          return null;
        }

        const rulesFilteredByCategory = rulesFilteredBySpecialite.filter(rule => {
          let accessRulesSpeciality = rule?.elements?.category?.value?.selection;
          if(accessRulesSpeciality.includes("NOT(")){
            return !rule?.elements?.category?.value?.selection.includes(categorie[0]);
          } else {
            return rule?.elements?.category?.value?.selection.includes(categorie[0]);
          }
        });

        if(rulesFilteredByCategory.length > 0){
          return null;
        }
      }
    }
  }
  
  return (
    <>
      {(limitedProfileDescription?.value ||
        limitedProfileLabel ||
        limitedProfileLink ||
        limitedProfileTitle?.value) && <ContentGateWrapperLimitedAccess type="encapsulated"/>}
    </>
  );
};

export { EncapsulationLayout };
