import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import PropTypes from 'prop-types';
import { Ellipsis } from 'components/shared/SvgIcons';
import './ViewMoreButton.scss';


const ViewMoreButton = ({
  onClick,
  className,
}) => {
  const { tViewMore } = useWebsiteConfigObject();
  return (
    <button
      type="button"
      onClick={onClick}
      className={`view-more-button-component custom-focus ${className ? className : ''}`}
    >
      <p>{tViewMore}</p>
      <div className="view-more-button-component-svg-container">
        <Ellipsis />
      </div>
    </button>
  );
};


ViewMoreButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};


export {
  ViewMoreButton,
};



