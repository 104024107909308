import React, {useState, useEffect, useRef} from "react";
import ReactHtmlParser from 'react-html-parser';
import { toggleOverlay } from './../helper';
import RichText from 'components/reusableComponents/richText/RichText';
import Link from 'components/reusableComponents/link/Link';
import ImageMapper from 'react-img-mapper';
import yearsArrow from '../../../../../assets/images/infographics-assets/brain/arrow-year-brain.png';
import close from '../../../../../assets/images/infographics-assets/circle-close-icon.svg';

import TimePopupsWrap from '../timepopups/timepopupswrap';

import './InfographicsDiagram.scss';
import { AppConfig } from 'AppConfig';

const InfographicsDiagram = (props) => {
    const {
		filterHeading,
		filterOptions,
		defaultImage,
        onSelectCheckboxFunction,
        enableSwapImageOnHover,
        enableSwapToDefaultImageOnLeave,
        defaultImageMap,	
        timedPopups,
        filterFontColour
	} = props.elements;
    const areasText= props?.areas;
    let imageSource = AppConfig.acousticApi.domain;
    let onLoadDefaultImageMap = defaultImageMap?.value ?? " ";

    const {
		areaSpecificContents,
		imageMapWithCoordinates,
		imagePicker
	} = onLoadDefaultImageMap;
    let onSelectFilterAreaArray = new Array();
    let defaultAreaArray = new Array();
    let areas = new Array();

    const [imageMapper, setImageMapper] = useState({infographicsName, areas});
    const [popupOpen, setPopupOpen] = useState(false);
    const [width, setWidth] = useState(0);
    const [popupTitle, setPopupTitle] = useState();
    const [popupDetail, setPopupDetail] = useState();
    const [popupLinkTarget, setPopupLinkTarget] = useState();
    const [popupLinkText, setPopupLinkText] = useState();
    const [popupLinkDescription, setPopupLinkDescription] = useState();
    const [popupAccordionId,  setPopupAccordionId] = useState();
    const [popupAccordionLinkLabel, setPopupAccordionLinkLabel] = useState();
    const [brainImage, setBrainImage] = useState(imageSource +""+ imagePicker?.url);
    const [defaultArrayImageMap, setDefaultArrayImageMap] = useState(defaultAreaArray)
    const [selectedArea, setSelectedArea] = useState();
    const [isActive, setIsActive] = useState(true);
    const imageRef = useRef();

    const infographicsName = String(props?.infographicsName).replace(/\s/g, '').toLowerCase() ?? "infographicsName";
    
    const toNumbers = arr => arr.map(Number);
    
    const onLoadAreasContent = (name, shape, coords, fillColor, areasText) => {
		var obj = {
            name:  name,
            shape: shape,
            coords: coords,
            fillColor: fillColor,
            areaContent: areasText
		};
        
        if(selectedArea?.area && selectedArea?.area?.name === name) { 
            obj.preFillColor = fillColor
        }

		return obj;
	}
    const convertHex = (hexCode,opacity = 100) => {
        if(!hexCode) return '';
        let hex = hexCode.replace('#','');
    
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
    
        let r = parseInt(hex.substring(0,2), 16),
            g = parseInt(hex.substring(2,4), 16),
            b = parseInt(hex.substring(4,6), 16);
    
        return 'rgba('+r+','+g+','+b+','+opacity/100+')';
    }

	const areasObj = (index, name, shape, coords, fillColor, img, areaContent) => {
		var obj = {
            index: index,  
            name:  name,
            shape: shape,
            coords: coords,
            fillColor: fillColor,
            imagePath: img,
            areaContent: areaContent
		};

        // if(selectedArea?.area && selectedArea?.area?.name === index) { 
        //     obj.preFillColor = fillColor
        // }

		return obj;
	}

    const imageMapObj = (name, areas) => {
		var obj = {
            name: name,
            areas: areas
		};
		return obj;
	}
	
    if (filterOptions !== undefined || filterOptions?.length > 0) {
        filterOptions?.values?.map((item) => {
            let newArray = new Array();
            let htmlImageMapParser = ReactHtmlParser(String(item?.elements?.filterImageMap?.value?.imageMapWithCoordinates?.value));
            const filterImageMapParser = htmlImageMapParser[0]?.props?.children?.filter(function(entry) { return /\S/.test(entry); });
            let unsetCount = 0;

            filterImageMapParser?.map((ele) => {
                let i = ele?.props?.title !== '' ? parseInt(ele?.props?.title) - 1 : 0;
                let name = ele?.props?.title
                if(!name) {
                    unsetCount++;
                    name = `custom-${unsetCount}`
                }

                if (ele?.props?.onhovercolor === undefined) {
                    newArray.push(areasObj(name, item?.elements?.filterId?.value, ele?.props?.shape, toNumbers(ele?.props?.coords.split(',')), convertHex("#ffffff", 50), item?.elements?.filterImageMap?.value?.imagePicker?.url, item?.elements?.filterImageMap?.value?.areaSpecificContents?.values[i]))
                } else {
                    newArray.push(areasObj(name, item?.elements?.filterId?.value, ele?.props?.shape, toNumbers(ele?.props?.coords.split(',')), convertHex(ele?.props?.onhovercolor, ele?.props?.opacity), item?.elements?.filterImageMap?.value?.imagePicker?.url, item?.elements?.filterImageMap?.value?.areaSpecificContents?.values[i]))
                }
            })
            onSelectFilterAreaArray.push(imageMapObj(item?.elements?.filterId?.value, newArray))
        })
    }

    useEffect(() => {
        if(imageRef?.current && imagePicker?.asset?.altText) {
            imageRef.current.querySelector('img').alt = imagePicker?.asset?.altText;
        }
        const OverlaySel = document.getElementById('js-overlay-modal');
        setWidth(window.innerWidth);
        function handleResize() {
            setWidth(window.innerWidth);
        }
        function togglePopup() {
            setPopupOpen(false);
            toggleOverlay('hide');
        }
        window.addEventListener("resize", handleResize);
        OverlaySel.addEventListener("click", togglePopup);
        let htmlDefaultImageMapParser = ReactHtmlParser(String(imageMapWithCoordinates?.value));
        let defaultImageMapParser = htmlDefaultImageMapParser[0]?.props?.children?.filter(function(entry) { return /\S/.test(entry); });
        let unsetCount = 0;
        defaultImageMapParser?.map((ele) => {
            let i = ele?.props?.title !== '' ? parseInt(ele?.props?.title) - 1 : 0;
            let name = ele?.props?.title
            if(!name) {
                unsetCount++;
                name = `custom-${unsetCount}`
            }
            if (ele?.props?.onhovercolor === undefined) {
                defaultAreaArray.push(onLoadAreasContent(name, ele?.props?.shape, toNumbers(ele?.props?.coords.split(',')), convertHex("#000000", 50), areaSpecificContents?.values[i]))
            } else {
                defaultAreaArray.push(onLoadAreasContent(name, ele?.props?.shape, toNumbers(ele?.props?.coords.split(',')), convertHex(ele?.props?.onhovercolor, ele?.props?.opacity), areaSpecificContents?.values[i]))
            }
        });
        let MAP = {
            name: infographicsName,
            areas: defaultAreaArray
        }
        setDefaultArrayImageMap(defaultAreaArray)
        setImageMapper(MAP)
    },[selectedArea]);
    useEffect(() => {
        const containerEl = document.getElementById('image-mapper-container');
        const containerWidth = containerEl?.getBoundingClientRect().width;
        const imageWidth = imagePicker?.asset?.width;
        const imageHeight = imagePicker?.asset?.height;
        if(containerWidth < imageWidth) {
            const scaleRatio = containerWidth / imageWidth;
            if(imageRef?.current) {
                imageRef.current.style.transform = `scale(${scaleRatio})`
                imageRef.current.style.height = `${scaleRatio * imageHeight}px`
            }
        }else{
            imageRef.current.style.transform = `none`
            // imageRef.current.style.height = 'auto';
        }

        // if(containerWidth < imageWidth) {
        //     const scaleRatio = containerWidth / imageWidth;
        //     containerEl.children[0].style.transform = `scale(${scaleRatio})`;
        //     containerEl.style.height = `${scaleRatio * imageHeight}px`
        // }else{
        //     containerEl.children[0].style.transform = 'none'
        //     containerEl.removeAttribute("style")
        // }
      
        if(!popupOpen) { 
            setSelectedArea(null) 
        }
    })
	const openTooltip = (area, index, event) => {
	   	setPopupOpen(true);
	   	toggleOverlay('show', true);
	   	setPopupTitle(area?.areaContent?.elements?.title);
    	setPopupDetail(area?.areaContent?.elements?.description);
        setPopupAccordionId(area?.areaContent?.elements?.accordionId?.value);
    	setPopupAccordionLinkLabel(area?.areaContent?.elements?.accordionLinkLabel?.value);
    	setPopupLinkTarget(area?.areaContent?.elements?.linkTarget?.linkURL);
    	setPopupLinkText(area?.areaContent?.elements?.linkTarget?.linkText);
    	setPopupLinkDescription(area?.areaContent?.elements?.linkTarget?.linkDescription);
		// setBrainImage(imageSource +""+ area?.imagePath)
        
        setSelectedArea({ area, coords: event.target.getAttribute("coords").split(",") });
    }

	const swapImage = (area) => {
        // setBrainImage(imageSource +""+ area?.imagePath)
    }

    const onSelectItem = (e, id) => {
        var cb = e.srcElement || e.target;
        if (cb.type !== 'checkbox') {return true;}
        var cbxs = document.getElementById(`${infographicsName}__checkbox`).getElementsByTagName('input'), i=cbxs.length;
         while(i--) {
             if (cbxs[i].type && cbxs[i].type == 'checkbox' && cbxs[i].id !== cb.id) {
              cbxs[i].checked = false;
             }
         }
         if (cb.checked) {
            if (onSelectFilterAreaArray.length > 0) {
                if (onSelectCheckboxFunction !== undefined) {
                    if (onSelectCheckboxFunction?.value?.selection === "swapImageOnly") {
                        setBrainImage(imageSource +""+ onSelectFilterAreaArray[id]?.areas[0]?.imagePath)
                        setImageMapper(onSelectFilterAreaArray[id])
                    } else if (onSelectCheckboxFunction?.value?.selection === "swapImageShowPopup") {
                        setPopupOpen(true);
                        toggleOverlay('show', true);
                        setPopupTitle(onSelectFilterAreaArray[id]?.areas[0]?.areaContent?.elements?.title);
                        setPopupDetail(onSelectFilterAreaArray[id]?.areas[0]?.areaContent?.elements?.description);
                        setBrainImage(imageSource +""+ onSelectFilterAreaArray[id]?.areas[0]?.imagePath)
                        setImageMapper(onSelectFilterAreaArray[id])
                    }
                }
            }
         } else {
            if (onSelectCheckboxFunction !== undefined) {
                let MAP = {
                    name: infographicsName,
                    areas: defaultArrayImageMap
                }
                setBrainImage(imageSource +""+ imagePicker?.url)
                setImageMapper(MAP)
            }
		}
	}
    const showTabHandler = (ev,tabID) => {
        let event = ev.target || ev.srcElement,
            elementSel = document.getElementById(tabID);
        
        if(!elementSel?.classList?.contains('open')){
            elementSel.click();
            elementSel.scrollIntoView({ behavior: 'smooth', block: 'start'});
            toggleOverlay('hide');
            setPopupOpen(!popupOpen);
        }
    }

    const imageAlignment = props.element?.imageMap?.value?.imageAlignment?.value?.selection;
    return (
    <div className="infographics-diagram__wrapper">
        <div className="container">
            <div className="mdt-main-wrapper">
                <div className="infographics-diagram__infographics js-scroll-target" data-popup-target="brain-pathology-popup">
                    <div className={`mdt__infographics-image-container1${imageAlignment ? ` image--${imageAlignment.toLowerCase()}` : ''}`}>
                        <div className="caption-container1">
                            <div id="image-mapper-container" className="caption-container-wrap">

                                {timedPopups && <TimePopupsWrap contents={timedPopups} onDisableTimePopUp={props.onDisableTimePopUp} wrapClass="timepopups-infographic-wrap" /> }
                                
                                <ImageMapper src={brainImage}
                                    containerRef={imageRef}
                                    map={imageMapper}
                                    active={isActive}
                                    width={imagePicker?.asset?.width}
                                    heigth={imagePicker?.asset?.height}
                                    onClick={(area, index, event) => openTooltip(area, index, event)}
                                    // onMouseEnter={(area => {
                                    //     swapImage(area);
                                    // })}
                                    // onMouseLeave={(area => {
                                    //     setBrainImage({
                                    //         name: infographicsName,
                                    //         areas: defaultArrayImageMap
                                    //     })
                                    // })}
                                /> 
                            </div>      
                        </div>
                        <div id={`${infographicsName}__checkbox`} className="mdt__infographics-selections" style={{color:filterFontColour?.value}}>
                            <RichText data={filterHeading}/>
                            {
                                filterOptions?.values?.map((item, index) => {
                                    return (
                                        <div className="checkbox-container"> 
                                            <div className="checkbox-container__shape" style={{ backgroundColor: item?.elements?.checkboxBoxBackgroundColor?.value }}>
                                                <label htmlFor={`${infographicsName}_${index}`} className="container-input">
                                                    <input type="checkbox" id={`${infographicsName}_${index}`} name="progression_selection" onChange={(e) => {onSelectItem(e, index)}} value={index} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="checkbox-text-container" style={{marginTop: item?.elements?.filterDescription?.value !== undefined || item?.elements?.filterDescription?.value !== undefined ? "10px" : "0"}}>
                                                <span className="main-text"><RichText data={item?.elements?.filterLabel} /></span>
                                                {item?.elements?.filterDescription?.value && <span className="sub-text"><RichText data={item?.elements?.filterDescription} /></span>} 
                                            </div>
                                        </div>
                                        )
                                })
                            }
                        </div>
                        {popupOpen && 
                            <div className="custom-popup">
                                <div className="custom-popup__close">
                                    <img className="close" src={close} onClick={() => { 
                                        setPopupOpen(!popupOpen);
                                        toggleOverlay('hide');
                                        }} />
                                </div>
                                <div className="custom-popup__detail">
                                    <div className="title"><RichText data={popupTitle} /></div>
						            <div className="desc"><RichText data={popupDetail} /></div>
                                    {popupLinkText && popupLinkTarget && (
                                        <>
                                            <p>{popupLinkDescription}</p>
                                            <Link href={popupLinkTarget}>{popupLinkText}</Link>
                                        </>
                                    )}
                                    {popupAccordionLinkLabel && popupAccordionId &&(
                                        <div className="timepopups__content-cta">
                                            <button type="button" className="btn-popups" onClick={(ev) => {showTabHandler(ev,popupAccordionId)}} >
                                            {popupAccordionLinkLabel}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        </div> 
    </div>);
}

export default InfographicsDiagram;