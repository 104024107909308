import React from 'react'

// components
import Card from '../../components/reusableComponents/card/Card'
import CardContent from '../../components/reusableComponents/card/components/cardContent/CardContent'
import CardImage from '../../components/reusableComponents/card/components/cardImage/CardImage'
import CardHeading from '../../components/reusableComponents/card/components/cardHeading/CardHeading'

// styles
import './TitleCard.scss'

const TitleCard = ({ image, heading, href, bgColor, maxWidth, center, clickable, ...rest }) => {
    return (
        <Card className={`TitleCard ${center ? ' TitleCard--center' : ''}`} href={clickable ? href : null} bgColor={bgColor || '#EA5504'} maxWidth={maxWidth} {...rest}>
            <CardImage bgImage={image} />
            <CardContent className='CardContent'>
                <CardHeading heading={heading} href={clickable ? null : href} />
            </CardContent>
        </Card>
    )
}

export default TitleCard
