import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import { userActions } from "redux/user/slice";
import { Form } from "components/shared/form/Form";
import {
  INPUT,
  BUTTON,
  CHECKBOX,
  COMPONENT,
  RADIO,
  ACCOUNT_DETAILS,
  SHOULD_HAVE_VALUE,
  DROPDOWN_DEFAULT,
  ROLE_DROPDOWN,
  SHOULD_CONTAIN_ONLY_NUMBER,
  commonShouldHaveValue,
  SHOULD_HAVE_VALUE_RADIO,
} from "components/shared/form/formConstants";
import { WithdrawConsent } from "components/pageTemplates/login/WithdrawConsent";
import { MarketingLabel } from "components/pageTemplates/login/Marketing-label";
import { MarketingConsent } from "components/pageTemplates/login/MarketingConsent";
import { PROFILE_SUCCESS_PATH, PROFILE_PAGE_PATH } from "routes/routeConstants";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import { useAuthService } from 'utils/authUtils';
import { DOCCHECK, ONEKEY } from 'api/authService';

const AccountDetailsForm = () => {
  const {
    tEmail,
    tEnterYourEmail,
    tSalutation,
    tEnterYourTitle,
    tFirstName,
    tEnterYourFirstName,
    tLastName,
    tEnterYourLastName,
    tSpeciality,
    tEnterYourSpeciality,
    tWorkplace,
    tEnterYourWorkplace,
    tIConsentTo,
    tSave,
    tAccountDetails,
    tCheckAndChange,
    tThisFieldIsRequired,
    tRoleLabel,
    tRoleValues,
    tJobTitleLabel,
    tJobTitlePlaceHolder,
    tDepartmentLabel,
    tDepartmentPlaceholder,
    tPracticePhoneNumberLabel,
    tPracticePhoneNumberPlaceholder,
    tDepartmentPhoneNumberLabel,
    tDepartmentPhoneNumberPlaceholder,
    featureMarketingPreferenceActivation,
    jobTitlePlaceholder,
    featureSwitchDisplayNewFormFields,
    disableMarketingConsent
  } = useWebsiteConfigObject();
  const {
    trackEventWithContentType,
    trackUpdateProfileAnonymous,
  } = useGoogleAnalytics();
  //feature activation

  let featureMandatory = featureMarketingPreferenceActivation;
  let boolOptionMarketing = false;
  let boolMandatory = false;
  const { fetching, details } = useSelector((state) => state.user);
  const history = useHistory();
  let detailsChange = details;
  const service = useAuthService();
  const dispatch = useDispatch();
  let type = history.location?.state?.state || null;

  if (type && type.type === "Anonymous-user-flow") {
    if (featureMandatory === true) {
      boolOptionMarketing = null;
      boolMandatory = true;
    }
  }
  const isAnonymous = details.username != details.email;

  if (featureMarketingPreferenceActivation) {
    detailsChange = JSON.parse(JSON.stringify(details));
    detailsChange.email = detailsChange.username || detailsChange.email;
  }

  const getAccountDetailsFormFields = ({
    salutation,
    firstname,
    lastname,
    speciality,
    role,
    jobTitle,
    department,
    phone,
    departmentPhone,
    workplace,
    promotionalMaterialsOptIn,
    email,
  }) => [
    {
      label: tEmail,
      id: "email",
      initialValue: email || "",
      type: INPUT,
      typeParams: {
        type: "text",
        autoComplete: "email",
        placeholder: tEnterYourEmail,
        readOnly: true,
      },
    },
    {
      label: tSalutation,
      id: "salutation",
      initialValue: salutation || "",
      type: INPUT, // Designs have drop down but client say they want it as an input
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "honorific-prefix",
        placeholder: tEnterYourTitle,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tFirstName,
      id: "first-name",
      initialValue: firstname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: tEnterYourFirstName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tLastName,
      id: "last-name",
      initialValue: lastname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "family-name",
        placeholder: tEnterYourLastName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tRoleLabel,
      id: "role",
      initialValue: role ? role : "",
      type: ROLE_DROPDOWN,
      mandatory: true,
      typeParams: {
        type: "profile"
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tSpeciality,
      id: "speciality",
      initialValue: speciality || "",
      type: DROPDOWN_DEFAULT,
      mandatory: true,
      typeParams: {
        type: "profile",
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tJobTitleLabel,
      id: "jobtitle",
      initialValue: jobTitle || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "text",
        placeholder: jobTitlePlaceholder,
      },
      validation: [],
    },
    {
      label: tWorkplace,
      id: "organization-title",
      initialValue: workplace || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "organization",
        placeholder: tEnterYourWorkplace,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tDepartmentLabel,
      id: "department",
      initialValue: department || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "text",
        autoComplete: "department",
        placeholder: tDepartmentPlaceholder,
      },
      validation: [],
    },
    {
      label: tDepartmentPhoneNumberLabel,
      id: "departmentPhone",
      initialValue: departmentPhone || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "numeric",
        autoComplete: "phone-number",
        placeholder: tDepartmentPhoneNumberPlaceholder,
      },
      validation: [
        {
          validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
          errorText: "Should contain only number",
        },
      ],
    },
    {
      label: tPracticePhoneNumberLabel,
      id: "phone",
      initialValue: phone || "",
      type: INPUT,
      mandatory: false,
      typeParams: {
        type: "numeric",
        autoComplete: "phone-number",
        placeholder: tPracticePhoneNumberPlaceholder,
      },
      validation: [
        {
          validationMethodKey: SHOULD_CONTAIN_ONLY_NUMBER,
          errorText: "Should contain only number",
        },
      ],
    },
    {
      id: "marketing-label",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingLabel,
      },
      disabled: promotionalMaterialsOptIn === null
    },
    {
      id: "marketing-text",
      type: COMPONENT,
      size: 100,
      typeParams: {
        PropComponent: MarketingConsent,
      },
      disabled: promotionalMaterialsOptIn === null
    },
    {
      label: "",
      id: 'information-consent',
      initialValue: promotionalMaterialsOptIn ?? null,
      type: RADIO,
      // mandatory: true,
      typeParams: {},
      // validation:[{
      //   validationMethodKey: SHOULD_HAVE_VALUE_RADIO,
      //   errorText: tThisFieldIsRequired,
      // },],
      disabled: promotionalMaterialsOptIn === null
    },
    {
      id: "withdraw-consent",
      type: COMPONENT,
      typeParams: {
        PropComponent: WithdrawConsent,
      },
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          actions.updateDetails(formValues);
        },
        text: tSave ? tSave.toUpperCase() : "",
        scrollToError: true,
        type: "submit",
      },
    },
  ];
  const getAccountDetailsFormFieldsOld = ({
    salutation,
    firstname,
    lastname,
    speciality,
    workplace,
    promotionalMaterialsOptIn,
    email,
  }) => [
    {
      label: tEmail,
      id: "email",
      initialValue: email || "",
      type: INPUT,
      typeParams: {
        type: "text",
        autoComplete: "email",
        placeholder: tEnterYourEmail,
        readOnly: true,
      },
    },
    {
      label: tSalutation,
      id: "salutation",
      initialValue: salutation || "",
      type: INPUT, // Designs have drop down but client say they want it as an input
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "honorific-prefix",
        placeholder: tEnterYourTitle,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tFirstName,
      id: "first-name",
      initialValue: firstname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "given-name",
        placeholder: tEnterYourFirstName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tLastName,
      id: "last-name",
      initialValue: lastname || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "family-name",
        placeholder: tEnterYourLastName,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tSpeciality,
      id: "speciality",
      initialValue: speciality || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "profile",
        placeholder: tEnterYourSpeciality,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tWorkplace,
      id: "organization-title",
      initialValue: workplace || "",
      type: INPUT,
      mandatory: true,
      typeParams: {
        type: "text",
        autoComplete: "organization",
        placeholder: tEnterYourWorkplace,
      },
      validation: [
        {
          validationMethodKey: SHOULD_HAVE_VALUE,
          errorText: tThisFieldIsRequired,
        },
      ],
    },
    {
      label: tIConsentTo,
      id: 'information-consent',
      initialValue: promotionalMaterialsOptIn ?? null,
      type: CHECKBOX,
      // mandatory: false,
      typeParams: {},
      disabled: promotionalMaterialsOptIn === null
    },
    {
      id: 'withdraw-consent',
      type: COMPONENT,
      typeParams: {
        PropComponent: WithdrawConsent,
      },
    },
    {
      id: "save-account-details",
      type: BUTTON,
      size: 50,
      typeParams: {
        callBack: ({ formValues, actions }) => {
          actions.updateDetails(formValues);
        },
        scrollToError: true,
        text: tSave ? tSave.toUpperCase() : "",
        type: "submit",
      },
    },
  ];

  const formFields = featureSwitchDisplayNewFormFields ? getAccountDetailsFormFields(detailsChange) : getAccountDetailsFormFieldsOld(detailsChange);
  const [showError, setShowError] = useState(false);
  const updateDetails = (formData) => {
    if (
      featureMarketingPreferenceActivation &&
      type &&
      type.type === "Anonymous-user-flow"
    ) {
      if (formData["information-consent"]) {
        dispatch(userActions.global.details.update(formData)).then(() => {
          trackUpdateProfileAnonymous();
          history.push({ pathname: type.redirect }, { state: null });
        });
      } else {
        history.push(
          { pathname: PROFILE_PAGE_PATH },
          {
            state: {
              type: "Anonymous-user-flow",
              redirect: type.redirect,
              openTooltip: true,
            },
          }
        );
      }
    } else {
      dispatch(userActions.global.details.update(formData)).then(() => {
        trackEventWithContentType("profile", "save");
        history.push(PROFILE_SUCCESS_PATH);
      });
    }
  };

  return (
    <>
      <div className="profile-form-content">
        <h3 className="account-details">{tAccountDetails}</h3>
        <p className="check-details">{tCheckAndChange}</p>
        <Form
          fields={formFields}
          formId={ACCOUNT_DETAILS}
          actions={{ updateDetails }}
          isSubmitting={fetching}
        />
      </div>
      {/* {showError &&   <TooltipAnonymous hide={showError}/>
    } */}
    </>
  );
};

export { AccountDetailsForm };
