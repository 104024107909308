import React, { useEffect, useState } from 'react'
import './MobileNavigation.scss'
import { useDispatch, useSelector } from 'react-redux'
import CountrySelector from '../countrySelector/CountrySelector'
import NavLink from 'components/reusableComponents/navLink/NavLink'
import { closeMobileNav } from 'redux/ui/slice'
import Link from 'components/reusableComponents/link/Link';
import SearchBox from '../searchBox/SearchBox'
import { useHistory } from 'react-router-dom'
    
import { ArrowContactUs } from 'components/shared/SvgIcons'
import SideNavigation from 'modules/sideNavigation/SideNavigation'
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';

const MobileNavigation = () => {
    const history = useHistory();
    const currentPath = history.location.pathname;
    const { tNavigationContactLink } = useWebsiteConfigObject();

    const dispatch = useDispatch()
    // Navigation Items
    const { main, mobile } = useSelector(state => state.ui.navigation)
    const { redesign } = useSelector(state => state.ui);


    // Navigation UI State
    const initalState = { id:null, title:'Main Menu', children: [] }
    const [navigation, setNavigation] = useState([]) // Nav Data with Parent ref
    const [selectedNav, setSelectedNav] = useState(initalState) // Selected Nav Item

    // Initialise Navigation Data
    useEffect(()=>{
        if(main){
            // Clone Redux State
            const navData = JSON.parse(JSON.stringify(main))
            // Recursively add parent as reference
            setNavParent(navData)
            // Initial Navigation Data
            setNavigation({ ...navigation, children: navData })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[main])

    // Load Initial UI State
    useEffect(()=>{
        loadSelectedNav()
        if(navigation?.children && currentPath !== "/") {
            findCurrentPath(navigation);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[navigation, navigation.length])

    useEffect(()=>{
        if(!selectedNav?.children){
            setSelectedNav(navigation)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedNav])

    const findCurrentPath = (navItems) => {
        if(navItems?.children){
            const selectedItem = navItems?.children.find(item => item.path === currentPath);
            if(selectedItem){
                setSelectedNav(selectedItem);
                if (!selectedItem?.children) { 
                    setSelectedNav(navItems);
                }
                return selectedItem;
            } else if (navItems?.children) {
                navItems.children.forEach(item => {
                    if(item?.children) findCurrentPath(item)
                });
            } else {
                return null
            }
        }
        return null
    }
    
    // Recursively add parent as reference
    const setNavParent = (data) => {
        if(Array.isArray(data))
        {
            for (let i = 0; i < data.length; i++) {
                setNavParent(data[i])
            }
        } else {
            if(data?.children){
                for (let x = 0; x < data.children.length; x++) {
                    data.children[x].parent = data
                }
                setNavParent(data.children)
            }
        }
    }

    // Load Selected UI State
    const loadSelectedNav = (id = null) => {
        if(id){
            // Selected Nav State
            const selectedItem = selectedNav.children.find(item => item.id === id)
            if(selectedItem){
                setSelectedNav(selectedItem)
            }
        } else {
            // Main Nav State
            setSelectedNav(navigation)
        }
    }

    // Close Navigation
    const closeNav = () => {
        dispatch(closeMobileNav())
    }

    // Back
    const handleBackLink = () => {
        if(selectedNav.parent){
            setSelectedNav(selectedNav.parent)
        } else {
            setSelectedNav(navigation)
        }
    }

    // Open Sub Item
    const handleSubLink = id => {
        loadSelectedNav(id)
    }

    // if(mobile) {
    //     return (
    //         <div className={`MobileNavigation${tNavigationContactLink?.linkURL ? " MobileNavigation--contact-us" : ""}`}>
    //             <SearchBox className="Mobile_SearchBox" />
    //             <div className={`NavInfo NavInfo-- ${selectedNav?.id?'NavInfo_Sub':''}`}>
    //                 <span className="ParentLink"><i></i> BACK</span>
    //                 <span className="ParentName">{selectedNav?.title || 'Main Menu'}</span>
    //             </div>
    //             <SideNavigation element={mobile} onClick={closeNav} />
    //             {tNavigationContactLink?.linkURL && 

    //                 <div className="ContactLink_Wrapper">
    //                     <div className="ContactLink_Link">
    //                         <Link className="" href={tNavigationContactLink.linkURL}>{tNavigationContactLink.linkText} <ArrowContactUs /></Link>
    //                     </div>
    //                 </div>
    //             }
                
    //             <CountrySelector isMobile={true} />
    //         </div>
    //     );
    // }

    return (
        <div className={`MobileNavigation`}>
            <SearchBox className="Mobile_SearchBox" />

            <ul className={`Main${selectedNav?.id ? " Main--subnav" : ""}`}>

                <li className={`NavInfo ${selectedNav?.id?'NavInfo_Sub':''}`} onClick={()=> handleBackLink()}>
                    <span className="ParentLink"><i></i> BACK</span>
                    <span className="ParentName">{selectedNav?.title || 'Main Menu'}</span>
                </li>
                {selectedNav?.id && redesign && 
                    (
                        <li className={`NavInfo ${selectedNav?.id?'NavInfo_Sub--subnav':''}`}>
                            <NavLink className="ParentLink--subnav" onClick={closeNav} href={selectedNav?.path || '#'}>{selectedNav?.title}</NavLink>
                        </li>
                    )
                }
               

                {
                    selectedNav?.children?.map(item=>{
                        return (
                            <li key={item.id}>

                                <NavLink className="NavLink" onClick={closeNav} href={item.path || '#'}>{item.title}</NavLink>

                                { item?.children?.length > 0 && <div onClick={() => handleSubLink(item.id)} className="ChildLink"><i></i></div>}

                            </li>
                        )
                    })
                }
            </ul>
            
            
            <CountrySelector isMobile={true} />
        </div>
    )
}
export default MobileNavigation
