import React from 'react';
import TimePopups from './timepopups';
import { randomAlphanumeric } from '../helper';

const TimePopupsWrap = (props) => {

    const { contents, onDisableTimePopUp, wrapClass } = props;

    let timePopupContent = null;

    //Set size of popup
    const setSize = (sizeCapture) => {
        let size = '';

        if(sizeCapture !== undefined && sizeCapture !== ''){
            if(sizeCapture === 'Small'){
                size = 'small';
            } else if(sizeCapture === 'Large'){
                size = 'large';
            } else if(sizeCapture === 'Xtra-small'){
                size = 'xtra-small';
            }
        }

        return size;
    }

    //Set arrow of popup
    const setArrow = (arrowCapture) => {
        let arrowDirection = '';

        if(arrowCapture !== undefined && arrowCapture !== ''){
            if(arrowCapture === 'Up'){
                arrowDirection = 'arrow-up';   
            } else if(arrowCapture === 'Down'){
                arrowDirection = 'arrow-down'; 
            }
        }

        return arrowDirection;
    }

    //Set Button of popup
    const setButton = (buttonCapture) => {
        let buttonDirection = '';

        if(buttonCapture !== undefined && buttonCapture !== ''){
            if(buttonCapture === 'Left'){
                buttonDirection = 'btn-left'; 
            } else if(buttonCapture === 'Right'){
                buttonDirection = 'btn-right';
            }
        }

        return buttonDirection;
    }

    //Set Time popup display
    const setTimePopupRender = (param) => {

        return (
            <TimePopups 
                id={'js-'+randomAlphanumeric(12)} 
                size={`${param.size} ${param.arrowDirection} ${param.buttonDirection}`}  //"xtra-small btn-left arrow-down"
                animation="fade-in" 
                animationDuration="1500"                //Miliseconds
                popupDuration={param.duration}          //in seconds
                content={param.descriptionText} 
                btnLabel={param.btnLabelText}
                onDisableTimePopUp={param.onDisableTimePopUp} 
            /> 
        );
    }

    //Content for accordions or more than one popup
    if(contents?.values?.length >= 1){
        //Loop in the time popup content
        timePopupContent = contents?.values?.map((elements, index) => { 
            let btnLabelText = elements?.elements?.buttonLabel?.value,
                descriptionText = elements?.elements?.description?.value,
                sizeCapture = elements?.elements?.popupSize?.value?.label,
                arrowCapture = elements?.elements?.arrowDirection?.value?.label,
                buttonCapture = elements?.elements?.buttonPlacement?.value?.label;

            let duration = (elements?.elements?.duration?.value !== undefined) ? elements?.elements?.duration?.value : '5';
                
            let size = setSize(sizeCapture),                        //large, small, xtra-small
                arrowDirection = setArrow(arrowCapture),            //arrow-up, arrow-down
                buttonDirection = setButton(buttonCapture);         //btn-left, btn-right

            return (
                <div className={wrapClass}>
                    {setTimePopupRender({
                        size: size,
                        arrowDirection: arrowDirection,
                        buttonDirection: buttonDirection,
                        duration: duration,
                        descriptionText: descriptionText,
                        btnLabelText: btnLabelText,
                        onDisableTimePopUp: onDisableTimePopUp
                    })}
                </div>
            );
        });
    } else {    //From mainly inforgraphics
        let btnLabelText = contents?.elements?.buttonLabel?.value,
            descriptionText = contents?.elements?.description?.value,
            sizeCapture = contents?.elements?.popupSize?.value?.label,
            arrowCapture = contents?.elements?.arrowDirection?.value?.label,
            buttonCapture = contents?.elements?.buttonPlacement?.value?.label;

        let duration = (contents?.elements?.duration?.value !== undefined) ? contents?.elements?.duration?.value : '5';

        let size = setSize(sizeCapture),                        //large, small, xtra-small
            arrowDirection = setArrow(arrowCapture),            //arrow-up, arrow-down
            buttonDirection = setButton(buttonCapture);         //btn-left, btn-right


        timePopupContent = (
            <div className={wrapClass}>
                {setTimePopupRender({
                    size: size,
                    arrowDirection: arrowDirection,
                    buttonDirection: buttonDirection,
                    duration: duration,
                    descriptionText: descriptionText,
                    btnLabelText: btnLabelText,
                    onDisableTimePopUp: onDisableTimePopUp
                })}
            </div>
        );
    }

    return timePopupContent;
}

export default TimePopupsWrap;