import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import PropTypes from 'prop-types';
import { useApiRoutes } from 'routes/useApiRoutes';
import {
  getUrlCountryPath,
  getLanguageCode,
  getUrlCountryPathFromUrl,
} from 'utils/urlUtils';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { getValue, getValues } from 'utils/contentUtils';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { AppConfig } from 'AppConfig';
import { useLocationSearchParameter } from 'utils/urlUtils';
import { INFOGRAPHICS, INFOGRAPHICSTB } from 'routes/routeConstants';
import { extractTaxonomyValues, useGATaxonomy } from 'hooks/taxonomyGoogleAnalytics';

const DefaultSEOHeaders = ({ path }) => {
  const { websiteConfig } = useWebsiteConfig();
  const { routes } = useApiRoutes();
  const { trackPageView} = useGoogleAnalytics();
  const langCode = getLanguageCode();
  const urlCountryPath = getUrlCountryPath();
  const kkiLogo = `${AppConfig.acousticApi.domain}/${AppConfig.acousticApi.contentHubId}/dxdam/1c/1c9bb697-5cc3-445b-a405-b2599b0a3e02/kki_logo.png`;
  const schema = {
    '@context': 'http://schema.org/',
    '@type': 'Organization',
    url: window.location.origin,
    name: 'Kyowa Kirin',
    logo: kkiLogo,
  };
  const JSONschema = JSON.stringify(schema);

  let cid = null;
  let pathName = '';
  if (routes) {
    if (websiteConfig) {
      const { landingPageId } = websiteConfig;
      const landingPage = {
        contentId: landingPageId,
        templateType: 'Landing page',
        path: '/',
      };
      const landingPageWithoutTrailingSlash = {
        contentId: landingPageId,
        templateType: 'Landing page',
        path: '',
      };
      routes.push(landingPage);
      routes.push(landingPageWithoutTrailingSlash);
    }
    const pathNameWithCC = window.location.pathname;
    pathName = decodeURI(pathNameWithCC.split(`/${urlCountryPath}`)[1]);
    path = decodeURI(path);
    routes
      .filter((route) => route.path === pathName)
      .forEach((route) => {
        cid = route.contentId;
      });
    if (!cid && pathName) {
      const lastCharacter = pathName.substring(pathName.length - 1);
      if (lastCharacter !== '/')
        routes
          .filter((route) => route.path === pathName + '/')
          .forEach((route) => {
            cid = route.contentId;
          });
    }
  }
  const { content } = usePageContent(cid);
  const { isLoading, taxonomyFields } = useGATaxonomy();

  let title;
  let crawlable;
  let contentAccess;
  const nullSEO = { seoTitle: '', seoDescription: '' };
  const { seoTitle, seoDescription } = websiteConfig ? websiteConfig : nullSEO;
  const contentTitle =
    content && content.elements && content.elements.title
      ? getValue(content.elements.title)
      : '';
  // KKICH-494: Only show the relevant title once. If ContentId exists concat h1 page title with SEO title field from web config
  // else just load the SEO title field into the head section title. Static route pages like login and search-results will take this. 

  if (content) {
    title = contentTitle ? `${contentTitle}` : `${seoTitle}` ; //title to check 
    title = content?.elements?.subtitle?.value ? title.concat(" ", getValue(content.elements.subtitle)) : title;
    title = title.concat(" ", " – Kyowa Kirin Hub");
    crawlable = content?.elements?.basePage?.value?.crawlable?.value
    contentAccess = content?.elements?.contentAccess?.value?.selection
  } else {
    if (pathName) {
      title = seoTitle;
      if (String(pathName) === String(INFOGRAPHICS)) {
        title = `Managing Parkinson’s as Multidisciplinary Team | ${seoTitle}`
      } else if (String(pathName) === String(INFOGRAPHICSTB)) {
        title = `Progression of Parkinson's Disease | ${seoTitle}`
      }
    }
  }

  let statusId = useLocationSearchParameter("StatusId");
  let CODE = useLocationSearchParameter('code');
  let COMPANY_INFO = useLocationSearchParameter('company_info_1');
  
  useEffect(() => {
    if(!statusId && !COMPANY_INFO && !CODE){
      if (!isLoading && content) {
        const taxonomy = extractTaxonomyValues(taxonomyFields, content?.elements?.taxonomy?.value)
        trackPageView(path, title, false, taxonomy);
      } else {
        // const taxonomy = extractTaxonomyValues(taxonomyFields, null, true);
        const taxonomy = {
          'message-businessUnit': 'Various',
        }
        let pageTitle = title;
        if (pathName === '/login') pageTitle = 'Login | ' + title;
        if (pathName === '/registration-success') pageTitle = 'Registration Success | ' + title;
        if (pathName === '/confirm-email-success') pageTitle = 'Confirm Email Success | ' + title; 
        if (pathName === '/search-results') pageTitle = 'Search Results | ' + title; 
        if (pathName === '/logout') taxonomy['item_id'] = path;
        const pageStatus = document.body.getAttribute('data-page-status')
        if(!cid && (!pageStatus || pageStatus !== '404')) trackPageView(path, pageTitle, false, taxonomy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, path]);
  //The seoDescription seems to bring back the same thing every time, which isn't a surprise when you see what is being done
  //So I'm ussing seoDescription as a backup to the actual meta description that should be driven from content in Acoustic
  //Please see jira ticket KKICH-217 for info
  const metaDescription = content?.description
    ? `${content.description}`
    : content?.elements?.basePage?.value?.seoShortDesc?.value ? content?.elements?.basePage?.value?.seoShortDesc?.value : seoDescription;
    
  const hrefLangList = getValues(content?.elements?.hrefLangUrlSlug)?.map(
    function (item, i) {
      return (
        <link
          key={i}
          rel='alternate'
          href={window.location.origin + item}
          hrefLang={getUrlCountryPathFromUrl(item)}
        />
      );
    }
  );
  
  title = content?.elements?.basePage?.value?.seoPageTitle?.value ? content?.elements?.basePage?.value?.seoPageTitle?.value : title;
  if(title) document.title = title;
  
  let indexing;
  if(contentAccess) {
    if(contentAccess === "a"){
      indexing = <meta name="robots" content="index, follow" />;
    } else if (contentAccess === "b" && crawlable) {
      indexing = <meta name="robots" content="index, follow" />;
    } else if (contentAccess === "c" && crawlable) {
      indexing = <meta name="robots" content="index, follow" />;
    } else {
      indexing = <meta name="robots" content="noindex, follow" />;
    }
  } else {
    indexing = <meta name="robots" content="index, follow" />;
  }

  return (
    <Helmet htmlAttributes={{ lang: urlCountryPath?.trim() || 'en'}}>
      {hrefLangList}
      {indexing}
      {title && <title>{title}</title>}
      <link rel='canonical' href={window.location.href} />
      {metaDescription && <meta name='description' content={metaDescription} />}
      <script type='application/ld+json'>{JSONschema}</script>
    </Helmet>
  );
};

DefaultSEOHeaders.propTypes = {
  path: PropTypes.string,
};

export { DefaultSEOHeaders };
