import React from 'react';

import RichText from 'components/reusableComponents/richText/RichText';
import Image from 'components/reusableComponents/image/Image';

import './StaticChart.scss';

const StaticChart = (props) => {

	const {
		descriptionBackgroundColor,
		staticChartsList
	} =props?.value?.elements
	let chartImage = staticChartsList?.value?.elements?.chart
	let chartLabel = staticChartsList?.value?.elements?.title?.value?.text


    return (
			<div className="bar-chart__wrapper">
				<div className="bar-chart__item">
					<RichText data={chartLabel}/> 
					<Image data={chartImage}/> 
				</div>
			</div>
    )
}

export default StaticChart;