import React from 'react';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown } from 'components/shared/SvgIcons';


const OpenClosedIndicator = ({ open }) => (
  open ? <ChevronUp /> : <ChevronDown />
);

OpenClosedIndicator.propTypes = {
  open: PropTypes.bool,
};

export { OpenClosedIndicator };
