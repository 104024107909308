import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/authentication/slice';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { Form } from 'components/shared/form/Form';
import { useResetPasswordFields } from 'components/pageTemplates/login/resetPasswordFields';
import { RESET_PASSWORD } from 'components/shared/form/formConstants';
import {
  RESET_PASSWORD_SUCCESS_PATH,
} from 'routes/routeConstants';
import { LoginSideText } from './LoginSideText';
import './ResetPassword.scss';

const ResetPassword = () => {
  const history = useHistory();
  const { tokenValue } = useParams();
  const dispatch = useDispatch();

  const {
    tResetPassword,
    tEnterYourNewPassword,
  } = useWebsiteConfigObject();

  const resetPasswordFields = useResetPasswordFields();

  const handleResetPassword = (formValues) => {

    const { 'new-password': password, 'new-password': confirmPassword } = formValues;

    dispatch(authActions.kki.password.reset(tokenValue, password, confirmPassword))
      .then(() => {
        history.push(RESET_PASSWORD_SUCCESS_PATH);
      });
  };

  return (
    <div
      className="reset-password-page-component"
    >
      <div
        className="reset-password-container"
      >
        <div className="page-content">
          <LoginSideText />
          <div
            className="reset-password-form"
          >
            <div
              className="reset-password-inner"
            >
              <h2 className="form-title">
                {tResetPassword}
              </h2>
              <p className="form-p">
                {tEnterYourNewPassword}
              </p>
              <hr />
              <Form
                fields={resetPasswordFields}
                formId={RESET_PASSWORD}
                actions={{
                  handleResetPassword,
                }}
                wrapped
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export { ResetPassword };

