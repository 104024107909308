/*
  the client has a lot of opinions about the onFocus/outlined functionalit. One of these opinions
  is that on mobile, onFocus shouldn't create an outline. As such, we disable this usings react keys.
  A react key change forces the component to remount and thus, forces it to lose it's focus
*/


import {
  getScreenWidth,
  SCREEN_WIDTH_MOBILE,
} from 'components/layout/ScreenWidthContext';

const getWarning = (missingVar) => `${missingVar} was found to be undefined in unfocusMobile. This variable is needed to determine if an element should lose focus on mobile.`;


const unfocusMobile = ({
  baseKey,
  isSelected,
}) => {
  const {
    currentWidthRanges,
  } = getScreenWidth();


  if (baseKey === undefined) {
    console.warn(getWarning('baseKey'));
  }

  if (isSelected === undefined) {
    console.warn(getWarning('isSelected'));
  }

  if (currentWidthRanges.includes(SCREEN_WIDTH_MOBILE)) {
    return `${baseKey}-${isSelected}`;
  }
  return baseKey;
};
export { unfocusMobile };
