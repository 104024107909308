import React, { useState, useEffect } from 'react';
import parse from 'react-html-parser';
import { permanentCloseTimePopup } from '../helper';
import closeImage from '../../../../../assets/images/infographics-assets/circle-close-icon.svg';

import './timepopups.scss'

const TimePopups = (props) => {
    /* 
        id = unique id for each popup, 
        size = added css class(es) to customise the popup 
        content = the content of the popup - mainly from contents.jsx (static page)    
    */
    const { id, size, content, animation, animationDuration, popupDuration, btnLabel } = props;
    
    const storageName = 'timepopups';             //Local storage name

    const [activePopup, setActivePopup] = useState('');
    const [disableTimePopTime, setDisableTimePopTime] = useState(true);

    let sizeContent = (size !== undefined) ? size : '';

    /* 
        function to close popup and action for permanent closing for the popup
    */
    const closePopup = (id, status = null) => {
        let selElement = document.getElementById(id);

        selElement.classList.add('close');

        if(status === 'permanent_close'){
            //props.onDisableTimePopUp(false)
            permanentCloseTimePopup();
        }
    }

    /*
        function to add time popup to localstrage for permanent invisiblity
    */
    const addTimepopup = (id) => {
        //Check if local storage exist 
        let getStorage = localStorage.getItem(storageName);

        if(getStorage !== null && getStorage !== undefined){
            localStorage.setItem(storageName,getStorage+'|'+id); 
        } else {
            //Create the local storage - first add
            localStorage.setItem(storageName, id);
        }
    }

    /*
        function to check if the popup needs to be display on not
    */
    const verifyTimePopupDisplay = () => {
        //Check if local storage exist 
        let getStorage = localStorage.getItem(storageName);

        if(getStorage !== null && getStorage !== undefined){
            if(getStorage.indexOf(id) > -1){
                setActivePopup('inactive'); 
            }
        }
    }

    //On component mount
    /*useEffect(() => {
        verifyTimePopupDisplay();
    }, []);*/

    return (
        <div id={id} className={`timepopups ${activePopup} ${sizeContent}`} 
            data-aos={animation} 
            data-aos-duration={animationDuration} 
            data-aos-once="true" 
            data-popup-duration={popupDuration}
            data-time-popup="false"
        >
            <div className="timepopups__container">
                <div className="timepopups__header">
                    <div className="timepopups__scroll">
                        <div id={`${id}-timer`} className="timepopups__scroll-bar"></div>
                    </div>
                    <div className="timepopups__close">
                        <img src={closeImage} width="36" height="36" alt="Close popup" onClick={() => closePopup(id)} />
                    </div>
                </div>
                <div className="timepopups__content">
                    {parse(content)}
                    <div className="timepopups__content-cta">
                        <button type="button" className="js-timepopup-btn btn-popups" onClick={() => closePopup(id, 'permanent_close')}>
                            {parse(btnLabel)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default TimePopups;