import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormError } from 'components/shared/form/FormError';
import { getFieldComponent } from 'components/shared/form/getFieldComponent';
import { fieldsPropTypes } from 'components/shared/form/formPropTypes';
import { getInlineErrors } from 'components/shared/form/formValidation';
import './Form.scss';

const getInitialState = ({
  fields,
}) => {
  const initialState = {};

  const urlParams = new URLSearchParams(window.location.search);
  
  fields.forEach((field) => {
    const {
      id,
      initialValue,
    } = field;

    // no op if there is no initialValue, ie, this is probably not a field.
    if (initialValue === undefined) {
      return;
    }

    if (initialState[id]) {
      console.error(`Found a duplication of id: ${id}. Field ids should be unique.`);
    }

    initialState[id] = initialValue;
    urlParams.forEach((value, key) => {
      if(id.toLowerCase() === key.toLowerCase()) { 
        initialState[id] = value 
      }
    });
  });

  return initialState;
};



const Form = ({
  fields,
  wrapped,
  removeBottomMargin,
  actions,
  formId,
  isSubmitting,
  requiredLabel,
  invalidFieldLabel
}) => {
  const [formValues, setFormValues] = useState(getInitialState({ fields }));
  const [touchedFields, setTouchedFields] = useState({});

  const setAllTouched = () => {
    const touched = {};
    fields.forEach((field) => {
      touched[field.id] = true;
    });
    setTouchedFields(touched);
  };



  const baseClassName = 'form-component';
  const classNames = [
    baseClassName,
  ];

  if (wrapped) {
    classNames.push(`${baseClassName}-wrapped`);
  }

  if (removeBottomMargin) {
    classNames.push(`${baseClassName}-mb-0`);
  }

  const inlineErrors = getInlineErrors({
    fields,
    formValues,
  });

  const { loading, error } = useSelector((state) => state.form);
  const { id: formErrorId, errorKey } = error || {};

  const hasFormErrors = formId === formErrorId; // errors from the server, handles differently from inlineErrors
  return (
    <form
      className={classNames.join(' ')}
    >
      {/* pass into form error component formId and errorKey from store */}
      {
        hasFormErrors && (
          <FormError
            error={{
              formId: formErrorId,
              errorKey,
            }}
          />
        )
      }
      {
        fields.map((field) => {

          const {
            type,
            id,
            disabled,
            size
          } = field;

          const FieldComponent = getFieldComponent({ type });

          if (!FieldComponent) {
            return null;
          }

          const fieldBaseClassName = 'form-field-wrapper';
          const fieldClassNames = [
            fieldBaseClassName,
            `${fieldBaseClassName}-${type}`,
            `${fieldBaseClassName}--${size}`,
            disabled ? `${fieldBaseClassName}--hidden` : ''
          ];

          return (
            <div className={fieldClassNames.join(' ')}>
              <FieldComponent
                key={id}
                field={field}
                fieldType={type}
                formValues={formValues}
                setFormValues={setFormValues}
                inlineErrors={inlineErrors}
                touchedFields={touchedFields}
                setTouchedFields={setTouchedFields}
                setAllTouched={setAllTouched}
                actions={actions}
                isSubmitting={loading || isSubmitting}
                requiredLabel={requiredLabel || ""}
                invalidFieldLabel={invalidFieldLabel || ""}
              />
            </div>
          );
        })
      }
    </form>
  );
};


Form.propTypes = {
  fields: fieldsPropTypes,
  wrapped: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.any),
  formId: PropTypes.string,
};

export { Form };


