import React from 'react';
import { contentItemProptype } from 'propTypes/contentProptypes';
import {
  CONTENT_LIST_PAGE_CARD_TYPE,
  ARTICLE_PAGE,
  DYNAMIC_ARTICLE_PAGE,
  CONTENT_PAGE,
  DYNAMIC_CONTENT_PAGE,
  MOODLE_DETAIL_PAGE,
} from 'api/acousticApi/contentTypes';
import { CMSTextCard } from 'components/content/cards/CMSTextCard';
import { CMSPageCard } from 'components/content/cards/CMSPageCard';
import { CMSMoodleCard } from '../cards/CMSMoodleCard';

// content list page card component
// the card passed in can be one of five content types
// - Article
// - Dynamic article
// - Content page
// - Dynamic content page
// - Therapy card
// The type of card component rendered is determined by the content type
const ContentListPageCard = ({
  card,
  type
}) => {
  const cardType = card.type;
  if (cardType === CONTENT_LIST_PAGE_CARD_TYPE) {
    if(type==="moodleCard"){
      return(
        <CMSMoodleCard 
          page={card}
        />
      )
    }
    else{
      return (
        <CMSTextCard
          element={card}
        />
      )
    }
    
  }
  if ([ARTICLE_PAGE, CONTENT_PAGE, DYNAMIC_ARTICLE_PAGE, DYNAMIC_CONTENT_PAGE].includes(cardType)) {
    if(type==="moodleCard"){
      return(
        <CMSMoodleCard 
          page={card}
        />
      )
    }
    else{
      return (
        <CMSPageCard
          page={card}
        />
      );
    }
  }
  if(cardType === MOODLE_DETAIL_PAGE)
  {
    return(
      <CMSMoodleCard 
        page={card}
      />
    )

  }
 
  return null;
};

ContentListPageCard.propTypes = {
  card: contentItemProptype,
};

export { ContentListPageCard };
