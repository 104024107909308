import { searchQuery } from 'api/acousticApi'
import { getLibraryByCode } from 'utils/libraryUtils'
import { getUrlCountryPath } from 'utils/urlUtils'

const WEBSITE_CONFIGURATION_CONTENT_TYPE = 'Website Configuration'

const websiteSearchParams = {
  q: '*:*',
  fl: 'name,document:[json],id',
  fq: `type:("${WEBSITE_CONFIGURATION_CONTENT_TYPE}")`
}

const params = new URLSearchParams(websiteSearchParams)

const culture = getUrlCountryPath()

const { libraryId } = getLibraryByCode(culture)

// we need to ad an additional value for the fq params, which is not possible using a javascript object
params.append('fq', `libraryId:("${libraryId}")`)

const websiteConfigQuery = () => searchQuery(params)

export { websiteConfigQuery }
