import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';

import { GenericError } from 'components/shared/form/InlineError';
import './RadioButton.scss';

const RadioButton = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
}) => {
  const { label, id, mandatory, showAsterix, size = 100 } = field;

  const value = formValues[id];
  const touched = touchedFields[id];
  const inlineError = inlineErrors[id];
  const viewableError = GenericError && touched;
  const {
    anonymousRadioButtonYesLabel,
    anonymousRadioButtonNoLabel,
    featureSwitchDisplayNewFormFields,
    
  } = useWebsiteConfigObject();

  const [changed, isChanged] = useState(value);
  const onChange = (status) => {
    setFormValues((prevState) => {
      return ({
      ...prevState,
      [id]: prevState[id] !== "" ? !prevState[id] : status,
    })});
    setTouchedFields((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };
  
  const changeRadio = (status) => {
    if (status !== changed && changed !== "") {
      isChanged(!changed);
      onChange();
    }
    if(changed === ""){
      isChanged(status);
      onChange(status);
    }
  };

  return (
    <div className='field-radio-buttons'>
      {label && !featureSwitchDisplayNewFormFields && <p className='consent-label'>{label}</p>}
      <div className='radio-buttons'>
        <div className='radio-button-wrap'>
          <div
            className={`radio-button${changed && changed !== "" ? ' active' : ''}`}
            onClick={() => changeRadio(true)}
          >
            <div className='radio-bg'></div>
          </div>
          <label htmlFor='consent1'>{anonymousRadioButtonYesLabel || "Yes"}</label>
        </div>
        <div className='radio-button-wrap'>
          <div
            className={`radio-button${!changed && changed !== "" ? ' active' : ''}`}
            onClick={() => changeRadio(false)}
          >
            <div className='radio-bg'></div>
          </div>
          <label htmlFor='consent2'>{anonymousRadioButtonNoLabel || "No"}</label>
        </div>
      </div>
      {viewableError && inlineError && <p className='form-inline-error-text'>{inlineError}</p>}
    </div>
  );
};

export { RadioButton };
