import React from 'react'
import { useSelector } from 'react-redux';

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import ContentCard from 'modules/contentCard/ContentCard'
import SideNavigation from 'modules/sideNavigation/SideNavigation'

import { getValue } from 'utils/contentUtils'
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { References } from 'components/content/References'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode';
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
} from 'components/layout/ScreenWidthContext';
import { AppConfig } from 'AppConfig';

import './MainPage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'

const MainPage = ({ content }) => {
    const {
        title,
        headerText,
        headerLogo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        subtitle,
        tabSectionTitle,
        references,
        subnavigation,
        carousel,
        textCard,
        logoCard,
        relatedContent,
        bannerImage,
        basePage,
        veevaApprovalCode
    } = content.elements
    console.log("main page", veevaApprovalCode?.value)
    const { redesign } = useSelector(state => state.ui);
    const {pageBackgroundImage, pageBackgroundImagemobile} = basePage?.value || {};
    let bgImage = {
        backgroundImage: `url(${pageBackgroundImage?.url ? AppConfig.acousticApi.domain + pageBackgroundImage?.url : null})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0% 0%',
        backgroundSize: "contain",
      }
      let bgImageMobile = {
        backgroundImage: `url(${pageBackgroundImagemobile?.url ? AppConfig.acousticApi.domain + pageBackgroundImagemobile?.url : null})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0% 0%',
        backgroundSize: "contain",
      }
    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

    const settings = {
        infinite: false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }
    const settingsCarousel1 = {
        infinite: carousel?.values && carousel?.values[0]?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        autoplay: isSmall ? false  : carousel?.values && carousel?.values[0]?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.values && carousel?.values[0]?.elements?.automationInterval?.value ? parseFloat(carousel?.values[0]?.elements?.automationInterval?.value) * 1000 : 2000,

        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }
    const settingsCarousel2 = {
        infinite: carousel?.values && carousel?.values[1]?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        autoplay: isSmall ? false  : carousel?.values && carousel?.values[1]?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.values && carousel?.values[1]?.elements?.automationInterval?.value ? parseFloat(carousel?.values[1]?.elements?.automationInterval?.value) * 1000 : 2000,

        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }
    if(redesign){
        return (
            <section role='main' className='global_layout' data-layout-type='main-page'>
                <MainAreaTitleSection
                    title={title && title.value}
                    description={headerText}
                    category={subtitle && subtitle.value}
                    logo={headerLogo}
                    backgroundImage={headerBackgroundImage}
                    backgroundHexColour={headerBackgroundHexColour}
                    fontHexColour={headerTitleHexColour}
                />
    
                <section style={isSmall ? bgImageMobile : bgImage} >
                    <div className='container'>
                        <div className='container_inner'>
                            <section className='content_wrapper content_wrapper--columns-2-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>
    
                            <section className='content_wrapper content_wrapper--columns-2-center content_wrapper--columns-2-center--stretch'>
                                {logoCard?.values?.length > 0 && (
                                    <div className='content_item'>
                                        <RelatedContent
                                            carouselSettings={settings}
                                            content={logoCard}
                                            componentType='cards'
                                            bgColor='white'
                                            cardTheme='redesign'
                                            alternateCardBg
                                            removeTopPadding
                                            removeLeftPadding
                                            fluidRight
                                        />
                                    </div>
                                )}
                                {carousel?.values && carousel?.values[0] && (
                                    <div className='content_item'>
                                        <RelatedContent
                                            carouselSettings={settingsCarousel1}
                                            content={{ value: { ...carousel?.values[0] } }}
                                            componentType='cards'
                                            bgColor='white'
                                            cardTheme='redesign'
                                            alternateCardBg
                                            removeTopPadding
                                            removeRightPadding
                                            fluidRight
                                        />
                                    </div>
                                )}
                                {carousel?.values && carousel?.values[1] && (
                                    <div className='content_item'>
                                        <RelatedContent carouselSettings={settingsCarousel2} bgColor='white' content={{ value: { ...carousel?.values[1] } }} componentType='title-cards' fluidRight />
                                    </div>
                                )}
                                {textCard?.value && (
                                    <div className='content_item'>
                                        <RelatedContent headingText={tabSectionTitle?.value} bgColor='white' fluidRight>
                                            <ContentCard
                                                heading={getValue(textCard.value?.elements?.title)}
                                                content={textCard.value?.elements?.bodyCopy}
                                                category={getValue(textCard.value?.elements?.cardCategory)}
                                                href={getValue(textCard.value?.elements?.externalLink)}
                                                maxWidth={765}
                                                linkText={
                                                    textCard.value?.elements?.footerText?.value
                                                        ? textCard.value.elements.footerText.value
                                                        : textCard.value?.elements?.displayLearnMoreOrFooterText?.value?.selection
                                                }
                                                alternateBg
                                                clickable
                                            />
                                        </RelatedContent>
                                    </div>
                                )}
                                <div className='content_item'>
                                    {relatedContent && (
                                        <RelatedContent content={relatedContent} componentType='cards' bgColor='#ffffff' carouselSettings={settings} cardTheme='secondary' alternateCardBg={true} />
                                    )}
                                </div>
                            </section>
                            
                            <section className='content_wrapper content_wrapper--columns-2-right content_wrapper--columns-2-right--shrink'></section>
                        </div>
                        {bannerImage && <BannerImage image={bannerImage} />}
                        {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                        {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
                        {references && <References references={references} />}
                    </div>
                </section>
            </section>
        )
    }
    return (
        <section role='main' className='global_layout' data-layout-type='main-page'>
            <MainAreaTitleSection
                title={title && title.value}
                description={headerText}
                category={subtitle && subtitle.value}
                logo={headerLogo}
                backgroundImage={headerBackgroundImage}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-2-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>

                        <section className='content_wrapper content_wrapper--columns-2-center content_wrapper--columns-2-center--stretch'>
                            {logoCard?.values?.length > 0 && (
                                <div className='content_item'>
                                    <RelatedContent
                                        carouselSettings={settings}
                                        content={logoCard}
                                        componentType='cards'
                                        bgColor='white'
                                        cardTheme='primary'
                                        alternateCardBg
                                        removeTopPadding
                                        removeLeftPadding
                                        fluidRight
                                    />
                                </div>
                            )}
                            {carousel?.values && carousel?.values[0] && (
                                <div className='content_item'>
                                    <RelatedContent
                                        carouselSettings={settingsCarousel1}
                                        content={{ value: { ...carousel?.values[0] } }}
                                        componentType='cards'
                                        bgColor='white'
                                        cardTheme='primary'
                                        alternateCardBg
                                        removeTopPadding
                                        removeRightPadding
                                        fluidRight
                                    />
                                </div>
                            )}
                            {carousel?.values && carousel?.values[1] && (
                                <div className='content_item'>
                                    <RelatedContent carouselSettings={settingsCarousel2} content={{ value: { ...carousel?.values[1] } }} componentType='title-cards' fluidRight />
                                </div>
                            )}
                            {textCard?.value && (
                                <div className='content_item'>
                                    <RelatedContent headingText={tabSectionTitle?.value} bgColor='white' fluidRight>
                                        <ContentCard
                                            heading={getValue(textCard.value?.elements?.title)}
                                            content={textCard.value?.elements?.bodyCopy}
                                            category={getValue(textCard.value?.elements?.cardCategory)}
                                            href={getValue(textCard.value?.elements?.externalLink)}
                                            maxWidth={765}
                                            linkText={
                                                textCard.value?.elements?.footerText?.value
                                                    ? textCard.value.elements.footerText.value
                                                    : textCard.value?.elements?.displayLearnMoreOrFooterText?.value?.selection
                                            }
                                            alternateBg
                                            clickable
                                        />
                                    </RelatedContent>
                                </div>
                            )}
                            <div className='content_item'>
                                {relatedContent && (
                                    <RelatedContent content={relatedContent} componentType='cards' bgColor='#ffffff' carouselSettings={settings} cardTheme='secondary' alternateCardBg={true} />
                                )}
                            </div>
                        </section>
                        
                        <section className='content_wrapper content_wrapper--columns-2-right content_wrapper--columns-2-right--shrink'></section>
                    </div>
                    {bannerImage && <BannerImage image={bannerImage} />}
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />}
                    {references && <References references={references} />}
                </div>
            </section>
        </section>
    )
}

export default MainPage
