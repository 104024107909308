import React, { useState } from 'react';
import Link from 'components/reusableComponents/link/Link';

import { getCourseDate } from 'components/pageTemplates/webinar/webinarHelpers';
import { deleteCourse } from 'api/dynamoApi';
import { Bin } from './SvgIcons';
import { Loader, TINY_LOADER } from 'components/shared/Loader';
import { useHistory } from 'react-router-dom';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import './CompletedCourseCard.scss';

const CompletedCourseCard = ({
  item,
  content,
  userId,
  token,
  removeCourse,
}) => {
  const {
    registeredDateLabel,
    completedLabel,
    completedDateLabel,
    startDateLabel,
    removeCourseTooltipText,
    removeCourseAlertMessage,
  } = content;
  const history = useHistory();

  const {trackContinueCourses} = useGoogleAnalytics();

  const [mouseOver, setMouseOver] = useState(false);
  const [removing, setRemove] = useState(false);

  const {
    recordId
  } = item;

  const {
    registration,
    started,
    url,
    name,
    completed,
    externalId,
  } = item?.data;
  const substring = 'https';
  let redirect = url;
  if (url.includes(substring)) {
    let domain = new URL(url);
    domain = domain.hostname;
    redirect = domain;
  }

  const deleteCourseAction = () => {
    setMouseOver(false);
    let body = {};
    body['userId'] = userId;
    body['externalId'] = externalId;
    if(recordId){
      body['recordId'] = recordId;
    }
    if (window.confirm(removeCourseAlertMessage?.value)) {
      setRemove(true);
      deleteCourse({ body, token })
        .then((response) => {
          if (response.status === 200) {
            removeCourse(externalId);
            setRemove(false);
          }
        })
        .catch((error) => {
          setRemove(false);
          console.log(error);
        });
    } else {
      setRemove(false);
    }
  };
  const callGA = ()=>{
    trackContinueCourses(name,userId);
  }

  return (
    <div className='completed-course-card'>
      <div className='completed-course-card__title'>
        <h3>{name}</h3>
      </div>
      <div className='completed-course-card__dates'>
        <div className='completed-course-card__dates__registered'>
          <span className='label'>
            {registeredDateLabel?.value
              ? registeredDateLabel?.value
              : 'Registered '}
          </span>
          <span className='date'>{getCourseDate(registration)}</span>
        </div>
        <div className='completed-course-card__dates__start'>
          <span className='label'>{startDateLabel?.value || 'Start Date'}</span>
          <span className='date'>{getCourseDate(started)}</span>
        </div>
        <div className='completed-course-card__dates__end'>
          <span className='label'>
            {completedDateLabel?.value || 'End Date'}
          </span>
          <span className='date'>{getCourseDate(completed)}</span>
        </div>
      </div>
      <div className='completed-course-card__continue'>
        <div className='completed-course-card__continue__btn'>
          <Link href={redirect} onClick={callGA}>
            <span>
              {completedLabel?.value ? completedLabel?.value : 'Continue'}
            </span>
          </Link>
        </div>
      </div>
      <div
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
        onClick={deleteCourseAction}
        className='delete-btn'
      >
        {removing && <Loader size={TINY_LOADER} />}
        {!removing && <Bin />}
      </div>
      <div className={`delete-btn-tooltip${mouseOver ? ' active' : ''}`}>
        {removeCourseTooltipText?.value}
      </div>
    </div>
  );
};

export default CompletedCourseCard;
