import React from 'react';
import PropTypes from 'prop-types';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { ChevronRight } from 'components/shared/SvgIcons';
import Link from 'components/reusableComponents/link/Link';

const TextCardLearnMore = ({
  text,
  onClickPath,
  ctaBackground,
}) => {
  const { tLearnMore } = useWebsiteConfigObject();
  const usedText = text ? text : tLearnMore;
  const ctaStyle = {
    color: ctaBackground?.value
      ? ctaBackground?.value
      : '#ea5504',
  };
  return (
    onClickPath
      ? (
        <Link
          className="learn-more"
          href={onClickPath}
          vvClickAttr='click'
        >
          <p className="learn-more-text" style={ctaStyle}>
            {usedText}
          </p>
          <div className="svg-container">
            <ChevronRight color={ctaBackground?.value} />
          </div>
        </Link>
      )
      : (
        <div className="learn-more">
          <p className="learn-more-text" style={ctaStyle}>
            {usedText}
          </p>
          <div className="svg-container">
            <ChevronRight color={ctaBackground?.value} />
          </div>
        </div>
      )
  );
};



TextCardLearnMore.propTypes = {
  text: PropTypes.string,
  onClickPath: PropTypes.string,
};

export { TextCardLearnMore };
