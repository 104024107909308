import './CountryByContinent.scss';
import CountryList from '../CountryList/CountryList'
import { useState } from 'react';
import { OpenClosedIndicator } from '../../../components/shared/OpenClosedIndicator';



const CountryByContinent = ({ content }) => {
    const {
        collapsible,
        countries,
        title
    } = content?.elements;

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }
    return (
        <div>
            {
                collapsible?.value ? (<div className={`container_auto_layout container_auto_layout--collapsible`} onClick={toggleCollapse}>
                    <OpenClosedIndicator open={collapsed} />
                    <p>{title?.value} </p>
                </div>) : <div className="container_auto_layout">
                    <p>{title?.value}</p>
                </div>
            }
            <div className={`container_country${collapsed ? ' collapsed' : ''}`}>
                {
                    countries.values?.map((elements) => {
                        return <CountryList key={elements.id} content={elements} isGlobal={true} />
                    })
                }
            </div>
        </div>
    );
}

export default CountryByContinent;