import { AppConfig } from 'AppConfig';

const {
  domain,
  apiPath,
  createTokenPath,
  userPath,
  deleteUserPath,
  consentPath,
  extendedprofilePath,

  // Proxy paths
  proxyDomain,
  controllerPath,
  validateUserPath,
} = AppConfig.authApi.owa;

// API paths
const API_URL = `${domain}/${apiPath}`;
const CREATE_TOKEN_URL = `${API_URL}/${createTokenPath}`;
const EXTENTED_PROFILE_PATH = `${extendedprofilePath}`;
const USER_URL = `${API_URL}/${userPath}`;
const DELETE_USER_URL = `${USER_URL}/${deleteUserPath}`;
const UPDATE_CONSENT_URL = `${USER_URL}/${consentPath}`;

// Proxy paths
const PROXY_URL = `${proxyDomain}/${controllerPath}`;
const VALIDATE_USER_URL = `${PROXY_URL}/${validateUserPath}`;



export {
  USER_URL,
  CREATE_TOKEN_URL,
  DELETE_USER_URL,
  VALIDATE_USER_URL,
  UPDATE_CONSENT_URL,
  EXTENTED_PROFILE_PATH,
};
