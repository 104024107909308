import React, { useRef, useState } from 'react'
import './EmbeddedPdf.scss'

import PropTypes from 'prop-types'
import { useScreenWidth } from 'components/layout/ScreenWidthContext'
import { usePdf } from '@mikecousins/react-pdf'

const EmbeddedPdf = ({pdf }) => {

  const prev = '<'
  const next = '>'

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  // Fluid Responsive Fix
  const { width } = useScreenWidth()
  const getScale = (MaxArea = 1440, MinArea = 375, MinSize = 0.5, MaxSize = 2.1) => {
    if(width >= MaxArea) {
      return MaxSize
    }else if (width <= MinArea){
      return MinSize
    } else {
      return Math.floor(MaxSize * (width * 100 / MaxArea) / 100 * 100) / 100
    }
  }

  const onDocumentLoadSuccess = ({
    numPages: newNumPages,
  }) => {
    setNumPages(newNumPages);
  }

  const prevPage = () => setPageNumber((prevState) => {
    const newPage = prevState - 1
    if (newPage < 1) {
      return 1
    }
    return newPage
  })
  const onDocumentLoadFail = () => {
    console.log("error")
  }
  const nextPage = () => setPageNumber((prevState) => {
    const newPage = prevState + 1
    if (newPage > numPages) {
      return numPages
    }
    return newPage
  })

  const showControls = numPages > 1

  // Canvas
  const canvasRef = useRef(null)
  usePdf({ canvasRef, file: pdf, page:pageNumber, scale:getScale(), onDocumentLoadSuccess, onDocumentLoadFail})

  return (
    <div className="embedded-pdf-comonent">
      <div className="embedded-pdf-container">
  
        <canvas ref={canvasRef} />

        {
          showControls && (
            <div className="embedded-pdf-controls">
              <button
                type="button"
                onClick={prevPage}
              >
                {prev}
              </button>
              <span>{`${pageNumber} of ${numPages}`}</span>
              <button
                type="button"
                onClick={nextPage}
              >
                {next}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}


EmbeddedPdf.propTypes = {
  pdf: PropTypes.string
}

export { EmbeddedPdf };
