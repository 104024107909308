import React, { Fragment, useEffect } from 'react';
import './CountrySelector.scss';

import Image from 'components/reusableComponents/image/Image';

import { getUrlCountryPath } from 'utils/urlUtils';
import { getLibraryByCode, getLibrariesByRegion } from 'utils/libraryUtils';
import { useDispatch, useSelector } from 'react-redux';
import { closeLibraryMenu, setCountryLibrary, toggleLibraryMenu } from 'redux/ui/slice';
import { useLocation } from 'react-router';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { NewDownArrow } from 'components/shared/SvgIcons';

const CountrySelector = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { redesign } = useSelector(state => state.ui);
  // Library popup State
  const { libraryMenuStatus } = useSelector((state) => state.ui);

  //Get webconfig - dynamic retrieval
  let { tCountrySelector, disableCountrySwitcher } = useWebsiteConfigObject();

  let getContent = usePageContent(tCountrySelector?.id);  //Get content dynamically

  //Verify if the country selector have been populated correctly else use the existing implementation
  let dynamicContent = (tCountrySelector !== null && tCountrySelector !== undefined) ? true : false;

  // Close library popup when location changed
  const { pathname } = useLocation();
  useEffect(() => {
    dispatch(closeLibraryMenu());
  }, [pathname, dispatch]);

  // Handle country selection
  const handleClick = ({ evt, library }) => {
    evt.preventDefault();

    const { country, nationality, code } = library || {};
   

    // Todo - Add translations
    const confirmed = window.confirm(
      `Materials on the ${country} site are certified for\n${nationality} health care professionals only.`
    );

    if (confirmed) {
      window.location = `/${code}`;
    }


  };

  //Handle dynamic country selection
  const linkCountryHandler = (evt, content) => {
    evt.preventDefault();
    const { nationality, countryName, alertMessage, url } = content?.elements || {};
    let staticMessage = getContent?.content?.elements?.alertMessage?.value;
    let alertMessages = alertMessage?.value;
    if(staticMessage){
      staticMessage = countryName?.value ? staticMessage?.replaceAll('{country}', countryName?.value) : staticMessage
      staticMessage = nationality?.value ? staticMessage?.replaceAll('{nationality}', nationality?.value) : staticMessage
    }
    if(alertMessages){
      alertMessages = countryName?.value ? alertMessages?.replaceAll('{country}', countryName?.value) : alertMessages
      alertMessages = nationality?.value ? alertMessages?.replaceAll('{nationality}', nationality?.value) : alertMessages
    }
    let alertMsg = alertMessages || staticMessage || `Materials on the ${countryName?.value} site are certified for\n${nationality?.value} health care professionals only.`;

    const confirmed = window.confirm(
      alertMsg
    );
    if (confirmed) {
      window.location = url?.value;
    }
  };

  // Close Popup
  const closeLibraryPopup = () => {
    dispatch(closeLibraryMenu());
  };

  // Toggle Popup
  const toggleLibraryPopup = () => {
    if(!(redesign && disableCountrySwitcher?.value)) {
      dispatch(toggleLibraryMenu());
    }
  };

  const keyPress = (e) => {
    if (e.charCode === 13) toggleLibraryPopup();
  };

  const countrycode = getUrlCountryPath();

   // Current Library
   const currentLibrary = getLibraryByCode(getUrlCountryPath());   //Get some information about the country selector

   // Active Libraries
   const activeLibrariesByRegion = getLibrariesByRegion();   //List of country - country list

   // Get Flags
   const flags = require.context('assets/images/flags/', true);

   //Set number of row to be displayed - for list of country
   const rowDisplay = 10;

   //get number of country value
   const countryLength = Object.keys(activeLibrariesByRegion).map((region, i) => {
     return activeLibrariesByRegion[region]?.length;
   });

   //Display per row
   const countryDisplay = (countryLength > rowDisplay) ? Math.ceil(countryLength / rowDisplay) : 1;

   //Country main flag
  //  let countryCode = (dynamicContent === true) ? (getContent?.content?.elements?.defaultCountry?.value?.elements?.locale?.value !== undefined) ? getContent?.content?.elements?.defaultCountry?.value?.elements?.locale?.value : currentLibrary.code : currentLibrary.code;
   let countryCode = currentLibrary.code;

   let countryTitleText = (dynamicContent === true) ? getContent?.content?.elements?.heading?.value : 'Current Country/Region and Language options';

   let countrySubText = (dynamicContent === true) ? getContent?.content?.elements?.subHeading?.value : 'Materials for every country are designed for a health care professional only and certified for that country.';

   let countryRegion = (dynamicContent === true) ? getContent?.content?.elements?.region?.value : 'EMEA';

   //Dynamic content
   let countryList = (dynamicContent === true) ? getContent?.content?.elements?.countryList?.values : null;

   let countryRegions = (dynamicContent === true) ? getContent?.content?.elements?.countryRegion?.values : null;

   let noCountryDisplay = (getContent?.content?.elements?.countryList?.values.length > rowDisplay) ? Math.ceil(getContent?.content?.elements?.countryList?.values.length / rowDisplay) : 1;

   let countryTitle = (dynamicContent === true) ? getContent?.content?.elements?.defaultCountry?.value?.elements?.linkLabel?.value : currentLibrary?.title;

   const currentCountry = countryList?.find(country => country?.elements?.url?.value?.includes(countrycode))

   useEffect(() => {
    if(currentCountry) {
      const { countryName, linkLabel, nationality, locale } = currentCountry.elements;
      dispatch(setCountryLibrary({
        countryName: countryName?.value,
        title: linkLabel?.value,
        nationality: nationality?.value,
        code: locale?.value
      }))
    } else {
      const { country, title, nationality, code } = currentLibrary;
      dispatch(setCountryLibrary({
        countryName: country,
        title,
        nationality,
        code
      }))
    }
   }, [currentLibrary, currentCountry, dispatch]);

   if(currentCountry) {
    countryTitleText = currentCountry.elements?.linkLabel?.value ? countryTitleText.replaceAll('{country}', currentCountry.elements?.linkLabel?.value.split('-')[0].trim()) : countryTitleText;
   }

  if(countrycode.length === 0){
    return '';
  }

   let def = null;
   if(countryRegions && countryRegions.length > 0){
     countryRegions.filter(region => {
      const { countries } = region?.elements;
      return countries?.values.filter(country => {
        if(countryCode === country.elements.locale.value){
          def = country;
        }
        return countryCode === country.elements.locale.value;
      })
     })
   }
   if (!def && dynamicContent === true ) {
    countryTitle = currentLibrary?.title;
   }
  return (
    <div className={`CountrySelector_Wrapper${redesign && disableCountrySwitcher?.value ? " CountrySelector_Wrapper--disabled" : ""}`}>
      <div
        className={`CountrySelector ${isMobile ? 'Mobile' : 'Desktop'} ${
          libraryMenuStatus ? 'icon-up' : 'icon-down'
        }`}
        onClick={toggleLibraryPopup}
        onKeyPress={keyPress}
        tabIndex='0'
      >
        {dynamicContent === false ? ( Object.keys(activeLibrariesByRegion).map((region, i) => {
          return (
            <>
              <img
                src={flags(`./${countryCode}.png`).default}
                alt={countryCode}
                width='24'
              />
              <p>{countryTitle}</p>
              {redesign && !disableCountrySwitcher?.value && <NewDownArrow />}
            </>
          );
        })) : (
          <>
            {def?.elements?.flag ? <Image data={def?.elements?.flag} alt={def?.elements?.linkLabel?.value}/> : 
              <img
                src={flags(`./${countryCode}.png`).default}
                alt={countryCode}
                width='24'
              />
            }
            <p>
              {def?.elements?.linkLabel?.value ? def?.elements?.linkLabel?.value : countryTitle}
            </p>
            {redesign && !disableCountrySwitcher?.value && <NewDownArrow />}
          </>
        )}
      </div>

      <div
        className={`CountryPopup ${
          libraryMenuStatus ? 'CountryPopup--open' : ''
        }`}
      >
        <div className='CountryPopup_Container'>
          <div className='CountryPopup_Content'>
            <div className='CountryPopup_Header'>
              <div className='CountryPopup_Heading'>
                <p>
                  {countryTitleText}
                </p>
                <div className='CountryPopup_Close' onClick={closeLibraryPopup}>
                  <span />
                  <span />
                </div>
              </div>
              <div className='CountryPopup_SubHeading'>
                <p>
                  {countrySubText} 
                </p>
              </div>
            </div>

            <div className='CountryPopup_List' data-vv-action='remove'data-vv-siteEntry="false" >
              {dynamicContent === false ? ( Object.keys(activeLibrariesByRegion).map((region, i) => {
                return (
                  <Fragment key={i}>
                  {countryRegion && <h3>{countryRegion}</h3>}
                  <div className='CountryPopup_Region' key={i}>
                    
                    {/* Slice the list of country per limit */}
                    {[...Array(countryDisplay)].map((key, index) => {

                      let startSlice = index * rowDisplay,
                          endSlice = (index+1) * rowDisplay;
                          index++;                          

                      return (
                        <div className='CountryPopup_RegionList' key={index}>
                          {/*
                            1- Sorting the country array by ascending order
                            2- Slicing the arrow for 2 columns (can be more - code is dynamic)
                            3- Constructing the HTML
                          */}
                          {activeLibrariesByRegion[region]?.sort((x,y) => {
                            let a = x.title.toUpperCase(),
                                b = y.title.toUpperCase();
                            return a == b ? 0 : a > b ? 1 : -1;
                          })
                          .slice(startSlice,endSlice)
                          .map((library, index) => {
                            if(library.code){
                            if (currentLibrary.code === library.code) {
                              return (
                                <span
                                  key={library.code}
                                  className='CountryPopup_Current'
                                >
                                  <img
                                    src={flags(`./${library.code}.png`).default}
                                    alt={library.code}
                                  />
                                  {library?.title}
                                </span>
                              );
                            }

                            return (
                              <a
                                className='CountryPopup_Link'
                                href={`/${library?.code}`}
                                key={library?.libraryId + index}
                                onClick={(evt) => {
                                  handleClick({ evt, library });
                                }}
                                data-vv-action='remove'
                              >
                                <img
                                  src={flags(`./${library?.code}.png`).default}
                                  alt={library?.code}
                                />
                                {library?.title}
                              </a>
                            );


                          }})}
                        </div>
                      )
                    })}
                    {/* <div className='CountryPopup_RegionList'>
                      {activeLibrariesByRegion[region]?.map((library) => {

                        if (currentLibrary.code === library.code) {
                          return (
                            <span
                              key={library.code}
                              className='CountryPopup_Current'
                            >
                              <img
                                src={flags(`./${library.code}.png`).default}
                                alt={library.code}
                              />
                              {library?.title}
                            </span>
                          );
                        }

                        return (
                          <a
                            className='CountryPopup_Link'
                            href={`/${library?.code}`}
                            key={library?.libraryId}
                            onClick={(evt) => {
                              handleClick({ evt, library });
                            }}
                          >
                            <img
                              src={flags(`./${library.code}.png`).default}
                              alt={library.code}
                            />
                            {library?.title}
                          </a>
                        );


                      })}
                    </div> */}
                  </div>
                  </Fragment>
                );
              })) : (
                <>
                  {/* Dynamic content */}
                  {countryRegion && <h3>{countryRegion}</h3>}
                  <div className='CountryPopup_Region'>
                    {/* Slice the list of country per limit */}
                    {countryRegions && countryRegions.map((region, key) => {
                      const { countries, title } = region?.elements;
                      return (
                        <div className='CountryPopup_RegionList' key={key}>
                          {title?.value && <h3>{title?.value}</h3>}
                          {countries?.values && countries?.values.map((country, key) => {
                            if (countryCode === country.elements.locale.value) {
                              return (
                                <span
                                  key={country.elements.locale.value}
                                  className='CountryPopup_Current'
                                >
                                  {country?.elements?.flag && <Image data={country?.elements?.flag} alt={country?.elements?.linkLabel?.value}/>}
                                  {country.elements.linkLabel.value}
                                </span>
                              );
                            } else {
                              return (
                                <a className='CountryPopup_Link'
                                  href={`/${country.elements.locale.value}`}
                                  key={country.id}
                                  onClick={(evt) => {
                                    linkCountryHandler(evt, country);
                                  }}
                                >
                                  {country?.elements?.flag && <Image data={country?.elements?.flag} alt={country?.elements?.linkLabel?.value}/>}
                                  {country.elements.linkLabel.value}
                                </a>
                              );
                            }
                          })}
                        </div>
                      )
                    })}
                    {!countryRegions && [...Array(noCountryDisplay)].map((key, index) => {
                      
                      let startSlice = index * rowDisplay,
                          endSlice = (index+1) * rowDisplay;
                        index++;     
                      
                      return (
                        <div className='CountryPopup_RegionList' key={index}>
                          {/*
                            1- Sorting the country array by ascending order
                            2- Slicing the arrow for 2 columns (can be more - code is dynamic)
                            3- Constructing the HTML
                          */}
                          {countryList?.sort((x,y) => {
                            let a = x.elements.linkLabel.value.toUpperCase(),
                                b = y.elements.linkLabel.value.toUpperCase();
                            return a == b ? 0 : a > b ? 1 : -1;
                          })
                          .slice(startSlice,endSlice)
                          .map((content) => {
                            if (countryCode === content.elements.locale.value) {
                              return (
                                <span
                                  key={content.elements.locale.value}
                                  className='CountryPopup_Current'
                                >
                                  {content?.elements?.flag && <Image data={content?.elements?.flag} alt={content?.elements?.linkLabel?.value}/>}
                                  {content.elements.linkLabel.value}
                                </span>
                              );
                            } else {
                              return (
                                <a className='CountryPopup_Link'
                                  href={`/${content.elements.locale.value}`}
                                  key={content.id}
                                  onClick={(evt) => {
                                    linkCountryHandler(evt, content);
                                  }}
                                >
                                  {content?.elements?.flag && <Image data={content?.elements?.flag} alt={content?.elements?.linkLabel?.value}/>}
                                  {content.elements.linkLabel.value}
                                </a>
                              );
                            }

                          })}
                        </div>
                      )

                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountrySelector;
