import React from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';

import './Checkbox.scss';

const NewCheckBox = ({
  checkboxNumber,
  field,
  fieldType,
  formValues,
  setFormValues,
  setTouchedFields,
}) => {
  const {
    label,
    id,
    mandatory,
    showAsterix,
    value,
    size = 100,
  } = field;
  const checkedValues = formValues[id];
  const checkboxID = id+checkboxNumber;

  const onChange = (e) => {
    let value = e.target.value;
    let checkboxValues = checkedValues || "";
    if(checkboxValues === ""){
      checkboxValues = value;
    } else if(checkboxValues.includes(value)){
      if(checkboxValues.includes(","+value)){
        checkboxValues = checkboxValues.replace(","+value, '');
      } else if (checkboxValues.includes(value)) {
        checkboxValues = checkboxValues.replace(value, '');
      }
    } else {
      checkboxValues = checkboxValues+","+value;
    }
    setFormValues((prevState) => ({
      ...prevState,
      [id]: checkboxValues,
    }));
    setTouchedFields((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-checkbox`,
    `${fieldBaseClassName}--${size}`,
  ];

  return (
    <div
      className={fieldClassNames.join(' ')}
    >
      <input
        className={`checkbox checkbox-${checkedValues.includes(value) ? 'checked' : 'unchecked'} custom-focus`}
        required={mandatory}
        id={checkboxID}
        name={id}
        value={value}
        onChange={onChange}
        type="checkbox"
        checked={checkedValues.includes(value)}
      />
      <div className="field-meta-data">
        {
          label && (
            <label
              htmlFor={checkboxID}
            >
              {label}
            </label>
          )
        }
      </div>
    </div>
  );
};

NewCheckBox.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
  formValues: formValuesPropTypes,
  setFormValues: PropTypes.func,
  inlineErrors: inlineErrorsPropTypes,
  touchedFields: touchedPropTypes,
  setTouchedFields: PropTypes.func,
};


export {
  NewCheckBox,
};


