import React, {useRef,useState} from "react";
import './Audio.scss';

const Audio = (props) => {
	const  playIcon = `
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
</svg>
`,
pauseIcon = `
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
</svg>
`,
soundIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
<path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
</svg>`,
muteIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
<path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
</svg>`;


let i=0;
	function callAudio () {
		i++
		const playerButton = document.querySelector('.player-button'),
		audio = document.querySelector('audio'),
		timeline = document.querySelector('.timeline'),
		soundButton = document.querySelector('.sound-button');
	playerButton.addEventListener('click', toggleAudio(playerButton,
	audio,
	timeline,
	soundButton));
	let time
	if(i ===1){
		time = (0 * audio.duration) / 100;
	}
	else{
		time = (timeline.value * audio.duration) / 100;
	}
	audio.currentTime = time;
		audio.ontimeupdate = changeTimelinePosition;
	}

	function toggleAudio (playerButton,audio,	timeline,soundButton) {
		// changeSeek(audio.currentTime)
		if (audio.paused) {
			audio.play();
			playerButton.innerHTML = pauseIcon;
		} else {
			audio.pause();
			playerButton.innerHTML = playIcon;
		}
	}
	function toggleSound () {
		let test= document.getElementById("audio")
		let mute= document.getElementById("soundButton")
		test.muted = !test.muted;
		mute.innerHTML = test.muted ? muteIcon : soundIcon;
	}
	function changeSeek () {
		let audio= document.getElementById("audio"),
		timeline = document.querySelector('.timeline');
		// setValueTimeline(timeline.value)
		const time = (timeline.value * audio.duration) / 100;
		audio.currentTime = time;
		audio.ontimeupdate = changeTimelinePosition;
			
	}
	
	
		function changeTimelinePosition () {
			let timeline = document.querySelector('.timeline');
			let audio = document.getElementById("audio");
			const percentagePosition = (100*audio.currentTime) / audio.duration;
			timeline.style.backgroundSize = `${percentagePosition}% 100%`;
			timeline.value = percentagePosition;
			// setValueTimeline(percentagePosition)
			}
	// document.getElementsByClassName("timeline").value=0;
	
	return (
		<div className="audio-player" id="audio-player">
			<audio src={props.url} type={props.type} id="audio" ontimeupdate={changeTimelinePosition}></audio>
			<div className="controls" onClick={callAudio}>
				<button className="player-button" id="player-button">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
					<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
					</svg>
				</button>
				<input type="range" className="timeline" max="100" onClick={changeSeek}/>
				<button className="sound-button" onClick={toggleSound} id="soundButton">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3D3132">
					<path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
					</svg>
				</button>
			</div>
		</div>
	);
}

export default Audio;