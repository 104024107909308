import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { childrenPropTypes } from 'propTypes/generic';
import { getUrlCountryPath } from 'utils/urlUtils';
// import { getUrlCountryData } from 'utils/libraryUtils';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/authentication/slice';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';

// constants for cookie banner functionality
const DAYS_COOKIES_VALID_FOR_ACCEPTANCE = 420;
const DAYS_COOKIES_VALID_FOR_REFUSAL = 3;
// const YEAR_GA_COOKIES_VALID_FOR_REFUSAL = 10;
const daysInSeconds = (days) => days * 86400;
const minutesInSeconds = (mins) => mins * 60;
// const yearsInSeconds = (years) => daysInSeconds(365) * years;

const COOKIE_OPTIONS = {
  path: `/${getUrlCountryPath()}`,
  maxAge: daysInSeconds(DAYS_COOKIES_VALID_FOR_REFUSAL),
};

let MINS_SELF_CERTIFICATION_VALID_FOR = 10; //default 10 minutes if no value is added in Acoustic

const cookieConfirmationContext = createContext({
  showCookieBanner: true,
  analyticsCookiesAccepted: false,
  userSelfCertified: false,
  updateShowCookieBanner: () => {},
  updateAnalyticsCookiesAccepted: () => {},
  updateUserSelfCertified: () => {},
});

const CookieConfirmationProvider = ({ children }) => {
  // const { gaMeasurementId } = getUrlCountryData();

  const [cookies, setCookie, removeCookie] = useCookies([
    'showCookieBanner',
    'analyticsCookiesAccepted',
    'userSelfCertified',
    // `ga-disable-${gaMeasurementId}`
  ]);

  const { selfCertificationDurationInMinutes, cookieBannerVersion } =
    useWebsiteConfigObject();
  const cookieVersion = cookieBannerVersion?.selection;

  if (selfCertificationDurationInMinutes) {
    MINS_SELF_CERTIFICATION_VALID_FOR = selfCertificationDurationInMinutes;
  }

  if (!cookies.showCookieBanner) {
    setCookie('showCookieBanner', true, COOKIE_OPTIONS);
  }

  if (cookieVersion !== 'OneTrust' && !cookies.analyticsCookiesAccepted) {
    setCookie('analyticsCookiesAccepted', false, COOKIE_OPTIONS);
  }

  if (!cookies.userSelfCertified) {
    setCookie('userSelfCertified', false, { ...COOKIE_OPTIONS, path: '/' });
  }

  if(cookieVersion && cookieVersion === 'OneTrust') {
    removeCookie('analyticsCookiesAccepted',{ path: COOKIE_OPTIONS.path });
  }

  const [showCookieBanner, setShowCookieBanner] = useState(
    cookies.showCookieBanner === 'false' ? false : true
  );
  const [analyticsCookiesAccepted, setAnalyticsCookiesAccepted] = useState(
    cookies.analyticsCookiesAccepted === 'true' ? true : false
  );
  const [userSelfCertified, setUserSelfCertified] = useState(
    cookies.userSelfCertified === 'true' ? true : false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (cookies && cookies?.userSelfCertified === 'true') {
      dispatch(authActions.kki.guestLogin());
    } else {
      dispatch(authActions.kki.guestLogout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const updateShowCookieBanner = (value, isAnalyticsAccepted) => {
    COOKIE_OPTIONS.maxAge = isAnalyticsAccepted
      ? daysInSeconds(DAYS_COOKIES_VALID_FOR_ACCEPTANCE)
      : daysInSeconds(DAYS_COOKIES_VALID_FOR_REFUSAL);
    setCookie('showCookieBanner', value, COOKIE_OPTIONS);
    setShowCookieBanner(value);
  };

  const updateAnalyticsCookiesAccepted = (value) => {
    if (cookieVersion !== 'OneTrust') {
      COOKIE_OPTIONS.maxAge = value
        ? daysInSeconds(DAYS_COOKIES_VALID_FOR_ACCEPTANCE)
        : daysInSeconds(DAYS_COOKIES_VALID_FOR_REFUSAL);
      setCookie('analyticsCookiesAccepted', value, COOKIE_OPTIONS);
      // if(!value) {
      //   COOKIE_OPTIONS.maxAge = yearsInSeconds(YEAR_GA_COOKIES_VALID_FOR_REFUSAL);
      //   setCookie(`ga-disable-${gaMeasurementId}`, true, COOKIE_OPTIONS);
      // }
      setAnalyticsCookiesAccepted(value);
    }
  };

  const updateUserSelfCertified = (value) => {
    COOKIE_OPTIONS.maxAge = minutesInSeconds(MINS_SELF_CERTIFICATION_VALID_FOR);
    setCookie('userSelfCertified', value, { ...COOKIE_OPTIONS, path: '/' });
    setUserSelfCertified(value);
  };

  const value = {
    showCookieBanner,
    analyticsCookiesAccepted,
    userSelfCertified,
    MINS_SELF_CERTIFICATION_VALID_FOR,
    updateShowCookieBanner,
    updateAnalyticsCookiesAccepted,
    updateUserSelfCertified,
  };
  return (
    <cookieConfirmationContext.Provider value={value}>
      {children}
    </cookieConfirmationContext.Provider>
  );
};

CookieConfirmationProvider.propTypes = {
  children: childrenPropTypes,
};

export { CookieConfirmationProvider, cookieConfirmationContext };
