import React from 'react'
import { contentItemProptype } from 'propTypes/contentProptypes'

// modules
import Logo from 'modules/logo/Logo'
import Hero from 'modules/hero/Hero'
import Subnavigation from '../../modules/subNavigation/SubNavigation'
import RelatedContent from 'modules/relatedContent/RelatedContent'
import { useSelector } from 'react-redux';


// components
import { References } from 'components/content/References'
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode'
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
  } from 'components/layout/ScreenWidthContext';

// styles
import './TherapyAreaLandingPage.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'



/*
  Brand Article Page (supercedes the Content Promo Page)
  Figma design here https://www.figma.com/file/iHhNCUBqTgthhUCuU6KCbx/KKI_Hub_Release2-3?node-id=315%3A112
*/

const BrandArticlePage = ({ content }) => {
    const {      
        logo,
        brandColours,
        hero,
        title,
        header,
        subnavigation,
        carousel,
        bannerImage,
        veevaApprovalCode,
        references
    } = content.elements

    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { primary } = brandColours?.value?.elements || {}
    const { subtitle, text } = header?.value?.elements || {}
    const { redesign } = useSelector(state => state.ui);


    const settingsCarousel1 = {
        infinite: carousel?.values && carousel?.values[0]?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        autoplay: isSmall ? false  : carousel?.values && carousel?.values[0]?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.values && carousel?.values[0]?.elements?.automationInterval?.value ? parseFloat(carousel?.values[0]?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }
    const settingsCarousel2 = {
        infinite: carousel?.values && carousel?.values[1]?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        autoplay: isSmall ? false  : carousel?.values && carousel?.values[1]?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.values && carousel?.values[1]?.elements?.automationInterval?.value ? parseFloat(carousel?.values[1]?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }
    const settingsCarousel3 = {
        infinite: carousel?.values && carousel?.values[2]?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        autoplay: isSmall ? false  : carousel?.values && carousel?.values[2]?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.values && carousel?.values[2]?.elements?.automationInterval?.value ? parseFloat(carousel?.values[2]?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1365,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }

    let containerClass = "container";
    if(redesign)
    {
        containerClass = "container redesign";
    }

    return (
        <section className='therapy-area-landing-page' role="main">
            <section className='therapy-area-landing-page__logo'>
                <Logo content={logo} />
            </section>
            <section className='therapy-area-landing-page__hero'>
                <Hero content={hero} />
            </section>
            <section className='therapy-area-landing-page__header'>
                <MainAreaTitleSection
                    category={subtitle?.value}
                    title={title?.value}
                    description={text?.value?.text}
                />
            </section>           
            <section className='therapy-area-landing-page__content-section'>
                <div className={containerClass}>
                    <div className='therapy-area-landing-page__content-section-inner'>
                        <div className='therapy-area-landing-page__subnavigation'>
                            {subnavigation?.value &&
                                <Subnavigation element={subnavigation?.value} colour={primary?.value} /> 
                            }
                        </div>
                        <div className='therapy-area-landing-page__content'>
                            {carousel?.values && carousel?.values[0] && (
                                <RelatedContent
                                    carouselSettings={settingsCarousel1}
                                    content={{ value: { ...carousel?.values[0] } }}
                                    componentType='cards'
                                    alternateCardBg={true}
                                    bgColor='white'
                                    cardTheme='primary'
                                />
                            )}

                            {carousel?.values && carousel?.values[1] && (
                                <RelatedContent 
                                    carouselSettings={settingsCarousel2}    
                                    content={{ value: { ...carousel?.values[1] } }} 
                                    componentType='title-cards' 
                                />
                            )}

                            {carousel?.values && carousel?.values[2] && (
                                <RelatedContent
                                    carouselSettings={settingsCarousel3}
                                    content={{ value: { ...carousel?.values[2] } }}
                                    componentType='cards'
                                    alternateCardBg={true}
                                    bgColor='white'
                                    cardTheme='primary'
                                />
                            )}                          
                            
                        </div>                        
                    </div>
                </div>                
            </section>
            {bannerImage && <BannerImage image={bannerImage} />}
            <ApprovalCode code={veevaApprovalCode} />       
            {adverseEventsComplianceText?.value && (
                <section className='therapy-area-landing-page__references-section'>
                    <div className='container'>
                        <div className='therapy-area-landing-page__references'>
                            {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                        </div>
                    </div>
                </section>
            )}
            {references && (
                <section className='therapy-area-landing-page__references-section'>
                    <div className='container'>
                        <div className='therapy-area-landing-page__references'>
                            <References references={references} />
                        </div>
                    </div>
                </section>
            )}
        </section>
    )
}

BrandArticlePage.propTypes = {
    content: contentItemProptype,
}

export default BrandArticlePage
