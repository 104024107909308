import React, { useState, useEffect } from 'react';
import { WebinarDropdown } from './WebinarDropdown';

import './ComplexDropdown.scss';

const ComplexDropdown = (props) => {
  const { field, formValues, setFormValues } =
    props;
  const { id, optionsJson, firstValue, isSecondary, primaryKey, label, mandatory } = field;

  const [secondaryDropdown, setSecondaryDropdown] = useState("");
  const firstDropdownValue = formValues[primaryKey];

  const dropdownOption = JSON.parse(optionsJson);
  const firstDropdown = dropdownOption?.[id];
  const firstDropdownValues = dropdownOption?.[primaryKey];

  const primaryDropdownFieldProps = {
    label: label,
    values: firstDropdown,
    placeholder: firstValue,
    mandatory: mandatory,
    id: id,
  };
  const secondayDropdownFieldProps = {
    label: label,
    values: secondaryDropdown,
    placeholder: firstValue,
    mandatory: mandatory,
    id: id,
  };

  useEffect(() => {
    if (firstDropdownValue && isSecondary) {
      const state = firstDropdownValues.find(
        (item) => item.value === firstDropdownValue
      );
      if (state?.[id]) {
        setSecondaryDropdown(state?.[id]);

      } else {
        setSecondaryDropdown();
      }
    } else {
      setSecondaryDropdown();
    }
  }, [firstDropdownValue]);

  useEffect(()=>{
    if(isSecondary && !secondaryDropdown) {
      setFormValues((prevState) => ({
        ...prevState,
        [id]: "N/A",
      }));
    } else if (isSecondary && secondaryDropdown) {
      setFormValues((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    }
  },[firstDropdownValue,secondaryDropdown]);
  
  return (
    <>
      {firstDropdown && !isSecondary && (
        <WebinarDropdown {...props} field={primaryDropdownFieldProps} />
      )}
      {secondaryDropdown && isSecondary && (
        <WebinarDropdown {...props} field={secondayDropdownFieldProps} />
      )}
    </>
  );
};

export default ComplexDropdown;
