import React, {
  useEffect, createRef,
} from 'react';
import PropTypes from 'prop-types';
import { ChevronButton, BUTTON } from 'components/shared/ChevronButton';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useParams ,useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeLocation } from 'redux/location/slice';
import { LoginButtonClickFunction, RegisterButtonClickFunction } from 'utils/tealiumUtils';
import {  useHistory } from 'react-router-dom';


import './ContentGate.scss';
import RichText from 'components/reusableComponents/richText/RichText';

// This paywall is an alternative to the paywall tied to access levels for self cert markets.
// This paywall can be used for external auth markets such as markets that use DocCheck and OneKey.
const ContentGatePopUp = ({
  buttonLogo,
  loginRedirect,
  registerRedirect,
}) => {
  const {
    tContentGateTitle,
    tContentGateText,
    tContentGateLoginButtonText,
    tContentGateRegisterButtonText,
    tContentGateLeftSmallPrintText,
    tContentGateRightSmallPrintText,
    tJointVentureNotificationText
  } = useWebsiteConfigObject();

  const { pathname, search  } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
 
  dispatch(storeLocation({ pathname, search }));   

  const LoginAction = () => {
    loginRedirect();
    if(history.action === "PUSH") {
      if(window.utag) {
        LoginButtonClickFunction(tContentGateLoginButtonText ? tContentGateLoginButtonText.toUpperCase() : "LOG IN");
      } 
    }
  }
  const RegisterAction = () => {
    registerRedirect();
    if(history.action === "PUSH") {
      if(window.utag) {
        RegisterButtonClickFunction(tContentGateRegisterButtonText ? tContentGateRegisterButtonText.toUpperCase() : "REGISTER");
      } 
    }
  }
  return (    
    <div
      className='content-gate'>
      <div className="container">
        <h3 className="content-gate__title">{tContentGateTitle}</h3>

        <p className="content-gate__text">
          {tContentGateText}
        </p>

        {tJointVentureNotificationText && (
          <RichText className='paywall__text' data={{ value: tJointVentureNotificationText}} />
        )}

        <div className="content-gate__footer">
          <div className="content-gate__cta-wrapper">
            <ChevronButton
              type={BUTTON}
              text={tContentGateLoginButtonText}
              className="btn_event_login"
              variant="paywall-btn"
              chevron="right"
              image={buttonLogo}
              onClick={LoginAction}
            />
            <p className="content-gate__cta-smallprint">{tContentGateLeftSmallPrintText}</p>
          </div>
          <div className="content-gate__cta-wrapper">
            <ChevronButton
              type={BUTTON}
              text={tContentGateRegisterButtonText}
              className="btn_event_register"
              variant="paywall-btn"
              chevron="right"
              image={buttonLogo}
              onClick={RegisterAction}
            />
            <p className="content-gate__cta-smallprint">{tContentGateRightSmallPrintText}</p>
          </div>
        </div>
      </div>
    </div>
    
  )
};

ContentGatePopUp.propTypes = {
  fullPage: PropTypes.bool,
  withBlur: PropTypes.bool,
  buttonLogo: PropTypes.object,
  loginRedirect: PropTypes.func,
  registerRedirect: PropTypes.func,
};

const ContentGateContainer = ({
  isSecure,
  canvas,
  browserSupported,
  buttonLogo,
  loginRedirect,
  registerRedirect,
}) => {
  const contentGateRef = createRef();
  const { contentGateBlurLevel } = useWebsiteConfigObject();

  useEffect(() => {
    const contentWrapper = document.querySelectorAll('[role="main"]')[0]

    if (contentWrapper) {
      contentWrapper.setAttribute("id", "blurredContentWrapper");
    }

    if (contentGateRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(contentGateRef.current);
    }

    return () => {
      const blurWrapper = document.getElementById("blurredContentWrapper");

      if (blurWrapper) {
        blurWrapper.removeAttribute("id");
      }

      clearAllBodyScrollLocks();
    };
  }, [contentGateRef]);

  return (
    <>
      <div
        ref={contentGateRef}
        className="content-gate__overlay">
        {
          browserSupported && (
            <>
              <svg id="svgBlur" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <filter id="blur">
                  <feGaussianBlur stdDeviation={`${contentGateBlurLevel || 10}`} />
                </filter>
              </svg>
              {
                isSecure && <img src={canvas} alt="Blurred Content" className="blur" />
              }
            </>
          )
        }
      </div>

      <ContentGatePopUp
        buttonLogo={buttonLogo}
        loginRedirect={loginRedirect}
        registerRedirect={registerRedirect}
      />
    </>
  );
};

ContentGateContainer.propTypes = {
  canvas: PropTypes.string,
  buttonLogo: PropTypes.object,
  loginRedirect: PropTypes.func,
  registerRedirect: PropTypes.func,
};

export { ContentGateContainer as ContentGate };


