import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { verifyUserToken } from 'api/genericApi';
import { KKILogin } from 'components/pageTemplates/login/LoginPage/components/KKI';
import { OWALogin } from 'components/pageTemplates/login/LoginPage/components/OneKey';
import { DocCheckLogin } from 'components/pageTemplates/login/LoginPage/components/DocCheck';
import { KKICMS } from 'components/pages/CMSPage/components/KKI';
import { OneKeyCMS } from 'components/pages/CMSPage/components/OneKey';
import { DocCheckCMS } from 'components/pages/CMSPage/components/DocCheck';
import { browserHasLocalStorageCheck } from 'utils/localStorageUtils';

/**
 * Function to setup auth and refresh token headers
 * @param  {String} authToken    JWT token to be pass in to function
 * @param  {String} refreshToken Refresh token to be passed in to function
 * @param  {String} secret       Audience Secret to be passed in to function
 * @return {{headers: *}}
 */
const getAuthHeaderConfig = ({
  audienceUrl, authToken, refreshToken, secret,
}) => {
  const AUTHORIZATION_HEADER = 'Authorization';
  const REFRESH_TOKEN_HEADER = 'Refresh-Token';
  const AUDIENCE = 'Audience';
  const AUDIENCE_SECRET = 'Audience-Secret';

  const audienceHeader = {
    [AUDIENCE]: audienceUrl,
  };

  const audienceSecretHeader = {
    [AUDIENCE_SECRET]: secret,
  };

  const authHeader = {
    [AUTHORIZATION_HEADER]: authToken,
  };

  const refreshTokenHeader = {
    [REFRESH_TOKEN_HEADER]: refreshToken,
  };

  return (
    {
      headers: {
        ...(audienceUrl ? audienceHeader : ''),
        ...(secret ? audienceSecretHeader : ''),
        ...(authToken ? authHeader : ''),
        ...(refreshToken ? refreshTokenHeader : ''),
      },
    }
  );
};

/**
 * Checks if there is auth data in storage. 
 * If there is none user may have logged outin a seperate tab so log them out in current tab.
 * 
 * @return {Boolean}             True/False boolean
 */
 const checkAuthExists = () => {
  if (browserHasLocalStorageCheck()) {
    const authObject = window.localStorage.getItem('persist:auth')

    if (!authObject) return false

    const auth = JSON.parse(authObject)
    const data = auth?.data
    const { token: _token } = JSON.parse(data) || {}
    const { token } = _token || {}

    if (!token) {
      return false
    }

    return true
  }

  return false
};

/**
 * Checks if jwt token has expired
 * @param  {String}  token       JWT token to be pass in to function
 * @return {Boolean}             True/False boolean
 */
const hasTokenExpired = async ({ tokenExpiry }) => {
  const current = new Date();

  const expiry = new Date(parseInt(tokenExpiry * 1000));

  const minutesLeft = (expiry.getTime() - current.getTime()) / (60 * 1000);

  if (minutesLeft <= 10) {
    return true;
  }

  return false;
};


// AUTH SERVICE HOOKS

const OWA_AUTH = 'OWA';
const KKI_AUTH = 'KKI';
const DOCCHECK_AUTH = 'DocCheck';

/**
 * Checks if user has full or limited access using their trust level
 * @param  {Number} trustLevel       Trust level to pass in
 * @return {Boolean}                 True/False boolean
 */
const useHasLimitedAccess = ({ trustLevel }) => {
  const { trustLevelMin } = useWebsiteConfigObject();
  const { selection: tlmin } = trustLevelMin || {};

  const trustlevelBelowMin = parseInt(trustLevel) < parseInt(tlmin);

  if (trustlevelBelowMin) {
    return true;
  }

  return false;
};

const useAuthService = () => {
  const { authService } = useWebsiteConfigObject();
  const { selection } = authService || {};

  if (!authService) {
    return null;
  }

  switch (selection) {
    case KKI_AUTH:
      return KKI_AUTH;
    case OWA_AUTH:
      return OWA_AUTH;
    case DOCCHECK_AUTH:
      return DOCCHECK_AUTH;
    default:
      return null;
  }
};

const useAuthServiceLoginPage = () => {
  const { authService } = useWebsiteConfigObject();
  const { selection } = authService || {};

  if (!authService) {
    return null;
  }

  switch (selection) {
    case KKI_AUTH:
      return KKILogin;
    case OWA_AUTH:
      return OWALogin;
    case DOCCHECK_AUTH:
      return DocCheckLogin;
    default:
      return null;
  }
};

const useAuthServiceCMSPage = () => {
  const { authService } = useWebsiteConfigObject();
  const { selection } = authService || {};

  if (!authService) {
    return null;
  }

  switch (selection) {
    case KKI_AUTH:
      return KKICMS;
    case OWA_AUTH:
      return OneKeyCMS;
    case DOCCHECK_AUTH:
      return DocCheckCMS;
    default:
      return null;
  }
};

export {
  getAuthHeaderConfig,
  checkAuthExists,
  hasTokenExpired,
  useHasLimitedAccess,
  OWA_AUTH,
  KKI_AUTH,
  DOCCHECK_AUTH,
  useAuthService,
  useAuthServiceLoginPage,
  useAuthServiceCMSPage,
};
