import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { getImgAttr } from 'api/acousticApi';
import { widthOfNColumns } from 'utils/responsiveUtils';
import {
  setHeightLogic,
  getSetSampleHeight,
} from 'utils/sampleHeightUtils';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
} from 'components/layout/ScreenWidthContext';
import { Carousel } from './Carousel';
import './ImageCarousel.scss';


const CAROUSEL_COLUMNS = 8;
const CAROUSEL_COLUMNS_SMALL = 4;
const IMAGE_HEIGHT = 400;
const IMAGE_HEIGHT_MOBILE = 200;

const CAROUSEL_PADDING_BOTTOM = 30;






const getImageHeight = (currentWidthRanges) => {
  if (currentWidthRanges.includes(SCREEN_WIDTH_SMALL)) { return IMAGE_HEIGHT_MOBILE; }
  return IMAGE_HEIGHT;
};

const getCarouselColumns = (currentWidthRanges) => {
  if (currentWidthRanges.includes(SCREEN_WIDTH_SMALL)) { return CAROUSEL_COLUMNS_SMALL; }
  return CAROUSEL_COLUMNS;
};



const ImageSlide = ({
  id,
  src,
  alt,
  addSampleHeightCallback,
  sampleHeights,
  carouselWidth,
}) => {


  const imgRef = useRef(null);


  // setSampleHeight is a chonky callBack. Essentially what it does it lets the component know the best height for
  // this image to be rendered at whilst having a width of 100%. However, as it's chonky, it has been extracted out
  // so you don't have to worry about it here
  const setSampleHeight = getSetSampleHeight({
    imgRef,
    sampleHeights,
    id,
    idealWidth: carouselWidth,
    addSampleHeightCallback,
    verticalPadding: CAROUSEL_PADDING_BOTTOM,
  });

  return (
    <div
      key={id}
      className="carousel-image-slide"
      data-vv-action="click"
      data-vv-snapshot="after"
    >
      <img
        ref={imgRef}
        onLoad={setSampleHeight}
        src={src}
        alt={alt}
        className="carousel-image"
      />
    </div>
  );
};

ImageSlide.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  addSampleHeightCallback: PropTypes.func,
  sampleHeights: PropTypes.objectOf(PropTypes.number),
  carouselWidth: PropTypes.number,
};





const ImageCarousel = ({
  images,
  orientation = 'horizontal'
}) => {
  const { width, currentWidthRanges } = useScreenWidth();
  const [carouselHeight, setHeight] = useState(getImageHeight(currentWidthRanges)); // a rough estimate at first
  // whilst sampleHeights would be more efficent as an array, it makes it suspetable to double renders pushing
  // multiple samples for the same image id. As such we make it an object
  const [sampleHeights, addSampleHeight] = useState({});
  const carouselColumns = getCarouselColumns(currentWidthRanges);
  // one image visible, so slide width is carousel width
  const carouselWidth = widthOfNColumns(carouselColumns, width);

  const addSampleHeightCallback = ({
    id,
    newSampleHeight,
  }) => addSampleHeight((prevState) => ({
    ...prevState,
    [id]: newSampleHeight,
  }));

  const extractedImages = images.values.map((contentItem) => {
    const { id } = contentItem;
    const {
      src,
      alt,
    } = getImgAttr(contentItem);

    return ({
      id,
      src,
      alt,
    });
  });

  useEffect(() => {
    // Sets the height for the carousel based on the best height for the highest image in an array of images
    setHeightLogic({
      sampleHeights,
      extractedImages,
      setHeight,
      currentHeight: carouselHeight,
    });
  }, [carouselHeight, extractedImages, sampleHeights]);

  return (
    <Carousel
      slideWidth={carouselWidth}
      slideHeight={carouselHeight}
      visibleSlides={1}
      className="image-carousel"
      orientation={orientation}
      slides={extractedImages.map((image, i) => {
        const {
          id,
          src,
          alt,
        } = image;

        const fallBackId = id ? `${id}-${i}` : `${src}-${i}`; // we have to mix i in just incase they upload the same image twice



        return (
          <ImageSlide
            id={fallBackId}
            src={src}
            alt={alt}
            addSampleHeightCallback={addSampleHeightCallback}
            sampleHeights={sampleHeights}
            carouselWidth={carouselWidth}
          />
        );
      })}
    />
  );
};

ImageCarousel.propTypes = {
  images: contentElementProptype,
};

export { ImageCarousel };
