import useTaxonomyRegistration from 'hooks/useTaxonomyRegistration';
import React from 'react';
import { useAuthServiceLoginPage } from 'utils/authUtils';
import './index.scss';

const LoginPage = ({ routeProps }) => {
  let returnPath;
  let requiresLogin;
  let taxonomy;
  if(routeProps?.location?.state) {
    returnPath = routeProps?.location?.state?.returnPath;
    requiresLogin = routeProps?.location?.state?.requiresLogin;
    taxonomy = routeProps?.location?.state?.taxonomy;
  }
  useTaxonomyRegistration({ requiresLogin, path: returnPath, taxonomy  });
  const LoginDisplay = useAuthServiceLoginPage();

  return <LoginDisplay />;
};

export { LoginPage };
