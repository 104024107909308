import React, { useEffect } from 'react';
import Link from 'components/reusableComponents/link/Link';
import { ScrollToTopIcon, MailIcon } from 'components/shared/SvgIcons';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { getValue, getValues } from 'utils/contentUtils';
import RichText from 'components/reusableComponents/richText/RichText';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import logo from 'assets/images/kk-logo.png';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import './Footer.scss';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Footer = (templates) => {
  const contentId = templates.contentId;
  let content;
  let contentfooter = usePageContent(contentId);
  const { footerId } = useWebsiteConfigObject();
  let contentfooterWebOject = usePageContent(footerId);
  let eventFooter = false;
  if (
    templates.templateType == 'Layout – Event Page' ||
    templates.templateType == 'Layout – Event Thank You Page'
  ) {
    eventFooter = true;
    content = contentfooter?.content?.elements?.footer?.value;
  } else {
    content = contentfooterWebOject.content;
  }

  const elements = content ? content.elements : {};
  const { emailAddress, footerText, links, adverseReportingText } = elements;
  const navLinks = getValues(links);
  let emailValue;
  if (emailAddress != undefined) {
    emailValue = getValue(emailAddress);
  }

  const { trackEventWithContentType } = useGoogleAnalytics();

  const handleClick = (itemId) => {
    trackEventWithContentType('footer', itemId);
  };

  useEffect(() => {
    // Function added to improve CLS score. It loads the Footer after 2 seconds with a smooth effect
    setTimeout(function () {
      document.getElementById('kkiFooter').classList.add('loaded');
    }, 2000);
  }, []);

  return (
    <footer
      id='kkiFooter'
      style={{
        display: 'none',
        transition: 'height 0.66s ease-out',
        overflow: 'hidden',
        height: '0',
      }}
      className={eventFooter ? 'events-footer' : ''}
    >
      <div className='container'>
        <div className='footer-top'>
          <div className='logo'>
            <img src={logo} alt='Kyora Kirin logo' width='168' height='26' />
          </div>

          <button
            type='button'
            className='scroll-to-top custom-focus'
            onClick={scrollToTop}
          >
            <ScrollToTopIcon />
          </button>
        </div>
        {adverseReportingText?.value ? (
          <section className='adverse-footer'>
            <RichText className='adverse-text' data={adverseReportingText} />
            {content && (
              <section>
                <RichText data={footerText} />
                {emailValue && (
                  <p className='mailto'>
                    <MailIcon />
                    <Link href={`mailto:${emailValue}`}>{emailValue}</Link>
                  </p>
                )}
              </section>
            )}
            {navLinks && (
              <nav className='footer-nav'>
                {navLinks.map((footerLink) => {
                  const { link, nofollow } = footerLink.elements;
                  const noFollow = getValue(nofollow);

                  return (
                    <Link
                      href={link?.linkURL || '/#'}
                      key={footerLink.id}
                      rel={noFollow ? 'nofollow' : ''}
                      onClick={() => handleClick(link?.linkText)}
                    >
                      {link?.linkText}
                    </Link>
                  );
                })}
              </nav>
            )}
          </section>
        ) : (
          <section className='normal-footer'>
            {navLinks && (
              <nav className='footer-nav'>
                {navLinks.map((footerLink) => {
                  const { link, nofollow } = footerLink.elements;
                  const noFollow = getValue(nofollow);

                  return (
                    <Link
                      href={link?.linkURL || '/#'}
                      key={footerLink.id}
                      rel={noFollow ? 'nofollow' : ''}
                      onClick={() => handleClick(link?.linkText)}
                    >
                      {link?.linkText}
                    </Link>
                  );
                })}
              </nav>
            )}
            {content && (
              <section>
                <RichText data={footerText} />
                {emailValue && (
                  <p className='mailto'>
                    <MailIcon />
                    <Link href={`mailto:${emailValue}`}>{emailValue}</Link>
                  </p>
                )}
              </section>
            )}
          </section>
        )}
      </div>
    </footer>
  );
};

export { Footer };
