import styles from './Quiz.module.scss'
import { useState, useEffect, useRef } from 'react'
import { getValue, getValues, getFullImageStr } from '../../../../utils/utilityHelper'

import Checkbox from '../../atoms/formElements/checkbox/Checkbox'
import Radio from '../../atoms/formElements/radioButton/TileRadioButton'
import DropdownList from '../../atoms/formElements/dropdown/DropdownList'
import RadioButton from 'components/kanso/atoms/formElements/radioButton/RadioButton'
import RichText from 'components/reusableComponents/richText/RichText'

const QuizQuestionTextAnswer = (props) => {  
  const { 
    quizId,
    locale,
    answers,
    current,
    selectedValue,
    defaultValue
  } = props

  let initialState = {}

  if(defaultValue) {
    for (const property in defaultValue) {
      initialState[property] = true
    }
  }

  // console.log({ initialState })
  
  const columnRef  = useRef(null)
  const [checked, setChecked] = useState(initialState)
  const [name, setName] = useState(`${current}`)
  const [answerType, setAnswerType] = useState(answers[0].type)
  const options = []

  useEffect(() => {
    columnRef.current.style.setProperty(
      "--quiz-answers-column-count",
      `${answers.length}`
    )
  }, [columnRef])

  useEffect(() => {
    if(answerType !== 'Kanso-Atom-Dropdown') {
      selectedValue(name, checked)
    } 
  }, [checked])

  const toggleElement = (index, multiple) => { 
    const isChecked = !!checked[index]    
    if(isChecked){
      if(Object.keys(checked).length === 1) {
        localStorage.removeItem(quizId+"-"+locale+"-"+current)
        setChecked({})
      } else {
        const { [index]: _, ...newChecked } = checked;
        setChecked(newChecked)
      }
    }
    if(!isChecked){
      if(multiple) {
        setChecked({
          ...checked,
          [index]: !isChecked
        })
      } else {
        setChecked({
          [index]: !isChecked
        })
      }
    }    
  }

  const handleDropdown = (value) => {
    selectedValue(name, {
      [value]: true
    })            
  }

  const handleChange = (e, index) => {
    toggleElement(index, false)
  }

  return (
    <div className={`
      ${styles['quiz-question-answers']}
      ${answerType ? styles[answerType.toLowerCase()] : ''} 
    `}
      ref={columnRef}
    >
      {answers.map((content, index) => {     
        if(content.type === "Kanso-Atom-Checkbox") {
          return (
            <Checkbox 
              key={index}
              name={`question${current}checkbox${index}`}
              id={getValue(content?.elements?.title)}
              label={getValue(content?.elements?.title)}
              required={false}
              disabled={false}
              assistiveText={getValue(content?.elements?.text)}
              showTiles={true}
              tileImage={content?.elements?.image ? getFullImageStr(content?.elements?.image?.url) : ""}
              checked={checked[index] ?? ''}
              onClick={(e) => toggleElement(index, true)}
            />
          )
        }
        if(content.type === "Kanso-Atom-Radio") {
          const value = getValue(content?.elements?.value)
          return (
            <RadioButton 
              key={index}
              name="radio"
              label={getValue(content?.elements?.title)}
              disabled={false}
              assistiveText={<RichText data={content?.elements?.text} />}
              value={value}
              // showTiles={true}
              // tileImage={content?.elements?.image ? getFullImageStr(content?.elements?.image?.url) : ""}
              // selected={checked[index] ?? ''}
              // checked={checked[index] ?? ''}
              field={{onChange: (e) => handleChange(e, index) , checked: checked[index], selected: checked[index] }}
            />
          )
        }
        if(content.type === "Kanso-Atom-Dropdown") {
          var matchedOption = null
          var setDefaultValue = false
          {getValues(content?.elements?.options).map((option, index) => {                       
            if(typeof defaultValue != "undefined" && Object.keys(defaultValue)[0].includes(index)) {
              matchedOption = option
              setDefaultValue = true 
            }
            options.push({ value: index, label: option })
          })}
          return (
            <DropdownList
              key={index}
              id={`question${current}-answer${(index+1)}`}
              name={`question${current}-answer${(index+1)}`}   
              placeholder={getValue(content?.elements?.placeHolder)}
              label={getValue(content?.elements?.label)}
              options={options}
              defaultValue={setDefaultValue?{ label: matchedOption, value: Object.keys(defaultValue) }:''}
              onchangeHandler={(selectedOption) => {
                handleDropdown(selectedOption.value)
              }}
            />
          )
        }
      })}
    </div>
  )
}

export default QuizQuestionTextAnswer