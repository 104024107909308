/* eslint-disable react/jsx-no-target-blank */
import React, { forwardRef, useEffect,useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { ConfirmAndNavigate } from 'utils/hyperlinkUtils'
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import useAppendAuthParams from 'hooks/useAppendAuthParams'
import { useGoogleAnalytics } from '../../../hooks/useGoogleAnalytics'
import { useHistory } from 'react-router-dom';
import { getUrlCountryPath } from 'utils/urlUtils';
import { userActions } from 'redux/user/slice';
import { useDispatch, useSelector } from 'react-redux';
import { getFileName } from 'utils/stringUtils';
import {
    PROFILE_PAGE_PATH,
    LOGIN_PAGE_PATH,
  } from '../../../routes/routeConstants';

const Link = forwardRef((props, ref) => {
    const users = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const { status, data } = useSelector((state) => state.authentication);
    const { user } = data || {};
    const userId = user.userId;
    const {token, refreshToken} = data?.token;    

    function checkRequiredData(data){
        let allDataAvailable = true;
        if(data?.firstname === null || data?.lastname === null 
            || data?.email === null || data?.speciality === null  )
            {
                allDataAvailable=false;
            }
       
       return allDataAvailable;

    }

    const { href: _href, rel, onClick, state, noConfirm, sameTab, vvClickAttr, vvCountAttr, assetName, ...rest } = props || {}

    const location = useLocation()

    const { tYouAreAboutToLeave } = useWebsiteConfigObject()

    const { trackOutboundLink, trackPDFDownload } = useGoogleAnalytics()

    const message = tYouAreAboutToLeave || 'You are about to leave the Kyowa Kirin site. Are you sure you want to continue?'

    const { value: href,isMoodleDomain, disableExternalMessageAlert } = useAppendAuthParams(_href) || {}
    
    function addToken(){
        let linkUrl = href;
        if(href.includes('?')){
             linkUrl = `${href}&token=${token}&refreshToken=${refreshToken}&locale=${getUrlCountryPath()}&returnUrl=${window.location.origin}/${getUrlCountryPath()}`
        }
        else
        {
             linkUrl = `${href}?token=${token}&refreshToken=${refreshToken}&locale=${getUrlCountryPath()}&returnUrl=${window.location.origin}/${getUrlCountryPath()}`
        }
        return linkUrl
    }

    const target = sameTab ? '_self' : '_blank'

    const SecureHref = (hrefValue) => {
        return hrefValue?.startsWith('www.') ? 'https://' + hrefValue : hrefValue
    }

    const trackAnalyticsEvent = (url) => {
        trackOutboundLink(SecureHref(url));

        const filename = assetName ? assetName : getFileName(url);
        if(filename.endsWith('.pdf')) trackPDFDownload(filename, userId);
    }
    const handleClick = (e) => {
        e.preventDefault();
        const loggedIn = status?.loggedIn
        let path = window.location.href.replace(/^.*\/\/[^\/]+/, '')
        path=path.substring(6);
        const userInfo = data;
        if(isMoodleDomain){            
            if(!loggedIn)//user not logged in
            {   
                history.push( LOGIN_PAGE_PATH,{returnPath:path})                
            }           
                     
            else{
                let requireData = checkRequiredData(userInfo);        
                if(!requireData){
                    history.push( PROFILE_PAGE_PATH,{returnPath:path})  
                } 
                else{
                    ConfirmAndNavigate({
                        href:addToken(),
                        message,
                        bypass: disableExternalMessageAlert || noConfirm || null,
                        target,
                        trackAnalyticsEvent,
                    })
                }
            }  
        }
        else{
            if (onClick) {
                onClick()
            }
            ConfirmAndNavigate({
                href: SecureHref(href),
                message,
                bypass: disableExternalMessageAlert || noConfirm || null,
                target,
                trackAnalyticsEvent,
            })
        }
       
    }

    if (!href) {
        return null
    }

    if (href.startsWith('#')) {
        // Anchor tag
        return (
            <a ref={ref} href={href} {...rest} onClick={onClick}>
                {props.children}
            </a>
        )
    }
   
    if (href.startsWith('http') || href.startsWith('www')) {
        // External link
        return (
            <a ref={ref} onClick={handleClick} href={SecureHref(href)} target={target} rel={`noreferrer${rel ? ` ${rel}` : ''}`} {...rest}>
                {props.children}
            </a>
        )
    }
    if (href.startsWith('mailto')) {
        // External link
        return (
            <a ref={ref} href={SecureHref(href)} rel={`noreferrer${rel ? ` ${rel}` : ''}`} {...rest}>
                {props.children}
            </a>
        )
    }

    // Internal link
   
    return (
        <RouterLink
            onClick={onClick}
            data-vv-action={vvClickAttr}
            data-vv-count={vvCountAttr}
            ref={ref}
            rel={rel || null}
            to={{
                pathname: href,
                state: {
                    global: {
                        prevPath: location.pathname,
                    },
                    ...state,
                },
            }}
            {...rest}
        >
            {props.children}
        </RouterLink>
    )
})

export default Link
