import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { AccountDetailsForm } from 'components/pageTemplates/profile/AccountDetailsForm';
import { ChangePasswordForm } from 'components/pageTemplates/profile/ChangePasswordForm';
import { DeleteAccountForm } from 'components/pageTemplates/profile/DeleteAccountForm';
import { userActions } from 'redux/user/slice';
import { KKI_AUTH } from 'utils/authUtils';
import { LOGIN_PAGE_PATH, PROFILE_PAGE_PATH } from 'routes/routeConstants';
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import RichText from 'components/reusableComponents/richText/RichText';
import './ProfilePage.scss';
import './TooltipAnonymous.scss';


const ProfilePage = () => {
  const { loggedIn } = useSelector((state) => state.authentication.status);
  const { fetching } = useSelector((state) => state.user);
  const [close, setClose] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  let type = history.location?.state?.state || null
  let reOpenTooltip = false;

  if( type ) {
    reOpenTooltip = type.openTooltip;
  }

  useEffect(() => {
    if( close && reOpenTooltip ){
      history.push({pathname:PROFILE_PAGE_PATH},{state: { type: "Anonymous-user-flow" , redirect : type.redirect , openTooltip : false }});
      setClose(false);
    }
  });

  useEffect(
    () => dispatch(userActions.global.details.fetch()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    authService,
    tProfile,
    tProfilePageVeevaCode,
    profileMarketOptInPopupCloseLabel,
    profileMarketOptInPopupText,
    disableMarketingConsent,
  } = useWebsiteConfigObject();

  if (!authService) {
    return null;
  }

  if (!loggedIn) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE_PATH,
          state: { returnPath: PROFILE_PAGE_PATH },
        }}
      />
    );
  }

  if (fetching) {
    return (
      <section role="main" className="loading-page">
        <Loader
          className="center-aligned-loader"
          size={LARGE_LOADER}
        />
      </section>
    );
  }

  return (
    <section role="main" className="profile-page-component">
      <MainAreaTitleSection
        title={tProfile || 'Profile'}
      />
      <section className="content-section">
        <AccountDetailsForm />
      </section>
      {
        authService.selection === KKI_AUTH && (
          <section className="content-section">
            <ChangePasswordForm />
          </section>
        )
      }
      {!disableMarketingConsent && type && !close &&
        <div className='container-anonymous-tooltip'>
          <div className='container anonymous-tooltip'>
            <div className='tooltip-text'>
              {profileMarketOptInPopupText?.value && (
                <RichText data={profileMarketOptInPopupText} />
              )}
            </div>
            <div className='tooltip-button'>
              <button onClick={() => setClose(true)}>
                {profileMarketOptInPopupCloseLabel || 'Close'}
              </button>
            </div>
          </div>
        </div>
      }
      <section className="content-section">
        <DeleteAccountForm />
      </section>
      <section className="content-section">
        <div className="profile-form-content">
          <div className="veeva-code">
            {tProfilePageVeevaCode}
          </div>
        </div>
      </section>   
    </section>
  );
};

export { ProfilePage };

