import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'redux/user/slice';
import { Form } from 'components/shared/form/Form';
import {
  DELETE_ACCOUNT,
} from 'components/shared/form/formConstants';
import { DELETE_USER_ACCOUNT_SUCCESS } from 'routes/routeConstants';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useDeleteAccountFormFields } from 'components/pageTemplates/profile/accountPageFormFields';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const DeleteAccountForm = () => {

  const {
    tDeleteAccount,
    tIfYouWishToBeRemoved,
    activateMyHub,
  } = useWebsiteConfigObject();

  const deleteAccountFormFields = useDeleteAccountFormFields();
  const { trackRemoveAccount } = useGoogleAnalytics();
  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDeleteAccount = (formValues) => {
    const {
      'password-2': password,
    } = formValues;
    trackRemoveAccount(userId)
    dispatch(userActions.kki.details.delete({ password })).then(() => {
      history.push(DELETE_USER_ACCOUNT_SUCCESS);
    });
  };

  const handleDeleteAccountOWA = () => {
    trackRemoveAccount(userId)
    dispatch(userActions.global.details.delete()).then(() => {
      history.push(DELETE_USER_ACCOUNT_SUCCESS);
    });
  };

  return (
    <div className="profile-form-content">
      <h3 className="account-details">{tDeleteAccount}</h3>
      {activateMyHub && <div className='my-hub-divider'></div>}
      <p className="check-details">{tIfYouWishToBeRemoved}</p>
      <Form
        formId={DELETE_ACCOUNT}
        fields={deleteAccountFormFields}
        actions={{ handleDeleteAccount, handleDeleteAccountOWA }}
      />
    </div>
  );

};

export { DeleteAccountForm };




