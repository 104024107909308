import React from 'react';
import Link from 'components/reusableComponents/link/Link';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import RichText from 'components/reusableComponents/richText/RichText';

import './WithdrawConsent.scss';

const WithdrawConsent = () => {
  const {
    privacyAndCookiePath,
    tIUnderstandThat,
    tClickHere,
    forMoreInformation,
  } = useWebsiteConfigObject();

  return (
    <p
      className={`withdraw-consent-component ${
        forMoreInformation?.value
          ? 'withdraw-consent-component--whole-text'
          : ''
      }`}
    >
      {forMoreInformation?.value && <RichText data={forMoreInformation} />}
      {!forMoreInformation?.value && (
        <>
          {tIUnderstandThat}
          {privacyAndCookiePath && (
            <Link href={privacyAndCookiePath}>{tClickHere}</Link>
          )}
          .
        </>
      )}
    </p>
  );
};

export { WithdrawConsent };
