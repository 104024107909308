import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useWebsiteConfigObject, translateFormFieldsInArrays } from 'websiteConfig/useWebsiteConfig';
import { FORGOTTEN_PASSWORD } from 'components/shared/form/formConstants';
import { LoginBranding } from 'components/pageTemplates/login/LoginBranding';
import { Form } from 'components/shared/form/Form';
import { useForgottenPasswordFields } from 'components/pageTemplates/login/forgottenPasswordFields';
import { LoginSideText } from 'components/pageTemplates/login/LoginSideText';
import { authActions } from 'redux/authentication/slice';
import {
  FORGOTTEN_PASSWORD_SUCCESS,
} from 'routes/routeConstants';
import './ResetPassword.scss';




// mostly relies on reset password classNames as it is pretty much so identical
const ForgottenPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tForgottenPassword, tPleaseAddBelow } = useWebsiteConfigObject();

  const handleForgottenPassword = (formValues) => {
    const { email } = formValues;

    dispatch(authActions.kki.password.forgot(email)).then(() => {
      history.push(FORGOTTEN_PASSWORD_SUCCESS);
    });
  };

  const forgottenPasswordFields = useForgottenPasswordFields();
  // translate the validation fields
  const translations = useWebsiteConfigObject();
  const formFields = translateFormFieldsInArrays(forgottenPasswordFields, translations)[0].formFields;

  return (
    <div
      className="forgotten-password-page-component"
    >
      <div
        className="reset-password-container"
      >
        <div className="page-content">
          <LoginSideText />
          <div
            className="reset-password-form"
          >
            <div
              className="reset-password-inner"
            >
              <h2 className="form-title">
                {tForgottenPassword}
              </h2>
              <p className="form-p">
                {tPleaseAddBelow}
              </p>
              <hr />
              <Form
                fields={formFields}
                formId={FORGOTTEN_PASSWORD}
                actions={{
                  handleForgottenPassword,
                }}
                wrapped
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export { ForgottenPassword };

