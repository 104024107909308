import React from 'react'

// components
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
  SCREEN_WIDTH_MEDIUM,
  SCREEN_WIDTH_DESKTOP,
} from 'components/layout/ScreenWidthContext'
import { getImgAttr } from 'api/acousticApi'

// styles
import './Hero.scss';

const Hero = ({
    content   
 }) => {
    const { currentWidthRanges } = useScreenWidth();
    if (!content?.value?.elements) { return null }
    const { desktopImage, tabletImage, mobileImage } = content?.value?.elements || {}  
    const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_SMALL)
    const isTablet = currentWidthRanges.includes(SCREEN_WIDTH_MEDIUM)
    const isDesktop = currentWidthRanges.includes(SCREEN_WIDTH_DESKTOP)
    let image = isMobile ? mobileImage: (isTablet ? tabletImage: desktopImage)
    // if an image for tablet or mobile doesn't exist use the desktop one
    if (!image?.url) {
        image = desktopImage
    }
    const style = {
        backgroundImage: `url(${getImgAttr(image)?.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: isDesktop ? 'center top' : 'center',
        backgroundSize: 'cover'
    };

    return(
        <div className='hero__outer' style={style}></div>      
    )
}


export default Hero