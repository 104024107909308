/* eslint-disable import/no-default-export */
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authSlice from './authentication/slice';
import userSlice from './user/slice';
import formSlice from './form/slice';
import locationSlice from './location/slice';
import uiSlice from './ui/slice';
import searchSlice from './search/slice';
import gaSlice from './ga/slice';
import api from './search/middleware/api';
import modalSlice from 'components/kanso/ducks/modalSlice';
import shareSlice from 'components/kanso/ducks/shareSlice';
import alertSlice from 'components/kanso/ducks/alertSlice';
import quizSlice from 'components/kanso/ducks/quizSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['data'],
};

const locationPersistConfig = {
  key: 'location',
  storage,
  whitelist: ['location'],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);
const persistedLocationReducer = persistReducer(
  locationPersistConfig,
  locationSlice
);

const reducers = combineReducers({
  authentication: persistedAuthReducer,
  user: userSlice,
  form: formSlice,
  location: persistedLocationReducer,
  ui: uiSlice,
  search: searchSlice,
  ga: gaSlice,
  modal: modalSlice,
  share: shareSlice,
  alert: alertSlice,
  quiz: quizSlice
});

const store = configureStore({
  reducer: reducers,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    api,
  ],
});

export const persistor = persistStore(store);

export default store;
