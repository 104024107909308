import { getUrlCountryPath } from 'utils/urlUtils'
import { getLibraryByCode } from 'utils/libraryUtils'

const displayAssetType = false //This is a switch for futue development

const type = "layout"
const assetType = ["image", "video"]

export const buildSearchParams = (searchTerm, PageNum, tSearchText) => {
    const searchParams = `?${tSearchText ? tSearchText :  'searchText'}=${searchTerm}&pageNum=${PageNum}`

    return searchParams;
}

export const buildSearchUrl = (searchTerm, startAt = 0) => {

    const culture = getUrlCountryPath()

    const { libraryId } = getLibraryByCode(culture)

    //weirdly solr doesn't allow spaces so this is to allow spaces
    const searchText = searchTerm.replace(/\s/g, `\\ `)

    const  urlParams = [
        `q=${buildQueryStr(searchText, displayAssetType)}`,
        `fl=*,document:[json]`,
        `fq=type:${type}*`,
        `fq=${buildFqStr(displayAssetType)}`,
        `fq=libraryId:${libraryId}`,
        `rows=10`,
        `start=${startAt}`,
        `sort=lastModified desc`
    ]
    
    const searchParameters = urlParams.join("&")

    return "search?" + searchParameters;

}

const buildQueryStr = (searchTerm, displayAssetType) => {
    const q = (displayAssetType) ? `(name:*${searchTerm}* OR tags:${searchTerm}* OR text:${searchTerm}*) OR assetType:${assetType[0]} OR assetType:${assetType[1]})`: `(name:*${searchTerm}* OR tags:${searchTerm}* OR text:${searchTerm})`;

    return q;
}

const buildFqStr = (displayAssetType) => {
    const fq = (displayAssetType) ? "classification:content OR classification:asset" : "classification:content";

    return fq
}
