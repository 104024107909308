import { WebinarTime } from 'components/shared/webinarTime';
import './Timer.scss';
import CountdownTimer from './CountdownTimer';
import { useEffect, useState } from 'react';

const Timer = ({ date }) => {
  const FIVE_MIN_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const [islast5min, setIsLast5Min] = useState(false);
  const [intervalId, setIntervalId] = useState();

  // const expiry = new Date(new Date(date).toUTCString().slice(0, -4)).getTime();
  const expiry = date.getTime();

  const countDown5min = FIVE_MIN_IN_MS;
  let timer = NOW_IN_MS + getDifferenceInMinutes(NOW_IN_MS) * 60 * 1000;

  function getDifferenceInMinutes(currentTime) {
    const diffInMs = expiry - currentTime;
    return diffInMs / (1000 * 60);
  }

  useEffect(() => {
    if (expiry > NOW_IN_MS) {
      const interval = setInterval(() => {
        if (getDifferenceInMinutes(new Date().getTime() + countDown5min) <= 0) {
          setIsLast5Min(true);
        }
      }, 1000);

      if (!intervalId) {
        setIntervalId(interval);
      }

      return () => clearInterval(interval);
    }
  }, [date]);

  if (!islast5min) {
    return null;
  } else {
    if (intervalId) clearInterval(intervalId);
  }

  return (
    <div className='timer'>
      <CountdownTimer targetDate={timer} />
    </div>
  );
};

export default Timer;
