import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { confirmRegisterEmail } from 'api/genericApi';
import { GenericMessagePage } from 'components/pageTemplates/GenericMessagePage';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
  Loader,
  LARGE_LOADER,
} from 'components/shared/Loader';
import {
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS_PATH,
} from 'routes/routeConstants';


const ConfirmRegisterPage = () => {
  const { tConfirmingEmailAddress, tPleaseStayOnConfirm } = useWebsiteConfigObject();

  const params = useParams();
  const history = useHistory();
  const { trackSelfCertRegistrationFlows } = useGoogleAnalytics();

  const {
    tokenValue,
  } = params;

  useEffect(() => {
    confirmRegisterEmail({ tokenValue }).then((response) => {
      if(response?.data){
        trackSelfCertRegistrationFlows("account_confirmation", "KKI Hub confirm email success", response?.data);
        history.push(CONFIRM_EMAIL_SUCCESS_PATH);
      } else {
        history.push(CONFIRM_EMAIL_SUCCESS_PATH);
      }
    })
    .catch((error) => {
      const responseCode = error.response.status;
      history.push(`${CONFIRM_EMAIL_ERROR}/${responseCode}`);
    });
  }, [history, tokenValue])

  return (
    <GenericMessagePage
      titleText={tConfirmingEmailAddress}
      headerText={tPleaseStayOnConfirm}
    >
      <Loader
        className="center-aligned-loader"
        size={LARGE_LOADER}
      />
    </GenericMessagePage>
  )
};


export { ConfirmRegisterPage };


