import React from 'react'
import './DesktopNavigation.scss'
import { NewDownArrow } from 'components/shared/SvgIcons';
import { useSelector } from 'react-redux'
import NavLink from 'components/reusableComponents/navLink/NavLink'

const DesktopNavigation = () => {

    const { main } = useSelector(state => state.ui.navigation)
    const { redesign } = useSelector(state => state.ui);

    return (
        <div className="DesktopNavigation">
            <ul className="Main">
                {
                    main?.map((item)=>{
                        if (!item.path) return null

                        return (
                            <li key={item.id}>
                                <NavLink 
                                    className={`${item.children?.length > 0 ? 'hasChild level-0' : ''}`} 
                                    activeClassName="active" 
                                    href={item.path}
                                >
                                    {item.title}
                                    {redesign && <NewDownArrow />}
                                </NavLink>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default DesktopNavigation
