import React, { useEffect, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/authentication/slice';
import { persistor } from 'redux/store';
import { HOME_PAGE_PATH } from 'routes/routeConstants';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';


const Logout = () => {
  const { tAreYouSureLogout } = useWebsiteConfigObject();
  const dispatch = useDispatch();
  const {
    updateUserSelfCertified,
  } = useContext(cookieConfirmationContext);

  const location = useLocation();
  const { forceLogout, prevPath } = location.state || {};

  useEffect(() => {
    persistor.purge().then(() => {
      dispatch(authActions.global.logout({ text: tAreYouSureLogout, withConfirm: forceLogout ? false : true }));
      updateUserSelfCertified(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <Redirect
      to={prevPath || HOME_PAGE_PATH}
    />
  );
};

export { Logout };
