import React, { useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { getCourses } from 'api/dynamoApi';

import RichText from 'components/reusableComponents/richText/RichText';
import OngoingCourseCard from 'components/shared/OngoingCourseCard';
import CompletedCourseCard from 'components/shared/CompletedCourseCard';
import Pagination from '@material-ui/lab/Pagination';

//scss
import './CoursesTab.scss';

const CoursesTab = ({ tabId, tabActive, section }) => {
  /**
   * State for changing tabs
   * This is set before any checks because useState must be declared before any conditional rendering
   */
  const [courseTabActive, setCourseTabActive] = useState('ongoing');
  const [pageOngoing, setPageOngoing] = useState(parseInt(1));
  const [pageCompleted, setPageCompleted] = useState(parseInt(1));
  const [pageCountOngoing, setPageCountOngoing] = useState(parseInt(0));
  const [pageCountCompleted, setPageCountCompleted] = useState(parseInt(0));
  const [ongoingCards, setOngoingCards] = useState();
  const [completedCards, setCompletedCards] = useState();
  const [coursesCompleted, setcoursesCompleted] = useState([]);
  const [coursesOngoing, setcoursesOngoing] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { resources, sectionDescription, sectionTitle } = section?.elements;
  const {
    completedCoursesLabel,
    numberOfCardsPerPage,
    ongoingCoursesLabel,
    completedDateLabel,
    startDateLabel,
    emptyCoursesLabel
  } = resources?.value?.elements;

  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;
  const { token } = data?.token;

  const cardsPerPage = numberOfCardsPerPage?.value
    ? numberOfCardsPerPage?.value
    : 10;

  /**
   * set variables for ongoing tab
   */
  let quotientOngoing = 0;
  let remainderOngoing = 0;
  let ongoingCoursesArray = [];

  /**
   * set variables for completed tab
   */

  let quotientCompleted = 0;
  let remainderCompleted = 0;
  let completedCoursesArray = [];

  if (!isFetching) {
    setIsFetching(true);
    let type = "course"; 
    getCourses({ userId, token, type })
      .then((response) => {
        if (response.status === 200) {
          response.data.map((course) => {
            if(course.type === "course"){
              if (course.data.completed) {
                let coursesCompletedArray = coursesCompleted;
                coursesCompletedArray.push(course);
                setcoursesCompleted(coursesCompletedArray);
              } else {
                let coursesOngoingArray = coursesOngoing;
                coursesOngoingArray.push(course);
                setcoursesOngoing(coursesOngoingArray);
              }
            }
          });
          let ongoingSortedArray = coursesOngoing;

          ongoingSortedArray.sort(function (a, b) {
            return (
              new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn)
            );
          });

          setcoursesOngoing(ongoingSortedArray);

          quotientOngoing = Math.floor(coursesOngoing.length / cardsPerPage);
          remainderOngoing = coursesOngoing.length % cardsPerPage;
          let pageCountOngoingValue =
            remainderOngoing > 0 ? quotientOngoing + 1 : quotientOngoing;
          setPageCountOngoing(pageCountOngoingValue);
          ongoingCoursesArray = coursesOngoing
            ? coursesOngoing.slice(0, cardsPerPage)
            : [];

          setOngoingCards(ongoingCoursesArray);

          let completedSortedArray = coursesCompleted;

          completedSortedArray.sort(function (a, b) {
            return (
              new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn)
            );
          });

          setcoursesCompleted(completedSortedArray);

          quotientCompleted = Math.floor(
            coursesCompleted.length / cardsPerPage
          );
          remainderCompleted = coursesCompleted.length % cardsPerPage;
          let pageCountCompletedValue =
            remainderCompleted > 0 ? quotientCompleted + 1 : quotientCompleted;
          setPageCountCompleted(pageCountCompletedValue);
          completedCoursesArray = coursesCompleted
            ? coursesCompleted.slice(0, cardsPerPage)
            : [];
          setCompletedCards(completedCoursesArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   *  this should have been implemented using react reduc, however, I did not have time to impletement it using react redux
   *
   */
  const removeCourse = (id) => {
    //ongoing
    let ongoingCardsArray1 = coursesOngoing.filter(
      (item) => item.data.externalId != id
    );
    quotientOngoing = Math.floor(ongoingCardsArray1.length / cardsPerPage);
    remainderOngoing = ongoingCardsArray1.length % cardsPerPage;

    let pageCountOngoingValue =
      remainderOngoing > 0 ? quotientOngoing + 1 : quotientOngoing;
    setPageCountOngoing(pageCountOngoingValue);
    setcoursesOngoing(ongoingCardsArray1);

    let remainder1 = ongoingCardsArray1.length % cardsPerPage;
    let pageNum1 = pageOngoing;
    if (remainder1 === 0 && ongoingCardsArray1.length > 0) {
      pageNum1 = pageOngoing - 1;
      if (pageNum1 > 0) {
        setPageOngoing(pageNum1);
      }
    }

    if (pageNum1 === 1) {
      let ongoingCoursesArray = ongoingCardsArray1
        ? ongoingCardsArray1.slice(0, cardsPerPage)
        : [];
      setOngoingCards(ongoingCoursesArray);
    } else if (pageNum1 > 1) {
      let ongoingCoursesArray = ongoingCardsArray1
        ? ongoingCardsArray1.slice(
            cardsPerPage * (pageNum1 - 1),
            cardsPerPage * pageNum1
          )
        : [];
      setOngoingCards(ongoingCoursesArray);
    }
    //completed
    let completedCardsArray1 = coursesCompleted.filter(
      (item) => item.data.externalId != id
    );
    quotientCompleted = Math.floor(completedCardsArray1.length / cardsPerPage);
    remainderCompleted = completedCardsArray1.length % cardsPerPage;

    let pageCountCompletedValue =
      remainderCompleted > 0 ? quotientCompleted + 1 : quotientCompleted;
    setPageCountCompleted(pageCountCompletedValue);
    setcoursesCompleted(completedCardsArray1);

    let remainder2 = completedCardsArray1.length % cardsPerPage;
    let pageNum2 = pageCompleted;
    if (remainder2 === 0 && completedCardsArray1.length > 0) {
      pageNum2 = pageCompleted - 1;
      if (pageNum2 > 0) {
        setPageOngoing(pageNum2);
      }
    }

    if (pageNum2 === 1) {
      let completedCoursesArray = completedCardsArray1
        ? completedCardsArray1.slice(0, cardsPerPage)
        : [];
      setCompletedCards(completedCoursesArray);
    } else if (pageNum2 > 0) {
      let completedCoursesArray = completedCardsArray1
        ? completedCardsArray1.slice(
            cardsPerPage * (pageNum2 - 1),
            cardsPerPage * pageNum2
          )
        : [];
      setCompletedCards(completedCoursesArray);
    }
    setcoursesCompleted(completedCardsArray1);
  };
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  function handleChange(event, value, type) {
    executeScroll();
    if (type === 'ongoing') {
      setPageOngoing(value);
      if (value === 1) {
        let ongoingCoursesArray = coursesOngoing
          ? coursesOngoing.slice(0, cardsPerPage)
          : [];

        setOngoingCards(ongoingCoursesArray);
      } else if (value > 1) {
        let ongoingCoursesArray = coursesOngoing
          ? coursesOngoing.slice(
              cardsPerPage * (value - 1),
              cardsPerPage * value
            )
          : [];
        setOngoingCards(ongoingCoursesArray);
      }
    }
    if (type === 'completed') {
      setPageCompleted(value);
      if (value === 1) {
        let completedCoursesArray = completedCards
          ? coursesCompleted.slice(0, cardsPerPage)
          : [];
        setCompletedCards(completedCoursesArray);
      } else if (value > 0) {
        let completedCoursesArray = completedCards
          ? coursesCompleted.slice(
              cardsPerPage * (value - 1),
              cardsPerPage * value
            )
          : [];
        setCompletedCards(completedCoursesArray);
      }
    }
  }

  /**
   * Check the id to see if this tab is active
   */

  if (tabId !== tabActive) {
    return '';
  }
  return (
    <div className='my-hub-courses-container'>
      <div className='my-hub-courses-texts'>
        {sectionTitle?.value && (
          <section className='courses-title'>
            <h2>{sectionTitle?.value}</h2>
          </section>
        )}
        {sectionDescription?.value && (
          <section className='courses-description'>
            <RichText data={sectionDescription} />
          </section>
        )}
      </div>
      <div ref={myRef} className='my-hub-courses-list'>
        <div className='my-hub-courses-list__tabs'>
          {ongoingCoursesLabel?.value && (
            <div>
              <span
                className={`my-hub-courses-list__tabs__text${
                  courseTabActive === 'ongoing' ? ' active' : ''
                }`}
                onClick={() => {
                  setCourseTabActive('ongoing');
                }}
              >
                {ongoingCoursesLabel?.value} ({coursesOngoing.length})
              </span>
            </div>
          )}
          {completedCoursesLabel?.value && (
            <div>
              <span
                className={`my-hub-courses-list__tabs__text${
                  courseTabActive === 'completed' ? ' active' : ''
                }`}
                onClick={() => {
                  setCourseTabActive('completed');
                }}
              >
                {completedCoursesLabel?.value} ({coursesCompleted.length})
              </span>
            </div>
          )}
        </div>
        <div
          className={`my-hub-courses-list__ongoing courses-list${
            courseTabActive === 'ongoing' ? ' active' : ''
          }`}
        >
          {ongoingCards &&
            ongoingCards.length > 0 &&
            ongoingCards.map((item, i) => {
              return (
                <OngoingCourseCard
                  key={i}
                  item={item}
                  content={resources?.value?.elements}
                  userId={userId}
                  token={token}
                  removeCourse={removeCourse}
                />
              );
            })}
          {ongoingCards && ongoingCards.length === 0 && emptyCoursesLabel?.value &&
            <div>{emptyCoursesLabel?.value}</div>
          }
          {pageCountOngoing > 1 && pageCountOngoing !== 0 && (
            <div className='Pagination'>
              <Pagination
                count={pageCountOngoing}
                page={pageOngoing}
                color='primary'
                onChange={(e, v) => {
                  handleChange(e, v, 'ongoing');
                }}
              />
            </div>
          )}
          {pageCountOngoing === 1 && (
            <div className='no-pagination-divider'></div>
          )}
        </div>
        <div
          className={`my-hub-courses-list__completed courses-list${
            courseTabActive === 'completed' ? ' active' : ''
          }`}
        >
          {completedCards &&
            completedCards.length > 0 &&
            completedCards.map((item, i) => {
              return (
                <CompletedCourseCard
                  key={i}
                  content={resources?.value?.elements}
                  item={item}
                  userId={userId}
                  removeCourse={removeCourse}
                  token={token}
                />
              );
            })}
            {completedCards && completedCards.length === 0 && emptyCoursesLabel?.value &&
                <div>{emptyCoursesLabel?.value}</div>
            }
          {pageCountCompleted > 1 && pageCountCompleted !== 0 && (
            <div className='Pagination'>
              <Pagination
                count={pageCountCompleted}
                page={pageCompleted}
                color='primary'
                onChange={(e, v) => {
                  handleChange(e, v, 'completed');
                }}
              />
            </div>
          )}
          {pageCountCompleted === 1 && (
            <div className='no-pagination-divider'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursesTab;
