import React from 'react';
// React query cache provided at the top level
// https://react-query.tanstack.com/docs/overview
import {
  QueryCache,
  ReactQueryCacheProvider,
} from 'react-query';
import { AppConfig } from 'AppConfig';
import { AppLayout } from 'components/layout/AppLayout';
import { AuthRedirects } from 'components/layout/AuthRedirects';
import { OnRouteActions } from 'components/layout/OnRouteActions';
import { BrowserRouter } from 'react-router-dom';
import { ScreenWidthProvider } from 'components/layout/ScreenWidthContext';
import { CookieConfirmationProvider } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { VerifyNotificationProvider } from 'components/layout/VerifyNotificationContext';
import { Provider } from 'react-redux';
import { EncapsulationLogic } from './layout/EncapsulationLogic';

import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'redux/store';
import { useAuthService } from 'utils/authUtils';
import {
  getUrlCountryPath,
} from 'utils/urlUtils';
import OneTrustEventInteraction from './layout/OneTrustEventInteraction';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: AppConfig.reactQuery.staleTime,
    },
  },
});

// Component providing any contexts that need to be at the top level of the app.
// E.G the router, query cache provider etc
function AppContext() {
  const service = useAuthService();

  if (!service) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={`/${getUrlCountryPath()}`}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <EncapsulationLogic />
            <AuthRedirects />
            <OnRouteActions service={service} />
            <ScreenWidthProvider>
              <CookieConfirmationProvider>
                <VerifyNotificationProvider>
                  <AppLayout service={service} />
                </VerifyNotificationProvider>
              </CookieConfirmationProvider>
            </ScreenWidthProvider>
          </ReactQueryCacheProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export { AppContext, queryCache };
