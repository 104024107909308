import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import PropTypes from 'prop-types';

import { getValues } from 'utils/contentUtils';
import { withLeadingSlash } from 'utils/stringUtils';
import NavLink from 'components/reusableComponents/navLink/NavLink';
import { ExternalSideNavIcon } from '../../components/shared/SvgIcons';

import './SubNavigation.scss';

const Subnavigation = ({ element, colour }) => {
  const { subnavigationItems } = element.elements;
  const navLinks = getValues(subnavigationItems) || [];
  const style = colour ? { color: `${colour}` } : {}
  return (
    <div className="content-page-promo-subnav">
      <nav>      
        {navLinks.map((item, index) => {
          
          let href = null; 
          let isExternalUrl = false;
          if (item?.link?.linkURL && (item?.link?.linkURL.startsWith('http') || item?.link?.linkURL.startsWith('www'))) {
            isExternalUrl = true;
            href = item?.link?.linkURL ? item?.link?.linkURL : null
          } else {
              href = item?.link?.linkURL ? withLeadingSlash(item?.link?.linkURL) : null
          }
          let hrefLink = item?.link?.linkText;

          if(href=== null){
            href= (item?.linkText?.value);
            hrefLink= href
          }
          if(href) 
            return (
              <NavLink 
                exact 
                className="content-page-promo-subnav-link" 
                activeClassName="content-page-promo-subnav-link--active" 
                href={href} 
                key={'subNav' + index} 
                activeStyle={style}
                vvClickAttr='click'
              >
                {isExternalUrl 
                    ? 
                        <div className='SideNavigation--external'>
                            <span className={`content-page-promo-subnav-link-${item.menuLevel?.value?.selection}`}>
                              {hrefLink}
                            </span>
                            <ExternalSideNavIcon />
                        </div>
                    : <span className={`content-page-promo-subnav-link-${item.menuLevel?.value?.selection}`}>
                      {hrefLink}
                    </span>
                }
              </NavLink>
            )
          return null;
        })}
      </nav>
    </div>
  );
};

Subnavigation.propTypes = {
  element: contentElementProptype,
  primaryColour: PropTypes.string,
};

export default Subnavigation;
