import React, { useState } from 'react';

//modules
import RichText from 'components/reusableComponents/richText/RichText';
import './expandableContentPanels.scss';

//svg icons
import { DownArrow } from 'components/shared/SvgIcons';
import { UpArrow } from 'components/shared/SvgIcons';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const ExpandableContentPanels = ({ content, backgroundColor }) => {
  const {
    sectionDescription,
    sectionSubtitle,
    sectionTitle,
    showLessLabel,
    showMoreLabel,
  } = content?.elements;
  const [open, setOpen] = useState(false);
  const { trackEventWithContentType } = useGoogleAnalytics();

  const style = {
    backgroundColor: backgroundColor?.value
      ? backgroundColor?.value
      : '#F9F4F2',
  };

  const handleClick = () => {
    setOpen(!open);
    trackEventWithContentType('collapsible-panel', sectionTitle?.value);
  }

  return (
    <div className='expandable-content' style={style}>
      <div className={`expandable-content__title${open ? ' opened' : ''}`}>
        <h4>{sectionTitle?.value}</h4>
      </div>
      {sectionSubtitle && <div className={`expandable-content__sub-title${open ? ' opened' : ''}`}>
        <RichText data={sectionSubtitle} />
      </div>}
      <div
        className={`expandable-content__description${open ? ' opened' : ''}`}
      >
        <RichText data={sectionDescription} />
      </div>
      <div
        className={`expandable-content__expand${open ? ' opened' : ''}`}
        onClick={() => handleClick()}
      >
        {open ? (
          <div className='expand'>
            <span>{showLessLabel?.value}</span> <UpArrow />
          </div>
        ) : (
          <div className='expand'>
            <span>{showMoreLabel?.value}</span> <DownArrow />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandableContentPanels;
