import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  lazy,
} from 'react';
import {
  getValue,
  getSelectionValue,
  getFullImageStr,
  setMultipleCSSProperties,
} from 'utils/utilityHelper';
import utilityHelper from 'utils/utilityHelperPlus';
import PropTypes from 'prop-types';
import styles from './HeroHeader.module.scss';
import Button from 'components/kanso/atoms/button/Button';
import Image from 'components/kanso/atoms/image/Image';
import Video from 'components/kanso/atoms/video/Video';

// const Image = lazy(() => import('components/kanso/atoms/image/Image'))
// const Video = lazy(() => import('components/kanso/atoms/video/Video'))

/**
 * HeroHeader Component
 * @param {string}   align       - The alignment of the Hero Header Component to be displayed.
 * @param {string}   backLink    - The back link of the Hero Header Component to be displayed.
 * @param {string}   title       - The title of the Hero Header Component to be displayed.
 * @param {string}   heading     - The heading of the Hero Header Component to be displayed.
 * @param {string}   headingTag  - The heading tag to wrap around the heading of the Hero Header Component.
 * @param {object}   description - The description of the Hero Header Component to be displayed
 * @param {object}   bgImage     - The background image of the Hero Header Component to be displayed..
 * @param {object}   sideImage   - The side image of the Hero Header Component to be displayed.
 * @param {object}   sideVideo   - The side video of the Hero Header Component to be displayed.
 * @param {object}   bgVideo     - The background video of the Hero Header Component to be displayed.s
 * @return React component
 */

const HeroHeader = React.forwardRef((props, ref) => {
  const {
    align,
    backLink,
    title,
    heading,
    headingTag,
    description,
    bgColour,
    bgImage,
    bgImageMobile,
    sideImage,
    sideVideo,
    bgVideo,
    ctaButtonsBackground
  } = props;

  const heroRef = useRef(null);
  useImperativeHandle(ref, () => heroRef?.current);
  const HeadingTag = `${headingTag}`;
  const { winWidth } = utilityHelper.useScreenDimensions();
  const [backgroundImage, setBackgroundImage] = useState(
    winWidth <= 545 ? bgImageMobile : bgImage
  );
  const [backgroundMedia, setBackgroundMedia] = useState();

  useEffect(() => {
    if (bgVideo) {
      setBackgroundMedia(
        <Video
          src={bgVideo}
          autoPlay={true}
          isLooped={true}
          clickToPlay={false}
          showControls={false}
        />
      );
    } else if (bgImage) {
      heroRef?.current?.style?.setProperty('--hh-bgcolor', `transparent`);
      setBackgroundMedia(
        <Image
          src={getFullImageStr(backgroundImage?.url)}
          priority={true}
          mediaSrc={backgroundImage?.asset}
          altText=''
        />
      );
    }
    if (!bgImage && !bgVideo && bgColour) {
      const cssProperties = {
        '--hh-bgcolor': bgColour,
        '--hh-text-color': "white",
      };
      setMultipleCSSProperties(heroRef?.current, cssProperties)
    }
  }, []);

  useEffect(() => {
    winWidth <= 545
      ? setBackgroundImage(bgImageMobile)
      : setBackgroundImage(bgImage);
  }, [winWidth]);

  useEffect(() => {
    setBackgroundMedia(
      <Image
        src={getFullImageStr(backgroundImage?.url)}
        priority={true}
        mediaSrc={backgroundImage?.asset}
        altText=''
      />
    );
  }, [backgroundImage]);

  return (
    <div
      className={`
        ${styles['heroHeader']} 
        ${align ? styles[`heroHeader-${align}`] : ''} 
        ${sideImage || sideVideo ? styles['showSide'] : ''}
      `}
      ref={heroRef}
    >
      {(bgVideo || bgImage) && (
        <div
          className={`${styles['heroHeader-background']} ${
            bgVideo ? styles['heroHeader-background-video'] : ''
          }`}
        >
          {backgroundMedia}
        </div>
      )}
      {(sideVideo || sideImage) && (
        <div className={styles['heroHeader-sidemedia']}>
          {sideVideo ? (
            <Video
              src={getFullImageStr(sideVideo?.src?.url)}
              caption={getValue(sideVideo.caption)}
              image={getFullImageStr(sideVideo.image.url)}
              subTitle={getValue(sideVideo.title)}
              title={getValue(sideVideo.title)}
              titleTag={getSelectionValue(sideVideo.titleTag)}
              transcript={getFullImageStr(sideVideo?.transcript?.url)}
            />
          ) : (
            <Image
              src={getFullImageStr(sideImage?.src?.url)}
              priority={true}
              mediaSrc={sideImage?.src?.asset}
              altText={getValue(sideImage.altText)}
              caption={getValue(sideImage.caption)}
              downloadText={getValue(sideImage.downloadText)}
              position={getSelectionValue(sideImage.position)}
              title={getValue(sideImage.title)}
              titleTag={getSelectionValue(sideImage.titleTag)}
            />
          )}
        </div>
      )}
      <div className={styles['heroHeader-content']}>
        <div>
          {backLink && (
            <nav aria-label='Breadcrumb' id='breadcrumb'>
              <Button
                type='tertiary'
                text={backLink}
                link='#'
                icon='chevronLeft'
              />
            </nav>
          )}
          {title && (
            <div className={styles['heroHeader-content-title']}>{title}</div>
          )}
          {heading && (
            <HeadingTag className={styles['headline']}>{heading}</HeadingTag>
          )}
          {description && (
            <div className={styles['heroHeader-content-description']}>
              {description}
            </div>
          )}
          {props.children && (
            <div className={styles['heroHeader-content-btn']}>
              {props.children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

HeroHeader.propTypes = {
  align: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  backLink: PropTypes.string,
  title: PropTypes.string,
  heading: PropTypes.string,
  headingTag: PropTypes.string.isRequired,
  headingTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  description: PropTypes.object,
  bgImage: PropTypes.object,
  bgImageMobile: PropTypes.object,
  sideImage: PropTypes.object,
  sideVideo: PropTypes.object,
  bgVideo: PropTypes.string,
};

HeroHeader.defaultProps = {
  align: 'center',
  bgImage: null,
  bgImageMobile: null,
  bgVideo: null,
  description: null,
  headingTag: 'h2',
  sideImage: null,
  sideVideo: null,
};

export default HeroHeader;
