import React, { useState } from "react";
import { getAssetUrl } from 'api/acousticApi';
import { useHistory } from 'react-router-dom';

import "./CountrySwitcher.scss";

const CountrySwitcher = (props) => {
    const [isOpen, setOpen] = useState(false);
    const history = useHistory();
    const paths = ['value', 'elements', 'linksPicker', 'values'];
    const countrySwitcherList = paths.reduce((object, path) => {
        return (object || {})[path];
    }, props.alternateLinks);

    const toggleDropdown = () => setOpen(!isOpen);
    const currentCountryItem = countrySwitcherList?.find(item => item?.elements?.locale?.value == props?.currentLocale?.value?.elements?.locale?.value);
    const updateCountrySwitcher = (id) => {
        const dropdownHeader = document.getElementsByClassName("country-switcher-dropdown-header")[0];
        const dropdownBody = document.getElementsByClassName("country-switcher-dropdown-body")[0];
        const itemClick = countrySwitcherList.find(item => item.id == id);
        dropdownHeader.innerHTML = `<img src=${getAssetUrl(itemClick?.elements?.flag)} width="30" height="24" />
        <span>${itemClick?.elements?.linkLabel?.value}</span>`;
        dropdownBody.classList.remove("open");
        dropdownHeader.classList.remove("open");
        history.push(itemClick?.elements?.url?.value);
    }

    return (<div className='country-switcher-dropdown-wrapper'>
                <div className={`country-switcher-dropdown-header ${isOpen && 'open'}`} onClick={toggleDropdown}>
                    <img src={getAssetUrl(currentCountryItem?.elements?.flag)} width="30" height="24" />
                    <span>{currentCountryItem?.elements?.linkLabel?.value}</span>
                </div>
                <div className={`country-switcher-dropdown-body ${isOpen && 'open'}`}>
                    {countrySwitcherList && countrySwitcherList?.map(item => (
                        <div key={item.id} className="country-switcher-dropdown-item" onClick={() => updateCountrySwitcher(item.id)}>
                            <a href="" className={currentCountryItem?.elements?.locale?.value == item?.elements?.locale?.value ? "current" : ""}>
                                <img src={getAssetUrl(item.elements.flag)} width="30" height="24" /> 
                                <span>{item.elements.linkLabel.value}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>)
}

export default CountrySwitcher;