import { getComponentName } from '../../utils/acousticMappings'
import QuizAcousticData from '../../components/kanso/patterns/quiz/QuizAcousticData'

const QuizTemplate = (props) => {

  const { data } = props

  if (data?.elements?.pageClass?.value) {
    document.body.classList.add(data?.elements?.pageClass?.value)
  }

  return (
    <>
      <QuizAcousticData data={data?.elements?.quizToDisplay?.value?.elements} />
    </>
  )
}

export default QuizTemplate