import React from 'react';
import PropTypes from 'prop-types';
import { useWebsiteConfigDeep,useWebsiteConfigObject } from '../../../websiteConfig/useWebsiteConfig';
import { GenericMessagePage } from 'components/pageTemplates/GenericMessagePage';


const searchNameIndex=(config, wantedContentType)=>{
    let nameKey ;
    let arrayContentType = new Array ();
    
    const keysConfigList= Object.values(config);
    for (const item of keysConfigList) {
        let itemCategory = item.name
        if( itemCategory!= undefined){
            const items= item?.name?.value?.selection;
            arrayContentType.push(items);
        }
    }
    if(arrayContentType.includes(wantedContentType)){
        nameKey = arrayContentType.indexOf(wantedContentType);  
    }
    else{
        nameKey= -1;
    }
    return(nameKey)
}

const checkActivation =(keyname,config,pageName)=>{
    let deactivatedPageList;
    let activeFeature;
    if(keyname === -1){
        return null
    }
    else{
        activeFeature = config[keyname]?.activeFeature.value;
        let pageListArray = new Array();
        deactivatedPageList = config[keyname]?.deactivatedPageList?.values;
    
        if(deactivatedPageList != undefined){
            const keysPageList= Object.values(deactivatedPageList);
            for (const item of keysPageList) {  
            const items = item.contentType?.value?.selection;
                pageListArray.push(items);
            }
        }
        if(activeFeature === true){
            if(pageName === undefined){return true;}
            //need to check if content type presnt in deactivate list
            if(pageListArray.includes(pageName)){
                return false;
            }           
            else{
                return true
            }
        }
        else{
            return false
        }
    } 
    
} 

const Features = ({pageName,contentType,page,children}) => {
    
    const {
        tThePageYouAreLooking,
        tError,
      } = useWebsiteConfigObject();
    
    let wantedPageName= pageName;
    let wantedContentType = contentType;
    let isContentActivate;
   

    // get features ids
    const features  = useWebsiteConfigDeep();

    let featuresElement =  features?.websiteConfig?.features;
    let configArray = new Array();
    if(featuresElement != undefined){
        const configList = Object.values(featuresElement);
        for (const item of configList) {
            const items = item.elements
            configArray.push(items);
        }
    }
    
    //compare contentType name in page elements and retrieve index

       let keyName = searchNameIndex(configArray,wantedContentType);
      
        isContentActivate = checkActivation(keyName,configArray,wantedPageName); 
    //check if content is activate
           
    if(isContentActivate=== true){
        return children;
    }
    else{
        if(page == true){
            return (
                <GenericMessagePage
          titleText='404'
          headerText={tThePageYouAreLooking}
          categoryText={tError}
        ></GenericMessagePage>
            )
        }
        return null
    }
   
}
Features.propTypes = {
    content: PropTypes.string,
};
 export { Features }; 