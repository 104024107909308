import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocationSearchParameter } from 'utils/urlUtils';
import { useApiRoutes } from 'routes/useApiRoutes';
import { authActions } from 'redux/authentication/slice';

import {
  HOME_PAGE_PATH,
  EXTERNAL_LOGIN_ERROR_PATH,
  LOGOUT_PATH,
} from 'routes/routeConstants';
import {
  Loader,
  LARGE_LOADER,
} from 'components/shared/Loader';
const ACTION = 'action';
const CODE = 'code';
const COMPANY_INFO = 'company_info_1';
const STATUS_ID = 'StatusId';
const LOGGED_OUT = 'logged-out';

const AuthRedirects = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.authentication.status);
  const { location: storedLocation } = useSelector((state) => state.location);
  const _authCode = useLocationSearchParameter(CODE);
  const _doccheckuserconsent = useLocationSearchParameter(COMPANY_INFO);
  const action = useLocationSearchParameter(ACTION);
  const statusId = useLocationSearchParameter(STATUS_ID);
  const { routes, isLoading, error } = useApiRoutes();
  const history = useHistory();

  const handleOWALogin = () => {
    dispatch(authActions.oneKey.login());
  };

  
  const handleDocCheckLogin = ({ authCode, doccheckuserconsent }) => {
    dispatch(authActions.docCheck.login({ authCode, doccheckuserconsent }));
  };

  // If OneKey auth is a fail redirect to error page
  // TODO - Handle if specific market declines anon users from viewing content
  if (statusId === '0') {
    return (
      <Redirect
        to={{
          pathname: EXTERNAL_LOGIN_ERROR_PATH,
        }}
      />
    );
  }

  // If OneKey authentication is a success login user in.
  if (statusId === '1') {
    handleOWALogin();
  }

  // Handle a successful authentication with DocCheck.
  if (_authCode) {
    // If DocCheck authentication is a success and there is an auth code login user in using
    // auth code.
    handleDocCheckLogin({
      authCode: _authCode,
      doccheckuserconsent: _doccheckuserconsent,
    });
    let url = history.location.pathname
    return (
      <>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 80,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            zIndex: 1010
        }}>
          <Loader
              className="center-aligned-loader"
              size={LARGE_LOADER}
          />
        </div>
        {loggedIn && <Redirect
          to={{
            pathname: url || HOME_PAGE_PATH,
          }}
        />}
      </>
    );
  }

  // Authentication is success and user is logged in redirect user to intended path or home page.
  if ((statusId === '1' || _authCode) && loggedIn) {

    const url = localStorage.getItem('owaPathName');
    const urlSearch = localStorage.getItem('owaPathSearch');
    localStorage.removeItem("owaPathName");
    localStorage.removeItem("owaPathSearch");

    return (
      <Redirect
        to={{
          pathname: storedLocation.pathname || url || HOME_PAGE_PATH,
          search: storedLocation.search || urlSearch || '',
        }}
        />
    );
  }

  // Logout user out if action param value is 'logged-out'.
  if (action === LOGGED_OUT) {
    return (
      <Redirect
        to={{
          pathname: LOGOUT_PATH,
          state: {
            forceLogout: true,
          },
        }}
      />
    );
  }

  return null;
};

export { AuthRedirects };
