import { useEffect } from 'react';
import { useLocationSearchParameter } from 'utils/urlUtils';
import { useSelector } from 'react-redux';
import { getLocationOrigin, getUrlCountryPath } from 'utils/urlUtils';
import { useCookies } from 'react-cookie';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { LOGIN_PAGE_PATH } from 'routes/routeConstants';

/**
 * Logic when user is redirected from moodle due to token expiry.
 * User redirected from moodle to hub with returnUrl as paramenter in url
 * Wait for user to login and redirect user to moodle
 *
 */
const MoodleRedirections = () => {
  const { websiteConfig } = useWebsiteConfig();
  const { data } = useSelector((state) => state.authentication);
  const { loggedIn } = useSelector((state) => state.authentication.status);
  const [cookies, setCookie] = useCookies(['moodleCountry']);
  const ReturnUrl = useLocationSearchParameter('returnUrl');
  const localReturnUrl = sessionStorage.getItem('MoodleReturnUrl');
  let storageReturnUrl = localStorage.getItem('incommingquerystring');
  const urlParams = new URLSearchParams(storageReturnUrl);
  storageReturnUrl = urlParams.get('returnUrl');
  const countryPath = getUrlCountryPath();
  const showMoodleAlert = sessionStorage.getItem('showMoodleAlert');

  useEffect(() => {
    if (ReturnUrl) {
      //create an object and add returl url and add 10 mins to current date as expiry of the obj
      const MoodleRedirectionObj = {
        ReturnUrl: decodeURIComponent(ReturnUrl),
        isGlobal: getUrlCountryPath() === "" ? true : false
      };
      //stringify obj to be able to save it in local storage
      const MoodleRedirection = JSON.stringify(MoodleRedirectionObj);
      sessionStorage.setItem('MoodleReturnUrl', MoodleRedirection);
      //redirect to moodle country
      if(countryPath === '' && cookies?.moodleCountry) {
        sessionStorage.removeItem('showMoodleAlert');
        window.location = [cookies?.moodleCountry.replaceAll('/', ''), LOGIN_PAGE_PATH.replaceAll('/', '')].join('/');
      }
      //set default moodle country when unset
      if(countryPath !== '' && !cookies?.moodleCountry) {
        setCookie('moodleCountry', `/${countryPath}/`, { path: '/', maxAge: 365 * 86400 });
      }
    }
  }, [])

  useEffect(() => {
    if(localReturnUrl && countryPath !== '' && websiteConfig && cookies?.moodleCountry) {
      if(showMoodleAlert !== 'false') {
        const { moodleRedirectConfirmationAlertOnGlobalLandingPage } = websiteConfig;

        let alertMsg = moodleRedirectConfirmationAlertOnGlobalLandingPage ? moodleRedirectConfirmationAlertOnGlobalLandingPage : 'You were trying to access {externalLink}. Please select a country and login to be redirected back.';
        const MoodleRedirectionObj = JSON.parse(localReturnUrl);

        const externalLink = storageReturnUrl ? decodeURIComponent(storageReturnUrl) : MoodleRedirectionObj?.ReturnUrl;
        alertMsg = alertMsg.replaceAll("{externalLink}", externalLink);
        alert(alertMsg);

        sessionStorage.setItem('showMoodleAlert', 'false');
      } 
    }
  }, [localReturnUrl,
    showMoodleAlert,
    websiteConfig, 
    cookies?.moodleCountry, 
    storageReturnUrl, 
    countryPath]);

  useEffect(() => {
    if (localReturnUrl && loggedIn) {
      const MoodleRedirectionObj = JSON.parse(localReturnUrl);
      if (MoodleRedirectionObj?.ReturnUrl && MoodleRedirectionObj?.isGlobal) {
        const url = new URL(MoodleRedirectionObj?.ReturnUrl);
        if (data?.token?.token) {
          url.searchParams.append('token', data?.token?.token);
        }
        if (data?.token?.refreshToken) {
          url.searchParams.append('refreshToken', data?.token?.refreshToken);
        }
        url.searchParams.append(
          'locale',
          `${getUrlCountryPath()}`
        );
        url.searchParams.append(
          'returnUrl',
          `${getLocationOrigin()}/${getUrlCountryPath()}`
        );
        // window.open(url, '_blank');
        sessionStorage.removeItem('MoodleReturnUrl');
        // history.push(history.pathname);
        window.location = url;
      }
    }
    if (localReturnUrl && loggedIn) {
      const MoodleRedirectionObj = JSON.parse(localReturnUrl);
      if (MoodleRedirectionObj?.ReturnUrl && !MoodleRedirectionObj?.isGlobal && MoodleRedirectionObj?.redirect) {
        const url = new URL(MoodleRedirectionObj?.ReturnUrl);
        if (data?.token?.token) {
          url.searchParams.append('token', data?.token?.token);
        }
        if (data?.token?.refreshToken) {
          url.searchParams.append('refreshToken', data?.token?.refreshToken);
        }
        url.searchParams.append(
          'locale',
          `${getUrlCountryPath()}`
        );
        url.searchParams.append(
          'returnUrl',
          `${getLocationOrigin()}/${getUrlCountryPath()}`
        );

        // window.open(url, '_blank');
        sessionStorage.removeItem('MoodleReturnUrl');
        // history.push(history.pathname);
        window.location = url;
      }
    }
  }, [
    loggedIn,
    data?.user?.userId,
    data?.token?.token,
    data?.token?.refreshToken,
    localReturnUrl
  ]);
  return null;
};

export { MoodleRedirections };
