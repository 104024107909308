import PropTypes from 'prop-types';


const formValuesPropTypes = PropTypes.object;
const inlineErrorsPropTypes = PropTypes.object;
const touchedPropTypes = PropTypes.object;


const typeParamsPropType = PropTypes.shape({
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
});

const fieldPropTypes = PropTypes.shape({
  label: PropTypes.string,
  id: PropTypes.string,
  initialValue: PropTypes.any,
  type: PropTypes.string,
  typeParams: typeParamsPropType,
});

const fieldsPropTypes = PropTypes.arrayOf(fieldPropTypes);

const formTabPropTypes = PropTypes.shape({
  text: PropTypes.string,
  id: PropTypes.string,
  formFields: fieldsPropTypes,
});

const formTabsPropTypes = PropTypes.arrayOf(formTabPropTypes);

export {
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
  typeParamsPropType,
  fieldPropTypes,
  fieldsPropTypes,
  formTabPropTypes,
  formTabsPropTypes,
};

