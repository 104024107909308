import PageSection from './PageSection'
import { getValue, getFullImageStr } from 'utils/utilityHelper'
import { getComponentName } from 'utils/acousticMappings'

import AccordionAcousticData from 'components/kanso/components/accordion/AccordionAcousticData'
import CarouselAcousticData from 'components/kanso/components/carousel/CarouselAcousticData'
import ContentCardAcousticData from 'components/kanso/components/contentCard/ContentCardAcousticData'
import ContentCardTileAcousticData from 'components/kanso/components/contentCardTile/ContentCardTileAcousticData'
import DividerAcousticData from 'components/kanso/atoms/divider/DividerAcousticData'
import EditorialAcousticData from '../editorial/EditorialAcousticData'
import SectionFooterAcousticData from 'components/kanso/atoms/sectionFooter/SectionFooterAcousticData'
import SectionTitleAcousticData from 'components/kanso/atoms/sectionTitle/SectionTitleAcousticData'
import TabsAcousticData from 'components/kanso/components/tabs/TabsAcousticData'
import TableAcousticData from 'components/kanso/atoms/table/TableAcousticData'
import { getComponent } from 'pages/flexiTemplate/FlexiTemplate'

const PageSectionAcousticData = ({ data, pageContent }) => {
  const { 
    backgroundImage, 
    content,
    cssClass, 
    footer,
    sectionId,       
    title
  } = data
  return (
    <PageSection
      title={getValue(title?.value?.elements?.title)}
      cssClass={getValue(cssClass)}
      id={getValue(sectionId)}
      bgImage={getFullImageStr(backgroundImage.url)}
    >
      {title?.value && (
        <SectionTitleAcousticData data={title?.value?.elements} />
      )}
      {content?.values?.map((content, index) => {
        const {component: Component, acousticComponentName, props } = getComponent(content, pageContent);
        return Component && <Component key={`${content.type}${index}`} {...props} />
      })}
      {footer?.value && (
        <SectionFooterAcousticData data={footer?.value?.elements} />
      )}
    </PageSection>
  )
}

export default PageSectionAcousticData
