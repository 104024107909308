import {
  SHOULD_HAVE_VALUE,
  SHOULD_MATCH_FIELD,
  SHOULD_BE_EMAIL_FORMAT,
  MIN_LEN,
  SHOULD_CONTAIN_NUMBER,
  SHOULD_CONTAIN_UPPER,
  SHOULD_CONTAIN_LOWER,
  SHOULD_CONTAIN_SPECIAL,
  SHOULD_BE_PROFESSIONAL_EMAIL,
  SHOULD_CONTAIN_ONLY_NUMBER,
  SHOULD_ACCEPT_CHECKBOX,
  SHOULD_ACCEPT_CONSENT,
  SHOULD_HAVE_VALUE_RADIO,
} from 'components/shared/form/formConstants';
import isEmpty from 'lodash.isempty';
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";

// Regex for testing if something is an email, Source:
// https://ui.dev/validate-email-address-javascript/
const isEmailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Regex for testing if something contains a number, source:
// https://stackoverflow.com/questions/5778020/check-whether-an-input-string-contains-a-number-in-javascript
const cointainsNumberRegex = /\d/;

// Regex for testing if something contains an upper case letter:
const cointainsUpperRegex = /[A-Z]/;

//Regex for testing if field contains only numbers
 const cointainsNumberOnlyRegex = /^\d+$/;

// Regex for testing if something contains an lower case letter:
const cointainsLowerRegex = /[a-z]/;

// Regex for testing if something contains a special character, source:
// https://stackoverflow.com/questions/32311081/check-for-special-characters-in-string
const containsSpecialCharacters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;


const getSafeString = (value) => (value ? value : ''); // just to eliminate a chance of typeErrors

// if there is an error, the method should return false
const validationMethodMap = {
  [SHOULD_HAVE_VALUE]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    if(formValues[id]){
      const fieldValue = formValues[id].trim();
      if (fieldValue && fieldValue.length === 0) {
        return errorText;
      }
  
      if (fieldValue) {
        return false;
      }
    }

    return errorText;
  },
  [SHOULD_MATCH_FIELD]: ({ id, formValues, validationObject = {} }) => {
    const { errorText, fieldToMatch } = validationObject;
    const fieldValue = formValues[id];
    const matchFieldValue = formValues[fieldToMatch];

    if (fieldValue !== matchFieldValue) {
      return errorText;
    }

    return false;
  },
  [SHOULD_BE_EMAIL_FORMAT]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (isEmailFormatRegex.test(fieldValue)) {
      return false;
    }

    return errorText;
  },
  [MIN_LEN]: ({ id, formValues, validationObject = {} }) => {
    const { minLength, getErrorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (fieldValue.length > minLength) {
      return false;
    }

    return getErrorText(minLength);
  },
  [SHOULD_CONTAIN_NUMBER]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (cointainsNumberRegex.test(fieldValue)) {
      return false;
    }

    return errorText;
  },
  [SHOULD_CONTAIN_ONLY_NUMBER]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id].trim());

    if (cointainsNumberOnlyRegex.test(fieldValue)) {
      return false;
    }

    return errorText;
  },
  [SHOULD_ACCEPT_CHECKBOX]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (!fieldValue) { // checkbox is not accepted
      return false
    }

    return errorText;
  },
  [SHOULD_CONTAIN_UPPER]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (cointainsUpperRegex.test(fieldValue)) {
      return false;
    }

    return errorText;
  },

  [SHOULD_CONTAIN_LOWER]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (cointainsLowerRegex.test(fieldValue)) {
      return false;
    }

    return errorText;
  },
  [SHOULD_CONTAIN_SPECIAL]: ({ id, formValues, validationObject = {} }) => {
    const { errorText } = validationObject;
    const fieldValue = getSafeString(formValues[id]);

    if (containsSpecialCharacters.test(fieldValue)) {
      return false;
    }

    return errorText;
  },
  [SHOULD_BE_PROFESSIONAL_EMAIL]: ({
    id,
    formValues,
    validationObject = {},
  }) => {
    const { errorText } = validationObject;
    const {
      emailBlacklistDomains,
    } = useWebsiteConfigObject();
    const fieldValue = getSafeString(formValues[id]);
    const blacklistArray = emailBlacklistDomains ? emailBlacklistDomains.split("|") : [];
    let address = fieldValue.split("@");
    let emailValid = true;
    if(address[1]) {
      blacklistArray.map((item) => {
        if (address[1] === (item.trim())) {
          emailValid = false;
        }
      })
    }
    if(emailValid) {
      return false;
    } else {
      return errorText;
    }
  },
  [SHOULD_ACCEPT_CONSENT]: ({
    id,
    formValues,
    validationObject = {},
  }) => {
    const {
      errorText,
    } = validationObject;
    const fieldValue = getSafeString(formValues[id]);
    if (fieldValue) {
      return false;
    }
    return errorText;
  },
  [SHOULD_HAVE_VALUE_RADIO]: ({
    id,
    formValues,
    validationObject = {},
  }) => {
    const {
      errorText,
    } = validationObject;
    const fieldValue = formValues[id];
    if (fieldValue !== '') {
      return false;
    }
    return errorText;
  },
};





const getInlineErrors = ({
  fields,
  formValues,
}) => {

  const inlineErrors = {};

  fields.forEach((field) => {
    const {
      validation = [],
      id,
      mandatory,
      pattern
    } = field;
    
    if (validation) {
      validation.forEach((validationObject) => {
        const {
          validationMethodKey,
        } = validationObject;

        const validationMethod = validationMethodMap[validationMethodKey];

        if (!validationMethod) {
          console.error(`Unrecognised validationMethodKey: ${validationMethodKey}. Unable to perform validation`);
          return;
        }

        const inlineError = validationMethod({
          id,
          formValues,
          validationObject,
        });
        if (inlineError && mandatory) {
          inlineErrors[id] = inlineError; // this means there can only ever be one displayed inline error
        }
        let value = formValues[id];
        if(pattern && value !== "") {
          let regex = new RegExp(pattern);
          if (regex.test(value) === false) {
            inlineErrors[id] = inlineError;
          }
        }
      });
    }
  });

  return inlineErrors;
};


const getHasErrors = ({
  inlineErrors,
}) => !isEmpty(inlineErrors); // ie, if there are inlineErrors, then there are errors;

export {
  getInlineErrors,
  getHasErrors,
};

