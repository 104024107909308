import React from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
} from 'components/shared/form/formPropTypes';


const FreeComponent = ({
  field,
  fieldType,
}) => {

  const {
    typeParams,
    size = 100,
  } = field;

  const {
    PropComponent,
  } = typeParams;


  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
  ];


  return (
    <div
      className={fieldClassNames.join(' ')}
    >
      <PropComponent />
    </div>
  );
};

FreeComponent.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
};


export {
  FreeComponent,
};


