import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: {
    id: null,
    error: null,
    errorKey: null,
  },
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    resetFormState: (state, action) => initialState,
    hasError: (state, action) => {
      const { id, error } = action.payload;
      const { message, response } = error;

      state.loading = false;
      state.error.id = id || null;
      state.error.error = message || null;
      state.error.errorKey = response?.status || null;
    },
  },
});

export const {
  hasError,
  loading,
  resetFormState,
} = formSlice.actions;


// eslint-disable-next-line import/no-default-export
export default formSlice.reducer;
