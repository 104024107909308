const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    taxonomyFields: []
};

const gaSlice = createSlice({
    name: 'ga',
    initialState,
    reducers: {
        setTaxonomyFields: (state, action) => {
            state.taxonomyFields = action.payload
        }
    }
})

export const {
   setTaxonomyFields
  } = gaSlice.actions;
  
  
  // eslint-disable-next-line import/no-default-export
  export default gaSlice.reducer;
  