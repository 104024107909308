import React, { useEffect } from 'react';
import HTMLReactParser from 'html-react-parser';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import ChatBotDiv from 'components/reusableComponents/chatbot/ChatBotDiv';


const ChatBot = ({basePage}) => {

    const { tChatBot } = useWebsiteConfigObject();

    const paths = ['value', 'moduleChatbotPicker', 'value', 'chatbot', 'value', 'elements', 'chatbotJsSnippet'];
    const chatbotJsSnippet = paths.reduce((object, path) => {
        return (object || {})[path];
    }, basePage);

    const jsSnippet = chatbotJsSnippet?.value ? chatbotJsSnippet?.value : tChatBot;

   
    var chatBotScriptEle = HTMLReactParser(jsSnippet == null || jsSnippet == undefined ? "" : jsSnippet);


    const tab = Array.isArray(chatBotScriptEle);

    var chatBotDiv = null;
    if(tab){
      chatBotDiv = chatBotScriptEle?.filter(element => element.props && element.props.id==="ch-widget").length > 0;
    }



    useEffect(() => {
      // Display Chatbot CSS
      if(!chatBotDiv){
        if(document.getElementById('ch-script') != undefined || document.getElementById('ch-script') != null) {
          document.getElementById('ch-script').remove();
          document.getElementById('ch-widget').remove();
        }
        let cssChatbotEle = document.getElementById('chatbot-css');
        let cssChatbotLink;
        if (cssChatbotEle != null || cssChatbotEle != undefined) {
          cssChatbotLink = document.createElement('link');
          cssChatbotLink.setAttribute('rel', 'stylesheet');
          cssChatbotLink.setAttribute('href', cssChatbotEle.getAttribute('href'));
          document.head.appendChild(cssChatbotLink);
        }
  
        // Load Jquery
        if (document.querySelectorAll('[data-src]') != null || document.querySelectorAll('[data-src]') != undefined) {
          document.querySelectorAll('[data-src]').forEach((elem) => {
            if (elem.getAttribute('data-src').includes('jquery')) {
              var s = document.createElement('script');
              s.setAttribute('src', elem.getAttribute('data-src'));
              s.setAttribute('data-element', 'chatbot-script');
              document.body.appendChild(s);
            }
          });
        }
        
        // After 5 seconds load the chatbot script
        setTimeout(function () {
          if (document.querySelectorAll('[data-src]') != null || document.querySelectorAll('[data-src]') != undefined) {
            document.querySelectorAll('[data-src]').forEach((elem) => {
              var s = document.createElement('script');
              s.setAttribute('src', elem.getAttribute('data-src'));
              s.setAttribute('data-element', 'chatbot-script');
              document.body.appendChild(s);
            });
          }
        }, 5000);
  
        return () => {
            if (cssChatbotLink != undefined || cssChatbotLink != null) {
              cssChatbotLink.remove();
            }
            document.querySelectorAll('[data-element]').forEach((elem) => {
              elem.remove();
            });
            if(document.getElementById('chat-cta') != undefined || document.getElementById('chat-cta') != null) {
              document.getElementById('chat-cta').remove();
              document.getElementById('chat-container').remove();
            }
            if(document.getElementById('hubspot-messages-iframe-container') != undefined || document.getElementById('hubspot-messages-iframe-container') != null) {
              document.getElementById('hubspot-messages-iframe-container').remove();
            }
        }
      }
     
    }, []);

    
    if(chatBotDiv){
      return <ChatBotDiv jsSnippet={jsSnippet} />
    }
    
    return (
        <>
        {jsSnippet != undefined 
        ? chatBotScriptEle
        : ""}
        </>
    );
}

export default ChatBot;