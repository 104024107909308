import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from "./api";
import { buildSearchUrl } from '../../utils/searchUtils';
import { createSelector } from "reselect";

// Initial state
const initialState =  {
    searchResults: {},
    loading: false,
    lastFetch: null,
    searchTerm: '',
    recentSearchTerms: [],
    pageSelected: 1,
    maxRecentSearchTermsToDisplay: 10,
    showRecentSearchHistory: true, //adding this as I'm not sure if we want to display this or not
}

const searchSlice = createSlice({
    name:'search',
    initialState,
    reducers: {
        searchRequested: (state, action) => {
            state.loading = true;
        },

        searchReceived: (state, action) => {
            state.searchResults = action.payload;
            state.loading = false;
            state.lastFetch = Date.now();
        },
    
        searchRequestFailed: (state, action) => {
            state.loading = false;
        },

        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },

        setRecentSearchTerms: (state, action) => {
            state.recentSearchTerms.push(action.payload)
        },

        clearRecentSearchTerms: (state, action) => {
            state.recentSearchTerms = []
        },

        setPageSelected: (state, action) => {
            state.pageSelected = action.payload;
        }

    }
})

const { searchRequested, searchReceived, searchRequestFailed, setSearchTerm, setRecentSearchTerms, clearRecentSearchTerms, setPageSelected } = searchSlice.actions

export const search = (searchTerm, startAt = 0) => (dispatch) => {

    dispatch(setSearchTerm(searchTerm))
    dispatch(setRecentSearchTerms(searchTerm))

    return dispatch (
        apiCallBegan({
            url: buildSearchUrl(searchTerm, startAt),
            method: "get",
            onStart: searchRequested.type,
            onSuccess: searchReceived.type,
            onError: searchRequestFailed.type
        })
    );
}

export const clearAllRecentSearchTerms = () => (dispatch) => {
    dispatch(clearRecentSearchTerms())
}

export const selectedPage = (pageSelected) => (dispatch) => {
    dispatch(setPageSelected(pageSelected))
}

export const recentUniqueSearchTerm = createSelector(
    state => state.search,
    (search, projects) => [...new Set(search.recentSearchTerms)]
  );



export default searchSlice.reducer