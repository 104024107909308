import {
  ARTICLE_PAGE,
  MAIN_PROMO_PAGE_ALTERNATE,
  CONTENT_LIST_PAGE,
  CONTENT_PAGE,
  CONTENT_PROMO,
  GENERIC_PAGE,
  LANDING_PAGE,
  MAIN_PROMO_PAGE,
  MAIN_THERAPY_PAGE,
  PRE_REGISTRATION_PAGE,
  VEEVA_PLAYER_PAGE,
  DYNAMIC_ARTICLE_PAGE,
  DYNAMIC_CONTENT_PAGE,
  BRAND_LANDING_PAGE,
  BRAND_ARTICLE_PAGE,
  CARD_LIST_PAGE,
  THERAPY_AREA_LANDING_PAGE,
  EDETAILER_PAGER4,
  MOODLE_DETAIL_PAGE,
  MOODLE_LIST_PAGE,
  THIRD_PARTY_PAGE,
  WEBINAR_DETAIL_PAGE,
  WEBINAR_LIST_PAGE,
  EVENT_LANDING_PAGE,
  EVENT_THANK_YOU_PAGE,
  INFOGRAPHICS,
  GLOBAL_LANDING_PAGE,
  FLEXI_TEMPLATE,
  CALCULATOR_TEMPLATE
} from 'api/acousticApi/contentTypes';
import { AvailableTreatmentsPage } from './AvailableTreatmentsPage';
import { GenericPage } from './GenericPage';
import { VeevaPlayerPage } from './VeevaPlayerPage';
import { HomePage } from './HomePage';
import { ContentListPage } from './ContentListPage';
import { PreRegistrationPage } from './PreRegistrationPage';
import ContentPromoPage from '../../pages/contentPromoPage/ContentPromoPage';
import MainPage from '../../pages/mainPage/MainPage';
import MainPagePromo from '../../pages/mainPagePromo/MainPagePromo';
import ArticlePage from 'pages/articlePage/ArticlePage';
import ContentPage from 'pages/contentPage/ContentPage';
import DynamicArticlePage from 'pages/dynamicArticlePage/DynamicArticlePage';
import DynamicContentPage from 'pages/dynamicContentPage/DynamicContentPage';
import BrandLandingPage from '../../pages/brandLandingPage/BrandLandingPage';
import BrandArticlePage from '../../pages/brandArticlePage/BrandArticlePage';
import CardListPage from '../../pages/cardListPage/CardListPage';
import TherapyAreaLandingPage from '../../pages/therapyAreaLandingPage/TherapyAreaLandingPage';
import SearchResult from 'pages/searchResults/SearchResults';
import ThirdPartyPage from '../pageTemplates/ThirdPartyPage';
import EdetailerPage from './EdetailerPage';
import DetailCoursePage from './moodlePage/MoodleDetail.jsx';
import WebinarDetailPage from './webinar/WebinarDetailPage';
import { MoodleList } from './moodlePage/MoodleList';
import { WebinarListPage } from './webinar/WebinarListPage';
import Eapc from './event/EventLandingPage.jsx';
import EventThankYouPage from './event/EventThankYouPage';
import InfographicsTBPage from './inforgraphicstb';
import M from 'minimatch';
import GlobalPage from 'pages/globalPage/GlobalPage';
import FlexiTemplate from 'pages/flexiTemplate/FlexiTemplate';
import QuizTemplate from 'pages/quizTemplate/QuizTemplate';

const DEFAULT_TEMPLATE = GENERIC_PAGE;

const SEARCH_TEMPLATE = 'Search_Result';

// list of page template types to be included in the search query for dynamic routes.
// does not include the LANDING_PAGE type, as this has a fixed route of '/'
// which is hard coded within the app
const pageTemplateTypesWithDynamicRoutes = [
  CONTENT_PROMO,
  MAIN_THERAPY_PAGE,
  ARTICLE_PAGE,
  CONTENT_LIST_PAGE,
  MAIN_PROMO_PAGE,
  GENERIC_PAGE,
  VEEVA_PLAYER_PAGE,
  MAIN_PROMO_PAGE_ALTERNATE,
  CONTENT_PAGE,
  PRE_REGISTRATION_PAGE,
  DYNAMIC_ARTICLE_PAGE,
  DYNAMIC_CONTENT_PAGE,
  BRAND_LANDING_PAGE,
  BRAND_ARTICLE_PAGE,
  CARD_LIST_PAGE,
  THERAPY_AREA_LANDING_PAGE,
  EDETAILER_PAGER4,
  MOODLE_DETAIL_PAGE,
  MOODLE_LIST_PAGE,
  THIRD_PARTY_PAGE,
  EDETAILER_PAGER4,
  WEBINAR_DETAIL_PAGE,
  WEBINAR_LIST_PAGE,
  EVENT_LANDING_PAGE,
  EVENT_THANK_YOU_PAGE,
  INFOGRAPHICS,
  GLOBAL_LANDING_PAGE,
  FLEXI_TEMPLATE,
  CALCULATOR_TEMPLATE
];

const pageTemplateComponentMap = {
  [CONTENT_PROMO]: ContentPromoPage,
  [MAIN_THERAPY_PAGE]: MainPage,
  [ARTICLE_PAGE]: ArticlePage,
  [CONTENT_LIST_PAGE]: ContentListPage,
  [LANDING_PAGE]: HomePage,
  [MAIN_PROMO_PAGE]: MainPagePromo,
  [GENERIC_PAGE]: GenericPage,
  [VEEVA_PLAYER_PAGE]: VeevaPlayerPage,
  [MAIN_PROMO_PAGE_ALTERNATE]: AvailableTreatmentsPage,
  [CONTENT_PAGE]: ContentPage,
  [PRE_REGISTRATION_PAGE]: PreRegistrationPage,
  [DYNAMIC_ARTICLE_PAGE]: DynamicArticlePage,
  [DYNAMIC_CONTENT_PAGE]: DynamicContentPage,
  [BRAND_LANDING_PAGE]: BrandLandingPage,
  [BRAND_ARTICLE_PAGE]: BrandArticlePage,
  [CARD_LIST_PAGE]: CardListPage,
  [THERAPY_AREA_LANDING_PAGE]: TherapyAreaLandingPage,
  [SEARCH_TEMPLATE]: SearchResult,
  [EDETAILER_PAGER4] : EdetailerPage,
  [ MOODLE_DETAIL_PAGE] : DetailCoursePage,
  [MOODLE_LIST_PAGE]: MoodleList,
  [THIRD_PARTY_PAGE]:ThirdPartyPage,
  [EDETAILER_PAGER4] : EdetailerPage,
  [WEBINAR_DETAIL_PAGE] :WebinarDetailPage,
  [WEBINAR_LIST_PAGE]:WebinarListPage,
  [EVENT_LANDING_PAGE] :Eapc,
  [EVENT_THANK_YOU_PAGE] :EventThankYouPage,
  [INFOGRAPHICS] : InfographicsTBPage,
  [GLOBAL_LANDING_PAGE] : GlobalPage,
  [FLEXI_TEMPLATE]: FlexiTemplate,
  [CALCULATOR_TEMPLATE]: QuizTemplate
};

const getPageTemplateForType = (type) => {
  const template = pageTemplateComponentMap[type];
  return template ? template : DEFAULT_TEMPLATE;
};

const isPromoContentType = (type) => ([
  CONTENT_PROMO,
  MAIN_PROMO_PAGE,
  BRAND_LANDING_PAGE,
  BRAND_ARTICLE_PAGE,
].includes(type));

// template types that are accessible
// without a valid login
const publicAccessTypes = [
  GENERIC_PAGE,
  VEEVA_PLAYER_PAGE,
  LANDING_PAGE,
  PRE_REGISTRATION_PAGE,
  THIRD_PARTY_PAGE,
  EDETAILER_PAGER4
];

const templateTypeRequiresLogin = ({ type, removeTypes = [], requireAll = false }) => {
  if (requireAll) {
    return true;
  }

  if (removeTypes.length > 0) {
    publicAccessTypes.forEach((contentType, index) => {
      if (removeTypes.includes(contentType)) {
        publicAccessTypes.splice(index, 1);
      }
    });
  }

  if (publicAccessTypes.includes(type)) {
    return false;
  }
  return true;
};

export {
  pageTemplateTypesWithDynamicRoutes,
  pageTemplateComponentMap,
  publicAccessTypes,
  getPageTemplateForType,
  isPromoContentType,
  templateTypeRequiresLogin,
};
