import { useQuery } from 'react-query';
import { useCurrentApiRoute } from 'routes/useApiRoutes';
import { getAPIContent } from 'api/acousticApi';
// custom hooks for managing content from the acoustic API
// see https://reactjs.org/docs/hooks-custom.html

const CONTENT_PAGE_QUERY_KEY = 'content-page-query';

// hook to retrieve the content for a page from acoustic
const usePageContent = (pageId) => {
  const { isLoading, error, data } = useQuery(
    // first parameter of useQuery is a key, which is an array
    // the arrays first element is the cache name for the query,
    // the rest of the array contains any parameters for the query
    // in this case the pageId
    [CONTENT_PAGE_QUERY_KEY, pageId],
    // the second parameter is a function to run the query. Any parameters passed in
    // to the array are passed to the callback function, so in this case
    // key == CONTENT_PAGE_QUERY_KEY
    // id == pageId
    // https://react-query.tanstack.com/docs/guides/queries#query-key-variables
    (_key, id) => {
      if (!id) { return null; }
      return getAPIContent(id);
    },
  );
  return ({
    isLoading,
    error,
    content: data,
  });
};

const useCurrentPageContent = () => {
  const currentRoute = useCurrentApiRoute();
  const contentId = currentRoute && currentRoute.contentId;
  const { content } = usePageContent(contentId);
  return content;
};


export {
  usePageContent,
  useCurrentPageContent,
};
