import Quiz from './Quiz'
import { useSelector } from 'react-redux'
import { getValue, getValues } from '../../../../utils/utilityHelper'
import { extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics'

const QuizAcousticData = (props) => {

  const { 
    exitButton,
    quizId,
    questions,
    quizTitle,
    backButton,
    nextButton,
    restartButton,
    submitButton,
    resultsPage,
    questioncounterplaceholder,
    embedded,
    resultContent,
    pageContent
  } = props.data

  // const {globalNavigation} = useSelector((state) => state.acoustic.data)

  const { taxonomyFields } = useSelector((state) => state.ga);
  const taxonomy = extractTaxonomyValues(taxonomyFields, getValue(pageContent?.elements?.taxonomy));
  return (
    <Quiz 
      quizId={quizId}
      // logo={globalNavigation?.yourLogo?.url}
      // logoAltText={globalNavigation?.siteLogoText?.value}
      exitButtonText={getValue(exitButton?.value?.elements?.text)}
      restartButtonText={getValue(restartButton?.value?.elements?.text)}
      caption={getValue(quizTitle)}
      questions={getValues(questions)}
      backButtonText={getValue(backButton?.value?.elements?.text)}
      nextButtonText={getValue(nextButton?.value?.elements?.text)}
      submitButtonText = {getValue(submitButton?.value?.elements?.text)}
      results={getValue(resultsPage)}
      questionCountPlaceholder={getValue(questioncounterplaceholder)}
      embedded={embedded}
      resultContent={getValue(resultContent)}
      taxonomy={taxonomy}
    />
  )
}

export default QuizAcousticData

