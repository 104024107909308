
import "./MyHubDocNumber.scss";
const MyHubDocNumber = (props) => {
    return (
        <div className="docNumber">
            <p>{props.docNumber}</p>
        </div>
    );
}

export default MyHubDocNumber;