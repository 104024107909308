import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import {
  getFooterTextDisplayData,
  getHrefs,
} from 'utils/contentUtils';
import { getAssetUrl, getImgAttr } from 'api/acousticApi';
import { TextCard } from 'components/content/TextCard';
import RichText from 'components/reusableComponents/richText/RichText'
import { TextCardLearnMore } from './TextCardLearnMore';





// text card that maps to a cms content element
const CMSTextCard = ({ element }) => {
  if (!element) { return null; }
  const {
    title,
    bodyCopy,
    cardCategory,
    footerText,
    displayLearnMoreOrFooterText,
    externalLink,
    link,
    page,
    linkToInternalPageOrExternalLink,
    displayExternalLinkChevron,
    image,
    fileAsset,
  } = element.elements;

  const { linkText, linkURL } = link || {};

  const {
    footerTextValue,
    displayChevron,
  } = getFooterTextDisplayData({
    displayLearnMoreOrFooterText,
    displayExternalLinkChevron,
    footerText,
    linkText
  });

  const {
    internalHref,
    externalHref,
  } = getHrefs({
    externalLink,
    page,
    link: linkURL,
    linkToInternalPageOrExternalLink,
  });

  const assetUrl = getAssetUrl(fileAsset);
  const externalLinkValue = assetUrl ? assetUrl : externalHref;

  return (
    <TextCard
      title={title && title.value}
      category={cardCategory && cardCategory.value}
      onClickPath={internalHref}
      externalUrl={externalLinkValue}
      image={getImgAttr(image)}
      fileAsset={fileAsset}
    >
      <RichText
        data={bodyCopy}
      />
      {displayChevron ? (
        <TextCardLearnMore
          text={footerTextValue}
        />
      ) : (
        <p className="meta-tags">
          {footerTextValue}
        </p>
      )}
    </TextCard>
  );
};

CMSTextCard.propTypes = {
  element: contentElementProptype,
};

export { CMSTextCard };

