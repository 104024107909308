import { useLocation } from 'react-router-dom'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
  // [::1] is the IPv6 localhost address.
  || window.location.hostname === '[::1]'
  // 127.0.0.1/8 is considered localhost for IPv4.
  || window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  )
)

// Method to get a specified GET parameter from the current location search string
// so for example if the current url is "https://www.kklife.com/artilce1?param1=bob&param2=bill"
// passing in paramName = 'param2' will return 'bill'
// Note this would be a lot easier with https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
// but that's not compatible with IE11 without polyfill
const useLocationSearchParameter = (paramName) => {
  const location = useLocation()
  const searchString = location.search
  if (!searchString || !paramName) { return null }
  // assuming paramName = 'param1'
  // the regular expression below will capture blah in:
  // - ?param1=blah
  // - ?param1=blah&param2=another
  // - ?param2=whatever&param1=blah
  // The ([^&$]*) is a capturing group. if there is a match it is returned as the second element
  // in the match array. This is the bit that will contain 'blah'
  const filterRegex = new RegExp(`[?&]${paramName}=([^&$]*)`)
  const match = location.search.match(filterRegex)
  return match ? match[1] : null
}

// gets the url origin (eith built in ie11 polyfill)
// polyfill based off of:
// https://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
const getLocationOrigin = () => {
  if (!window.location.origin) {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
  }
  return window.location.origin
}

// Sourced from:
// https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
const getHostnameFromUrl = (url) => {
  let hostname

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  }
  else {
    hostname = url.split('/')[0]
  }

  if (isLocalhost) {
    return hostname
  }

  // find & remove port number
  hostname = hostname.split(':')[0]
  // find & remove "?"
  hostname = hostname.split('?')[0]

  return hostname
}

// Sourced from:
// https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
const getDomainFromUrl = (url) => {
  let domain = getHostnameFromUrl(url)
  const splitArr = domain.split('.')
  const arrLen = splitArr.length

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if ((splitArr[arrLen - 2].length === 2) && (splitArr[arrLen - 1].length === 2)) {
      // this is using a ccTLD
      domain = `${splitArr[arrLen - 3]}.${domain}`
    }
  }
  return domain
}

const getUrlCountryPath = () => {
  return getUrlCountryPathFromUrl(window.location.pathname);
}

const getUrlCountryPathFromUrl = (url) => {
  // Regex pattern based on iso languange (ISO 639-1)
  const isoPattern = new RegExp('^[a-z]{2}(?:-[a-z]{2})?$')
  // Split pathnames by slashes and get value in index 1
  const isoCode = url.split('/')[1]
  //if events library - EAPC
  if(isoCode && isoCode.toLowerCase() ==='events')
  {
    return `${isoCode}`
  }
  // Test if value matches regex expression (isoPattern)
  // If matches return isoCode value
  if (isoPattern.test(isoCode)) {
    return `${isoCode}`
  }
  // If no match then return default
  return ''
}

// Get the language code from country path
const getLanguageCode = () => {
  const countryPath = getUrlCountryPath()
  const languageCode = countryPath.substring(0, countryPath.indexOf('-'))

  return languageCode
}

// Get the language code from country path
const getCountryCode = () => {
  const countryPath = getUrlCountryPath()
  const countryCode = countryPath.substring(countryPath.indexOf('-') + 1)

  return countryCode
}

const getDocheckUrlRedirect = () => {
  let countryPath = window.location.pathname;
  countryPath = countryPath.replace("login",'');
  countryPath = countryPath.replace(/\//g,'%7Bslash%7D');
  return countryPath;
}

const urlToTitle = (url) => url.toLowerCase().replace('-',' ')

export {
  useLocationSearchParameter,
  getLocationOrigin,
  isLocalhost,
  getDomainFromUrl,
  getHostnameFromUrl,
  getUrlCountryPath,
  getUrlCountryPathFromUrl,
  getLanguageCode,
  getCountryCode,
  urlToTitle,
  getDocheckUrlRedirect
}
