const ordinalSuffixes = {
  en: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
  default: {
    one: '',
    two: '',
    few: '',
    other: '',
  }
};

export class OrdinalFormat {
  #rules;
  #suffixes;

  constructor(locale) {
    if (!(locale in ordinalSuffixes)) locale = 'default';
    this.#suffixes = ordinalSuffixes[locale];
    this.#rules = new Intl.PluralRules(locale, { type: 'ordinal' });
  }

  withOrdinalSuffix(x) {
    if (typeof x != 'number')
      throw new TypeError(`Expected Number but received ${typeof x}`);
    if (x < 1) throw new RangeError(`Expected a number > 0 but received ${x}`);
    const ordinal = this.#rules.select(x);
    if (!(ordinal in this.#suffixes))
      throw new Error(`Unexpected ordinal ${ordinal}`);
    const suffix = this.#suffixes[ordinal];
    return `${x}${suffix}`;
  }
}
