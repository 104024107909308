import React from "react";
import Link from "components/reusableComponents/link/Link";
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import RichText from "components/reusableComponents/richText/RichText";

const PrivacyConsent = () => {
  const {
    privacyAndCookiePath,
    tPrivacyLabel,
    tPolicyText,
    termsAndConditionsPath,
    tTermsLabel,
  } = useWebsiteConfigObject();
const PolicyTextValue = { value: tPolicyText };
  return (
    <p className="marketing-consent">
      <RichText data={PolicyTextValue} />
    </p>
  );
};

export { PrivacyConsent };
