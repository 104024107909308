/* eslint-disable react/jsx-no-target-blank */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { ConfirmAndNavigate } from 'utils/hyperlinkUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import useAppendAuthParams from 'hooks/useAppendAuthParams';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getFileName } from 'utils/stringUtils';
import { useSelector } from 'react-redux';

const NavLink = forwardRef((props, ref) => {
  const {
    href: _href,
    rel,
    onClick,
    state,
    noConfirm,
    sameTab,
    activeClassName,
    vvClickAttr,
    vvSequenceAttr,
    ...rest
  } = props;

  const location = useLocation();
  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;

  const { tYouAreAboutToLeave } = useWebsiteConfigObject();
  const { trackOutboundLink, trackPDFDownload } = useGoogleAnalytics();

  const message =
    tYouAreAboutToLeave ||
    'You are about to leave the Kyowa Kirin site. Are you sure you want to continue?';
  let link = _href;
  if(_href.startsWith('www')){
    link = "https://" + _href;
  }
  const { value: href, disableExternalMessageAlert } = useAppendAuthParams(
    link
  );

  const target = sameTab ? '_self' : '_blank';

  function trackAnalyticsEvent(url) {
    trackOutboundLink(url);

    const filename = getFileName(url);
    if(filename.endsWith('.pdf')) trackPDFDownload(filename, userId);
  }

  if (!_href) {
    return null;
  }

  if (_href.startsWith('#')) {
    // Anchor tag
    return (
      <a ref={ref} href={href} {...rest}>
        {props.children}
      </a>
    );
  }

  if (_href.startsWith('http') || _href.startsWith('www')) {
    // External link
    return (
      <a
        onClick={(evt) => {
          evt.preventDefault();

          if (onClick) {
            onClick();
          }

          ConfirmAndNavigate({
            href,
            message,
            bypass: disableExternalMessageAlert || noConfirm || null,
            target,
            trackAnalyticsEvent,
          });
        }}
        ref={ref}
        href={href}
        target={target}
        rel={`noreferrer${rel ? ` ${rel}` : ''}`}
        {...rest}
      >
        {props.children}
      </a>
    );
  }

  // Internal link
  return (
    <RouterLink
      activeClassName={activeClassName}
      onClick={onClick}
      data-vv-action={vvClickAttr}
      data-vv-seq={vvSequenceAttr}
      ref={ref}
      rel={rel || ''}
      to={{
        pathname: href,
        state: {
          global: {
            prevPath: location.pathname,
          },
          ...state,
        },
      }}
      {...rest}
    >
      {props.children}
    </RouterLink>
  );
});

export default NavLink;
