import React from 'react';
import { contentItemProptype } from 'propTypes/contentProptypes';
import {
  getValue,
} from 'utils/contentUtils';
import { TextCard } from 'components/content/TextCard';
import RichText from 'components/reusableComponents/richText/RichText'
import { TextCardLearnMore } from './TextCardLearnMore';

// text card that is an excerpt view of a CMS page
const CMSPageCard = ({ page }) => {
  if (!page) { return null; }
  const {
    title,
    cardCategory,
    excerpt,
    urlSlug,
    headerLogo
  } = page.elements;

  return (
    <TextCard
      title={getValue(title)}
      category={getValue(cardCategory)}
      onClickPath={getValue(urlSlug)}
      logoSrc={headerLogo?.url}
    >
      <RichText
        data={excerpt}
      />
      <TextCardLearnMore />
    </TextCard>
  );
};

CMSPageCard.propTypes = {
  page: contentItemProptype,
};

export { CMSPageCard };

