import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { utmRegistration } from '../../../api/utmRegistration/index';
import { useEffect } from 'react';



const UtmRegistration = () => {
    const { search } = useLocation();

    if (search) {
        const querString_value = search.substring(1);
        localStorage.setItem("incommingquerystring", querString_value);
    }

    const { additional } = useSelector((state) => state.authentication.data);
    const { loggedIn } = useSelector((state) => state.authentication.status);
    useEffect(()=>{
        if (loggedIn && additional?.tokenResponse?.userCreatedDate && additional?.signUpGaEventRegistered) {
            const today = new Date().toISOString();
            let currentDate = new Date(today).getTime();
            let registeredDate = new Date(additional?.tokenResponse?.userCreatedDate).getTime();
            let mins = (currentDate - registeredDate) / 60000;
            if (mins < 15) {
                const querystring = localStorage.getItem("incommingquerystring");
                if(querystring){
                    const userId = additional?.tokenResponse?.userId;
                    const body = {
                        "type": "UTMRegistration",
                        "additionalValues": querystring
                    };
                    utmRegistration(body, userId);
                }
                setTimeout(()=>{
                    localStorage.removeItem("incommingquerystring");
                },2000);
            }
        }
    },[loggedIn])
    return null;
}




export default UtmRegistration;