import React from 'react'
import RecentSearchIcon from 'assets/images/recent-search-icon.png';

const SearchBoxHistory = (props) => {

    return (
        <div className='RecentHistory'>
            <div className="RecentHistory-Head">
                <h5>Recent</h5>
                <button onClick={() => props.clearAll()}>Clear All</button> 
            </div>
            <div className="RecentHistory-Body">
                <ul className="RecentHistory-List">
                    {props.recentSearchTerms && props.recentSearchTerms.slice(props.maxRecentSearchTermsToDisplay).reverse().map((item) => (
                        <li key={item} onClick={() => props.recentTermSelected(item)} className="RecentHistory-List-Item">
                            <img src={RecentSearchIcon} alt="Recent search" />
                            <span>{item}</span> 
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )

}

export default SearchBoxHistory