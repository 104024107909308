import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAssetUrl } from 'api/acousticApi';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import KKLogo from 'assets/images/kk-logo.svg';
import VideoWithChapters from 'components/reusableComponents/video/videoChapters/VideoWithChapters';
import Image from 'components/reusableComponents/image/Image';
import "./Video.scss";
import { PlayVideo } from 'components/shared/SvgIcons';

import { videoPause, videoPlay, videoEnd, videoTimeUpdate } from 'utils/tealiumUtils';

const Video = ({
  element,
  title,
  webvttChaptersFile,
  subtitles,
  videoPoster,
  videoPosterImage,
  showControls = true,
  disablePlay = false,
  externalVideo
}) => {
  const { trackVideo } = useGoogleAnalytics();
  const [hidePoster, setHidePoster] = useState(false);
  const videoRef = useRef(null);
  const history = useHistory();
  
  useEffect(() => {
    if(history.action === "PUSH") {
      if(window.utag) {
        initVideoTealium();
      } else {
        const utagInterval = setInterval(() => {
          if(window.utag) {
            initVideoTealium();
            clearInterval(utagInterval);
          }
        },1000)
      }
    }
  },[])
  const initVideoTealium = () => {
    window.videoplayers = [];
    window.mileStones = [10, 25, 50, 75, 90];
    let videos = document.getElementsByTagName('video');
    for (let i = 0; i < videos.length; i++) {
      let id = videos[i].id;
      if (id === null || id === undefined) {
        videos[i].id = 'tl_video_' + i;
      }
      videos[i].removeEventListener("ended", videoEnd);
      videos[i].addEventListener('ended', videoEnd, false);
      videos[i].removeEventListener("timeupdate", videoTimeUpdate);
      videos[i].addEventListener(
        'timeupdate',
        videoTimeUpdate,
        false
      );
      videos[i].removeEventListener("play", videoPlay);
      videos[i].addEventListener('play', videoPlay, false);
      videos[i].removeEventListener("pause", videoPause);
      videos[i].addEventListener('pause', videoPause, false);
      window.videoplayers.push({
        playerId: videos[i].id,
        mileStones: JSON.parse(JSON.stringify(window.mileStones)),
      });
    }
  }
  const videoAsset = element ? element.asset : null;
  if (!videoAsset && !externalVideo?.value) {
    return null;
  }

  const url = getAssetUrl(element);
  const watchedPercentage = Array(9);

  const webvttChaptersUrl = getAssetUrl(webvttChaptersFile);

  let playTimes = 0;
  let pauseTimes = 0;
  const playVideo = () => {
    if(!disablePlay && videoRef.current){
      videoRef.current.play();
    }
  }
  function onPlay(e) {
    const videoElements = document.getElementsByTagName('video');
    Array.from(videoElements).forEach(video => {
      if (video !== e.target && !video.paused) video.pause();
    });
    setHidePoster(true);
    playTimes = playTimes + 1;
    const loadedPercentage = Math.round(
      (e.currentTarget.currentTime / e.currentTarget.duration) * 100
    );
    trackVideo(!hidePoster && playTimes === 1 ? 'video_start' : 'video_resume', e?.currentTarget, loadedPercentage);
  }

  function onPause(e) {
    pauseTimes = pauseTimes + 1;
    const loadedPercentage = Math.round(
      (e.currentTarget.currentTime / e.currentTarget.duration) * 100
    );
    if (loadedPercentage < 100)
      trackVideo('video_pause', e?.currentTarget, loadedPercentage);
  }

  function onEnded(e) {
    trackVideo('video_complete', e?.currentTarget, '100');
  }
   
  let previousTime = 0;
  let currentTime = 0;
  let seekStart = null;
  function onSeeked(e) {
    const loadedPercentage = Math.round(
      (currentTime / e.currentTarget.duration) * 100
    );
    trackVideo(seekStart < currentTime ? 'video_fastforward' : 'video_rewind', e?.currentTarget, loadedPercentage)
    seekStart = null;
  }

  function onSeeking(e) {
    if(seekStart ===   null) seekStart = previousTime;
  }

  function onTimeUpdate(e) {
    previousTime = currentTime;
    currentTime = e.currentTarget.currentTime;

    if (playTimes === pauseTimes)
      //only track if video is playing
      return;
    
    const loadedPercentage = Math.trunc(
      (e.currentTarget.currentTime / e.currentTarget.duration) * 10
    );
    for (let i = 1; i <= 9; i++) {
      if (loadedPercentage === i && !watchedPercentage[i]) {
        watchedPercentage[i] = true;
        trackVideo('video_progress', e?.currentTarget, i * 10);
        return;
      }
    }
  }

  const webvttSubtitles = [];
  (subtitles?.values || []).forEach((element, index) => {
    webvttSubtitles.push(
      <track
        key={index}
        label={element.elements.label.value}
        kind='subtitles'
        srcLang={element.elements.srclang.value}
        src={getAssetUrl(element.elements.webvttSubtitleUrl)}
        default={element.elements.default.value}
      ></track>
    );
  });
  // perhaps refactoring will be necessary on this file ( duplicate code? )

  if (webvttChaptersUrl) {
    return (
      <VideoWithChapters
        onPause={onPause}
        onPlay={onPlay}
        onEnded={onEnded}
        onSeeking={onSeeking}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        url={externalVideo?.value ? externalVideo?.value : url}
        title={title}
        videoAsset={videoAsset}
        webvttChaptersUrl={webvttChaptersUrl}
        subtitles={subtitles}
        videoPoster={videoPoster}
        videoPosterImage={videoPosterImage}
        showControls={showControls}
        disablePlay={disablePlay}
        externalVideo={externalVideo}
        crossOrigin='anonymous'
      />
    );
  } else {
    let posterImage = getAssetUrl(videoPosterImage);
    posterImage = posterImage ? posterImage : KKLogo;

    return (
      <figure className='video'>
        {videoPosterImage?.url && videoPoster?.value && !hidePoster && 
          <div className='video__poster' onClick={playVideo}>
            <Image className="video__poster__img" data={videoPosterImage} />
            <PlayVideo className="video__poster__play"/>
          </div>
        }
        
        {
          externalVideo?.value ? 
              <video
                ref={videoRef}
                controls={showControls}
                onPause={onPause}
                onPlay={onPlay}
                onEnded={onEnded}
                onSeeking={onSeeking}
                onSeeked={onSeeked}
                controlsList='nodownload'
                onTimeUpdate={onTimeUpdate}
                crossOrigin='anonymous'
                style={{ width: '100%',height:'100%', backgroundColor:!videoPosterImage?.url && videoPoster?.value ? "white" : ""}}
                poster={!videoPosterImage?.url && videoPoster?.value ? KKLogo : ""}
                key={externalVideo?.value}
              >
                <source src={externalVideo?.value} type='video/mp4' />
                {webvttSubtitles}
              </video> 
              
            :
              <video
                ref={videoRef}
                controls={showControls}
                controlsList='nodownload'
                onPause={onPause}
                onPlay={onPlay}
                onEnded={onEnded}
                onSeeking={onSeeking}
                onSeeked={onSeeked}
                onTimeUpdate={onTimeUpdate}
                crossOrigin='anonymous'
                style={{ width: '100%',height:'100%', backgroundColor:!videoPosterImage?.url && videoPoster?.value ? "white" : ""}}
                poster={!videoPosterImage?.url && videoPoster?.value ? KKLogo : ""}
                key={url}
                title={title?.value ? title.value : videoAsset.fileName}
                id={videoAsset.id}
              >
                <source src={url} type={videoAsset.mediaType != 'video/quicktime' ? videoAsset.mediaType : 'video/mp4'} />
                {webvttSubtitles}
              </video>
        }
      </figure>
    );
  }
};

Video.propTypes = {
  element: PropTypes.objectOf(PropTypes.any),
};

export default Video;
