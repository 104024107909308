import React from 'react';

// layouts
import Grid from 'layouts/grid/Grid'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import SideNavigation from 'modules/sideNavigation/SideNavigation'
import VideoCard from 'modules/videoCard/VideoCard'

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { DynamicContentSection } from 'pages/dynamicContentPage/DynamicContentSection'
import { References } from 'components/content/References'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode'
import { getValue } from 'utils/contentUtils'
import Logo from 'modules/logo/Logo'
import Hero from 'modules/hero/Hero'
// import clock from '../../assets/images/clock.png';


const MDTIntroSection = (props) => {
    
    const {
        headerText,
        title,
        subtitle,
        headerLogo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        content,
        relatedVideos,
        relatedContent,
        bannerImage,
        veevaApprovalCode,
        references,
        subnavigation,
        logo,
        hero,
    } = props?.content?.elements;

    const isFullWidth = relatedVideos?.value?.elements?.fullWidthVideoCards?.value

    // const timeReading = timeToRead.value;

    // const externalContentLinkUrl = externalContentLink.linkURL ?? '';

    // need to create an external function to get the token
    const authObject = window.localStorage.getItem('persist:auth')
    if (!authObject) return false
    const auth = JSON.parse(authObject)
    const data = auth?.data
    const { token: _token } = JSON.parse(data) || {}
    const { token } = _token || {}
    //  end of function get token

    const hasSubnavigation = Boolean(subnavigation?.value?.elements?.subnavigationItems?.values)

    return (
        // <section className="mdt-biomakers-intro">
        //     <div className="container">
        //         <div className="container_inner">
        //             <section className="content_wrapper content_wrapper--columns-2-left">
        //                 <nav class="SideNavigation">
        //                     <Link aria-current="page" class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease">
        //                         <span class="SideNavigation_Item--level1">Parkinson's Disease</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item SideNavigation_Item--active" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education">
        //                         <span class="SideNavigation_Item--level1">Medical Education</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item SideNavigation_Item--active" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond">
        //                         <span class="SideNavigation_Item--level2">Think Beyond Programme</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/steering-committee">
        //                         <span class="SideNavigation_Item--level3">Our Steering Committee</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/clinical-cases">
        //                         <span class="SideNavigation_Item--level3">Clinical Cases</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item SideNavigation_Item--active" rel="" href="/therapy-areas/neurology/parkinsons-disease/think-beyond-medical-education/elearning/progressionofpd">
        //                         <span class="SideNavigation_Item--level3">Progression of PD</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/think-beyond-medical-education/elearning/managingparkinsonsasmdt">
        //                         <span class="SideNavigation_Item--level3">Managing Parkinson's as MDT</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/Identifyingandquantifyingoffstateepisodes">
        //                         <span class="SideNavigation_Item--level3">Identifying and quantifying ‘OFF’ state episodes</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/Impactofoffstateondailylife">
        //                         <span class="SideNavigation_Item--level3">Impact of ‘OFF’ state on daily life</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/understandingandcommunicatingwithpeoplelivingwithparkinsons">
        //                         <span class="SideNavigation_Item--level3">Understanding and communicating with people living with Parkinson’s</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/think-beyond-medical-education/publicationspotlightvideos">
        //                         <span class="SideNavigation_Item--level3">Publication Spotlight Videos</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/mechanism-of-disease">
        //                         <span class="SideNavigation_Item--level3">Mechanism of Disease</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/think-beyond/mission">
        //                         <span class="SideNavigation_Item--level3">Our Mission</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/congresses">
        //                         <span class="SideNavigation_Item--level2">Congresses</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/congresses/cony">
        //                         <span class="SideNavigation_Item--level3">CONy 21</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/congresses/wcn">
        //                         <span class="SideNavigation_Item--level3">WCN 21</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/our-commitment">
        //                         <span class="SideNavigation_Item--level2">Our Commitment</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/our-research">
        //                         <span class="SideNavigation_Item--level2">Our Research</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/medical-education/covid-19andpd">
        //                         <span class="SideNavigation_Item--level2">Telemedicine and COVID-19 Pandemic</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/disease-pages">
        //                         <span class="SideNavigation_Item--level1">Disease Pages</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/disease-pages/Introduction-to-pd">
        //                         <span class="SideNavigation_Item--level2">Introduction to PD</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/disease-pages/diagnosis-of-PD">
        //                         <span class="SideNavigation_Item--level2">Diagnosis of PD</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/disease-pages/treatment-of-pd">
        //                         <span class="SideNavigation_Item--level2">Treatment of PD</span>
        //                     </Link>
        //                     <Link class="SideNavigation_Item" rel="" href="/therapy-areas/neurology/parkinsons-disease/disease-pages/burden-of-pd">
        //                         <span class="SideNavigation_Item--level2">Burden of PD</span>
        //                     </Link>
        //                 </nav>
        //             </section>
        //             <section className="content_wrapper content_wrapper--columns-2-center">
        //                 <h1>{props.title.value}</h1>
        //                 <p>Follow the development of Parkinson's disease to explore the key manifestations of this condition at each stage, underlying pathophysiological processes, clinical challenges and available management strategies. </p>
        //                 <p>We hope that this interactive resource will provide valuable insight into the multifaceted nature of Parkinson's disease and its evolution over time to inform treatment approaches in your patients.</p>
        //                 <br /><br />
        //                 <p className="heading">Aim</p>
        //                 <ul className="sublist">
        //                     <li><span>Support healthcare professionals to develop a deeper understanding of Parkinson’s as a progressive disease that affects multiple aspects of health and motivate them to recognise the strengths and limitations of established management approaches, as well as the need for discovery of new therapies in Parkinson’s</span></li>
        //                 </ul>
        //                 <p className="heading">Objectives</p>
        //                 <ul className="sublist">
        //                     <li><span>Deep dive into Parkinson’s progression from diagnosis and through gradual physical and cognitive decline</span></li>
        //                     <li><span>Highlight different interventions used in the management of Parkinson’ depending on the disease severity</span></li>
        //                     <li><span>Explore the pathophysiological processes affecting the brain in people living with Parkinson’s over time and the lack of disease-modifying therapies that would prevent this progression</span></li>
        //                 </ul>
        //             </section>
        //             <section className="content_wrapper content_wrapper--columns-2-right"></section>
        //         </div>
        //     </div>
        // </section>
        <section role='main' className='global_layout' data-layout-type='dynamic-content-page'>
            {logo?.value && 
                <section className='brand-article-page__logo'>
                    <Logo content={logo} />
                </section>
            }
            {hero?.value && 
                <section className='brand-article-page__hero'>
                    <Hero content={hero} />
                </section>
            }
            <MainAreaTitleSection
                title={title && title.value}
                description={headerText}
                category={subtitle && subtitle.value}
                logo={headerLogo}
                backgroundImage={headerBackgroundImage}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        {hasSubnavigation && <section className='content_wrapper content_wrapper--columns-2-left'><SideNavigation element={getValue(subnavigation)} /></section>}
                        <section className={`content_wrapper${hasSubnavigation ? ' content_wrapper--columns-2-center' : ''}`}>
                            <div className='content_item'>
                                {/* TODO: This DynamicContentSection can be replaced with the newer Content.jsx module - just need to bring Content styles into the DynamicContentPage.scss file */}
                                {content?.values?.map((section, index) => (
                                    <DynamicContentSection
                                        element={section}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        className={!hasSubnavigation ? 'no-padding' : ''}
                                    />
                                ))}
                            </div>
                            <div className='content_item'>
                                {relatedVideos && (
                                    <RelatedContent bgColor='#ffffff' headingText={relatedVideos?.value?.elements?.relatedVideosHeading?.value} fluid>
                                        <Grid wrapperStyle={{ marginTop: 40 }} itemsPerRow={{ sm: 1, md: 2, lg: isFullWidth ? 1 : 3 }} gap={{ sm: 20, md: 20, lg: 20 }}>
                                            {relatedVideos?.value?.elements?.relatedVideosVideoCards?.values.map((video) => {
                                                const { id, lastModified } = video || {}

                                                const { videoCardContent, videoCardHeading, videoCardImage,externalVideoLink:videoCardExternalLink, videoCardLink, videoCardSubheading, videoCardVideo, webvttChaptersFile, subtitles, videoPoster } = video?.elements || {}

                                                return (
                                                    <VideoCard
                                                        key={id}
                                                        href={videoCardLink?.linkURL}
                                                        image={videoCardImage}
                                                        video={videoCardVideo}
                                                        heading={videoCardHeading?.value}
                                                        subHeading={videoCardSubheading?.value}
                                                        content={videoCardContent?.value?.text}
                                                        postDate={lastModified}
                                                        isFullWidth={isFullWidth}
                                                        webvttChaptersFile={webvttChaptersFile}
                                                        subtitles={subtitles}
                                                        videoPoster={videoPoster}
                                                        externalVideoLink={videoCardExternalLink}
                                                    />
                                                )
                                            })}
                                        </Grid>
                                    </RelatedContent>
                                )}
                            </div>
                        </section>
                        {hasSubnavigation && <div className='content_wrapper content_wrapper--columns-2-right'></div>}
                    </div>
                    {relatedContent && relatedContent?.value && <RelatedContent content={relatedContent} fluid />}
                    {bannerImage && <BannerImage image={bannerImage} contain={false} />}
                    <ApprovalCode code={veevaApprovalCode} />
                    {references && <References references={references} />}
                </div>
            </section>
        </section>
    );
}

export default MDTIntroSection;