import React, { useEffect,useRef } from 'react';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { useState } from 'react';
import {CloseButton} from '../../shared/CloseButton';
import { getLocationOrigin,getUrlCountryPath } from 'utils/urlUtils';
import { getQuery } from '../../../api/AxiosQueries';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import Image from '../image/Image';
import RichText from '../richText/RichText';
import { AppConfig } from 'AppConfig';
import './previewIframe.scss';
import { useScreenWidth, SCREEN_WIDTH_MOBILE } from 'components/layout/ScreenWidthContext';
import { PDFPreview } from 'components/reusableComponents/pdfPreview/PDFPreview'
import icon from '../../../assets/images/openPresentation.png';
import { usePdf } from '@mikecousins/react-pdf'
import { useSelector } from 'react-redux';

export const shouldDisplayFrame = (checkHeader) => {
    let header = checkHeader?.data?.code;
    let xframeOption
    if( checkHeader?.data?.data != undefined){
        xframeOption= checkHeader?.data?.data['x-frame-options']
    }
    if(xframeOption === "DENY" || header !== 'ok') {
        header = 'Not Ok';
    }
    return header;
}


const PreviewIframe = (iframeUrl) => {

    let sourceIframe = iframeUrl?.source;
    let queryString = iframeUrl.queryString;
    let externalContentImage = iframeUrl.image;
    // let togglePreview = iframeUrl.toggle;
    let togglePreview =  iframeUrl?.toggle?.value;
    
    const { data } = useSelector((state) => state.authentication);
    const { token } = data.token || {};

    const [isOpened, setIsOpened] = useState(false);
    const [iframeError, setIframeError] = useState(false);
    const [iframeMobile, setIframeMobile] = useState(false);
    const buttonGeneral = useWebsiteConfig();
    const [isIframe, setIsIframe] = useState(true);

    let externalButtonText = buttonGeneral?.websiteConfig.externalContentButtonText;
    let errorText = buttonGeneral?.websiteConfig?.externalContent404Error;
    let errorMobileMessage = buttonGeneral?.websiteConfig.externalContentMobileMessage;
    const { currentWidthRanges } = useScreenWidth();
    const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);

    const checkPdf = (iframeUrl) =>{
        const str = iframeUrl.source.toString();
        return (
            str.split('.').pop() == 'pdf'
        )
           
    }

    const onDocumentLoadFail = () =>{
        let checkPdfs = checkPdf(iframeUrl);
        checkPdfs && setIframeError(true);
    }

    const canvasRef = useRef(null)
    usePdf({  canvasRef, file: sourceIframe, onDocumentLoadFail})
    
    const checkFrameUrl = async () => {
       
        let checkPdfs = checkPdf(iframeUrl);
      
        if(!isMobile){
            if(checkPdfs===false){
                const checkHeader = await getQuery(`${AppConfig?.lamda?.checkSiteHead}&token=${token}&url=${sourceIframe}`);
                // console.log(checkHeader?.data?.code) 
                let headerchecking = shouldDisplayFrame(checkHeader);
                setIframeError(headerchecking !== 'ok');
                setIsIframe(true)
            }
            else{
                setIsIframe(false);
            }
        }
        else{
            setIframeError(true);
            setIframeMobile(true);
        }
    }

    const toggle = () => {
        setIsOpened( !isOpened );
        document.body.style['overflow-y'] = 'hidden';
    }

    const close= () =>{
        setIsOpened(false);
        document.body.style['overflow-y'] = 'visible';
    }
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                close()
           }
         };
         window.addEventListener('keydown', handleEsc);
        checkFrameUrl();
    }, [sourceIframe]);
    let finalSrc = sourceIframe;

    if(sourceIframe.includes('?'))
    {     
        finalSrc=`${sourceIframe}&token=${queryString}&referalUrl=${getLocationOrigin()}/${getUrlCountryPath()}`;
    }
    else
    {        
        finalSrc=`${sourceIframe}?token=${queryString}&referalUrl=${getLocationOrigin()}/${getUrlCountryPath()}`;
    }

    return (
        <div className = "edetailer">
            { togglePreview ? 
            <div className = "iframe-preview">
                {
                    iframeError ? <div className = "iframe-preview--error">
                        {iframeMobile ?<div><h1></h1> <RichText data= {errorMobileMessage} fromWebconfig={true}/></div>  : 
                        <div><h1>404</h1>
                        <RichText data={errorText} fromWebconfig={true} /></div>}
                    </div> : <div className = "iframe-preview--success">
                        <section className="iframe-image-sucess">
                            {/* {externalContentImage.url== undefined? null: 
                                <Image
                                data={externalContentImage}
                                />
                            } */}
                            {isIframe ?
                                 <iframe className="VeevaIframe" id="iframeEdetailer" src={finalSrc} width="100%" height="100%" title="Veeva Player" scrolling="yes" x-frame option="allow"/> :
                                 <div id="pdf" className="pdfPreview">
                                    <PDFPreview pdfUrl={ iframeUrl.source} />
                                 </div>
                            }
                        </section>
                    </div>
                }
            </div> : null}
            <div className="button-placement">
            <button 
                type='button' 
                onClick={ toggle } 
                data-vv-action='click'
                data-vv-snapshot='after'
                className="iframe-button" 
                id="iframeButton" 
                disabled={iframeError}
            > 
                {externalButtonText}
                <img className="imagebutton" alt="open presentation" src={icon}/>
            </button>
            </div>
            { isOpened && (
                <div className="overlay showHideClassName">
                <div className="overlaySpace"  onClick={()=>{ close();}}></div>
                <section role="main" className="veeva-page-component edetailer-modal" id="edetailerModal">
                    <div className="taskbar">
                    <div className="button-frame">
                        <CloseButton
                            onClick={()=>{ close();
                            }} 
                        />
                    </div>
                    </div>
                    <section  className="veeva-page-component section">
                        <div className="veeva-container" >
                            <div className="veeva-aspect-ratio">
                            <div className="veeva-player" id="engage">
                                {isIframe ?
                                    <iframe className="VeevaIframe" id="iframeEdetailer" src={finalSrc} width="100%" height="100%" title="Veeva Player" scrolling="yes" x-frame option="allow"/> :
                                    <div id="pdf" className="pdfShow">
                                    <PDFPreview pdfUrl={ iframeUrl.source} />
                                </div>
                                }
                            </div>
                            </div>
                        </div>
                    </section>
                </section>
                </div>
            )} 
        </div>
    );
};

PreviewIframe.propTypes = {
    content: contentItemProptype,
  };
 export { PreviewIframe }; 