import RichText from "components/reusableComponents/richText/RichText";
import React from "react";

import './CircleCallOut.scss'

const CircleCallOut = (props) => {

    const {
        fontColor,
        backgroundHexColor,
        ctaLabel,
        description,
        targetAccordion,
        showButton
    } = props?.circleCalloutContent?.elements;
    const showTabHandler = (ev, tabID) => {
        let event = ev.target || ev.srcElement,
            elementSel = document.getElementById(targetAccordion?.value);
        
        if(!elementSel.classList.contains('open')){
            elementSel.click();
            elementSel.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
    }

    const ctaStyle = {
        borderColor: fontColor?.value,
        color: fontColor?.value
    }

    return (
        <div className="circle-call-out__itemWrapper">
            <div className="circle-call-out__itemContent" style={{backgroundColor: backgroundHexColor?.value}}>
                <div className="circle-call-out__content" style={{color: fontColor?.value}}>
                    <RichText data={description} />
                </div>
                {showButton?.value && <div className="circle-call-out__btn"  style={ctaStyle} onClick={(event) => {showTabHandler(targetAccordion?.value)}}>
                    <RichText data={ctaLabel} />
                </div>}
            </div>
        </div>
    );
}

export default CircleCallOut;