// Page template types defined in acoustic
const CONTENT_PROMO = 'Layout H - Content promo';
const MAIN_THERAPY_PAGE = 'Layout C - Main therapy page';
const ARTICLE_PAGE = 'Layout E - Article';
const CONTENT_LIST_PAGE = 'Layout D - Content list page';
const LANDING_PAGE = 'Layout A - Landing page';
const MAIN_PROMO_PAGE = 'Layout G - Main Promo Page';
const GENERIC_PAGE = 'Layout K - Generic page';
const MAIN_PROMO_PAGE_ALTERNATE = 'Layout J - Main Promo Page Alternate';
const CONTENT_PAGE = 'Layout - Content page';
const PRE_REGISTRATION_PAGE = 'Layout - Pre-registration page';
const VEEVA_PLAYER_PAGE = 'Layout - Veeva player page';
const DYNAMIC_ARTICLE_PAGE = 'Layout E - Dynamic article';
const DYNAMIC_CONTENT_PAGE = 'Layout F - Dynamic content page';
const BRAND_LANDING_PAGE = 'Layout G - Brand Landing Page';
const BRAND_ARTICLE_PAGE = 'Layout H - Brand Article Page';
const CARD_LIST_PAGE = 'Layout I - Card List Page';
const THERAPY_AREA_LANDING_PAGE = 'Layout C - Therapy Area Landing Page';
const EDETAILER_PAGER4= 'Layout H – eDetailer';
const MOODLE_DETAIL_PAGE = 'Layout F - Course Detail Page';
const MOODLE_LIST_PAGE = 'Layout D – Course List Page';
const THIRD_PARTY_PAGE = 'Layout E – Third Party';
const EVENT_LANDING_PAGE='Layout – Event Page';
const EVENT_THANK_YOU_PAGE = 'Layout – Event Thank You Page';
const WEBINAR_DETAIL_PAGE = 'Layout F - Webinar Page';
const WEBINAR_LIST_PAGE ='Layout D - Webinar List Page';
const INFOGRAPHICS = 'Layout H - Infographics Page';
const GLOBAL_LANDING_PAGE = 'Layout Global Page';

const FLEXI_TEMPLATE = 'Layout - Flexi Template Page';

// Acoustic content types for page elements
const CONTENT_LIST_PAGE_CARD_TYPE = 'Therapy card';
const CALCULATOR_TEMPLATE = 'Kanso-Template-Quiz';

export {
  CONTENT_PROMO,
  MAIN_THERAPY_PAGE,
  ARTICLE_PAGE,
  CONTENT_LIST_PAGE,
  LANDING_PAGE,
  MAIN_PROMO_PAGE,
  GENERIC_PAGE,
  MAIN_PROMO_PAGE_ALTERNATE,
  CONTENT_PAGE,
  CONTENT_LIST_PAGE_CARD_TYPE,
  PRE_REGISTRATION_PAGE,
  VEEVA_PLAYER_PAGE,
  DYNAMIC_ARTICLE_PAGE,
  DYNAMIC_CONTENT_PAGE,
  BRAND_LANDING_PAGE,
  BRAND_ARTICLE_PAGE,
  CARD_LIST_PAGE,
  THERAPY_AREA_LANDING_PAGE,
  EDETAILER_PAGER4,
  MOODLE_DETAIL_PAGE,
  MOODLE_LIST_PAGE,
  THIRD_PARTY_PAGE,
  WEBINAR_DETAIL_PAGE,
  WEBINAR_LIST_PAGE,
  EVENT_LANDING_PAGE,
  EVENT_THANK_YOU_PAGE,
  INFOGRAPHICS,
  GLOBAL_LANDING_PAGE,
  FLEXI_TEMPLATE,
  CALCULATOR_TEMPLATE
};
