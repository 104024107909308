import { getAPIContent } from "api/acousticApi";
import { isError } from "lodash";
import { useEffect } from "react";
import { useQuery } from 'react-query';
import { useDispatch } from "react-redux";
import { setTaxonomyRegistration } from "redux/authentication/slice";
import { getUrlCountryData } from "utils/libraryUtils";
import { getUrlCountryPath } from "utils/urlUtils";

const TAXONOMY_REGISTRATION_QUERY_KEY = 'taxonomy-registration';

const useTaxonomyRegistration = ({ requiresLogin, path, taxonomy }) => {
    const dispatch = useDispatch();
    const { data, isLoading, isError } = useQuery(
        [TAXONOMY_REGISTRATION_QUERY_KEY],
        () => getAPIContent(getUrlCountryData()?.taxonomyReg),
        { staleTime: Infinity }, // only request the taxonomy reg once
    );

    const parseToJson = (values) =>  values?.split(/\r?\n/).map(value => {
        const valueSplit = value.replace(/\s+/g, '').split('-');
        const taxonomyField = {
            parent: valueSplit[0],
            fields: []
        }
    
        if(valueSplit?.length > 1) {
            taxonomyField.fields = valueSplit[1].substring(1, valueSplit[1].length-1).split(',');
        }
    
        return taxonomyField;
    });
    
    
    const extractTaxonomyValues = (taxonomyFields, taxonomy) => {
        const taxonomyValues = {};
    
        taxonomyFields?.forEach(({ parent, fields }) => {
            fields?.forEach(field => {
                if(!taxonomyValues[parent]) taxonomyValues[parent] = {}
                let fieldValue = taxonomy && taxonomy[parent]?.value && taxonomy[parent]?.value[field]?.categories ? taxonomy[parent]?.value[field] : ''
                
                if(fieldValue?.categories?.length > 0) { 
                    fieldValue = fieldValue?.categories[0] 
                    fieldValue = fieldValue?.substring(fieldValue.lastIndexOf('/')+1)
                }
    
                taxonomyValues[parent][field] = fieldValue
            })
        })
    
        return taxonomyValues
    }

    const utmValues = localStorage.getItem("incommingquerystring");

    useEffect(() => {
        if(requiresLogin && !isLoading && !isError) {
            const taxonomyFields = parseToJson(data?.elements?.taxonomyApiKeys?.value);
            const taxonomyValues = extractTaxonomyValues(taxonomyFields, taxonomy);
            taxonomyValues['path'] = `/${getUrlCountryPath()}${path}`;
            taxonomyValues['utmValues'] = utmValues;
            dispatch(setTaxonomyRegistration(taxonomyValues))
        }
    }, [path, isError, isLoading])

    return { isLoading };
}

export default useTaxonomyRegistration;