const Download = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden="true" 
      focusable="false"
    >
      <path d='M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z' />
    </svg>
  )
}

const ExternalLink = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden="true" 
      focusable="false"
    >
      <path d='M14.2222 14.7222H1.77778V2.27778H8V0.5H1.77778C0.791111 0.5 0 1.3 0 2.27778V14.7222C0 15.7 0.791111 16.5 1.77778 16.5H14.2222C15.2 16.5 16 15.7 16 14.7222V8.5H14.2222V14.7222ZM9.77778 0.5V2.27778H12.9689L4.23111 11.0156L5.48444 12.2689L14.2222 3.53111V6.72222H16V0.5H9.77778Z' />
    </svg>
  )
}

const Globe = () => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="#4B4C53" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 9.39 2.08 8.79 2.21 8.22L6.99 13V14C6.99 15.1 7.89 16 8.99 16V17.93C5.06 17.43 2 14.07 2 10ZM15.89 15.4C15.63 14.59 14.89 14 13.99 14H12.99V11C12.99 10.45 12.54 10 11.99 10H5.99V8H7.99C8.54 8 8.99 7.55 8.99 7V5H10.99C12.09 5 12.99 4.1 12.99 3V2.59C15.92 3.77 18 6.65 18 10C18 12.08 17.19 13.98 15.89 15.4Z" />
    </svg>
  )
}

const ScrollToTop = (props) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <svg
      width='48px'
      height='48px'
      viewBox='0 0 48 48'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => handleClick()}
      fill='none'
      aria-hidden="true" 
      focusable="false"
    >
      <circle
        id="scrollToTopCircle"
        fill='none'
        cx='24'
        cy='24'
        r='24'
      ></circle>
      <polygon
        id="scrollToTopArrow"
        fill='none'
        points='16 24 17.41 25.41 23 19.83 23 32 25 32 25 19.83 30.58 25.42 32 24 24 16'
      ></polygon>
    </svg>
  )
}

const BurgerMenu = (props) => {
  return (
    <svg 
      width="18" 
      height="12" 
      viewBox="0 0 18 12" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
        <path d="M1 12H17C17.55 12 18 11.55 18 11C18 10.45 17.55 10 17 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1Z" fill="#4B4C53"/>
    </svg>
  )
}

const ChevronUp = () => {
  return (
    <svg 
      width="14" 
      height="8" 
      viewBox="0 0 14 8" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M1.77737 8L6.73284 3.05533L11.6883 8L13.2106 6.47773L6.73284 0L0.255106 6.47773L1.77737 8Z" fill="white"/>
    </svg>
  )
}

const ChevronUpMob = () => {
  return (
    <svg 
      width="14" 
      height="8" 
      viewBox="0 0 14 8" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false">
      <path d="M1.68944 8L6.64491 3.05533L11.6004 8L13.1226 6.47773L6.64491 0L0.167177 6.47773L1.68944 8Z" fill="#4B4C53"/>
    </svg>
  )
}

const ChevronDown = () => {
  return (
    <svg 
      width="14" 
      height="8" 
      viewBox="0 0 14 8" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M12.166 0L7.21052 4.94467L2.25506 0L0.732788 1.52227L7.21052 8L13.6883 1.52227L12.166 0Z" fill="#4B4C53"/>
    </svg>
  )
}

const ChevronLeft = () => {
  return (
    <svg 
      width="8" 
      height="13" 
      viewBox="0 0 8 13" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d='M8 11.4555L3.05533 6.5L8 1.54453L6.47773 0.0222625L-2.83151e-07 6.49999L6.47773 12.9777L8 11.4555Z' fill="#4B4C53"/>
    </svg>
  )
}

const ChevronRight = () => {
  return (
    <svg 
      width="8" 
      height="13" 
      viewBox="0 0 8 13" 
      fill="white" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M1.3634e-07 2.04458L4.94467 7.00005L1.81529e-08 10.9555L1.52227 12.4778L8 7.00005L1.52227 0.522318L1.3634e-07 2.04458Z"/>
    </svg>
  )
}

const AudioPause = () => {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 27.999H8V-0.000976562H0V27.999ZM16 -0.000976562V27.999H24V-0.000976562H16Z"/>
    </svg>
  )
}

const AudioPlay = () => {
  return (
    <svg 
      width="22" 
      height="28" 
      viewBox="0 0 22 28" 
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M4 7.28L14.54 14L4 20.72V7.28ZM0 0V28L22 14L0 0Z"/>
    </svg>
  )
}

const AudioPlaying = () => {
  return (
    <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10.1981C0 8.20984 1.54878 6.59807 3.4593 6.59807C5.36982 6.59807 6.9186 8.20984 6.9186 10.1981V15.7981C6.9186 17.7863 5.36982 19.3981 3.4593 19.3981C1.54878 19.3981 0 17.7863 0 15.7981V10.1981Z" fill="white"/>
      <path d="M11.0271 4.59805C11.0271 2.60982 12.5759 0.998047 14.4864 0.998047C16.397 0.998047 17.9457 2.60982 17.9457 4.59805V21.398C17.9457 23.3863 16.397 24.998 14.4864 24.998C12.5759 24.998 11.0271 23.3863 11.0271 21.398V4.59805Z" fill="white"/>
      <path d="M22.0543 12.998C22.0543 11.6726 23.0868 10.598 24.3605 10.598H26.6667C27.9403 10.598 28.9729 11.6726 28.9729 12.998C28.9729 14.3235 27.9403 15.398 26.6667 15.398H24.3605C23.0868 15.398 22.0543 14.3235 22.0543 12.998Z" fill="white"/>
      <path d="M33.0814 11.798C33.0814 9.80982 34.6302 8.19805 36.5407 8.19805C38.4512 8.19805 40 9.80982 40 11.798V14.198C40 16.1863 38.4512 17.798 36.5407 17.798C34.6302 17.798 33.0814 16.1863 33.0814 14.198V11.798Z" fill="white"/>
    </svg>
  )
}

const AudioVolume = () => {
  return (
    <svg fill="#333333" width="36" height="36" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
      <path
        className="clr-i-solid clr-i-solid-path-1"
        d="M23.41,25.25a1,1,0,0,1-.54-1.85,6.21,6.21,0,0,0-.19-10.65,1,1,0,1,1,1-1.73,8.21,8.21,0,0,1,.24,14.06A1,1,0,0,1,23.41,25.25Z"
      ></path>
      <path
        className="clr-i-solid clr-i-solid-path-2"
        d="M25.62,31.18a1,1,0,0,1-.45-1.89A12.44,12.44,0,0,0,25,6.89a1,1,0,1,1,.87-1.8,14.44,14.44,0,0,1,.24,26A1,1,0,0,1,25.62,31.18Z"
      ></path>
      <path
        className="clr-i-solid clr-i-solid-path-3"
        d="M18.33,4,9.07,12h-6a1,1,0,0,0-1,1v9.92a1,1,0,0,0,1,1H8.88l9.46,8.24A1,1,0,0,0,20,31.43V4.72A1,1,0,0,0,18.33,4Z"
      ></path>
      <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
    </svg>
  )
}

const AudioMute = () => {
  return (
    <svg fill="#333333" width="36" height="36" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
      <path className="clr-i-solid clr-i-solid-path-1" d="M24.87,6.69A12.42,12.42,0,0,1,28.75,26.3l1.42,1.42A14.43,14.43,0,0,0,25.74,4.88a1,1,0,0,0-.87,1.8Z"></path>
      <path
        className="clr-i-solid clr-i-solid-path-2"
        d="M27.3,27.67h0l-3.84-3.84-.57-.57h0L4.63,5,3.21,6.41,8.8,12H3a1,1,0,0,0-1,1V23a1,1,0,0,0,1,1H8.83l9.51,8.3A1,1,0,0,0,20,31.55V23.2l5.59,5.59c-.17.1-.34.2-.51.29a1,1,0,0,0,.9,1.79c.37-.19.72-.4,1.08-.62l2.14,2.14L30.61,31l-3.25-3.25Z"
      ></path>
      <path
        className="clr-i-solid clr-i-solid-path-3"
        d="M22.69,12.62A6.27,6.27,0,0,1,25.8,18a6.17,6.17,0,0,1-1.42,3.92l1.42,1.42a8.16,8.16,0,0,0,2-5.34,8.28,8.28,0,0,0-4.1-7.11,1,1,0,1,0-1,1.73Z"
      ></path>
      <path className="clr-i-solid clr-i-solid-path-4" d="M20,4.62a1,1,0,0,0-1.66-.75l-6.42,5.6L20,17.54Z"></path>
      <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
    </svg>
  )
}

const VideoPlay = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" strokeWidth="3" stroke="white" fill="none">
      <path d="M24.35,42.59V21.41A.49.49,0,0,1,25.1,21L41.77,31.59a.49.49,0,0,1,0,.82L25.1,43A.49.49,0,0,1,24.35,42.59Z" />
      <circle cx="32" cy="32" r="25.48" />
    </svg>
  )
}

const Advice = () => {
  return (
    <svg 
      width="40" 
      height="40" 
      viewBox="0 0 40 40" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <circle cx="20" cy="20" r="20" fill="#EA5724"/>
      <path d="M18.5 26.2949H20.5V24.2949H18.5V26.2949ZM19.5 10.2949C13.98 10.2949 9.5 14.7749 9.5 20.2949C9.5 25.8149 13.98 30.2949 19.5 30.2949C25.02 30.2949 29.5 25.8149 29.5 20.2949C29.5 14.7749 25.02 10.2949 19.5 10.2949ZM19.5 28.2949C15.09 28.2949 11.5 24.7049 11.5 20.2949C11.5 15.8849 15.09 12.2949 19.5 12.2949C23.91 12.2949 27.5 15.8849 27.5 20.2949C27.5 24.7049 23.91 28.2949 19.5 28.2949ZM19.5 14.2949C17.29 14.2949 15.5 16.0849 15.5 18.2949H17.5C17.5 17.1949 18.4 16.2949 19.5 16.2949C20.6 16.2949 21.5 17.1949 21.5 18.2949C21.5 20.2949 18.5 20.0449 18.5 23.2949H20.5C20.5 21.0449 23.5 20.7949 23.5 18.2949C23.5 16.0849 21.71 14.2949 19.5 14.2949Z" fill="white"/>
    </svg>
  )
}

const Terminology = () => {
  return (
    <svg 
      width="40" 
      height="40" 
      viewBox="0 0 40 40" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <circle cx="20" cy="20" r="20" fill="#EA5724"/>
      <path d="M28.5 12.295C27.39 11.945 26.17 11.795 25 11.795C23.05 11.795 20.95 12.195 19.5 13.295C18.05 12.195 15.95 11.795 14 11.795C12.05 11.795 9.95 12.195 8.5 13.295V27.945C8.5 28.195 8.75 28.445 9 28.445C9.1 28.445 9.15 28.395 9.25 28.395C10.6 27.745 12.55 27.295 14 27.295C15.95 27.295 18.05 27.695 19.5 28.795C20.85 27.945 23.3 27.295 25 27.295C26.65 27.295 28.35 27.595 29.75 28.345C29.85 28.395 29.9 28.395 30 28.395C30.25 28.395 30.5 28.145 30.5 27.895V13.295C29.9 12.845 29.25 12.545 28.5 12.295ZM28.5 25.795C27.4 25.445 26.2 25.295 25 25.295C23.3 25.295 20.85 25.945 19.5 26.795V15.295C20.85 14.445 23.3 13.795 25 13.795C26.2 13.795 27.4 13.945 28.5 14.295V25.795Z" fill="white"/>
      <path d="M25 17.795C25.88 17.795 26.73 17.885 27.5 18.055V16.535C26.71 16.385 25.86 16.295 25 16.295C23.3 16.295 21.76 16.585 20.5 17.125V18.785C21.63 18.145 23.2 17.795 25 17.795Z" fill="white"/>
      <path d="M20.5 19.785V21.445C21.63 20.805 23.2 20.455 25 20.455C25.88 20.455 26.73 20.545 27.5 20.715V19.195C26.71 19.045 25.86 18.955 25 18.955C23.3 18.955 21.76 19.255 20.5 19.785Z" fill="white"/>
      <path d="M25 21.625C23.3 21.625 21.76 21.915 20.5 22.455V24.115C21.63 23.475 23.2 23.125 25 23.125C25.88 23.125 26.73 23.215 27.5 23.385V21.865C26.71 21.705 25.86 21.625 25 21.625Z" fill="white"/>
    </svg>
  )
}

const Bulb = () => {
  return (
    <svg 
      width="40" 
      height="40" 
      viewBox="0 0 40 40" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <circle cx="20" cy="20" r="20" fill="#EA5724"/>
      <path d="M16.5 29.295C16.5 29.845 16.95 30.295 17.5 30.295H21.5C22.05 30.295 22.5 29.845 22.5 29.295V28.295H16.5V29.295ZM19.5 10.295C15.64 10.295 12.5 13.435 12.5 17.295C12.5 19.675 13.69 21.765 15.5 23.035V25.295C15.5 25.845 15.95 26.295 16.5 26.295H22.5C23.05 26.295 23.5 25.845 23.5 25.295V23.035C25.31 21.765 26.5 19.675 26.5 17.295C26.5 13.435 23.36 10.295 19.5 10.295ZM22.35 21.395L21.5 21.995V24.295H17.5V21.995L16.65 21.395C15.3 20.455 14.5 18.925 14.5 17.295C14.5 14.535 16.74 12.295 19.5 12.295C22.26 12.295 24.5 14.535 24.5 17.295C24.5 18.925 23.7 20.455 22.35 21.395Z" fill="white"/>
    </svg>
  )
}

const ModalClose = () => {
  return (
    <svg 
      width="14" 
      height="14" 
      viewBox="0 0 14 14" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z" />
    </svg>
  )
}

const Error = (props) => {
  return (
    <svg 
      {...props} 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
      >
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="#B00020"/>
    </svg>
  )
}

const Password = (props) => {
  return (
    <svg 
      {...props} 
      width="16" 
      height="10" 
      viewBox="0 0 16 10" 
      fill="#6267A1" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M8.00008 1.33333C10.5267 1.33333 12.7801 2.75333 13.8801 5C12.7801 7.24667 10.5267 8.66667 8.00008 8.66667C5.47341 8.66667 3.22008 7.24667 2.12008 5C3.22008 2.75333 5.47341 1.33333 8.00008 1.33333ZM8.00008 0C4.66675 0 1.82008 2.07333 0.666748 5C1.82008 7.92667 4.66675 10 8.00008 10C11.3334 10 14.1801 7.92667 15.3334 5C14.1801 2.07333 11.3334 0 8.00008 0ZM8.00008 3.33333C8.92008 3.33333 9.66675 4.08 9.66675 5C9.66675 5.92 8.92008 6.66667 8.00008 6.66667C7.08008 6.66667 6.33341 5.92 6.33341 5C6.33341 4.08 7.08008 3.33333 8.00008 3.33333ZM8.00008 2C6.34675 2 5.00008 3.34667 5.00008 5C5.00008 6.65333 6.34675 8 8.00008 8C9.65341 8 11.0001 6.65333 11.0001 5C11.0001 3.34667 9.65341 2 8.00008 2Z" />
    </svg>
  )
}

const PasswordList = () => {
  return (
    <svg 
      width="14" 
      height="14" 
      viewBox="0 0 14 14" 
      fill="#4B4C53" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M3.52172 6.30433V7.69563H10.4782V6.30433H3.52172ZM6.99998 0.043457C3.15998 0.043457 0.043457 3.15998 0.043457 6.99998C0.043457 10.84 3.15998 13.9565 6.99998 13.9565C10.84 13.9565 13.9565 10.84 13.9565 6.99998C13.9565 3.15998 10.84 0.043457 6.99998 0.043457ZM6.99998 12.5652C3.93215 12.5652 1.43476 10.0678 1.43476 6.99998C1.43476 3.93215 3.93215 1.43476 6.99998 1.43476C10.0678 1.43476 12.5652 3.93215 12.5652 6.99998C12.5652 10.0678 10.0678 12.5652 6.99998 12.5652Z" />
    </svg>
  )
}

const CheckMark = () => {
  return (
    <svg 
      width="14" 
      height="14" 
      viewBox="0 0 14 14" 
      fill="#2BA24C" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M6.99998 0.043457C3.15998 0.043457 0.043457 3.15998 0.043457 6.99998C0.043457 10.84 3.15998 13.9565 6.99998 13.9565C10.84 13.9565 13.9565 10.84 13.9565 6.99998C13.9565 3.15998 10.84 0.043457 6.99998 0.043457ZM6.99998 12.5652C3.93215 12.5652 1.43476 10.0678 1.43476 6.99998C1.43476 3.93215 3.93215 1.43476 6.99998 1.43476C10.0678 1.43476 12.5652 3.93215 12.5652 6.99998C12.5652 10.0678 10.0678 12.5652 6.99998 12.5652ZM10.193 3.9252L5.60867 8.50954L3.80694 6.71476L2.82607 7.69563L5.60867 10.4782L11.1739 4.91302L10.193 3.9252Z" />
    </svg>
  )
}

const Close = () => {
  return(
    <svg 
      width="14" 
      height="15" 
      viewBox="0 0 14 15" 
      fill="#6267A1" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M13.3 1.21022C12.91 0.820215 12.28 0.820215 11.89 1.21022L6.99997 6.09022L2.10997 1.20021C1.71997 0.810215 1.08997 0.810215 0.699971 1.20021C0.309971 1.59021 0.309971 2.22022 0.699971 2.61022L5.58997 7.50022L0.699971 12.3902C0.309971 12.7802 0.309971 13.4102 0.699971 13.8002C1.08997 14.1902 1.71997 14.1902 2.10997 13.8002L6.99997 8.91021L11.89 13.8002C12.28 14.1902 12.91 14.1902 13.3 13.8002C13.69 13.4102 13.69 12.7802 13.3 12.3902L8.40997 7.50022L13.3 2.61022C13.68 2.23022 13.68 1.59022 13.3 1.21022Z" />
    </svg>
  )
}

const QuoteMarks = () => {
  return(
    <svg 
      width="80" 
      height="48" 
      viewBox="0 0 80 48" 
      fill="#C3C4CA" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M80 0C78.2264 7.70612 76.4529 15.9347 74.6793 24.6857C73.0325 33.4367 71.7023 41.2082 70.6888 48H44.6556L43.3254 45.8449C45.099 38.9224 47.4426 31.3469 50.3563 23.1184C53.3967 14.8898 56.5004 7.18367 59.6675 0H80ZM36.6746 0C34.901 7.70612 33.1275 15.9347 31.3539 24.6857C29.707 33.4367 28.3769 41.2082 27.3634 48H1.33017L0 45.8449C1.77355 38.9224 4.11718 31.3469 7.03088 23.1184C10.0713 14.8898 13.175 7.18367 16.342 0H36.6746Z"/>
    </svg>
  )
}

const CarouselIndicatorInactive = () => {
  return(
    <svg 
      width="8" 
      height="8" 
      viewBox="0 0 8 8" 
      fill="#C3C4CA" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="8" height="8" rx="4" />
    </svg>
  )
}

const CarouselIndicatorActive = () => {
  return(
    <svg 
      width="16" 
      height="8" 
      viewBox="0 0 16 8" 
      fill="#6267A1" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="8" rx="4" />
    </svg>
  )
}

const FormSliderRange = () => {
  return(
    <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.9964" cy="2" r="2" fill="#F5F5F6"/>
    </svg>
  )
}

const Calendar = (props) => {
  return(
    <svg {...props} width="18" height="20" viewBox="0 0 18 20" fill="#6267A1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
      <path d="M16 2H15V0L13 0V2H5V0L3 0V2H2C0.89 2 0 2.9 0 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM16 6H2V4H16V6ZM4 10H9V15H4V10Z" />
    </svg>
  )
}

const Share = () => {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16"
    aria-hidden="true" 
    focusable="false"
    > 
      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/> 
    </svg>
  )
}

const ShareFacebook = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 14.84 3.44 18.87 8 19.8V13H6V10H8V7.5C8 5.57 9.57 4 11.5 4H14V7H12C11.45 7 11 7.45 11 8V10H14V13H11V19.95C16.05 19.45 20 15.19 20 10Z" fill="#4267B2"/>
    </svg>
  )
}

const ShareTwitter = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.28982 16.2544C13.8373 16.2544 17.9648 10.0015 17.9648 4.57946C17.9648 4.40181 17.9612 4.22506 17.953 4.049C18.7541 3.46985 19.4507 2.74695 20 1.92441C19.2648 2.25106 18.4737 2.47103 17.6436 2.5702C18.4907 2.06226 19.1413 1.25837 19.4482 0.300263C18.6552 0.770447 17.7771 1.11211 16.8427 1.29636C16.0936 0.498846 15.0272 0 13.8473 0C11.581 0 9.7433 1.83774 9.7433 4.10291C9.7433 4.42501 9.77947 4.73801 9.84953 5.0385C6.4395 4.86721 3.41549 3.23419 1.3919 0.751567C1.03909 1.35778 0.835959 2.06249 0.835959 2.81383C0.835959 4.23734 1.56046 5.49413 2.6621 6.22954C1.98901 6.20884 1.35664 6.02368 0.803658 5.71614C0.802748 5.7332 0.802748 5.75049 0.802748 5.76846C0.802748 7.75588 2.21739 9.41506 4.09472 9.79107C3.7501 9.88501 3.38751 9.93551 3.01309 9.93551C2.74877 9.93551 2.49173 9.90913 2.24173 9.86158C2.76401 11.4916 4.27897 12.6781 6.07509 12.7116C4.67045 13.8123 2.90117 14.4681 0.978811 14.4681C0.64784 14.4681 0.32119 14.4492 0 14.4112C1.81591 15.575 3.97211 16.2544 6.28982 16.2544Z" fill="#00ACEE"/>
    </svg>
  )
}

const ShareEmail = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 16C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H18.5C18.9 0 19.25 0.15 19.55 0.45C19.85 0.75 20 1.1 20 1.5V14.5C20 14.9 19.85 15.25 19.55 15.55C19.25 15.85 18.9 16 18.5 16H1.5ZM10 8.45L1.5 2.875V14.5H18.5V2.875L10 8.45ZM10 6.95L18.4 1.5H1.625L10 6.95ZM1.5 2.875V1.5V14.5V2.875Z" fill="#323232"/>
    </svg>
  )
}

const ShareLink = () => {
  return (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z" fill="#323232"/>
    </svg>
  )
}

const SharePrint = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.45 4.8V1.5H5.55V4.8H4.05V0H15.95V4.8H14.45ZM1.5 6.3H18.5H4.05H1.5ZM16.475 8.675C16.675 8.675 16.85 8.6 17 8.45C17.15 8.3 17.225 8.125 17.225 7.925C17.225 7.725 17.15 7.55 17 7.4C16.85 7.25 16.675 7.175 16.475 7.175C16.275 7.175 16.1 7.25 15.95 7.4C15.8 7.55 15.725 7.725 15.725 7.925C15.725 8.125 15.8 8.3 15.95 8.45C16.1 8.6 16.275 8.675 16.475 8.675ZM14.45 16.5V11.7H5.55V16.5H14.45ZM15.95 18H4.05V13.6H0V7.45C0 6.7 0.254167 6.07083 0.7625 5.5625C1.27083 5.05417 1.9 4.8 2.65 4.8H17.35C18.1 4.8 18.7292 5.05417 19.2375 5.5625C19.7458 6.07083 20 6.7 20 7.45V13.6H15.95V18ZM18.5 12.1V7.45C18.5 7.11667 18.3917 6.84167 18.175 6.625C17.9583 6.40833 17.6833 6.3 17.35 6.3H2.65C2.31667 6.3 2.04167 6.40833 1.825 6.625C1.60833 6.84167 1.5 7.11667 1.5 7.45V12.1H4.05V10.2H15.95V12.1H18.5Z" fill="#323232"/>
    </svg>
  )
}

const Play = () => {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16"
    aria-hidden="true" 
    focusable="false"
    > 
      <path d="M6.4,11.6L11.2,8L6.4,4.4V11.6z M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14.4c-3.5,0-6.4-2.9-6.4-6.4
    S4.5,1.6,8,1.6s6.4,2.9,6.4,6.4S11.5,14.4,8,14.4z"/>
    </svg>
  )
}

const InputError = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="#B00020"/>
    </svg>
  )
}

const CircleIndicator = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
      <circle cx="14.5" cy="14" r="13" fill="white" stroke="#EA5504" stroke-width="2"/>
    </svg>
  )
}

const CheckCircle = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z" fill="#EA5504"/>
      <path d="M21.53 12.47C21.6705 12.6106 21.7493 12.8012 21.7493 13C21.7493 13.1988 21.6705 13.3894 21.53 13.53L14.53 20.53C14.3894 20.6705 14.1988 20.7493 14 20.7493C13.8013 20.7493 13.6106 20.6705 13.47 20.53L10.47 17.53C10.3375 17.3878 10.2654 17.1998 10.2688 17.0055C10.2723 16.8112 10.351 16.6258 10.4884 16.4884C10.6258 16.351 10.8112 16.2723 11.0055 16.2688C11.1998 16.2654 11.3878 16.3375 11.53 16.47L14 18.9387L20.47 12.47C20.6106 12.3295 20.8013 12.2507 21 12.2507C21.1988 12.2507 21.3894 12.3295 21.53 12.47ZM28.75 16C28.75 18.5217 28.0022 20.9868 26.6012 23.0835C25.2003 25.1802 23.209 26.8144 20.8792 27.7795C18.5495 28.7445 15.9859 28.997 13.5126 28.505C11.0393 28.0131 8.76751 26.7987 6.98439 25.0156C5.20127 23.2325 3.98696 20.9607 3.49499 18.4874C3.00303 16.0141 3.25552 13.4505 4.22054 11.1208C5.18556 8.79103 6.81976 6.79975 8.91648 5.39876C11.0132 3.99777 13.4783 3.25 16 3.25C19.3803 3.25397 22.621 4.59854 25.0112 6.98877C27.4015 9.379 28.746 12.6197 28.75 16ZM27.25 16C27.25 13.775 26.5902 11.5999 25.354 9.74984C24.1179 7.89978 22.3609 6.45784 20.3052 5.60636C18.2495 4.75487 15.9875 4.53208 13.8052 4.96617C11.623 5.40025 9.6184 6.47171 8.04505 8.04505C6.47171 9.61839 5.40025 11.6229 4.96617 13.8052C4.53209 15.9875 4.75488 18.2495 5.60636 20.3052C6.45785 22.3609 7.89979 24.1179 9.74984 25.354C11.5999 26.5902 13.775 27.25 16 27.25C18.9827 27.2467 21.8422 26.0604 23.9513 23.9513C26.0604 21.8422 27.2467 18.9827 27.25 16Z" fill="white"/>
    </svg>
  )
} 

export {
  AudioPause,
  AudioPlay,
  AudioPlaying,
  AudioVolume,
  AudioMute,
  Advice,
  Bulb,
  Download,
  BurgerMenu,
  Calendar,
  CarouselIndicatorInactive,
  CarouselIndicatorActive,
  ChevronUp,
  CheckMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUpMob,
  Close,
  Error,
  ExternalLink,
  FormSliderRange,
  Globe,
  ModalClose,
  Password,
  PasswordList,
  Play,
  QuoteMarks,
  ScrollToTop,
  Share,
  ShareFacebook,
  ShareTwitter,
  ShareEmail,
  ShareLink,
  SharePrint,
  Terminology,
  VideoPlay,
  InputError,
  CircleIndicator,
  CheckCircle
}