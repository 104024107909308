import React from 'react';
import { NewCheckBox } from './NewCheckBox';
import { InlineError } from './InlineError';

import './CheckboxGroup.scss';

const CheckboxGroup = (props) => {
  const { field, inlineErrors, touchedFields, requiredLabel } = props;
  const { options, id, label, mandatory } = field;

  const inlineError = inlineErrors[id];
  const touched = touchedFields[id];
  const viewableError = InlineError && touched;

  return (
    <div className='checkbox-group'>
      {label && (
        <div className='group-field-meta-data'>
          <label>
            {mandatory && <span className='mandatory-astrix'>*</span>}
            {label}
          </label>
          {viewableError && inlineError && (
            <InlineError inlineErrorText={requiredLabel} />
          )}
        </div>
      )}
      <div className='checkboxes-group'>
      {options.map((checkbox, index) => {
          const checkboxField = {
            ...field,
            label: checkbox?.elements?.optionText?.value,
            value: checkbox?.elements?.optionValue?.value,
          };
          return (
            <div key={index}>
              {checkbox?.elements?.optionText?.value &&
                checkbox?.elements?.optionValue?.value && (
                  <NewCheckBox
                    {...props}
                    checkboxNumber={index}
                    field={checkboxField}
                  />
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxGroup;
