import RichText from "components/reusableComponents/richText/RichText";
import React from "react";

import './OnOffCallOut.scss';

const OnOffCallOut = (props) => {

    const {
        backgroundColor,
        circleColor,
        circlePlacement,
        descriptionText,
        fontColor,
        titleText  
    } = props?.content?.elements;
    return (
        <div className={`onOffCallOut__state ${String(circlePlacement?.value?.selection) === "Right" ? "floatRightCircle" : "floatLeftCircle"}`} style={{ backgroundColor: backgroundColor?.value }}>
            <div className="state" style={{ color: fontColor?.value }}>{titleText?.value}</div>
            <div className="desc" style={{ color: fontColor?.value }}>
                <RichText data={descriptionText?.value?.text} />
            </div>
            <div className="deco" style={{ backgroundColor: circleColor?.value }}></div>
        </div>
    )
}

export default OnOffCallOut;