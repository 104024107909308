import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { AppConfig } from 'AppConfig';
import { getQuery } from '../../../api/AxiosQueries';
import publicIp from "public-ip";

const useIpGeoLocationRedirect = (accessKey, isIpGeoLocationEnable, alternateLinks, currentLocale) => {

    const [countryCodeName, setCountryCodeName] = useState(null);
    const [ipAddress, setIpAddress] = useState(null);
    const history = useHistory();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('geoLocation') == undefined) { 
            const paths = ['value', 'elements', 'linksPicker', 'values'];
            const countryList = paths.reduce((object, path) => {
                return (object || {})[path];
            }, alternateLinks);
            let defaultPage = null;
            countryList?.map(item => {
                if(defaultPage === null) {
                    if(item?.elements?.default?.value) {
                        defaultPage = item;
                        return;
                    }
                }
            })
            if (isIpGeoLocationEnable) {
                const getClientIpPromise = async () => await publicIp.v4({
                    fallbackUrls: [ "https://ifconfig.co/ip" ]
                });
            
                getClientIpPromise().then((ip) =>{
                    setIpAddress(ip);
                });
                const ipGeoPromise = getQuery(`${AppConfig?.lamda?.ipGeoLocation}&ip=${ipAddress}&accessKey=${accessKey}`);
                ipGeoPromise.then((val) => {
                    setCountryCodeName(val?.data);
                });

                if (countryCodeName != null) {
                    if(!currentLocale?.value?.elements?.locale?.value.includes(countryCodeName.toLowerCase())) {
                        const currentCountryItem = countryList?.find(item => item?.elements?.locale?.value.includes(countryCodeName.toLowerCase()));
                        if(currentCountryItem) {
                            history.push(currentCountryItem?.elements?.url?.value);
                        } else {
                            history.push(defaultPage?.elements?.url?.value);
                        }
                    } 
                }

            }
        }

    }, [ipAddress, countryCodeName]);

    return {countryCodeName, ipAddress};
}

export default useIpGeoLocationRedirect;