import { AppConfig } from 'AppConfig';

const {
  domain,
  apiPath,
  createTokenPath,
  consentPath,
} = AppConfig.authApi.docCheck;

// API paths
const API_URL = `${domain}/${apiPath}`;
const CREATE_TOKEN_URL = `${API_URL}/${createTokenPath}`;
const UPDATE_CONSENT_URL = `${API_URL}/${consentPath}`;

export {
  CREATE_TOKEN_URL,
  UPDATE_CONSENT_URL,
};
