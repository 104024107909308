import {
  INPUT,
  LINK,
  BUTTON,
  LINK_BUTTON,
  DROPDOWN_WEBINAR,
  DROPDOWN_DEFAULT,
  CHECKBOX,
  RADIO,
  COMPONENT,
  RADIO_BUTTTON,
  DROPDOWN,
  ROLE_DROPDOWN,
  CHECKBOX_GROUP,
  COMPLEX_DROPDOWN,
  TEXTAREA,
} from 'components/shared/form/formConstants';
import { Input } from 'components/shared/form/Input';
import { FieldLink } from 'components/shared/form/FieldLink';
import { FormButton } from 'components/shared/form/FormButton';
import { FormLinkButton } from 'components/shared/form/FormLinkButton';
import { Checkbox } from 'components/shared/form/Checkbox';
import { FreeComponent } from 'components/shared/form/FreeComponent';
import { WebinarDropdown } from './WebinarDropdown';
import { RadioButton } from './RadioButton';
import { DefaultDropdown } from './DefaultDropdown';
import { RoleDropdown } from './RoleDropdown';
import { RadioButtonEAPC } from './RadioButtonEAPC';
import {Dropdown} from './Dropdown.jsx'
import CheckboxGroup from './CheckboxGroup';
import ComplexDropdown from './ComplexDropdown';
import { Textarea } from './Textarea';

const fieldTypeMap = {
  [INPUT]: Input,
  [LINK]: FieldLink,
  [LINK_BUTTON]: FormLinkButton,
  [BUTTON]: FormButton,
  [RADIO]: RadioButton,
  [CHECKBOX]: Checkbox,
  [COMPONENT]: FreeComponent,
  [DROPDOWN_WEBINAR]: WebinarDropdown,
  [RADIO_BUTTTON]: RadioButtonEAPC,
  [DROPDOWN]:Dropdown,
  [DROPDOWN_DEFAULT]: DefaultDropdown,
  [ROLE_DROPDOWN] :RoleDropdown,
  [CHECKBOX_GROUP] :CheckboxGroup,
  [COMPLEX_DROPDOWN] :ComplexDropdown,
  [TEXTAREA] :Textarea
};


const getFieldComponent = ({
  type,
}) => {
  const FieldComponent = fieldTypeMap[type];

  if (!FieldComponent) {
    console.error(`Cannot find FieldComponent for type: ${type}`);
    return null;
  }

  return FieldComponent;
};

export {
  getFieldComponent,
};

