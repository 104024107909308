import { useEffect, useRef } from 'react'
import styles from "./Quiz.module.scss"
import Button from '../../atoms/button/Button'
import Image from 'components/kanso/atoms/image/Image'
import Logo from 'components/layout/header/components/logo/Logo'
import { getUrlCountryPath } from 'utils/urlUtils'

/**
 * QuizHeader Component
 * @param {string} logo           - The logo image to be displayed
 * @param {string} logoAltText    - The logo alt text to be displayed
 * @param {string} exitButtonText - The exit button text to be displayed
 * @param {string} exitAction     - The exit button action to be triggered
 * @returns React Component
 */

const QuizHeader = (props) => {

  const { 
    logo,
    logoAltText,
    exitButtonText, 
    exitAction
  } = props

  return (
    <div className={styles["quiz-header"]}>
      <div className={styles["nav"]}>
        {logo && <div className={styles["nav-logo"]}>
          <Logo library={getUrlCountryPath()} />
        </div>}
        {exitButtonText && (
          <span className={styles["nav-exit"]}>
            <Button type="tertiary" text={exitButtonText} link="" icon="close" iconAlign="right" onClick={() => {exitAction()}} />
          </span>
        )}
      </div>
      {props.children}
    </div>
  )
}

export default QuizHeader;
