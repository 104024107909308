import React, {useRef} from "react";
import ReactTooltip from 'react-tooltip';

import './IconLink.scss';
import { getAssetUrl } from "api/acousticApi";
import { ConfirmAndNavigate } from 'utils/hyperlinkUtils'

import { toggleOverlay, closePopup } from 'components/pageTemplates/inforgraphicstb/components/helper';
import close from '../../../../assets/images/infographics-assets/circle-close-icon.svg';
import arrow from '../../../../assets/images/infographics-assets/arrow-icon.svg';
import RichText from "components/reusableComponents/richText/RichText";
import Link from "components/reusableComponents/link/Link";
import parse from 'react-html-parser'

const IconLink = (props) => {

    const {
        icon,
        buttonTitle,
        callout,
        buttonTextColour,
        buttonBackgroundColour,
        displayCtaVersion,
        popupText,
        descriptionText,
        linkText,
        linkUrl,
        descriptionTextFontColour,
    } = props?.iconItemContent?.elements;

    let toolTipBtn = useRef(null);
    let backgroundColorValue= buttonBackgroundColour?.value
    let buttonTextColourValue= buttonTextColour?.value
    let textFontColourValue = descriptionTextFontColour?.value

    if((backgroundColorValue?.indexOf("#") == -1)){
        backgroundColorValue = "#"+backgroundColorValue
    }
    if((buttonTextColourValue?.indexOf("#") == -1)){
        buttonTextColourValue = "#"+buttonTextColourValue
    }
    if((textFontColourValue?.indexOf("#") == -1)){
        textFontColourValue = "#"+textFontColourValue
    }

    const styleBtn ={
        backgroundColor:backgroundColorValue,
        color:buttonTextColourValue,
        borderColor:buttonTextColourValue,
    }
    const handleExternalLink = (evt, url) => {
        const message = "You are about to leave the Kyowa Kirin site.\nAre you sure you want to continue?";
        const target = '_blank'
        ConfirmAndNavigate({
            href: url,
            message,
            target,
        })
    };

    return (
        <div className="icon-link-grid__item">
            <img className="main-icon" src={getAssetUrl(icon)} alt={icon?.asset?.altText} />
            <div className="icon-link-grid-item__contentWrapper">
                <img className="arrow" src={arrow} />
                <div className="icon-link-grid-item__content">
                    <div className="icon-link-grid-item__title" style={{color:textFontColourValue}}>
                        <RichText data={descriptionText} />
                    </div>
                    {displayCtaVersion?.value === true ? 
                    <div className="icon-link-grid-item__button" data-popup-for={props.pid} ref={ref => toolTipBtn = ref}  data-tip data-event='click' data-for={props.pid} style={styleBtn}>
                      {buttonTitle?.value}
                    </div> :null
                    }
                    {(

                        <ReactTooltip
                            id={props.pid}
                            data-id={props.pid}  
                            globalEventOff='click' 
                            place="bottom" 
                            textColor='#3D3F5B' 
                            backgroundColor='#ffffff' 
                            effect='solid'
                            afterShow={(evt) => toggleOverlay('show')}
                            afterHide={(evt) => toggleOverlay('hide')}
                            wrapper='span'>
                                <div className="tool-tip__wrapper">
                                    <div className="tool-tip__close">
                                        <img src={close} onClick={(evt) => closePopup(evt) } />
                                    </div>
                                    <div className="tool-tip__content">
                                        <RichText data={popupText}/>
                                        {linkUrl?.value && (
                                            <div className="tool-tip__content-cta">
                                                <button type="button" onClick={(ev) => {handleExternalLink(ev,linkUrl?.value)}} >
                                                {linkText?.value}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                        </ReactTooltip>
                    )}
                </div>
            </div>
        </div>
    );
}

export default IconLink;