import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'components/shared/SvgIcons';
import './CloseButton.scss';
import { ModalClose } from 'assets/kanso/svgIcons/SvgIcons';


const CloseButton = ({
  onClick,
  className,
}) => (
  <button
    type="button"
    onClick={onClick}
    className="close-button-container"
  >
    <ModalClose />
  </button>
);


CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};


export {
  CloseButton,
};



