import {
  DATA_CAPTURE_URL,
  COURSES_LIST,
  DELETE_EVENT_PATH,
  ANONYMOUS_USER_REGISTRATION,
} from './path';
import { authPostQuery, authGetQuery } from '../AxiosQueries';
import { getEnvApiConfigValues } from 'utils/envUtils';
import { getAuthHeaderConfig } from 'utils/authUtils';

const { audience } = getEnvApiConfigValues();
let url = DATA_CAPTURE_URL;

const webinarSignUp = ({ body, token }) => {
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
    authToken: token,
  });
  return authPostQuery({ url, data: body, config: headerConfig });
};

const getCourses = ({ userId, token, type }) => {
  let url = COURSES_LIST;
  url = url + '?id=' + userId + '&type=' + type;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
    authToken: token,
  });
  return authGetQuery({ url, config: headerConfig });
};

const deleteCourse = ({ body, token }) => {
  let url = DELETE_EVENT_PATH;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
    authToken: token,
  });
  return authPostQuery({ url, data: body, config: headerConfig });
};

const anonymousRegistration = ({ body, token }) => {
  let url = ANONYMOUS_USER_REGISTRATION;
  const headerConfig = getAuthHeaderConfig({
    audienceUrl: audience,
    authToken: token,
  });
  return authPostQuery({ url, data: body, config: headerConfig });
};

export { webinarSignUp, getCourses, deleteCourse, anonymousRegistration };
