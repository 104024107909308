import {
  INPUT,
  BUTTON,
  COMPONENT,
  commonShouldHaveValue,
} from 'components/shared/form/formConstants';
import { MandatoryKey } from 'components/pageTemplates/login/MandatoryKey';
import { KKI_AUTH, OWA_AUTH, DOCCHECK_AUTH } from 'utils/authUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';


const useDeleteAccountFormFields = () => {

  const {
    authService,
    tCurrentPassword,
    tEnterYourPassword,
    tDeleteAccount,
  } = useWebsiteConfigObject();

  const { selection } = authService || {};

  if (!authService) {
    return null;
  }

  const deleteAccountFormFields = {
    kki: [
      {
        label: tCurrentPassword,
        id: 'password-2', // this gets turned into a html id and is the second time it appears on the same page
        initialValue: '',
        type: INPUT,
        mandatory: true,
        typeParams: {
          type: 'password',
          autoComplete: 'off', // < - this is the correct method but it's no longer supported. Most browsers just auto complete regardless
          // placeholder: tEnterYourPassword, removed as per ticket KKICH-838 and figma
        },
        validation: [
          commonShouldHaveValue,
        ],
      },
      {
        id: 'delete-account',
        type: BUTTON,
        size: 50,
        typeParams: {
          callBack: ({ formValues, actions }) => {
            actions.handleDeleteAccount(formValues);
          },
          text: tDeleteAccount.toUpperCase(),
          type: 'submit',
        },
      },
      {
        id: 'mandatory-key',
        type: COMPONENT,
        size: 100,
        typeParams: {
          PropComponent: MandatoryKey,
        },
      },
    ],
    docCheck: [
      {
        id: 'delete-account',
        type: BUTTON,
        size: 50,
        typeParams: {
          callBack: ({ actions }) => {
            actions.handleDeleteAccountOWA();
          },
          text: tDeleteAccount.toUpperCase(),
          type: 'submit',
        },
      },
      {
        id: 'mandatory-key',
        type: COMPONENT,
        size: 100,
        typeParams: {
          PropComponent: MandatoryKey,
        },
      },
    ],
    oneKey: [
      {
        id: 'delete-account',
        type: BUTTON,
        size: 50,
        typeParams: {
          callBack: ({ actions }) => {
            actions.handleDeleteAccountOWA();
          },
          text: tDeleteAccount.toUpperCase(),
          type: 'submit',
        },
      },
      {
        id: 'mandatory-key',
        type: COMPONENT,
        size: 100,
        typeParams: {
          PropComponent: MandatoryKey,
        },
      },
    ],
  };

  switch (selection) {
    case KKI_AUTH:
      return deleteAccountFormFields.kki;
    case OWA_AUTH:
      return deleteAccountFormFields.oneKey;
    case DOCCHECK_AUTH:
      return deleteAccountFormFields.docCheck;
    default:
      return null;
  }
};


export {
  useDeleteAccountFormFields,
};

