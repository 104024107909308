import React, { useRef } from 'react';
import '../../pages/brandArticlePage/BrandArticlePage.scss';
import { getComponent } from 'pages/flexiTemplate/FlexiTemplate';
import Anchors from 'modules/contentAnchors/ContentAnchors';

import './FlexiColumnContainer.scss';
import { getValue } from 'utils/utilityHelper';
import { stringToCssId } from 'utils/stringUtils';
import Calculator from 'components/reusableComponents/calculator/Calculator';

const FlexiColumnContainer = ({ content, pageContent }) => {
  const { column1, column2, column3 } = content?.value?.elements;

  const { component: Column1Component, props: column1Props } = getComponent(
    column1?.value,
    pageContent
  );

  const { component: Column3Component, props: column3Props } = getComponent(
    column3?.value,
    pageContent
  );

  if (Column1Component === Anchors) column1Props['elements'] = column2?.values;
  if (Column3Component === Anchors) column3Props['elements'] = column2?.values;

  const stickyMenuRef = useRef();

  return (
    <section className='flexi-column-container'>
      <div className='container'>
        <div className='brand-article-page__content-section-inner'>
          <div
            ref={Column1Component === Anchors ? stickyMenuRef : null}
            className='flexi-column-1 brand-article-page__subnavigation'
          >
            {Column1Component && (
              <Column1Component
                {...column1Props}
                containerRef={
                  Column1Component === Anchors ? stickyMenuRef : null
                }
              />
            )}
          </div>
          <div className='flexi-column-2 brand-article-page__content'>
            {column2?.values?.map((columnContent) => {
              const { component: Component, props } = getComponent(
                columnContent,
                pageContent
              );

              let title = getValue(props?.data?.title);
              const id = title && stringToCssId(title);

              return (
                Component && (
                  <div id={id}>
                    <Component key={columnContent?.id} {...props} />
                  </div>
                )
              );
            })}
          </div>
          <div
            ref={Column3Component === Anchors ? stickyMenuRef : null}
            className='flexi-column-3 brand-article-page__subnavigation'
          >
            {Column3Component && (
              <Column3Component
                {...column3Props}
                elements={column2?.values}
                containerRef={
                  Column3Component === Anchors ? stickyMenuRef : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlexiColumnContainer;
