import React from 'react';
import PropTypes from 'prop-types';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
  SCREEN_WIDTH_MEDIUM
} from 'components/layout/ScreenWidthContext';
import { getColourHashValue } from 'utils/contentUtils';
import { getImgAttr } from 'api/acousticApi';
import RichText from 'components/reusableComponents/richText/RichText';
import { contentElementProptype } from 'propTypes/contentProptypes';
import { useSelector } from 'react-redux';
import './MainAreaTitleSection.scss';

const MAIN_AREA_TITLE_STANDARD = 'standard';
const MAIN_AREA_TITLE_CONTENT_PROMO = 'promo';



const MainAreaTitleSection = ({
  category,
  logo,
  title,
  description,
  headline,
  headerAlternateDisplay,
  type = MAIN_AREA_TITLE_STANDARD,
  backgroundImage,  
  backgroundHexColour,
  fontHexColour,
  standardText,
  emphasisedText
}) => {
  const headerLogo = getImgAttr(logo);
  const headerBackgroundImage = getImgAttr(backgroundImage);
  const textColour = getColourHashValue(fontHexColour);
  const backgroundColour = getColourHashValue(backgroundHexColour);
  const baseClassName = 'main-title-section';
  const { redesign } = useSelector(state => state.ui);
  const classNames = [
    baseClassName,
    `${baseClassName}--${type}`,
  ];
  // if - the category is being used on the Main promo pages (About Moventig, Abstral & Pecfent). Titles here are short so we can use a 30/70% column split
  if (category && !logo) {
    classNames.push('has-category');
  }
  const className = classNames.join(' ');

   const {
    currentWidthRanges,
  } = useScreenWidth();
  const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
  const isIpad = currentWidthRanges.includes(SCREEN_WIDTH_MEDIUM);
  const image = isMobile ? 'none' : `url(${headerBackgroundImage?.src})`;

  const style = {
    backgroundImage: image,
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  };

  // If font colour is not set in Acoustic the style sheet colours will be applied by default
  const textStyle = {
    color: `${textColour}`
  }
  const textSubtitle= {
    color:`${textColour}`
  }
  if(redesign) {
    return (
      <section
        className={className}
        style={{ backgroundColor: backgroundColour }}
      >
        <div className="title-background-image" style={style}>
          <div className="container">
            <div className={"title-section-inner " + (!isMobile && !isIpad && headerAlternateDisplay?.value ? 'use-header-alternative' : '')}>
              <div className="title">
                {(headerLogo && headerLogo?.src) && (
                  <img
                    src={headerLogo?.src}
                    alt={headerLogo?.alt}
                    className="title-section-logo"
                  />
                )}
                {category && (
                  <h3
                    className="title-section-category"
                    style={textSubtitle}
                  >
                      {category}
                  </h3>
                )}
                 {headline && (
                  <RichText
                  className="title-description"
                  data={headline}
                  style={textSubtitle}
                />
                )}
                {title && (
                   <h1 className="title-section-title" style={textStyle}>
                   { title }
                 </h1>
                )}
              </div>
              { description && (
                <RichText
                  className="title-description"
                  data={description}
                />
              )}
              {standardText && 
                <p>
                  {standardText}
                  <span className="emphasisedText">{emphasisedText}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section
      className={className}
      style={{ backgroundColor: backgroundColour }}
    >
      <div className="title-background-image" style={style}>
        <div className="container">
          <div className={"title-section-inner " + (!isMobile && !isIpad && headerAlternateDisplay?.value ? 'use-header-alternative' : '')}>
            <div className="title">
              {(headerLogo && headerLogo?.src) && (
                <img
                  src={headerLogo?.src}
                  alt={headerLogo?.alt}
                  className="title-section-logo"
                />
              )}
              {category && (
                <h3
                  className="title-section-category"
                  style={textSubtitle}
                >
                    {category}
                </h3>
              )}
               {headline && (
                <RichText
                className="description"
                data={headline}
                style={textSubtitle}
              />
              )}
              {title && (
                 <h1 className="title-section-title" style={textStyle}>
                 { title }
               </h1>
              )}
            </div>
            { description && (
              <RichText
                className="description"
                data={description}
              />
            )}
            {standardText && 
              <p>
                {standardText}
                <span className="emphasisedText">{emphasisedText}</span>
              </p>
            }
          </div>
        </div>
      </div>
    </section>
  );
};


MainAreaTitleSection.propTypes = {
  category: PropTypes.string,
  logo: PropTypes.object,
  title: PropTypes.string,
  description: contentElementProptype,
  backgroundHexColour: PropTypes.object,
  fontHexColour: PropTypes.object,
  backgroundImage: PropTypes.object,  
  type: PropTypes.string,
  headline:contentElementProptype,
};

export {
  MainAreaTitleSection,
  MAIN_AREA_TITLE_STANDARD,
  MAIN_AREA_TITLE_CONTENT_PROMO,
};
