import React from 'react';
import RichText from 'components/reusableComponents/richText/RichText';
import Link from 'components/reusableComponents/link/Link';
import { ChevronRight } from 'components/shared/SvgIcons';
import Image from 'components/reusableComponents/image/Image';
import './TextAndImagePanel.scss';

const TextAndImagePanel = ({ action, content, ctaBtnBackground }) => {
  const {
    buttonText,
    buttonUrl,
    description,
    heading,
    image,
    imageCaption,
    imageLogo,
  } = content?.elements;
  let bgStyle = {
    backgroundColor: ctaBtnBackground?.value || '#EA5504',
  };
  let isAnhor = buttonUrl?.value.includes('#');
  return (
    <div className={`text-and-image-panel${image?.asset ? "" : " full-width"}`}>
      {heading?.value && (
        <div className='text-and-image-panel__header'>
          <h4>{heading?.value}</h4>
        </div>
      )}
      <div className={`text-and-image-panel__columns${image?.asset ? "" : " full-width"}`}>
        <div className='text-and-image-panel__columns__left'>
          <RichText data={description} />
          {buttonText?.value && (
            <div
              className='text-and-image-panel__columns__left__link'
              style={bgStyle}
            >
              {isAnhor && (
                <a onClick={action}>
                  <span>{buttonText?.value}</span> <ChevronRight />
                </a>
              )}
              {!isAnhor && (
                <Link href={buttonUrl?.value}>
                  <span>{buttonText?.value}</span> <ChevronRight />
                </Link>
              )}
            </div>
          )}
        </div>
        {image?.asset && (
          <div className='text-and-image-panel__columns__right'>
            <div className='text-and-image-panel__columns__right__logos'>
              <div className='logos-wrap'>
                <Image data={image} />
                {imageLogo && <Image className='logo' data={imageLogo} />}
              </div>
            </div>
            {imageCaption?.value && <p>{imageCaption?.value}</p>}
            <div
              className='text-and-image-panel__columns__right__link'
              style={bgStyle}
            >
              {isAnhor && (
                <a onClick={action}>
                  <span>{buttonText?.value}</span> <ChevronRight />
                </a>
              )}
              {!isAnhor && (
                <Link href={buttonUrl?.value}>
                  <span>{buttonText?.value}</span> <ChevronRight />
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextAndImagePanel;
