import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import {
  ChevronUp,
  ChevronRight,
} from 'components/shared/SvgIcons';
import {
  getValue,
  getHrefs,
} from 'utils/contentUtils';
import RichText from 'components/reusableComponents/richText/RichText';
import Link from 'components/reusableComponents/link/Link';
import './StorySelectorBody.scss';


const StorySelectorBody = ({
  accordionMode,
  closeStory,
  story,
  backgroundColor,
  primaryColour,
}) => {
  const {
    tabContent,
    tabTitle,
    category,
    date,
    externalLink,
    page,
    linkToInternalPageOrExternalLink,
  } = story.elements;


  const { tLearnMore } = useWebsiteConfigObject();
  const tabTitleValue = getValue(tabTitle);
  const dateValue = getValue(date);
  const categoryValue = getValue(category);
  
  const {
    internalHref,
    externalHref,
  } = getHrefs({
    externalLink,
    page,
    linkToInternalPageOrExternalLink,
  });

  const hasLink = internalHref || externalHref;

  const backgroundColorStyle = backgroundColor ? ({
    backgroundColor,
  }) : null;


  const metaTextColorStyle = primaryColour ? ({
    color: primaryColour,
  }) : null;


  return (
    <div className="story-selector-body-container">
      <div
        className="story-selector-body"
        style={backgroundColorStyle}
      >
        <div className="top-half">
          {
            accordionMode ? (
              <button
                type="button"
                onClick={closeStory}
                className="story-selector-accordion-head-button custom-focus"
              >
                <h3 className="story-body-title">{tabTitleValue}</h3>
                <div className="svg-container">
                  <ChevronUp
                    clearSize
                  />
                </div>
              </button>
            ) : (
              <h3 className="story-body-title">{tabTitleValue}</h3>
            )
          }
          {
            categoryValue && (
              <h4
                className="meta-tag meta-tag-category"
                style={metaTextColorStyle}
              >
                {categoryValue}
              </h4>
            )
          }
          {
            dateValue && (
              <span
                className="meta-tag meta-tag-date"
                style={metaTextColorStyle}
              >
                {dateValue}
              </span>
            )
          }
          {
            tabContent && (
              <RichText
                className="story-body-text"
                element={tabContent}
              />
            )
          }
        </div>
        <div className="bottom-half">
          {
            hasLink && (
              <Link
                className="learn-more"
                href={internalHref || externalHref}
              >
                <p
                  className="learn-more-text"
                  style={metaTextColorStyle}
                >
                  {tLearnMore}
                </p>
                <div
                  className="svg-container"
                  style={metaTextColorStyle}
                >
                  <ChevronRight />
                </div>
              </Link>
            )
          }
        </div>
      </div>
    </div>
  );
};


export {
  StorySelectorBody,
};

