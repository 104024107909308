import React from 'react'

// icons
import { ExampleCategoryIcon } from '../../../../shared/SvgIcons'

// styles
import './CardCategory.scss'

const CardCategory = ({ category, withIcon, theme, colorInherit, fluidStyle }) => {

    if (!category) return null

    return (
        <h3 className={`CardCategory${theme ? ` CardCategory--theme-${theme}` : ''}${colorInherit ? ' CardCategory--color-inherit' : ''}${fluidStyle ? ' CardCategory--fluid-style' : ''}`}>
            {withIcon && <ExampleCategoryIcon />}
            <p>{category}</p>
        </h3>
    )
}

export default CardCategory
