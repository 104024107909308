import React from 'react';

//modules
import RichText from 'components/reusableComponents/richText/RichText';
import { TextCardLearnMore } from 'components/content/cards/TextCardLearnMore';

import './TwoColumnPanel.styles.scss';

const TwoColumnPanel = ({...props}) => {
  const {
    columnOne,
    columnTwo,
    linkText,
    linkUrl,
    bgColor,
    ctaBackground
  } = props;
  const brandColourStyle = {
    backgroundColor: bgColor?.value ? bgColor?.value : "#F9F4F2"
  };
  return(
    <div className="two-column-panel-container" style={brandColourStyle}>
      <div className="two-column-panel-container__wrap">
        <div className="two-column-panel-container__wrap__left">
          <RichText data={columnOne} />      
        </div>
        <div className="two-column-panel-container__wrap__right">
          <RichText data={columnTwo} />   
          {linkText && linkUrl && <TextCardLearnMore /* ctaBackground={ctaBackground} */ text={linkText} onClickPath={linkUrl}/>}   
        </div>
      </div>
    </div>
  )
}

export default TwoColumnPanel;