import 'intersection-observer'; //Polyfill
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  LOGIN_PAGE_PATH,
  EXTERNAL_LOGIN_ERROR_PATH,
  INFOGRAPHICSTB,
} from 'routes/routeConstants';
import { getUrlCountryData } from 'utils/libraryUtils';

import MDTSection from './components/MDTSection';
import MDTIntroSection from './components/MDTIntroSection';
import References from './components/references';
import { ApprovalCode } from 'components/content/ApprovalCode';
import MDTPathologySection from './components/MDTPathologySection';
import MDTDopaminergicSection from './components/MDTDopaminergicSection';
import MDTBiomakersSection from './components/MDTBiomakersSection';
import MDTSummary from './components/MDTSummary';
import Overlay from './components/overlay';
import { loadScrollerPopup, closeTimePopup } from './components/helper';
import InfographicsSection from './components/InfographicsSection';

//AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
import { node } from 'prop-types';
import { toggleOverlay } from './components/helper';

const InfographicsTBPage = ({ content, template }) => {
  const {
    infographicsSection,
    references,
    referencesFontColour,
    referencesBackgroundColourHex,
    veevaApprovalCode
  } = content.elements;

  let backgroundColorValue = referencesBackgroundColourHex?.value;
  if (backgroundColorValue?.indexOf('#') == -1) {
    backgroundColorValue = '#' + referencesBackgroundColourHex?.value;
  }
  let foregroundColorValue = referencesFontColour?.value;
  if (foregroundColorValue?.indexOf('#') == -1) {
    foregroundColorValue = '#' + referencesFontColour?.value;
  }

  const [isTimePopEnable, setIsTimePopEnable] = useState(true);
  loadScrollerPopup('.js-scroll-target');

  const [isOpenRef, setIsOpenRef] = useState(false);
  const openRef = () => {
    return setIsOpenRef(!isOpenRef);
  };
  let element = document.querySelectorAll('sup');
  element.forEach(function (nodeList) {
    nodeList.style.cursor = 'pointer';
    nodeList.addEventListener('click', () => {
      openRef();
    });
  });

  const [referencesColors, setReferencesColors] = useState({
    textColor: foregroundColorValue,
    backgroundColor: backgroundColorValue,
  });

  const elementsOverlap = (el1, el2) => {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  };

  useEffect(() => {
    //Scroll behaviour for popups
    //loadScrollerPopup('.js-scroll-target');

    //Now using time AOS - Animation on scroll plugin
    AOS.init();

    //Event Listener when animation is on
    document.addEventListener('aos:in', ({ detail }) => {
      closeTimePopup(detail);
    });

    let container = document.querySelector('#main');
    let elementSup = document.querySelectorAll('sup');
    container?.addEventListener('click', function (e) {
      //     let element = document.querySelectorAll("sup");
      getSup();
    });
    function getSup() {
      let element = document.querySelectorAll('sup');
      element.forEach(function (nodeList) {
        nodeList.style.cursor = 'pointer';
        // nodeList.click();
        nodeList.addEventListener('click', () => {
          openRef();
        });
      });
    }

    // References colors updates on overlapping infographics sections when scrolling
    document.addEventListener('scroll', function (e) {
      let isOverlapping = false;

      infographicsSection?.values?.forEach((element) => {
        const { sectionId, referencesBackgroundColor, referencesFontColor } =
          element.elements;
        const referencesButton = document.getElementById('references-button');
        const sectionElement = document.getElementById(sectionId?.value);

        if (sectionElement) {
          // if (elementsOverlap(sectionElement, referencesButton)) {
          if (elementsOverlap(referencesButton, sectionElement)) {
            setReferencesColors({
              textColor: referencesFontColor?.value,
              backgroundColor: referencesBackgroundColor?.value,
            });
            isOverlapping = true;
          }
        }
      });

      if (!isOverlapping) {
        setReferencesColors({
          textColor: foregroundColorValue,
          backgroundColor: backgroundColorValue,
        });
      }
    });
  }, []);

  const { textColor, backgroundColor } = referencesColors;

  return (
    <>
      {/* Introduction */}
      {content && <MDTIntroSection content={content} />}
      {/* Parkinson progression graph */}
      {InfographicsSection &&
        infographicsSection?.values?.map((elements, index) => (
          <InfographicsSection
            element={elements}
            key={index}
            showTimePopUp={isTimePopEnable}
            onDisableTimePopUp={(isEnable) => setIsTimePopEnable(isEnable)}
            content={content}
          />
          // <MDTSection
          // element={elements}
          //     key={index}
          //     showTimePopUp={isTimePopEnable}
          //     onDisableTimePopUp={isEnable => setIsTimePopEnable(isEnable)}
          // />
        ))}
         {veevaApprovalCode && veevaApprovalCode?.value && <ApprovalCode code={veevaApprovalCode} />} 
      {references && (
        <References
          isOpenRef={isOpenRef}
          setIsOpenRef={setIsOpenRef}
          type='mdtSection'
          textColor={textColor}
          backgroundColor={backgroundColor}
          content={references}
        />
      )}
      <Overlay />
    </>
  );
};

export default InfographicsTBPage;
