import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/reusableComponents/link/Link';
import RichText from 'components/reusableComponents/richText/RichText'
import { HomepageCollapsableSection } from 'components/content/homepage/HomepageCollapableSection';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { getImgAttr } from 'api/acousticApi';
import LinkPanel from 'modules/linkPanel/LinkPanel';
import { getValue } from 'utils/contentUtils';
import './SectionPage.scss';
import { useSelector } from 'react-redux';

const TitleElement = ({
  sectionTitle,
  href,
}) => {
  const Title = () => <h2>{sectionTitle}</h2>;

  if (href) {
    return (
      <Link href={href}>
        <Title />
      </Link>
    );
  }

  return <Title />;
};

TitleElement.propTypes = {
  sessionTitle: PropTypes.string,
  href: PropTypes.string,
};


const SectionPage = ({ moduleSectionPage }) => {
  
  const {
    sectionCategory,
    sectionIcon,
    sectionIconTitle,
    sectionTitle,
    sectionText,
    moduleLinkPanels,
    sectionTitleLink
  } = moduleSectionPage;
  const { redesign } = useSelector(state => state.ui);

  const {
    src,
    alt,
  } = getImgAttr(sectionIcon);

  const Icon = src ? (
    <img
      src={src}
      alt={alt}
    />
  ) : null;

  const sectionCategoryValue = getValue(sectionCategory);

  if (!sectionTitle?.value && moduleLinkPanels?.values.length === 0)
    return null;
  
  if(redesign){
    return <div className="module-section-page">
        {/* {sectionCategoryValue && (
          <div className="category-divider">
            <span>{sectionCategoryValue}</span>
            <div className="line" />
          </div>
        )} */}
        {
          sectionIconTitle.value && (
            <HomepageCollapsableSection
              title={sectionIconTitle ? sectionIconTitle.value : (sectionTitle ? sectionTitle.value : "")}
              icon={Icon}
              startOpen={true}
              isCollapsable={false}
              subtitle={sectionCategoryValue ? sectionCategoryValue : null}
            >
              <div className="title-description">
                {
                  sectionTitle && (
                    <TitleElement href={sectionTitleLink?.linkURL} sectionTitle={sectionTitle.value} />
                  )
                }

                {
                  sectionText && (
                    <RichText data={sectionText} />
                  )
                }
              </div>
              <LinkPanel linkElements={moduleLinkPanels} />
            </HomepageCollapsableSection>
          )
        }
        
      </div>
  }
  return <div className="module-section-page">
      {sectionCategoryValue && (
        <div className="category-divider">
          <span>{sectionCategoryValue}</span>
          <div className="line" />
        </div>
      )}
      {
        sectionIconTitle.value && (
          <HomepageCollapsableSection
            title={sectionIconTitle ? sectionIconTitle.value : (sectionTitle ? sectionTitle.value : "")}
            icon={Icon}
            startOpen={true}
            isCollapsable={false}
          >
            <div className="title-description">
              {
                sectionTitle && (
                  <TitleElement href={sectionTitleLink?.linkURL} sectionTitle={sectionTitle.value} />
                )
              }

              {
                sectionText && (
                  <RichText data={sectionText} />
                )
              }
            </div>
            <LinkPanel linkElements={moduleLinkPanels} />
          </HomepageCollapsableSection>
        )
      }
      
    </div>
};

SectionPage.propTypes = {
  moduleSectionPage: contentItemProptype,
};

export { SectionPage };