//content types with ids for infrographics section
const Module_Column_Container = "286e4e2c-d69f-440c-9da1-47442af13925";
const Module_Accordion = "a5a0b8cf-4120-4506-b874-8631fe3a3d16";
const Module_Text_Block = "9f435db2-52d1-4217-8d02-f2aeb4d7d7b2";
const Module_Centred_Icon = "dbdf7f87-ce5c-4b92-87ad-80fa51006ea0";
const Component_Aligned_Icon = "183c3073-65a6-4c1a-85af-dac3e1eed885";
const Module_Infographic = "6bf56c07-ea70-4545-a033-353d21347e2c";
const Module_Compound_Infographic = "850a7b1c-280f-45ec-b116-5794613966e4";
const Module_Image_Block = "b6bfa259-20fc-4226-ac86-d025595d958c";
const Module_Circle_CallOut = "3e5c50e8-581c-40b3-bf06-90361d15f4c9";
const Module_CallOut = "8be251fe-474b-43b7-b8b2-0bf6870a47cd";
const Module_Caroussel_Item = "f2d71170-dc7e-40c0-a569-2c102e0a5470";

export {
    Module_Column_Container,
    Module_Accordion,
    Module_Text_Block,
    Module_Centred_Icon,
    Component_Aligned_Icon,
    Module_Infographic,
    Module_Compound_Infographic,
    Module_Image_Block,
    Module_Circle_CallOut,
    Module_CallOut,
    Module_Caroussel_Item
}
