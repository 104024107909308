import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './CountryList.scss';
import Image from 'components/reusableComponents/image/Image';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { LOGIN_PAGE_PATH } from 'routes/routeConstants';

const CountryList = ({ content, isGlobal }) => {
    const {
        url,
        flag,
        linkLabel,
        countryName,
    } = content.elements;
    const {
        moodleRedirectConfirmationAlertOnGlobalLandingPage,
    } = useWebsiteConfigObject();
    const [cookies, setCookie] = useCookies(['moodleCountry']);
    const localReturnUrl = sessionStorage.getItem('MoodleReturnUrl');
    const MoodleRedirectionObj = JSON.parse(localReturnUrl);

    useEffect(() => {
        const showMoodleAlert = sessionStorage.getItem('showMoodleAlert');

        if(localReturnUrl && showMoodleAlert !== 'false'){
            if (MoodleRedirectionObj?.ReturnUrl && MoodleRedirectionObj?.isGlobal && moodleRedirectConfirmationAlertOnGlobalLandingPage) {
                let alertMsg = moodleRedirectConfirmationAlertOnGlobalLandingPage;
                alertMsg = alertMsg.replaceAll("{externalLink}", decodeURIComponent(MoodleRedirectionObj?.ReturnUrl));
                sessionStorage.setItem('showMoodleAlert', 'false');
                alert(alertMsg);
            }
        }
    }, [localReturnUrl, MoodleRedirectionObj, moodleRedirectConfirmationAlertOnGlobalLandingPage])

    let urlValue = url?.value
    if(urlValue.startsWith('www')) urlValue = `https://${urlValue}`
    urlValue =  !urlValue.startsWith('http') && !urlValue.startsWith('/') ? `/${urlValue}` : urlValue

    const linkOnClick = (e, urlValue) => {
        e.preventDefault();
        if(localReturnUrl){
            if (MoodleRedirectionObj?.ReturnUrl && MoodleRedirectionObj?.isGlobal && moodleRedirectConfirmationAlertOnGlobalLandingPage) {
                setCookie('moodleCountry', urlValue, { path: '/', maxAge: 365 * 86400 });
                // sessionStorage.setItem('showMoodleAlert', 'false');
                // let alerMsg = moodleRedirectConfirmationAlertOnGlobalLandingPage;
                // alerMsg = alerMsg.replaceAll("{externalLink}", decodeURIComponent(MoodleRedirectionObj?.ReturnUrl));
                // alert(alerMsg);
                window.location = urlValue;
            } else {
                window.location = urlValue;
            }
        } else {
            window.location = urlValue;
        }
    }
    return (
        <div className={`CountrySelector${isGlobal ? " CountrySelector--global" : ""}`} tabindex="0">
            <a href={`${urlValue}`} onClick={(e) => {linkOnClick(e, urlValue)}}>
        {/* <div className="CountrySelector Desktop" tabindex="0">
            <a href={`${urlValue}`}> */}
                <Image data={flag} alt={linkLabel?.value} />
                <p>{countryName.value}</p>
            </a>
        </div>
    );
}

export default CountryList;