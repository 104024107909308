import React, { useState, useEffect } from 'react';
import { Rating } from 'react-simple-star-rating';
import { StarIcon } from 'components/shared/SvgIcons';
import { useHistory } from 'react-router-dom';

import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import './ContentSentimentRatings.scss';

const ContentSentimentRatings = (props) => {
  const history = useHistory();

  const {
    isSentimentRatingEnable
  } = props;
  const {
    tContentRatingPrompt,
    tContentRatingConfirmation,
  } = useWebsiteConfigObject();
  const { trackContentRating } = useGoogleAnalytics();

  // initial rating value
  const [ratingValue, setRatingValue] = useState(0);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  useEffect(() => {
    let starRatingMailHtml = document.getElementsByClassName("star-rating-content");
    if(starRatingMailHtml && starRatingMailHtml[0] && starRatingMailHtml.length > 0){
      const starRatingSpans = starRatingMailHtml[0].childNodes;
      if(starRatingSpans && starRatingSpans[0] && starRatingSpans.length > 0){
        starRatingSpans.forEach((span, index) => {
          span.dataset.value = index + 1;
        });
      }
    }
  },[])
  
  // Catch Rating value
  const handleRating = (rate) => {
    if (!hasConfirmed) {
      setRatingValue(rate);
      setHasConfirmed(true);

      //Disable click on the
      document.querySelector('.js-star-rating').classList.add('disable-rating');

      //GA events
      trackContentRating(rate);
      if(history.action === "PUSH") {
        if(window.utag) {
          const button_data = { tealium_event: "Star Rating Click", buttontext: rate.toString() };
          window.utag.ut.merge(button_data, window.utag_data, 1);
          window.utag.view(button_data, null, [2]);
        } 
      }
    }
  };

  return (
    <div className={`${isSentimentRatingEnable ? 'star-rating-view' : 'star-rating-view star-rating-view--hide'}`}>
      <span className='rating-text'>
        {hasConfirmed ? tContentRatingConfirmation : tContentRatingPrompt}
      </span>
      <Rating
        onClick={handleRating}
        ratingValue={ratingValue}
        emptyColor='white'
        fillColor='#EA5504'
        className='js-star-rating star-rating-content'
        size={39}
      >
        <StarIcon />
      </Rating>
    </div>
  );
};

export { ContentSentimentRatings };
