import React from 'react'

// layouts
import Grid from 'layouts/grid/Grid'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import SideNavigation from '../../modules/sideNavigation/SideNavigation'
import VideoCard from 'modules/videoCard/VideoCard'

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { DynamicContentSection } from './DynamicContentSection'
import { References } from 'components/content/References'
import { BannerImage } from 'components/content/BannerImage'
import { ApprovalCode } from 'components/content/ApprovalCode'
import { getValue } from 'utils/contentUtils'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'

const DynamicContentPage = ({ content }) => {
    const {
        headerText,
        title,
        subtitle,
        headerLogo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        contentSection,
        relatedVideos,
        relatedContent,
        bannerImage,
        veevaApprovalCode,
        references,
        subnavigation,
    } = content ? content.elements : {}

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;

    const isFullWidth = relatedVideos?.value?.elements?.fullWidthVideoCards?.value

    return (
        <section role='main' className='global_layout' data-layout-type='dynamic-content-page'>
            <MainAreaTitleSection
                title={title && title.value}
                description={headerText}
                category={subtitle && subtitle.value}
                logo={headerLogo}
                backgroundImage={headerBackgroundImage}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-2-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>
                        <section className='content_wrapper content_wrapper--columns-2-center'>
                            <div className='content_item'>
                                {/* TODO: This DynamicContentSection can be replaced with the newer Content.jsx module - just need to bring Content styles into the DynamicContentPage.scss file */}
                                {contentSection?.values.map((section, index) => (
                                    <DynamicContentSection
                                        element={section}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                    />
                                ))}
                            </div>
                            <div className='content_item'>
                                {relatedVideos && (
                                    <RelatedContent bgColor='#ffffff' headingText={relatedVideos?.value?.elements?.relatedVideosHeading?.value} fluid>
                                        <Grid wrapperStyle={{ marginTop: 40 }} itemsPerRow={{ sm: 1, md: 2, lg: isFullWidth ? 1 : 3 }} gap={{ sm: 20, md: 20, lg: 20 }}>
                                            {relatedVideos?.value?.elements?.relatedVideosVideoCards?.values.map((video) => {
                                                const { id, lastModified } = video || {}

                                                const { videoCardContent, videoCardHeading,externalVideoLink:videoCardExternalLink, videoCardImage, videoCardLink, videoCardSubheading, videoCardVideo, webvttChaptersFile, subtitles, videoPoster } = video?.elements || {}

                                                return (
                                                    <VideoCard
                                                        key={id}
                                                        href={videoCardLink?.linkURL}
                                                        image={videoCardImage}
                                                        video={videoCardVideo}
                                                        heading={videoCardHeading?.value}
                                                        subHeading={videoCardSubheading?.value}
                                                        content={videoCardContent?.value?.text}
                                                        postDate={lastModified}
                                                        isFullWidth={isFullWidth}
                                                        webvttChaptersFile={webvttChaptersFile}
                                                        subtitles={subtitles}
                                                        videoPoster={videoPoster}
                                                        externalVideoLink={videoCardExternalLink}
                                                    />
                                                )
                                            })}
                                        </Grid>
                                    </RelatedContent>
                                )}
                            </div>
                        </section>
                        <div className='content_wrapper content_wrapper--columns-2-right'></div>
                    </div>
                    {relatedContent && relatedContent?.value && <RelatedContent content={relatedContent} fluid />}
                    {bannerImage && <BannerImage image={bannerImage} contain={false} />}
                    <ApprovalCode code={veevaApprovalCode} />
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    {references && <References references={references} />}
                </div>
            </section>
        </section>
    )
}

export default DynamicContentPage
