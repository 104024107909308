import React from 'react';
import './CustomisableImageCard.scss';

import Card from '../../components/reusableComponents/card/Card';
import CardImage from 'components/reusableComponents/card/components/cardImage/CardImage';
import CardHeading from 'components/reusableComponents/card/components/cardHeading/CardHeading';
import CardContent from 'components/reusableComponents/card/components/cardContent/CardContent';
import RichText from 'components/reusableComponents/richText/RichText';
import { convertHTMLToText } from 'utils/stringUtils';

const CustomisableImageCard = ({ element }) => {
  const {
    image,
    description,
    title,
    imageAlignment
  } = element.elements;
  return (
    <Card className={`CustomisableImageCard ${imageAlignment?.value?.selection==="Right" ? "CustomisableImageCard--right" : ""}`} removeShadow>
      <CardImage icon={image} />
      <CardContent className="CardContent">
        {title && <CardHeading heading={convertHTMLToText(title?.value, false)} />}
        <RichText className="CardDescription" data={description} />
      </CardContent>
    </Card>
  );
};

export default CustomisableImageCard;
