import { AppConfig } from 'AppConfig';
import { useCookies } from 'react-cookie';
import { getUrlCountryPath } from 'utils/urlUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { validateUser } from 'api/veevaApi';
import { setVeevaUser, setveevaUserInvalid } from 'redux/authentication/slice';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const COOKIE_OPTIONS = {
  path: `/${getUrlCountryPath()}`,
};

const SECRET_KEY = 'VeevaUserAccessKey';
const ALL_HUB_ACCESS_TYPE = 'ah';
export const VEEVA_SRC = 'VeevaCRM';

const useVeevaUserAccess = (accessLevel) => {
  let accessGranted = false;
  const [cookies, setCookie] = useCookies(['veevaAccessGrants']);
  const dispatch = useDispatch();
  let veevaAccessGrants = [];

  // const history = useHistory();
  const { pathname: path, search } = useLocation();

  const {
    sourceQueryName,
    sendDateQueryName,
    accessTypeQueryName,
    userIdQueryName,
    durationQueryName,
  } = AppConfig.veevaApi;

  //url querystring
  const urlParams = new URLSearchParams(search);
  const src = urlParams.get(sourceQueryName);
  // let userId = urlParams.get(userIdQueryName) ?? urlParams.get('actid');
  // userId = userId ?? urlParams.get('actId');
  const veevaIdKeyParams = [userIdQueryName, 'actId', 'veevaId'];
  let userId;
  veevaIdKeyParams.forEach(key => {
    const paramValues = [urlParams.get(key), urlParams.get(key.toLowerCase()), urlParams.get(key.toUpperCase())].filter(value => value !== null)

    if(Boolean(paramValues.length)) {
      userId = paramValues[0];
      return;
    }
  });
  const sendDateTime = urlParams.get(sendDateQueryName);
  const accessType = urlParams.get(accessTypeQueryName);
  const durationInDays = urlParams.get(durationQueryName);

  // For session only access
  const { veevaUser } = useSelector((state) => state.authentication.status);
  if (veevaUser) {
    const { entryUrl, accessType, duration } = veevaUser;
    if (duration === 'so') {
      // if (src === VEEVA_SRC) {
      //   history.replace({ search: '' });
      // }
      if(entryUrl === path || accessType === ALL_HUB_ACCESS_TYPE) {
        return true
      }

      dispatch(setVeevaUser(false))
      return false;
    }
  }

  // Get actual cookie value
  if (cookies.veevaAccessGrants) {
    // Decrypt and parse
    const bytes = CryptoJS.AES.decrypt(cookies.veevaAccessGrants, SECRET_KEY);
    veevaAccessGrants = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const getMaxDate = () => {
    return new Date(
      veevaAccessGrants.reduce((max, date) =>
        new Date(max.expiry) < new Date(date.expiry) ? date : max
      ).expiry
    );
  };
  const updateVeevaAccessGrants = () => {
    const maxDate = getMaxDate();

    // Encryption before updating cookie
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(veevaAccessGrants),
      SECRET_KEY
    ).toString();

    setCookie('veevaAccessGrants', ciphertext, {
      ...COOKIE_OPTIONS,
      expires: maxDate,
    });
  };
  const hasExpired = (date) => {
    // return new Date(new Date(date).toUTCString().slice(0, -4)) - new Date() < 0;
    return date - new Date() < 0;
  };
  const getCookieAccessOption = () => {
    const result = veevaAccessGrants.filter(
      (option) => option.entryUrl === path
    );
    return result[0];
  };
  const validateSession = () => {
    const cookieOptions = veevaAccessGrants.filter(
      (option) =>
        (option.entryUrl === path ||
          option.accessType === ALL_HUB_ACCESS_TYPE) &&
        !hasExpired(option.expiry)
    );

    if (cookieOptions.length > 0) {
      validateUserId(cookieOptions[0].userId, {
        expiry: new Date(cookieOptions[0].expiry),
      });
      return true;
    }

    dispatch(setVeevaUser(false))

    return false;
  };

  const validateUserId = (userId, options = {}) => {
    if (userId && veevaUser === null) {
      dispatch(setVeevaUser(true));
      validateUser(userId).then((response) => {
        if (response.status === 200) {
          dispatch(
            setVeevaUser({
              userInfo: response.data,
              ...options,
            })
          );
        } 
      }).catch(error => {
        dispatch(setVeevaUser(false));
      }).finally(_ => {
        // if (src === VEEVA_SRC) {
          // Remove query strings
          // history.replace({ search: '' });
        // }
      });
    }
  };

  const setInvalidAccess = () => {
    if (veevaUser === null) {
      dispatch(
        setVeevaUser({
          userInfo: "invalid",
        })
      );
      dispatch(setveevaUserInvalid(true));
    }
  };

  const createSession = () => {
    if (userId) {
      let accessOption = getCookieAccessOption();
      let date = sendDateTime;

      if (date && durationInDays && durationInDays !== 'so') {
        date = new Date(date);
        // Set expiry to last for one day
        // date.setUTCHours(23, 59, 59, 999);
        // date.setDate(date.getDate() + parseInt(durationInDays));
        const expiration = parseFloat(durationInDays) * 24 * 60 * 60 * 1000;
        date.setTime(date.getTime() + expiration);
      }

      if(!durationInDays){
        validateUserId(userId, { accessType, entryUrl: path, duration: 'so' });
        return veevaUser?.userInfo ? true : false;
      }

      if (date && !hasExpired(date)) {
        const options = {
          accessType,
          entryUrl: path,
          duration: durationInDays,
          expiry: durationInDays && durationInDays !== 'so' ? date : null,
        };

        if(accessOption && new Date(accessOption.expiry) > date) {
          options.expiry = new Date(accessOption.expiry)
          options.accessType = accessOption.accessType
        }

        // Call veeva crm to validate user
        // if (veevaUser?.userInfo?.Id !== userId) {
        validateUserId(userId, options);
        // }
        if (veevaUser?.userInfo) {
          // if (date && !hasExpired(date)) {
          if (!accessOption) {
            accessOption = {
              accessType: accessType,
              entryUrl: path,
              expiry: date,
              userId,
            };
            veevaAccessGrants.push(accessOption);
            updateVeevaAccessGrants();
          } else {
            if (new Date(accessOption.expiry) < date) {
              accessOption.expiry = date;
              accessOption.accessType = accessType;

              updateVeevaAccessGrants();
            }
          }


          return true;
          // }
        }
      } else {
        setInvalidAccess();
      }
    }
    else {
      return validateSession();
    }

    return false;
  };

  // const createVeevaSession = () => {
  //   validateUserId(userId, {
  //     accessType: 'po',
  //     entryUrl: path,
  //     duration: 'so'
  //   })

  //   return veevaUser?.userInfo ? true : false;
  // }

  accessGranted = createSession();
  // accessGranted = createVeevaSession();

  return accessGranted;
};

export default useVeevaUserAccess;
