import React from 'react'
import './LinkPanel.scss'
import { LinkButton } from 'components/content/LinkButton';
import { getValue } from 'utils/contentUtils';
import RichText from 'components/reusableComponents/richText/RichText'
import { ChevronRight } from 'components/shared/SvgIcons';
import Link from '../../components/reusableComponents/link/Link';

const LinkPanel = ({ linkColour, introTitleElement, introTextElement, linkElements, category}) => {
    return <> 
        { introTitleElement && (
        <div className="link-panel">

            <div className="title-description">
                {category?.value && 
                    <h3>{category.value}</h3>
                }
                <h2>{getValue(introTitleElement)}</h2>

                {introTextElement && <RichText data={introTextElement} />}
            </div> 

            <ul className="link-panel-links">

                { linkElements?.map((linkItem, i) => {
                    const { externalLink, paragraph, title, link, titleRichText} = linkItem;
                    
                    const titleRichTextExists = titleRichText?.value && titleRichText?.value?.text?.value;

                    const href = (link?.linkURL) ? link?.linkURL : externalLink?.value;
                    return (
                        <li key={i}>

                            <Link className="link-panel-title-link" href={href} style={{ color: linkColour }} vvClickAttr='click'> { titleRichTextExists ? <RichText data={titleRichText?.value?.text ? titleRichText?.value?.text : titleRichText} /> : getValue(title)} <span className="chevron"> <ChevronRight /> </span> </Link>
                            
                            <RichText data={paragraph?.value?.text ? paragraph.value.text : paragraph} />
                        </li>
                    );
                })}
            </ul>   
        </div>
    )}
    { !introTitleElement && linkElements?.values?.length && (
        <ul className="links">
            {
            linkElements.values.map((item, i) => {
                return (
                <li key={i}>
                    <LinkButton to={item.link?.linkURL} vvClickAttr='click'>
                        {item.titleRichText?.value?.text?.value ? <RichText data={item.titleRichText?.value?.text} /> : item.title?.value}
                    <span className="chevron">
                        <ChevronRight />
                    </span>
                    </LinkButton>
                    <RichText data={item?.paragraph?.value?.text} />                   
                </li>
                );
            })
            }
        </ul>
    )}
    </>
}

export default LinkPanel