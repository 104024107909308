import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getUrlCountryPath } from 'utils/urlUtils';

const Giosg = (props) => {
    const { id, url, environment } = props;
  // const langCode = getLanguageCode();
  const market = getUrlCountryPath();

  return (
    <>
      {id && url && environment && (
        <Helmet htmlAttributes={{ lang: market?.trim() || 'en' }}>
          <script
            id='giosg-integration'
            type='text/javascript'
          >{`(function(w, t, f) { if(!window._giosg) { var s='script',o='_giosg',h='${url}',e,n;e=t.createElement(s);e.async=1;e.src=h+'/${environment}/'+f;
  w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
}})(window,document,"${id}");`}</script>
        </Helmet>
      )}
    </>
  );
};

export default Giosg;
