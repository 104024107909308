import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { HOME_PAGE_PATH } from 'routes/routeConstants';
import { LANDING_PAGE } from 'api/acousticApi/contentTypes';
import { CMSPage } from './CMSPage';

// Landing page is a special case content page
// the route is hard coded as / and the content id comes from the
// website config object rather than as a result of the routes search
const LandingPage = () => {
  const { landingPageId } = useWebsiteConfigObject();
  if (!landingPageId) {
    return null;
  }
  return (
    <CMSPage
      contentId={landingPageId}
      path={HOME_PAGE_PATH}
      templateType={LANDING_PAGE}
    />
  );
};

export { LandingPage };
