import useSfmc from 'hooks/useSfmc';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { getUrlCountryPath } from 'utils/urlUtils';

export const Sfmc = (props) => {
  const { dataset } = props;
  // const { oneTrustTargetingAccepted } = useSelector((state) => state.ui);
  const [sfmcInstance, setSfmcInstance] = useState(null)
  const market = getUrlCountryPath();
  const { init } = useSfmc() || {};
  const [type, setType] = useState('text/javascript');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.SalesforceInteractions) {
        setSfmcInstance(window.SalesforceInteractions)
        clearInterval(intervalId)
      }
    }, 1000);

    return () => clearInterval(intervalId); 
  }, []);

  useEffect(() => {
    if(sfmcInstance) init();
  }, [init, sfmcInstance])
  

  // useEffect(() => {
  //   setType(oneTrustTargetingAccepted ? 'text/javascript' : 'text/plain');
  // }, [oneTrustTargetingAccepted]);

  return (
    <>
      {dataset && (
        <Helmet htmlAttributes={{ lang: market?.trim() || 'en' }}>
          <script
            type={type}
            src={`//cdn.evgnet.com/beacon/kyowakirinintlplc/${dataset}/scripts/evergage.min.js`}
          ></script>
        </Helmet>
      )}
    </>
  );
};

export default Sfmc;
