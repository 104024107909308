import React, { useState } from 'react';
import RichText from 'components/reusableComponents/richText/RichText';
import './ImageBlock.scss';
import Image from 'components/reusableComponents/image/Image';
import { AppConfig } from 'AppConfig';
import {parse} from 'react-html-parser';

const ImageBlock = (props) => {

  const {
		title,
		description,
		alternateText,
		alternateTextBackgroundColor,
		imagePicker
  } = props.elements.elements;
	
	const style = {
		backgroundColor:alternateTextBackgroundColor?.value
}

  return (
		<div className="image_block">
			<RichText data={title} />
			<div className={props?.size=="large" ? "image_block-image" : "image_block_sm"}>
			<Image data={imagePicker}/>
			</div>
			{alternateText?.value &&
			<div style={style} className="alternate_text">
				<RichText data={alternateText}/>
			</div>}
			{
				description &&
				<div className="description">
					<RichText data={description} />
				</div>
			}
			
		</div>
  );
}

export default ImageBlock;
