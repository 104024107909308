import React from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { castElementToBool } from 'utils/contentUtils';
import { AuthorsIcon } from 'components/shared/SvgIcons';
import RichText from 'components/reusableComponents/richText/RichText'
import {
  contentElementProptype,
} from 'propTypes/contentProptypes';
import './AuthorSection.scss';
import { UserIcon } from 'components/shared/SvgIcons';


const AuthorSection = ({
  authors,
  icon
}) => {
  const hasAuthors = castElementToBool(authors);
  const { tAuthors } = useWebsiteConfigObject();

  if (!hasAuthors) {
    return null;
  }
  return (
    <aside className="authors-section-container">
      <h4 className="authors-section-header">
        {icon === "iconUser"? <UserIcon/> :<AuthorsIcon/> }
        
        <span>{tAuthors}</span>
      </h4>
      <div className="contents authors-section-body">
        <RichText data={authors} />
      </div>
    </aside>
  );
};

AuthorSection.propTypes = {
  authors: contentElementProptype,
};

export {
  AuthorSection,
};

