import React from 'react';
import { ChevronRight } from 'components/shared/SvgIcons';
import { getAssetUrl } from 'api/acousticApi';
import { getImgAttr } from 'api/acousticApi';
import { TextCardLearnMore } from 'components/content/cards/TextCardLearnMore';
import Link from 'components/reusableComponents/link/Link';


import './SectionCard.scss';
const TextCardContainer = ({ onClickPath, externalUrl, className, children }) => {

  let  cssClass 
 
  const href = externalUrl || onClickPath || null
  if(href !== undefined ){
    cssClass = `custom-focus-therapy module-section-card-item ${className ? className : ''}`
  }
 

  if (href === undefined) {
      return <div className="module-section-card-item">{children}</div>
  }

  return (
      <Link className={cssClass} href={href}>
          {children}
      </Link>
  )
}

const SectionCard = ({ moduleSectionPage, labelReadMore }) => {

    const {
        sectionIcon,
        sectionTitle,
        sectionTitleLink,
        sectionImage 
      } = moduleSectionPage;

      const style = {
        backgroundImage: `url(${getAssetUrl(sectionImage)})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'right'
      };

      const {
        src,
        alt,
      } = getImgAttr(sectionIcon);

      const Icon = src ? (
        <img
          src={src}
          alt={alt}
        />
      ) : null;

    return (
      <>
      {sectionImage.url && (
         <TextCardContainer onClickPath={sectionTitleLink?.linkURL}>
          <div className="module-section-card" style={style}>
           <div className="section-card-header">
               <span className="icon">
                 {Icon}
               </span>
               <h2 className="title">
                 {sectionTitle.value}
               </h2>
             </div>
             <div className="read-more">
               <Link className="learn-more"
                 href={sectionTitleLink?.linkURL}
                 vvClickAttr='click'
               >
                 <p className="learn-more-text">
                   {labelReadMore}
                 </p>
                 <div className="svg-container">
                   <ChevronRight />
                 </div>
               </Link>
            </div>
          </div>
        </TextCardContainer>
      )}
      </>
    ) 
};

export { SectionCard }