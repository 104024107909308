import React from 'react';

//CSS
import './overlay.scss';

const Overlay = () => { 

    return (
        <div id="js-overlay-modal" className="overlay-modal hide-overlay"></div>
    );
}

export default Overlay;