import { AppConfig } from 'AppConfig';
import { getUTMCampaigns, searchQuery } from 'api/acousticApi'
import { getLibraryByCode } from 'utils/libraryUtils'
import { getUrlCountryPath } from 'utils/urlUtils'

const UTM_CAMPAIGN_CONTENT_TYPE = 'Deep Link'
const UTM_CAMPAIGN_LIBRAIRY_ID = '81837619-b13b-4221-b2ed-1c180771833e'

const websiteSearchParams = {
  q: '*:*',
  fl: 'name,document:[json],id',
  fq: `type:("${UTM_CAMPAIGN_CONTENT_TYPE}")`,
  rows: 10000,
  start: 0,
  sort: 'lastModified desc',
}

const params = new URLSearchParams(websiteSearchParams)

// const culture = getUrlCountryPath()

// const { libraryId } = getLibraryByCode(culture)

params.append('fq', `libraryId:("${UTM_CAMPAIGN_LIBRAIRY_ID}")`)

const utmCampaignQuery = () => getUTMCampaigns(params)

export { utmCampaignQuery }
